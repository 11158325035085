// helpers/SplideSlider.tsx
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Options, Splide, SplideEventHandlers, SplideSlide } from '@splidejs/react-splide';

interface SplideSliderProps extends Partial<SplideEventHandlers> {
  options?: Options; // Options for configuring the Splide slider.
  children: ReactNode; // React children to be used as slides.
  /*if is autoplay is seted true from the sitecore side then we need also need to provide this props because 
  to manage the arrow click */
  isAutoPlay?: boolean;
  setVisibleIndex?: Dispatch<SetStateAction<number>>;
}

const SplideSlider: React.FC<SplideSliderProps> = ({
  options = {},
  children,
  isAutoPlay,
  setVisibleIndex,
  ...props
}) => {
  return (
    // Splide component with combined options.
    <Splide
      options={options}
      onMoved={(splide) => {
        isAutoPlay && splide.Components.Autoplay.play();
      }}
      onVisible={(splide) => {
        setVisibleIndex && setVisibleIndex(splide.index);
      }}
      {...props}
    >
      {/* Map over children and wrap each in a SplideSlide component. */}
      {React.Children.map(children, (child) => (
        <SplideSlide>{child}</SplideSlide>
      ))}
    </Splide>
  );
};

export default SplideSlider;
