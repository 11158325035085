import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';
import LinkHelper from './LinkHelper';
import TextHelper from './TextHelper';
export type ButtonProps = {
  field?: LinkField | Field<string>;
  isLinkfield: boolean;
  isDisbaled?: boolean;
  outline?: boolean;
  asButton?: boolean;
  icon?: string;
  size: 'default' | 'medium' | 'small';
  variant:
    | 'primary'
    | 'secondary'
    | 'link'
    | 'disabled'
    | 'btnSuccess'
    | 'btnError'
    | 'btnInfo'
    | 'btnPurple';
  className?: string;
  onClickHandler?: (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
};
const buttonVariant = tv({
  base: 'rounded-[4px]',
  variants: {
    color: {
      primary:
        'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x lg:px-desk-component-button-full-padding-x  hover:no-underline cursor-pointer bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark text-color-text-white',
      secondary:
        'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x lg:px-desk-component-button-full-padding-x  hover:no-underline cursor-pointer bg-color-scale-1-white hover:bg-color-brand-primary-1-dark border-2 border-color-brand-primary-1-base hover:border-color-brand-primary-1-dark text-color-text-brand-1 hover:text-color-text-white',
      link: 'p-desk-component-button-full-space-between text-color-text-brand-1 no-underline	hover:underline',
      disabled:
        'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x lg:px-desk-component-button-full-padding-x  hover:no-underline cursor-pointer bg-color-brand-primary-1-disabled text-color-scale-1-white  cursor-not-allowed',
      btnSuccess:
        'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x lg:px-desk-component-button-full-padding-x  hover:no-underline cursor-pointer bg-color-scale-1-white hover:bg-[#98D8A3] border-2 border-color-brand-primary-1-base hover:border-[#98D8A3] text-color-text-brand-1 hover:text-color-text-white',
      btnError:
        'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x lg:px-desk-component-button-full-padding-x  hover:no-underline cursor-pointer bg-color-scale-1-white hover:bg-[#F8827F] border-2 border-color-accent-red hover:border-[#F8827F] text-color-accent-red hover:text-color-text-white',
      btnInfo:
        'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x lg:px-desk-component-button-full-padding-x  hover:no-underline cursor-pointer bg-color-scale-1-white hover:bg-[#7FC2E2] border-2 border-color-accent-blue hover:border-[#7FC2E2] text-color-accent-blue hover:text-color-text-white',
      btnPurple:
        'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x lg:px-desk-component-button-full-padding-x  hover:no-underline cursor-pointer bg-color-scale-1-white hover:bg-[#B983BE] border-2 border-color-accent-purple hover:border-[#B983BE] text-color-accent-purple hover:text-color-text-white',
    },
    size: {
      default:
        'text-heading-mob-large-bold lg:text-heading-desk-large-bold font-heading-mob-large-bold lg:font-heading-desk-large-bold leading-heading-mob-large-bold lg:leading-heading-desk-large-bold',
      medium:
        'text-heading-mob-medium-bold lg:text-heading-desk-medium-bold font-heading-mob-medium-bold lg:font-heading-desk-medium-bold leading-heading-mob-medium-bold lg:leading-heading-desk-medium-bold',
      small: 'text-body-large-bold font-body-large-bold leading-body-large-bold',
    },
  },
});
const ButtonHelper: React.FC<ButtonProps> = ({
  field,
  isLinkfield,
  variant,
  size,
  asButton,
  outline,
  isDisbaled,
  className,
  onClickHandler,
}): JSX.Element => {
  // const classes = `${className} ${outline ? 'border-[3px] hover:border-[#BC7B68]' : ''} ${
  //   isDisbaled ? 'pointer-events-none' : ''
  // }`;
  const buttonStyle = {
    className: `${className} ${outline ? 'border-[3px] hover:border-[#BC7B68]' : ''} ${
      isDisbaled ? '!cursor-not-allowed' : ''
    }`,
    color: variant,
    size: size,
  };
  // const { base } = buttonVariant({ ...buttonStyle });
  if (!field) {
    return <></>;
  }
  return (
    <>
      {isLinkfield ? (
        <LinkHelper
          className={buttonVariant({ ...buttonStyle })}
          field={field as LinkField}
          editable={true}
          asButton={asButton}
          onClick={onClickHandler}
        />
      ) : (
        <>
          <button
            aria-label={field?.value?.toString() || ''}
            className={buttonVariant({ ...buttonStyle })}
            disabled={isDisbaled}
            onClick={onClickHandler}
          >
            <TextHelper
              field={field as Field<string>}
              isEditing={true}
              onClickHandler={onClickHandler}
            />
          </button>
        </>
      )}
    </>
  );
};
export default ButtonHelper;
