import { errorMessages } from 'components/helpers/Constants';
import React from 'react';
import { tv } from 'tailwind-variants';

const errorLabelVariant = tv(
  {
    slots: {
      title: ['text-system-red'],
    },
    variants: {
      size: {
        mobile: {
          title: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
        },
        desktop: {
          title: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

const ErrorLabel = () => {
  const { title } = errorLabelVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  return (
    <>
      <span className={title()}>{errorMessages?.invalidCVV}</span>
    </>
  );
};

export default ErrorLabel;
