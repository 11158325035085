/* eslint-disable */
import { tv } from 'tailwind-variants';
const BVPLPRatingTailwind = tv(
  {
    slots: {
      base: ['w-full', 'flex'],
    },

    variants: {
      device: {
        mobile: {
          base: [''],
        },
        desktop: {
          base: [''],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
const BVPLPRating = ({
  bvProductId,
  bvRedirectUrl,
  showLeftAlign,
}: {
  bvProductId: string | undefined;
  bvRedirectUrl: string;
  showLeftAlign?: boolean;
}) => {
  const { base } = BVPLPRatingTailwind({
    device: { initial: 'mobile', lg: 'desktop' },
  });

  return (
    <div
      data-bv-show="inline_rating"
      data-bv-product-id={bvProductId}
      data-bv-redirect-url={bvRedirectUrl}
      data-bind="attr:{'data-bv-product-id:SKU(), 'data-bv-redirect-url':ProductUrl()}"
      className={base({
        className: showLeftAlign ? 'justify-start' : 'justify-center',
      })}
    ></div>
  );
};
export default BVPLPRating;
