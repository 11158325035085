import apiConfig from 'src/utils/apiConfig';
import { apiRequest } from 'src/utils/apiWrapper';

export const END_POINTS = {
  allPets: apiConfig?.petsAPI?.allPets,
  getGender: apiConfig?.petsAPI?.getGender,
  getSpecies: apiConfig?.petsAPI?.getSpecies,
  getBreed: apiConfig?.petsAPI?.getBreed,
  addPet: apiConfig?.petsAPI?.addPet,
  updatePet: apiConfig?.petsAPI?.updatePet,
  appPetImage: apiConfig?.petsAPI?.addPetImage,
};

export const REQUEST_METHOD = {
  get: 'GET',
  post: 'POST',
};
export const BASE_URL = process.env.NEXT_PUBLIC_PET_API_ENDPOINT;

//set the gender details

export const setPetsGender = async (token: string, siteName: string) => {
  if (token) {
    const headersData = {
      Authorization: token,
      site: siteName,
    };
    const options = {
      method: REQUEST_METHOD?.get,
      headers: headersData,
    };
    try {
      const response = await apiRequest(BASE_URL + END_POINTS?.getGender, options);
      if (response) {
        return response;
      }
      return {};
    } catch (error) {
      console.error('@@PetsGenderError', error);
      return {};
    }
  }
  return null;
};
//set the species details
export const setPetsSpecies = async (token: string, siteName: string) => {
  const headersData = {
    Authorization: token,
    site: siteName,
  };
  const options = {
    method: REQUEST_METHOD?.post,
    data: {
      CustGroup: siteName?.toUpperCase(),
    },
    headers: headersData,
  };
  try {
    const response = await apiRequest(BASE_URL + END_POINTS?.getSpecies, options);
    if (response) {
      return response;
    }
    return {};
  } catch (error) {
    console.error('@@PetsSpeciesError', error);
    return {};
  }
};
