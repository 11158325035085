import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

interface TextProps {
  field?: Field<string>;
  className?: string;
  tag?: string;
  isEditing?: boolean;
  updatedField?: Field<string>;
  onClickHandler?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const TextHelper: React.FC<TextProps> = ({
  field,
  className,
  tag,
  isEditing,
  updatedField,
  onClickHandler,
  ...props
}: TextProps) => {
  if (!field) {
    return <></>;
  }
  return (
    <Text
      {...props}
      field={updatedField || field}
      tag={tag}
      className={className}
      editable={isEditing}
      onClick={onClickHandler}
    />
  );
};

export default TextHelper;
