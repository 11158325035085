import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { ProductSearchResultModel } from '../types';
import searchResultsTailwind from '../SearchResultsTailwind';
// import { PDPCarouselVariant } from 'components/ProductDetailSliderComponent/ProductDetailSliderComponent';
// const { flagIcon } = PDPCarouselVariant({
//   size: { initial: 'mobile', lg: 'desktop' },
// });

export function ProductMadeInUsa({ product }: { product: ProductSearchResultModel }) {
  const { flagIcon } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  if (product.xp_features?.includes('Made in USA')) {
    // return <div>[US Flag]</div>;
    return (
      <NextImage
        field={{
          value: {
            src: '/images/badge-flag-1.png',
            alt: 'US Made',
            width: '27',
            height: '18',
          },
        }}
        className={flagIcon()}
      />
    );
  } else {
    return <div></div>;
  }
  return null;
}
