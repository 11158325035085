/**
 * To check if user is authenticated or not at the time of redirecting to checkout page.
 * @param isAnonymous
 * @param redirectLink
 */

import { currentPath } from 'components/helpers/Constants';

export const analyseAuthForLoginCheckout = (
  isAnonymous: boolean,
  redirectLink: string,
  pathName?: string,
  isFulfilmentDFS?: boolean,
  showDeliveryWarningThreshold?: boolean
) => {
  if (pathName == currentPath?.isShoppingCart && isFulfilmentDFS && showDeliveryWarningThreshold) {
    return { openDeliveryWarningThreashold: true };
  } else if (isAnonymous && redirectLink && pathName == currentPath?.isShoppingCart) {
    return { redirectToLoginCheckout: true };
  } else if (isAnonymous && redirectLink && pathName !== currentPath?.isShoppingCart) {
    // now being handled into OrderSummary - keep this commented for now.
    const returnUrl = redirectLink; // '/checkout'
    // window.location.pathname = '/logincheckout';
    // const url = new URL(currentUrl);
    // url.searchParams.set('ReturnUrl', returnUrl);
    // window.history.pushState({}, '', url.href);
    // return {};

    const currentUrl = new URL(window.location.href);
    currentUrl.pathname = currentPath?.isLoginCheckout;
    currentUrl.searchParams.set('ReturnUrl', returnUrl);
    // window.location.href = currentUrl.toString();
    return { redirectToLoginCheckout: true, redirectURL: currentUrl.toString() };
  } else {
    // window.location.pathname = redirectLink;
    // window.history.pushState({}, '', redirectLink);
    return { redirectURL: redirectLink };
  }
};
