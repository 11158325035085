//global
import React, { useContext } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local

//lib
import { ComponentProps } from 'lib/component-props';
import ComponentContext from 'lib/context/ComponentContext';
import ModalWrapper from 'components/helpers/ModalWrapper';
import { WarningDeliveryThresholdClass } from 'components/WarningDeliveryThreshold/WarningDeliveryThreshold';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useOcSelector } from 'src/redux/ocStore';
import LinkHelper from 'components/helpers/LinkHelper';
import clsx from 'clsx';
import { currentPath } from 'components/helpers/Constants';
import { useRouter } from 'next/router';
import { PSP } from 'models/PetSuppliesPlus.Model';
//type
export type WarningAutoshipProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.WarningAutoship.Fields.WarningAutoship;
//main component
const WarningAutoship: React.FC<WarningAutoshipProps> = ({ fields }) => {
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const router = useRouter();
  const {
    popUpPad,
    titleLargeBold,
    bodyCopy,
    buttonStyles,
    buttonPrimaryStyle,
    buttonSecondaryStyle,
  } = WarningDeliveryThresholdClass({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const handleModalClose = () => {
    setcomponentContextData({
      ...componentContextData,
      showAutoshipWarning: false,
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedStore = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const eligibleValue = selectedStore?.deliveryFee?.minimumAmount;

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div>
      {componentContextData.showAutoshipWarning && (
        <ModalWrapper
          additionalClassForModal={'!z-[1000]'}
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          showModal={true}
          onCloseClick={handleModalClose}
          popupWidth="max-w-[995px]"
          popupSpacing={popUpPad()}
          customPopup
        >
          <div className="flex flex-col gap-desk-space-between-tight-vertical">
            <RichTextHelper field={fields?.title} tag="p" className={titleLargeBold()} />
            {fields?.description && (
              <RichTextHelper
                field={fields?.description}
                className={bodyCopy()}
                tag="p"
                updatedField={{
                  value: fields?.description?.value?.replaceAll('{minAmount}', '$' + eligibleValue),
                }}
              />
            )}
            <div className="flex lg:flex-row flex-col lg:gap-[8px]">
              <LinkHelper
                field={fields?.primaryLink}
                onClick={async (e) => {
                  e.preventDefault();
                  setcomponentContextData({
                    ...componentContextData,
                    showAutoshipWarning: false,
                  });

                  router.push(currentPath?.isShoppingCart);
                }}
                className={clsx(buttonStyles(), buttonPrimaryStyle())}
              />
              <LinkHelper
                field={fields?.secondaryLink}
                className={clsx(buttonStyles(), buttonSecondaryStyle())}
                onClick={async (e) => {
                  e.preventDefault();
                  handleModalClose();
                }}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<WarningAutoshipProps>(WarningAutoship);
