// components/Breadcrumb.tsx
import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { Field, GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  getBreadCrumbWithSiblingsGql,
  getBreadcrumbDefaultGql,
  getBreadcrumbOnlySiblingsGql,
  getPDPBreadcrumbGql,
} from './breadcrumb.graphql';
import BreadCrumbWithSiblings, { BreadcrumbData } from './BreadCrumbWithSiblings';
import OnlyBreadCrumbSiblings from './OnlyBreadCrumbSiblings';
import BreadcrumbPDP from './BreadcrumbPDP';
import apiConfig from 'src/utils/apiConfig';
import { extractDomainUrlFromConfig } from 'src/helpers/publicURLTrailingSlash';
import { getSiteName } from 'src/helpers/siteNameHelper';
import BreadCrumbDefault from './BreadCrumbDefault';
import graphqlClientFactory from 'lib/graphql-client-factory';

type BreadcrumbProps = ComponentProps & {
  staticProps: {
    isBreadcrumbEnable: Field<string>;
    data: BreadcrumbData;
    itemPath: string;
    siteName: string;
    itemId: string;
    breadCrumbTitle?: Field<string>;
    pageTitle?: Field<string>;
    formattedItemId: string;
    productName: string;
  };
  children?: React.ReactNode;
};

const BreadcrumbBase: React.FC<BreadcrumbProps> = ({ staticProps, children }) => {
  if (!staticProps?.isBreadcrumbEnable?.value) {
    return null;
  }
  return <>{children}</>;
};

export const Default: React.FC<BreadcrumbProps> = ({ staticProps, rendering, params }) => (
  <BreadcrumbBase staticProps={staticProps} rendering={rendering} params={params}>
    <BreadCrumbDefault gqldata={staticProps?.data} siteName={staticProps?.siteName} />
  </BreadcrumbBase>
);

export const OnlySiblings: React.FC<BreadcrumbProps> = ({ staticProps, rendering, params }) => (
  <BreadcrumbBase staticProps={staticProps} rendering={rendering} params={params}>
    {staticProps?.breadCrumbTitle || staticProps?.pageTitle ? (
      <OnlyBreadCrumbSiblings
        gqldata={staticProps?.data}
        siteName={staticProps?.siteName}
        breadCrumbTitle={staticProps?.breadCrumbTitle}
        pageTitle={staticProps?.pageTitle}
        formattedItemId={staticProps?.formattedItemId}
      />
    ) : null}
  </BreadcrumbBase>
);

export const PDPBreadcrumb: React.FC<BreadcrumbProps> = ({ staticProps, rendering, params }) => (
  <BreadcrumbBase staticProps={staticProps} rendering={rendering} params={params}>
    <BreadcrumbPDP gqldata={staticProps?.data} productName={staticProps?.productName} />
  </BreadcrumbBase>
);

export const PLPBreadcrumb: React.FC<BreadcrumbProps> = ({ staticProps, rendering, params }) => (
  <BreadcrumbBase staticProps={staticProps} rendering={rendering} params={params}>
    <BreadCrumbWithSiblings gqldata={staticProps?.data} siteName={staticProps?.siteName} />
  </BreadcrumbBase>
);

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData, context) => {
  const sitecore = layoutData?.sitecore;
  const route = sitecore?.route;
  const sitecoreContext = sitecore?.context;
  const fields = route?.fields;
  const itemId = route?.itemId;
  const templateId = route?.templateId;
  const language = sitecoreContext?.language;
  const itemPath = sitecoreContext?.itemPath as string;
  const siteName = sitecoreContext?.site?.name as string;
  const parentid = sitecoreContext?.productData?.parentid as string;
  const productSku = sitecoreContext?.productData?.productSku as string;
  const domainUrl = extractDomainUrlFromConfig(siteName);
  if (rendering && language && itemId && rendering.params) {
    const graphQLClient = graphqlClientFactory({});
    const isBreadcrumbEnable = (fields?.enabled as Field<boolean>) ?? false;
    const fieldName = rendering.params.FieldNames as QueryMapKeys;

    let pathSegments: string[];
    let parentPath: string;
    let productName: string | null;

    if (fieldName === 'PDPBreadcrumb' && parentid && productSku) {
      const response = await fetch(
        `${domainUrl}/${apiConfig?.productAPI?.getProductAPI
          ?.replace('{parentid}', parentid)
          ?.replace('{productSku}', productSku)
          ?.replace('{siteName}', getSiteName(siteName)?.toUpperCase())}`
      );
      const productData = await response.json();
      const productPath = productData?.Xp?.Path?.toLowerCase() ?? '';
      productName = productData?.Xp?.ParentFamilyName ?? null;

      parentPath = `/sitecore/content/PetSuppliesPlus/${siteName}/Home/categories/${productPath}`;
    } else {
      pathSegments = itemPath.split('/').filter(Boolean);
      parentPath = `/sitecore/content/PetSuppliesPlus/${siteName}/Home/${pathSegments
        .slice(0, -1)
        .join('/')}`;
      productName = null;
    }

    const formattedItemId = itemId.replace(/-/g, '').toUpperCase();

    const breadCrumbTitle = fields?.breadcrumbTitle ?? null;
    const pageTitle = fields?.title ?? null;

    type QueryMapKeys = 'Default' | 'OnlySiblings' | 'PDPBreadcrumb' | 'PLPBreadcrumb';
    const gqlQueryMap: Record<QueryMapKeys, string> = {
      Default: getBreadcrumbDefaultGql,
      OnlySiblings: getBreadcrumbOnlySiblingsGql,
      PDPBreadcrumb: getPDPBreadcrumbGql,
      PLPBreadcrumb: getBreadCrumbWithSiblingsGql,
    };

    const gqlQuery = gqlQueryMap[fieldName] || getBreadcrumbDefaultGql;

    const data = await graphQLClient.request(gqlQuery, {
      datasource: formattedItemId,
      language,
      path: parentPath,
      templateId: [templateId],
    });

    return {
      staticProps: {
        isBreadcrumbEnable,
        data,
        itemPath,
        siteName,
        itemId,
        breadCrumbTitle,
        pageTitle,
        formattedItemId,
        productName,
      },
      revalidate: 5, // In seconds
    };
  }

  throw new Error(
    `No datasource set for rendering: ${rendering.componentName}, Guid: ${
      layoutData.sitecore.route?.itemId
    }, Path: ${
      context.params?.path instanceof Array ? context.params.path.join('/') : context.params?.path
    } `
  );
};
