import { BuyerProduct, Products } from 'ordercloud-javascript-sdk';
import { useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

import { useRouter } from 'next/router';
import { ComponentProps } from 'lib/component-props';
// import ProductCard from './cards/ProductCard';

import { Configuration } from 'ordercloud-javascript-sdk';

// import FEAASProductCard from "../cards/ProductCard_feaas"

interface Fields {
  Title: Field<string>;
  SearchTerm: Field<string>;
  CategoryID: Field<string>;
}

export type ProductListsProps = ComponentProps & {
  params: { [key: string]: string };
  fields: Fields;
};

// export type ContentBlockProps = ComponentProps & {
//   fields: {
//     heading: Field<string>;
//     content: Field<string>;
//   };
// };

const ProductListsDefaultComponent = (props: ProductListsProps): JSX.Element => (
  <div className={`component featured-products ${props.params.styles}`}>
    <div className="component-content">
      <span className="is-empty-hint">{props?.fields?.Title?.value}</span>
    </div>
  </div>
);

export const ProductList = (props: ProductListsProps): JSX.Element => {
  // const containerStyles = props.params && props.params.Styles ? props.params.Styles : '';
  // const styles = `${props.params.GridParameters} ${containerStyles}`.trimEnd();
  // let backgroundImage = props.params.BackgroundImage as string;
  // let backgroundStyle: { [key: string]: string } = {};
  // if (backgroundImage) {
  //   const prefix = `${sitecoreContext.pageState !== 'normal' ? '/sitecore/shell' : ''}/-/media/`;
  //   backgroundImage = `${backgroundImage?.match(BACKGROUND_REG_EXP)?.pop()?.replace(/-/gi, '')}`;
  //   backgroundStyle = {
  //     backgroundImage: `url('${prefix}${backgroundImage}')`,
  //   };
  // }
  const { query } = useRouter();
  const [products, setProducts] = useState([] as BuyerProduct[]);

  useEffect(() => {
    //Search Term Authored in Sitecore
    // if (props.fields && props.fields.SearchTerm?.value !== '') {
    //   options.search = props.fields.SearchTerm?.value;
    //   options.searchOn = ['Name', 'Description'];
    // }
    // //Category Id Authored in Sitecore
    // if (props.fields && props.fields.CategoryID?.value !== '') {
    //   options.categoryID = props.fields.CategoryID?.value;
    // }
    // if ((query.term as string) !== undefined) {
    //   options.search = query.term as string;
    //   options.searchOn = ['Name', 'Description'];
    // }
    // if ((query.categoryid as string) !== undefined) {
    //   options.search = query.categoryid as string;
    //   options.searchOn = ['Name', 'Description', 'CategoryID'];
    // }
    // const searchPageSize = 60;
    // options.page = 1;
    // options.pageSize = searchPageSize;
    // options.sortBy = ['Name'];

    //setSearchRecords(Number(searchColumns));

    const getProducts = async () => {
      Configuration.Set({
        baseApiUrl: 'https://sandboxapi.ordercloud.io',
      });

      // const productsList = await Me.ListProducts();
      const productsList = await Products.List();
      setProducts(productsList.Items);
    };
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.term, query.categoryid]);

  return (
    <>
      <h2>Product Listing component</h2>
      {products &&
        products.map((prod, index) => {
          return (
            <div
              key={index}
              className="bg-color-border-brand py-2 mb-3 pl-mob-padding-loose-y lg:pl-desk-padding-loose-y"
            >
              <h2>{prod.Name}</h2>
              <p>{prod.Description}</p>
            </div>
          );
        })}
      {!products && <h1>no products found!</h1>}
    </>
  );

  return <ProductListsDefaultComponent {...props} />;
};
