import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface ApiRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | string;
  headers?: Record<string, string>;
  params?: Record<string, unknown>; // New field for additional params
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export async function apiRequest<T>(endpoint: string, options?: ApiRequestOptions): Promise<T> {
  const axiosConfig: AxiosRequestConfig = {
    method: options?.method,
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    params: options?.params, // Pass params to Axios config
    data: options?.data,
  };

  try {
    const response: AxiosResponse<T> = await axios(axiosConfig);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.response?.data?.Message) {
      //For Storefront middleware, if you use OrderCloud.PSP.Common.Extensions.GetBadRequestResponse, you will be able to capture error Message here
      //Based on the Error message/code, component specific action can be taken.
      throw new Error(error?.response?.data?.Message);
    }
    throw new Error(`Error fetching ${endpoint}: ${error}`);
  }
}

interface ApiResponse<T> {
  status: number;
  data: T;
}

export async function apiRequestExtended<T>(
  endpoint: string,
  options?: ApiRequestOptions
): Promise<ApiResponse<T>> {
  const axiosConfig: AxiosRequestConfig = {
    method: options?.method,
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    params: options?.params,
    data: options?.data,
  };

  try {
    const response: AxiosResponse<T> = await axios(axiosConfig);
    return {
      ...response,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.response?.data?.Message) {
      // For Storefront middleware, if you use OrderCloud.PSP.Common.Extensions.GetBadRequestResponse,
      // you will be able to capture error Message here. Based on the Error message/code, component specific action can be taken.
      throw new Error(error?.response?.data?.Message);
    }
    throw new Error(`Error fetching ${endpoint}: ${error}`);
  }
}
