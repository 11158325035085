import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import React, { MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import IconHelper from '../IconHelper';

export interface PersonalizationModalWrapperProps extends React.PropsWithChildren {
  title?: Field<string>;
  closeModalOnOverlayClick?: boolean;
  onCloseClick?: MouseEventHandler;
  onSubmitClick?: MouseEventHandler;
  showCloseButtonInModalHeader?: boolean;
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
  showModal?: boolean;
  customPopup?: boolean;
  buttonsPosition?: string;
  popupSpacing?: string;
  popupWidth?: string;
  popupBG?: string;
  additionalClassForModal?: string;
  additionalClassForPopUpModal?: string;
  showSmallPopup?: boolean;
  componentName?: string;
  isMiniCart?: boolean;
  isInEditor?: boolean;
}

const PersonalizationModalWrapper: React.FC<PersonalizationModalWrapperProps> = ({
  closeModalOnOverlayClick = false,
  onCloseClick,
  showModal,
  customPopup,
  popupSpacing,
  popupWidth,
  popupBG,
  additionalClassForPopUpModal,
  showCloseButtonInModalHeader = true,
  showSmallPopup,
  componentName,
  isMiniCart,
  isInEditor,
  ...props
}: PersonalizationModalWrapperProps) => {
  const { componentContextData } = useContext(ComponentContext);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isFirstModal, setIsFirstModal] = useState<boolean>(true);

  useEffect(() => {
    const isAncestorModal = (element: HTMLElement | null): boolean => {
      let currentElement = element;
      while (currentElement) {
        if (currentElement.classList.contains('modal')) {
          return true;
        }
        currentElement = currentElement.parentElement;
      }
      return false;
    };
    if (showModal && modalRef.current && isAncestorModal(modalRef.current.parentElement)) {
      setIsFirstModal(false);
    }
  }, [showModal]);

  const PersonalizationModalWrapperTV = tv({
    slots: {
      modalWrapperBase: [
        'fixed',
        'flex',
        isFirstModal ? 'h-screen' : 'h-full',
        isFirstModal ? 'w-screen' : 'w-full',
        'justify-center',
        'items-center',
        'top-0',
        'left-0',
        'bottom-0',
        'right-0',
        'modal', // used to detect if the modal is in another modal
      ],
      modalWrapperInner: [
        'h-full',
        'w-full',
        'bg-transparent',
        'rounded',
        'py-4',
        'px-4',
        'lg:py-10',
        'absolute',
        'overflow-auto',
        'left-0',
        'top-0',
      ],
      modalWrapperCloseIcon: [
        'absolute',
        'z-[1]',
        'right-2',
        'top-2',
        'lg:right-4',
        'lg:top-4',
        'cursor-pointer',
        'w-6',
        'h-6',
        'lg:w-10',
        'lg:h-10',
      ],
      modalWrapperCloseIconHelper: [
        'iconWrapper',
        '&>svg',
        'w-6',
        '&>svg',
        'h-6',
        '&>svg',
        'lg:w-10',
        '&>svg',
        'lg:h-10',
      ],
    },
    variants: {
      isInEditor: {
        false: {
          modalWrapperBase: ['z-[999]'],
        },
        true: {
          // z-index is one higher than the element outliner in pages editor
          modalWrapperBase: ['z-[2100000002]'],
        },
      },
    },
    defaultVariants: {
      isInEditor: false,
    },
  });
  const {
    modalWrapperBase,
    modalWrapperInner,
    modalWrapperCloseIcon,
    modalWrapperCloseIconHelper,
  } = PersonalizationModalWrapperTV({ isInEditor });

  return (
    <>
      {showModal && (
        <div
          ref={modalRef}
          className={clsx(
            modalWrapperBase(),
            `${!isMiniCart && componentName}`,
            !showSmallPopup && (popupBG && !showSmallPopup ? popupBG : 'bg-[rgba(0,0,0,0.5)]'),
            componentContextData?.openMiniCart === true ? 'bg-transparent' : '',
            { block: showModal, hidden: !showModal },
            props?.additionalClassForModal
          )}
          {...(closeModalOnOverlayClick && onCloseClick && { onClick: onCloseClick })}
        >
          <div
            className={clsx(
              modalWrapperInner(),
              showSmallPopup !== true ? 'lg:px-20' : 'lg:px-[55px]'
            )}
            onClick={(e) => e.stopPropagation()}
          >
            {showCloseButtonInModalHeader && (
              <a className={modalWrapperCloseIcon()} onClick={onCloseClick}>
                {<IconHelper className={modalWrapperCloseIconHelper()} icon={'close'} /> ?? 'X'}
              </a>
            )}
            {customPopup ? (
              <div
                className={`flex h-full w-full  items-center ${additionalClassForPopUpModal} ${
                  showSmallPopup !== true ? 'justify-center' : 'justify-end'
                }`}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={`bg-color-background-white rounded-xl  overflow-y-auto max-h-full w-full relative ${
                    popupWidth ? popupWidth : 'max-w-[526px]'
                  } ${
                    popupSpacing
                      ? popupSpacing
                      : 'lg:py-desk-padding-tight-y py-mob-padding-tight-y lg:px-desk-padding-tight-x px-mob-padding-tight-x'
                  } `}
                >
                  {props.children}
                </div>
              </div>
            ) : (
              <div className="flex h-full [&>div]:w-full " onClick={(e) => e.stopPropagation()}>
                {props.children}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default PersonalizationModalWrapper;
