//global
import React, { useEffect, useState } from 'react';
import { Field, NextImage, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { myFavoriteVariants } from './myFavoriteVariants';
//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'components/helpers/LinkHelper';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import {
  getWishlistWithAllProductDetails,
  getWishlistWithAllProductDetailsByStore,
  updateProductInWishlist,
} from 'src/redux/wishlist';
import useOcCart from 'src/hooks/useOcCart';
import useDictionary from 'src/hooks/useDictionary';
import { useBreakpoints } from 'src/utils/breakpoints';
import IconHelper from 'components/helpers/IconHelper';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'components/helpers/Constants';
import TextHelper from 'components/helpers/TextHelper';
import { BuyerProductWithXp } from 'src/redux/xp';
import { useProductPriceForUI } from 'src/hooks/useProductPrice';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import Link from 'next/link';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import Loader from 'components/Loader/Loader';
//type
export type MyFavoriteProps = ComponentProps & {
  fields: {
    title?: Field<string>;
    addToCartButtonText?: Field<string>;
    addedItemsText?: Field<string>;
    availableInStoreOnlyText?: Field<string>;
    deletedText?: Field<string>;
    lowStockLabelThreshold?: Field<number>;
    multipleUnitsMessage?: Field<string>;
    noUnitsMessage?: Field<string>;
    oneUnitMessage?: Field<string>;
    priceText?: Field<string>;
    quantityText?: Field<string>;
    removeFavoriteText?: Field<string>;
    viewStoreDetailsText?: Field<string>;
    noProductInWishlistText?: Field<string>;
  };
};
//component variants
export interface imageType {
  Position: number;
  Url: string;
}
interface qtyProps {
  [key: string]: string | number;
}
interface inventoryProps {
  [key: string]: {
    availability: 'InStock' | 'LowStock' | 'OutOfStock';
    inventoryRecordId: string;
    productInventory: number;
  };
}
//main component
const MyFavorite: React.FC<MyFavoriteProps> = ({ fields, params }) => {
  const {
    base,
    // title,
    productTitles,
    price,
    priceWrapper,
    addToCartWrapper,
    stockDetail,
    productName,
    productDetails,
    productDetailWrapper,
    noProductInWishlistText,
    removeFromWishlistButton,
    productImageWrapper,
    titlesWrapper,
    inputError,
    inputWrapper,
    input,
    inputButtonWrapper,
    inputButton,
    addToCartButton,
    topTooltipWrapper,
    tooltipContainer,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
    topToolTipDescription,
    productImgWrapper,
    imageTagWrapper,
    saleLabel,
    offerLabel,
    retailTagWrapper,
    retailTag,
    prices,
  } = myFavoriteVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { isMobile } = useBreakpoints();
  const [productListFromStore, setProductListFromStore] = useState<BuyerProductWithXp[]>([]);
  const [productList, setProductList] = useState<BuyerProductWithXp[]>([]);
  const [productQty, setProductQty] = useState<qtyProps>({});
  const [showIMapPriceTooltip, setShowIMapPriceTooltip] = useState<false | string>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string[]>([]);
  const [productAvailableStockData, setProductAvailableStockData] = useState<inventoryProps>({});
  const dispatch = useOcDispatch();
  const { addToCart, getProductAvailability } = useOcCart();
  const getWishlistData = async () => {
    try {
      if (myStoreData?.storeId) {
        const productData = await getWishlistWithAllProductDetails(
          wishlistCollection?.wishlistCollectionId
        );
        setProductList(productData?.Items);
        if (productData?.Items?.length > 0) {
          const productDataFromStore = await getWishlistWithAllProductDetailsByStore(
            productData?.Items?.map((item) => item?.ID),
            myStoreData?.storeId
          );
          if (productDataFromStore?.Items?.length > 0) {
            setProductListFromStore(productDataFromStore?.Items);
          }
          const productQty: qtyProps = {};
          let newProductAvailableStockData = { ...productAvailableStockData };
          await Promise.all(
            productData?.Items?.map(async (product: { ID: string }) => {
              productQty[product?.ID] = 1;
              if (myStoreData?.storeId && product?.ID) {
                const data = await getProductAvailability(
                  product?.ID,
                  myStoreData?.storeId,
                  myStoreData?.deliveryFee?.lowStock
                );
                if (data) {
                  if (data?.productInventory <= 0) {
                    setError((prev) => [...prev, product?.ID]);
                  }
                  newProductAvailableStockData = {
                    ...newProductAvailableStockData,
                    [product?.ID]: data,
                  };
                  setProductAvailableStockData(newProductAvailableStockData);
                } else {
                  setError((prev) => [...prev, product?.ID]);
                }
              }
            })
          );
          setProductQty(productQty);
        }
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wishlistCollection = useOcSelector((state: any) => state?.wishlist);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const { getDictionaryValue } = useDictionary();
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  useEffect(() => {
    setError([]);
    setProductAvailableStockData({});
    setIsLoading(true);
    setProductList([]);
    setProductListFromStore([]);
    if (wishlistCollection?.wishlistCollectionId) {
      getWishlistData();
    }
  }, [wishlistCollection?.wishlistCollectionId, myStoreData?.storeId]);

  const ecommerceDisabled = myStoreData?.ecommerceDisabled;

  function renderInput(productId: string): React.ReactNode {
    return (
      <div className="mt-mob-margin-base-bottom lg:mt-0">
        {error?.includes(productId) && (
          <p className={inputError()}>
            {!productAvailableStockData?.[productId]?.productInventory ||
            productAvailableStockData?.[productId]?.productInventory === 0
              ? fields?.noUnitsMessage?.value
              : productAvailableStockData?.[productId]?.productInventory === 1
              ? fields?.oneUnitMessage?.value
              : fields?.multipleUnitsMessage?.value?.replace(
                  '{unitcount}',
                  productAvailableStockData?.[productId]?.productInventory < 0
                    ? '0'
                    : productAvailableStockData?.[productId]?.productInventory?.toString() || ''
                )}
          </p>
        )}
        <div className={inputWrapper()}>
          <input
            aria-label="quantity"
            type="text"
            className={input()}
            value={productQty?.[productId]}
            onBlur={(event) => {
              if (Number.isNaN(event?.target?.value) || event?.target?.value === '') {
                setProductQty({
                  ...productQty,
                  [productId]: 1,
                });
              } else {
                setProductQty({
                  ...productQty,
                  [productId]: event?.target?.value,
                });
              }
            }}
            onChange={(event) => {
              if (Number(event?.target?.value) === 0 || event?.target?.value === '') {
                setProductQty({
                  ...productQty,
                  [productId]: '',
                });
              } else {
                setProductQty({
                  ...productQty,
                  [productId]: event?.target?.value,
                });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (productQty?.[productId] === 0 || productQty?.[productId] === '') {
                  setProductQty({
                    ...productQty,
                    [productId]: 1,
                  });
                } else {
                  setProductQty({
                    ...productQty,
                    [productId]: productQty?.[productId],
                  });
                }
              }
            }}
          />
          <div className={inputButtonWrapper()}>
            <button
              aria-label="add to cart"
              className={inputButton()}
              onClick={() => {
                const newQty = isNaN(+productQty?.[productId]) ? 1 : +productQty?.[productId] + 1;
                setProductQty({
                  ...productQty,
                  [productId]: newQty,
                });
                if (
                  newQty > productAvailableStockData?.[productId]?.productInventory &&
                  !error?.includes(productId)
                ) {
                  setError([...error, productId]);
                }
              }}
              type="button"
            >
              <IconHelper icon={'Plus'} />
            </button>
            <button
              aria-label="remove from cart"
              className={inputButton()}
              type="button"
              onClick={() => {
                const newQty = isNaN(+productQty?.[productId])
                  ? 1
                  : +productQty?.[productId] - 1 >= 1
                  ? +productQty?.[productId] - 1
                  : 1;
                setProductQty({
                  ...productQty,
                  [productId]: newQty,
                });
                if (
                  newQty <= productAvailableStockData?.[productId]?.productInventory &&
                  error?.includes(productId)
                ) {
                  setError(error.filter((id) => id !== productId));
                }
              }}
            >
              <IconHelper icon={'Minus'} />
            </button>
          </div>
        </div>
      </div>
    );
  }
  const RenderPrice = ({ productId }: { productId: string }) => {
    const productFromStore = productListFromStore?.find((product) => productId === product?.ID);

    const priceForFavorite = useProductPriceForUI(productFromStore?.ID ?? '');
    return (
      <div className={priceWrapper()}>
        {productFromStore && (
          <>
            {!priceForFavorite?.hasListandMemberPrice ? (
              <div className={prices()}>
                <p className={price({ priceType: 'isListPriceWithMemberPrice' })}>
                  {getDictionaryValue('CurrencySymbol')}
                  {priceForFavorite?.hasImapPrice
                    ? priceForFavorite?.imapPrice
                    : priceForFavorite?.hasListPrice
                    ? priceForFavorite?.listPrice
                    : ''}
                </p>
                <p className={price({ priceType: 'hasMemberPrice' })}>
                  {getDictionaryValue('ViewPriceInCartPDP')}
                </p>

                {(priceForFavorite?.hasImapPrice ||
                  (priceForFavorite?.listPrice != null &&
                    priceForFavorite?.imapPrice != null &&
                    priceForFavorite?.memberPrice != null &&
                    priceForFavorite.listPrice > priceForFavorite.imapPrice &&
                    priceForFavorite.imapPrice > priceForFavorite.memberPrice)) && (
                  <span
                    className={'relative'}
                    onMouseEnter={() => {
                      setShowIMapPriceTooltip(productId);
                    }}
                    onMouseLeave={() => {
                      setShowIMapPriceTooltip(false);
                    }}
                  >
                    <p className={price({ className: 'hasImapPrice' })}>
                      {getDictionaryValue('WhyWeDoThisPDP')}
                    </p>
                    {showIMapPriceTooltip === productId && (
                      <div className={topTooltipWrapper()}>
                        <div className={tooltipContainer()}>
                          <div className={topTooltipArrowFirstDiv()}></div>
                          <div className={topTooltipArrowSecondDiv()}></div>
                          <p className={topToolTipDescription()}>
                            {getDictionaryValue('WhyWeDoThisTooltipPDP')}
                          </p>
                        </div>
                      </div>
                    )}
                  </span>
                )}
              </div>
            ) : (
              <div className={priceWrapper()}>
                <div className={prices()}>
                  <p className={price({ priceType: 'isListPriceWithMemberPrice' })}>
                    {getDictionaryValue('CurrencySymbol')}
                    {priceForFavorite?.listPrice}
                  </p>
                  <p className={price()}>{getDictionaryValue('ListPrice')}</p>
                </div>
                <div className={prices()}>
                  <p className={price({ priceType: 'hasMemberPrice' })}>
                    {getDictionaryValue('CurrencySymbol')}
                    {priceForFavorite?.memberPrice}
                  </p>
                  <p className={price()}>{getDictionaryValue('MemberPricePDP')}</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderAddToCart = (newProduct: BuyerProductWithXp) => {
    const productFromStore = productListFromStore?.find(
      (product) => newProduct?.ID === product?.ID
    );
    return (
      ecommerceDisabled === 0 && (
        <LinkHelper
          className={addToCartButton({
            isError: error?.includes(newProduct?.ID ?? '') || !productFromStore,
          })}
          field={{ value: { text: fields?.addToCartButtonText?.value, href: '/' } }}
          onClick={async (e) => {
            e.preventDefault();
            if (!(error?.includes(newProduct?.ID ?? '') || !productFromStore)) {
              await addToCart({
                productId: newProduct?.ID ?? '',
                quantity: +productQty?.[newProduct?.ID as string],
                storeId: myStoreData?.storeId,
              });
              sendProductsPromotion({
                eventName: GTM_EVENT?.addToCart,
                data: newProduct as ProductSearchResultModelWithVariants,
                currentPage: GTMLabels?.myFavorites,
                pageItem: GTMLabels?.myFavorites,
                click_from: GTMLabels?.fromMyFavorites,
                quantity: productQty?.[newProduct?.ID as string] as number,
                fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
              });
            }
          }}
        />
      )
    );
  };
  const renderStockInformation = (productId: string) => {
    const productFromStore = productListFromStore?.find((product) => productId === product?.ID);
    const productStockData = productAvailableStockData[productId];
    return (
      <>
        {productFromStore ? (
          <p
            className={stockDetail({ stockDetail: productStockData?.availability || 'OutOfStock' })}
          >
            {getDictionaryValue(productStockData?.availability || 'OutOfStock')}
          </p>
        ) : (
          <p className={stockDetail()}>{fields?.deletedText?.value}</p>
        )}
      </>
    );
  };
  const renderTitles = () => (
    <li className={titlesWrapper()}>
      <div className="min-w-[218px]">
        <Text tag="p" className={productTitles()} field={fields?.addedItemsText} />
      </div>
      <div className="w-full"></div>
      <div className="min-w-[188px]">
        <Text tag="p" className={productTitles()} field={fields?.quantityText} />
      </div>
      <div className="min-w-[98px]">
        <Text tag="p" className={productTitles()} field={fields?.priceText} />
      </div>
    </li>
  );
  const renderProductDetails = (product: BuyerProductWithXp, index: number) => {
    const productURL = getProductDetailPath(product);

    // getting current page from GTM local Storage
    const { currentPage, pageItem } = getGTMSessionStorage();

    const sendGTM = () => {
      sendProductsPromotion({
        eventName: GTM_EVENT?.selectItem,
        data: product as ProductSearchResultModelWithVariants,
        currentPage: currentPage,
        pageItem: pageItem,
        position: index,
        isRatingShow: true,
      });
    };

    const promoTag =
      product?.xp?.PromoTag && getProductPromoTag(product?.xp?.PromoTag, myStoreData?.dataareaid);
    return (
      <>
        {wishlistCollection?.wishlist?.includes(product?.ID) && (
          <li className={productDetailWrapper()}>
            <div className={productImageWrapper()}>
              <div className={productImgWrapper()}>
                <div className={imageTagWrapper()}>
                  {product?.xp?.USMade && (
                    <NextImage
                      field={{
                        value: {
                          src: '/images/badge-flag-1.png',
                          alt: 'US Made',
                          width: '27',
                          height: '18',
                        },
                      }}
                      className="z-[2]"
                    />
                  )}
                  {product?.xp?.OnSale && (
                    <span className={saleLabel()}>{getDictionaryValue('Sale')}</span>
                  )}
                </div>
                <Link aria-label="product url" prefetch={false} href={productURL} onClick={sendGTM}>
                  <NextImage
                    field={{
                      value: {
                        src:
                          getFirstProductImage(product?.xp?.Images)?.Url ||
                          dummyProductImage?.jsonValue?.value?.src,
                        width: '100',
                        height: '150',
                        alt: product?.Name,
                      },
                    }}
                    className="max-h-[150px] w-auto min-h-[150px] h-auto object-contain"
                  />
                </Link>
                {promoTag && (
                  <div
                    className={offerLabel()}
                    onMouseEnter={() => {
                      if (
                        maxLengthForPromoTag &&
                        promoTag &&
                        promoTag?.length > maxLengthForPromoTag
                      ) {
                        setShowOfferTooltip(product?.ID);
                      }
                    }}
                    onMouseLeave={() => {
                      if (
                        maxLengthForPromoTag &&
                        promoTag &&
                        promoTag?.length > maxLengthForPromoTag
                      ) {
                        setShowOfferTooltip(false);
                      }
                    }}
                  >
                    {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
                  </div>
                )}
              </div>
              {product?.ID === showOfferTooltip && (
                <div
                  className={topTooltipWrapper({
                    className: 'bottom-[80%] lg:bottom-[80%] left-1/2 -translate-x-1/2',
                  })}
                >
                  <div className={tooltipContainer()}>
                    <div className={topTooltipArrowFirstDiv()}></div>
                    <div className={topTooltipArrowSecondDiv()}></div>
                    <p className={topToolTipDescription({ className: 'text-center' })}>
                      {promoTag}
                    </p>
                  </div>
                </div>
              )}
              <div className={retailTagWrapper()}>
                <span className={retailTag()}>
                  {product?.xp?.RetailUnit}&nbsp;{product?.xp?.RetailMeasure}
                </span>
                <IconHelper icon="vector" className={'[&>svg>*]:fill-color-brand-primary-1-base'} />
              </div>
              {fields?.removeFavoriteText?.value && (
                <LinkHelper
                  className={removeFromWishlistButton()}
                  onClick={(e) => {
                    e.preventDefault();
                    if (product?.ID) {
                      dispatch(
                        updateProductInWishlist({
                          product: product,
                          index: index,
                          storeId: myStoreData?.storeId,
                          clickFrom: GTMLabels?.myFavorites,
                        })
                      );
                    }
                  }}
                  field={{ value: { text: fields.removeFavoriteText?.value, href: '/' } }}
                />
              )}
            </div>
            <div className={productDetails()}>
              <Link
                aria-label="product url"
                className={productName()}
                prefetch={false}
                href={productURL}
                onClick={sendGTM}
              >
                {product?.Name}
              </Link>
              {isMobile && product?.ID && <RenderPrice productId={product?.ID} />}
              {product?.ID && renderStockInformation(product.ID)}
            </div>
            <div className={addToCartWrapper()}>
              {product?.ID && renderInput(product?.ID)}
              {product?.ID && renderAddToCart(product)}
            </div>
            {!isMobile && product?.ID && <RenderPrice productId={product?.ID} />}
          </li>
        )}
      </>
    );
  };

  useEffect(() => {
    if (window) {
      window?.sessionStorage?.setItem(GTMLabels?.currentPage, GTMLabels?.myFavorites);
      window?.sessionStorage?.setItem(GTMLabels?.pageItem, GTMLabels?.myFavorites);
    }

    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewItemList)
          ?.length > 0;
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (!isEventFired && productList && isGTMLoad) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.viewItemList,
          data: productList as ProductSearchResultModelWithVariants[],
          currentPage: GTMLabels?.myFavorites,
          pageItem: GTMLabels?.myFavorites,
          affiliation: myStoreData?.storeId,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [productList]);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Style ?? '' })}>
      <div>
        <ul className="w-full">
          {!isMobile && renderTitles()}
          {isLoading ? (
            <li className={productDetailWrapper({ className: 'flex justify-center w-full' })}>
              <Loader />
            </li>
          ) : wishlistCollection?.wishlist?.length > 0 ? (
            productList?.map((product, index) => renderProductDetails(product, index))
          ) : (
            <TextHelper
              tag="p"
              className={noProductInWishlistText()}
              field={fields?.noProductInWishlistText}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<MyFavoriteProps>(MyFavorite);
