import { SortSelect } from '@sitecore-search/ui';

import styles from './styles.module.css';
import { SearchResponseSortChoice } from '@sitecore-search/react';
import { SearchResultsWidget } from '@sitecore-search/react/dist/esm/types';
import IconHelper from 'components/helpers/IconHelper';
import searchResultsTailwind from './SearchResultsTailwind';
import clsx from 'clsx';

export interface PspSortSelectProps {
  actions: SearchResultsWidget['ActionProps'];
  sortChoices: SearchResponseSortChoice[];
  sortType: string;
}

const { sortSelectParent, sortSelectTrigger, sortSelectContent, sortIcon, sortOption } =
  searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

export const PspSortSelect = ({ actions, sortChoices, sortType }: PspSortSelectProps) => {
  const selectedSortIndex = sortChoices.findIndex((s) => s.name === sortType);
  return (
    <div className={sortSelectParent()}>
      <SortSelect.Root
        defaultValue={sortChoices[selectedSortIndex]?.name}
        onValueChange={actions.onSortChange}
      >
        <SortSelect.Trigger className={sortSelectTrigger()}>
          <SortSelect.SelectValue className={styles['sitecore-sort-select-value']}>
            Sort by: {selectedSortIndex > -1 ? sortChoices[selectedSortIndex].label : ''}
          </SortSelect.SelectValue>
          {/* <SortSelect.Icon className={styles['sitecore-sort-select-icon']} /> */}
          <IconHelper icon={'Caret'} className={sortIcon()} />
        </SortSelect.Trigger>
        <SortSelect.Content className={clsx(sortSelectContent())}>
          <SortSelect.Viewport className={styles['sitecore-sort-select-viewport']}>
            {sortChoices.map((option) => (
              <SortSelect.Option value={option} key={option.name} className={sortOption()}>
                <SortSelect.OptionText className={styles['sitecore-sort-select-option-text']}>
                  {option.label}
                </SortSelect.OptionText>
              </SortSelect.Option>
            ))}
          </SortSelect.Viewport>
        </SortSelect.Content>
      </SortSelect.Root>
    </div>
  );
};
