import { SearchResultsStoreState } from '@sitecore-search/react';
import { useOcSelector } from 'src/redux/ocStore';
import searchResultsTailwind from './SearchResultsTailwind';

export interface PspQuerySummaryProps {
  state: SearchResultsStoreState;
  totalItems: number;
  productsLength: number;
}

export const PspQuerySummary = ({ state, totalItems, productsLength }: PspQuerySummaryProps) => {
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const { querySummary } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  if (!totalItems) {
    return <></>;
  }
  const { page, itemsPerPage } = state;
  return (
    // <div className={styles['sitecore-query-summary']}>
    //   Results <small>{itemsPerPage * (page - 1) + 1} - {itemsPerPage * (page - 1)</small> + productsLength} of{' '}
    //   {totalItems} in the {storeData?.name as string} Store
    // </div>
    // <div className={styles['sitecore-query-summary']}>
    <div className={querySummary()}>
      Results{' '}
      <span>
        {itemsPerPage * (page - 1) + 1} - {itemsPerPage * (page - 1) + productsLength}
      </span>{' '}
      of{' '}
      <span>
        {totalItems}
        {/* 10000 is the max results, so show 10000+ */}
        {totalItems === 10000 ? '+' : ''}
      </span>{' '}
      in the <span>{storeData?.storeName as string} </span>
      Store
    </div>
  );
};
