const preconnectUrls = [
  'https://p.typekit.net',
  'https://use.typekit.net',
  'https://useast-sandbox.ordercloud.io',
  'https://buyer-qa.vercel.app', // remove later if QA is not needed
  'https://buyer-prod.vercel.app',
  'https://oc-storefront-middleware-qa.azurewebsites.net', // remove later if QA is not needed
  'https://ordercloud-storefront-middleware-prod.azurewebsites.net',

  // include more resources here if detected
];

export default preconnectUrls;
