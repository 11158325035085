// Tailwind Import
import myCartItems from './MyItemsTailwindVariant';

import { ComponentProps } from '@sitecore-feaas/clientside';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { pDPComponentVariants } from 'components/ProductDetail/ProductDetail';
import {
  FulfillmentType,
  getProductDetailPath,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'components/helpers/Constants';
import LinkHelper from 'components/helpers/LinkHelper';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useState } from 'react';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import useOcCart from 'src/hooks/useOcCart';
import { useLineItemPriceForUI } from 'src/hooks/useLineItemPriceForUI';
import { useOcSelector } from 'src/redux/ocStore';
import { BuyerProductWithXp, LineItemWithXp } from 'src/redux/xp';
import { myFavoriteVariants } from 'components/MyFavorite/myFavoriteVariants';
import Link from 'next/link';
import { OrderPromotion } from 'ordercloud-javascript-sdk';
import { currencyUsdFormatter } from 'lib/utils/formatters';

export type MyItems = React.InputHTMLAttributes<HTMLInputElement> &
  PSP.Sitecore.templates.PetSuppliesPlus.MyItems.Fields.MyItems &
  ComponentProps;

const MyItems = ({ fields }: MyItems): JSX.Element => {
  // Tailwind Variant
  const {
    base,
    title,
    titleWrapper,
    linkWithUnderline,
    // cardWrapper,
  } = myCartItems({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const empty = <div className="myitems" hidden></div>;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();
  //const myLineItems = useOcSelector((state) => state?.ocCurrentOrder?.lineItems);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const promotions = useOcSelector((state) => state?.ocCurrentOrder?.orderPromotions);

  if (
    !myStoreData ||
    Object.keys(myStoreData).length === 0 ||
    !cart ||
    cart.xp?.Fulfillment === FulfillmentType.DFS
  ) {
    return empty;
  }

  return (
    <div className={base()}>
      <div className={titleWrapper()}>
        {/* Title */}
        {fields?.title?.value && <div className={title()}>{fields?.title?.value}</div>}

        {/* Edit Cart */}
        {fields?.link?.value?.href && (
          <div className={linkWithUnderline()}>
            <LinkHelper field={fields?.link} />
          </div>
        )}
      </div>
      {/* <div className={cardWrapper()}> */}
      {productlineitems?.length &&
        productlineitems.map((line) => (
          <MyLineItem key={line.ID} line={line} promotions={promotions ?? []} fields={fields} />
        ))}
      {/* </div> */}
    </div>
  );
};

function MyLineItem({
  line,
  promotions,
  fields,
}: {
  line: LineItemWithXp;
  promotions: OrderPromotion[];
  fields: MyItems['fields'];
}) {
  const {
    cartTitle,
    itemWrapper,
    cardInfoWrapper,
    cardTitleQtyContainer,
    // cartTitle,
    cartTitleRed,
    hideInMobile,
    centerInMobile,
    itemImages,
    productWeight,
    priceWrapper,
    productImageWrapper,
    // cardWrapper,
  } = myCartItems({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { offerLabel } = myFavoriteVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = pDPComponentVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const priceForCheckout = useLineItemPriceForUI(line, promotions);
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;
  const isAnonymous = useOcSelector((state) => state.ocAuth.isAnonymous);
  const imageData = getFirstProductImage(line?.Product?.xp?.Images);
  const promoTag =
    line?.Product?.xp?.PromoTag &&
    getProductPromoTag(line?.Product?.xp?.PromoTag, myStoreData?.dataareaid);

  return (
    <div className="relative">
      {showOfferTooltip == line.ProductID && (
        <div
          className={topTooltipWrapper({
            className: '!bottom-[60%] !left-[15%] lg:!left-[11%] -translate-x-1/2',
          })}
        >
          <div className={tooltipContainer()}>
            <div className={topTooltipArrowFirstDiv()}></div>
            <div className={topTooltipArrowSecondDiv()}></div>
            <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
          </div>
        </div>
      )}
      <div
        className={
          'lines relative overflow-hidden ' +
          showOfferTooltip +
          clsx(centerInMobile(), itemWrapper())
        }
      >
        {promoTag && (
          <div
            className={offerLabel({})}
            onMouseEnter={() => {
              if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                setShowOfferTooltip(line.ProductID);
              }
            }}
            onMouseLeave={() => {
              if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                setShowOfferTooltip(false);
              }
            }}
          >
            {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
          </div>
        )}
        <div className={productImageWrapper()}>
          <>
            <div className="product-weight-container">
              <p className={productWeight()}>
                {line?.Product?.xp?.RetailUnit} {line?.Product?.xp?.RetailMeasure}
              </p>
            </div>
            <Link
              aria-label="product image"
              prefetch={false}
              href={getProductDetailPath(line?.Product as BuyerProductWithXp)}
            >
              <NextImage
                className={itemImages()}
                field={{
                  value: {
                    alt: imageData?.Name,
                    src: imageData?.Url,
                  },
                }}
                alt={imageData?.Name}
                width="104"
                height="104"
              />
            </Link>
          </>
        </div>
        <div className={clsx(centerInMobile(), cardInfoWrapper())}>
          <div className={cardTitleQtyContainer()}>
            <div className={clsx(hideInMobile(), cartTitle())}>{line?.Product?.Name}</div>
            <div className={cartTitle()}>
              {fields?.quantity?.value}: {line?.Quantity}
            </div>
          </div>
          <div className={priceWrapper()}>
            {/* List Price */}
            {priceForCheckout?.lineItemMemberPrice ? (
              <>
                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                  <div className={clsx(hideInMobile(), cartTitle())}>
                    {fields?.listTotal?.value}
                  </div>
                  <div className={clsx(cartTitle(), !isAnonymous && 'line-through')}>
                    {currencyUsdFormatter.format(priceForCheckout?.lineItemListPrice ?? 0)}
                  </div>
                </div>
                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                  <div className={clsx(hideInMobile(), cartTitle())}>
                    {fields?.memberTotal?.value}
                  </div>
                  <div className={clsx(cartTitle(), cartTitleRed(), isAnonymous && 'line-through')}>
                    {currencyUsdFormatter.format(priceForCheckout?.lineItemMemberPrice ?? 0)}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                <div className={clsx(hideInMobile(), cartTitle())}>{fields?.listTotal?.value}</div>
                <div className={clsx(cartTitle())}>
                  {currencyUsdFormatter.format(priceForCheckout?.lineItemListPrice ?? 0)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyItems;
