const jumpMenuQuery = `query {
  item(path: "{1D102508-F01B-4895-AA95-0F16C004BE22}", language: "en") {
    field(name: "Jump Menu Label") {
      name
      jsonValue
    }
    children(includeTemplateIDs: ["{F32E5D39-27A7-437A-9D9C-A534DC168DBF}"]) {
      results {
        
        JumpMenuTargetSectionId:field(name:"jumpMenuTargetSectionId")
        {
          name
          jsonValue
        }
        JumpMenuItemName:field(name:"jumpMenuItemName")
        {
          name
          jsonValue
        }
      }
    }
  }
}`;

export { jumpMenuQuery };
