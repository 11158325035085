/* eslint-disable */
import { useEffect } from 'react';
import { tv } from 'tailwind-variants';
const BVRatingSummaryVariaints = tv(
  {
    slots: {
      base: ['w-full'],
    },

    variants: {
      device: {
        mobile: {
          base: [''],
        },
        desktop: {
          base: [''],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
const BVRatingSummary = ({ bvProductId }: { bvProductId: string }) => {
  const { base } = BVRatingSummaryVariaints({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  useEffect(() => {}, []);
  return <div data-bv-show="reviews" data-bv-product-id={bvProductId} className={base()} />;
};
export default BVRatingSummary;
