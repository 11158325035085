//global
import React from 'react';
import { NextImage, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';

export type DividerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Divider.Fields.Divider;

const dividerVariaints = tv(
  {
    slots: {
      base: ['divider', 'h-full', ' w-full', 'flex'],
      wrapper: 'container mx-auto',
      mobileIcon: [''],
      desktopIcon: [''],
    },
    compoundSlots: [{ slots: ['desktopIcon', 'mobileIcon'], class: ['w-full'] }],
    variants: {
      device: {
        mobile: {
          base: ['pt-[16.8px]', 'pb-[13px]'],
          mobileIcon: ['flex justify-center'],
          desktopIcon: ['hidden'],
        },
        desktop: {
          base: ['pt-[15px]', 'pb-[3px]'],
          mobileIcon: ['hidden'],
          desktopIcon: ['flex justify-center'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md'],
  }
);
const Divider: React.FC<DividerProps> = ({ fields, params }) => {
  const { base, mobileIcon, desktopIcon, wrapper } = dividerVariaints({
    device: { initial: 'mobile', md: 'desktop' },
  });
  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <div className={wrapper()}>
        {fields?.desktopIcon && <NextImage field={fields?.desktopIcon} className={desktopIcon()} />}
        {fields?.mobileIcon && <NextImage field={fields?.mobileIcon} className={mobileIcon()} />}
      </div>
    </div>
  );
};

export default withDatasourceCheck()<DividerProps>(Divider);
