import { useState, useEffect, useRef } from 'react';

const useIntersectionObserver = <T extends Element>(
  options: IntersectionObserverInit
): { ref: React.RefObject<T>; isVisible: boolean } => {
  const ref = useRef<T>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const element = ref.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [options]);

  return { ref, isVisible };
};

export default useIntersectionObserver;
