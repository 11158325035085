import ReactPlayer from 'react-player';

import { Text, ImageField, LinkField, Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

import { tv } from 'tailwind-variants';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import clsx from 'clsx';
import LinkHelper from 'components/helpers/LinkHelper';
import ModalWrapper from 'components/helpers/ModalWrapper';
import React, { useEffect, useRef, useState } from 'react';
import IconHelper from 'components/helpers/IconHelper';
import useExperienceEditor from '../../hooks/useExperienceEditor';
import { getPromotionObject, trackObjectForPromotion } from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import { GTM_EVENT } from 'components/helpers/Constants';
import ImageHelper from 'components/helpers/ImageHelper';

export type FeaturedTilesProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.FeaturedTiles.Fields.FeaturedTile & {
    children?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
  };

interface FeaturedImageProps {
  imageField?: ImageField;
  SelectedTheme?: string;
  tileVideo?: boolean | undefined;
  handleModalOpen?: () => void;
}
interface ContentBlockProps {
  titleField?: Field<string>;
  subtitleField?: Field<string>;
  descriptionField?: Field<string>;
  linkField?: LinkField;
  SelectedTheme?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotionObject?: any;
  storeId?: string;
  componentName?: string;
}

const FeaturedTilesClass = tv(
  {
    slots: {
      base: 'w-full featuredTiles ',
      fullWrapper: '',
      wrapper: 'container mx-auto',
      contentAndImage: 'flex-wrap	flex',
      image:
        'order-1 lg:order-none flex relative z-[0] [&>.sc-image-wrapper]:flex [&>.sc-image-wrapper]:h-full',
      contentBlockWrapper: 'order-2 lg:order-none w-full',
      contentBlock: ' grid gap-y-3 w-full px-desk-padding-micro-x',
      heading: 'font-heading-desk-xLarge-bol text-center',
      title:
        'text-heading-desk-large-bold leading-heading-desk-large-bold font-heading-desk-large-bold',
      closeIcon: '',
      content: '',
      link: 'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold hover:no-underline',
      playIcon: ' [&>svg>*]:fill-color-brand-primary-1-base inline-block',
      playButton: 'absolute top-1/2 left-1/2 -translate-x-1/2	-translate-y-1/2',
      playerWrapperModel: 'relative pt-[48%]',
      reactPlayer: 'absolute top-0 left-0',
    },
    variants: {
      size: {
        mobile: {
          base: '',
          heading:
            'py-mob-padding-tight-y text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
          fullWrapper: 'py-mob-padding-tight-y',
          title: '',
          image: 'w-full',
          contentBlockWrapper: '',
          contentBlock: '',
          playIcon: '[&>svg]:w-[50px]',
          link: 'text-color-text-brand-1 hover:text-color-brand-tertiary-2-base',
        },
        desktop: {
          base: '',
          heading:
            'py-desk-padding-tight-y text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
          fullWrapper: 'py-desk-padding-tight-y',
          title: '',
          contentAndImage: 'w-full',
          image: '',
          contentBlockWrapper: ' ',
          contentBlock: '',
          content: 'text-heading-mob-small-regular mb-desk-margin-micro-bottom',
          link: 'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x text-color-text-white hover:text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark rounded-desk-global-radius-small',
          playIcon: '[&>svg]:w-[81px]',
        },
      },

      backgroundTheme: {
        Dark: {
          contentAndImage: '',
          contentBlockWrapper:
            'w-full lg:w-[630px] lg:bg-color-scale-8-dark text-color-text-dark lg:text-color-text-white',
          image: 'w-1/2',
        },
        White: {
          fullWrapper: 'bg-color-background-white text-color-text-dark',
          contentBlockWrapper: 'w-full lg:w-[58.6%]',
          image: 'w-[41.4%] ',
        },
        Gray: {
          fullWrapper: 'text-color-text-dark ',
          contentAndImage:
            'bg-color-background-light lg:p-desk-padding-tight-x py-mob-padding-micro-y px-mob-padding-none',
          contentBlockWrapper:
            'lg:w-[646px] items-center	flex lg:py-desk-padding-tight-x py-mob-padding-tight-x',
          image: 'w-[44.2%] ',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);

const {
  base,
  fullWrapper,
  wrapper,
  contentAndImage,
  image,
  contentBlock,
  contentBlockWrapper,
  heading,
  content,
  title,
  link,
  playIcon,
  playButton,
  playerWrapperModel,
  reactPlayer,
} = FeaturedTilesClass({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
  backgroundTheme: {
    initial: 'Dark',
  },
});
// Main FeaturedTiles component

const FeaturedTiles: React.FC<FeaturedTilesProps> = ({ fields, params }) => {
  const SelectedTheme = (params?.backgroundTheme ?? 'Dark') as 'Dark' | 'White' | 'Gray';

  const tileVideoValue = fields?.tileVideo?.value as boolean | undefined;

  const [showModal, setShowModal] = useState<boolean>(false);
  // const reactPlayerRef = useRef<ReactPlayerProps>(null);
  const reactPlayerRef = useRef<ReactPlayer>(null);

  const handleModalClose = () => {
    setShowModal(false);
    document.body.classList.remove('overflow-hidden');
    if (reactPlayerRef.current) {
      const internalPlayer = reactPlayerRef.current.getInternalPlayer();
      if (internalPlayer && typeof internalPlayer.pauseVideo === 'function') {
        internalPlayer.pauseVideo();
      }
    }
  };

  const handleModalOpen = () => {
    setShowModal(true);
    document.body.classList.add('overflow-hidden');
  };

  //Getting current Store ID
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  // Promotion GTM data Object
  const gtmPromotion = getPromotionObject(fields, deviceName);
  //Promotion GTM data push on load
  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);
  if (fields === null || fields === undefined) return <></>;

  return (
    <div
      className={base({ className: params?.Styles ?? '' })}
      data-component={'components/FeaturedTile/FeaturedTile'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
    >
      {fields?.heading?.value && (
        <div className={wrapper()}>
          <RichText className={heading({ className: 'rte' })} field={fields?.heading} />
        </div>
      )}
      <div className={fullWrapper({ backgroundTheme: SelectedTheme })}>
        <div className={wrapper()}>
          <div className={contentAndImage({ backgroundTheme: SelectedTheme })}>
            {/* {children} */}
            {params.FieldNames !== 'ImageOnRight' && (
              <FeaturedImage
                imageField={fields?.image}
                tileVideo={tileVideoValue}
                SelectedTheme={SelectedTheme}
                handleModalOpen={handleModalOpen}
              />
            )}
            <ContentBlock
              titleField={fields?.title}
              subtitleField={fields?.subtitle}
              descriptionField={fields?.description}
              linkField={fields?.link}
              SelectedTheme={SelectedTheme}
              componentName={fields?.componentName?.value}
              promotionObject={gtmPromotion}
              storeId={myStoreId}
              // params={params}
              imageOnRight={params.FieldNames === 'ImageOnRight'}
            />
            {params.FieldNames == 'ImageOnRight' && (
              <FeaturedImage
                imageField={fields?.image}
                tileVideo={tileVideoValue}
                SelectedTheme={SelectedTheme}
                handleModalOpen={handleModalOpen}
              />
            )}
          </div>
          {fields?.tileVideo?.value && (
            <>
              <ModalWrapper
                showCloseButtonInModalHeader={true}
                closeModalOnOverlayClick={true}
                onCloseClick={handleModalClose}
                showModal={showModal}
              >
                <div className={playerWrapperModel()}>
                  <ReactPlayer
                    className={reactPlayer()}
                    width="100%"
                    height="100%"
                    url={fields?.tileVideo?.value}
                    ref={reactPlayerRef}
                    playing={showModal}
                    controls={true}
                  />
                </div>
              </ModalWrapper>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const FeaturedImage: React.FC<FeaturedImageProps> = ({
  imageField,
  SelectedTheme,
  tileVideo,
  handleModalOpen,
}) => {
  const isEE = useExperienceEditor();
  const { isDesktop } = useBreakpoints();

  if (!imageField) return null; // Return null if imageField is not provided

  return (
    imageField && (
      <div
        className={clsx(image(), {
          'lg:w-fit w-full [&>img]:w-full lg:[&>img]:max-w-[450px] [&>img]:h-auto px-mob-margin-loose-right lg:px-desk-margin-loose-right lg:flex place-self-center justify-center':
            SelectedTheme === 'White',
          ' w-full lg:w-fit [&>img]:w-full lg:[&>img]:max-w-[450px] [&>img]:h-auto px-mob-margin-loose-right lg:px-desk-margin-loose-right lg:flex place-self-center justify-center':
            SelectedTheme === 'Gray',
          'lg:w-fit w-full [&>img]:w-full lg:[&>img]:max-w-[650px] [&>img]:h-auto [&>img]:object-cover':
            SelectedTheme === 'Dark',
        })}
        {...(isDesktop && {
          style: {
            minWidth: `calc(100% - (100% * 670 /1200))`,
            width: `calc(100% - (100% * 670 /1200))`,
          },
        })}
      >
        <ImageHelper field={imageField} />
        {tileVideo && !isEE && (
          <button aria-label="play" onClick={handleModalOpen} className={playButton()}>
            <IconHelper className={playIcon()} icon={'icon-play'} />
          </button>
        )}
      </div>
    )
  );
};
const ContentBlock: React.FC<ContentBlockProps & { imageOnRight: boolean }> = ({
  titleField,
  subtitleField,
  descriptionField,
  linkField,
  SelectedTheme,
  imageOnRight,
  componentName,
  promotionObject,
  storeId,
}) => {
  const { isDesktop } = useBreakpoints();

  return (
    <div
      className={clsx(
        contentBlockWrapper({
          backgroundTheme: SelectedTheme as 'Dark' | 'White' | 'Gray',
        }),
        {
          'lg:px-desk-padding-base-x px-mob-padding-none lg:py-desk-padding-tight-y py-mob-padding-tight-y':
            SelectedTheme === 'Dark',
        },
        {
          'lg:px-desk-padding-base-x px-mob-padding-none lg:py-desk-padding-tight-y py-mob-padding-tight-y':
            SelectedTheme === 'White' && !imageOnRight,
        },
        {
          'lg:pr-desk-padding-base-x pr-mob-padding-none lg:pl-desk-padding-micro-x pl-mob-padding-none lg:py-desk-padding-tight-y py-mob-padding-tight-y':
            SelectedTheme === 'White' && imageOnRight,
        },
        {
          'lg:px-desk-padding-tight-x px-mob-padding-none':
            SelectedTheme === 'Gray' && !imageOnRight,
        },
        {
          'lg:px-desk-padding-tight-x px-mob-padding-tight-x ':
            SelectedTheme === 'Gray' && imageOnRight,
        }
      )}
      {...(isDesktop && { style: { width: `calc(100% * 670 /1200)` } })}
    >
      <div className={contentBlock()}>
        <Text tag="h3" className={title()} field={titleField} />
        <RichText className={content({ className: 'rte' })} field={subtitleField} />
        <RichText className={content({ className: 'rte' })} field={descriptionField} />

        {linkField && linkField?.value?.text && (
          <div className="flex">
            <LinkHelper
              field={linkField}
              className={link()}
              onClick={() => {
                if (componentName !== '')
                  trackObjectForPromotion(
                    GTM_EVENT?.selectPromotion,
                    storeId as string,
                    promotionObject
                  );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const Default = (props: FeaturedTilesProps): JSX.Element => {
  return <FeaturedTiles {...props} />;
};

export const ImageOnLeft = (props: FeaturedTilesProps): JSX.Element => {
  return <FeaturedTiles {...props} />;
};
export const ImageOnRight = (props: FeaturedTilesProps): JSX.Element => {
  return <FeaturedTiles {...props} />;
};
