//global
import React, { useLayoutEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
//model
import { PSP } from '../../../models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import RichTextHelper from 'components/helpers/RichTextHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import ImageHelper from 'components/helpers/ImageHelper';

export type BannerCtaProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.BannerCtaPanel.Fields.BannerCtapanel;
//component variants
const bannerCtaVariants = tv(
  {
    slots: {
      base: ['relative', 'bannerCta', 'w-full'],
      innerDiv: [
        'container',
        'mx-auto',
        'bg-cover',
        'bg-center',
        'text-center',
        'text-color-text-white',
        'text-primary',
      ],
      imageWrapper: [],
      imgWrapper: [
        '*:object-cover',
        '*:absolute',
        '*:h-full',
        '*:w-full',
        '*:left-0',
        '*:top-0',
        '*:max-h-[400px]',
      ],
      title: [
        'w-full',
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
      description: [
        'w-full',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      contentWrapper: [],
      ctaLink: [
        'z-10',
        'bg-color-brand-primary-1-base',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'rounded-[0.25rem]',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
      ],
      LinkWrapper: ['flex'],
      content: ['z-[1]', 'w-full'],
    },
    compoundSlots: [
      {
        slots: ['contentWrapper', 'innerDiv', 'content'],
        class: ['flex', 'flex-col', 'justify-center', 'items-center'],
      },
    ],
    variants: {
      colors: {
        black: { description: ['text-color-scale-9-black'], title: ['text-color-scale-9-black'] },
        default: { description: ['text-white'], title: ['text-white'] },
      },
      device: {
        mobile: {
          innerDiv: ['py-mob-padding-tight-y'],
          contentWrapper: ['gap-2'],
          content: ['gap-mob-margin-tight-bottom'],
          ctaLink: [
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
          ],
        },
        desktop: {
          innerDiv: ['py-desk-padding-tight-y'],
          contentWrapper: ['gap-desk-margin-base-bottom'],
          content: ['gap-desk-margin-tight-bottom'],
          ctaLink: [
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
        },
      },
      bannerSize: {
        small: {
          innerDiv: ['h-auto'],
        },
        big: {
          innerDiv: ['h-[400px]'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component

const BannerCta: React.FC<BannerCtaProps> = ({ fields, params }) => {
  //state
  const isEE = useExperienceEditor();
  const [screen, setScreen] = useState<number>(1023);

  // variant
  const {
    base,
    innerDiv,
    description,
    contentWrapper,
    ctaLink,
    LinkWrapper,
    content,
    imgWrapper,
    title,
  } = bannerCtaVariants({
    colors: params?.FieldNames === 'BlackText' ? 'black' : 'default',
    device: { initial: 'mobile', lg: 'desktop' },
    bannerSize: params?.showCTALoader === '1' ? 'small' : 'big',
  });

  //handle mobile
  useLayoutEffect(() => {
    setScreen(window?.innerWidth);
    const handleResize = () => {
      setScreen(window?.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screen]);

  //return empty fragment if field value is null or undefined
  if (fields === undefined || fields === null) return <></>;

  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <div className={innerDiv()}>
        <div className={contentWrapper()}>
          <div className={imgWrapper()}>
            <ImageHelper field={fields?.desktopBackgroundImage} />
          </div>

          <div className={content()}>
            <div className={params?.showCTALoader === '1' ? title() : ''}>
              <RichTextHelper field={fields?.title} />
            </div>
            <RichTextHelper field={fields?.description} className={description()} />
          </div>
          <div className={LinkWrapper()}>
            {isEE
              ? fields?.link && <LinkHelper field={fields?.link} className={ctaLink()} />
              : fields?.link?.value?.href?.length &&
                fields?.link?.value?.href?.length > 0 && (
                  <LinkHelper field={fields?.link} className={ctaLink()} />
                )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Default = (props: BannerCtaProps): JSX.Element => {
  return <BannerCta {...props} />;
};
export const WhiteText = (props: BannerCtaProps): JSX.Element => {
  return <BannerCta {...props} />;
};
export const BlackText = (props: BannerCtaProps): JSX.Element => {
  return <BannerCta {...props} />;
};
