//global
import React, { useEffect, useState } from 'react';
import { LinkField, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import { Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';

//local

//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'components/helpers/LinkHelper';
import { useOcSelector } from 'src/redux/ocStore';
import { currentPath, redirectTo } from 'components/helpers/Constants';
import { usePathname } from 'next/navigation';

//type
export type LoginContentProps = ComponentProps & {
  fields?: {
    data?: {
      data?: {
        title?: Field<string>;
        shortDescription?: Field<string>;
        successRedirectUrl?: { jsonValue: LinkField };
        cancelButtonUrl?: { jsonValue: LinkField };
        submitButtonText?: Field<string>;
        cancelButtonText?: Field<string>;
      };
    };
  };
};
//component variants
const loginContentVariants = tv(
  {
    slots: {
      base: 'LoginContent flex flex-col',
      title: '',
      headingWrapper: 'flex flex-col gap-desk-margin-tight-bottom',
      description: 'text-body-large-regular leading-body-large-regular font-body-large-regular',
      buttonWithOutline:
        'flex hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-brand-1 justify-center items-center bg-color-background-white border-[2px] border-color-brand-primary-1-base outline-none hover:bg-color-brand-primary-1-dark hover:text-color-text-white focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
      button: [
        'flex hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-white justify-center items-center bg-color-brand-primary-1-base outline-none',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          base: 'gap-desk-margin-base-bottom',
          title:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          headingWrapper: 'gap-desk-margin-tight-bottom',
          button:
            'w-fit rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
          buttonWithOutline:
            'w-fit rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
        },
        mobile: {
          base: 'gap-mob-margin-base-bottom',
          title:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          headingWrapper: 'gap-mob-margin-tight-bottom',
          button:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
          buttonWithOutline:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
        },
      },
      isDisabled: {
        true: {
          button: [
            'cursor-not-allowed',
            'bg-color-brand-primary-1-disabled',
            'hover:bg-color-brand-primary-1-disabled',
          ],
        },
        false: {
          button: ['pointer'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const LoginContent: React.FC<LoginContentProps> = ({ fields, params }) => {
  const { base, headingWrapper, title, button, buttonWithOutline } = loginContentVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const isRegistrationEnable = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.enableAccountCreation?.jsonValue?.value
  );
  const [appendCheckoutUrl, setAppendCheckoutUrl] = useState(false);

  const pathName = usePathname();

  useEffect(() => {
    // Check if current page is on '/logincheckout' page.
    if (pathName == currentPath?.isLoginCheckout) {
      setAppendCheckoutUrl(true);
    }
  }, []);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={(params?.Styles ?? '') + base()}>
      <div className={headingWrapper()}>
        {fields?.data?.data?.title && (
          <Text tag="p" className={title()} field={fields?.data?.data?.title} />
        )}
        {fields?.data?.data?.shortDescription && (
          <RichText tag="p" field={fields?.data?.data?.shortDescription} />
        )}
      </div>
      {/* Registration CTA */}
      {fields?.data?.data?.successRedirectUrl?.jsonValue?.value && (
        <LinkHelper
          className={button({ isDisabled: !(isRegistrationEnable === 'true') })}
          field={{
            value: {
              ...fields?.data?.data?.successRedirectUrl?.jsonValue?.value,
              text: fields?.data?.data?.submitButtonText?.value,
              querystring: appendCheckoutUrl ? redirectTo?.checkout : '',
              href:
                isRegistrationEnable === 'true'
                  ? fields?.data?.data?.successRedirectUrl?.jsonValue?.value?.href
                  : '#',
            },
          }}
        />
      )}
      {/* Checkout as Guest CTA */}
      {selectedStore?.checkoutDisable == 0 &&
        fields?.data?.data?.cancelButtonUrl?.jsonValue?.value && (
          <LinkHelper
            className={buttonWithOutline()}
            field={{
              value: {
                ...fields?.data?.data?.cancelButtonUrl?.jsonValue?.value,
                text: fields?.data?.data?.cancelButtonText?.value,
              },
            }}
          />
        )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<LoginContentProps>(LoginContent);
