import { SitecoreTemplateId } from 'lib/constants';
import { edgeSafeGraphQLQuery } from './edgeSafeGraphQLQuery';

const appRootQuery = /* GraphQL */ `
  query AppRootQuery(
    $jssAppTemplateId: String!
    $jssSettingsTemplateId: String!
    $jssDataTemplateId: String!
    $siteName: String!
    $language: String!
  ) {
    layout(site: $siteName, routePath: "/", language: $language) {
      homePage: item {
        id
        rootItem: ancestors(includeTemplateIDs: [$jssAppTemplateId]) {
          id
          data: children(includeTemplateIDs: [$jssDataTemplateId]) {
            results {
              id
            }
          }
          settings: children(includeTemplateIDs: [$jssSettingsTemplateId]) {
            results {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * The schema of data returned in response to an app root query request
 */
type AppRootQueryResult = {
  layout?: {
    homePage?: {
      id: string;
      rootItem?: {
        id: string;
        data: {
          results: [
            {
              id: string;
            }
          ];
        };
        settings: {
          results: [
            {
              id: string;
            }
          ];
        };
      }[];
    };
  };
};

export async function getAppRoot(siteName: string, language: string) {
  const siteRoots = await getSiteRootIds(siteName, language);
  return siteRoots.siteRootId;
}

export interface SiteRootIds {
  siteRootId?: string;
  homeId?: string;
  dataFolderId?: string;
  settingsItemId?: string;
}

export async function getSiteRootIds(siteName: string, language: string): Promise<SiteRootIds> {
  const data = await edgeSafeGraphQLQuery<AppRootQueryResult>(appRootQuery, {
    jssAppTemplateId: SitecoreTemplateId.JssApp,
    jssDataTemplateId: SitecoreTemplateId.JssData,
    jssSettingsTemplateId: SitecoreTemplateId.JssSettings,
    siteName,
    language,
  });

  const root = data?.layout?.homePage?.rootItem?.[0];
  return {
    homeId: data?.layout?.homePage?.id,
    siteRootId: root?.id,
    dataFolderId: root?.data.results[0].id,
    settingsItemId: root?.settings.results[0].id,
  };
}
