import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import DropdownField from 'components/helpers/Form/DropdownField';
import PhoneField from 'components/helpers/Form/PhoneField';
import StateDropdown from 'components/helpers/Form/StateDropdownField';
import TextAreaField from 'components/helpers/Form/TextAreaField';
import TextField from 'components/helpers/Form/TextField';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { ComponentProps } from 'lib/component-props';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import useDictionary from 'src/hooks/useDictionary';
import { useSiteName } from 'src/hooks/useSiteName';
import { useOcSelector } from 'src/redux/ocStore';
import { apiRequest } from 'src/utils/apiWrapper';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import { tv } from 'tailwind-variants';

export type FormPropsType = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title?: Field<string>;
          shortDescription?: Field<string>;
          submitButtonText?: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          submitEndpoint: {
            jsonValue: Field<string>;
          };
          failureMessage?: Field<string>;
          successRedirectUrl?: {
            jsonValue: { value: { [key: string]: string } };
          };
        };
        messagetopics?: {
          items: { targetItems: Array<MessageTopic> };
        };
        wnwmessagetopics?: {
          items: { targetItems: Array<MessageTopic> };
        };
        countries_region: {
          countries: {
            results: Array<Country>;
          };
        };
      };
    };
  };

interface MessageTopic {
  text: Field<string>;
  value: Field<string>;
}
interface Country {
  name: Field<string>;
  code: Field<string>;
  regions: {
    targetItems: [
      {
        code: { value: string };
        name: { value: string };
        value: string;
      }
    ];
  };
}
interface FormValuesTypes {
  [key: string]: string | boolean;
}

const ContactUsFormVariant = tv(
  {
    slots: {
      formWrapper: ['contactUsForm', 'flex', 'flex-col', 'items-center', 'gap-[8px]'],
      formContainer: ['container', 'max-w-[800px]', 'contact-us'],
      form: [
        'bg-color-background-light',
        'flex',
        'flex-col',
        'gap-desk-space-between-base-vertical',
      ],
      fieldWrap: ['flex', 'justify-between', 'w-full'],
      contentTitleSection: ['flex', 'flex-col', 'items-center', 'gap-[12px]', 'w-full'],
      title: ['text-center'],
      shortDescription: [
        'text-center',
        'text-color-text-dark',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      submitBtn: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
    },
    variants: {
      device: {
        mobile: {
          formWrapper: [
            'pt-mob-padding-none',
            'px-mob-global-grid-margin',
            'pb-mob-margin-loose-bottom',
          ],
          formContainer: ['px-0'],
          form: ['py-mob-padding-tight-y', 'px-mob-padding-tight-x'],
          fieldWrap: ['gap-mob-space-between-loose-horizontal', 'flex-col'],
          contentTitleSection: ['pt-mob-padding-tight-y', 'pb-mob-padding-micro-y'],
          title: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          submitBtn: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'rounded-desk-global-radius-small',
          ],
        },
        desktop: {
          formWrapper: [
            'pt-desk-padding-none',
            'px-desk-global-grid-margin',
            'pb-desk-margin-loose-bottom',
          ],
          formContainer: ['px-inherit'],
          form: ['py-desk-padding-tight-y', 'px-desk-padding-tight-x'],
          fieldWrap: ['gap-x-desk-space-between-loose-horizontal', 'flex-row', 'gap-none'],
          contentTitleSection: ['pt-desk-padding-tight-y', 'pb-desk-padding-micro-y'],
          title: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          submitBtn: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const ContactUsForm = ({ fields, params }: FormPropsType): JSX.Element => {
  const { getDictionaryValue } = useDictionary();
  const token = useAuthToken();
  const {
    formContainer,
    form,
    fieldWrap,
    title,
    contentTitleSection,
    formWrapper,
    submitBtn,
    shortDescription,
  } = ContactUsFormVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const router = useRouter();
  const sitename = useSiteName()?.toUpperCase() || '';
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [initialValues, setInitialValues] = useState({
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    MessageTopicId: '',
    MessageTopic: '',
    Country: 'US',
    State: '',
    ZipCode: '',
    City: '',
    PhoneNumber: '',
    Message: '',
    IsPSP: sitename === 'PSP' ? true : false,
    StoreId: '',
    Store: '',
  });
  useEffect(() => {
    if (myStoreData) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        StoreId: Number(myStoreData?.storeId).toString() || '',
        Store: `${myStoreData?.state} - ${myStoreData.storeName} (${myStoreData.city})` || '',
      }));
    }
  }, [myStoreData]);

  const transFormFields = transformData(fields.data?.data?.formFields);
  //

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const formSubmitAction = async (
    formvalues: FormValuesTypes,
    endpointURL: string,
    routeURL: string
  ) => {
    const updatedPostData = Object.keys(formvalues).reduce((acc: FormValuesTypes, key: string) => {
      if (key === 'MessageTopic') {
        const splitValues = formvalues[key].toString().split('||');
        acc[key] = splitValues[1].trim();
        acc['MessageTopicId'] = splitValues[0].trim() as string;
      } else {
        acc[key] = formvalues[key];
      }
      return acc;
    }, {});
    const headersData = {
      Authorization: token,
      site: sitename,
    };

    try {
      setShowErrorMsg(false);
      const endpoint = `${process.env.NEXT_PUBLIC_API_ENDPOINT}${endpointURL}`;
      const options = { method: 'POST', headers: headersData, data: updatedPostData };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await apiRequest(endpoint, options);
      router.push(routeURL); // TODO: Need to manage globally with the status code.
    } catch (error) {
      setShowErrorMsg(true);
      throw new Error(`Error fetching ${endpointURL}: ${error}`);
    }
  };
  return (
    <div className={formWrapper({ className: params?.Syles ?? '' })}>
      <div className={contentTitleSection()}>
        {fields.data?.data?.title?.value && (
          <Text field={fields.data?.data?.title} className={title()} tag="h3" />
        )}
        {fields.data?.data?.shortDescription?.value && (
          <p className={shortDescription()}>{fields.data?.data?.shortDescription?.value}</p>
        )}
      </div>
      <div className={formContainer()}>
        {showErrorMsg && (
          <div className="text-system-red">
            <RichText field={fields?.data?.data?.failureMessage} className="rte" />
          </div>
        )}
        <Formik
          initialValues={initialValues}
          enableReinitialize={true} // Reinitialize form when initialValues change
          onSubmit={async (
            values: FormValuesTypes,
            { setSubmitting }: FormikHelpers<FormValuesTypes>
          ) => {
            setSubmitting(true);
            const successRedirectionURL =
              fields.data?.data?.successRedirectUrl?.jsonValue?.value.href || '';
            await formSubmitAction(
              values,
              fields.data?.data?.submitEndpoint?.jsonValue?.value,
              successRedirectionURL
            );
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <FormikForm className={form()}>
              <div className={fieldWrap()}>
                <TextField {...transFormFields.FirstName} />
                <TextField {...transFormFields.LastName} />
              </div>
              <div className={fieldWrap()}>
                <TextField {...transFormFields.EmailAddress} />
              </div>
              <div className={fieldWrap()}>
                <DropdownField
                  {...transFormFields.Country}
                  options={fields.data.countries_region?.countries.results}
                  defaultValue={'US'}
                  firstOptionData={getDictionaryValue('Select')}
                />
                <TextField {...transFormFields.ZipCode} />
              </div>
              <div className={fieldWrap()}>
                <TextField {...transFormFields.City} />
                <StateDropdown
                  {...transFormFields.State}
                  options={fields.data.countries_region?.countries.results}
                  dependentfieldname={transFormFields?.Country?.name as string}
                />
              </div>
              <div className={fieldWrap()}>
                <TextField {...transFormFields.Store} />
              </div>
              <div className={fieldWrap()}>
                <DropdownField
                  {...transFormFields.MessageTopic}
                  firstOptionData={getDictionaryValue('Select')}
                  options={
                    sitename === 'PSP'
                      ? fields.data.messagetopics?.items.targetItems
                      : fields.data.wnwmessagetopics?.items.targetItems
                  }
                  mergeTextAndValue={true}
                />
                <PhoneField {...transFormFields.PhoneNumber} />
              </div>
              <div className={fieldWrap()}>
                <TextAreaField {...transFormFields.Message} />
              </div>
              <div>
                <button
                  aria-label="submit"
                  className={submitBtn()}
                  type="submit"
                  data-disabled={isSubmitting}
                  disabled={isSubmitting}
                >
                  {fields.data?.data?.submitButtonText?.value}
                </button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUsForm;
