import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { LuPlus } from 'react-icons/lu';
import React, { useState } from 'react';
import { RuleItem } from './RuleItem';
import { ConditionTypes, IPersonalizationRule } from '../models';
import { DefaultRule } from './DefaultRule';
import TextField from 'components/helpers/Form/TextField';

interface RulesListProps {
  rules: IPersonalizationRule[];
  setRules: React.Dispatch<React.SetStateAction<IPersonalizationRule[]>>;
  addRule: (rule: IPersonalizationRule) => void;
  removeRule: (index: number) => void;
  swapRule: (oldIndex: number, newIndex: number) => void;
  contentItemIds: string[];
}

export function RuleList({
  rules,
  addRule,
  removeRule,
  swapRule,
  setRules,
  contentItemIds,
}: RulesListProps) {
  const [openIndex, setOpenIndex] = useState(0);

  const handleAddRule = () => {
    const nowTime = new Date().getTime().toString();
    const newRule: IPersonalizationRule = {
      name: 'New rule',
      id: nowTime,
      show: true,
      contentItemId: '',
      contentItemName: '',
      conditions: [
        {
          id: nowTime,
          type: ConditionTypes.WHERE_STORE_IS,
          value: '',
        },
      ],
    };
    addRule(newRule);
    setRules((prevRules) => {
      const updatedRules = [...prevRules, newRule];
      setOpenIndex(updatedRules.length - 1); // Set open index to the last item in the array
      return updatedRules;
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }

    const oldIndex = rules.findIndex((rule) => rule.id === active.id);
    const newIndex = rules.findIndex((rule) => rule.id === over?.id);

    if (oldIndex === -1 || newIndex === -1) {
      return;
    }

    swapRule(oldIndex, newIndex);
  };

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={(e: DragEndEvent) => handleDragEnd(e)}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={rules.map((rule) => rule.id)}
          strategy={verticalListSortingStrategy}
        >
          <TextField
            label="Default CMS Path"
            id="defaultCmsPath"
            name="defaultCmsPath"
            required={false}
          />
          {rules.map((rule, index) => (
            <RuleItem
              key={`rules.${index}`}
              rule={rule}
              index={index}
              removeRule={removeRule}
              defaultOpen={index === openIndex}
              contentItemIds={contentItemIds}
            />
          ))}
          <DefaultRule />
        </SortableContext>
      </DndContext>
      <button
        type="button"
        onClick={handleAddRule}
        className="flex items-center w-full text-left mt-2 p-3 gap-3 border-2 border-dashed border-green-500/30 bg-green-600/5 text-green-800 text-lg hover:bg-green-600/10 transition-colors"
      >
        <LuPlus className="[&>svg>*>*]:fill-green-700" />
        <p>Create new rule</p>
      </button>
    </>
  );
}
