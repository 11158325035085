//global
import React, { useContext, useEffect, useState } from 'react';
import { Field, ImageField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local

//lib
import { ComponentProps } from 'lib/component-props';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';
import TextHelper from 'components/helpers/TextHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import ModalWrapper from 'components/helpers/ModalWrapper';
import ImageHelper from 'components/helpers/ImageHelper';
import { useOcSelector } from 'src/redux/ocStore';
import useDictionary from 'src/hooks/useDictionary';
import { FulfillmentType } from 'components/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
//type
export type AutoshipAlertMessageProps = ComponentProps & {
  fields: {
    title?: Field<string>;
    readMoreText?: Field<string>;
    readMoreModalImage?: ImageField;
    readMoreModalText?: Field<string>;
  };
};
//component variants
const autoshipAlertMessageVariants = tv(
  {
    slots: {
      base: ['autoshipAlertMessage', 'block'],
      autoshipAlertMessageWrapper: '',
      descriptionWrapper: 'block border border-color-brand-autoship bg-color-background-white',
      description:
        'inline font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      link: 'inline ml-1 text-color-text-brand-1 font-primary text-body-large-bold font-body-large-bold leading-body-large-bold underline hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      titleWrapper:
        'bg-color-brand-autoship text-color-text-white font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      modalDiv: 'flex flex-col',
      modalTitle:
        'font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          base: '-mt-[15px] mb-0',
          autoshipAlertMessageWrapper: 'my-desk-margin-base-bottom',
          titleWrapper: 'py-desk-global-spacing-spacing-2 px-desk-global-spacing-spacing-5',
          descriptionWrapper: 'p-desk-global-spacing-spacing-5',
          modalDiv:
            'gap-desk-space-between-tight-vertical py-desk-padding-tight-y px-desk-padding-base-x',
        },
        mobile: {
          base: 'mt-mob-margin-tight-bottom mb-mob-padding-tight-bottom',
          titleWrapper: 'py-mob-global-spacing-spacing-2 px-mob-global-spacing-spacing-5',
          descriptionWrapper: 'p-desk-global-spacing-spacing-5',
          modalDiv:
            'gap-mob-space-between-tight-vertical py-mob-padding-tight-y px-mob-padding-base-x',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const AutoshipAlertMessage: React.FC<AutoshipAlertMessageProps> = ({ fields, params }) => {
  const {
    base,
    titleWrapper,
    description,
    descriptionWrapper,
    modalDiv,
    link,
    autoshipAlertMessageWrapper,
    modalTitle,
  } = autoshipAlertMessageVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const [showModal, setShowModal] = useState(false);
  const { getProductLineItems } = useOcCart();
  const productLineItems: LineItemWithXp[] = getProductLineItems();
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);
  const [newMethod, setNewMethod] = useState<string>(FulfillmentType.BOPIS);
  const hasAutoship = productLineItems.some((x) => x.Product?.xp?.Autoship);
  const store = useOcSelector((state) => state.storeReducer.selectedStore);
  const { getDictionaryValueWithReplacement } = useDictionary();
  const [autoshipMessage, setAutoshipMessage] = useState<null | string>(null);
  const { componentContextData } = useContext(ComponentContext);

  useEffect(() => {
    if (localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod) {
      setNewMethod(localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod || '');
    }
  }, [fulfillmentMethod, componentContextData?.isDFSMethodChanged]);
  const totalPossibleAutoship = productLineItems
    .filter((x) => x.xp?.Autoship)
    .map((x) => x.LineTotal ?? 0)
    .reduce((a, b) => a + b, 0);
  const maxFirstTime =
    (store.autoshipSettings?.firstTimeDiscount ?? 0) * 0.01 * totalPossibleAutoship;
  const handleModalClose = () => {
    setShowModal(false);
  };
  const receivedFirstTimeAutoshipDiscount = useOcSelector(
    (state) => state.ocUser.user?.xp?.ReceivedFirstTimeAutoshipDiscount
  );
  const renderMessage = () => {
    if (receivedFirstTimeAutoshipDiscount) {
      if (
        productLineItems.some(
          (x) =>
            x.Product?.xp?.Autoship &&
            x.Product?.xp?.FirstTimeAutoshipDiscount &&
            x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageForFutureOrder';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.FirstTimeAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageWithoutDiscount';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageForFutureOrder';
      } else if (productLineItems.some((x) => x.Product?.xp?.Autoship)) {
        return 'AutoshipAlertMessageWithoutDiscount';
      }
    } else {
      if (
        productLineItems.some(
          (x) =>
            x.Product?.xp?.Autoship &&
            x.Product?.xp?.FirstTimeAutoshipDiscount &&
            x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageFirstTimeWithAdditionalDiscount';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.FirstTimeAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageFirstTimeDiscount';
      } else if (
        productLineItems.some(
          (x) => x.Product?.xp?.Autoship && x.Product?.xp?.ReccuringAutoshipDiscount
        )
      ) {
        return 'AutoshipAlertMessageForFutureOrder';
      } else if (productLineItems.some((x) => x.Product?.xp?.Autoship)) {
        return 'AutoshipAlertMessageWithoutDiscount';
      }
    }

    return null;
  };

  useEffect(() => {
    setAutoshipMessage(renderMessage());
  }, [productLineItems]);
  if (fields === undefined || fields === null) return <></>;
  return (
    <>
      {hasAutoship && newMethod === FulfillmentType.DFS && autoshipMessage && (
        <>
          <div className={base({ className: params?.Style ?? '' })}>
            <div className={autoshipAlertMessageWrapper()}>
              <div className={titleWrapper()}>
                <TextHelper tag="p" field={fields?.title} />
              </div>
              <div className={descriptionWrapper()}>
                <RichText
                  tag="p"
                  field={{
                    value: getDictionaryValueWithReplacement(
                      autoshipMessage,
                      {
                        xx: Math.min(
                          store.autoshipSettings?.firstTimeDiscountLimit ?? 25,
                          maxFirstTime
                        ).toFixed(2),
                        x: store.autoshipSettings?.firstTimeDiscount?.toString() ?? '35',
                        y: store.autoshipSettings?.subsequentOrdersDiscount?.toString() ?? '5',
                        z: store.autoshipSettings?.firstTimeDiscountLimit?.toString() ?? '25',
                      },
                      // Fallback explicitly empty string because we don't want to display they key if dictionary lookup fails
                      autoshipMessage
                    ),
                  }}
                  className={description()}
                />
                <LinkHelper
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                  field={{ value: { text: fields?.readMoreText?.value, href: '/' } }}
                  className={link()}
                />
              </div>
            </div>
          </div>
          <ModalWrapper
            showCloseButtonInModalHeader={false}
            closeModalOnOverlayClick={true}
            onCloseClick={handleModalClose}
            showModal={showModal}
            customPopup={true}
            popupWidth="max-w-[997px]"
            popupSpacing="p-0"
          >
            <div className={modalDiv()}>
              <TextHelper className={modalTitle()} tag="p" field={fields?.readMoreModalText} />
              <ImageHelper className="w-full object-cover" field={fields?.readMoreModalImage} />
            </div>
          </ModalWrapper>
        </>
      )}
    </>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default AutoshipAlertMessage;
