// Global
import {
  Field as FormikField,
  Formik,
  Form as FormikForm,
  FormikValues,
  FormikHelpers,
} from 'formik';
// Utils and Form Helper
import TextAreaField from 'components/helpers/Form/TextAreaField';
import TextField from 'components/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
// Tailwind Import
import deliveryAddress from '../DeliveryAddress/DeliveryAddressTailwindVariant';
import deliveryFrequency from '../DeliveryFrequency/DeliveryFrequencyTailwindVariant';
import billingAddress from '../BillingAddress/BillingTailwindVariant';
import CheckboxField from 'components/helpers/Form/CheckboxField';
import IconHelper from 'components/helpers/IconHelper';
// import { ComponentProps } from '@sitecore-feaas/clientside';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
// import { saveShippingAddress } from 'src/redux/ocCurrentOrder';
import { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react';
import { Address, Me } from 'ordercloud-javascript-sdk';
import PhoneField, { formatPhoneNumber } from 'components/helpers/Form/PhoneField';
import { FulfillmentType, addressType, DiscreteLineItem } from 'components/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
import { useCheckoutFormContext } from '../../../lib/context/CheckoutFormContext';
import RichTextHelper from 'components/helpers/RichTextHelper';
import clsx from 'clsx';
import pickUpAddress from '../PickupAddress/TailwindVariant';
import LinkHelper from 'components/helpers/LinkHelper';
import useOcCart from 'src/hooks/useOcCart';
import { updateUser } from 'src/redux/ocUser';
import { UserAddress } from '../PickupAddress/PickupAddress';
import { saveShippingAddress } from 'src/redux/ocCurrentOrder';
import { saveAddress } from 'src/redux/ocAddressBook';
import Loader from 'components/Loader/Loader';
import useDictionary from 'src/hooks/useDictionary';
import { formatPhone } from 'lib/utils/string-utils';

export type DeliveryAddressProps = {
  fields: {
    data: {
      data: {
        title: Field<string>;
        shortDescription: Field<string>;
        submitButtonText: Field<string>;
        successMessage: Field<string>;
        successRedirectUrl?: { jsonValue: LinkField };
        failureMessage?: Field<string>;
        consentMessage?: Field<string>;
        errors: ErrorField;
        formFields: {
          targetItems: Array<FormFieldsProps>;
        };
      };
      content: {
        editLabel?: Field<string>;
        deliveryInfo: Field<string>;
        delivertoLabel?: Field<string>;
        instructionLabel?: Field<string>;
        deliveryInfoTitle?: Field<string>;
      };
    };
  };
};
interface ErrorField {
  values: {
    name: string;
    value: string;
  }[];
}
const DeliveryAddress = ({ fields }: DeliveryAddressProps): JSX.Element => {
  // Tailwind Variant
  const {
    base1,
    deliveryAddressWrapper,
    informationTitle,
    form,
    radioIcon,
    pickupInformationContainer,
    radioButtonsWrapper,
    radioButtonWrapper,
    radioField,
    validFieldInfo,
    info,
    inlineFieldWrapper,
    radioAsCheckboxWrapper,
    labels,
    inlineFields,
    submitBtn,
    loaderWrapper,
    fieldWrapper,
    titleWrapper,
    title,
  } = deliveryAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { blockTitle } = billingAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const {
    addressBlockHeadline,
    addressInfo,
    personalInfo,
    blockWithMargin,
    addressBlock,
    checkmarkIcon,
    field,
  } = pickUpAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  interface FormValuesTypes {
    Email?: string;
    Message?: string;
    PhoneNumber?: string;
    PhoneTypeCheckbox?: string;
    FirstName?: string;
    LastName?: string;
    DeliveryInstruction?: string;
    DeliveryTo?: string;
    SaveToProfile?: string;
  }
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const ocCurrentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();
  //const lineItems = useOcSelector((state) => state?.ocCurrentOrder?.lineItems);
  const shippingAddress = productlineitems?.[0]?.ShippingAddress;
  const { deliveryAddressForm } = useCheckoutFormContext() || {};
  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const formRef = useRef<HTMLDivElement>(null);
  const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;

  const dispatch = useOcDispatch();
  const { componentContextData, setcomponentContextData, setExpandedForm } =
    useContext(ComponentContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(
    componentContextData?.showDeliveryAddressForm ? false : true
  );

  const [deliverToMe, setDeliverToMe] = useState<boolean>(shippingAddress?.xp?.SelfPickup || true);
  const [formValues, setFormValues] = useState<FormikValues>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initialDataValues, setInitialDataValues] = useState<any>();

  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);

  // Get form-fields
  const transFormFields = transformData(fields.data?.data?.formFields);
  // Radio for pickup information
  const radioDeliveryTo = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'DeliveryTo'
  )?.checkboxvalues?.values;
  const radioPhoneType = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'PhoneTypeCheckbox'
  )?.checkboxvalues?.values;
  const content = fields.data?.content;
  //set initial componentContextDataValues
  useLayoutEffect(() => {
    setcomponentContextData({
      ...componentContextData,
      warningDeliveryProfileUpdate: undefined,
    });
  }, [componentContextData?.setDeliverySubmitting]);

  useEffect(() => {
    if (componentContextData?.expandedForm == 'delivery') {
      setCollapse(false);
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setCollapse(true);
    }
  }, [componentContextData?.expandedForm]);

  const openDeliveryCart = async () => {
    /*
      Commenting now as discussed that first form Edit functionality should not be handled by the second form
    */
    /*
      const billingAddressErrors = await (checkoutForm?.billingAddressForm?.current?.errors as Record<
        string,
        string
      >);
      if (billingAddressErrors) {
        const deliveryErrorArray = Object.entries(billingAddressErrors);
        if (deliveryErrorArray.length > 0) {
          const deliveryTarget = document.getElementById('billingaddress');
          deliveryTarget && deliveryTarget.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }
    */
    if (setExpandedForm) {
      setExpandedForm('delivery');
      setCollapse(false);
      const deliveryTarget = document.getElementById('deliveryaddress');
      deliveryTarget && deliveryTarget.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // to set the initial data values:
  useEffect(() => {
    if (isAnonymous) {
      setInitialDataValues(() => ({
        FirstName: shippingAddress?.FirstName ?? '',
        LastName: shippingAddress?.LastName ?? '',
        Email: shippingAddress?.xp?.Email ?? '',
        PhoneNumber: shippingAddress?.Phone ?? '',
        PhoneTypeCheckbox: shippingAddress?.xp?.IsMobile.toString()
          ? shippingAddress?.xp?.IsMobile?.toString()
          : 'true',
        DeliveryTo: !shippingAddress
          ? 'true'
          : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
        DeliveryInstruction: shippingAddress?.xp?.DeliveryInstruction ?? '',
        SaveToProfile: 'false',
      }));
    } else {
      setInitialDataValues({
        FirstName: deliverToMe
          ? (userDetails?.FirstName || '').trim()
          : shippingAddress?.FirstName === userDetails?.FirstName
          ? ' '
          : shippingAddress?.FirstName,
        LastName: deliverToMe
          ? (userDetails?.LastName || '').trim()
          : shippingAddress?.LastName === userDetails?.LastName
          ? ' '
          : shippingAddress?.LastName,
        Email: (shippingAddress?.xp?.Email || userDetails?.Email) ?? '',
        PhoneNumber:
          (formatPhoneNumber(shippingAddress?.Phone as string) ||
            formatPhoneNumber(userDetails?.Phone as string)) ??
          '',
        PhoneTypeCheckbox:
          shippingAddress?.xp?.IsMobile?.toString() ||
          userDetails?.xp?.IsMobile?.toString() ||
          'true',
        // by default, it should be Deliver to me selected
        DeliveryTo: !shippingAddress
          ? 'true'
          : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
        DeliveryInstruction: deliverToMe
          ? shippingAddress?.xp?.DeliveryInstruction || userDetails?.xp?.DeliveryInstruction?.trim()
          : shippingAddress?.xp?.DeliveryInstruction === userDetails?.xp?.DeliveryInstruction
          ? ' '
          : shippingAddress?.xp?.DeliveryInstruction,
        SaveToProfile: 'true',
      });
    }
  }, [deliverToMe, isAnonymous, shippingAddress, userDetails]);

  const [validAddress, setValidAddress] = useState<boolean>(false);
  const { getDictionaryValue } = useDictionary();

  // to re-render based on SelfPickup option on first load and Edit:
  useEffect(() => {
    if (shippingAddress) {
      const validAddressBoolean = !!(
        shippingAddress &&
        shippingAddress.FirstName &&
        shippingAddress.LastName &&
        shippingAddress.Phone &&
        shippingAddress.xp &&
        shippingAddress.xp.Email &&
        shippingAddress.xp.SelfPickup != undefined &&
        shippingAddress.xp.IsMobile != undefined
      );
      setValidAddress(validAddressBoolean);
    }
  }, [shippingAddress]);

  useEffect(() => {
    if (validAddress) {
      setCollapse(true);
    }
  }, [validAddress]);

  useEffect(() => {
    if (!pickup && !validAddress && !(initialDataValues?.Email && initialDataValues?.PhoneNumber)) {
      // for DFS
      setCollapse(false);
      // add condition to check if Billing is present or  not.
      if (!cart?.BillingAddress) {
        setExpandedForm('delivery');
      }
    } else if (componentContextData?.expandedForm == 'delivery') {
      setCollapse(false);
    } else {
      setCollapse(true);
    }
  }, [componentContextData?.expandedForm]);

  /**
   * Logic to automatically save the Delivery form info if user is authenticated
   */
  useEffect(() => {
    if (
      !isAnonymous &&
      ocCurrentOrder?.lineItems &&
      ocCurrentOrder?.lineItems?.length > 0 &&
      componentContextData?.expandedForm === 'delivery' &&
      !loading
    ) {
      const shippingAddress = ocCurrentOrder?.lineItems?.find(
        (x) => ![DiscreteLineItem.TIP].includes(x.ProductID)
      )?.ShippingAddress;

      if (shippingAddress && shippingAddress?.Street1 && shippingAddress?.City) {
        if (!shippingAddress?.FirstName && !shippingAddress?.xp?.Email && !shippingAddress?.Phone) {
          // ---- Extract data from user Details and save to shippingAddress. ---
          submitForm({
            ...initialDataValues,
            FirstName: userDetails?.FirstName,
            LastName: userDetails?.LastName,
            PhoneNumber: formatPhoneNumber(userDetails?.Phone as string) ?? '',
            Email: userDetails?.Email,
          });
          setCollapse(true);

          // At time of saving, check other forms:
          if (cart?.BillingAddress) {
            // if Billing present already, open payment,
            if (setExpandedForm) {
              setExpandedForm('payment'); // delivery | billing | tip | pickup | payment
              setTimeout(() => {
                const paymentTarget = document.getElementById('paymentsection');
                paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
              }, 500);
            }
          }
        }
      }
    }
  }, [componentContextData?.expandedForm, ocCurrentOrder?.lineItems?.length]);

  //for saving pickupaddress in home
  const userDeliveryAddress = {
    ID: userDetails?.ID,
    Phone: userDetails?.Phone,
    xp: {
      DeliveryInstruction: userDetails?.xp?.DeliveryInstruction,
      PickupInstruction: userDetails?.xp?.PickupInstruction,
    },
  };

  // save to profile function:
  const saveUserProfile = async (values: FormikValues) => {
    setLoading(true);
    const request: UserAddress = {
      ID: userDeliveryAddress?.ID as string,
      ...(values?.PhoneTypeCheckbox === 'true' && { Phone: formatPhone(values?.PhoneNumber) }),
      xp: {
        DeliveryInstruction: values?.DeliveryInstruction as string,
        ...(values?.PhoneTypeCheckbox !== 'true' && {
          HomePhone: formatPhone(values?.PhoneNumber),
        }),
      },
    };
    request && (await dispatch(updateUser(request)));
    setLoading(false);
  };

  const handleDeliveryOption = (
    event: React.MouseEvent,
    setValues: FormikHelpers<FormikValues>['setValues']
  ) => {
    if ((event.target as HTMLInputElement)?.value == 'true') {
      // deliverToMe = false
      setDeliverToMe(true);
      if (!shippingAddress) {
        // no shippingAddress is saved prior to this.
        if (isAnonymous) {
          setValues({
            FirstName: '',
            LastName: '',
            Email: '',
            PhoneNumber: '',
            PhoneTypeCheckbox: 'true',
            DeliveryTo: 'true',
            DeliveryInstruction: '',
          });
        } else {
          // has some logged in user with details:
          setValues({
            FirstName: userDetails?.FirstName || '',
            LastName: userDetails?.LastName || '',
            Email: userDetails?.Email || '',
            PhoneNumber: formatPhoneNumber(userDetails?.Phone as string) || '',
            PhoneTypeCheckbox: userDetails?.xp?.IsMobile?.toString() ?? 'true',
            DeliveryTo: 'true',
            DeliveryInstruction:
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (shippingAddress as any)?.xp?.DeliveryInstruction ??
              userDetails?.xp?.DeliveryInstruction,
          });
        }
      } else {
        if (shippingAddress?.xp?.SelfPickup) {
          setValues({
            FirstName: isAnonymous ? shippingAddress?.FirstName : userDetails?.FirstName || '',
            LastName: isAnonymous ? shippingAddress?.LastName : userDetails?.LastName || '',
            Email:
              (shippingAddress?.xp?.Email ||
                (!isAnonymous && userDetails?.Email ? userDetails?.Email : '')) ??
              '',
            PhoneNumber:
              (formatPhoneNumber(shippingAddress?.Phone as string) ||
                (!isAnonymous && userDetails?.Phone
                  ? formatPhoneNumber(userDetails?.Phone as string)
                  : '')) ??
              '',
            PhoneTypeCheckbox: shippingAddress?.xp?.IsMobile.toString()
              ? shippingAddress?.xp?.IsMobile?.toString()
              : !isAnonymous && userDetails?.xp?.IsMobile
              ? userDetails?.xp?.IsMobile?.toString()
              : 'true',
            // by default, it should be Deliver to me selected
            DeliveryTo: !shippingAddress
              ? 'true'
              : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
            DeliveryInstruction: isAnonymous
              ? shippingAddress?.xp?.DeliveryInstruction
              : userDetails?.xp?.DeliveryInstruction || '',
          });
        } else {
          setValues({
            FirstName: '',
            LastName: '',
            Email:
              ((isAnonymous && '') ||
                (!isAnonymous && userDetails?.Email ? userDetails?.Email : '')) ??
              '',
            PhoneNumber:
              ((isAnonymous && '') ||
                (!isAnonymous && userDetails?.Phone
                  ? formatPhoneNumber(userDetails?.Phone as string)
                  : '')) ??
              '',
            PhoneTypeCheckbox: isAnonymous ? 'true' : userDetails?.xp?.IsMobile?.toString(),
            // by default, it should be Deliver to me selected
            DeliveryTo: !shippingAddress
              ? 'true'
              : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
            DeliveryInstruction: '',
          });
        }
      }
    } else {
      setDeliverToMe(false);
      if (shippingAddress?.xp?.SelfPickup) {
        // the values should be null
        setValues({
          FirstName: '',
          LastName: '',
          Email: '',
          PhoneNumber: '',
          PhoneTypeCheckbox: 'true',
          // by default, it should be Deliver to me selected
          DeliveryTo: !shippingAddress
            ? 'true'
            : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
          DeliveryInstruction: '',
        });
      } else {
        setValues({
          FirstName:
            shippingAddress?.FirstName === userDetails?.FirstName
              ? ' '
              : shippingAddress?.FirstName,
          LastName:
            shippingAddress?.LastName === userDetails?.LastName ? ' ' : shippingAddress?.LastName,
          Email: shippingAddress?.xp?.Email ?? '',
          PhoneNumber: formatPhoneNumber(shippingAddress?.Phone as string) ?? '',
          PhoneTypeCheckbox: shippingAddress?.xp?.IsMobile?.toString() ?? 'true',
          // by default, it should be Deliver to me selected
          DeliveryTo: !shippingAddress
            ? 'true'
            : shippingAddress?.xp?.SelfPickup?.toString() ?? 'true',
          DeliveryInstruction: shippingAddress?.xp?.DeliveryInstruction ?? '',
        });
      }
    }
  };

  const { linkWithUnderline } = deliveryFrequency({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // Function call to submit/save the delivery address[ShippingAddress] to the currentOrder.
  const submitForm = async (values: FormikValues) => {
    setLoading(true);
    try {
      if (values?.SaveToProfile && !isAnonymous) {
        const request: UserAddress = {
          AddressName: addressType?.delivery,
          Shipping: true,
          FirstName: shippingAddress?.FirstName,
          LastName: shippingAddress?.LastName,
          Street1: shippingAddress?.Street1 ?? '',
          Street2: shippingAddress?.Street2,
          Phone: formatPhone(shippingAddress?.Phone ?? ''),
          City: shippingAddress?.City ?? '',
          State: shippingAddress?.State ?? '',
          Zip: shippingAddress?.Zip ?? '',
          Country: shippingAddress?.Country ?? '',
        };
        const listAdd = await Me.ListAddresses({ sortBy: ['DateCreated'] });
        const add = listAdd?.Items?.filter(
          (item) => item?.AddressName === addressType?.delivery
        )[0];
        if (add) {
          await dispatch(saveAddress({ ...request, ID: add?.ID }));
        } else {
          await dispatch(saveAddress({ ...request }));
        }
      }
      const ocShippingAddressData: Address = {
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Phone: formatPhone(values?.PhoneNumber),
        Street1: shippingAddress?.Street1 ?? '',
        Street2: shippingAddress?.Street2,
        City: shippingAddress?.City ?? '',
        State: shippingAddress?.State ?? '',
        Zip: shippingAddress?.Zip ?? '',
        Country: shippingAddress?.Country ?? '',
        xp: {
          IsMobile: values?.PhoneTypeCheckbox == 'false' ? false : true,
          Email: values?.Email ?? '',
          SelfPickup: values?.DeliveryTo == 'false' ? false : true,
          DeliveryInstruction: values?.DeliveryInstruction,
        },
      };
      if (deliverToMe && !isAnonymous) {
        // fetch names from user's details:
        ocShippingAddressData.FirstName = userDetails?.FirstName;
        ocShippingAddressData.LastName = userDetails?.LastName;
      }
      await dispatch(saveShippingAddress(ocShippingAddressData)).then(() => {
        setCollapse(true);
        // check to open BillingAddr Form if not BillingAddress obj is yet to set
        if (!cart?.BillingAddress) {
          setcomponentContextData({
            ...componentContextData,
            showBillingAddressForm: true,
            showDeliveryAddressForm: false,
            setDeliverySubmitting: undefined,
          });
          // go to BA form:
          if (setExpandedForm) {
            setExpandedForm('billing');
            const billingTarget = document.getElementById('billingaddress');
            billingTarget && billingTarget.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          setcomponentContextData({
            ...componentContextData,
            setDeliverySubmitting: undefined,
            showDeliveryAddressForm: false,
          });
          if (setExpandedForm) {
            setExpandedForm('payment'); // delivery | billing | tip | pickup | payment
            setTimeout(() => {
              const paymentTarget = document.getElementById('paymentsection');
              paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
            }, 500);
          }
        }
        setLoading(false);
      });
    } catch (error) {
      console.error('submitDeliveryAddress: ', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (componentContextData?.setDeliverySubmitting !== undefined) {
      if (componentContextData?.setDeliverySubmitting === true) {
        saveUserProfile(formValues as FormikValues);
        submitForm(formValues as FormikValues);
      } else if (componentContextData?.setDeliverySubmitting === false) {
        submitForm(formValues as FormikValues);
      }
    }
  }, [componentContextData?.setDeliverySubmitting]);

  // Function call to submit/save the delivery address[ShippingAddress] to the currentOrder.
  const submitDeliveryAddress = async (values: FormikValues) => {
    setcomponentContextData({ ...componentContextData });
    setFormValues(values);
    if (values?.SaveToProfile && !isAnonymous) {
      if (
        formatPhone(userDeliveryAddress?.Phone ?? '') !== formatPhone(values?.PhoneNumber) ||
        (userDeliveryAddress?.xp?.DeliveryInstruction &&
          userDeliveryAddress?.xp?.DeliveryInstruction?.trim() !==
            values?.DeliveryInstruction?.trim())
      ) {
        setcomponentContextData({
          ...componentContextData,
          warningDeliveryProfileUpdate: true,
          isDelivery: true,
        });
      } else {
        saveUserProfile(values);
        submitForm(values);
      }
    } else {
      submitForm(values);
    }
  };

  if (
    !myStoreData ||
    Object.keys(myStoreData).length === 0 ||
    !cart ||
    cart.xp?.Fulfillment === FulfillmentType.BOPIS
  ) {
    return (
      <div id="deliveryaddress" ref={formRef} className="deliveryaddress" hidden>
        {cart?.xp?.Fulfillment}
      </div>
    );
  }

  const phoneNumber = shippingAddress
    ? shippingAddress?.Phone
    : formatPhoneNumber(userDetails?.Phone as string);
  return (
    <>
      <div id="deliveryaddress" className={'deliveryaddress ' + base1({ active: !collapse })}>
        <div className={titleWrapper()}>
          <div className={title({ active: collapse })}>2. {fields?.data?.data?.title?.value}</div>
          {collapse && (
            <div className={linkWithUnderline()} onClick={openDeliveryCart}>
              {content?.editLabel?.value ?? 'Edit'}
            </div>
          )}
        </div>
        <div>
          <div
            className={clsx(
              deliveryAddressWrapper(),
              !collapse ? 'lg:pl-desk-margin-base-right' : ''
            )}
          >
            <div className={blockWithMargin()}>
              {!collapse && (
                <div className="flex flex-col">
                  <p className={blockTitle()}>{content?.delivertoLabel?.value ?? 'Deliver to:'}</p>
                  <span>
                    {shippingAddress
                      ? `${
                          shippingAddress?.FirstName ?? (!isAnonymous ? userDetails?.FirstName : '')
                        } ${
                          shippingAddress?.LastName ?? (!isAnonymous ? userDetails?.LastName : '')
                        }`
                      : `${userDetails?.FirstName} ${userDetails?.LastName}`}
                  </span>
                  <span>
                    {shippingAddress?.Street1} {shippingAddress?.Street2}
                  </span>
                  <span>
                    {shippingAddress?.City}, {shippingAddress?.State} {shippingAddress?.Zip}
                  </span>
                </div>
              )}
              {collapse && (
                <div className="flex flex-col">
                  <div className={personalInfo()}>
                    <div className={blockWithMargin()}>
                      <div className={clsx(addressBlockHeadline(), addressBlock())}>
                        {' '}
                        {content?.delivertoLabel?.value ?? 'Deliver to:'}
                      </div>
                      <div className={addressBlock()}>
                        <span className="block">
                          {shippingAddress
                            ? `${
                                shippingAddress?.FirstName ??
                                (!isAnonymous ? userDetails?.FirstName : '')
                              } ${
                                shippingAddress?.LastName ??
                                (!isAnonymous ? userDetails?.LastName : '')
                              }`
                            : `${userDetails?.FirstName} ${userDetails?.LastName}`}
                        </span>
                        <span className="block">
                          {shippingAddress?.Street1} {shippingAddress?.Street2}
                        </span>
                        <span className="block">
                          {shippingAddress?.City} {shippingAddress?.State} {shippingAddress?.Zip}
                        </span>
                      </div>
                    </div>
                    <div className={blockWithMargin()}>
                      {phoneNumber && (
                        <LinkHelper
                          className={linkWithUnderline()}
                          field={{
                            value: {
                              href: `tel:${phoneNumber}`,
                              text: `${formatPhoneNumber(phoneNumber)}`,
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={clsx(addressInfo(), blockWithMargin())}>
              <RichTextHelper
                className={addressInfo({ className: 'break-all' })}
                field={content?.deliveryInfo}
              />
            </div>
          </div>
          <div className={clsx(personalInfo(), !collapse ? 'lg:pl-desk-margin-base-right' : '')}>
            {shippingAddress?.xp?.DeliveryInstruction && (
              <div className="block">
                <div className={addressBlock()}>
                  {content?.instructionLabel?.value ?? 'Special instructions'}
                </div>
                <div className="break-all">{shippingAddress?.xp?.DeliveryInstruction}</div>
              </div>
            )}
          </div>
        </div>

        {!collapse && (
          <Formik
            initialValues={initialDataValues}
            innerRef={deliveryAddressForm}
            onSubmit={(values: FormValuesTypes) => {
              submitDeliveryAddress(values);
            }}
          >
            {({ setValues }) => (
              <FormikForm className={pickupInformationContainer()}>
                <div className={radioButtonsWrapper()}>
                  <div className={informationTitle()}>{content?.deliveryInfoTitle?.value}</div>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className={radioButtonsWrapper()}
                  >
                    {radioDeliveryTo?.map((radioButton, index: number) => {
                      return (
                        <label key={index} className={radioButtonWrapper()}>
                          <FormikField
                            type="radio"
                            className={radioField()}
                            name="DeliveryTo"
                            value={radioButton?.value}
                            onClick={(event: React.MouseEvent) =>
                              handleDeliveryOption(event, setValues)
                            }
                          />
                          {radioButton?.name}
                          <div className={radioIcon()}></div>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className={pickupInformationContainer()}>
                  <div className={form()}>
                    {/* Fields marked with an asterisk * .... */}
                    {fields?.data?.data?.shortDescription?.value && (
                      <div className={fieldWrapper()}>
                        <div className={validFieldInfo()}>
                          {fields?.data?.data?.shortDescription?.value}
                        </div>
                      </div>
                    )}
                    {/* First Name and Last Name */}
                    {isAnonymous && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}
                    {!isAnonymous && !deliverToMe && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}
                    {/* Email Field */}
                    {transFormFields.Email && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.Email} />
                      </div>
                    )}
                    {/* Telephone - Mobile Phone & Home Phone */}
                    {transFormFields.PhoneNumber && (
                      <div className={inlineFieldWrapper()}>
                        <div className="inlineWrapper">
                          <PhoneField {...transFormFields.PhoneNumber} />
                        </div>
                        <div className={radioAsCheckboxWrapper()}>
                          {radioPhoneType?.map((element, index: number) => {
                            return (
                              // <h2 key={index}>hello</h2>
                              <label className={labels()} key={index}>
                                <FormikField
                                  type="radio"
                                  className={field({ className: 'rounded-full' })}
                                  name="PhoneTypeCheckbox"
                                  value={element?.value}
                                />
                                {element.name}
                                <IconHelper className={checkmarkIcon()} icon={'icon-checkmark'} />
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {/* Special Information TextBlock */}
                    <div className={fieldWrapper()}>
                      {transFormFields.DeliveryInstruction && (
                        <TextAreaField {...transFormFields.DeliveryInstruction} />
                      )}
                      {fields?.data?.data?.consentMessage && (
                        <span className={info()}>{fields?.data?.data?.consentMessage?.value}</span>
                      )}
                    </div>
                    {/* Save to Profile */}
                    {!isAnonymous && transFormFields.SaveToProfile && (
                      <div className={fieldWrapper()}>
                        {<CheckboxField {...transFormFields.SaveToProfile} singleCheckbox={true} />}
                      </div>
                    )}
                    <div className={fieldWrapper()}>
                      {loading ? (
                        <div className={loaderWrapper()}>
                          <Loader />
                          {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                        </div>
                      ) : (
                        <>
                          {fields.data?.data?.submitButtonText?.value && (
                            <button aria-label="submit" className={submitBtn()} type="submit">
                              {fields.data?.data?.submitButtonText?.value}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};
export default DeliveryAddress;
