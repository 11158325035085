import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderCloudError } from 'ordercloud-javascript-sdk';
import { OcRootState } from '../ocStore';

interface OcErrorEntry {
  error: OrderCloudError;
  timestamp: number;
}

const ocErrorAdapter = createEntityAdapter<OcErrorEntry>({
  selectId: (e) => e.timestamp,
});

const ocErrorsSlice = createSlice({
  name: 'ocErrors',
  initialState: ocErrorAdapter.getInitialState(),
  reducers: {
    logError: (state, action: PayloadAction<OrderCloudError>) => {
      // Stringify and reparse to convert error class into plain object
      // This prevents "A non-serializable value was detected in the state" error.
      const serializableError = JSON.parse(JSON.stringify(action.payload));
      ocErrorAdapter.addOne(state, {
        error: serializableError,
        timestamp: new Date().getTime(),
      });
    },
  },
});

export const ocErrorSelectors = ocErrorAdapter.getSelectors<OcRootState>((s) => s.ocErrors);

export const { logError } = ocErrorsSlice.actions;

export default ocErrorsSlice.reducer;
