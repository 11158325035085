import React, { createContext, useContext, useState, ReactNode } from 'react';
import { BaseItemNode } from '../components/ItemTreeView/TreeViewer/TreeViewer';

interface TreeCacheContextType<T extends BaseItemNode<T>> {
  cache: Record<string, T[]>;
  setCache: (id: string, children: T[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TreeCacheContext = createContext<TreeCacheContextType<any> | undefined>(undefined);

export function useTreeCache<T extends BaseItemNode<T>>() {
  const context = useContext(TreeCacheContext);
  if (!context) {
    throw new Error('useTreeCache must be used within a TreeCacheProvider');
  }
  return context as TreeCacheContextType<T>;
}

interface TreeCacheProviderProps {
  children: ReactNode;
}

export function TreeCacheProvider<T extends BaseItemNode<T>>({ children }: TreeCacheProviderProps) {
  const [cache, setCacheState] = useState<Record<string, T[]>>({});

  const setCache = (id: string, children: T[]) => {
    setCacheState((prev) => ({
      ...prev,
      [id]: children,
    }));
  };

  return (
    <TreeCacheContext.Provider value={{ cache, setCache }}>{children}</TreeCacheContext.Provider>
  );
}
