import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { LuGripVertical, LuTrash } from 'react-icons/lu';
import { ConditionValueField } from './ConditionValueField';
import DropdownField, { OptionFieldProps } from './form/DropdownField';
import {
  ConditionTypeKeys,
  ConditionTypeMapper,
  ConditionTypes,
  IPersonalizationCondition,
} from '../models';
import {
  conditionItem,
  conditionItemValueFieldWrapper,
  iconButton,
  iconDraggable,
} from '../styles';

interface ConditionItemProps {
  ruleIndex: number;
  conditionIndex: number;
  removeCondition: () => void;
  condition: IPersonalizationCondition;
}

export function ConditionItem({
  condition,
  ruleIndex,
  conditionIndex,
  removeCondition,
}: ConditionItemProps) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: typeof condition === 'string' ? condition : condition.id });

  const sortStyles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const andOrOptions: OptionFieldProps[] = ['and', 'or'].map((value: string) => ({
    value: { value: value },
    text: { value: value },
  }));

  const conditionTypeOptions: OptionFieldProps[] = Object.keys(ConditionTypes).map(
    (value: ConditionTypeKeys, index: number) => ({
      value: { value: index.toString() },
      text: { value: ConditionTypeMapper[value] },
    })
  );

  return (
    <div
      ref={setNodeRef}
      style={sortStyles}
      {...attributes}
      className={`${conditionItem()} ${typeof condition === 'string' ? 'border-none' : ''}`}
    >
      {typeof condition !== 'string' ? (
        <button type="button" {...listeners} ref={setActivatorNodeRef}>
          <LuGripVertical className={iconDraggable()} />
        </button>
      ) : null}
      <div className={typeof condition === 'string' ? 'min-w-[100px]' : 'w-full max-w-[35%]'}>
        {typeof condition === 'string' ? (
          <DropdownField
            id={`rules.${ruleIndex}.conditions.${conditionIndex}`}
            name={`rules.${ruleIndex}.conditions.${conditionIndex}`}
            options={andOrOptions}
          />
        ) : (
          <DropdownField
            id={`rules.${ruleIndex}.conditions.${conditionIndex}.type`}
            name={`rules.${ruleIndex}.conditions.${conditionIndex}.type`}
            options={conditionTypeOptions}
          />
        )}
      </div>
      <div className={conditionItemValueFieldWrapper()}>
        <ConditionValueField condition={condition} ruleIndex={ruleIndex} index={conditionIndex} />
      </div>
      {typeof condition !== 'string' ? (
        <button
          type="button"
          className={iconButton({ color: 'delete', className: 'ml-auto' })}
          onClick={removeCondition}
        >
          <LuTrash className={'text-red-500'} />
        </button>
      ) : null}
    </div>
  );
}
