//global
import React from 'react';
import clsx from 'clsx';
//local
import { navigationVariants } from './GlobalHeader';
import IconHelper from 'components/helpers/IconHelper';
import {
  Link as JssLink,
  Text,
  TextField,
  NextImage,
  Placeholder,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'components/helpers/LinkHelper';
//lib
import { DPNTargetItems } from 'lib/types/header';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
//types
type DesktopNavProps = {
  item: DPNTargetItems;
  isActive: string;
  modelref?: React.LegacyRef<HTMLUListElement | undefined> | undefined;
  handleisActive: (val: string) => void;
  parentIndex?: number; //for nested navigation
  rendering: ComponentRendering;
};
export default function DesktopNav({
  item,
  isActive,
  modelref,
  handleisActive,
  parentIndex,
  rendering,
}: DesktopNavProps): JSX.Element {
  const {
    flexGrid,
    navigationOpen,
    navigationAfter,
    liWrapper,
    subMenuWrapper,
    iconWrapper,
    h4,
    navigationLink,
    bodyRegular,
    closeIcon,
    deskSubNavWrapper,
    deskSubNavHeading,
    justifyBetween,
    normalFont,
    flexCol,
    desktopHeading,
    leading39,
    desktopIcon,
    gap2,
    textBlack,
    fontMedium,
    shopAllLink,
  } = navigationVariants();
  const hasChildren = item?.childrens?.results && item?.childrens?.results?.length > 0;
  const isEE = useExperienceEditor();
  return (
    <li key={item?.name} className={liWrapper()}>
      {item?.childrens?.results?.length > 0 ? (
        item?.link?.jsonValue && (
          <JssLink
            editable={false}
            role="button"
            field={item?.link?.jsonValue}
            className={clsx(
              'cursor-pointer',
              navigationLink(),
              `${isActive === item?.name ? clsx(navigationAfter()) : ''}`
            )}
            onClick={(e) => {
              hasChildren ? handleisActive(item?.name as string) : handleisActive(''),
                e.preventDefault();
            }}
          />
        )
      ) : item?.link?.jsonValue?.value?.href && item?.link?.jsonValue?.value?.href?.length > 0 ? (
        <LinkHelper
          onClick={() => {
            handleisActive('');
          }}
          editable={false}
          field={item?.link?.jsonValue}
          className={clsx(
            navigationLink(),
            `${isActive === item?.name ? clsx(navigationAfter(), textBlack()) : ''}`
          )}
        />
      ) : (
        ''
      )}
      {isActive !== item?.name ? null : (
        <ul
          data-component-section={'deskutility'}
          ref={modelref as React.LegacyRef<HTMLUListElement> | undefined}
          className={clsx(navigationOpen())}
        >
          <li>
            <div className={subMenuWrapper()}>
              <div className={iconWrapper()}>
                <div className={leading39()}>
                  {item?.link?.jsonValue?.value?.text && (
                    <p className={desktopHeading()}>{item?.link?.jsonValue?.value?.text}</p>
                  )}
                </div>
                <IconHelper
                  icon={'close'}
                  className={closeIcon()}
                  onClick={() => {
                    handleisActive('');
                  }}
                />
              </div>
              <div className={flexGrid()}>
                {item?.childrens &&
                  item?.childrens?.results?.flatMap((child, ind) => {
                    const gchildLength = item?.childrens?.results?.length;
                    return (
                      <div className={clsx(justifyBetween(), flexCol())} key={child?.name}>
                        {child?.childrens
                          ? child?.childrens?.results?.map((gchild, index) => {
                              return (
                                <div key={index} className={deskSubNavHeading()}>
                                  {gchild?.childrens &&
                                    !child?.hideTitle?.jsonValue?.value &&
                                    ind !== gchildLength && (
                                      <>
                                        {gchild?.icon === null && (
                                          <p className={h4()}>
                                            {gchild?.link?.jsonValue?.value?.text}

                                            <LinkHelper
                                              className={shopAllLink()}
                                              field={gchild?.shopall?.jsonValue}
                                              onClick={() => {
                                                handleisActive('');
                                              }}
                                            ></LinkHelper>
                                          </p>
                                        )}
                                      </>
                                    )}

                                  <ul className={deskSubNavWrapper()}>
                                    {gchild?.childrens?.results &&
                                      gchild?.childrens?.results?.map((c, i) => (
                                        <li key={i} className={normalFont()}>
                                          {c?.link?.jsonValue?.value?.href &&
                                          c?.link?.jsonValue?.value?.href?.length > 0 ? (
                                            <LinkHelper
                                              onClick={() => {
                                                handleisActive('');
                                              }}
                                              editable={false}
                                              field={c?.link?.jsonValue}
                                              className={clsx(bodyRegular())}
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              );
                            })
                          : ''}
                        {ind === gchildLength - 1 && (
                          <div
                            data-placeholder-id={`primary-nav-desktop-${parentIndex}`}
                            className={
                              isEE ? 'flex flex-col self-start w-full' : 'flex flex-col self-start'
                            }
                          >
                            <Placeholder
                              name={`primary-nav-desktop-${parentIndex}`}
                              rendering={rendering}
                            />
                          </div>
                        )}
                        {child?.childrens
                          ? child?.childrens?.results?.map((gchild, index) => {
                              return (
                                <div key={index} className={deskSubNavHeading()}>
                                  <ul className={deskSubNavWrapper()}>
                                    {/**Showing icons on last column */}
                                    {gchild?.icon && (
                                      <li className={normalFont()}>
                                        <div className={clsx(justifyBetween(), desktopIcon())}>
                                          <NextImage field={gchild?.icon?.jsonValue} />
                                          <div className={clsx(flexCol(), gap2())}>
                                            <Text
                                              tag="h4"
                                              className={fontMedium()}
                                              field={gchild?.iconTitle as TextField}
                                            />
                                            {gchild?.link?.jsonValue?.value?.href &&
                                            gchild?.link?.jsonValue?.value?.href?.length > 0 ? (
                                              <LinkHelper
                                                onClick={() => {
                                                  handleisActive('');
                                                }}
                                                editable={false}
                                                className={bodyRegular()}
                                                field={gchild?.link?.jsonValue}
                                              />
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              );
                            })
                          : ''}
                        {/* {item?.rfkID?.value &&
                        ind === gchildLength - 1 &&
                        child?.childrens?.results &&
                        child?.childrens?.results?.length <= 0 && (
                          <div className={deskSubNavHeading()}>
                            <PersionalizedHeaderBannerWidgetWithLink
                              rfkId={item?.rfkID?.value.toString()}
                            />
                          </div>
                        )} */}
                      </div>
                    );
                  })}
              </div>
            </div>
          </li>
        </ul>
      )}
    </li>
  );
}
