//global
import { ComponentParams, GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecoreTemplateId } from 'lib/constants';
import { getAppRoot } from 'lib/edge-runtime/graphql/get-app-root';
import React from 'react';
// import PreviewSearch from 'src/helpers/search/PreviewSearch';

import SearchResultsWidget from 'src/helpers/search/SearchResults';
import { RerenderOnRouteChange } from 'src/helpers/utility/RerenderOnRouteChange';
import { useSearchParams } from 'next/navigation';
import graphqlClientFactory from 'lib/graphql-client-factory';

const SearchListing: React.FC = ({
  facetSettings,
  visualFilterData,
}: {
  facetSettings: FacetSettingMap;
  visualFilterData: FacetFilterMap;
  params: ComponentParams;
}) => {
  const searchParams = useSearchParams();
  const query = searchParams.get('q') || searchParams.get('query') || '';

  return (
    <div>
      <RerenderOnRouteChange>
        <SearchResultsWidget
          rfkId={'rfkid_7'}
          facetSettings={facetSettings}
          defaultKeyphrase={query}
          visualFiltersData={visualFilterData}
        />
      </RerenderOnRouteChange>
    </div>
  );
};

export default SearchListing;

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  const graphQLClient = graphqlClientFactory({});
  const siteName = layoutData.sitecore.context.site?.name ?? 'psp';
  if (!siteName) {
    console.error('Site name not found');
    return {
      facetSettings: {},
    };
  }
  const language = layoutData.sitecore.context.language ?? 'en';
  if (!language) {
    console.error('language not found');
    return {
      facetSettings: {},
    };
  }
  const appRootId = await getAppRoot(siteName, language);
  if (!appRootId) {
    console.error(`appRootId not found for site: "${siteName}" in language: "${language}"`);
    return {
      facetSettings: {},
    };
  }
  const facetSettings = await getFacetSettings();
  const visualFilterSettings = await getVisualFilterSettings();

  return {
    facetSettings: facetSettings,
    visualFilterData: visualFilterSettings,
  };

  // Function to call GQL visual filter for search query:
  async function getVisualFilterSettings() {
    const visualFilterData = await graphQLClient.request<QueryResponse>(visualFilterQuery, {
      language: layoutData?.sitecore?.context?.language,
      appRootId,
    });
    const visualFilterResponse = visualFilterData?.search?.results[0];
    return visualFilterResponse;
  }

  async function getFacetSettings() {
    const data = await graphQLClient.request<QueryResponse>(query, {
      language: layoutData?.sitecore?.context?.language,
      appRootId,
    });

    const facetSettings = data.search.results
      .filter((x) => x.facetName?.value)
      .reduce((prev, curr) => {
        const facet: FacetSetting = {
          facetName: curr.facetName?.value ?? '',
          facetLabelOverride: curr.facetLabelOverride?.value ?? '',
          facetDefaultOpen: curr.facetDefaultOpen?.value === '1',
          facetValueOverrideMap:
            curr.facetValueOverrideMap?.values.reduce((prev, curr) => {
              return { ...prev, [curr.name]: decodeURI(curr?.value) };
            }, {} as Record<string, string>) ?? {},
          mergedFacets: curr.mergedFacets?.value.split('|').filter(Boolean) ?? [],
        };

        prev[facet.facetName] = facet;

        return prev;
      }, {} as FacetSettingMap);
    return facetSettings;
  }
};

export type FacetSettingMap = Record<string, FacetSetting | undefined>;
export type FacetFilterMap = Record<string, FacetFilter | undefined>;

export interface FacetFilter {
  [key: string]: string;
}

export interface FacetSetting {
  facetName: string;
  facetLabelOverride: string;
  facetDefaultOpen: boolean;
  facetValueOverrideMap: Record<string, string>;
  mergedFacets: string[];
}
interface QueryResponse {
  search: {
    results: {
      facetName?: {
        value: string;
      };
      facetLabelOverride?: {
        value: string;
      };
      facetDefaultOpen?: {
        value: '1' | '0' | '';
      };
      facetValueOverrideMap?: {
        values: {
          name: string;
          value: string;
        }[];
      };
      mergedFacets?: {
        value: string;
      };
    }[];
  };
}

// TODO Update this to be site specific
const query = /* GraphQL */ `
  query GetFacets($appRootId: String!, $language: String = "en") {
    search(
      where: {
        AND: [
          # Hard coded query values need to appear before variable ones.
          { name: "_templates", value: "${SitecoreTemplateId.FacetItem}" }
          { name: "_path", value: $appRootId }
          { name: "_language", value: $language }
        ]
      }
      first: 100
    ) {
      results {
        ... on FacetItem {
          facetName {
            value
          }
          facetLabelOverride {
            value
          }
          facetDefaultOpen {
            value
          }
          facetValueOverrideMap {
            values {
              name
              value
            }
          }
          mergedFacets {
            value
          }
        }
      }
    }
  }
`;

// Query for Visual Filters upon seach query:
const visualFilterQuery = `
{
  search(
    where: {
      AND: [
        { name: "_path", value: "{60EB345F-EEFA-4476-99C1-D37ED2180FE6}" }
        { name: "_templates", value: "{46FDA11A-AE74-4F69-9BBD-6250CC02C360}" }
      ]
    }
  ) {
    results {
      path
      children(first: 100) {
        results {
          ... on SearchPhraseMapItem {
            exclusionMatch{
              value
            }
            exclusions{
              value
            }
            searchPhrase{
              value
            }
            wordMatch{
              value
            }
            visualFilterDatasource {
              jsonValue
            }
          }
        }
      }
    }
  }
}`;
