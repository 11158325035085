import React, { useState } from 'react';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { tv } from 'tailwind-variants';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { FAQProps, FAQItem, TabItemProps, QuestionProps } from './helpers/types';
import Accordion from './helpers/faqAccordion';
import Tab from './helpers/faqTab';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { faqContainerQuery, getStoreDetails } from 'lib/faqContainer.graphql';
import { useOcSelector } from 'src/redux/ocStore';
import graphqlClientFactory from 'lib/graphql-client-factory';

export type FAQContainerProps = FAQProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Faq.Fields.Faqcontainer;

const FAQContainer: React.FC<FAQContainerProps> = ({ staticProps, params }) => {
  const fieldsData = staticProps?.datasource;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const tabs: TabItemProps[] =
    fieldsData?.selectCategories?.targetItems?.reduce((acc: TabItemProps[], category, id) => {
      const myStoreServices = myStoreData?.services;
      const myStoreServicesArray = myStoreServices ? myStoreServices.split('|') : [];

      // TODO verify if this code is still valid.  It looks like the category template doesn't have a "storeIds" field.
      if (category.storeIds && category.storeIds.length > 0) {
        if (myStoreServicesArray.includes(category.storeIds[0]?.value)) {
          const tab: TabItemProps = {
            id: category?.categoryName?.value + '-' + id,
            title: category?.categoryName?.value || '',
            faq:
              category?.selectQuestions?.targetItems?.map((question: QuestionProps) => ({
                question: { value: question?.question?.value } || { value: '' },
                answer: { value: question?.answer?.value } || { value: '' },
              })) || [],
            configuration: category?.storeConfiguration?.value || '',
          };
          acc.push(tab);
        }
      } else {
        const tab: TabItemProps = {
          id: category?.categoryName?.value + '-' + id,
          title: category?.categoryName?.value || '',
          faq:
            category?.selectQuestions?.targetItems?.map((question: QuestionProps) => ({
              question: { value: question?.question?.value } || { value: '' },
              answer: { value: question?.answer?.value } || { value: '' },
            })) || [],
          configuration: category?.storeConfiguration?.value || '',
        };
        acc.push(tab);
      }
      return acc;
    }, []) || [];

  const defaultCategoryId = `${fieldsData?.defaultCategory?.value}-default-category`;

  if (fieldsData?.showDefaultCategory?.jsonValue?.value) {
    if (fieldsData?.showDefaultCategoryAtLast?.jsonValue?.value) {
      tabs.push({
        id: defaultCategoryId,
        title: fieldsData?.defaultCategory?.value,
        faq: [],
      });
    } else {
      tabs.unshift({
        id: defaultCategoryId,
        title: fieldsData?.defaultCategory?.value,
        faq: [],
      });
    }
  }

  const [activeTab, setActiveTab] = useState(tabs[0]?.id || '');
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  const handleTabClick = (tabId: string) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
      setActiveIndices([]);
    }
  };

  const toggleAccordion = (index: number) => {
    const currentIndex = activeIndices.indexOf(index);
    const newActiveIndices = [...activeIndices];

    if (currentIndex === -1) {
      newActiveIndices.push(index);
    } else {
      newActiveIndices.splice(currentIndex, 1);
    }

    setActiveIndices(newActiveIndices);
  };

  const getAllQuestionsAndAnswers = () => {
    let allQuestionsAndAnswers: FAQItem[] = [];

    tabs.forEach((tab) => {
      allQuestionsAndAnswers = allQuestionsAndAnswers.concat(tab.faq);
    });
    return allQuestionsAndAnswers;
  };

  const FAQContainerClass = tv(
    {
      slots: {
        base: ['flex justify-center'],
        container: ['container'],
        top: ['flex', 'flex-col', 'gap-desk-margin-loose-right', 'pb-desk-margin-base-bottom'],
        heading: [''],
        title: [
          'font-primary',
          'text-color-text-black',
          'text-heading-desk-xLarge-bold',
          'font-heading-desk-xLarge-bold',
          'leading-heading-desk-xLarge-bold',
          'mb-desk-margin-micro-bottom',
        ],
        titleWithoutDescription: [
          'text-heading-desk-small-bold',
          'font-heading-desk-small-bold',
          'leading-heading-desk-small-bold',
        ],
        description: [
          'font-primary',
          'text-color-text-black',
          'text-body-large-regular',
          'font-body-large-regular',
          'leading-body-large-regular',
        ],
        faqTabs: [
          'flex',
          'flex-wrap',
          'items-end',
          'self-stretch',
          'gap-desk-space-between-base-horizontal',
        ],
        faqAccordion: ['pt-desk-margin-base-bottom'],
      },
      variants: {
        size: {
          mobile: {
            container: ['my-mob-padding-base-y', 'mob-global-grid-margin'],
          },
          desktop: {
            container: ['my-desk-global-grid-margin'],
          },
        },
      },
    },
    {
      responsiveVariants: ['lg'],
    }
  );

  const {
    base,
    container,
    top,
    heading,
    title,
    titleWithoutDescription,
    description,
    faqTabs,
    faqAccordion,
  } = FAQContainerClass({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  return (
    <div className={base()}>
      <div className={container()}>
        {params?.FieldNames !== 'WithoutDescription' && (
          <div className={top()}>
            <div className={heading()}>
              {fieldsData?.title?.value && (
                <RichTextHelper className={title()} field={fieldsData?.title} />
              )}
              {fieldsData?.description?.value && (
                <RichTextHelper className={description()} field={fieldsData?.description} />
              )}
            </div>
          </div>
        )}

        <div className={faqTabs()}>
          {params?.FieldNames === 'WithoutDescription' && fieldsData?.title?.value && (
            <RichTextHelper className={titleWithoutDescription()} field={fieldsData?.title} />
          )}
          {!fieldsData?.hideCategory?.jsonValue?.value &&
            tabs.map((tab) => (
              <Tab
                key={tab.id}
                tab={tab}
                isActive={activeTab === tab.id}
                onClick={handleTabClick}
              />
            ))}
        </div>
        <div className={faqAccordion()}>
          {!fieldsData?.hideCategory?.jsonValue?.value ? (
            activeTab === defaultCategoryId ? (
              <Accordion
                faq={getAllQuestionsAndAnswers()}
                activeIndices={activeIndices}
                toggleAccordion={toggleAccordion}
              />
            ) : (
              <Accordion
                faq={tabs.find((tab) => tab.id === activeTab)?.faq || []}
                activeIndices={activeIndices}
                toggleAccordion={toggleAccordion}
              />
            )
          ) : (
            <Accordion
              faq={getAllQuestionsAndAnswers()}
              activeIndices={activeIndices}
              toggleAccordion={toggleAccordion}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData, context) => {
  if (rendering.dataSource && layoutData?.sitecore?.context?.language) {
    const graphQLClient = graphqlClientFactory({});

    const data = await graphQLClient.request<FAQContainerProps>(faqContainerQuery, {
      datasource: rendering?.dataSource,
      language: layoutData?.sitecore?.context?.language,
    });

    const filteredItems = (data?.datasource?.selectCategories?.targetItems || []).filter(
      (item) =>
        item.storeConfiguration?.value !== undefined && item.storeConfiguration?.value !== ''
    );

    const storeConfigurations = await Promise.all(
      filteredItems.map(async (item) => {
        const storeConfigurationValue = item.storeConfiguration?.value;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const secondResult = await graphQLClient.request<any>(
          getStoreDetails(storeConfigurationValue)
        );
        return secondResult;
      })
    );

    const updatedTabs = data?.datasource?.selectCategories?.targetItems?.map((item) => {
      if (item.storeConfiguration?.value !== undefined && item.storeConfiguration?.value !== '') {
        return {
          ...item,
          storeIds:
            storeConfigurations[0]?.pageOne?.results?.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (result: any) => result?.serviceId?.value
            ) || [],
        };
      } else {
        return {
          ...item,
          storeIds: [],
        };
      }
    });

    const updatedData = {
      ...data,
      datasource: {
        ...data.datasource,
        selectCategories: {
          ...data.datasource.selectCategories,
          targetItems: updatedTabs || [],
        },
      },
    };

    return {
      staticProps: updatedData,
    };
  }

  throw new Error(
    `No datasource set for rendering: ${rendering.componentName}, Guid: ${
      layoutData.sitecore.route?.itemId
    }, Path: ${
      context.params?.path instanceof Array ? context.params.path.join('/') : context.params?.path
    }`
  );
};

export const WithDescription = (props: FAQContainerProps): JSX.Element => {
  return <FAQContainer {...props} />;
};
export const WithoutDescription = (props: FAQContainerProps): JSX.Element => {
  return <FAQContainer {...props} />;
};

export default FAQContainer;
