import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

const Loader = ({ className }: { className?: string }) => {
  return (
    <NextImage
      className={className}
      field={{
        value: {
          src: '/images/loader.gif',
          alt: 'loader',
          width: '32',
          height: '32',
        },
      }}
    />
  );
};

export default Loader;
