// import { configureStore, createSlice } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface Location {
  Id: number;
  CompanyId: number;
  StreetAddress: string;
  StreetAddress2: string | null;
  City: string;
  State: string;
  ZipCode: string;
  PhoneNumber: string;
  TimeZone: string;
  Lat: string;
  Lng: string;
  PspStoreId: string;
}

interface Service {
  Id: number;
  DealId: number;
  Name: string;
  Description: string | null;
  Duration: number;
  SortOrder: number;
  Amount: number;
}

interface Appointment {
  Id: number;
  Status: string;
  BeginAt: string;
  EmployeeName: string;
  Location: Location;
  ChildId: number;
  ChildName: string;
  ChildBreed: string;
  Services: Service[];
}

const initialState = {
  appointments: {} as Appointment,
};
export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setAppointments: (state, action) => {
      state.appointments = action.payload;
    },
  },
});

export const { setAppointments } = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
