import { ProductData } from 'components/ProductDetail/ProductDetail';
import useDictionary from 'src/hooks/useDictionary';
import { useOcSelector } from 'src/redux/ocStore';
import { BuyerProductWithXp } from 'src/redux/xp';

export function useAutoshipMessage({
  product,
  autoshipMarketingText,
}: {
  product?: BuyerProductWithXp | ProductData;
  autoshipMarketingText: string;
}) {
  const { getDictionaryValueWithReplacement } = useDictionary();
  const receivedFirstTimeAutoshipDiscount = useOcSelector(
    (state) => state.ocUser.user?.xp?.ReceivedFirstTimeAutoshipDiscount
  );

  const store = useOcSelector((state) => state.storeReducer.selectedStore);

  if (!product) {
    return '';
  }
  if (!product.xp?.Autoship) {
    return '';
  }

  const sub = boolToYN(receivedFirstTimeAutoshipDiscount);
  const ft = boolToYN(product.xp.FirstTimeAutoshipDiscount);
  const recur = boolToYN(product.xp.ReccuringAutoshipDiscount);

  const key = `${autoshipMarketingText}_Sub-${sub}_FT-${ft}_Recur-${recur}`;
  return getDictionaryValueWithReplacement(
    key,
    {
      x: store.autoshipSettings?.firstTimeDiscount?.toString() ?? '35',
      y: store.autoshipSettings?.subsequentOrdersDiscount?.toString() ?? '5',
      z: store.autoshipSettings?.firstTimeDiscountLimit?.toString() ?? '25',
    },
    // Fallback explicitly empty string because we don't want to display they key if dictionary lookup fails
    ''
  );
}
export function useFullfillmentSwitcherReplacement(originalvalue: string) {
  const store = useOcSelector((state) => state.storeReducer.selectedStore);

  const replacement = {
    PERCENT1: store.autoshipSettings?.firstTimeDiscount?.toString() ?? '35',
    PERCENT2: store.autoshipSettings?.subsequentOrdersDiscount?.toString() ?? '5',
    PRICE: store.autoshipSettings?.firstTimeDiscountLimit?.toString() ?? '25',
  };
  let value = originalvalue;
  if (replacement) {
    Object.keys(replacement).forEach((key: keyof typeof replacement) => {
      value = value.replace(`[${key}]`, replacement[key]);
    });
  }
  return value;
}

export function boolToYN(value?: boolean) {
  return value ? 'Y' : 'N';
}
