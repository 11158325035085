import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from 'lib/context/ThemeContext';

/**
 * Returns "psp" or "wagnwash"
 */
export function useSiteName() {
  const context = useSitecoreContext();
  const siteName = context.sitecoreContext.site?.name ?? 'psp';
  return siteName;
}

/**
 * Returns "PSP" or "WNW"
 */
export function useSiteNameForApi() {
  const { themeNameUpper } = useTheme();
  return themeNameUpper;
}
