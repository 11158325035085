import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import useExperienceEditor from '../../hooks/useExperienceEditor';

interface LinkProps {
  field?: Field<string>;
  updatedField?: Field<string>;
  className?: string;
  tag?: string;
}

const RichTextHelper: React.FC<LinkProps> = ({
  field,
  updatedField,
  className,
  tag,
}: LinkProps) => {
  const isEE = useExperienceEditor();

  if (!field) {
    return <></>;
  }
  if (isEE) {
    return <RichText field={field} tag={tag} className={`rte ${className}`} />;
  }
  const fieldToRender = updatedField || field;

  if (!fieldToRender?.value) {
    return <></>;
  }
  // A `p` tag cannot be nested in a `p` tag.  If it's nested, replace the parent tag with a div to make it valid.
  // Invalid nesting causes the browser to rendering it differently causing a hydration error.
  if (tag === 'p' && fieldToRender?.value.includes('<p')) {
    tag = 'div';
  }
  return <RichText field={fieldToRender} tag={tag} className={`rte ${className}`} />;
};

export default RichTextHelper;
