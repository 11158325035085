// Global
import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import React, { ReactElement } from 'react';
import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';
import useSWR from 'swr';

export type Variant = 'outlined' | 'rounded' | 'sharp';

export type IconHelperProps = {
  className?: string;
  fill?: boolean;
  icon: string;
  variant?: Variant;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const IconHelper = ({
  className,
  fill,
  icon,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: //   variant = 'sharp' as Variant,
IconHelperProps): ReactElement => {
  const publicURL = publicURLTrailingSlash();
  const iconUri = `${publicURL}icons/${icon}${fill ? '-fill' : ''}.svg`;
  const fetcher = async (url: string) => {
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Failed to fetch icon: ${res.statusText}`);
    }

    return res.text();
  };
  const { data } = useSWR(iconUri, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  // Handle onClick event
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return data ? (
    <span
      className={`${className}`}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {convertHtmlToReact(data)}
    </span>
  ) : (
    <></>
  );
};

export default IconHelper;
