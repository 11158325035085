//global
import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'components/helpers/LinkHelper';
import ImageHelper from 'components/helpers/ImageHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
//type
export type BrandsListingProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.BrandsListing.Fields.BrandsListing;
//component variants
const brandsListingVariants = tv(
  {
    slots: {
      base: ['brandsListing', 'container', 'mx-auto', 'flex', 'flex-col'],
      titleWrapper: ['w-full', 'text-center', 'mb-mob-padding-base-y'],
      brandWrapper: ['grid'],
      linkWraper: [
        'px-mob-padding-micro-y',
        'max-w-[180px]',
        'flex',
        'h-fit',
        'justify-center',
        'flex-col',
        'flex-col-reverce',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-tight-y'],
          titleWrapper: [
            'text-heading-mob-xLarge-bold ',
            'font-heading-mob-xLarge-bold ',
            'leading-heading-mob-xLarge-bold',
          ],
          brandWrapper: ['grid-cols-3', 'gap-y-mob-margin-loose-bottom'],
        },
        desktop: {
          base: ['py-desk-padding-tight-y'],
          titleWrapper: [
            'text-heading-desk-xLarge-bold ',
            'font-heading-desk-xLarge-bold ',
            'leading-heading-desk-xLarge-bold',
          ],
          brandWrapper: ['grid-cols-6', 'gap-y-desk-margin-loose-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const BrandsListing: React.FC<BrandsListingProps> = ({ fields, params }) => {
  const { base, titleWrapper, brandWrapper, linkWraper } = brandsListingVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div id={'brandsListing'} className={base({ className: params?.Style ?? '' })}>
      <RichTextHelper field={fields?.heading} className={titleWrapper()} />
      <div className={brandWrapper()}>
        {fields?.selectBrands?.map((brand, index) => {
          return (
            <LinkHelper field={brand?.fields?.link} key={index} className={linkWraper()}>
              <ImageHelper field={brand?.fields?.image} />
            </LinkHelper>
          );
        })}
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<BrandsListingProps>(BrandsListing);
