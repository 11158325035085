import { GTMLabels, currencyType } from 'components/helpers/Constants';
import { getProductPriceFromSearch } from 'src/helpers/ProductPriceUI';
import {
  ProductPDP,
  ProductSearchResultModelWithVariants,
} from 'src/helpers/search/SearchResults/types';

declare global {
  interface Window {
    dataLayer?: object[];
  }
}
interface EventProps {
  storeId?: string;
}
interface EventName {
  eventName?: string;
}
interface EventParams extends EventProps {
  ecommerce: { items: Items[] };
}
export interface Items {
  promotion_id?: string;
  promotion_name?: string;
  componentName?: string;
  creative_name?: string;
  creative_slot?: number;
  promotion_device?: string;
  promotion_copy?: string;
  promotion_dates?: string;
  promotion_cta?: string;
  promotion_url?: string;
}
/**
 * Sends events to Google Tag Manager.
 * @param eventName - The name of the event to be sent.
 * @param eventParams - The parameters associated with the event.
 */
export const sendGTMEvent = (eventName: string, eventParams: EventProps) => {
  if (!eventParams || typeof eventParams !== 'object' || Array.isArray(eventParams)) {
    console.warn('Invalid eventParams. Expected an object.');
    return;
  }

  if (typeof window === 'undefined') {
    return;
  }

  if (!window['dataLayer']) {
    window['dataLayer'] = [];
    console.warn(
      `Google Tag Manager is not initialized. The event '${eventName}' could not be sent.`
    );
  }
  // const isUserDataPassed =
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   window && (window as any)['dataLayer']?.filter((val: any) => val?.loggedIn);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const isGTMLoad = (window as any)['google_tag_manager']?.dataLaye?.gtmLoad;
  // if (!isGTMLoad) {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   (window as any)?.['google_tag_manager'].dataLayer['gtmLoad'] = true;
  //   window.dataLayer.push({
  //     event: 'gtm.load',
  //     'gtm.start': new Date().getTime(),
  //     'gtm.uniqueEventId': 'gtm.load',
  //   });
  // }

  // isUserDataPassed?.length > 0 &&
  window['dataLayer'].push({
    ...(eventName && eventName?.length > 0 && { event: eventName }),
    ...eventParams,
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPromotionObject = (fields: any, deviceName: string) => {
  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(fields?.componentName?.value),
      creative_slot: Number(fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(`${fields?.promotionDateFROM?.value} - ${fields?.promotionDateTO?.value}`)
      ),
      promotion_cta: notAvailableIfNullOrEmpty(fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(fields?.promotionURL?.value?.href),
    },
  ];
  return gtmPromotion;
};

export const formatDateForGTM = (promotionDates: string) => {
  if (/^\d{8}T\d{6}Z\s-\s\d{8}T\d{6}Z$/.test(promotionDates)) {
    const pDate = promotionDates.replace(
      /^(\d{4})(\d{2})(\d{2})T\d{6}Z\s-\s(\d{4})(\d{2})(\d{2})T\d{6}Z$/,
      '$1-$2-$3 - $4-$5-$6'
    );
    if (pDate === ' - ' || pDate === '0001-01-01 - 0001-01-01') {
      return '';
    } else {
      return pDate;
    }
  }

  // For 'YYYY-MM-DDTHH:mm:ssZ - YYYY-MM-DDTHH:mm:ssZ' format
  const pDate = promotionDates.replace(
    /^(\d{4})-(\d{2})-(\d{2})T.*\s-\s(\d{4})-(\d{2})-(\d{2})T.*$/,
    '$1-$2-$3 - $4-$5-$6'
  );
  if (pDate === ' - ' || pDate === '0001-01-01 - 0001-01-01') {
    return '';
  } else {
    return pDate;
  }
};

export const trackObjectForPromotion = (eventName: string, storeId: string, promotion: Items[]) => {
  const promotionObj: EventParams = {
    storeId: storeId,
    ecommerce: {
      items: [],
    },
  };
  promotionObj.ecommerce.items = promotion;
  const isGTMLoad =
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
  if (isGTMLoad) {
    sendGTMEvent(eventName, promotionObj);
  } else {
    setTimeout(() => {
      trackObjectForPromotion(eventName, storeId, promotion);
    }, 1000);
  }
};

export const notAvailableIfNullOrEmpty = (s: string | undefined) => {
  if (s == null || s == undefined || s === '') return GTMLabels?.notAvailable;
  return s;
};

//Handling the PLP and Search products GTM promotion

export interface SendProductsPromotionProps {
  eventName: string;
  data: ProductSearchResultModelWithVariants[] | ProductSearchResultModelWithVariants;
  currentPage?: string;
  pageItem?: string;
  position?: string | number;
  fulfillment_option?: string;
  storeId?: string;
  click_from?: string;
  parentId?: string;
  quantity?: number;
  listPrice?: number;
  category?: string;
  totalCount?: number;
  coupon?: string;
  currency?: boolean;
  ShippingTier?: string;
  transactionId?: string;
  affiliation?: string;
  tax?: string;
  shipping?: number;
  deliveryTip?: string;
  coupCurrencyOn?: string;
  paymentMethod?: string;
  purchaseType?: string;
  purchaseChannel?: string;
  retailType?: string;
  isRatingShow?: boolean;
}
export interface GTMDataObj {
  [key: string]: string | number | boolean | undefined;
}
[];
//function to send Products Promotion
/**
 * @param eventName EventName e.g. click_event, view_event, itemList_event
 * @param data Product Data
 * @param currentPage Page name from where the event is fired
 * @param pageItem if it is from the search page then the query or else the heading one
 * @param fulfillment_option DFS or BIOPS
 * @param storeId current store Id
 * @param click_from from where the click event is fired i.e. PDP, Mini Cart, Shopping Cart etc...
 */
export const sendProductsPromotion = ({
  eventName,
  data,
  currentPage,
  pageItem,
  position,
  fulfillment_option,
  storeId,
  click_from,
  parentId,
  quantity,
  listPrice,
  totalCount,
  category,
  coupon,
  currency,
  ShippingTier,
  coupCurrencyOn,
  deliveryTip,
  paymentMethod,
  shipping,
  tax,
  transactionId,
  purchaseChannel,
  purchaseType,
  retailType,
  affiliation,
  isRatingShow,
}: SendProductsPromotionProps) => {
  const dataObj = {
    ...(fulfillment_option && { fulfillment_option: fulfillment_option }),
    ...(storeId && { storeId: storeId }),
    ...(purchaseChannel && { purchaseChannel: purchaseChannel }),
    ...(purchaseType && { purchaseType: purchaseType }),
    ...(retailType && { retailType: retailType }),
    ...(click_from && { click_from: click_from }),
    ...(purchaseChannel && { purchaseChannel: purchaseChannel }),
    ...(purchaseType && { purchaseType: purchaseType }),
    ...(retailType && { retailType: retailType }),
    ecommerce: {
      ...(transactionId && { transaction_id: transactionId }), // transaction id
      ...(storeId && { affiliation: storeId }), // affiliation
      ...(tax && { tax: tax }), // tax
      ...(shipping && { shipping: Number(shipping)?.toFixed(2) || Number(0)?.toFixed(2) }), // delivery fee
      ...(deliveryTip && {
        delivery_tip: !isNaN(Number(deliveryTip))
          ? deliveryTip?.length > 0
            ? Number(deliveryTip).toFixed(2)
            : Number(0)?.toFixed(2)
          : Number(0)?.toFixed(2),
      }), // deliveryTip
      ...(coupCurrencyOn && { coupcurrencyon: coupCurrencyOn }), // coupon if any
      ...(paymentMethod && { payment_method: paymentMethod }),
      ...(currency && { currency: currencyType }), // currency
      ...(totalCount && { value: totalCount?.toFixed(2) }), //total value (EXCL. tax, shipping/delivery fee, and delivery tip)
      ...(coupon && { coupon: coupon?.toUpperCase() }),
      ...(ShippingTier && { shipping_tier: ShippingTier }),
      items: [] as { [key: string]: string | number | boolean | undefined }[],
    },
  };

  Array.isArray(data)
    ? data?.map((product, index) => {
        dataObj?.ecommerce?.items?.push(
          GTMObject({
            product: product,
            currentPage: currentPage,
            index: Number((index || position) ?? 0),
            pageItem: pageItem,
            quantity: quantity,
            storeId: storeId,
            affiliation: affiliation,
            isRatingShow: isRatingShow,
          })
        );
      })
    : dataObj?.ecommerce?.items?.push(
        GTMObject({
          product: data,
          currentPage: currentPage,
          index: Number(position ?? 0),
          pageItem: pageItem,
          parentId: parentId,
          quantity: quantity,
          listPrice: listPrice,
          category: category,
          isRatingShow: isRatingShow,
        })
      );

  dataObj && dataObj?.ecommerce?.items?.length > 0 && sendGTMEvent(eventName, dataObj);
};

//user
interface UserData extends EventProps {
  visitorType?: string;
  loggedIn?: string;
  userId?: string;
  unityId?: string;
}
/**
 *
 * @param loggedIn "Y" if user loggedIn else "N"
 * @param storeId current store Id
 * @param unityId if user loggedIn then pass user unity id from xp value
 * @param userId if user loggedIn then pass userId
 * @param visitorType for loggedIn user "LoggedIn" and "Guest" for non-loggedIn user
 */
export const sendUserGTMdata = ({ loggedIn, storeId, unityId, userId, visitorType }: UserData) => {
  const dataObj = {
    ...(userId && { userId: userId }),
    ...(unityId && { unityId: unityId }),
    loggedIn,
    storeId,
    visitorType,
  };
  dataObj && sendGTMEvent('', dataObj);
};

//creating GTM promotion object
const GTMObject = ({
  product,
  index,
  currentPage,
  pageItem,
  parentId,
  quantity,
  listPrice,
  category,
  storeId,
  affiliation,
  isRatingShow,
}: {
  product: ProductPDP;
  index?: number;
  currentPage?: string;
  pageItem?: string;
  parentId?: string;
  quantity?: number;
  listPrice?: number;
  category?: string;
  storeId?: string;
  affiliation?: string;
  isRatingShow?: boolean;
}) => {
  const productPrice = getProductPriceFromSearch(product);
  const productCategory = product?.xp?.Path
    ? product?.xp?.Path?.split('/')
    : product?.Xp?.Path
    ? product?.Xp?.Path?.split('/')
    : '';
  const itemAvailability = product?.inventory?.quantityavailable
    ? product?.inventory?.quantityavailable <= 0
      ? GTMLabels?.notAvailable
      : product?.inventory?.quantityavailable
    : product?.Inventory?.QuantityAvailable || 0 < 0
    ? GTMLabels?.notAvailable
    : product?.Inventory?.QuantityAvailable;

  const productName =
    product?.xp?.ParentFamilyName ||
    product?.Xp?.ParentFamilyName ||
    product?.name?.split('-')?.[0]?.trim() ||
    product?.Name?.split('-')?.[0]?.trim();
  //returning an object for GTM Promotion
  return {
    item_id: product?.xp?.UPC || product?.Xp?.UPC,
    item_name: productName,
    item_group: (product?.parentid || product?.ParentID || parentId) ?? GTMLabels?.notAvailable,
    custom_itemId: product?.id || product?.ID,
    ...((storeId || affiliation) && { affiliation: storeId || affiliation }),
    coupon: GTMLabels?.notAvailable, // todo:  will applies when coupon part will implemented
    currency: currencyType, //USD
    discount: Number(0).toFixed(2), // always to be 0
    index: (index && index + 1) || 1,
    item_brand: product?.xp?.Brand || product?.Xp?.Brand || GTMLabels?.notAvailable,
    ...(productCategory
      ? productCategories(productCategory, 'item_category')
      : product?.category_names
      ? productCategories(product?.category_names, 'item_category')
      : { item_category: category || GTMLabels?.notAvailable }),
    ...(pageItem && { item_list_name: pageItem }),
    ...(currentPage && { item_list_id: currentPage }),
    item_variant: product?.name || product?.Name || GTMLabels?.notAvailable,
    item_size:
      (product?.xp?.RetailUnit || product?.Xp?.RetailUnit) +
      ' ' +
      (product?.xp?.RetailMeasure || product?.Xp?.RetailMeasure),
    ...(product?.xp?.Flavor || product?.Xp?.Flavor
      ? { item_flavor: product?.xp?.Flavor?.join(',') || product?.Xp?.Flavor?.join(',') }
      : { item_flavor: GTMLabels?.notAvailable }),
    item_color: product?.xp?.BrandedColor || product?.Xp?.BrandedColor || GTMLabels?.notAvailable,
    item_image:
      (product?.xp?.Images?.length && product?.xp?.Images?.length > 0
        ? GTMLabels?.yes
        : GTMLabels?.no) ||
      (product?.Xp?.Images?.length && product?.Xp?.Images?.length > 0
        ? GTMLabels?.yes
        : GTMLabels?.no),
    item_availability: itemAvailability,
    item_autoship_availability: product?.xp?.Autoship
      ? GTMLabels?.yes
      : product?.Xp?.Autoship
      ? GTMLabels?.yes
      : GTMLabels?.no,
    ...(isRatingShow && {
      item_stars:
        Number(product?.xp?.BVRating ?? 0).toFixed(1) ||
        Number(product?.Xp?.BVRating || 0).toFixed(1) ||
        0,
    }),
    ...(isRatingShow && {
      item_reviews: product?.xp?.BVReviews || product?.Xp?.BVReviews || 0,
    }),
    price: productPrice?.memberPrice || product?.BasePrice || product?.PriceSchedule?.xp?.PPCPrice,
    listprice:
      productPrice?.listPrice ||
      product?.PriceSchedule?.xp?.ListPrice ||
      product?.listPrice ||
      listPrice,
    quantity: product?.quantity || quantity || 1,
  };
};

//generating dynamic categories/flavors object from the array
const productCategories = (itemName: string[], keyName: string) => {
  const categoryObj: { [key: string]: string } = {};
  itemName?.map(
    (item, index) =>
      (categoryObj[`${keyName}${index + 1 !== 1 ? index + 1 : ''}`] = item?.toLocaleLowerCase())
  );
  return categoryObj;
};

//getting the session storage for GTM
export const getGTMSessionStorage = (): {
  currentPage: string;
  pageItem: string;
  position: string;
} => {
  if (typeof window !== 'undefined') {
    const currentPage = window?.sessionStorage?.getItem(GTMLabels?.currentPage);
    const pageItem = window?.sessionStorage?.getItem(GTMLabels?.pageItem);
    const position = window?.localStorage?.getItem(GTMLabels?.position);
    return {
      currentPage: currentPage ?? '',
      pageItem: pageItem ?? '',
      position: position ?? '',
    };
  }
  return {
    currentPage: '',
    pageItem: '',
    position: '',
  };
};

//
interface StoreGTMDataProps extends EventProps, EventName {
  milesAway?: string;
  storesShown?: string | number;
  error?: string;
  groomingStoreId?: string;
}

//sending Store LocatorData
export const sendStoreGTMData = ({
  storeId,
  eventName,
  milesAway,
  storesShown,
  error,
  groomingStoreId,
}: StoreGTMDataProps) => {
  const dataObj = {
    ...(storeId && { storeId: storeId }),
    ...(error && { error: error }),
    ...(milesAway && { milesAway: milesAway }),
    ...(groomingStoreId && { groomingStoreId: groomingStoreId }),
    storesShown: storesShown,
  };
  dataObj && sendGTMEvent(eventName || '', dataObj);
};

//Sending/Tracking grooming data

// Step 1

type GroomingStepsDataParams = {
  storeId?: string;
  eventName?: string;
  groomingStoreId?: string;
  stepName?: string;
};

export const groomingStepsData = ({
  storeId,
  eventName,
  groomingStoreId,
  stepName,
}: GroomingStepsDataParams) => {
  const dataObj: Partial<GroomingStepsDataParams> = {
    ...(storeId && { storeId: storeId }),
    ...(groomingStoreId && { groomingStoreId: groomingStoreId }),
    ...(stepName && { stepName: stepName }),
  };
  if (dataObj) {
    sendGTMEvent(eventName || '', dataObj);
  }
};

// Step 4

type GroomingStep4DataParams = {
  event?: string; // "groomingSubmit"
  storeId?: string; // user's store ID for shopping
  groomingStoreId: string; // user's selected grooming store, can be different from storeId
  stepName: string; // name of the current step
  revenue: string; // the sum of all services scheduled
  scheduledTime?: string; // selected time of the appointment
  scheduledDate?: string; // selected date of the appointment
  searchBy?: string; // how the appointment date/time was searched by (e.g., Grooming Professional and Date Range, or Next Available Appointment)
  dogBreed?: string; // dog's breed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dogAge?: any; // dog's age in years, if possible (current date – dog's birth date)
  dogGender?: string; // dog's gender
  quantity?: number; // product quantity
  items: {
    itemName: string; // service name
    category: string; // service category
    price: string; // service price
  }[];
};

export const groomingStep4Data = ({
  event,
  storeId,
  groomingStoreId,
  stepName,
  revenue,
  scheduledTime,
  scheduledDate,
  searchBy,
  dogBreed,
  dogAge,
  dogGender,
  quantity,
  items,
}: GroomingStep4DataParams) => {
  const dataObj: Partial<GroomingStep4DataParams> = {
    ...(storeId && { storeId: storeId }),
    ...(groomingStoreId && { groomingStoreId: groomingStoreId }),
    ...(stepName && { stepName: stepName }),
    ...(revenue && { revenue: revenue }),
    ...(scheduledTime && { scheduledTime: scheduledTime }),
    ...(scheduledDate && { scheduledDate: scheduledDate }),
    ...(searchBy && { searchBy: searchBy }),
    ...(dogBreed && { dogBreed: dogBreed }),
    ...(dogAge !== undefined ? { dogAge: dogAge } : {}), // Ensure dogAge is included even if 0
    ...(dogGender && { dogGender: dogGender }),
    ...(quantity && { quantity: quantity }),
    ...(items && { items: items }),
  };
  if (dataObj) {
    sendGTMEvent(event || '', dataObj);
  }
};

// Grooming cancel appointment

type GroomingCancelAppointmentParams = {
  event?: string;
  storeId?: string;
  groomingStoreId: string;
  groomingApptId: string;
  revenue: string;
  items: {
    itemName: string; // service name
    category?: string; // service category
    price: string; // service price
  }[];
};

export const groomingCancelAppointmentData = ({
  event,
  storeId,
  groomingStoreId,
  revenue,
  groomingApptId,
  items,
}: GroomingCancelAppointmentParams) => {
  const dataObj: Partial<GroomingCancelAppointmentParams> = {
    ...(storeId && { storeId: storeId }),
    ...(groomingStoreId && { groomingStoreId: groomingStoreId }),
    ...(revenue && { revenue: revenue }),
    ...(groomingApptId && { groomingApptId: groomingApptId }),
    ...(items && { items: items }),
  };
  if (dataObj) {
    sendGTMEvent(event || '', dataObj);
  }
};
