import { rfkidOf } from 'components/helpers/Constants';
import React from 'react';
import OriginalPreviewSearch from 'src/helpers/search/PreviewSearch';
import { tv } from 'tailwind-variants';

const PreviewSearch: React.FC = () => {
  const previewSearchTV = tv(
    {
      slots: {
        baseWrapper: ['flex', 'justify-center', 'bg-color-background-light'],
      },
      variants: {
        size: {
          mobile: {
            baseWrapper: [
              'pt-mob-padding-tight-y',
              'pb-mob-padding-micro-y',
              'px-mob-global-grid-margin',
            ],
          },
          desktop: {
            baseWrapper: [
              'pt-desk-padding-tight-y',
              'pb-desk-padding-micro-y',
              'px-desk-global-grid-margin',
            ],
          },
        },
      },
    },
    {
      responsiveVariants: ['lg'],
    }
  );
  const { baseWrapper } = previewSearchTV({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  return (
    <div className={baseWrapper()}>
      <OriginalPreviewSearch rfkId={rfkidOf?.search} />
    </div>
  );
};

export default PreviewSearch;
