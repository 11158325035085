import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'components/helpers/IconHelper';
import ModalWrapper from 'components/helpers/ModalWrapper';
import TextHelper from 'components/helpers/TextHelper';
import Loader from 'components/Loader/Loader';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useSiteNameForApi } from 'src/hooks/useSiteName';
import apiConfig, { SubscriptionResponse } from 'src/utils/apiConfig';
import { apiRequest, ApiRequestOptions } from 'src/utils/apiWrapper';
import { tv } from 'tailwind-variants';
const CancelAutoshipModalVariants = tv(
  {
    slots: {
      base: 'CancelAutoship flex flex-col',
      errorMessage:
        'font-primary text-body-small-regular leading-body-small-regular font-body-small-regular text-system-red',
      buttonWrapper: 'flex',
      title:
        'font-primary text-color-text-black font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
      description:
        'font-primary text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      warningHeading:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      loadingWrapper:
        'font-primary w-full py-desk-component-button-full-padding-y flex text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
      buttonWithBg:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      button:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold ',
      dropdownIcon: 'absolute cursor-pointer top-1/2 transform -translate-y-1/2 right-2',
      contentWrapper: 'flex flex-col',
      reasonWrapper: '',
      cancellationReasonTitle:
        'font-primary mb-desk-margin-micro-bottom text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      cancellationReasonSelect:
        'focus:outline-none w-full border-2 rounded-mob-global-radius-small focus:border-color-border-brand cursor-pointer appearance-none py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
      cancellationReasonInput:
        'focus:outline-none w-full border-2 border-color-border-mid rounded-mob-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
      cancellationReasonSelectWrapper: 'relative w-[280px]',
      cancellationReasonSelectWrapperInput: 'relative w-full',
    },

    variants: {
      size: {
        desktop: {
          contentWrapper: 'gap-desk-margin-micro-bottom',
          reasonWrapper: 'mt-desk-margin-base-top',
          base: 'px-desk-padding-tight-x py-desk-padding-tight-y gap-desk-space-between-tight-vertical',
          // warningHeading: 'mt-desk-margin-micro-bottom',
          buttonWrapper: 'mt-desk-margin-base-top flex-row gap-desk-space-between-base-horizontal',
          cancellationReasonTitle: 'mb-desk-margin-micro-bottom',
          cancellationReasonSelect:
            'rounded-mob-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
          cancellationReasonInput:
            'rounded-mob-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
          errorMessage: 'mt-desk-margin-micro-bottom',
        },
        mobile: {
          errorMessage: 'mt-mob-margin-micro-bottom',
          contentWrapper: 'gap-mob-margin-micro-bottom',
          cancellationReasonTitle: 'mb-mob-margin-micro-bottom',
          cancellationReasonSelect:
            'rounded-mob-global-radius-small py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4',
          cancellationReasonInput:
            'rounded-mob-global-radius-small py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4',

          reasonWrapper: 'mt-mob-margin-base-top',
          base: 'px-mob-padding-tight-x py-mob-padding-tight-y gap-mob-space-between-tight-vertical',
          // warningHeading: 'mt-mob-margin-micro-bottom',
          buttonWrapper: 'mt-mob-margin-base-top flex-col gap-mob-space-between-base-horizontal',
        },
      },
      isError: {
        true: { cancellationReasonSelect: 'border-system-red' },
        false: { cancellationReasonSelect: 'border-color-border-mid' },
      },
      isLoading: {
        true: {
          button: 'bg-color-brand-primary-1-disabled cursor-not-allowed text-color-text-white',
        },
        false: {
          button:
            'text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white focus:text-color-text-white hover:border-color-brand-primary-1-dark focus:border-color-brand-primary-1-dark hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
interface CancelAutoshipProps {
  fields: {
    title?: Field<string>;
    description?: Field<string>;
    warningHeading?: Field<string>;
    confirmCancellationText?: Field<string>;
    loaderText?: Field<string>;
    ctaText?: Field<string>;
    button?: Field<string>;
    enterYourReasonText?: Field<string>;
    reasonForCancellationText?: Field<string>;
    errorMessageForCancellation?: Field<string>;
    reasonList?: {
      fields?: {
        reasonItem?: Field<string>;
      };
    }[];
  };
}
const CancelAutoship = ({ fields }: CancelAutoshipProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reasonOfCancellation, setReasonOfCancellation] = useState<false | string>(false);
  const [otherReasonOfCancellation, setOtherReasonOfCancellation] = useState<false | string>(false);
  const {
    base,
    title,
    description,
    button,
    warningHeading,
    buttonWrapper,
    loadingWrapper,
    buttonWithBg,
    dropdownIcon,
    contentWrapper,
    reasonWrapper,
    cancellationReasonTitle,
    cancellationReasonSelect,
    cancellationReasonInput,
    cancellationReasonSelectWrapper,
    cancellationReasonSelectWrapperInput,
    errorMessage,
  } = CancelAutoshipModalVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const handleModalClose = () => {
    setIsLoading(false);
    setIsError(false);
    setReasonOfCancellation(false);
    setOtherReasonOfCancellation(false);
    setAutoshipContextData({
      ...autoshipContextData,
      isCancelClicked: false,
      subscriptionData: false,
    });
  };

  const site = useSiteNameForApi();
  const token = useAuthToken();
  const onCancelClicked = async () => {
    //TODO: need to refactor this in future (this static message)
    if (reasonOfCancellation && reasonOfCancellation != 'Choose from these options') {
      setIsLoading(true);
      const cancellationReason =
        reasonOfCancellation === 'Other'
          ? otherReasonOfCancellation
            ? otherReasonOfCancellation
            : reasonOfCancellation
          : reasonOfCancellation;

      const options: ApiRequestOptions = {
        method: 'POST',
        data: {
          SubscriptionId: autoshipContextData.subscriptionData.ID,
          CancellationReason: cancellationReason,
        },
        headers: {
          Authorization: token,
          site: site,
        },
      };
      const response = await apiRequest<SubscriptionResponse>(
        apiConfig.autoship.cancelAutoship,
        options
      );
      setIsLoading(false);
      setReasonOfCancellation(false);
      setIsError(false);
      setOtherReasonOfCancellation(false);
      setAutoshipContextData({
        ...autoshipContextData,
        isCancelClicked: false,
        subscriptionData: false,
        isCancelledSuccessfully: response.Success,
        cancelledAutoshipId: autoshipContextData?.subscriptionData?.ID,
        autoshipEndDate: DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ"),
        autoshipCancellationReason: cancellationReason,
      });
    } else {
      setIsError(true);
    }
  };
  if (fields === undefined || fields === null) return <></>;

  return (
    <>
      <ModalWrapper
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={autoshipContextData?.isCancelClicked && autoshipContextData?.subscriptionData}
        customPopup={true}
        popupWidth="max-w-[997px]"
        popupSpacing="p-0"
      >
        <div className={base()}>
          <RichText className={title()} field={fields?.title} />
          <div className={contentWrapper()}>
            <RichText className={description()} field={fields?.description} />
            <RichText className={warningHeading()} field={fields?.warningHeading} />
            <div className={reasonWrapper()}>
              <TextHelper
                field={fields?.reasonForCancellationText}
                className={cancellationReasonTitle()}
              />
              <div className={cancellationReasonSelectWrapper()}>
                <select
                  aria-label="cancellationReason"
                  className={cancellationReasonSelect({
                    isError: isError,
                  })}
                  onChange={(e) => {
                    setIsError(false);
                    setReasonOfCancellation(e.target?.value);
                  }}
                  value={reasonOfCancellation ? reasonOfCancellation : ''}
                >
                  {fields?.reasonList?.map((reason, index) => (
                    <option key={index} value={reason?.fields?.reasonItem?.value}>
                      {reason?.fields?.reasonItem?.value}
                    </option>
                  ))}
                </select>
                <IconHelper className={dropdownIcon()} icon="dropdown" />
              </div>
              {isError ? (
                <RichText className={errorMessage()} field={fields?.errorMessageForCancellation} />
              ) : null}
            </div>
            {reasonOfCancellation === 'Other' && (
              <div className={reasonWrapper()}>
                <TextHelper
                  field={fields?.enterYourReasonText}
                  className={cancellationReasonTitle()}
                />
                <div className={cancellationReasonSelectWrapperInput()}>
                  <input
                    aria-label="cancel autoship input"
                    className={cancellationReasonInput()}
                    onChange={(e) => {
                      setOtherReasonOfCancellation(e.target?.value);
                    }}
                  ></input>
                </div>
              </div>
            )}
            <div className={buttonWrapper()}>
              {isLoading ? (
                <div className={loadingWrapper()}>
                  <Loader />
                  {fields?.loaderText?.value}
                </div>
              ) : (
                <>
                  {fields?.confirmCancellationText?.value && (
                    <button
                      aria-label="confirm cancellation"
                      className={buttonWithBg()}
                      onClick={onCancelClicked}
                    >
                      {fields?.confirmCancellationText?.value}
                    </button>
                  )}
                </>
              )}
              {fields?.ctaText?.value && (
                <button
                  aria-label="close button"
                  className={button({
                    isLoading,
                  })}
                  onClick={handleModalClose}
                >
                  {fields?.ctaText?.value}
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default CancelAutoship;
