//global
import React, { useContext } from 'react';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import IconHelper from 'components/helpers/IconHelper';
import TextHelper from 'components/helpers/TextHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import ComponentContext from 'lib/context/ComponentContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useOcSelector } from 'src/redux/ocStore';
import { useTheme } from 'lib/context/ThemeContext';
import { closedText } from 'components/helpers/Constants';
// import { useSelector } from 'react-redux';
//type
export type ChangeMyStoreProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeMyStoreWidget.Fields.ChangeMyStoreWidget;
//component variants
export type theme = 'psp' | 'wnw';
const changeMyStoreVariants = tv(
  {
    slots: {
      base: ['change-my-store', 'font-primary', 'relative', 'shadow-changeStore', 'flex-col'],
      storeName: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      storeAddress: [
        'mt-2',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      storeNumber: [
        'block',
        'mt-desk-global-spacing-spacing-3',
        'focus:outline-0',
        'underline',
        'text-color-brand-primary-1-base',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      timingWrapper: ['flex', 'items-end'],
      storeTiming: [
        'mt-desk-global-spacing-spacing-3',
        'mr-1',
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      openingHour: [
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      buttonWrapper: [''],
      openText: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      cta: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'text-color-text-brand-1',
        'border',
        'hover:no-underline',
        'hover:bg-color-brand-primary-1-base',
        'hover:text-color-text-white',
        'rounded-desk-global-radius-small',
        'border-2',
        'border-color-brand-primary-1-base',
      ],
      icon: [
        '[&>svg>*>*]:fill-color-scale-8-dark [&>svg>*]:fill-color-scale-8-dark',
        'absolute',
        'top-[16px]',
        'right-[16px]',
        'h-6',
        'w-6',
      ],
    },
    compoundSlots: [{ slots: ['base', 'buttonWrapper', 'icon'], class: ['flex'] }],
    variants: {
      size: {
        mobile: {
          base: [
            'py-mob-padding-tight-y',
            'px-mob-padding-micro-x',
            'h-screen',
            'w-full',
            'gap-mob-space-between-tight-vertical',
            // 'translate-y-0 animate-[topAnimation_0.3s_ease-in-out]',
          ],
          buttonWrapper: ['gap-mob-space-between-tight-vertical', 'py-mob-padding-micro-y'],
          cta: ['py-desk-component-button-full-padding-y', 'px-[20px]'],
        },
        desktop: {
          base: [
            'w-full',
            'max-w-[426px]',
            'rounded-desk-global-radius-medium',
            'h-full',
            'py-desk-padding-tight-y',
            'px-desk-padding-micro-x',
            'gap-desk-space-between-tight-vertical',
            'animate-none',
          ],
          buttonWrapper: ['gap-desk-space-between-tight-vertical', 'py-desk-padding-micro-y'],
          cta: ['py-mob-component-button-full-padding-y', 'px-[30px]'],
          icon: ['cursor-pointer'],
        },
      },
      theme: {
        psp: {},
        wnw: {
          cta: ['!text-[17px]'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const ChangeMyStore: React.FC<ChangeMyStoreProps> = ({ fields, rendering }): JSX.Element => {
  const { themeName } = useTheme();
  const {
    base,
    storeName,
    storeAddress,
    timingWrapper,
    storeNumber,
    storeTiming,
    cta,
    buttonWrapper,
    icon,
  } = changeMyStoreVariants({
    size: { initial: 'mobile', lg: 'desktop' },
    theme: themeName,
  });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const router = useRouter();
  const now = new Date();
  const day = now.getDay();
  //Store Data
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={base({
        className: `${componentContextData?.isChangeStoreOpen ? 'block' : 'hidden'}`,
      })}
    >
      <IconHelper
        icon="close"
        className={icon()}
        onClick={() => {
          setcomponentContextData({ ...componentContextData, isChangeStoreOpen: false });
        }}
      />
      <div id={'store-detial'}>
        <p className={storeName()}>{storeData?.storeName}</p>
        <p className={storeAddress()}>{storeData?.street1}</p>
        <p className="text-2">
          {storeData?.city}, {storeData?.state} {storeData?.zip}
        </p>
        <Link
          aria-label="phone number link"
          href={`tel:${storeData?.phone}`}
          className={storeNumber()}
        >
          {storeData?.phone}
        </Link>
        <div className={timingWrapper()}>
          {storeData?.hours &&
          Object?.values(storeData?.hours)?.[day] &&
          Object?.values(storeData?.hours)?.[day] === closedText ? (
            <span
              className={storeTiming({
                className: '!font-body-small-bold',
              })}
            >
              {storeData?.hours && Object?.values(storeData?.hours)?.[day]?.toUpperCase()}
            </span>
          ) : (
            <>
              <RichTextHelper field={fields?.storeTimingLabel} className={storeTiming()} />
              <span>{storeData?.hours && (Object?.values(storeData?.hours)?.[day] as string)}</span>
            </>
          )}
        </div>
      </div>
      <div id={'store-change'} className={buttonWrapper()}>
        <button
          aria-label="store-change"
          className={cta()}
          onClick={() => {
            setcomponentContextData({
              ...componentContextData,
              showStoreLocatorGlobalModal: true,
              isChangeStoreOpen: false,
            });
          }}
        >
          <TextHelper field={fields?.changeStoreLabel} />
        </button>
        <LinkHelper
          onClick={(e) => {
            e.preventDefault();
            router.push(
              `/store/${storeData?.state?.toLowerCase()}/${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}-${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}`?.replaceAll(
                ' ',
                '-'
              )
            );
            setcomponentContextData({ ...componentContextData, isChangeStoreOpen: false });
          }}
          field={{
            value: {
              href: `/store/${storeData?.state?.toLowerCase()}/${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}-${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}`?.replaceAll(
                ' ',
                '-'
              ),
              text: fields?.storeDetailsCTA?.value?.text,
            },
          }}
          className={cta()}
        />
      </div>
      <Placeholder name="change-store-personalised" rendering={rendering} />
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<ChangeMyStoreProps>(ChangeMyStore);
