import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import ButtonHelper from 'components/helpers/Button';
import ModalWrapper from 'components/helpers/ModalWrapper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import TextHelper from 'components/helpers/TextHelper';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import addATipVariants from '../Checkout/AddATip/AddATipVariant';
import { clsx } from 'clsx';
import { Me } from 'ordercloud-javascript-sdk';
import { AddATipProps } from 'components/Checkout/AddATip/AddATip';
import Loader from 'components/Loader/Loader';

const CancelledAutoshipModalVariants = tv(
  {
    slots: {
      base: 'MyAutoshipAddAtip flex flex-col gap-6',
      buttonWrapper: 'flex',
      tipLabel: '',
      priceError: ['text-system-red'],
      title:
        'font-primary text-color-text-black font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
      subTitle:
        'font-primary text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      description:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      warningHeading:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      loadingWrapper:
        'font-primary w-full py-desk-component-button-full-padding-y flex text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
      buttonWithBg:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      button:
        'h-fit w-fit px-desk-component-button-full-padding-x rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white focus:text-color-text-white hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark ',
      customTipWrapper: ['flex w-full gap-desk-global-spacing-spacing-5 '],
      currencyLabel: ['input-group-addon absolute top-[27%] left-[1.5%]'],
      tipField: [
        'outline-none',
        'border-2',
        'text-color-text-black',
        'placeholder:text-color-text-black',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        '!pl-5',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
        'appearance-none',
        'bg-color-scale-1-white',
      ],
      tipButton: [
        '!w-max !text-body-large-bold !font-body-large-bold !leading-body-large-bold !py-desk-global-spacing-spacing-3',
      ],
      loader: [
        'absolute h-full w-full top-0 left-0 bg-black opacity-25 flex justify-center items-center border-b-[1px]',
      ],
    },
    compoundSlots: [
      {
        slots: ['tipLabel', 'tipField'],
        class: ['text-body-large-regular font-body-large-regular leading-body-large-regular'],
      },
    ],
    variants: {
      size: {
        desktop: {
          base: 'px-desk-padding-tight-x py-desk-padding-tight-y',
          warningHeading: 'mt-desk-margin-micro-bottom',
          buttonWrapper: 'mt-desk-margin-base-top flex-row gap-desk-space-between-base-horizontal',
          tipField: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
          tipLabel: ['mx-2'],
        },
        mobile: {
          base: 'px-mob-padding-tight-x py-mob-padding-tight-y',
          warningHeading: 'mt-mob-margin-micro-bottom',
          buttonWrapper: 'mt-mob-margin-base-top flex-col gap-mob-space-between-base-horizontal',
          tipField: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
          tipLabel: [''],
        },
      },
    },
  },

  { responsiveVariants: ['lg'] }
);

const MyAutoshipAddAtip = ({ fields }: AddATipProps) => {
  const { base, title, description, subTitle } = CancelledAutoshipModalVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    priceError,
    buttonWrapper,
    tipLabel,
    tipCard,
    currencyLabel,
    tipField,
    customTipWrapper,
    customTip,
    tipButton,
    tipContainer,
    loader,
  } = addATipVariants({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const [customTipSaved, setCustomTipSaved] = useState('0.00');
  const [customTipUnsaved, setCustomTipUnsaved] = useState('0.00');
  const [customTipError, setCustomTipError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [isCustomTip, setIsCustomTip] = useState(false);
  const hasCustomTip = parseFloat(customTipSaved) > 0;
  const [tipLineItem, setTipLineItem] = useState<{
    ID?: string;
    xp?: {
      TipAmount?: number;
      TipIsRecurring?: boolean;
    };
  }>();
  const tipPrice = fields?.tipPrice?.value;
  const tips = [...new URLSearchParams(tipPrice).entries()];
  const handleModalClose = () => {
    setIsCustomTip(false);
    setShowCustomTip(false);
    setCustomTipSaved('0');
    setCustomTipUnsaved('0');
    setAutoshipContextData({
      ...autoshipContextData,
      openTipModal: false,
      subscriptionData: false,
    });
  };
  useEffect(() => {
    if (autoshipContextData?.openTipModal) {
      setTipLineItem(autoshipContextData?.subscriptionData?.tipDetails);
      if (
        autoshipContextData?.subscriptionData?.tipDetails &&
        !tips?.find(
          (tip) => tip?.[1] == autoshipContextData?.subscriptionData?.tipDetails?.xp?.TipAmount
        )
      ) {
        setIsCustomTip(true);
        setShowCustomTip(true);
        setCustomTipSaved(
          autoshipContextData?.subscriptionData?.tipDetails?.xp?.TipAmount?.toFixed(2)
        );
        setCustomTipUnsaved(
          autoshipContextData?.subscriptionData?.tipDetails?.xp?.TipAmount?.toFixed(2)
        );
      }
    }
  }, [autoshipContextData?.openTipModal]);
  const setTip = async (amount: number) => {
    if (autoshipContextData?.subscriptionData?.ID && tipLineItem?.ID) {
      setIsLoading(true);
      await Me.PatchSubscriptionItem(autoshipContextData?.subscriptionData?.ID, tipLineItem?.ID, {
        LineSubtotal: amount,
        LineTotal: amount,
        xp: {
          ...tipLineItem?.xp,
          TipAmount: amount,
        },
      });
      setAutoshipContextData({
        ...autoshipContextData,
        openTipModal: false,
        subscriptionData: false,
        autoshipTipAmount: amount,
        subscriptionIdForTipChange: autoshipContextData?.subscriptionData?.ID,
      });
      setIsLoading(false);
      setIsCustomTip(false);
      setShowCustomTip(false);
      setCustomTipSaved('0');
      setCustomTipUnsaved('0');
    }
  };

  if (fields === undefined || fields === null) return <></>;
  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={handleModalClose}
      showModal={autoshipContextData?.openTipModal}
      customPopup={true}
      popupWidth="max-w-[733px]"
      popupSpacing="p-0"
    >
      <div className={base()}>
        {isLoading && (
          <div className={loader()}>
            <Loader />
          </div>
        )}
        <div className="flex flex-col gap-mob-margin-tight-bottom lg:gap-desk-margin-tight-bottom">
          <RichText className={title()} field={fields?.title} />
          <RichTextHelper className={subTitle()} field={fields?.subHeading} />
        </div>
        <div
          id="delvey-tipping-content-container"
          className="flex flex-col gap-desk-margin-base-bottom"
        >
          <div className={tipContainer()}>
            <ul className="flex w-full">
              {tips.map(([label, value], index) => {
                const amount = parseInt(value) || 0;
                const lineItemTipAmount = tipLineItem?.xp?.TipAmount || 0;
                return (
                  index !== tips?.length - 1 && (
                    <li
                      key={label}
                      className={clsx(tipCard(), {
                        'font-bold !border-color-border-brand border-x-2 [&+li]:!border-l-0 ':
                          lineItemTipAmount === amount && !hasCustomTip,
                        'border-l-2': lineItemTipAmount !== amount && !hasCustomTip,
                        'border-l-2 rounded-l': index === 0,
                        'hidden ': index === tips?.length - 1,
                        '!border-color-border-mid font-normal !border-r-0 [&+li]:!border-l-2':
                          isCustomTip,
                      })}
                      onClick={() => {
                        setShowCustomTip(false);
                        setIsCustomTip(false);
                        setCustomTipSaved('0.00');
                        setCustomTipUnsaved('0.00');
                        setTip(amount);
                      }}
                    >
                      <p className={tipLabel()}>{label}</p>
                    </li>
                  )
                );
              })}
              <li
                className={clsx(customTip(), {
                  'font-bold ': hasCustomTip,
                  '!border-color-border-brand': isCustomTip,
                })}
                onClick={() => {
                  setShowCustomTip(true);
                  setIsCustomTip(true);
                }}
              >
                {hasCustomTip ? <span className="block">${customTipSaved}&nbsp;</span> : null}
                <TextHelper tag="span" field={fields?.customTip} className="hidden lg:flex" />
                <TextHelper tag="span" field={fields?.customTipMobile} className="flex lg:hidden" />
              </li>
            </ul>
          </div>
        </div>
        {showCustomTip && (
          <div id="delivery-tip-custom-amount-body" className={customTipWrapper()}>
            <div className="delivery-tip-input w-full">
              <div className="relative">
                <span className={currencyLabel()}>$</span>
                <input
                  aria-label="tip-amount"
                  className={tipField()}
                  value={customTipUnsaved}
                  type="text"
                  onChange={(e) => {
                    const value = e.target?.value;
                    setCustomTipUnsaved(value);
                    const numValue = parseFloat(value);
                    if (isNaN(numValue) || numValue < 0 || isNaN(Number(value))) {
                      setCustomTipSaved('0.00');
                      setCustomTipError(fields?.errorMessage?.value || '');
                    } else {
                      setCustomTipError('');
                      setCustomTipSaved(numValue?.toFixed(2));
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target?.value;
                    const numValue = parseFloat(value);

                    setCustomTipUnsaved(numValue.toFixed(2));
                    if (isNaN(numValue) || numValue < 0 || isNaN(Number(value))) {
                      setCustomTipSaved('0.00');
                      setCustomTipError(fields?.errorMessage?.value || '');
                    } else {
                      setCustomTipError('');
                      setCustomTipSaved(numValue?.toFixed(2));
                    }
                  }}
                />
              </div>

              {customTipError ? <div className={priceError()}>{customTipError}</div> : null}
            </div>
            <div className={buttonWrapper()}>
              {fields?.addtip && (
                <ButtonHelper
                  onClickHandler={() => {
                    const numValue = parseFloat(customTipSaved);
                    setTip(numValue);
                    setShowCustomTip(false);
                  }}
                  field={fields?.addtip}
                  size={'default'}
                  isLinkfield={false}
                  variant={customTipError?.length > 0 ? 'disabled' : 'primary'}
                  className={tipButton()}
                />
              )}
            </div>
          </div>
        )}
        <RichTextHelper className={description()} field={fields?.deliveryNote} />
        <RichTextHelper className={description()} field={fields?.autoshipNote} />
        <RichTextHelper className={description()} field={fields?.sucessMessage} />
      </div>
    </ModalWrapper>
  );
};

export default MyAutoshipAddAtip;
