import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'components/helpers/IconHelper';
import React, { useState, useEffect } from 'react';
import { tv } from 'tailwind-variants';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import Link from 'next/link';

export type BackToTopProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.BackToTop.Fields.BackToTopArrow;

const bactToTopVariant = tv({
  slots: {
    base: 'backToTop fixed transition-opacity flex flex-cols justify-center items-center duration-1000 ease-in bottom-[104px] z-[2] right-8 p-4 rounded-full text-color-scale-1-white bg-color-brand-primary-1-base cursor-pointer shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] w-[40px] h-[40px]',
    icon: '[&>svg>*>*]:fill-color-brand-primary-1-base h-6 w-6 [&_svg]:max-w-6 [&_svg]:max-h-6',
  },
  variants: {
    isVisible: {
      true: {
        base: 'opacity-100 z-0',
      },
      false: {
        base: 'opacity-0 z-[-1]',
      },
    },
  },
});
/**
 * BackToTop component scrolls the page to the top when clicked.
 */
const BackToTop: React.FC<BackToTopProps> = ({ fields, params }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { base, icon } = bactToTopVariant({
    isVisible,
  });
  // Show the button when the user scrolls down 200 pixels
  const handleScroll = () => {
    setIsVisible(window.scrollY > 200);
  };

  // Scroll to top when the button is clicked
  const scrollToTop = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${params?.Styles ?? ''}`}>
      <Link aria-label="back to top" href="/" onClick={scrollToTop} className={base()}>
        <IconHelper className={icon()} icon={'chevron-up'} />
        <Text tag="span" fields={fields?.backToTopText} className="sr-only" />
      </Link>
    </div>
  );
};

export default BackToTop;
