/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from 'clsx';
import { ErrorMessage, useField } from 'formik';
import { tv } from 'tailwind-variants';

export type FieldWrapperProps = React.InputHTMLAttributes<HTMLInputElement> & {
  children?: React.ReactNode | React.ReactNode[];
  label?: string;
  name: string;
  hasError: boolean;
  hasFullWidth?: boolean;
  validate?: string;
  helptext?: string;
  className?: string;
  hasStackedHelper?: boolean;
};

const FieldWrapperVariant = tv(
  {
    slots: {
      fieldWrapperContainer: ['flex', 'flex-col'],
      labelContainer: ['flex', 'flex-row', 'gap-[2px]'],
      labels: [
        'text-color-text-black',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      errorStyle: [
        'text-system-red',
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      helpTextStyle: [
        'font-body-large-regular',
        'text-body-large-regular',
        'leading-body-large-regular',
      ],
    },
    variants: {
      device: {
        mobile: {
          fieldWrapperContainer: ['w-full'],
          labels: ['gap-mob-margin-micro-bottom'],
        },
        desktop: {
          fieldWrapperContainer: ['w-full', 'flex-wrap', 'gap-desk-margin-micro-bottom'],
          labels: ['gap-desk-margin-micro-bottom'],
        },
      },
      hasStackedHelper: {
        //for managing helper text alongside of label or below label
        true: {
          labelContainer: ['flex-col'],
        },
        false: {
          labelContainer: ['flex-row'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const FieldWrapper = (props: FieldWrapperProps): JSX.Element => {
  const { fieldWrapperContainer, labels, errorStyle, helpTextStyle, labelContainer } =
    FieldWrapperVariant({
      device: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });

  const [{}, { error, touched }] = useField(props.name);

  return (
    <div className={clsx(fieldWrapperContainer(), props?.className)}>
      {props.label && props.hasStackedHelper && (
        <div className={labelContainer({ hasStackedHelper: props?.hasStackedHelper })}>
          {props.label && (
            <label htmlFor={props.id} className={labels()}>
              {props.required ? '*' : ''}
              {props.label}
            </label>
          )}
          {props.helptext && <span className={helpTextStyle()}>{props.helptext}</span>}
        </div>
      )}
      {props.children}
      {touched && error && (
        <ErrorMessage className={errorStyle()} name={props.name} component="div" />
      )}
    </div>
  );
};

export default FieldWrapper;
