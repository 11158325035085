import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import accountSettingsGraphql from '../lib/query.accountSettingsGraphql';
import axios from 'axios';
import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';

type ConfigurationState = {
  status: string;
  loading: boolean;
  data: ConfigurationData;
  error: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ConfigurationData = Record<string, any>;

type ConfigurationGraphQLResponse = {
  graphQLResponse?: {
    data: {
      item: {
        fields: {
          name: string;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: any;
        }[];
      };
    };
  };
};
const initialState: ConfigurationState = {
  status: '',
  loading: true,
  data: {
    AccountUnlock: '/accountunlock',
  },
  error: '',
};

// TODO: need to dynamically call psp or wnw based on theme in the path:
const path = `/sitecore/content/PetSuppliesPlus/psp/Data/Commerce/Accounts`;
const language = 'en';

export const getConfiguration = createAsyncThunk('configuration/getConfiguration', async () => {
  try {
    const publicURL = publicURLTrailingSlash();
    const response = await axios.get<ConfigurationGraphQLResponse>(
      `${publicURL}api/graphql/getData?path=${path}&language=${language}&graphQLQuery=${accountSettingsGraphql}`
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseObj: ConfigurationData = {};
    response?.data?.graphQLResponse?.data?.item?.fields.forEach(({ name, value }) => {
      responseObj[name] = value;
    });
    // for (const items in response?.data?.graphQLResponse?.data?.item?.fields) {
    //   responseObj[response?.data?.graphQLResponse?.data?.item?.fields[items]['name']] =
    //     response?.data?.graphQLResponse?.data?.item?.fields[items]['value'];
    // }
    return responseObj;
  } catch (err) {
    return err;
  }
});

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfiguration.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.data = action.payload as ConfigurationData;
      })
      .addCase(getConfiguration.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = true;
        state.error = action.error.message || '';
      });
  },
});
export const selectLoading = (state: ConfigurationState) => state.status;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getConfigurationData = (state: { data: any }) => state.data;

export default configurationSlice.reducer;
