// ProductDetails/fetchProductData.ts

import { getSiteName } from 'src/helpers/siteNameHelper';
import apiConfig from '../../utils/apiConfig';
import { apiRequest } from '../../utils/apiWrapper';

interface ProductUrlData {
  ID?: string;
  // Add other fields as necessary
}

export interface FetchProductDataResult extends ProductUrlData {
  productData: ProductUrlData;
  parentId: string;
  productSku: string;
}

interface CheckProductAssociationResponse {
  redirect: boolean;
  // Add other fields if necessary
}

export const checkProductAssociation = async (
  productId: string | undefined,
  myStoreId: string | undefined,
  siteName: { name?: string } | undefined
): Promise<boolean> => {
  try {
    if (productId && myStoreId && siteName?.name) {
      const url = `${apiConfig?.productAPI?.getProductAssociationCheck
        ?.replace('{productId}', productId)
        ?.replace('{myStoreId}', myStoreId)}`;
      const response: CheckProductAssociationResponse = await apiRequest(url, {
        method: 'GET',
        headers: {
          site: getSiteName(siteName?.name)?.toUpperCase(),
        },
      });
      return response.redirect;
    }
    return false; // this true will be redirect to 404 Page from PDP page.
  } catch (err) {
    console.error('err', err);
    throw new Error('Error checking product association:');
  }
};
