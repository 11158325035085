import { tv } from 'tailwind-variants';

const progressBarTailwind = tv(
  {
    slots: {
      base: ['progressBar', 'flex', 'flex-col', 'items-center'],
      wrapper: ['bg-gray-200', 'h-4', 'w-full'],
      progressFill: ['h-full', 'bg-color-brand-autoship'],
      label: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-color-brand-autoship',
        'text-center',
      ],
    },
    compoundSlots: [
      { slots: ['wrapper', 'progressFill'], class: ['rounded-mob-global-radius-pill'] },
    ],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-global-spacing-spacing-1', 'gap-mob-margin-micro-bottom'],
        },
        desktop: {
          base: ['py-desk-global-spacing-spacing-1', 'gap-desk-margin-micro-bottom'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
export default progressBarTailwind;
