import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { OptionFieldProps } from '../components/form/DropdownField';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { IDropdownItem, IXmcQueryResponse } from '../models';
import axios from 'axios';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const RuleOptionsContext = createContext({
  serviceOptions: [] as OptionFieldProps[],
  speciesOptions: [] as OptionFieldProps[],
});

export function useRuleOptions() {
  const context = useContext(RuleOptionsContext);
  if (!context) {
    throw new Error('useRuleOptions must be used within a RuleOptionsProvider');
  }
  return context;
}

interface RuleOptionsProviderProps {
  children: ReactNode;
}

/**
 * Populates the options for rules used by the personalization component
 * such as services, and pet species.
 */
export const RuleOptionsProvider = ({ children }: RuleOptionsProviderProps) => {
  const isEE = useExperienceEditor();
  const {
    sitecoreContext: { site },
  } = useSitecoreContext();
  const siteName = site?.name;

  const PATHS = {
    DEFAULT: {
      SERVICES: '{69E0365A-1A86-414A-85CB-DCAF1B0DA682}',
    },
    WAGNWASH: {
      SERVICES: '{355932E5-69B8-4535-B3CB-32265A98CFD2}',
    },
  };

  const selectedPaths = siteName === 'wagnwash' ? PATHS.WAGNWASH : PATHS.DEFAULT;

  // temporary way of viewing the experience editor while developing locally (set isEditor to true in localStorage)
  const isDevEE = typeof window !== 'undefined' && localStorage.getItem('isEditor') === 'true';
  const isEditor = isDevEE || isEE;
  const [serviceOptions, setServiceOptions] = useState<OptionFieldProps[]>([]);
  const [speciesOptions, setSpeciesOptions] = useState<OptionFieldProps[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const toOption = (item: IDropdownItem): OptionFieldProps => ({
    value: { value: item?.value?.value },
    text: { value: item.label?.value },
  });

  const servicesQuery = `query($path: String!, $language: String!) {
    item(language: $language, path: $path) {
      children(first:25) {
        results {
          label: field(name: "Name") {
            value
          }
          value: field(name: "serviceId") {
            value
          }
        }
      }
    }
  }
  `;

  const userGroupsQuery = `query($path: String!, $language: String!) {
    item(language: $language, path: $path) {
      children(first:50) {
        results {
          label: field(name: "Phrase") {
            value
          }
          value: field(name: "Key") {
            value
          }
        }
      }
    }
  }`;

  useEffect(() => {
    if (!isEditor) {
      return;
    }
    if (isLoaded) {
      return;
    }

    async function fetchServices() {
      const servicesResponse = await axios.get<IXmcQueryResponse<IDropdownItem>>(
        `${process.env.PUBLIC_URL}/api/graphql/getData?path=${selectedPaths.SERVICES}&language=en&graphQLQuery=${servicesQuery}`
      );
      const serviceOptions = servicesResponse.data.graphQLResponse.data.item.children.results;
      setServiceOptions(serviceOptions.map((service) => toOption(service)));
    }

    async function fetchSpecies() {
      //Species will fetched from Shared sites.
      const userGroupsResponse = await axios.get<IXmcQueryResponse<IDropdownItem>>(
        `${process.env.PUBLIC_URL}/api/graphql/getData?path={DD4AB977-FE28-40D6-8D9B-E0C78EAE0C39}&language=en&graphQLQuery=${userGroupsQuery}`
      );
      const userGroups = userGroupsResponse.data.graphQLResponse.data.item.children.results;
      const speciesOptions = userGroups
        .filter((item) => !item?.value?.value.startsWith('LTAC')) // Exclude Loyalty Accepted Usergroup
        .map((item) => toOption(item));
      setSpeciesOptions(speciesOptions);
    }

    async function fetchData() {
      await Promise.all([fetchServices(), fetchSpecies()]);
      setIsLoaded(true);
    }
    fetchData();
  }, [isEditor, isLoaded]);

  return (
    <RuleOptionsContext.Provider value={{ serviceOptions, speciesOptions }}>
      {children}
    </RuleOptionsContext.Provider>
  );
};
