import {
  Field,
  Text,
  RichText,
  withDatasourceCheck,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { tv } from 'tailwind-variants';
import { Field as FormikField, Form, Formik, FormikProps } from 'formik';
import RichTextHelper from 'components/helpers/RichTextHelper';
// import { PSP } from 'models/PetSuppliesPlus.Model';
import apiConfig from 'src/utils/apiConfig';
import { ThemeUpperCase } from 'components/helpers/Constants';
import { apiRequest } from 'src/utils/apiWrapper';
import ModalWrapper from 'components/helpers/ModalWrapper';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import Loader from 'components/Loader/Loader';

export type Theme = 'psp' | 'wnw';

export type NewsLetterProps = ComponentProps & {
  fields: {
    data: {
      item: {
        id: string;
        title?: Field<string>;
        emailAddressPlaceholder?: Field<string>;
        emailAddressRequiredErrorMessage?: Field<string>;
        emailAddressInvalidErrorMessage?: Field<string>;
        buttonLabel?: Field<string>;
        modalTitle?: Field<string>;
      };
    };
  };
};
// PSP.Sitecore.templates.PetSuppliesPlus.Newsletter.Fields.Newsletter; //TODO: Leprechaun from BED

const newsletter = tv(
  {
    slots: {
      base: 'NewsLetter flex flex-col items-center text-color-text-white justify-items-center	center',
      title: 'font-primary text-white text-center self-stretch font-bold',
      wrapper: 'flex flex-row w-full justify-center',
      input:
        'flex items-center gap-0.5 flex-grow-1 flex-shrink-0 flex-basis-0 text-color-text-dark text-left border-2 border-transparent placeholder:text-color-text-dark placeholder:font-bold focus:placeholder:opacity-0',
      button: 'bg-color-brand-primary-1-base font-bold',
      loaderButton: '',
    },
    compoundSlots: [
      {
        slots: ['button', 'title', 'input'],
        class: ['font-primary'],
      },
      {
        slots: ['button'],
        class: ['hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark'],
      },
      {
        slots: ['input'],
        class: ['focus:border-2 focus:border-solid focus:border-color-border-brand'],
      },
      {
        slots: ['input', 'button'],
        class: ['outline-none'],
      },
      {
        slots: ['button', 'loaderButton'],
        class: ['inline-flex justify-center items-center'],
      },
    ],

    variants: {
      color: {
        true: {
          base: 'bg-color-background-brand-4',
          loaderButton: 'bg-color-background-brand-4',
        },
        false: {
          base: 'bg-color-scale-8-dark',
          loaderButton: 'bg-color-scale-8-dark',
        },
      },
      size: {
        small: {
          base: 'px-mob-global-grid-margin py-mob-padding-base-y w-full font-xl [&>form]:w-full gap-mob-margin-base-bottom',
          title: 'text-heading-mob-large-bold leading-heading-mob-large-bold w-[100%]',
          input: 'py-mob-padding-tight-y px-mob-padding-micro-x w-4/5 w-full',
          button: 'py-mob-padding-tight-y px-mob-padding-micro-x w-20 w-1/5 w-full',
          wrapper: ['flex-col', 'gap-mob-margin-base-bottom'],
        },
        large: {
          base: 'px-desk-global-grid-margin py-desk-padding-tight-y w-full font-2xl justify-items-center [&>form]:w-fit gap-desk-margin-base-bottom',
          title: 'text-heading-desk-large-bold leading-heading-desk-large-bold',
          input: 'w-[320px] py-desk-padding-micro-y px-mob-padding-micro-x',
          button: 'py-desk-padding-micro-y px-desk-padding-micro-x w-fit',
          wrapper: ['flex-row', 'gap-[0px]'],
          loaderButton: ['pl-4', 'w-[72px]'],
        },
      },
      activeLoader: {
        true: {
          button: [],
        },
        false: {
          button: [],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'], // `true` to apply to all screen sizes
  }
);

// TV Stylings to pass into the modal:
const modalTv = tv(
  {
    slots: {
      contentWrapperM: 'flex flex-col items-start self-stretch',
      titleM: '',
      descriptionM: '',
    },
    compoundSlots: [
      {
        slots: ['titleM', 'descriptionM'],
        class: ['font-primary', 'w-full', 'text-color-text-dark'],
      },
    ],
    variants: {
      size: {
        small: {
          contentWrapperM: 'gap-mob-space-between-tight-vertical',
          titleM:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
        },
        large: {
          contentWrapperM: 'gap-desk-space-between-tight-vertical',
          titleM:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

const initialValues = {
  email: '',
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const NewsLetter = ({ fields, params }: NewsLetterProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseMesssage, setResponseMesssage] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const sitename = useSitecoreContext().sitecoreContext.site?.name?.toUpperCase() as ThemeUpperCase;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef: React.RefObject<FormikProps<any>> = useRef(null);
  const { base, title, input, wrapper, button, loaderButton } = newsletter({
    color: useSitecoreContext().sitecoreContext.site?.name?.toUpperCase() === 'PSP',
    size: {
      initial: 'small',
      lg: 'large',
    },
  });

  const defaultModalTv = modalTv({
    size: {
      initial: 'small',
      lg: 'large',
    },
  });

  // Function to reset the email input field
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resetEmailField = () => {
    if (formRef.current) {
      const formikProps = formRef.current;
      if (formikProps.resetForm) {
        formikProps.resetForm(); // Reset the form using Formik's resetForm method
      }
    }
  };
  const token = useAuthToken();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promotionalSignUp = async (values: any) => {
    const emailInput = values?.email;
    if (!emailInput) {
      return; // to avoid unnecessary targets
    }
    setLoadSpinner(true);
    const signForPromotion = apiConfig?.promotionalSignupAPI?.promotionalSignup;
    const headersData = {
      site: sitename, // for now, only PSP would work, WNW integration is left from OC Marketplace
      requesturl: window.location.origin,
      Authorization: token,
    };
    const postData = {
      Email: emailInput,
      IsPSP: sitename == 'PSP' ? true : false,
      DatasourceId: fields?.data?.item?.id,
    };
    const options = { method: 'POST', headers: headersData, data: postData };

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await apiRequest(signForPromotion, options);
      if (response && response?.Message) {
        setResponseMesssage(response?.Message);
        setIsModalOpen(true);
        setLoadSpinner(false);
        document.body.classList.add('modal-open');
      }
    } catch (error) {
      console.error('API Error:', error);
      setLoadSpinner(false);
    }
  };

  // Handle the form submission logic here
  const handleNewsLetterSignup = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _values: any,
    actions: { setFieldError: (arg0: string, arg1: string | undefined) => void }
  ) => {
    const emailInput = _values?.email;
    if (!emailInput) {
      actions.setFieldError('email', fields?.data?.item?.emailAddressRequiredErrorMessage?.value);
      setShowRed(true);
      setErrorMessage(fields?.data?.item?.emailAddressRequiredErrorMessage?.value || 'Is required');
      return;
    }

    // Validate email format
    const validationpattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regex = new RegExp(validationpattern);

    if (!regex.test(emailInput)) {
      actions.setFieldError('email', fields?.data?.item?.emailAddressInvalidErrorMessage?.value);
      setShowRed(true);
      setErrorMessage(
        fields?.data?.item?.emailAddressInvalidErrorMessage?.value || 'Field not valid'
      );
      return;
    }
    promotionalSignUp(_values);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModalClose = () => {
    resetEmailField(); // Reset the email input field
    setIsModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  return (
    <>
      <div className={base({ className: params?.Styles ?? '' })} id="newsletter">
        {fields?.data?.item?.title && (
          <RichTextHelper tag="p" className={clsx(title())} field={fields?.data?.item?.title} />
        )}
        <Formik initialValues={initialValues} onSubmit={handleNewsLetterSignup} innerRef={formRef}>
          {(formikProps) => (
            <Form>
              {showRed && formikProps.errors.email && (
                <p className="text-system-red">{errorMessage}</p>
              )}

              <div className={wrapper()}>
                <FormikField
                  type="email"
                  className={input()}
                  id="email"
                  name="email"
                  aria-label="email"
                  placeholder={fields?.data?.item?.emailAddressPlaceholder?.value}
                />
                {!loadSpinner && (
                  <button aria-label="submit" className={button()} type="submit">
                    {fields?.data?.item?.buttonLabel?.value}
                  </button>
                )}
                {loadSpinner && (
                  <span className={clsx(loaderButton(), 'flex items-center')}>
                    <Loader />
                  </span>
                )}
              </div>
            </Form>
          )}
        </Formik>

        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={handleModalClose}
          showModal={isModalOpen}
          customPopup={true}
          popupSpacing="py-mob-space-between-loose-horizontal px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x shadow-privacyShadow modal-open"
          popupBG="bg-transparent"
          additionalClassForPopUpModal={`${
            params?.Styles ?? ''
          } !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
        >
          <div className={defaultModalTv?.contentWrapperM()}>
            <Text
              tag="p"
              className={defaultModalTv?.titleM()}
              field={fields?.data?.item?.modalTitle}
            />

            <RichText
              className={defaultModalTv?.descriptionM()}
              field={{ value: responseMesssage }}
            />
          </div>
        </ModalWrapper>
      </div>
    </>
  );
};

export default withDatasourceCheck()<NewsLetterProps>(NewsLetter);
