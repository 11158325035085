//global
import React from 'react';
import { useSitecoreContext, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import FeaturedProductWidget from 'src/helpers/search/FeaturedProduct';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import RichTextHelper from 'components/helpers/RichTextHelper';
import TextHelper from 'components/helpers/TextHelper';
import { RerenderOnRouteChange } from 'src/helpers/utility/RerenderOnRouteChange';
//type
export type FeaturedProductProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.FeaturedProduct.Fields.FeaturedProduct;
//main component
const FeaturedProduct: React.FC<FeaturedProductProps> = ({ fields }) => {
  const isEE = useExperienceEditor();
  const { sitecoreContext } = useSitecoreContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const brandName = (sitecoreContext as any)?.route?.fields?.brandName?.value;

  if (isEE) {
    return (
      <div className="w-full">
        Heading: <RichTextHelper field={fields?.heading} />
        Page Limit: <TextHelper field={fields?.pageLimit} />
        RFKID: <TextHelper field={fields?.rfkID} />
      </div>
    );
  }

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  if (!fields?.rfkID?.value && (fields?.rfkID?.value?.length as number) <= 0) return <></>;

  return (
    <RerenderOnRouteChange>
      <FeaturedProductWidget
        rfkId={fields?.rfkID?.value as string}
        title={fields?.heading}
        pageLimit={fields?.pageLimit?.value}
        brandName={brandName}
      />
    </RerenderOnRouteChange>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<FeaturedProductProps>(FeaturedProduct);
