import CheckboxField from './form/CheckboxField';

export function DefaultRule() {
  return (
    <div className="w-full">
      <div className="mx-auto w-full divide-y divide-gray-500 rounded-sm bg-gray-100">
        <div className="group flex w-full justify-between items-center px-4 py-2 gap-4 active:bg-gray-200">
          <h3 className="text-lg font-medium text-black group-data-[hover]:text-gray-800 flex-none">
            Default Rule
          </h3>
          <div>
            <CheckboxField
              id={`showDefaultContent`}
              name={`showDefaultContent`}
              label="Show"
              singleCheckbox
              required={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
