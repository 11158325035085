//global
import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import RichTextHelper from 'components/helpers/RichTextHelper';
import TextHelper from 'components/helpers/TextHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import ImageHelper from 'components/helpers/ImageHelper';
//type
export type CredentialsProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Credentials.Fields.Credentials;
//component variants
const credentialsVariaints = tv(
  {
    slots: {
      base: ['credentials'],
      divWrapper: ['flex', 'container', 'mx-auto'],
      cardWrapper: ['lg:[&_.right]:ml-auto'],
      card: [
        'bg-color-background-white',
        'rounded-[12px]',
        'w-[85%]',
        'min-w-[334px]',
        'lg:min-w-[0px]',
        'lg:max-w-[415px]',
        'lg:w-[415px]',
      ],
      rightWrapper: [''],
      contentWrapper: ['flex-col', 'text-color-text-white', 'max-w-[954px]'],
      title: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      description: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
    },
    compoundSlots: [
      { slots: ['contentWrapper', 'cardWrapper', 'card'], class: ['flex'] },
      { slots: ['divWrapper', 'card'], class: ['justify-center', 'items-center'] },
    ],
    variants: {
      device: {
        mobile: {
          divWrapper: [
            'pt-mob-padding-tight-y',
            'pl-mob-global-grid-margin',
            'pb-mob-padding-loose-bottom',
            'pr-0',
            'flex-col',
            'flex-col-reverse',
          ],
          cardWrapper: [
            'px-mob-padding-tight-y',
            'gap-[19px]',
            'overflow-x-scroll',
            'flex-row',
            'w-full ',
          ],
          card: [
            'py-mob-padding-micro-y',
            'px-mob-padding-tight-x',
            'rounded-global-radius-large',
            'h-[145px]',
          ],
          rightWrapper: ['py-mob-padding-tight-y', 'px-mob-padding-tight-y'],
          contentWrapper: [
            'px-mob-padding-micro-x',
            'gap-mob-margin-tight-bottom',
            'text-center',
            'pb-2',
          ],
        },
        desktop: {
          divWrapper: ['pt-desk-padding-tight-y', 'pb-desk-padding-tight-y', 'flex-row'],
          cardWrapper: [
            'px-desk-margin-loose-right',
            'gap-[24px]',
            'flex-col',
            'overflow-visible',
            'w-full',
            'max-w-[570px]',
          ],
          card: ['py-desk-padding-micro-y', 'px-desk-padding-tight-x', 'h-full'],
          rightWrapper: ['py-desk-padding-tight-y', 'px-desk-padding-base-x', 'order-1'],
          contentWrapper: [
            'px-desk-padding-micro-x',
            'gap-desk-margin-tight-bottom',
            'text-left',
            'pb-0',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const Credentials: React.FC<CredentialsProps> = ({ fields, params }) => {
  //check if pageEditor or not
  const isEE = useExperienceEditor();
  //background color
  const backgroundColor =
    fields?.hexColor && fields?.hexColor?.value !== ''
      ? fields?.hexColor?.value
      : 'bg-color-brand-primary-1-base';
  //variants
  const { base, divWrapper, cardWrapper, rightWrapper, card, contentWrapper, title, description } =
    credentialsVariaints({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      id={'credentials'}
      className={(params?.Styles ?? '') + ' ' + base({ class: backgroundColor })}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={divWrapper()}>
        <div
          className={cardWrapper({
            class: `${fields?.awardsList && fields.awardsList.length <= 1 ? 'justify-center' : ''}`,
          })}
        >
          {fields?.awardsList?.map((c, index) => {
            return (
              <div key={index} className={card({ class: (index + 1) % 2 !== 0 ? 'right' : '' })}>
                <ImageHelper field={c?.fields?.image} />
              </div>
            );
          })}
        </div>
        <div className={rightWrapper()}>
          <div className={contentWrapper()}>
            <RichTextHelper field={fields?.title} className={title()} />
            <RichTextHelper field={fields?.description} className={description()} />
            {isEE && (
              <>
                Background color :<TextHelper field={fields?.hexColor} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<CredentialsProps>(Credentials);
