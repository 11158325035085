import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import FormTextField from './form/TextField';
import { FaEdit } from 'react-icons/fa';
import { LuTrash, LuGripVertical } from 'react-icons/lu';
import { FieldArray, useField } from 'formik';
import { useState } from 'react';
import { ConditionList } from './ConditionList';
import { IPersonalizationRule } from '../models';
import { buttonAddRule, iconButton, iconDraggable } from '../styles';
import CheckboxField from './form/CheckboxField';
import { ItemTreeViewModal } from './ItemTreeViewModal';
import { ItemNode } from './ItemTreeView/ItemTreeView';

interface RuleItemProps {
  rule: IPersonalizationRule;
  index: number;
  defaultOpen: boolean;
  removeRule: (index: number) => void;
  contentItemIds: string[];
}

export function RuleItem({ rule, index, defaultOpen, removeRule }: RuleItemProps) {
  const [{ value: defaultCmsPath }] = useField(`defaultCmsPath`);
  const [isEditing, setIsEditing] = useState(false);
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id: rule.id });
  const [showItemTreeViewModal, setShowItemTreeViewModal] = useState(false);

  const sortStyles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [{ value: nameValue }] = useField(`rules.${index}.name`);
  const [{ value: contentItemNameValue }, {}, { setValue: setContentItemNameValue }] = useField(
    `rules.${index}.contentItemName`
  );
  const [{}, {}, { setValue: setContentItemIdValue }] = useField(`rules.${index}.contentItemId`);

  const handleItemSaveClick = (selectedItem?: ItemNode) => {
    setContentItemIdValue(selectedItem?.id);
    setContentItemNameValue(selectedItem?.name);
    setShowItemTreeViewModal(false);
  };

  return (
    <div className="w-full" ref={setNodeRef} style={sortStyles} {...attributes}>
      <div className="mx-auto w-full divide-y divide-gray-500 rounded-sm bg-gray-100">
        <Disclosure as="div" defaultOpen={defaultOpen}>
          <div className="group flex w-full items-center px-4 py-2 gap-4 active:bg-gray-200">
            <button type="button" {...listeners} ref={setActivatorNodeRef}>
              <LuGripVertical className={iconDraggable()} />
            </button>
            {isEditing ? (
              <>
                <FormTextField
                  className="max-w-[40%]"
                  id={`rules.${index}.name`}
                  name={`rules.${index}.name`}
                  label="Rule Name"
                  required={true}
                />
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing((isEditing) => !isEditing);
                  }}
                  className={buttonAddRule()}
                  disabled={nameValue === ''}
                >
                  Save
                </button>
              </>
            ) : (
              <>
                <h3 className="text-lg font-medium text-black group-data-[hover]:text-gray-800 flex-none">
                  {rule.name}
                </h3>
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing((isEditing) => !isEditing);
                  }}
                  className={iconButton({ color: 'primary', className: 'mr-auto' })}
                >
                  <FaEdit className={'w-5 h-5 text-green-500'} />
                </button>
              </>
            )}
            <a onClick={() => setShowItemTreeViewModal(true)} className="whitespace-nowrap">
              {contentItemNameValue || 'Select Content'}
            </a>
            <ItemTreeViewModal
              showModal={showItemTreeViewModal}
              onCloseClick={() => setShowItemTreeViewModal(false)}
              onSaveClick={handleItemSaveClick}
              rootNode={{
                id: defaultCmsPath || '/sitecore/content/PetSuppliesPlus',
                name: defaultCmsPath || 'PetSuppliesPlus',
                hasLayout: false,
                hasChildren: true,
              }}
            />
            <CheckboxField
              id={`rules.${index}.show`}
              name={`rules.${index}.show`}
              label="Show"
              singleCheckbox
              required={false}
            />
            {!isEditing && (
              <button
                type="button"
                className={iconButton({ color: 'delete', className: 'ml-auto' })}
                onClick={() => removeRule(index)}
              >
                <LuTrash className={'text-red-500'} />
              </button>
            )}
            <DisclosureButton
              className={`group flex items-center p-4 gap-4 ${isEditing && 'ml-auto'}`}
            >
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180 ml-auto" />
            </DisclosureButton>
          </div>
          <Transition
            enter="duration-200 ease-out"
            enterFrom="opacity-0 -translate-y-6"
            enterTo="opacity-100 translate-y-0"
            leave="duration-300 ease-out"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-6"
          >
            <DisclosurePanel className="bg-white text-sm/5 text-gray-500 p-3">
              <FieldArray name={`rules.${index}.conditions`}>
                {(arrayHelpers) => (
                  <ConditionList
                    ruleIndex={index}
                    conditions={rule.conditions}
                    addCondition={(condition) => arrayHelpers.push(condition)}
                    removeCondition={(index) => arrayHelpers.remove(index)}
                    swapCondition={(oldIndex, newIndex) => arrayHelpers.swap(oldIndex, newIndex)}
                  />
                )}
              </FieldArray>
            </DisclosurePanel>
          </Transition>
        </Disclosure>
      </div>
    </div>
  );
}
