import { IPersonalizationCondition, ConditionTypes, IPersonalizationRule } from '../models';
import {
  evaluateWhereStoreIs,
  evaluateExceptWhereStoreIs,
  evalueWhereStoreTypeIs,
  evaluateExceptWhereStoreTypeIs,
  evaluateWhereStoreOffers,
  evaluateExceptWhereStoreOffers,
  evaluateWhenDateHasPassed,
  evaluateExceptWhenDateHasPassed,
  evaluateWherePageUrlIs,
  evaluateExceptWherePageUrlIs,
  evaluateWhereGoalIs,
  evaluateExceptWhereGoalIs,
  evaluateWhereDeviceIs,
  evaluateExceptWhereDeviceIs,
  evaluateWhereBookingOnline,
  evaluateExceptWhereBookingOnline,
  evaluateWhereUserHasAcceptedLoyaltyTermsOfUse,
  evaluateExceptWhereUserHasAcceptedLoyaltyTermsOfUse,
  evaluateWhereUserHasPetSpecies,
  evaluateExceptWhereUserHasPetSpecies,
} from './conditionEvaluators';

export interface ConditionInputs {
  storeId: string;
  path: string;
  goalId: string;
  isMobile: boolean;
  isBopisEnabled: boolean;
  isDfsEnabled: boolean;
  isFranchise: boolean;
  isCorporate: boolean;
  hasOnlineBooking: boolean;
  userHasAcceptedLoyaltyTermsOfUse: boolean;
  userPetSpecies: string[];
  storeServices: number[];
}

export function evaluateRules(
  rules: IPersonalizationRule[],
  inputs: ConditionInputs
): IPersonalizationRule | null {
  if (!rules?.length) {
    return null;
  }
  const match = rules.find((rule) => evaluateConditions(rule.conditions, inputs));
  return match || null;
}

export function evaluateConditions(
  conditions: IPersonalizationCondition[],
  inputs: ConditionInputs
): boolean {
  return conditions.reduce((result, currentCondition, index, allConditions) => {
    if (index === 0) {
      return evaluateCondition(currentCondition, inputs);
    }
    const lastIndex = allConditions.length - 1;
    if (index === lastIndex) {
      return result;
    }
    if (currentCondition === 'and') {
      return result && evaluateCondition(allConditions[index + 1], inputs);
    }
    if (currentCondition === 'or') {
      return result || evaluateCondition(allConditions[index + 1], inputs);
    }
    return result;
  }, false);
}

function evaluateCondition(condition: IPersonalizationCondition, inputs: ConditionInputs): boolean {
  if (typeof condition === 'string') {
    return false;
  }

  const conditionType = condition.type;
  const evaluator = conditionEvaluators[conditionType];
  if (evaluator) {
    return evaluator(condition, inputs);
  } else {
    console.error(`Unknown condition type: ${conditionType}`);
    return false;
  }
}

const conditionEvaluators = {
  [ConditionTypes.WHERE_STORE_IS]: evaluateWhereStoreIs,
  [ConditionTypes.EXCEPT_WHERE_STORE_IS]: evaluateExceptWhereStoreIs,
  [ConditionTypes.WHERE_STORE_TYPE_IS]: evalueWhereStoreTypeIs,
  [ConditionTypes.EXCEPT_WHERE_STORE_TYPE_IS]: evaluateExceptWhereStoreTypeIs,
  [ConditionTypes.WHERE_STORE_OFFERS]: evaluateWhereStoreOffers,
  [ConditionTypes.EXCEPT_WHERE_STORE_OFFERS]: evaluateExceptWhereStoreOffers,
  [ConditionTypes.WHEN]: evaluateWhenDateHasPassed,
  [ConditionTypes.EXCEPT_WHEN]: evaluateExceptWhenDateHasPassed,
  [ConditionTypes.WHERE_PAGE_URL_IS]: evaluateWherePageUrlIs,
  [ConditionTypes.EXCEPT_WHERE_PAGE_URL_IS]: evaluateExceptWherePageUrlIs,
  [ConditionTypes.WHERE_GOAL_IS]: evaluateWhereGoalIs,
  [ConditionTypes.EXCEPT_WHERE_GOAL_IS]: evaluateExceptWhereGoalIs,
  [ConditionTypes.WHERE_DEVICE_IS]: evaluateWhereDeviceIs,
  [ConditionTypes.EXCEPT_WHERE_DEVICE_IS]: evaluateExceptWhereDeviceIs,
  [ConditionTypes.WHERE_BOOKING_ONLINE]: evaluateWhereBookingOnline,
  [ConditionTypes.EXCEPT_WHERE_BOOKING_ONLINE]: evaluateExceptWhereBookingOnline,
  [ConditionTypes.WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE]:
    evaluateWhereUserHasAcceptedLoyaltyTermsOfUse,
  [ConditionTypes.EXCEPT_WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE]:
    evaluateExceptWhereUserHasAcceptedLoyaltyTermsOfUse,
  [ConditionTypes.WHERE_USER_HAS_PET_SPECIES]: evaluateWhereUserHasPetSpecies,
  [ConditionTypes.EXCEPT_WHERE_USER_HAS_PET_SPECIES]: evaluateExceptWhereUserHasPetSpecies,
};
