//global imports
import { createSlice } from '@reduxjs/toolkit';
//Gender
export type Gender = {
  GenderId: number;
  GenderName: string;
}[];
//Species
export type Species = {
  SpeciesId: number;
  SpeciesName: string;
}[];
const initialState = {
  gender: {} as Gender,
  species: {} as Species,
};
export const myPetsSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setSpecies: (state, action) => {
      state.species = action.payload;
    },
  },
});

export const { setGender, setSpecies } = myPetsSlice.actions;

export default myPetsSlice.reducer;
