const footerQuery = `query FooterQuer($datasource: String!, $language: String!) {
  datasource: item(path: $datasource, language: $language) {
    ... on Footer {
      footerLogo: field(name: "footerLogo") {
        jsonValue
      }
   copyRightText: field(name: "copyRightText") {
        value
      }
    list: field(name: "list") {
        ...List
      }
    }
  }
}

fragment List on MultilistField {
  targetItems {
   
      
     link: field(name: "link") {
      jsonValue
  
    }
    
    linkTitle: field(name: "linkTitle") {
      jsonValue
  
    }
    subLink: field(name: "list") {
     ...SubLink
    }
  
  }
}
fragment SubLink on MultilistField {
  targetItems {
   
     link: field(name: "link") {
      jsonValue
  
    }
    
    linkTitle: field(name: "linkTitle") {
      jsonValue
  
    }
   Image: field(name: "Image") {
     jsonValue
  
    }
  
ImageCaption: field(name: "ImageCaption") {
     jsonValue
  
    }
TargetUrl: field(name: "TargetUrl") {
     jsonValue
  
    }
  }
}`;

export default footerQuery;
