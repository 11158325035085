import { tv } from 'tailwind-variants';

const CheckoutPaymentVariant = tv(
  {
    slots: {
      base: [
        'rounded-b-desk-global-radius-medium',
        'flex',
        'flex-col',
        'items-start',
        'justify-start',
        'shrink-0',
        'relative',
      ],
      form: ['bg-color-background-light', 'flex', 'flex-col', 'w-full'],
      paymentInformationContainer: ['flex', 'gap-[26px]', 'flex-col', 'w-full'],
      fieldWrap: ['flex', 'justify-between', 'w-full'],
      defaultTitle: ['text-color-text-black'],
      title: ['text-color-text-black'],
      shortDescription: [
        'text-left',
        'text-color-text-dark',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      disclaimer: 'w-full self-stretch',
      submitBtn: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
      loaderWrapper: ['flex', 'items-center'],
      fieldWrapper: ['w-full'],
      position: 'relative',
      radioButtonWrapper: 'flex w-fit relative cursor-pointer',
      radioButtonsWrapper: 'flex',
      radioIcon:
        'invisible w-[20px] h-[20px] outline-color-border-mid border-2 rounded-full peer-checked:visible absolute bg-color-brand-primary-1-base',
      radioField:
        'w-[20px] h-[20px] rounded-full cursor-pointer outline-color-border-mid border-2 appearance-none bg-color-background-white rounded-4 peer',

      titleWrapper: [
        'flex',
        'flex-row',
        'gap-desk-margin-base-right',
        'items-center',
        'justify-between',
        'self-stretch',
        'shrink-0',
        'relative',
      ],
    },
    variants: {
      active: {
        true: {
          base: ['!pl-0'],
        },
        false: {
          base: ['bg-color-background-light'],
          form: ['bg-color-background-light'],
        },
      },
      device: {
        mobile: {
          base: [
            // 'pt-mob-padding-tight-y',
            'pr-mob-padding-micro-x',
            'pb-mob-padding-tight-y',
            'pl-mob-padding-micro-x',
          ],
          form: ['w-full', 'gap-mob-margin-base-bottom'],
          paymentInformationContainer: ['mb-mob-margin-base-bottom'],
          fieldWrap: ['gap-mob-space-between-loose-horizontal', 'flex-col'],
          title: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          submitBtn: [
            'w-full',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'rounded-desk-global-radius-small',
          ],
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          disclaimer: 'text-body-large-regular leading-body-large-regular font-body-large-regular',
          radioButtonsWrapper: 'gap-mob-margin-tight-right',
          radioButtonWrapper: 'gap-mob-space-between-micro-horizontal',
        },
        desktop: {
          base: [
            // 'pt-desk-padding-tight-y',
            'pr-desk-padding-micro-x',
            'pb-desk-padding-tight-y',
            'pl-desk-padding-micro-x',
          ],
          form: ['gap-desk-margin-base-bottom'],
          paymentInformationContainer: ['mb-desk-margin-base-bottom'],
          fieldWrap: ['gap-x-desk-space-between-loose-horizontal', 'flex-row', 'gap-none'],
          defaultTitle: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          title: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          submitBtn: [
            'max-w-[280px]',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          disclaimer:
            'text-body-small-regular leading-body-small-regular font-body-small-regular max-w-[627px]',
          radioButtonWrapper: 'gap-desk-space-between-micro-horizontal',
          radioButtonsWrapper: 'gap-desk-margin-tight-right',
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

export default CheckoutPaymentVariant;
