import { tv } from 'tailwind-variants';
import { TabProps } from './types';

const Tab = ({ tab, isActive, onClick }: TabProps) => {
  const FAQContainerClass = tv(
    {
      slots: {
        tabButton: [
          'flex',
          'items-center',
          'justify-center',
          'p-desk-component-button-condensed-padding-y',
          'rounded-desk-global-radius-x-small',
          'font-primary',
          'cursor-pointer',
          'text-color-text-brand-1',
          'font-heading-desk-xLarge-bold',
          'leading-body-large-regular',
        ],
        activeTabButton: ['!text-color-text-black', 'border', 'border-color-border-brand'],
      },
    },
    {
      responsiveVariants: ['lg'],
    }
  );

  const { tabButton, activeTabButton } = FAQContainerClass({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  return (
    <div
      className={`${tabButton()} ${isActive ? activeTabButton() : ''}`}
      onClick={() => onClick(tab.id)}
    >
      {tab.title}
    </div>
  );
};

export default Tab;
