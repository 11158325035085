import { Field, FormikValues, useFormikContext } from 'formik';
import React from 'react';
import FieldWrapper from './FieldWrapper';
import { tv } from 'tailwind-variants';

type StateDropdownProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  subLabel?: string;
  name?: string;
  requiredvalidationmessage?: string;
  validationpattern?: string;
  hasFullWidth?: boolean;
  options?: OptionFieldProps[];
  dependentfieldname?: string;
};

interface OptionFieldProps {
  code: { value: string };
  regions: {
    targetItems: [
      {
        code: { value: string };
        name: { value: string };
        value: string;
      }
    ];
  };
}

interface State {
  id: string;
  name: string;
}

const FieldsVariant = tv(
  {
    slots: {
      fields: [
        'outline-none',
        'bg-color-scale-1-white',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'appearance-none',
        'w-full',
        "bg-[url('/icons/dropdown.svg')]",
        'bg-no-repeat',
        'bg-[position:calc(100%-10px)_center]',
      ],
    },
    variants: {
      device: {
        mobile: {
          fields: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
        },
        desktop: {
          fields: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
        },
      },
      hasErrorStyle: {
        true: { fields: ['border-system-red'] },
        false: { fields: ['border-color-border-mid'] },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const StateDropdown: React.FC<StateDropdownProps> = (props: StateDropdownProps) => {
  const { id, name, requiredvalidationmessage, required, options, dependentfieldname } = props;
  const fieldValidate = (value: string) => {
    let error;
    if (required && !value) {
      error = requiredvalidationmessage || 'APP: This field is required.'; // TODO: - Static message callback can be take from dictionary item.
    }
    return error;
  };
  const { errors, touched, values } = useFormikContext<FormikValues>();
  const hasError = name && touched[name] && errors[name] ? true : false;
  const { fields } = FieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const states: { [key: string]: State[] } =
    options &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options.reduce((acc: any, country) => {
      acc[country?.code?.value] = country.regions.targetItems.map((region) => ({
        id: region?.code?.value,
        name: region?.name?.value,
      }));
      return acc;
    }, {});
  return (
    <>
      <FieldWrapper {...props} hasError={hasError}>
        <Field
          as="select"
          className={fields()}
          id={id}
          name={name}
          aria-label={name}
          value={values.name}
          validate={fieldValidate}
        >
          {/* TODO - First Option creation. */}
          <option value="" label="Please select a State" />
          {values[dependentfieldname as string] &&
            states[values[dependentfieldname as string]].map((state) => (
              <option key={state.id} value={state.id} label={state.name} />
            ))}
        </Field>
      </FieldWrapper>
    </>
  );
};

export default StateDropdown;
