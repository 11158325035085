import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';

const InternalServerError: React.FC = () => {
  return <></>;
};

export default InternalServerError;

export const getStaticProps: GetStaticComponentProps = async (_rendering) => {
  throw new TypeError("Ops, CMS didn't return a reasonable response.");
};
