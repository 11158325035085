import { useRouter } from 'next/router';
import React, { useState, useEffect, useContext, createContext } from 'react';

export interface RerenderOnRouteChangeProps extends React.PropsWithChildren {
  /** @default `true` */
  rerenderOnRouteChange?: boolean;
  /** @default `true` */
  rerenderOnHashChange?: boolean;
}

type RerenderPersistStateContextType = {
  state: Record<string, unknown>;
  setState: (newState: Record<string, unknown>) => void;
};

export const RerenderPersistStateContext = createContext<RerenderPersistStateContextType>(
  {} as RerenderPersistStateContextType
);

/**
 * When using `RerenderOnRouteChange`, any useState inside will get reset when route changes
 * Use this instead of `useState`, and pass a unique `key`.
 * @param key The key for this state.  Recommend to use component name.
 * @returns
 */
export function useRerenderPersistState<T>(key: string) {
  const context = useContext(RerenderPersistStateContext);

  const value = context.state[key] as T;

  const setValue = (newState: T) => {
    // Check if it's actually changed, otherwise we get in an infinite loop.
    if (context.state[key] !== newState) {
      const newLocal = { ...context.state, [key]: newState };
      context.setState(newLocal);
    }
  };

  return [value, setValue] as const;
}

/**
 * Will force children to rerender on `window` "hashchange" event or when route changes.
 * Note: For components inside here, use `useRerenderPersistState` instead of `useState`
 */
export const RerenderOnRouteChange = ({
  children,
  rerenderOnRouteChange = true,
  rerenderOnHashChange = true,
}: RerenderOnRouteChangeProps) => {
  // We are using an incrementor instead of the actual hash value because
  // of a case where the hash is changed progrmatically the event won't fire
  // and if user clicks on same hash link, a re-render won't happen because it
  // doesn't look like a change
  const [incrementor, setIncrementor] = useState(1);
  const router = useRouter();

  // This is a named function so we can ensure that we are adding and removing the same
  // function instance in our event handlers
  function onRouteChange() {
    setIncrementor(incrementor + 1);
  }

  // Because the querystring isn't present on page load for SSG/ISR
  // it doesn't recognize as a route change (because technically it isn't, but functionally it is)
  // This is to force a re-render on querystring change for initial page load
  // After initial page load, the route change event will catch querystring changes
  useEffect(
    onRouteChange,
    // We intentionally do not want incrementor as a dependency as that will create infinite loop.
    // We are also calling JSON.stringify so we do string comparison instead of object comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(router.query)]
  );

  useEffect(() => {
    if (rerenderOnHashChange) {
      window.addEventListener('hashchange', onRouteChange);
    }
    if (rerenderOnRouteChange) {
      router.events.on('routeChangeComplete', onRouteChange);
    }
    return () => {
      if (rerenderOnHashChange) {
        window.removeEventListener('hashchange', onRouteChange);
      }
      if (rerenderOnRouteChange) {
        router.events.off('routeChangeComplete', onRouteChange);
      }
    };
    // We technically shouldn't have `incrementor` here, but because this useEffect doesn't actually do the change
    // it just adds and removes event handlers, it doesn't cause an infinite loop
    // and having it here satisfies es-lint.
  }, [incrementor, onRouteChange, rerenderOnHashChange, rerenderOnRouteChange, router.events]);

  const [state, setState] = useState<Record<string, unknown>>({});

  return (
    <RerenderPersistStateContext.Provider value={{ state, setState }}>
      <React.Fragment key={incrementor}>{children}</React.Fragment>
    </RerenderPersistStateContext.Provider>
  );
};
