import { NextImage, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'components/helpers/IconHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import React, { useContext } from 'react';
import { tv } from 'tailwind-variants';
import { StoreLocatorConfig, StoreProps } from './GenericStoreLocator';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import { useRouter } from 'next/router';
import { createOrder, retrieveOrder } from 'src/redux/ocCurrentOrder';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  closedText,
  FulfillmentType,
  GTM_EVENT,
  SiteNameUppercase,
} from 'components/helpers/Constants';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { DateTime } from 'luxon';
const storeVariants = tv(
  {
    slots: {
      base: 'flex flex-col',
      changeLink:
        'font-primary text-body-large-bold_underline leading-body-large-bold_underline font-body-large-bold_underline underline text-color-text-brand-1  hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      noofItemsPerPageText: 'flex flex-wrap gap-x-2 gap-y-0',
      serviceListWrapper: 'list-disc pl-1',
      serviceList: 'font-primary text-[18px] font-[400] text-color-text-black leading-[28px]',
      serviceListBold: 'font-primary text-[18px] font-[700] text-color-text-black leading-[28px]',
      storesWrapper: 'flex-col flex',
      mainWrapper: '',
      storeText: 'text-color-text-brand-1 h-fit ',
      storeAddress: 'hover:no-underline font-primary',
      shopButton:
        'rounded-desk-global-radius-small bg-color-text-brand-1 h-fit w-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark',
      storeDetailWrapper: 'flex w-full',
      storeActionWrapper: 'flex flex-col ',
      buttonWrapper: 'flex flex-col ',
      storePageArrow:
        'ml-1 [&>svg>*]:stroke-color-text-brand-1 [&>svg>path]:fill-color-text-brand-1 h-[16px] w-[16px] [&_svg]:max-w-[16px] [&_svg]:max-h-[16px] group-hover:[&>svg>*]:stroke-color-brand-tertiary-3-base group-focus:[&>svg>path]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:stroke-color-brand-tertiary-3-base group-hover:[&>svg>path]:fill-color-brand-tertiary-3-base',
      openStoreTimingText: '',
      resultText:
        'font-primary inline-block font-body-large-regular text-body-large-regular leading-body-large-regular',
      resultTextBold: 'font-primary',
      storeDetails: 'flex flex-col',
      storeAddressWrapper: '',
      iconWrapper: 'flex h-fit',
      storePawImage: 'min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]',
      buttonWithArrow:
        'group flex underline items-center font-primary text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      storeName:
        'font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      storeViewDetail:
        'font-primary ml-2 text- text-[22px] font-heading-desk-large-bold leading-heading-desk-large-bold text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      storeLink:
        'font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      storeWrapper:
        'bg-color-background-white w-full flex border-b border-b-color-scale-5-mid border-solid	',
      imageLabel: 'font-primary absolute left-[30%] text-white bottom-[2px] text-[14px] font-[700]',
      myStoreIcon:
        '[&>svg>*]:stroke-color-text-brand-1 [&>svg>*]:stroke-[3.5] h-[26px] w-[26px] [&_svg]:max-w-[26px] max-w-[26px] [&_svg]:max-h-[26px] max-h-[26px] mr-3',
      storeAddressLink:
        'hover:!underline !font-[700] focus:!underline text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    },
    variants: {
      size: {
        desktop: {
          noofItemsPerPageText: 'mt-desk-margin-tight-top',
          serviceListWrapper: 'ml-desk-padding-micro-x',
          storesWrapper: 'gap-desk-margin-loose-bottom',
          loadMore:
            'my-desk-margin-tight-top text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold',
          buttonWithArrow:
            'text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold',
          openStoreTimingText:
            'ml-0 text-heading-desk-large-bold leading-heading-desk-large-bold font-heading-desk-large-bold',
          iconWrapper: 'mt-0 min-w-[221px] justify-end mb-0 ',
          shopButton:
            'mt-0 max-w-max min-w-max rounded-desk-global-radius-small text-color-text-white text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y mb-0',
          storeDetails: 'gap-desk-margin-base-bottom',
          storeText:
            'font-primary text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold ',
          storeAddressWrapper: 'mt-3',
          buttonWrapper: 'gap-desk-margin-base-bottom items-end',
          storeActionWrapper: 'mt-0 gap-desk-space-between-base-vertical items-end',
          storeDetailWrapper: 'justify-between flex-row gap-desk-margin-tight-bottom',
          storeWrapper:
            'flex-row gap-desk-margin-base-right py-desk-padding-tight-top px-desk-padding-micro-x',
          storeAddress:
            'ml-desk-margin-base-top text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
        },
        mobile: {
          noofItemsPerPageText: 'mt-mob-margin-tight-top',
          serviceListWrapper: 'ml-mob-padding-micro-x',
          storesWrapper: 'gap-mob-margin-loose-bottom',
          buttonWithArrow:
            'text-heading-mob-medium-bold leading-heading-mob-medium-bold font-heading-mob-medium-bold',
          storeAddress:
            'ml-mob-margin-base-top text-heading-mob-medium-regular font-heading-mob-medium-regular leading-heading-mob-medium-regular',
          storeDetails: 'gap-mob-margin-base-bottom',
          buttonWrapper: 'gap-mob-margin-base-bottom',
          storeAddressWrapper: 'mt-3',
          storeActionWrapper: 'mt-4 gap-mob-space-between-base-vertical -mt-2',
          iconWrapper: 'mt-mob-margin-loose-bottom mb-mob-margin-loose-bottom',
          openStoreTimingText:
            'ml-mob-margin-base-top text-heading-mob-large-bold leading-heading-mob-large-bold font-heading-mob-large-bold',
          shopButton:
            'rounded-mob-global-radius-small px-mob-component-button-condensed-padding-x py-mob-component-button-condensed-padding-y text-color-text-white block text-body-large-bold font-body-large-bold leading-body-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y',
          loadMore:
            'my-mob-margin-tight-top text-heading-mob-medium-bold leading-heading-mob-medium-bold font-heading-mob-medium-bold',
          storeText:
            'text-color-text-brand-1 h-fit font-primary text-body-large-bold font-body-large-bold leading-body-large-bo',
          storeDetailWrapper: 'flex-col gap-mob-margin-tight-bottom',
          storeWrapper: 'flex-col gap-mob-margin-base-right py-mob-padding-tight-top',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

const ModalStore = ({
  stores,
  fields,
  location,
  setStores,
  // setAddress,
  handleModalClose,
  setSelectedSuggestions,
}: {
  stores: StoreProps[];
  fields: StoreLocatorConfig;
  location: string;
  // setAddress: (value: string) => void;
  setStores: (value: []) => void;
  handleModalClose: () => void;
  setSelectedSuggestions: ({ Keywords }: { Keywords: string }) => void;
}) => {
  const {
    base,
    mainWrapper,
    storeWrapper,
    storeAddressWrapper,
    storeDetailWrapper,
    storeActionWrapper,
    resultText,
    storeName,
    storeDetails,
    storeAddress,
    openStoreTimingText,
    buttonWrapper,
    buttonWithArrow,
    iconWrapper,
    storeText,
    shopButton,
    storesWrapper,
    myStoreIcon,
    storeViewDetail,
    storeAddressLink,
    noofItemsPerPageText,
    changeLink,
    storePageArrow,
  } = storeVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const router = useRouter();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const dispatch = useOcDispatch();
  //get sitecore context for siteName
  const context = useSitecoreContext();
  const sitecoreContext = context.sitecoreContext;
  const siteName = sitecoreContext?.site?.name ?? 'unknown';
  const siteNameUpper = siteName.toUpperCase() as SiteNameUppercase;
  const lookupList = useOcSelector(
    (state) => state?.lookupSlice?.data?.graphQLResponse?.data?.[siteNameUpper]?.lookupList
  );
  const isEE = useExperienceEditor();

  const { isMobile }: { isMobile: boolean } = useBreakpoints();
  const servicesData: { [key: string]: string } = {};
  lookupList
    ?.filter((data) => data?.lookupName === 'Store Services')?.[0]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ?.items?.list?.forEach((item: { fields: any }) => {
      servicesData[item?.fields?.serviceId?.value] = item?.fields?.name?.value;
    });
  const renderServicesList = (servicesList: number[]) => {
    const { serviceList, serviceListWrapper, serviceListBold } = storeVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });

    return (
      <>
        {servicesList?.length > 0 && (
          <>
            {fields?.servicesText && (
              <Text tag="p" className={serviceListBold()} field={fields?.servicesText} />
            )}
            <ul className={serviceListWrapper()}>
              {servicesList.map((service, index) => {
                if (servicesData[service?.toString()]) {
                  return (
                    <li key={index} className={serviceList()}>
                      {servicesData[service?.toString()]}
                    </li>
                  );
                } else {
                  return <></>;
                }
              })}
            </ul>
          </>
        )}
      </>
    );
  };
  const renderResultText = () => {
    const stringArr = fields?.noofItemsPerPageText?.value
      ?.replace('{number}', `${stores?.length}`)
      ?.split('{location}');
    return stringArr && stringArr?.length > 1 ? (
      <>
        {stringArr[0]}
        <strong>
          {location?.length > 0 ? `"${location}"` : `"${myStoreData?.city}, ${myStoreData?.state}"`}
        </strong>
        {stringArr[1]}
      </>
    ) : (
      stringArr && stringArr[0]
    );
  };
  const renderViewStoreLink = () => (
    <LinkHelper
      className={changeLink()}
      onClick={(e) => {
        e.preventDefault();
        setStores([]);
        // setAddress('');
        setSelectedSuggestions({ Keywords: '' });
      }}
      field={{ value: { href: '/', text: fields?.changeText?.value } }}
    />
  );
  return (
    <div className={mainWrapper()}>
      {stores?.length > 0 && (
        <div className={base()}>
          {fields?.noofItemsPerPageText && stores?.length > 0 && (
            <div className={noofItemsPerPageText()}>
              {isEE ? (
                <>
                  <RichTextHelper
                    tag="p"
                    field={fields?.noofItemsPerPageText}
                    updatedField={{
                      value: fields?.noofItemsPerPageText?.value
                        ?.replace(
                          '{location}',
                          `<strong>"${
                            location?.length > 0
                              ? location
                              : `${myStoreData?.city}, ${myStoreData?.state}`
                          }"</strong>`
                        )
                        ?.replace('{number}', `${stores?.length}`),
                    }}
                    className={resultText()}
                  />
                  {renderViewStoreLink()}
                </>
              ) : (
                <>
                  {renderResultText()}
                  {renderViewStoreLink()}
                </>
              )}
            </div>
          )}
          <div className={storesWrapper()}>
            {stores.map((store, index) => {
              const storeTimings = store?.xp_storehours?.find(
                (dayData) => dayData?.Day === new Date()?.getDay() + 1
              );
              return (
                <div
                  id={`google_map_store_${store?.storeid}`}
                  key={index}
                  className={storeWrapper()}
                >
                  <div className={storeDetailWrapper()}>
                    <div className={storeDetails()}>
                      <div>
                        <div className="flex flex-wrap">
                          <p className={storeName()}>
                            {index + 1}. {store?.firstname}{' '}
                          </p>
                          {fields?.viewStorePageButtonText?.value && (
                            <LinkHelper
                              className={storeViewDetail()}
                              onClick={(e) => {
                                e.preventDefault();
                                router.push(
                                  `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`?.replaceAll(
                                    ' ',
                                    '-'
                                  )
                                );
                                handleModalClose();
                              }}
                              field={{
                                value: {
                                  text: fields?.viewStorePageButtonText?.value || '',
                                  href: `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`?.replaceAll(
                                    ' ',
                                    '-'
                                  ),
                                },
                              }}
                            />
                          )}
                        </div>

                        <div className={storeAddressWrapper()}>
                          <Text
                            className={storeAddress()}
                            tag="p"
                            field={{ value: store?.street1 }}
                          />
                          <Text
                            className={storeAddress()}
                            tag="p"
                            field={{ value: `${store.city}, ${store.state} ${store?.zip}` }}
                          />
                          {store?.phone && (
                            <LinkHelper
                              className={clsx(storeAddress(), storeAddressLink())}
                              field={{
                                value: {
                                  text: store.phone,
                                  href: `tel:${store.phone}`,
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {isMobile && fields?.storeLocatorRemainingOpenTimeText && (
                        <RichTextHelper
                          tag="p"
                          field={fields?.storeLocatorRemainingOpenTimeText}
                          updatedField={{
                            value:
                              storeTimings && storeTimings?.OpenTime && storeTimings?.CloseTime
                                ? storeTimings?.OpenTime === storeTimings?.CloseTime
                                  ? closedText
                                  : fields?.storeLocatorRemainingOpenTimeText?.value?.replace(
                                      '{storeLocatorRemainingOpenTime}',
                                      `${
                                        storeTimings?.OpenTime
                                          ? DateTime.fromFormat(
                                              storeTimings.OpenTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      } - ${
                                        storeTimings?.CloseTime
                                          ? DateTime.fromFormat(
                                              storeTimings.CloseTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      }`
                                    ) || ''
                                : closedText,
                          }}
                          className={openStoreTimingText()}
                        />
                      )}
                      <div>{renderServicesList(store?.xp_storeservices)}</div>
                    </div>
                    <div className={storeActionWrapper()}>
                      {!isMobile && fields?.storeLocatorRemainingOpenTimeText && (
                        <RichTextHelper
                          tag="p"
                          field={fields?.storeLocatorRemainingOpenTimeText}
                          updatedField={{
                            value:
                              storeTimings && storeTimings?.OpenTime && storeTimings?.CloseTime
                                ? storeTimings?.OpenTime === storeTimings?.CloseTime
                                  ? closedText
                                  : fields?.storeLocatorRemainingOpenTimeText?.value?.replace(
                                      '{storeLocatorRemainingOpenTime}',
                                      `${
                                        storeTimings?.OpenTime
                                          ? DateTime.fromFormat(
                                              storeTimings.OpenTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      } - ${
                                        storeTimings?.CloseTime
                                          ? DateTime.fromFormat(
                                              storeTimings.CloseTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      }`
                                    ) || ''
                                : closedText,
                          }}
                          className={openStoreTimingText()}
                        />
                      )}

                      <div className={buttonWrapper()}>
                        {myStoreData?.storeId === store?.storeid ? (
                          <>
                            {fields?.myStoreText?.value && (
                              <div className={iconWrapper()}>
                                <NextImage className={myStoreIcon()} field={fields?.myStoreIcon} />
                                <Text
                                  field={{ value: fields?.myStoreText?.value }}
                                  tag="p"
                                  className={storeText()}
                                ></Text>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {fields?.shopThisStoreText?.value && (
                              <LinkHelper
                                className={shopButton()}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  if (store?.storeid) {
                                    setcomponentContextData({
                                      ...componentContextData,
                                      storeId: store?.storeid,
                                      showStoreLocatorGlobalModal: true,
                                      storeCount: stores?.length,
                                      callBackFunctionAfterStoreChange: async () => {
                                        const retrieveStoreOrder = await dispatch(retrieveOrder());
                                        const responsesStoreOrder =
                                          unwrapResult(retrieveStoreOrder);
                                        if (responsesStoreOrder === undefined) {
                                          await dispatch(
                                            createOrder(
                                              localStorage.getItem(
                                                'selected_fulfillment_Method'
                                              ) === FulfillmentType?.DFS
                                            )
                                          );
                                          localStorage.removeItem('selected_fulfillment_Method');
                                        } else {
                                          sendStoreGTMData({
                                            eventName: GTM_EVENT?.shopThisStore,
                                            storeId: store?.storeid,
                                            storesShown: stores?.length?.toString(),
                                          });
                                          await dispatch(retrieveOrder());
                                        }
                                        handleModalClose();
                                      },
                                    });
                                  }
                                }}
                                field={{
                                  value: { href: '/', text: fields?.shopThisStoreText?.value },
                                }}
                              />
                            )}
                          </>
                        )}
                        {store?.xp_storetype === 1 ? (
                          <LinkHelper
                            className={buttonWithArrow()}
                            field={{
                              value: {
                                href: fields?.jobOpportunitiesURL?.value?.href,
                                target: fields?.jobOpportunitiesURL?.value?.target ?? '_blank',
                                text: fields?.jobOpportunitiesText?.value,
                              },
                            }}
                          >
                            <>
                              {fields?.jobOpportunitiesURL?.value?.href &&
                                fields?.jobOpportunitiesText?.value && (
                                  <>
                                    <Text field={{ value: fields?.jobOpportunitiesText?.value }} />
                                    <IconHelper
                                      className={storePageArrow()}
                                      icon={'chevron-right'}
                                    />
                                  </>
                                )}
                            </>
                          </LinkHelper>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalStore;
