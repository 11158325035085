import logout from 'src/redux/ocAuth/logout';
import { handleCTRewardsSignOut } from 'lib/crowd-twist/ct-sign-out';
import { removeSoftLoginDependencies } from 'src/utils/handleSoftLogin';

export const handleLogout = async (dispatch, router, removeCookie, ctAuthCookie) => {
  if (sessionStorage.getItem('isSoftLoginEnabled') === 'true') {
    removeSoftLoginDependencies();
  }
  dispatch(logout());

  if (ctAuthCookie) {
    removeCookie('ct-auth');
    const ctSignOutRedirectUrl = await handleCTRewardsSignOut();
    router.push(ctSignOutRedirectUrl);
  } else {
    router.push('/');
  }
};
