import { DiscreteLineItem } from 'components/helpers/Constants';

// Code to execute before logging out for SoftLogin
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function toExecuteBeforeSoftLogin(ocUser: any, ocCurrentOrder: any) {
  let currentLineItems;
  // let softlogin_dependencies;

  const name = ocUser?.user?.FirstName;
  currentLineItems =
    ocCurrentOrder?.lineItems?.filter(
      (x: { ProductID: string }) => ![DiscreteLineItem.TIP].includes(x.ProductID)
    ) ?? [];
  const subTotal = ocCurrentOrder?.order?.Subtotal;

  if (currentLineItems.length <= 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentLineItems = JSON.parse(sessionStorage.getItem('cartLineItems') as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // softlogin_dependencies = JSON.parse(sessionStorage.getItem('softlogin_dependencies') as any);
  }
  const softLoginState = {
    preferredStore: localStorage.getItem('storeId'),
    accountName: name,
    cartItem: currentLineItems,
    subTotal: subTotal ?? 0,
  };
  await sessionStorage.setItem('isSoftLoginEnabled', 'true');
  await sessionStorage.setItem('softLoginState', JSON.stringify(softLoginState));
}
export async function removeSoftLoginDependencies() {
  sessionStorage.removeItem('isSoftLoginEnabled');
  sessionStorage.removeItem('softLoginState');
  sessionStorage.removeItem('softlogin_dependencies');
  sessionStorage.removeItem('softlogin_pets');
  sessionStorage.removeItem('cartLineItems');
  sessionStorage.removeItem('isAnonymous');
}

export default toExecuteBeforeSoftLogin;
