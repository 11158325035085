//global
import React, { useEffect } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useOcSelector } from 'src/redux/ocStore';
//lib
import { ComponentProps } from 'lib/component-props';
//helpers
import ImageHelper from 'components/helpers/ImageHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import ButtonHelper from 'components/helpers/Button';
import LinkHelper from 'components/helpers/LinkHelper';
import {
  // formatDateForGTM,
  getPromotionObject,
  // notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'components/helpers/Constants';
import { useBreakpoints } from 'src/utils/breakpoints';
import clsx from 'clsx';
import useExperienceEditor from 'src/hooks/useExperienceEditor';

//type
export type TitleBannerWithImageAndLinkProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.TitleBannerWithImageAndLink.Fields.TitleBannerWithImageAndLink;
//component variants
const titleBannerWithImageAndLinkVariants = tv(
  {
    slots: {
      base: ['container', 'mx-auto'],
      ctaButton: [
        '!text-heading-desk-medium-bold',
        '!font-heading-desk-medium-bold',
        '!leading-heading-desk-medium-bold',
      ],
      link: ['hover:no-underline', 'flex', 'justify-between', 'text-white', 'items-center'],
      img: ['h-[110px]', 'w-[112px]'],
      imgWrapper: ['gap-mob-space-between-micro-horizontal'],
      title: [''],
      description: ['max-w-[596px]', 'text-center'],
    },
    compoundSlots: [
      { slots: ['base', 'imgWrapper'], class: ['items-center'] },
      { slots: ['title', 'description'], class: ['w-full'] },
    ],
    variants: {
      size: {
        mobile: {
          link: [
            'py-mob-global-spacing-spacing-5',
            'flex-col',
            'px-mob-padding-tight-x',
            'gap-mob-space-between-loose-horizontal',
            'after:!content-none',
          ],
          imgWrapper: ['hidden'],
          title: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          description: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
        },
        desktop: {
          link: [
            'py-desk-global-spacing-spacing-3',
            'flex-row',
            'gap-auto',
            'px-desk-padding-tight-x',
            'gap-desk-space-between-loose-horizontal',
          ],
          description: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          imgWrapper: ['flex'],
          title: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

/**
 * Representing a Main component
 */
const TitleBannerWithImageAndLink: React.FC<TitleBannerWithImageAndLinkProps> = ({
  fields,
  params,
}) => {
  const { base, imgWrapper, img, title, description, ctaButton, link } =
    titleBannerWithImageAndLinkVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });

  //Getting current Store ID
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const isEE = useExperienceEditor();

  // Promotion GTM data Object
  const gtmPromotion = getPromotionObject(fields, deviceName);

  //Promotion GTM data push on load
  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bg = (fields as any)?.backgroundColor?.fields?.Value?.value;

  if (fields === undefined || fields === null) return <></>;

  return (
    <div className={'titleBannerWithImageAndLink'}>
      <div
        className={base({
          className: `${params?.Styles ?? ''} ${isEE ? `bg-[${bg}]` : ''} `,
        })}
        style={{
          backgroundColor: bg,
        }}
        data-component={'components/TitleBannerWithImageAndLink/TitleBannerWithImageAndLink'}
        data-creative-id={fields?.componentName?.value}
        data-promotion-cta={fields?.cTAButtonCopy?.value}
        data-promo-id={fields?.promotionId?.value}
        data-promotion-name={fields?.promotionName?.value}
        data-promotion-copy={fields?.promotionCopy?.value}
        dta-promotion-url={fields?.promotionURL?.value?.href}
        onClick={() => {
          if (myStoreId && fields?.componentName?.value !== '') {
            trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
          }
        }}
      >
        <LinkHelper field={fields?.link} className={link()} hideLinkInEE={true}>
          <div className={imgWrapper()}>
            <ImageHelper field={fields?.image} className={img()} />
            <RichTextHelper
              tag={'p'}
              field={fields?.title}
              className={clsx(title(), isEE ? 'text-black' : '')}
            />
          </div>
          <div className={description()}>
            <RichTextHelper
              field={fields?.description}
              className={clsx(description(), isEE ? 'text-black' : '')}
            />
          </div>
          <ButtonHelper
            field={fields?.link}
            variant={'secondary'}
            isLinkfield={true}
            size="medium"
            asButton={true}
            className={ctaButton({ className: 'whitespace-nowrap' })}
          />
        </LinkHelper>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<TitleBannerWithImageAndLinkProps>(TitleBannerWithImageAndLink);
