import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { LuPlus } from 'react-icons/lu';
import { ConditionItem } from './ConditionItem';
import { ConditionTypes, IPersonalizationCondition } from '../models';
import { container } from '../styles';

interface ConditionListProps {
  ruleIndex: number;
  conditions: IPersonalizationCondition[];
  addCondition: (condition: IPersonalizationCondition) => void;
  removeCondition: (index: number) => void;
  swapCondition: (oldIndex: number, newIndex: number) => void;
}

export function ConditionList({
  ruleIndex,
  conditions,
  addCondition,
  removeCondition,
  swapCondition,
}: ConditionListProps) {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }

    const oldIndex = conditions.findIndex(
      (condition) => typeof condition !== 'string' && condition.id === active.id
    );
    const newIndex = conditions.findIndex(
      (condition) => typeof condition !== 'string' && condition.id === over?.id
    );

    if (oldIndex === -1 || newIndex === -1) {
      return;
    }

    swapCondition(oldIndex, newIndex);
  };

  const createNewCondition = () => {
    const operator = 'and';
    addCondition(operator);
    const newCondition: IPersonalizationCondition = {
      id: new Date().getTime().toString(),
      type: ConditionTypes.WHERE_STORE_IS,
      value: '',
    };
    addCondition(newCondition);
  };

  const deleteCondition = (index: number, conditionsCount: number) => {
    if (conditionsCount === 1) {
      removeCondition(index);
    } else if (index > 0) {
      removeCondition(index);
      removeCondition(index - 1);
    } else {
      removeCondition(0);
      removeCondition(0);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={(e: DragEndEvent) => handleDragEnd(e)}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={conditions.map((condition) =>
          typeof condition === 'string' ? condition : condition?.id
        )}
        strategy={verticalListSortingStrategy}
      >
        <div className={container()}>
          {conditions.map((condition, index) => (
            <ConditionItem
              key={`rules.${ruleIndex}.conditions.${index}`}
              conditionIndex={index}
              removeCondition={() => deleteCondition(index, conditions.length)}
              condition={condition}
              ruleIndex={ruleIndex}
            />
          ))}
          <button
            type="button"
            onClick={createNewCondition}
            className="flex items-center w-full text-left p-3 gap-3 border-dashed border-gray-200 hover:border-gray-300 border-2 bg-gray-600/5 text-gray-500 text-lg hover:bg-gray-600/10 transition-colors"
          >
            <LuPlus className="[&>svg>*>*]:fill-gray-500" />
            <p>Create new condition</p>
          </button>
        </div>
      </SortableContext>
    </DndContext>
  );
}
