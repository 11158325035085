/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import ButtonHelper from 'components/helpers/Button';
import { GTM_EVENT, GTMLabels } from 'components/helpers/Constants';
import IconHelper from 'components/helpers/IconHelper';
import Loader from 'components/Loader/Loader';
import { StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import ComponentContext from 'lib/context/ComponentContext';
import { useTheme } from 'lib/context/ThemeContext';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuthToken } from 'src/helpers/MiddlewareToken';

import { REQUEST_METHOD } from 'src/helpers/MyPetsHelper';
import useDictionary from 'src/hooks/useDictionary';
import { useOcSelector } from 'src/redux/ocStore';
import { apiRequest } from 'src/utils/apiWrapper';
import { groomingStepsData } from 'src/utils/sendGTMEvent';
import { tv } from 'tailwind-variants';

interface StoreBase {
  ChildName: string;
  ChildBreed: string;
}
export interface Service {
  ChildId?: string;
  serviceid: string;
  servicename: string;
  serviceprice: string;
  servicedescription?: string;
  dealid?: number;
  Services?: StoreBase[];
  title?: string;
}

const ScheduleAppointmentStep3TV = tv(
  {
    slots: {
      base: ['step3-wrapper'],
      step1HeadingWrapper: ['flex flex-row justify-between'],
      step1HeadingTitle: [
        'font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold text-color-text-black',
      ],
      step1HeadingLink: [
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline',
        'cursor-pointer',
      ],
      serviceItemWrapper: ['flex flex-row flex-wrap'],
      serviceItem: ['flex lg:flex-col lg:w-4/12 grow mb-[12px] w-full flex-row'],

      serviceControlWrapper: ['flex flex-row'],
      inputControl: ['mr-[12px] relative'],
      seviceInfoWrapper: ['flex flex-col'],
      serviceName: [
        'font-body-large-bold text-body-large-bold leading-body-large-bold text-color-text-black',
      ],
      servicePrice: [
        'font-body-large-bold text-body-large-bold leading-body-large-bold text-color-text-black',
      ],
      selectedBaseServices: ['flex flex-col'],
      selectedServiceHeadingWrapper: ['flex flex-row mt-[20px]'],
      selectedServiceHeading: [
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-black mb-[12px]',
      ],
      serviceDescription: ['service-description'],
      expand: ['text-color-background-brand-1 ml-[7px] text-[24px] cursor-pointer leading-[100%] '],
      seriveExpandIcon: ['flex flex-row items-start'],
      editService: [
        'text-color-background-brand-1 ml-[12px] text-body-large-bold_underline font-body-large-bold_underline leading-body-large-bold_underline underline cursor-pointer',
      ],
      checkbox: [
        'w-[24px]',
        'h-[24px]',
        'outline-color-border-mid',
        'border',
        'appearance-none',
        'bg-color-background-white',
        'peer',
        'checked:bg-color-brand-primary-1-base',
        'rounded-[4px]',
        'border',
        'border-color-border-brand',
      ],
      checkboxIcon: [
        '[&>svg>*>*]:fill-color-background-white absolute [&>svg]:w-[24px] [&>svg]:h-[24px] invisible peer-checked:visible left-0',
      ],
      radioButton:
        'h-6 w-6 cursor-pointer min-h-6 min-w-6 appearance-none border border-color-border-dark rounded-full outline-none shadow-[inset_22px_0px_6px_6px_color-brand-primary-1-base] border-color-border-mid border-2 bg-color-scale-1-white',
      radioButtonFiller:
        'cursor-pointer absolute top-[1px] left-[1px] h-[22px] w-[22px] bg-color-brand-primary-1-base rounded-full',
    },
  },
  { responsiveVariants: ['lg'] }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ScheduleAppointmentStep3 = ({
  data,
  onInitiateStep4,
  selectedStoreFromStep1,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInitiateStep4: (data: any) => void;
  selectedStoreFromStep1: StoreProps | null;
}) => {
  const {
    base,
    step1HeadingWrapper,
    step1HeadingTitle,
    serviceItemWrapper,
    serviceItem,
    serviceControlWrapper,
    inputControl,
    seviceInfoWrapper,
    serviceName,
    servicePrice,
    selectedBaseServices,
    selectedServiceHeadingWrapper,
    selectedServiceHeading,
    expand,
    seriveExpandIcon,
    serviceDescription,
    editService,
    checkbox,
    checkboxIcon,
    radioButton,
    radioButtonFiller,
  } = ScheduleAppointmentStep3TV({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { getDictionaryValue } = useDictionary();
  const [selectedService, setSelectedService] = useState<string>('');
  const [selectedAddonsService, setSelectedAddonsService] = useState<string>('None');
  const [selectedSingleService, setselectedSingleService] = useState<string>('');
  const [showAddons, setShowAddons] = useState<boolean>(false);
  const [storeBaseService, setStoreBaseService] = useState<Service[]>([]);
  const [expandedServices, setExpandedServices] = useState<{ [key: string]: boolean }>({});
  const [showSingleSelectServices, setshowSingleSelectServices] = useState<boolean>(false);
  const [showMultiSelectServices, setshowMultiSelectServices] = useState<boolean>(false);
  const [singleSelectionService, setsingleSelectionService] = useState<number>();
  const [multiSelectionService, setmultiSelectionService] = useState<number>();
  const [showSubmit, setshowSubmit] = useState<boolean>(false);
  const [showAllSelectedServices, setshowAllSelectedServices] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [componentData, setcomponentData] = useState<any>(null);
  const [matchedItems, setMatchedItems] = useState([]);
  const [fetchservices, setFetchServices] = useState<boolean>(false);
  const [showcategoryonmobile, setShowCategoryOnMobile] = useState<boolean>(true);
  const [showaddonsonmobile, setShowAddonsOnMobile] = useState<boolean>(true);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userDetails = useSelector((state: any) => state?.ocUser?.user);
  const { themeNameUpper } = useTheme();
  const token = useAuthToken();
  const { sitecoreContext } = useSitecoreContext();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [hasstep3gtmrun, setHasStep3GtmRun] = useState(false);

  useEffect(() => {
    if (componentContextData.bookedAppointmentForStep3 !== null) {
      setStoreBaseService(componentContextData?.bookedAppointmentForStep3);
      getGroomingServices(false);
    } else {
      setStoreBaseService([]);
    }
  }, [componentContextData.bookedAppointmentForStep3]);

  useEffect(() => {
    if (
      hasstep3gtmrun === false &&
      componentContextData &&
      componentContextData.bookedAppointmentForStep3
    ) {
      groomingStepsData({
        eventName: GTM_EVENT?.groomingSteps,
        storeId: myStoreData?.storeId,
        groomingStoreId: componentContextData?.bookedAppointmentForStep1?.Location?.PspStoreId,
        stepName: GTMLabels?.groomingStep3StepName,
      });
      setHasStep3GtmRun(true);
    }
  }, [componentContextData.bookedAppointmentForStep3]);

  useEffect(() => {
    setcomponentData(data);
  }, [data]);

  const handleSelection = (serviceId: string) => {
    setshowSingleSelectServices(false);
    setSelectedService(serviceId);
  };

  // This enables single service selection (Puppy services)
  const setSingleSelectedService = (service: Service) => {
    setselectedSingleService(service?.serviceid);
    setshowSingleSelectServices(false);
    setShowAddons(true);
    setStoreBaseService((prevServices) => {
      if (prevServices?.some((item) => item?.serviceid === service?.serviceid)) {
        return prevServices.filter((item) => item?.serviceid !== service?.serviceid);
      } else {
        return [...prevServices, service];
      }
    }); // Append new service
  };

  // This enables multi service selection (Puppy services)
  const setMultiSelectedService = (service: Service) => {
    setselectedSingleService(service?.serviceid);
    //setshowMultiSelectServices(false);
    setShowAddons(false);
    setStoreBaseService((prevServices) => {
      if (prevServices?.some((item) => item?.serviceid === service?.serviceid)) {
        return prevServices.filter((item) => item?.serviceid !== service?.serviceid);
      } else {
        return [...prevServices, service];
      }
    }); // Append new service
    setshowSubmit(true);
  };

  //This handles the Addons selection
  const handleAddonsSelection = (serviceId: string) => {
    if (serviceId == '') {
      setSelectedAddonsService('None');
    } else {
      setshowSingleSelectServices(false);
      setshowMultiSelectServices(false);
      setSelectedAddonsService(serviceId);
    }
  };

  // This handles base service selection
  const baseServiceSelection = () => {
    setshowSingleSelectServices(false);
    setshowMultiSelectServices(false);
    setShowAddons(true);
  };

  // This handles toplevel services and also differentiate multi select and single select
  const topLevelServiceSelection = (service: Service) => {
    setsingleSelectionService(service?.dealid);
    setmultiSelectionService(service?.dealid);
    if (service.servicename === 'Puppy Services') {
      setshowSingleSelectServices(true);
      setshowMultiSelectServices(false);
    } else {
      setshowSingleSelectServices(false);
      setshowMultiSelectServices(true);
    }
    setShowAddons(true);
  };

  // This handles + and - expansion on service level (Addons and Packages)
  const toggleExpand = (serviceId: string) => {
    setExpandedServices((prevState) => ({
      ...prevState,
      [serviceId]: !prevState[serviceId],
    }));
  };

  // This handles Edit click on selected service
  const resetServices = () => {
    setExpandedServices({});
    setSelectedService(''); // Deselect radio buttons
    setSelectedAddonsService('None'); // Deselect radio buttons
    setselectedSingleService(''); // Deselect single selection
    setmultiSelectionService(undefined); // Deselect multi selection
    setShowAddons(false);
    setshowSingleSelectServices(false);
    setshowMultiSelectServices(false);
    setStoreBaseService([]);
    setshowSubmit(false);
    getGroomingServices(true);
  };
  const groomingServiceIDs = (sitecoreContext as any)?.route?.fields?.groomingConfiguration?.[0]
    ?.fields.serviceIds?.value;
  const getGroomingServices = async (resetFlag: boolean) => {
    if (componentContextData.bookedAppointmentForStep1 !== undefined) {
      const getGroomingServices = {
        SelectedPetId: componentContextData.bookedAppointmentForStep1?.ChildId,
        SelectedPetName: componentContextData.bookedAppointmentForStep1?.ChildName,
        SelectedPetBreedName: componentContextData.bookedAppointmentForStep1?.ChildBreed,
        SelectedStoreLocationId: componentContextData.bookedAppointmentForStep1?.Location?.Id,
        ServicesId: groomingServiceIDs,
        WebId: userDetails?.xp?.WebId,
      };

      const headersData = {
        site: themeNameUpper,
        Authorization: token,
      };
      // // setPetLoader(true);
      const getGroomingServicesUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/grooming-services`;
      try {
        const options = {
          method: REQUEST_METHOD?.post,
          headers: headersData,
          data: getGroomingServices,
        };
        const res: any = await apiRequest(getGroomingServicesUrl, options);
        if (res) {
          setFetchServices(false);
        }
        if (resetFlag) {
          setcomponentData(res);
        } else {
          const idsToMatch = componentContextData.bookedAppointmentForStep3?.Services.map(
            (item: any) => item.Id
          );

          const result: any = [];

          const checkAndAddMatch = (serviceArray: any, flag: string) => {
            serviceArray.forEach((service: any) => {
              if (idsToMatch.includes(service.serviceid)) {
                result.push({
                  Id: service.serviceid,
                  DealId: service.dealid,
                  Price: service.serviceprice,
                  title:
                    flag === 'base'
                      ? 'Base Service Options'
                      : flag === 'addons'
                      ? 'Optional Add-ons'
                      : flag === 'optionalpackage'
                      ? 'Optional Packages'
                      : 'A la Carte',
                });
              }
            });
          };
          checkAndAddMatch(res?.BaseServices, 'base');
          checkAndAddMatch(res?.AddOnServices, 'addons');
          checkAndAddMatch(res?.Packages, 'optionalpackage');
          checkAndAddMatch(res?.CategoryServices, 'alacarte');
          checkAndAddMatch(res?.TopLevelCategories, 'base');

          setMatchedItems(result);
          setcomponentContextData({
            ...componentContextData,
            dealIds: result,
          });
        }
      } catch (err) {
        console.error('@@Error', err);
      }
    }
  };

  const filterArray = (firstArray: Service[], secondArray: Service[]) => {
    return firstArray?.filter(
      (item1) => !secondArray?.some((item2) => item2?.serviceid === item1?.serviceid)
    );
  };

  useEffect(() => {
    if (componentContextData?.resetStep3 === true) {
      setshowAllSelectedServices(false);
      resetServices();
    }
  }, [componentContextData?.resetStep3]);

  function getCheckedAddOnServices() {
    const checkboxes = document.querySelectorAll('input.addOnService[type="checkbox"]');

    const checkedCheckboxes = Array.from(checkboxes).filter(
      (checkbox) => (checkbox as HTMLInputElement).checked
    );

    return checkedCheckboxes.length;
  }

  const ServiceLoaderComponent = () => {
    return (
      <div className={'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'}>
        <Loader />
        <span>{getDictionaryValue('ScheduleAppointmentsStep3LoadingText')}</span>
      </div>
    );
  };
  return (
    <div className={base()}>
      <div className={(step1HeadingWrapper(), 'mb-[10px] flex flex-row justify-between')}>
        <p className={step1HeadingTitle()}>
          {getDictionaryValue('ScheduleAppointmentsStep3Heading')}
        </p>
        {showAllSelectedServices &&
          componentContextData?.resetStep3 === false &&
          !componentContextData?.bookedAppointmentForStep3 &&
          !fetchservices && (
            <p
              className={editService()}
              onClick={() => {
                setshowAllSelectedServices(false);
                setcomponentContextData({
                  ...componentContextData,
                  resetStep4: true,
                });
              }}
            >
              {getDictionaryValue('EditCTA')}
            </p>
          )}
        {componentContextData?.bookedAppointmentForStep3 && !fetchservices && (
          <p
            className={editService()}
            onClick={() => {
              setStoreBaseService([]);
              setshowAllSelectedServices(false);
              setcomponentContextData({
                ...componentContextData,
                resetStep3: false,
                resetStep4: true,
                bookedAppointmentForStep3: null,
                bookedAppointmentForStep4: null,
              });
              setFetchServices(true);
              resetServices();
            }}
          >
            {getDictionaryValue('EditCTA')}
          </p>
        )}
      </div>
      {fetchservices && <ServiceLoaderComponent />}
      {componentContextData?.resetStep3 === false && !fetchservices && (
        <>
          {(componentData !== null || componentContextData?.bookedAppointmentForStep3) && (
            <>
              <div className="service-wrapper">
                <div className="service-wrapper-serviceheading">
                  {!showAllSelectedServices && (
                    <p className="mb-[12px]">
                      {getDictionaryValue('ScheduleAppointmentsStep3Subheading')}
                    </p>
                  )}
                  {/* --------------------------- BASE SERVICES STARTS HERE ---------------------- */}
                  {!showAddons && !showSubmit && (
                    <div className={serviceItemWrapper()}>
                      {componentData?.BaseServices?.map((service: Service, index: number) => (
                        <div className={serviceItem()} key={index}>
                          <label className={serviceControlWrapper()} htmlFor={service.serviceid}>
                            <div className={inputControl()}>
                              <input
                                aria-label="baseService"
                                id={service?.serviceid}
                                type="radio"
                                name="baseService"
                                value={service?.serviceid}
                                onChange={() => {
                                  setStoreBaseService((prevServices) => {
                                    if (prevServices?.length > 0) {
                                      const filteredServices = filterArray(
                                        prevServices,
                                        componentData?.BaseServices
                                      );
                                      if (
                                        prevServices?.some((item) =>
                                          componentData?.BaseServices?.some(
                                            (s: any) => s?.serviceid === item?.serviceid
                                          )
                                        ) ||
                                        selectedAddonsService == 'None'
                                      ) {
                                        return filteredServices;
                                      } else {
                                        service.title = 'Base Service Options';
                                        return [...filteredServices, service];
                                      }
                                    } else {
                                      service.title = 'Base Service Options';
                                      return [{ ...service }];
                                    }
                                  });
                                  handleSelection(service?.serviceid);
                                  baseServiceSelection();
                                }}
                                className={radioButton()}
                              />
                            </div>
                            <div className={seviceInfoWrapper()}>
                              <div className={serviceName()}>{service.servicename}</div>
                              <div className={servicePrice()}>
                                {getDictionaryValue('CurrencySymbol')}
                                {service.serviceprice}
                              </div>
                              <div className={serviceDescription()}>
                                {service.servicedescription}
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* --------------------------- BASE SERVICES ENDS ABOVE ---------------------- */}
                  {/* --------------------------- TOP LEVEL SERVICES STARTS HERE ---------------------- */}
                  {!showAddons && !showSubmit && !showAllSelectedServices && (
                    <div className={serviceItemWrapper()}>
                      {componentData?.TopLevelCategories.map((service: Service, index: number) => (
                        <div className={serviceItem()} key={index}>
                          <label className={serviceControlWrapper()} htmlFor={service.serviceid}>
                            <div className={inputControl()}>
                              <input
                                aria-label="topLevelService"
                                id={service.serviceid}
                                type="radio"
                                name="topLevelService"
                                value={service.serviceid}
                                onChange={() => {
                                  setStoreBaseService((prevServices) => {
                                    if (prevServices?.length > 0) {
                                      const filteredServices = filterArray(
                                        prevServices,
                                        componentData?.TopLevelCategories
                                      );
                                      if (
                                        prevServices?.some((item) =>
                                          componentData?.TopLevelCategories?.some(
                                            (s: any) => s?.serviceid === item?.serviceid
                                          )
                                        ) ||
                                        selectedAddonsService == 'None'
                                      ) {
                                        return filteredServices;
                                      } else {
                                        service.title = 'Base Service Options';
                                        return [...filteredServices, service];
                                      }
                                    } else {
                                      service.title = 'Base Service Options';
                                      return [{ ...service }];
                                    }
                                  });
                                  handleSelection(service.serviceid);
                                  topLevelServiceSelection(service);
                                }}
                                className={radioButton()}
                              />
                              {selectedService === service.serviceid && (
                                <div className={radioButtonFiller()}></div>
                              )}
                            </div>
                            <div className={seviceInfoWrapper()}>
                              <div className={serviceName()}>{service.servicename}</div>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* --------------------------- TOP LEVEL SERVICES ENDS ABOVE ---------------------- */}
                  {/* --------------------------- DISPLAY OF SELECTED SERVICES STARTS HERE ---------------------- */}
                  {(storeBaseService?.length > 0 ||
                    componentContextData?.bookedAppointmentForStep3?.Services?.length > 0) && (
                    <div className={selectedBaseServices()}>
                      <div className={selectedServiceHeadingWrapper()}>
                        <span className={selectedServiceHeading()}>
                          {getDictionaryValue('ScheduleAppointmentsStep3BaseServiceOptionsHeading')}
                        </span>
                        {!showAllSelectedServices &&
                          !componentContextData?.bookedAppointmentForStep3 && (
                            <p
                              className={editService()}
                              onClick={() => {
                                resetServices();
                              }}
                            >
                              {getDictionaryValue('EditCTA')}
                            </p>
                          )}
                      </div>
                      {/**Showing selected services */}
                      <div className="selected-services-info flex flex-col gap-[5px] mb-[12px]">
                        {/* {(showAllSelectedServices
                        ? storeBaseService */}
                        <>
                          {!componentContextData?.bookedAppointmentForStep3 && (
                            <>
                              {storeBaseService &&
                                storeBaseService.slice(0, 1).map((service, index) => (
                                  <div key={index} className="flex flex-row gap-[5px]">
                                    <p>{service.servicename}</p>
                                    <p>
                                      {getDictionaryValue('CurrencySymbol')}
                                      {service.serviceprice}
                                    </p>
                                  </div>
                                ))}

                              {showAllSelectedServices &&
                                storeBaseService?.slice(1)?.map((service, index) => (
                                  <div key={index} className="flex flex-row gap-[5px]">
                                    <p>{service.servicename}</p>
                                    <p>
                                      {getDictionaryValue('CurrencySymbol')}
                                      {service.serviceprice}
                                    </p>
                                  </div>
                                ))}
                            </>
                          )}
                          {componentContextData?.bookedAppointmentForStep3 &&
                            componentContextData?.bookedAppointmentForStep3?.Services && (
                              <div className="selected-services-info flex flex-col gap-[5px] mb-[12px]">
                                {(componentContextData?.bookedAppointmentForStep3?.Services).map(
                                  (service: any, index: number) => {
                                    // Find the matching service from MatchedItems if service.Name is not available

                                    const matchedService =
                                      service.Name &&
                                      matchedItems.find((item: any) => item.Id === service.Id);
                                    return (
                                      <div key={index} className="flex flex-row gap-[5px]">
                                        <p>{service.Name}</p>
                                        <p>
                                          {getDictionaryValue('CurrencySymbol')}
                                          {service.Amount || matchedService?.Price}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                        </>
                      </div>
                    </div>
                  )}
                  {/* --------------------------- DISPLAY OF SELECTED SERVICES ENDS HERE ---------------------- */}
                  {/* --------------------------- CATEGORY SERVICES STARTS HERE ---------------------- */}
                  {showSingleSelectServices && (
                    <div className={serviceItemWrapper()}>
                      {componentData?.CategoryServices.filter(
                        (service: Service) => service?.dealid === singleSelectionService
                      ).map((service: Service, index: number) => (
                        <div className={serviceItem()} key={index}>
                          <label className={serviceControlWrapper()} htmlFor={service.serviceid}>
                            <div className={inputControl()}>
                              <input
                                aria-label="topLevelService"
                                id={service.serviceid}
                                type="radio"
                                name="topLevelService"
                                value={service.serviceid + `${service.serviceid}`}
                                checked={storeBaseService?.some(
                                  (s) => s?.serviceid === service.serviceid
                                )}
                                onChange={() => {
                                  service.title = 'A la Carte';
                                  setSingleSelectedService(service);
                                }}
                                className={radioButton()}
                              />
                              {selectedSingleService === service.serviceid && (
                                <div className={radioButtonFiller()}></div>
                              )}
                            </div>
                            <div className={seviceInfoWrapper()}>
                              <div className={serviceName()}>{service.servicename}</div>
                              <div className={servicePrice()}>
                                {getDictionaryValue('CurrencySymbol')}
                                {service.serviceprice}
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* --------------------------- CATEGORY SERVICES ENDS ABOVE ---------------------- */}
                  {/* --------------------------- CATEGORY (Multi select) SERVICES STARTS HERE ---------------------- */}
                  {showMultiSelectServices && !showAllSelectedServices && (
                    <div className={serviceItemWrapper()}>
                      {componentData?.CategoryServices.filter(
                        (service: Service) => service.dealid === multiSelectionService
                      ).map((service: Service, index: number) => (
                        <div className={serviceItem()} key={index}>
                          <div className="flex flex-row">
                            <label className={serviceControlWrapper()} htmlFor={service.serviceid}>
                              <div className={inputControl()}>
                                <input
                                  aria-label="topLevelService"
                                  id={service.serviceid}
                                  type="checkbox"
                                  name="topLevelService"
                                  value={service.serviceid + `${service.serviceid}`}
                                  checked={storeBaseService?.some(
                                    (s) => s?.serviceid === service.serviceid
                                  )}
                                  onChange={() => {
                                    service.title = 'A la Carte';
                                    setMultiSelectedService(service);
                                  }}
                                  className={checkbox()}
                                />
                                <IconHelper className={checkboxIcon()} icon={'icon-checkmark'} />
                              </div>
                            </label>
                            <div className={seviceInfoWrapper()}>
                              <div className={seriveExpandIcon()}>
                                <div
                                  className={serviceName({ className: 'cursor-pointer' })}
                                  onClick={() => {
                                    toggleExpand(service?.serviceid);
                                  }}
                                >
                                  <p className="cursor-pointer">{service?.servicename}</p>
                                </div>
                                <div className={servicePrice()}>
                                  {getDictionaryValue('CurrencySymbol')}
                                  {service.serviceprice}
                                </div>
                                <span
                                  className={expand()}
                                  onClick={() => {
                                    toggleExpand(service?.serviceid);
                                  }}
                                >
                                  {expandedServices[service?.serviceid] ? '-' : '+'}
                                </span>
                              </div>
                              {expandedServices[service?.serviceid] && (
                                <div className={serviceDescription()}>
                                  {service?.servicedescription}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* --------------------------- CATEGORY (Multi select) SERVICES ENDS ABOVE ---------------------- */}
                  {/* --------------------------- PACKAGES STARTS HERE ---------------------- */}
                  {showAddons &&
                    !showSingleSelectServices &&
                    !showMultiSelectServices &&
                    !showAllSelectedServices && (
                      <div className={serviceItemWrapper()}>
                        <div className="service-wrapper-serviceheading">
                          <p className={selectedServiceHeading()}>
                            {getDictionaryValue('ScheduleAppointmentsStep3PackagesHeading')}
                            <span
                              className={clsx(expand(), 'lg:hidden')}
                              onClick={() => {
                                setShowCategoryOnMobile(!showcategoryonmobile);
                              }}
                            >
                              {showcategoryonmobile ? '-' : '+'}
                            </span>
                          </p>
                          {!showcategoryonmobile && selectedAddonsService !== 'None' && (
                            <div className="flex flex-row lg:my-0 my-[16px]">
                              <p>
                                {
                                  storeBaseService?.find(
                                    (service: Service) =>
                                      service?.serviceid === selectedAddonsService
                                  )?.servicename
                                }
                              </p>
                              <div className={clsx(servicePrice(), 'ml-[7px]')}>
                                {getDictionaryValue('CurrencySymbol')}
                                {
                                  storeBaseService?.find(
                                    (service: Service) =>
                                      service?.serviceid === selectedAddonsService
                                  )?.serviceprice
                                }
                              </div>
                            </div>
                          )}
                          {!showcategoryonmobile && selectedAddonsService == 'None' && (
                            <div className="flex flex-row lg:my-0 my-[16px]">
                              <p>
                                {getDictionaryValue(
                                  'ScheduleAppointmentsStep3NoPackagesSelectedText'
                                )}
                              </p>
                            </div>
                          )}
                          {showcategoryonmobile && (
                            <div className={serviceItemWrapper()}>
                              {componentData?.Packages.map((service: Service, index: number) => (
                                <div
                                  className={serviceItem({ className: 'flex !flex-row' })}
                                  key={index}
                                >
                                  <label
                                    className={serviceControlWrapper()}
                                    htmlFor={service.serviceid}
                                  >
                                    <div className={inputControl()}>
                                      <input
                                        aria-label="packagesService"
                                        id={service.serviceid}
                                        type="radio"
                                        name="packagesService"
                                        value={service.serviceid}
                                        checked={storeBaseService?.some(
                                          (s) => s?.serviceid === service.serviceid
                                        )}
                                        onChange={() => {
                                          setStoreBaseService((prevServices) => {
                                            const filteredServices = filterArray(
                                              prevServices,
                                              componentData?.Packages
                                            );
                                            if (
                                              prevServices?.some((item) =>
                                                componentData?.Packages?.some(
                                                  (s: any) => s?.serviceid === item?.serviceid
                                                )
                                              )
                                            ) {
                                              service.title = 'Optional Packages';
                                              return [...filteredServices, service];
                                            } else {
                                              service.title = 'Optional Packages';
                                              return [...filteredServices, service];
                                            }
                                          });
                                          handleAddonsSelection(service?.serviceid);
                                        }}
                                        className={radioButton()}
                                      />
                                      {selectedAddonsService === service?.serviceid && (
                                        <div className={radioButtonFiller()}></div>
                                      )}
                                    </div>
                                  </label>
                                  <div className={seviceInfoWrapper()}>
                                    <div className={seriveExpandIcon()}>
                                      <div
                                        className={serviceName({ className: 'cursor-pointer' })}
                                        onClick={() => {
                                          toggleExpand(service?.serviceid);
                                        }}
                                      >
                                        {service?.servicename}
                                      </div>
                                      <div className={clsx(servicePrice(), 'ml-[7px]')}>
                                        {getDictionaryValue('CurrencySymbol')}
                                        {service?.serviceprice}
                                      </div>
                                      <span
                                        className={expand()}
                                        onClick={() => {
                                          toggleExpand(service?.serviceid);
                                        }}
                                      >
                                        {expandedServices[service?.serviceid] ? '-' : '+'}
                                      </span>
                                    </div>
                                    {expandedServices[service?.serviceid] && (
                                      <div className={serviceDescription()}>
                                        {service?.servicedescription}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className={serviceItem()}>
                                <label className={serviceControlWrapper()} htmlFor="None">
                                  <div className={inputControl()}>
                                    <input
                                      aria-label="baseService"
                                      id="None"
                                      type="radio"
                                      name="baseService"
                                      value="checked"
                                      checked={selectedAddonsService === 'None'}
                                      onChange={() => {
                                        handleAddonsSelection('');
                                        setStoreBaseService((prevServices) => {
                                          const filteredServices = filterArray(
                                            prevServices,
                                            componentData?.Packages
                                          );
                                          const filteredServices2 = filterArray(
                                            filteredServices,
                                            componentData?.TopLevelCategories
                                          );
                                          return filteredServices2;
                                        });
                                      }}
                                      className={radioButton()}
                                    />
                                    {selectedAddonsService ===
                                      ('None' || '' || undefined || null) && (
                                      <div className={radioButtonFiller()}></div>
                                    )}
                                  </div>
                                  <div className={seviceInfoWrapper()}>
                                    <div className={serviceName()}>
                                      {getDictionaryValue('none')}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  {/* --------------------------- PACKAGES ENDS HERE ---------------------- */}
                  {/* --------------------------- ADD ON SERVICE STARTS HERE ---------------------- */}
                  {showAddons &&
                    !showSingleSelectServices &&
                    !showMultiSelectServices &&
                    !showAllSelectedServices && (
                      <div className={serviceItemWrapper()}>
                        <div className="service-wrapper-serviceheading">
                          <div className={selectedServiceHeading()}>
                            <p>
                              {getDictionaryValue('ScheduleAppointmentsStep3AddOnsHeadingText')}
                              <span
                                className={clsx(expand(), 'lg:hidden')}
                                onClick={() => {
                                  setShowAddonsOnMobile(!showaddonsonmobile);
                                }}
                              >
                                {showaddonsonmobile ? '-' : '+'}
                              </span>
                            </p>

                            {getCheckedAddOnServices() === 0 && showaddonsonmobile && (
                              <p
                                className={
                                  'mt-[7px] text-body-small-regular font-body-small-regular leading-body-small-regular'
                                }
                              >
                                {getDictionaryValue(
                                  'ScheduleAppointmentsStep3NoAddOnsSelectedText'
                                )}
                              </p>
                            )}
                          </div>

                          {!showaddonsonmobile &&
                            componentData?.AddOnServices.filter((service: Service) =>
                              storeBaseService?.some((s) => s?.serviceid === service?.serviceid)
                            ).map((s: Service) => (
                              <div key={s?.serviceid} className="flex flex-row lg:my-0 my-[16px]">
                                <p className="mr-7px">{s?.servicename}</p>
                                <div className={clsx(servicePrice(), 'ml-[7px] block')}>
                                  {getDictionaryValue('CurrencySymbol')}
                                  {s?.serviceprice}
                                </div>
                              </div>
                            ))}

                          {showaddonsonmobile && (
                            <div className={serviceItemWrapper()}>
                              {componentData?.AddOnServices.map(
                                (service: Service, index: number) => (
                                  <div className={serviceItem()} key={index}>
                                    <div className="flex flex-row">
                                      <label
                                        className={serviceControlWrapper()}
                                        htmlFor={service?.serviceid}
                                      >
                                        <div className={inputControl()}>
                                          <input
                                            aria-label={`addOnService_${index}`}
                                            id={service?.serviceid}
                                            type="checkbox"
                                            name={`addOnService_${index}`}
                                            value={service?.serviceid}
                                            className={clsx(checkbox(), 'addOnService')}
                                            checked={storeBaseService?.some(
                                              (s) => s?.serviceid === service?.serviceid
                                            )}
                                            onChange={() => {
                                              setStoreBaseService((prevServices) => {
                                                if (
                                                  prevServices?.some(
                                                    (item) => item?.serviceid === service?.serviceid
                                                  )
                                                ) {
                                                  return prevServices.filter(
                                                    (item) => item?.serviceid !== service?.serviceid
                                                  );
                                                } else {
                                                  service.title = 'Optional Add-ons';
                                                  return [...prevServices, service];
                                                }
                                              });
                                              handleSelection(service?.serviceid);
                                            }}
                                          />
                                          <IconHelper
                                            className={checkboxIcon()}
                                            icon={'icon-checkmark'}
                                          />
                                        </div>
                                      </label>
                                      <div className={seviceInfoWrapper()}>
                                        <div className={seriveExpandIcon()}>
                                          <div
                                            className={serviceName({ className: 'cursor-pointer' })}
                                            onClick={() => {
                                              toggleExpand(service?.serviceid);
                                            }}
                                          >
                                            {service?.servicename}
                                          </div>
                                          <span
                                            className={expand()}
                                            onClick={() => {
                                              toggleExpand(service?.serviceid);
                                            }}
                                          >
                                            {expandedServices[service?.serviceid] ? '-' : '+'}
                                          </span>
                                        </div>
                                        <div className={servicePrice()}>
                                          {getDictionaryValue('CurrencySymbol')}
                                          {service?.serviceprice}
                                        </div>
                                        {expandedServices[service?.serviceid] && (
                                          <div className={serviceDescription()}>
                                            {service?.servicedescription}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  {/* --------------------------- ADD ON SERVICE STARTS HERE ---------------------- */}
                  {/* --------------------------- SELECT SERVICES STARTS HERE ---------------------- */}
                  {(showAddons || showMultiSelectServices) &&
                    !showSingleSelectServices &&
                    !showAllSelectedServices && (
                      <div className="select-services-cta">
                        <ButtonHelper
                          field={{
                            value: getDictionaryValue(
                              'ScheduleAppointmentsStep3SelectServicesButtonText'
                            ),
                          }}
                          isLinkfield={false}
                          size="medium"
                          variant={
                            showMultiSelectServices &&
                            !storeBaseService?.some((item) =>
                              componentData?.CategoryServices?.some(
                                (s: any) => s?.serviceid === item?.serviceid
                              )
                            )
                              ? 'disabled'
                              : 'primary'
                          }
                          className="mt-[10px]"
                          onClickHandler={() => {
                            setcomponentContextData({
                              ...componentContextData,
                              resetStep4: false,
                            });
                            onInitiateStep4(storeBaseService);
                            setshowAllSelectedServices(true);
                            groomingStepsData({
                              eventName: GTM_EVENT?.groomingSteps,
                              storeId: myStoreData?.storeId,
                              groomingStoreId: selectedStoreFromStep1?.storeid,
                              stepName: GTMLabels?.groomingStep3StepName,
                            });
                          }}
                          isDisbaled={
                            showMultiSelectServices &&
                            !storeBaseService?.some((item) =>
                              componentData?.CategoryServices?.some(
                                (s: any) => s?.serviceid === item?.serviceid
                              )
                            )
                          }
                        />
                      </div>
                    )}
                  {/* --------------------------- SELECT SERVICES ENDS HERE ---------------------- */}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ScheduleAppointmentStep3;
