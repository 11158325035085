import { ComponentProps } from 'lib/component-props';
import { FulfillmentSwitch } from './FulfillmentSwitch';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { tv } from 'tailwind-variants';
import { useSitecoreContext, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';

export type FulfillmentSwitcherProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.FulfillmentSwitcher.Fields.FulfillmentSwitcher;

const FulfillmentSwitcherVariants = tv(
  {
    slots: {
      container: ['fulfillment-switcher', 'flex', 'mb-[15px]'],
      pageTitle: [
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
      cartNumberWrapper: ['flex', 'items-center'],
      cartText: [
        'font-heading-desk-xLarge-bold',
        'font-primary',
        'leading-heading-desk-xLarge-bold',
        'text-heading-desk-xLarge-bold',
        'text-color-text-black',
      ],
      cartNumber: [
        'text-body-large-regular',
        'leading-body-large-regular',
        'font-body-large-regular',
        'flex',
        'gap-[3px]',
      ],
    },
    compoundSlots: [],
    variants: {
      device: {
        mobile: {
          container: ['flex-col', 'items-start'],
          cartNumberWrapper: ['gap-mob-margin-base-right'],
        },
        desktop: {
          container: ['justify-between', 'items-center', 'self-stretch', 'flex-row'],
          cartNumberWrapper: ['gap-desk-margin-base-right'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const FulfillmentSwitcher = ({
  fields,
  params,
  rendering,
}: FulfillmentSwitcherProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const pageTitleText = sitecoreContext.route?.fields?.title as TextField;
  const { container, pageTitle, cartNumberWrapper, cartNumber } = FulfillmentSwitcherVariants({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const showTitle = params?.showCheckoutTitle === '1' ? true : false;
  const showItems = params?.showCartCount === '1' ? true : false;
  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  return (
    <div className={container()}>
      {(showTitle || showItems) && (
        <div className={cartNumberWrapper()}>
          {showTitle && (
            <div className={pageTitle()}>
              {pageTitleText?.value ? <Text field={pageTitleText} tag="h1" /> : <h1>Checkout</h1>}
            </div>
          )}
          {showItems && (
            <p className={cartNumber()}>
              {productlineitems?.length}
              <Text tag="span" field={fields?.itemLabel} />
            </p>
          )}
        </div>
      )}
      <FulfillmentSwitch fields={fields} params={params} componentName={rendering?.componentName} />
    </div>
  );
};

export default FulfillmentSwitcher;
