import React from 'react';
import Link from 'next/link';
import { BreadcrumbVariants } from './BreadcrumbVariants';
import clsx from 'clsx';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'components/helpers/IconHelper';

interface SiblingItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

interface BreadcrumbData {
  siblings: {
    children: {
      results: SiblingItem[];
    };
  };
}

interface OnlyBreadCrumbSiblingsProps {
  gqldata: BreadcrumbData;
  siteName: string;
  breadCrumbTitle?: Field<string>;
  pageTitle?: Field<string>;
  formattedItemId: string;
}

interface IconProps {
  sitename?: string;
  className?: string;
}

//Tailwind Variants Styles
const {
  breadcrumbText,
  breadcrumbContainer,
  ancestorsWrapper,
  siblingsWrapper,
  siblingsList,
  siblingItem,
  siblingLink,
} = BreadcrumbVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const LinkIcon = ({ className }: IconProps): JSX.Element => {
  return (
    <span className={className}>
      <IconHelper icon={'breadcrumb-down'} className="[&>svg>path]:fill-color-text-brand-1" />
    </span>
  );
};

const getSiblings = (siblings: SiblingItem[], currentItemId: string): SiblingItem[] => {
  return siblings?.filter((sibling) => sibling.id !== currentItemId);
};

const OnlyBreadCrumbSiblings: React.FC<OnlyBreadCrumbSiblingsProps> = ({
  gqldata,
  siteName,
  breadCrumbTitle,
  pageTitle,
  formattedItemId,
}) => {
  const { siblings } = gqldata;
  const filteredSiblings = getSiblings(siblings?.children?.results, formattedItemId);
  const title = breadCrumbTitle?.value || pageTitle?.value;
  return (
    <div>
      {/* Breadcrumb Rendering */}
      <nav aria-label="breadcrumb" className={breadcrumbContainer()}>
        <ul className={ancestorsWrapper()}>
          <li className={clsx(ancestorsWrapper(), 'relative', 'group', 'flex')}>
            <span className={clsx(breadcrumbText())}>{title}</span>
            {filteredSiblings?.length > 0 && (
              <LinkIcon sitename={siteName} className={'group-hover:rotate-180'} />
            )}
            {/* Siblings Rendering */}
            {filteredSiblings?.length > 0 && (
              <div className={siblingsWrapper()}>
                <ul className={siblingsList()}>
                  {filteredSiblings?.map((sibling, index) => (
                    <li key={sibling?.id} className={siblingItem()}>
                      <Link
                        aria-label={'breadcrumbTitle' + index}
                        href={sibling?.url?.path}
                        className={clsx(siblingLink(), 'block hover:no-underline')}
                      >
                        {sibling?.breadcrumbTitle?.value || sibling?.title?.value}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default OnlyBreadCrumbSiblings;
