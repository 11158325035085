import { tv } from 'tailwind-variants';

export const OrderConfirmationVariant = tv(
  {
    slots: {
      calloutBase: ['flex', 'flex-col', 'w-full'],
      calloutTextWrapper: 'flex w-full gap-[8px]  bg-color-confirmation-block items-center',
      title: '',
      calloutTitle: '',
      calloutText: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      link: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-color-text-brand-1',
      ],
      formContainer: [
        'container',
        'flex',
        'flex-col',
        'contact-us',
        'w-full',
        '[&>*]:w-full',
        'gap-[24px]',
      ],
      shortDescription: [
        'text-left',
        'text-color-text-dark',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
    },
    variants: {
      device: {
        mobile: {
          calloutBase: ['gap-mob-margin-tight-bottom', 'py-mob-padding-micro-y'],
          calloutTextWrapper: ['p-mob-global-spacing-spacing-4'],
          title: [
            'text-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
          ],
          calloutTitle: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
          formContainer: ['p-mob-padding-micro-x'],
        },
        desktop: {
          calloutBase: ['gap-desk-margin-tight-bottom', 'py-desk-padding-micro-y'],
          calloutTextWrapper: ['p-desk-global-spacing-spacing-4'],
          title: [
            'text-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
          ],
          calloutTitle: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          formContainer: [
            'px-inherit',
            'p-desk-padding-micro-x',
            'px-0',
            'pb-desk-margin-base-bottom',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
