const config = require('../../../temp/config');
const { GraphQLRequestClient } = require('@sitecore-jss/sitecore-jss-nextjs/graphql');

const keyValueOfHeader = {
  contentSecurityPolicy: process.env.DISABLE_CSP
    ? 'Content-Security-Policy-Report-Only'
    : 'Content-Security-Policy',
};
const getCorsHeaderData = async () => {
  try {
    const headerQuery = `query{
    homeItem: item(path: "{86C07A48-894C-46E2-B333-5CF629215C1A}", language: "en") {
      ... on HTTPHeaders{
        contentSecurityPolicy{
          value
        }
      }
    }
  }`;

    const graphQLClient = new GraphQLRequestClient(config.graphQLEndpoint, {
      apiKey: config.sitecoreApiKey,
    });
    const result = await graphQLClient.request(headerQuery, {
      language: 'en',
    });
    let headerData = [];
    if (result?.homeItem && Object.keys(result?.homeItem)?.length > 0) {
      Object.keys(result?.homeItem)?.forEach((key) => {
        if (result?.homeItem?.[key]?.value) {
          headerData.push({
            key: keyValueOfHeader?.[key],
            value: result?.homeItem?.[key]?.value?.replace(/\s{2,}/g, ' ').trim(),
          });
        }
      });
    }
    return headerData;
  } catch (err) {
    console.error('err', err);
  }
};

const getOtherHeader = () => [
  {
    source: '/_next/:path*',
    headers: [
      {
        key: 'Access-Control-Allow-Origin',
        value: config.sitecoreApiHost.replace(/\/$/, ''),
      },
    ],
  },
  {
    source: '/api/:path*',
    headers: [
      {
        key: 'Access-Control-Allow-Origin',
        value: config.sitecoreApiHost.replace(/\/$/, ''),
      },
      {
        key: 'Access-Control-Allow-Methods',
        value: 'GET,HEAD,OPTIONS,POST',
      },
      {
        key: 'Access-Control-Allow-Headers',
        value: 'Content-Type',
      },
    ],
  },
];
/**
 * @param {import('next').NextConfig} nextConfig
 */
const corsHeaderPlugin = (nextConfig = {}) => {
  if (!config.sitecoreApiHost) {
    return Object.assign({}, nextConfig, {
      async headers() {
        const corsHeaders = await getCorsHeaderData();
        const headers =
          corsHeaders?.length > 0
            ? [
                {
                  source: '/(.*)',
                  headers: corsHeaders,
                },
              ]
            : [];
        return headers;
      },
    });
  }
  return Object.assign({}, nextConfig, {
    async headers() {
      const corsHeaders = await getCorsHeaderData();
      const headers =
        corsHeaders?.length > 0
          ? [
              {
                source: '/(.*)',
                headers: corsHeaders,
              },
              ...getOtherHeader(),
            ]
          : getOtherHeader();
      return headers;
    },
  });
};

module.exports = corsHeaderPlugin;
