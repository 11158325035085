// import { Dispatch, SetStateAction, createContext } from 'react';

export interface AutoshipComponentContextData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

// const AutoshipContext = createContext({});
interface AutoshipComponentContextProps {
  autoshipContextData: AutoshipComponentContextData;
  setAutoshipContextData: Dispatch<SetStateAction<AutoshipComponentContextData>>;
}
const AutoshipContext = createContext<AutoshipComponentContextProps>({
  autoshipContextData: {},
  setAutoshipContextData: (_state: AutoshipComponentContextData) => {
    _state;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AutoshipContextProvider = ({ children }: any) => {
  const [autoshipContextData, setAutoshipContextData] = useState<AutoshipComponentContextData>({});

  return (
    <AutoshipContext.Provider value={{ autoshipContextData, setAutoshipContextData }}>
      {children}
    </AutoshipContext.Provider>
  );
};

const useAutoshipContext = () => {
  return useContext(AutoshipContext);
};

export { AutoshipContextProvider, useAutoshipContext };
