const accountSettingGraphQl = `
  query AccountsSetting($path: String!, $language: String!) {
  item(path: $path, language: $language) {
    fields(ownFields: true) {
      name
      value
    }
  }
}
`;

export default accountSettingGraphQl;
