import { tv } from 'tailwind-variants';

export const SearchLandingPageBannerVariaints = tv(
  {
    slots: {
      base: ['SearchLandingPageBanner'],
      bannerBase: [''],
      persionalizedBase: [''],
      contentWrapper: ['flex-wrap'],
      title: [''],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:w-4',
        '[&>svg]:h-4',
        'group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base',
      ],
      linkWrapper: [
        'group',
        'hover:text-color-brand-tertiary-3-base',
        'text-color-text-brand-1',
        'gap-mob-space-between-micro-horizontal',
        'items-center',
      ],
      deskImg: [''],
      mobImg: [''],
    },
    compoundSlots: [
      {
        slots: ['title', 'linkWrapper'],
        class: ['text-body-large-bold', 'font-body-large-bold', 'leading-body-large-bold'],
      },
      {
        slots: ['contentWrapper', 'linkWrapper'],
        class: ['flex'],
      },
      {
        slots: ['deskImg', 'mobImg'],
        class: ['w-full'],
      },
    ],
    variants: {
      padding: {
        default: {},
        noPaddingTop: { base: ['!pt-0'] },
        normal: {},
      },
      device: {
        mobile: {
          base: ['py-mob-padding-micro-y'],
          contentWrapper: ['py-mob-padding-micro-y', 'gap-mob-margin-tight-bottom', 'items-start'],
          deskImg: ['hidden'],
        },
        desktop: {
          base: ['py-desk-padding-micro-y'],
          contentWrapper: ['py-desk-padding-micro-y', 'gap-desk-margin-tight-bottom'],
          deskImg: ['block'],
          mobImg: ['hidden'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
