import { useState, useEffect } from 'react';

export const useBreakpoints = () => {
  const [screenWidth, setScreenWidth] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize(); // Call initially
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = screenWidth !== null && screenWidth < 1024;
  const isDesktop = screenWidth !== null && screenWidth >= 1024;
  const deviceName = isMobile ? 'mobile' : 'desktop';

  return {
    screenWidth,
    isMobile,
    isDesktop,
    deviceName,
  };
};
