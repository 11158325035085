//global
import React, { useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
//helpers
import RichTextHelper from 'components/helpers/RichTextHelper';
import ImageHelper from 'components/helpers/ImageHelper';
import ButtonHelper from 'components/helpers/Button';
import IconHelper from 'components/helpers/IconHelper';
import TextHelper from 'components/helpers/TextHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
//type
export type PositionsGridProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.PositionsGrid.Fields.PositionsGrid;
//component variants
const positionGridAndGridItemVariants = tv(
  {
    slots: {
      base: ['positionsGrid', 'container', 'mx-auto'],
      contentWrapper: [''],
      contentGap: [''],
      title: [''],
      description: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      ctaWrapper: [
        'flex',
        'w-full',
        'justify-center',
        'items-center',
        '[&>a]:hover:text-color-brand-tertiary-3-base',
        '[&>span]:hover:text-color-brand-tertiary-3-base',
        '[&>span>svg>*]:hover:fill-color-brand-tertiary-3-base',
      ],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:h-4',
        '[&>svg]:w-4',
        'cursor-pointer',
        '[&>*>*]:fill-color-text-brand-1]',
        'ml-mob-space-between-micro-horizontal',
      ],
      cardImage: ['h-full', 'w-full', 'object-cover', 'absolute', 'top-0', 'left-0'],
      cardTitle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      learnMoreCTA: [
        '!text-heading-desk-large-bold',
        '!font-heading-desk-large-bold',
        '!leading-heading-desk-large-bold',
      ],
      cardWrapper: [],
      darkBg: [
        'transition-all',
        'after:duration-300',
        'after:hover:opacity-80',
        'after:absolute',
        'after:bg-black',
        'after:top-0',
        'after:left-0',
        'after:opacity-50',
        'after:w-full',
        'after:h-full',
      ],
      card: ['group', 'flex', 'flex-col', 'justify-center', 'items-center', 'relative'],
      cardContentWrapper: [
        'z-[1]',
        'text-white',
        'flex',
        'flex-col',
        'items-center',
        'gap-desk-global-spacing-spacing-2',
      ],
      closeIcon: [
        'iconWrapper [&>svg>*>*]:fill-color-text-brand-1 [&>*>*>*]:fill-color-text-brand-1 [&>svg]:w-8 [&>svg]:flex [&>svg]:h-8',
      ],
      cardButton: ['w-fit'],
      modalTitle: ['px-2', 'flex', 'justify-center'],
      modalWrapper: ['flex'],
      modalImg: ['w-full'],
      modalImgWrapper: ['w-full', 'bg-color-scale-6-mid'],
      modalContentWrapper: [''],
      modalTopContent: ['px-mob-padding-micro-x', 'bg-color-background-light'],
      modalBottomContent: ['px-mob-padding-micro-x'],
      topContent: ['gap-desk-padding-micro-bottom'],
      modalBtn: ['text-center'],
      colSpan: ['col-span-2'],
      rowSpan: [''],
    },
    compoundSlots: [
      {
        slots: ['modalBottomContent', 'modalContentWrapper', 'modalTopContent', 'topContent'],
        class: ['flex', 'flex-col'],
      },
      {
        slots: ['contentWrapper', 'contentGap'],
        class: ['flex', 'flex-col', 'text-center'],
      },
    ],
    variants: {
      variant: {
        threeInARow: { cardWrapper: ['flex flex-col lg:grid lg:grid-cols-3'] },
        sixGrid: { cardWrapper: ['flex flex-col lg:grid lg:grid-cols-6'] },
        twelveGrid: { cardWrapper: ['flex flex-col lg:grid lg:grid-cols-6'] },
      },
      size: {
        mobile: {
          base: [
            'pb-mob-padding-tight-bottom',
            'px-mob-padding-micro-x',
            'gap-mob-global-spacing-spacing-2',
          ],
          title: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          card: [
            'min-h-[200px]',
            'py-mob-padding-micro-y',
            'px-mob-padding-micro-x',
            'gap-mob-global-spacing-spacing-2',
          ],
          cardWrapper: ['gap-mob-global-spacing-spacing-3', 'mt-mob-global-spacing-spacing-4'],
          contentWrapper: [
            'gap-mob-margin-base-bottom',
            'py-mob-padding-tight-y',
            'px-mob-global-grid-margin',
          ],
          contentGap: ['gap-mob-global-spacing-spacing-2'],
          modalTitle: ['py-mob-padding-micro-y'],
          modalWrapper: ['flex-col'],
          modalContentWrapper: ['gap-mob-margin-base-bottom'],
          modalTopContent: ['gap-mob-margin-base-bottom', 'py-mob-padding-micro-y'],
          modalBottomContent: ['gap-mob-margin-base-bottom', 'py-mob-padding-micro-y'],
        },
        desktop: {
          base: [
            'pb-desk-padding-tight-bottom',
            'px-0',
            'gap-desk-global-spacing-spacing-4',
            'px-desk-padding-micro-x',
          ],
          title: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          card: [
            'min-h-[160px]',
            'py-desk-padding-micro-y',
            'px-desk-padding-micro-x',
            'gap-desk-global-spacing-spacing-2',
          ],
          cardWrapper: ['gap-mob-global-spacing-spacing-4', 'mt-desk-global-spacing-spacing-4'],
          contentWrapper: [
            'gap-desk-margin-base-bottom',
            'py-desk-padding-tight-y',
            'px-desk-global-grid-margin',
          ],
          contentGap: ['gap-desk-global-spacing-spacing-3'],
          cardButton: [
            'hidden',
            'opacity-0',
            'group-hover:block',
            'group-hover:opacity-100',
            'transition-all',
            'duraiton-700',
            'ease-in-out',
          ],
          modalTitle: ['py-desk-padding-micro-y'],
          modalWrapper: ['flex-row'],
          modalImg: ['h-full', 'object-cover'],
          modalImgWrapper: ['max-w-[348px]'],
          modalContentWrapper: ['gap-desk-margin-base-bottom'],
          modalBottomContent: ['gap-desk-margin-base-bottom', 'pt-desk-padding-micro-y'],
          modalTopContent: ['gap-desk-margin-base-bottom', 'pb-desk-padding-micro-y'],
          modalBtn: ['w-fit'],
          colSpan: ['max-w-[408px] max-h-[160px] '],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

// //custom modal
// const CustomModal= ()=>{

// }
//main component
const PositionsGrid: React.FC<PositionsGridProps> = ({ fields, params }) => {
  const {
    base,
    contentWrapper,
    title,
    description,
    icon,
    darkBg,
    cardWrapper,
    card,
    cardImage,
    cardTitle,
    learnMoreCTA,
    cardContentWrapper,
    cardButton,
    modalTitle,
    modalBottomContent,
    modalContentWrapper,
    modalImgWrapper,
    modalImg,
    modalBtn,
    closeIcon,
    modalTopContent,
    modalWrapper,
    topContent,
    colSpan,
    contentGap,
    ctaWrapper,
  } = positionGridAndGridItemVariants({
    size: { initial: 'mobile', lg: 'desktop' },
    variant:
      params?.FieldNames === 'ThreeInARow'
        ? 'threeInARow'
        : params?.FieldNames === 'SixGrid'
        ? 'sixGrid'
        : 'twelveGrid',
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<number>(-1);
  const isEE = useExperienceEditor();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // const ref = document?.getElementById('positionModal');
      const clickedOutside = ref?.current?.contains(event.target as Node);

      if (!clickedOutside) {
        setShowModal(false);
        document.body.classList.remove('overflow-hidden');
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);
  const handleModalClose = () => {
    setShowModal(false);
    document.body.classList.remove('overflow-hidden');
  };
  const handleModalOpen = () => {
    setShowModal(true);
    document.body.classList.add('overflow-hidden');
  };
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Style ?? '' })}>
      <div className={contentWrapper()}>
        <div className={contentGap()}>
          <RichTextHelper tag="p" field={fields?.title} className={title()} />
          <RichTextHelper
            tag="h3"
            field={fields?.subheading}
            className={cardTitle({ className: 'text-color-scale-7-mid-dark' })}
          />
          <RichTextHelper field={fields?.description} className={description()} />
        </div>
        <div className={ctaWrapper()}>
          <ButtonHelper
            className="!p-0"
            variant="link"
            field={fields?.cta}
            isLinkfield={true}
            size="small"
          />
          {fields?.cta?.value?.href && fields?.cta?.value?.href?.length > 0 && (
            <IconHelper icon="chevron-right" className={icon()} />
          )}
        </div>
      </div>

      {/**
       * Custom Modal popup
       */}
      {fields?.items && (
        <div
          className={`${
            showModal
              ? 'block fixed flex h-screen w-screen overflow-y-scroll justify-center bg-[rgba(0,0,0,0.8)] top-0 left-0 bottom-0 right-0  z-40'
              : 'hidden'
          } `}
        >
          <div
            id="positionModal"
            className="z-80 h-fit w-fit max-w-[915px] bg-white translate-y-0 animate-[topAnimation_0.3s_ease-in-out] rounded-[12px] relative"
            ref={ref}
          >
            {/* <div className="flex [&>div]:w-full z-50  h-fit w-fit bg-white"> */}
            <a
              aria-label="modal close"
              className="absolute z-[1] right-2 top-2 lg:right-2 lg:top-2 cursor-pointer w-8 h-8"
              onClick={handleModalClose}
            >
              {<IconHelper className={closeIcon()} icon={'close'} /> ?? 'X'}
            </a>
            <div
              className={modalTitle({
                className: cardTitle(),
              })}
            >
              <RichTextHelper
                field={fields?.items[modalData]?.fields?.Position?.fields?.ModalTitle}
              />
            </div>
            <div className={modalWrapper()}>
              <div className={modalImgWrapper()}>
                <ImageHelper
                  field={fields?.items[modalData]?.fields?.Position?.fields?.Image}
                  className={modalImg()}
                />
              </div>
              <div className={modalContentWrapper()}>
                <div className={modalTopContent()}>
                  <div className={topContent()}>
                    <TextHelper
                      tag="p"
                      field={fields?.items[modalData]?.fields?.Position?.fields?.Name}
                      className={cardTitle({ className: 'hidden lg:block' })}
                    />
                    <RichTextHelper
                      field={fields?.items[modalData]?.fields?.Position?.fields?.Title}
                      className={title({ className: 'text-color-brand-primary-1-base' })}
                    />
                  </div>
                  <ButtonHelper
                    isLinkfield={true}
                    size="default"
                    variant="primary"
                    className={modalBtn()}
                    field={fields?.items[modalData]?.fields?.Position?.fields?.ApplyNowLink}
                  />
                </div>
                <div className={modalBottomContent()}>
                  <RichTextHelper
                    field={fields?.items[modalData]?.fields?.Position?.fields?.Responsibilities}
                  />
                  <RichTextHelper
                    field={fields?.items[modalData]?.fields?.Position?.fields?.Expectations}
                  />
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
      {/**------------------------- */}
      {params?.FieldNames === 'ThreeInARow' ? (
        <div className={cardWrapper()}>
          {fields?.items?.slice(0, 3)?.map((item, index) => {
            const isImage =
              item?.fields?.BackgroundImage?.value && item?.fields?.BackgroundImage?.value?.src;
            return (
              <div
                key={index}
                className={card({
                  className: `
                  ${
                    !isImage && index === 0
                      ? 'h-fit !min-h-[0px] bg-white text-color-brand-primary-1-base lg:h-full lg:bg-positionGridPrimary'
                      : ''
                  }
                  ${
                    index === 0
                      ? 'h-[200px] lg:max-w-[408px]'
                      : `h-[200px] w-full lg:max-w-[408px] ${darkBg()}`
                  } `,
                })}
              >
                <ImageHelper
                  field={item?.fields?.BackgroundImage}
                  className={cardImage({ className: isEE ? 'z-[1]' : '' })}
                />
                <div className={cardContentWrapper()}>
                  <RichTextHelper
                    field={item?.fields?.Title}
                    className={cardTitle({
                      className: `text-center break-word ${
                        !isImage && index === 0
                          ? 'text-color-brand-primary-1-base lg:text-white '
                          : ''
                      } `,
                    })}
                  />
                  {/* {!isImage && index !== 0 ? ( */}
                  <div
                    className={cardButton()}
                    onClick={() => {
                      handleModalOpen(), setModalData(index);
                    }}
                  >
                    {item?.fields?.LearnMoreCTAText &&
                      item?.fields?.LearnMoreCTAText?.value?.length > 0 && (
                        <ButtonHelper
                          field={item?.fields?.LearnMoreCTAText}
                          isLinkfield={false}
                          size="default"
                          variant="secondary"
                          className={learnMoreCTA()}
                        />
                      )}
                  </div>
                  {/* ) : (
                    ''
                  )} */}
                </div>
              </div>
            );
          })}
        </div>
      ) : params?.FieldNames === 'SixGrid' || params?.FieldNames === 'TwelveGrid' ? (
        <>
          <div className={cardWrapper()}>
            {fields?.items?.slice(0, 6)?.map((item, index) => {
              const isImage =
                item?.fields?.BackgroundImage?.value && item?.fields?.BackgroundImage?.value?.src;
              return (
                <div
                  key={index}
                  className={card({
                    className: `
                    ${
                      !isImage && index === 0
                        ? 'h-fit !min-h-[0px] bg-white text-color-brand-primary-1-base lg:h-full lg:bg-positionGridPrimary'
                        : ''
                    }
                      ${
                        index === 0
                          ? `w-full lg:max-w-[408px] row-span-2`
                          : (index + 1) % 2 == 0 || index === 4
                          ? `${colSpan()} ${isImage ? ` ${darkBg()}` : ''}`
                          : `lg:max-w-[408px] row-span-2  ${isImage ? darkBg() : ''}`
                      } `,
                  })}
                >
                  <div className="flex">
                    <ImageHelper
                      field={item?.fields?.BackgroundImage}
                      className={cardImage({ className: isEE ? 'z-[1]' : '' })}
                    />
                  </div>
                  <div className={cardContentWrapper({ className: 'group' })}>
                    <RichTextHelper
                      field={item?.fields?.Title}
                      className={cardTitle({
                        className: `text-center break-word ${
                          !isImage && index === 0
                            ? 'text-color-brand-primary-1-base lg:text-white '
                            : ''
                        } `,
                      })}
                    />
                    {/* {!isImage && index !== 0 ? ( */}
                    <div
                      className={cardButton()}
                      onClick={() => {
                        handleModalOpen(), setModalData(index);
                      }}
                    >
                      {item?.fields?.LearnMoreCTAText &&
                        item?.fields?.LearnMoreCTAText?.value?.length > 0 && (
                          <ButtonHelper
                            field={item?.fields?.LearnMoreCTAText}
                            isLinkfield={false}
                            size="default"
                            variant="secondary"
                            className={learnMoreCTA()}
                          />
                        )}
                    </div>
                    {/* ) : (
                      ''
                    )} */}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={cardWrapper()}>
            {fields?.items?.slice(6, 12)?.map((item, index) => {
              const isImage =
                item?.fields?.BackgroundImage?.value && item?.fields?.BackgroundImage?.value?.src;
              return (
                <div
                  key={index}
                  className={card({
                    className: `
                     ${
                       !isImage && index === 0
                         ? 'h-fit !min-h-[0px] bg-white text-color-brand-primary-1-base lg:h-full lg:bg-positionGridSecondary'
                         : ''
                     }
                      ${
                        index === 0
                          ? `w-full lg:max-w-[408px] row-span-2`
                          : (index + 1) % 2 == 0 || index === 4
                          ? `${colSpan()} ${isImage ? ` ${darkBg()}` : ''}`
                          : `row-span-2 ${isImage ? darkBg() : ''}`
                      }`,
                  })}
                >
                  <ImageHelper
                    field={item?.fields?.BackgroundImage}
                    className={cardImage({ className: isEE ? 'z-[1]' : '' })}
                  />
                  <div className={cardContentWrapper()}>
                    <RichTextHelper
                      field={item?.fields?.Title}
                      className={cardTitle({
                        className: `text-center break-word ${
                          !isImage && index === 0
                            ? 'text-color-brand-primary-1-base lg:text-white '
                            : ''
                        } `,
                      })}
                    />
                    {/* {!isImage && index !== 0 ? ( */}
                    <div
                      className={cardButton()}
                      onClick={() => {
                        handleModalOpen(), setModalData(index + 6);
                      }}
                    >
                      {item?.fields?.LearnMoreCTAText &&
                        item?.fields?.LearnMoreCTAText?.value?.length > 0 && (
                          <ButtonHelper
                            field={item?.fields?.LearnMoreCTAText}
                            isLinkfield={false}
                            size="default"
                            variant="secondary"
                            className={learnMoreCTA()}
                          />
                        )}
                    </div>
                    {/* ) : (
                      ''
                    )} */}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export const Default = (props: PositionsGridProps): JSX.Element => {
  return <PositionsGrid {...props} />;
};
export const ThreeInARow = (props: PositionsGridProps): JSX.Element => {
  return <PositionsGrid {...props} />;
};
export const SixGrid = (props: PositionsGridProps): JSX.Element => {
  return <PositionsGrid {...props} />;
};
export const TwelveGrid = (props: PositionsGridProps): JSX.Element => {
  return <PositionsGrid {...props} />;
};
