import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

/**
 * A wrapper around useState returns different states for rendering vs animating open/closed state.
 *
 * e.g. We have a menu that we only want to render when it is open (to reduce DOM size and improve performance).
 * To do this, we conditionally render the element with the `renderState`.  We also have a conditional CSS class applied
 * via the `cssState`.  The slight delay between the two allows for the menu to first render as closed, then
 * change the CSS class to open, thus triggering the animation.  The reverse is true when closing.
 * Without the delay the animation will not occur.
 *
 * If not animating, no need to use this.
 * @example
 * const [isOpenRender, isOpenCss, setIsOpen, isOpenActual] = useStateAnimate(false);
 *
 * // This is basic example, should use tailwindVariant or other ways of controlling classes
 * return (<div>
 *  <button onClick={() => setIsOpen(!isOpenActual)}>Toggle Open</button>
 *  {isOpenRender && <ul className={'menu ' + isOpenCss ? 'menu-open' : ''}><li>item1</li></ul>}
 * </div>)
 *
 * @param initialState The Initial State
 * @param animationDuration The animation duration, used for when closing (setting state to false) to allow closing
 * @returns `[renderState, cssState, setState, realState]`
 *    * `renderState` should be used for determining whether to render the elements
 *    * `cssState` should be used to determine CSS classes that affect animations
 *    * `setState` Sets the various states with the appropriate delays
 *    * `realState` use if we want to know the real state regardless of animations
 */
export function useStateAnimate(
  initialState: boolean | (() => boolean),
  animationDuration = 500
): [boolean, boolean, Dispatch<SetStateAction<boolean>>, boolean] {
  const [realState, setRealState] = useState(initialState);
  const [cssState, setCssState] = useState(initialState);
  const [renderState, setRenderState] = useState(initialState);

  // Store result of setTimeout so we can clear it when the state changes
  // This is used in cases where user quickly clicks between open and closed to ensure the animation remains smooth
  // useRef to ensure that we referencing the same instance
  const currentTimeout = useRef<NodeJS.Timeout>();

  // useCallback so we use the same function
  const setState = useCallback(
    (newState: boolean) => {
      // The real state will always be the real state
      setRealState(newState);

      // Clear out previous timeout so we don't get competing animations
      if (currentTimeout.current) {
        clearTimeout(currentTimeout.current);
      }

      if (newState) {
        // If we're opening, set the render state right away
        setRenderState(true);
        // But delay the css state so we can render the closed class before the open one
        currentTimeout.current = setTimeout(() => setCssState(true), 0);
      } else {
        // If we're closing, update the css state first so we start the closing animation
        setCssState(false);
        // The wait for the animation to finish before we change the render state
        currentTimeout.current = setTimeout(() => setRenderState(false), animationDuration);
      }
    },
    [animationDuration]
  );

  return [renderState, cssState, setState, realState];
}
