const withBundleAnalyzer = require('@next/bundle-analyzer')({
  enabled: process.env.ANALYZE === 'true',
});

/**
 * @param {import('next').NextConfig} nextConfig
 */
const nextjsBundleAnalyzerPlugin = (nextConfig = {}) => {
  const newConfig = Object.assign({}, nextConfig, {
    webpack: (config, options) => {
      // This is needed to prevent import errors when running app.
      // These errors occur simply by requiring the bundle analyzer, even if it isn't used.
      // https://trungvose.com/experience/next-bundle-analyzer-cant-resolve-child-process/
      if (!options.isServer) {
        config.resolve.fallback.fs = false;
        config.resolve.fallback.child_process = false;
      }

      // Overload the Webpack config if it was already overloaded
      if (typeof nextConfig.webpack === 'function') {
        return nextConfig.webpack(config, options);
      }

      return config;
    },
  });
  return withBundleAnalyzer(newConfig);
};

module.exports = nextjsBundleAnalyzerPlugin;
