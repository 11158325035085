//global
import React, { useEffect, useState } from 'react';
import { Field, ImageField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import RichTextHelper from 'components/helpers/RichTextHelper';
import SplideSlider from 'components/helpers/SplideSlider';
import ImageHelper from 'components/helpers/ImageHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
//type
export type CarouselThreeColumnProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.ThreeSlidesCarousel.Fields.ThreeSlidesCarousel;

export type CardList = {
  userCard: Card;
  index: number;
  cardLength: number;
};
export type Card = {
  fields: {
    userName: Field<string>;
    userImage: ImageField;
    userDetails: Field<string>;
    description: Field<string>;
    borderColor: BorderColor;
    quoteImage: ImageField;
  };
};
export type BorderColor = {
  fields: {
    hexColor: Field<string>;
    quoteImage: ImageField;
    Icon: ImageField;
  };
};
//component variants
const carouselThreeColumnVariaints = tv(
  {
    slots: {
      base: ['carouselThreeColumn', 'font-primary', 'relative'],
      innerDiv: ['container', 'mx-auto'],
      backgroundImageWrapper: [
        'absolute',
        'top-0',
        'left-0',
        'z-[1]',
        'h-full',
        'w-full',
        'bg-cover',
      ],
      backgroundImage: ['h-full', 'w-full', 'object-cover'],
      heading: ['text-center', 'z-[1]'],
      cardWrapper: [
        'z-[1]',
        '[&_.splide_ul_li]:h-full',
        '[&_.splide]:w-[90%]',
        '[&_.splide]:flex',
        '[&_.splide]:justify-center',
        '[&_.splide_ul_li]:h-auto',
        '[&_.splide_.arrow-prev]:-left-[80px]',
        '[&_.splide_.arrow-next]:-right-[70px]',
        '[&_.splide_.splide-button]:bg-white',
        '[&_.splide_.splide-button]:h-[40px]',
        '[&_.splide_.splide-button]:w-[40px]',
        '[&_.splide_.splide-button>svg]:fill-color-brand-primary-1-base',
        '[&_.splide_.splide-button>svg>path]:!drop-shadow-arrows',
        '[&_*_*.splide-button]:border-color-brand-primary-1-base',
        '[&_*_*.splide-button]:border-[2px]',
        '[&_*_*.splide-button]:border-solid',
      ],
      card: [
        'border',
        'border-[5px]',
        'rounded-[24px]',
        'max-w-[358px]',
        'w-full',
        'relative',
        'bg-color-background-white',
      ],
      description: [],
      designation: [''],
      userName: [
        'w-full',
        'break-all',
        'text-heading-desk-small-bold',
        'font-heading-desk-small-bold',
        'leading-heading-desk-small-bold',
      ],
      userWrapper: ['justify-center', 'items-center', 'mt-auto'],
      userDetail: ['gap-desk-margin-micro-bottom'],
      quoteIcon: [
        'absolute',
        'top-[82px]',
        'right-[47px]',
        'h-full',
        'w-full',
        'max-w-[80px]',
        'max-h-[130px]',
      ],
      imageWrapper: [''],
      img: ['h-[140px]', 'w-full', 'max-w-[171px]', 'object-cover', 'self-end', 'z-[1]'],
    },
    compoundSlots: [
      {
        slots: ['description', 'designation'],
        class: ['text-body-large-regular', 'font-body-large-regular', 'leading-body-large-regular'],
      },
      {
        slots: ['cardWrapper', 'userWrapper', 'innerDiv', 'card', 'userDetail'],
        class: ['flex'],
      },
      {
        slots: ['innerDiv', 'card', 'userDetail'],
        class: ['flex-col'],
      },
    ],
    variants: {
      device: {
        mobile: {
          innerDiv: ['py-mob-padding-micro-y', 'pr-0'],
          heading: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
            'pr-mob-global-grid-margin',
          ],
          card: [
            'h-auto',
            'px-mob-padding-micro-x',
            'pt-mob-padding-micro-top',
            'rounded-global-radius-xLarge',
            'gap-mob-global-spacing-spacing-10',
            'w-full',
            'min-w-[320px]',
          ],
          cardWrapper: [
            // '[&_:last-child]:mr-[15px]',
            'overflow-x-scroll',
            'items-stretch',
            'pl-2',
            'no-scrollbar',
            'py-desk-padding-tight-y',
            'gap-mob-space-between-base-horizontal',
          ],
          cardDetails: ['gap-mob-space-between-tight-vertical'],
          userWrapper: ['gap-mob-space-between-tight-vertical'],
        },
        desktop: {
          innerDiv: ['py-desk-padding-tight-y'],
          heading: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
            'pr-0',
            'pb-[40px]',
          ],
          card: [
            'h-full',
            'px-desk-padding-micro-x',
            'pt-desk-padding-micro-top',
            'gap-desk-global-spacing-spacing-10',
            'min-w-[0px]',
          ],
          cardWrapper: [
            'items-stretch',
            'py-desk-padding-tight-y',
            'gap-desk-space-between-base-horizontal',
            'px-desk-padding-micro-x',
            'justify-center',
            'overflow-visible',
          ],
          cardDetails: ['gap-desk-space-between-tight-vertical'],
          userWrapper: ['gap-desk-space-between-tight-vertical'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//card component
const Card: React.FC<CardList> = ({ userCard, index, cardLength }) => {
  //component variant
  const { card, description, userWrapper, userDetail, img, quoteIcon, userName, designation } =
    carouselThreeColumnVariaints({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  const isEE = useExperienceEditor();
  const border = userCard?.fields?.borderColor?.fields?.hexColor?.value;
  const icon = userCard?.fields?.borderColor?.fields?.quoteImage;
  return (
    <div className={`${index === cardLength - 1 ? 'pr-2 lg:pr-0' : ''}`}>
      <div
        className={card({ className: `${index === cardLength - 1 ? 'mr-2 lg:mr-0' : 'mr-0'}` })}
        style={{ borderColor: border }}
      >
        {userCard?.fields?.description && userCard?.fields?.description?.value?.length > 0 && (
          <RichTextHelper field={userCard?.fields?.description} className={description()} />
        )}
        {!isEE ? (
          icon && <ImageHelper field={icon} className={quoteIcon()} editable={false} />
        ) : (
          <ImageHelper field={icon} className={quoteIcon()} />
        )}
        <div className={userWrapper()}>
          {userCard?.fields?.userImage && (
            <ImageHelper field={userCard?.fields?.userImage} className={img()} />
          )}
          <div className={userDetail()}>
            {userCard?.fields?.userName && (
              <RichTextHelper field={userCard?.fields?.userName} className={userName()} />
            )}
            {userCard?.fields?.userDetails?.value && (
              <RichTextHelper field={userCard?.fields?.userDetails} className={designation()} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
//main component
const CarouselThreeColumn: React.FC<CarouselThreeColumnProps> = ({ fields, params }) => {
  //state
  const [screen, setScreen] = useState<number>();
  const cardLength = (fields?.cardList && fields?.cardList?.length) ?? 0;
  const isLoop = cardLength > 3 ? 'loop' : '';
  const isArrow = cardLength > 3 ? true : false;
  const isAutoPlay = fields?.autoPlay?.value ?? false;
  const duration =
    fields?.duration?.value === undefined || fields?.duration?.value?.length <= 0
      ? 3000
      : Number(fields?.duration?.value);
  //component variant
  const { base, innerDiv, backgroundImageWrapper, backgroundImage, heading, cardWrapper } =
    carouselThreeColumnVariaints({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  //handle Screen
  useEffect(() => {
    setScreen(window?.innerWidth);
    const handleResize = () => {
      setScreen(window?.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screen]);
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <div className={innerDiv()}>
        <div className={backgroundImageWrapper()}>
          <ImageHelper field={fields?.backgroundImage} className={backgroundImage()} />
        </div>
        {fields?.heading && (
          <RichTextHelper tag="h1" field={fields?.heading} className={heading()} />
        )}
        {fields?.cardList?.length && (
          <div id="slides" className={cardWrapper()}>
            {screen && screen >= 1024 ? (
              <SplideSlider
                isAutoPlay={isAutoPlay}
                options={{
                  perPage: fields?.cardList?.length > 2 ? 3 : fields?.cardList?.length,
                  type: isLoop,
                  pagination: false,
                  perMove: 1,
                  interval: duration,
                  autoplay: isAutoPlay,
                  arrows: isArrow,
                  classes: {
                    prev: 'arrow-prev splide-button splide__arrow--prev your-class-prev',
                    next: 'arrow-next splide-button splide__arrow--next your-class-next',
                  },
                  rewind: true,
                  gap: cardLength > 3 ? '24px' : '10px',
                  rewindSpeed: 3000,
                }}
              >
                {fields?.cardList?.map((userCard, index) => (
                  <Card userCard={userCard} key={index} index={index} cardLength={cardLength} />
                ))}
              </SplideSlider>
            ) : (
              fields?.cardList?.map((userCard, index) => (
                <Card userCard={userCard} key={index} index={index} cardLength={cardLength} />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<CarouselThreeColumnProps>(CarouselThreeColumn);
