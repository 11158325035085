import { useContext, useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import SplideSlider from 'components/helpers/SplideSlider';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import LinkHelper from 'components/helpers/LinkHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { ComponentProps } from 'lib/component-props';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import ImageHelper from 'components/helpers/ImageHelper';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import { GTM_EVENT } from 'components/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
import {
  formatDateForGTM,
  Items,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { sendGTMData } from 'components/MultiColumnItem/MultiColumnItem';
export type MultiColumnCarouselProps = ComponentUidProps &
  ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.MultiColumnCarousel.Fields.MultiColumnCarousel;

export type CardProps = {
  cardProps: {
    id: string;
    name: string;
    url: string;
    displayName: string;
    fields: CardFieldProps;
  };
};
type CardFieldProps = {
  componentName: Field<string>;
  cTAButtonCopy: Field<string>;
  promotionId: Field<string>;
  promotionName: Field<string>;
  promotionCopy: Field<string>;
  creativeSlotNumber: Field<string>;
  promotionDateFROM: Field<string>;
  promotionDateTO: Field<string>;
  description: Field<string>;
  promotionURL: LinkField;
  image: ImageField;
  link: LinkField;
  title: Field<string>;
};
type ComponentUidProps = {
  uid: string;
  params: {
    FieldNames: string;
    DynamicPlaceholderId: string;
  };
};
const carouselMultiColumnCarouselVariants = tv(
  {
    slots: {
      base: [
        'carouselMultiColumnCarousel',
        'font-primary',
        'relative',
        '[&_.splide_.arrow-prev]:-left-mob-global-spacing-spacing-10',
        '[&_.splide_.arrow-next]:-right-mob-global-spacing-spacing-10',
        '[&_.splide_.splide-button]:bg-color-background-light',
        '[&_.splide_.splide-button]:h-[40px]',
        '[&_.splide_.splide-button]:w-[40px]',
        '[&_.splide_.splide-button>svg]:text-body-xSmall-regular',
        '[&_.splide_.splide-button>svg]:fill-color-brand-primary-1-base',
        '[&_.splide_.splide-button>svg>path]:!drop-shadow-arrows',
        '[&_*_*.splide-button]:border-color-["#777777"]',
        '[&_*_*.splide-button]:border-[2px]',
        '[&_*_*.splide-button]:border-solid',
      ],
      innerDiv: ['container', 'mx-auto'],
      wrapper: [''],
      row: ['py-desk-component-button-full-padding-y'],
      heading: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'mt-desk-margin-base-top',
        'mb-desk-margin-tight-bottom',
      ],
      card: ['w-full', 'px-desk-padding-micro-x'],
      cardWrapper: ['flex', 'flex-col', 'items-center', 'gap-desk-margin-tight-bottom'],
      cardImageWrapper: ['max-w-[200px]'],
      cardImage: [''],
      content: ['flex', 'flex-col', 'w-full', 'items-center', 'gap-desk-margin-tight-bottom'],
      // text: ['w-full'],
      title: [
        'w-full',
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'text-center',
      ],
      description: [
        'mb-desk-margin-micro-bottom',
        'w-full',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'text-center',
      ],
      cta: [
        'text-color-text-brand-1',
        'text-heading-mob-small-bold',
        'font-heading-mob-small-bold',
        'leading-heading-mob-small-bold',
      ],
    },
    variants: {
      site: {
        psp: {},
        wnw: { heading: ['font-secondary'] },
      },
      device: {
        mobile: {
          innerDiv: ['px-0 pl-4'],
        },
        desktop: {
          wrapper: ['px-desk-component-button-full-padding-y'],
          row: ['mx-desk-global-spacing-spacing-8'],
          // card: ['px-desk-padding-micro-x'],
          cardImage: ['max-w-desk-component-image-content-circle-width'],
          heading: ['-ml-16', '-mr-16'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendCarouselGTMData = (data: any, deviceName: string, storeId: string, event: string) => {
  const gtmPromotion: Items[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?.map((d: any) => {
    gtmPromotion.push({
      promotion_id: notAvailableIfNullOrEmpty(d?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(d?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(d?.fields?.componentName?.value),
      creative_slot: Number(d?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(d?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${d?.fields?.promotionDateFROM?.value} - ${d?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(d?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(d?.fields?.promotionURL?.value?.href),
    });
  });

  gtmPromotion && trackObjectForPromotion(event, storeId, gtmPromotion);
};
const Card: React.FC<CardProps> = ({ cardProps }) => {
  const { card, cardWrapper, cardImageWrapper, cardImage, content, description, title, cta } =
    carouselMultiColumnCarouselVariants();
  const isEE = useExperienceEditor();
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  return (
    <div
      className={card()}
      data-creative-id={cardProps?.fields?.componentName?.value}
      data-promotion-cta={cardProps?.fields?.cTAButtonCopy?.value}
      data-promo-id={cardProps?.fields?.promotionId?.value}
      data-promotion-name={cardProps?.fields?.promotionName?.value}
      data-promotion-copy={cardProps?.fields?.promotionCopy?.value}
      dta-promotion-url={cardProps?.fields?.promotionURL?.value?.href}
      onClick={() => sendGTMData(cardProps, deviceName, myStoreId, GTM_EVENT?.selectPromotion)}
    >
      <div className={cardWrapper()}>
        {cardProps?.fields?.image?.value && !isEE ? (
          <LinkHelper field={cardProps?.fields?.link}>
            <div className={cardImageWrapper()}>
              <ImageHelper field={cardProps?.fields?.image} className={cardImage()} />
            </div>
          </LinkHelper>
        ) : (
          <div className={cardImageWrapper()}>
            {cardProps?.fields?.image && (
              <ImageHelper field={cardProps?.fields?.image} className={cardImage()} />
            )}
          </div>
        )}
        <div className={content()}>
          {cardProps?.fields?.title && (
            <RichTextHelper field={cardProps?.fields?.title} className={title()} />
          )}
          {/* <div className={text()}> */}
          {cardProps?.fields?.description && (
            <RichTextHelper
              tag="p"
              field={cardProps?.fields?.description}
              className={description()}
            />
          )}
          {/* </div> */}
          {cardProps?.fields?.link && (
            <LinkHelper field={cardProps?.fields?.link} className={cta()} />
          )}
        </div>
      </div>
    </div>
  );
};
const MultiColumnCarousel = ({ uid, params, fields }: MultiColumnCarouselProps) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const splideSliderRef = useRef(null);
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const variant = params?.FieldNames;
  const enableLoop = fields?.enableLoop?.value ?? false;
  const itemsCount = fields?.carouselItems?.length ?? 0;
  const isThreeColumnVariant = variant === 'ThreeColumn';
  const isFourColumnVariant = variant === 'FourColumn';
  let showArrows = false;
  if (isDesktop) {
    if ((isFourColumnVariant && itemsCount > 4) || (isThreeColumnVariant && itemsCount > 3)) {
      showArrows = true;
    } else if (
      enableLoop &&
      ((isFourColumnVariant && itemsCount === 4) || (isThreeColumnVariant && itemsCount === 3))
    ) {
      showArrows = true;
    }
  }
  const showPerPage = isDesktop ? (variant === 'ThreeColumn' ? 3 : 4) : 1;
  const loopType = enableLoop ? 'loop' : '';
  // const loopDuration = fields?.loopDuration?.value ? Number(fields.loopDuration?.value) : 3000;
  const fixedWidth = !isDesktop ? '262px' : undefined;
  const { base, heading, innerDiv, wrapper, row } = carouselMultiColumnCarouselVariants({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  useEffect(() => {
    // Add this instance's data to the context
    setcomponentContextData((prevData) => ({
      ...prevData,
      multiColumnCarousel: [
        ...(prevData.multiColumnCarousel || []),
        { uid, items: fields && fields.carouselItems },
      ],
    }));

    // Cleanup function to remove this instance when unmounting
    return () => {
      setcomponentContextData((prevData) => ({
        ...prevData,
        multiColumnCarousel: prevData.multiColumnCarousel.filter(
          (item: MultiColumnCarouselProps) => item.uid !== uid
        ),
      }));
    };
  }, []);

  useEffect(() => {
    const multiColumnInstances = componentContextData.multiColumnCarousel || [];

    // Check if this is the last rendered instance
    if (splideSliderRef.current && multiColumnInstances.length > 1) {
      const allInstances = document.querySelectorAll('.carouselMultiColumnCarousel');
      if (splideSliderRef.current === allInstances[allInstances.length - 1]) {
        // This is the last instance, combine data here
        const combinedData = multiColumnInstances.flatMap(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (instance: any) => instance.items && instance.items
        );
        combinedData &&
          myStoreId &&
          sendCarouselGTMData(combinedData, deviceName, myStoreId, GTM_EVENT?.viewPromotion);
      }
    }
  }, [componentContextData.multiColumnCarousel, myStoreId]);

  if (!fields) {
    return null;
  }
  return (
    <div ref={splideSliderRef} className={base({ className: params?.Styles ?? '' })}>
      <div className={innerDiv()}>
        <div className={wrapper()}>
          <div className={row()}>
            {fields?.link?.value && fields?.link?.value ? (
              <LinkHelper field={fields?.link}>
                <RichTextHelper field={fields?.heading} className={heading()} />
              </LinkHelper>
            ) : (
              fields?.heading && <RichTextHelper field={fields?.heading} className={heading()} />
            )}
            <div className="py-desk-padding-tight-y">
              <SplideSlider
                options={{
                  perPage: showPerPage,
                  pagination: false,
                  perMove: 1,
                  type: loopType,
                  arrows: showArrows,
                  classes: {
                    prev: 'arrow-prev splide-button splide__arrow--prev',
                    next: 'arrow-next splide-button splide__arrow--next',
                  },
                  gap: '24px',
                  // rewindSpeed: loopDuration,
                  fixedWidth: fixedWidth,
                }}
              >
                {fields?.carouselItems?.length &&
                  fields.carouselItems.map((itemCard, index) => (
                    <Card cardProps={itemCard} key={`${uid}-${index}`} />
                  ))}
              </SplideSlider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const Default = (props: MultiColumnCarouselProps): JSX.Element => {
  return <MultiColumnCarousel {...props} />;
};
export const ThreeColumn = (props: MultiColumnCarouselProps): JSX.Element => {
  return <MultiColumnCarousel {...props} />;
};
export const FourColumn = (props: MultiColumnCarouselProps): JSX.Element => {
  return <MultiColumnCarousel {...props} />;
};
export default MultiColumnCarousel;
