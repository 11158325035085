import { useOcSelector } from 'src/redux/ocStore';
import { ProductSearchResultModel, ProductSearchResultModelWithVariants } from './types';
import { useEffect, useState } from 'react';
import { Me } from 'ordercloud-javascript-sdk';

import groupBy from 'lodash/groupBy';
import { BuyerProductWithXp } from 'src/redux/xp';

export function useProductVariants(products: ProductSearchResultModel[]) {
  const store = useOcSelector((x) => x.storeReducer.selectedStore);

  // We'll start with just casting the objects, and we'll populate the variants after
  const initialState = products.map((x) => x as ProductSearchResultModelWithVariants);

  const [productsWithVariants, setProductsWithVariants] = useState(() => initialState);

  // Pipe separate to indicate "OR" filter
  const parentIds = products.map((x) => x.parentid).join('|');

  useEffect(() => {
    // Wait until after we get results
    if (!parentIds.length || !store.storeId) {
      return;
    }
    Me.ListProducts<BuyerProductWithXp>({
      filters: { ParentID: parentIds, 'xp.WE': false },
      sellerID: store.storeId,
    }).then((siblingProducts) => {
      const grouped = groupBy(siblingProducts.Items, (x) => x.ParentID);

      // Add the variants to the product
      const updated = products.map((x) => {
        const product = x as ProductSearchResultModelWithVariants;
        product.variants = grouped[product.parentid ?? ''] ?? [];
        return product;
      });

      setProductsWithVariants(updated);
      return grouped;
    });
  }, [parentIds, products, store.storeId]);

  return productsWithVariants;
}
