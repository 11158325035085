import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';

type ProgramOffersAndRewardsTabsContainerProps = {
  rendering: ComponentRendering & {
    params: ComponentParams;
  };
  params: ComponentParams;
};

const ProgramOffersAndRewardsTabsContainer = (props: ProgramOffersAndRewardsTabsContainerProps) => {
  const phKey = `loyalty-program-tabs-container-${props.params?.DynamicPlaceholderId}`;
  return (
    <>
      <div>
        <Placeholder name={phKey} rendering={props.rendering} />
      </div>
    </>
  );
};

export default ProgramOffersAndRewardsTabsContainer;
