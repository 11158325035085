import { ReactNode } from 'react';
import { useContentBlock } from '@sitecore-search/react';

export type JsonDataProps = {
  [key: string]: {
    value: string | boolean | number;
  };
};

interface ContentDataWrapperProps {
  children: (widgetData: JsonDataProps) => ReactNode;
}

const ContentDataWrapper = ({ children }: ContentDataWrapperProps) => {
  const {
    queryResult: { isLoading, ...otherData },
  } = useContentBlock();

  const widgetData = !isLoading && otherData?.data?.appearance?.variables;

  return <>{!isLoading && widgetData ? children(widgetData) : null}</>;
};

export default ContentDataWrapper;
