import { tv } from 'tailwind-variants';

const orderSummary = tv(
  {
    slots: {
      base: [
        'w-full',
        'flex',
        'flex-col',
        'bg-red',
        'rounded-radius-card',
        'bg-color-background-white',
        'gap-[24px]',
      ],
      headline: [],
      block: ['relative'],
      couponWrapper: [],
      title: [
        'text-color-text-black',
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      titleMb: [],
      coupon: ['border', 'bg-color-scale-1-white', 'border-color-border-mid', 'w-4/5'],
      couponContainer: ['flex', 'justify-between', 'items-center'],
      linkWithUnderline: [
        'text-left',
        'relative',
        'font-body-large-regular-boldunderline',
        'text-body-large-regular-boldunderline',
        'leading-body-large-regular-boldunderline',
        'underline',
      ],
      summary: ['flex', 'justify-between'],
      regular: [
        'text-color-text-black',
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      bold: [
        'text-color-text-black',
        'text-body-small-bold',
        'font-body-small-bold',
        'leading-body-small-bold',
      ],
      summaryMargin: ['mb-[8px]'],
      summaryMarginLeft: ['ml-mob-margin-tight-left'],
      summaryBorder: ['border-t-[1px]', 'border-color-border-mid'],
      wrapIcon: ['flex', 'items-center'],
      icon: ['ml-[6px]', '[&>svg]:w-[16px]', 'cursor-pointer'],
      tooltipContainer: 'relative w-full h-full p-2 pb-3 lg:p-4',
      topTooltipWrapper:
        'absolute left-1/2 -translate-x-1/2 bottom-[calc(100%+10px)] w-[193px] lg:w-[320px] bg-color-background-light text-color-text-black border-2 rounded-lg  border-color-border-mid text-sm shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] z-10',
      tooltipArrowSecondDiv:
        'border-[12px] absolute top-1/2 -translate-y-1/2 -right-[21px] border-solid border-t-transparent border-r-transparent border-b-transparent border-l-color-background-light',
      topTooltipArrowFirstDiv:
        'absolute left-1/2 top-full border-[12px] border-solid border-l-transparent border-r-transparent border-b-transparent border-color-border-mid -translate-x-1/2',
      topToolTipDescription:
        'font-primary text-body-small-bold font-body-small-bold leading-body-small-bold gap-4 flex flex-col',
      topTooltipArrowSecondDiv:
        'border-[12px] absolute left-1/2 -translate-x-1/2 -bottom-[21px] border-solid border-l-transparent border-r-transparent border-b-transparent border-t-color-background-light',
      skeletonLoading: 'h-4 w-12 bg-slate-200 rounded animate-pulse',
      blockOpacity50: ['opacity-50'],
      blockOpacity100: ['opacity-100'],
      loadingImageWrapper: ['absolute', 'mt-7'],
      errorPopupWrapper: ['flex', 'flex-col', 'items-start'],
      errorPopupSpacing: [],
      errorPopupHeading: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      errorPopupDescription: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      errorPopupButtonWrapper: ['flex'],
    },
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-micro-y', 'px-mob-padding-micro-x'],
          headline: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          couponWrapper: ['mb-mob-margin-tight-bottom'],
          titleMb: ['mb-desk-margin-tight-bottom'],
          coupon: [
            'rounded-mob-global-radius-small',
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
          ],
          errorPopupWrapper: ['gap-mob-space-between-tight-vertical'],
          errorPopupSpacing: ['py-mob-padding-tight-y', 'px-mob-padding-base-x'],
          errorPopupButtonWrapper: ['mt-mob-margin-base-top'],
        },
        desktop: {
          base: ['py-desk-padding-micro-y', 'px-desk-padding-micro-x'],
          headline: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          couponWrapper: ['mb-desk-margin-tight-bottom'],
          titleMb: ['mb-desk-margin-tight-bottom'],
          coupon: [
            'rounded-desk-global-radius-small',
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
          ],
          errorPopupWrapper: ['gap-desk-space-between-tight-vertical'],
          errorPopupSpacing: ['py-desk-padding-tight-y', 'px-desk-padding-base-x'],
          errorPopupButtonWrapper: ['mt-desk-margin-base-top'],
        },
      },
      guest: {
        true: {
          coupon: ['cursor-not-allowed'],
          linkWithUnderline: ['text-color-border-mid', 'cursor-not-allowed'],
        },
        false: {
          coupon: ['pointer'],
          linkWithUnderline: ['text-color-brand-primary-1-base'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
export default orderSummary;
