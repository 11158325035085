import {
  FilterGeo,
  PageController,
  SearchResultsInitialState,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StoreLocatorConfig, StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { MouseEventHandler, useEffect, useState } from 'react';
import { convertMilesToKM } from 'src/utils/milestokm';
import clsx from 'clsx';
import { ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'components/helpers/LinkHelper';
import useDictionary from 'src/hooks/useDictionary';
import { useOcSelector } from 'src/redux/ocStore';
import RichTextHelper from 'components/helpers/RichTextHelper';
//import ComponentContext, { ComponentContextData } from 'lib/context/ComponentContext';
import { StoreObjectData } from 'src/pages/api/store/get-store-details';
import { ChangeStoreVariants } from 'components/ProductDetail/ChangeStorePopup/ChangeStoreVariants';
import { groomingStepsData, sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { GTMLabels, GTM_EVENT } from 'components/helpers/Constants';
import Loader from 'components/Loader/Loader';

export type GenericStoreLocatorProps =
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeStoreWidget.Fields.ChangeStore &
    StoreLocatorConfig & {
      coordinates: { lat: number; lng: number };
    };
export type StoreDetailsProps =
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeStoreWidget.Fields.ChangeStore & {
    store: StoreProps | null;
    handleModalClose: MouseEventHandler | undefined;
    showModal: boolean | undefined;
    myStoreData: StoreObjectData;
    getDictionaryValue: (key: string, fallbackValue?: string | undefined) => string;
  };
//Tailwind Variants Styles
const {
  storeListRow,
  storeDetailsContainer,
  storeDetail,
  storeName,
  storeInventoryContainer,
  myStoreWrapper,
  myStoreTxt,
  selectStoreCTA,
  storeAddressWrapper,
  storeAddressLink,
  storeAddress,
  mainWrapper,
  noofItemsPerPageText,
  resultText,
  changeLink,
} = ChangeStoreVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});
const LoaderComponent = ({ loadingText }: { loadingText: string }) => {
  return (
    <div className={clsx(storeListRow(), 'items-center lg:flex-row border-b-0')}>
      <Loader />
      <span>{loadingText}</span>
    </div>
  );
};
const NoStoreFound = (props: { noStoreTxt: string | undefined }) => {
  return (
    <div
      className={clsx(storeListRow(), 'lg:flex-row border-b-0 lg:pl-0 lg:pr-0 flex !gap-[12px]')}
    >
      <NextImage
        field={{
          value: {
            src: '/images/hard-error-icon.png',
            alt: 'hard-error-icon',
            width: '17',
            height: '17',
            class: 'w-[17px] h-[17px]',
          },
        }}
      />
      <p
        className={clsx(
          'text-color-accent-red',
          'text-body-large-bold',
          'font-body-large-bold',
          'leading-body-large-bold'
        )}
      >
        {props?.noStoreTxt}
      </p>
    </div>
  );
};

const ZipCodeRequired = () => {
  return (
    <div
      className={clsx(storeListRow(), 'lg:flex-row border-b-0 lg:pl-0 lg:pr-0 flex !gap-[12px]')}
    >
      <NextImage
        field={{
          value: {
            src: '/images/hard-error-icon.png',
            alt: 'hard-error-icon',
            width: '17',
            height: '17',
            class: 'w-[17px] h-[17px]',
          },
        }}
      />
      <p
        className={clsx(
          'text-color-accent-red',
          'text-body-large-bold',
          'font-body-large-bold',
          'leading-body-large-bold'
        )}
      >
        {'The Zip Code field is required'}
      </p>
    </div>
  );
};

const MyStoreText = (props: {
  myStoreText: string;
  field: ImageField | undefined;
}): JSX.Element | null => {
  return (
    <div className={myStoreWrapper()}>
      <div className="storeImage">
        <NextImage field={props.field} />
      </div>
      <span className={myStoreTxt()}>{props.myStoreText}</span>
    </div>
  );
};
const GenericStoreLocator = ({
  fields,
  coordinates,
  location,
  onStoreSelect,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coordinates: any;
  location: string;
  onStoreSelect: (store: StoreProps) => void;
}) => {
  const milestokm = convertMilesToKM(Number(fields?.defaultRadiusInMiles?.value) ?? 0) || 3000;
  const [renderStores, setRenderStores] = useState<StoreProps[]>([]);
  //const [selectedStore, setSelectedStore] = useState<StoreProps | null>(null);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [noStoreError, setNoStoreError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const { getDictionaryValue } = useDictionary();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  //const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  type InitialState = SearchResultsInitialState<'itemsPerPage' | 'sortType'>;
  const {
    queryResult: { isFetching, isLoading, data: { content: sdStoresList = [] } = {} },
    query,
  } = useSearchResults<StoreProps, InitialState>({
    state: {
      itemsPerPage: Number(fields?.numberOfRecords?.value) || 10,
      sortType: 'near_by_distance_asc',
    },
  });

  const geoFilter = new FilterGeo('location', `${milestokm}km`);
  useEffect(() => {
    query.getRequest().resetSearchFilter().setSearchFilter(geoFilter);
  }, []);
  useEffect(() => {
    PageController.getContext().setGeo({
      location: { lat: coordinates?.lat, lon: coordinates?.lng },
    });
  }, [coordinates]);

  useEffect(() => {
    setFetchLoader(true);
    setNoStoreError(false);
    setZipCodeError(false);
    if (!(isLoading || isFetching)) {
      if (sdStoresList?.length > 0) {
        const filteredStores = sdStoresList.filter(
          (store) =>
            store.xp_onlinebooking === true &&
            store.xp_onlinebookingcompany &&
            store.xp_onlinebookinglocation
        );

        if (filteredStores.length > 0) {
          sendStoreGTMData({
            eventName: GTM_EVENT?.groomingStoreLocator,
            storesShown: filteredStores.length,
          });
          setFetchLoader(false);
          setZipCodeError(false);
          setNoStoreError(false);
          setRenderStores(filteredStores);
        } else {
          setFetchLoader(false);
          if (location.length === 0) {
            setZipCodeError(true);
            setNoStoreError(false);
          } else {
            setNoStoreError(true);
            setZipCodeError(false);
          }
        }
      } else {
        setFetchLoader(false);
        if (location.length === 0) {
          setZipCodeError(true);
          setNoStoreError(false);
        } else {
          setNoStoreError(true);
          setZipCodeError(false);
        }
      }
    }
  }, [isLoading, isFetching, sdStoresList]);

  useEffect(() => {
    if (location.length === 0) {
      setZipCodeError(true);
      setNoStoreError(false);
    }
  }, [location]);

  //sending GTM data regarding to Store not found error
  useEffect(() => {
    if (noStoreError) {
      sendStoreGTMData({
        eventName: GTM_EVENT?.groomingStoreLocator,
      });
      sendStoreGTMData({
        eventName: GTM_EVENT?.storeLocatorError,
        error: GTMLabels?.groomingStoreError,
      });
    }
  }, [noStoreError]);

  return (
    <>
      <div className={mainWrapper()}>
        {fetchLoader && (
          <LoaderComponent
            loadingText={getDictionaryValue('ScheduleAppointmentStep1LoaderMessage') as string}
          />
        )}
        {zipCodeError && <ZipCodeRequired />}
        {!fetchLoader && noStoreError && (
          <NoStoreFound
            noStoreTxt={getDictionaryValue('ScheduleAppointmentStep1NoStoresFoundMessageText')}
          />
        )}
        {!(isLoading || isFetching || noStoreError || zipCodeError) && renderStores?.length > 0 && (
          <div data-component="/src/components/Grooming/ScheduleAppointment/GetGroomingStoreWidget">
            <>
              {renderStores?.length > 0 && (
                <div className={noofItemsPerPageText()}>
                  <RichTextHelper
                    tag="p"
                    field={fields?.noofItemsPerPageText}
                    updatedField={{
                      value: fields?.noofItemsPerPageText?.value
                        ?.replace(
                          '{location}',
                          `<strong>"${
                            renderStores?.length > 0
                              ? location
                              : `${myStoreData?.city}, ${myStoreData?.state}`
                          }"</strong>`
                        )
                        ?.replace('{number}', `${renderStores?.length}`),
                    }}
                    className={resultText()}
                  />
                  <LinkHelper
                    className={changeLink()}
                    onClick={(e) => {
                      e.preventDefault();
                      // setStores([]);
                      setRenderStores([]);
                      // setSelectedSuggestions({ Keywords: '' });
                    }}
                    field={{ value: { href: '/', text: fields?.changeText?.value } }}
                  />
                </div>
              )}
              {renderStores.map((store, index) => {
                return (
                  <div data-storeId={store.id} key={index} className={storeListRow()}>
                    <div className={storeDetailsContainer()}>
                      <div className={storeDetail()}>
                        <div className={storeAddressWrapper()}>
                          <div className={clsx(storeName(), 'mb-[25px] !text-color-text-brand-1')}>
                            {store.firstname}
                          </div>
                          <Text
                            className={(storeAddress(), 'ml-0')}
                            tag="p"
                            field={{ value: store?.street1 }}
                          />
                          <Text
                            className={(storeAddress(), 'ml-0')}
                            tag="p"
                            field={{ value: `${store.city}, ${store.state} ${store?.zip}` }}
                          />
                          {store?.phone && (
                            <LinkHelper
                              className={clsx(storeAddress(), storeAddressLink(), '!ml-0')}
                              field={{
                                value: {
                                  text: store.phone,
                                  href: `tel:${store.phone}`,
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className={storeInventoryContainer()}>
                        <div className="flex">
                          {myStoreData?.id === store?.storeid ? (
                            <MyStoreText
                              myStoreText={getDictionaryValue('MyStore')}
                              field={fields?.storeImageIcon}
                            />
                          ) : (
                            <LinkHelper
                              className={selectStoreCTA()}
                              onClick={async (e) => {
                                e.preventDefault();
                                onStoreSelect(store);
                                sendStoreGTMData({
                                  eventName: GTM_EVENT?.groomingSelectStore,
                                  storesShown: renderStores?.length?.toString(),
                                  storeId: myStoreData?.storeId,
                                  groomingStoreId: store?.id,
                                });
                                //Once store selected push data to data layer
                                groomingStepsData({
                                  eventName: GTM_EVENT?.groomingSteps,
                                  storeId: myStoreData?.storeId,
                                  groomingStoreId: store?.id,
                                  stepName: GTMLabels?.groomingStep1StepName,
                                });
                              }}
                              field={{
                                value: {
                                  href: '/',
                                  text: getDictionaryValue('ShopThisStore'),
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        )}
      </div>
    </>
  );
};
const GetGroomingStoreWidget = widget(GenericStoreLocator, WidgetDataType.SEARCH_RESULTS, 'store');
export default GetGroomingStoreWidget;
