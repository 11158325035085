export const getBreadCrumbWithSiblingsGql = `fragment breadcrumbFields on Item {
  ... on _HasBreadcrumb {
    breadcrumbTitle {
      value
    }
  }
  title: field(name: "title") {
    value
  }
  url {
    path
  }
  id
}
query Breadcrumb($datasource: String!, $language: String!, $path: String!, $templateId: [String]) {
  breadcrumb: item(path: $datasource, language: $language) {
    ...breadcrumbFields
    ancestors(
      hasLayout: true
      includeTemplateIDs: "47151711-26CA-434E-8132-D3E0B7D26683"
    ) {
      ...breadcrumbFields
    }
  }
  siblings: item(path: $path, language: $language) {
    children(first: 50, includeTemplateIDs:$templateId) {
      results {
        ...breadcrumbFields
        title: field(name: "title") {
          value
        }
        id
      }
    }
  }
}`;

export const getBreadcrumbOnlySiblingsGql = `query GetSiblings($path: String!, $language: String!, $templateId: [String]) {
  siblings: item(path: $path, language: $language) {
    children(first: 50, includeTemplateIDs:$templateId) {
     results{
      ...breadcrumbFields
      name
      title:field(name:"title"){
        value
      }
      id
    }
    }
  }
}

fragment breadcrumbFields on Item {
  ... on _HasBreadcrumb {
    breadcrumbTitle {
      value
    }
  }
  title: field(name: "title") {
    value
  }
  url {
    path
  }
  id
}`;

export const getPDPBreadcrumbGql = `fragment breadcrumbFields on Item {
  ... on _HasBreadcrumb {
    breadcrumbTitle {
      value
    }
  }
  title: field(name: "title") {
    value
  }
  url {
    path
  }
  id
}
query Breadcrumb($language: String!, $path: String!) {
  breadcrumb: item(path: $path, language: $language) {
    ...breadcrumbFields
    ancestors(
      hasLayout: true
      includeTemplateIDs: "47151711-26CA-434E-8132-D3E0B7D26683"
    ) {
      ...breadcrumbFields
    }
  }
}`;

export const getBreadcrumbDefaultGql = `query Breadcrumb($datasource: String!, $language: String!) {
  breadcrumb: item(path: $datasource, language: $language) {
    ...breadcrumbFields
    ancestors(
      hasLayout: true
      includeTemplateIDs: "47151711-26CA-434E-8132-D3E0B7D26683"
    ) {
      ...breadcrumbFields
    }
  }
}
fragment breadcrumbFields on Item {
  ... on _HasBreadcrumb {
    breadcrumbTitle {
      value
    }
  }
  title: field(name: "title") {
    value
  }
  url {
    path
  }
  id
}`;
