import { isArray, mergeWith } from 'lodash';
import axios from 'axios';

const MAX_PAGINATION_LOOP = 20;

export interface PageInfo {
  hasNext: boolean;
  endCursor: string;
}

export const getPaginatedDataUtil = async <T>(
  query: string,
  variables?: Record<string, string>,
  getPageInfo?: (data: T) => PageInfo | undefined
) => {
  let pageInfo: PageInfo | undefined;
  let result: T | null = null;

  let loopCounter = 0;
  do {
    loopCounter++;
    const queryParameters = {
      ...variables,
      nextCursor: pageInfo?.endCursor || '', // add cursor to query if present
      graphQLQuery: query, // add graphql query
    };
    const queryString = new URLSearchParams(queryParameters).toString();
    const response = await axios.get(
      `${process.env.PUBLIC_URL}/api/graphql/getData?${queryString}`
    );
    const data = response.data.graphQLResponse.data as T;
    if (getPageInfo) {
      pageInfo = getPageInfo(data);
    }
    if (result) {
      result = mergeWith(result, data, arrayMerger);
    } else {
      result = data;
    }
  } while (pageInfo?.hasNext && loopCounter < MAX_PAGINATION_LOOP);

  if (loopCounter >= MAX_PAGINATION_LOOP) {
    throw new Error("Max pagination loop reached.  Ensure that you don't have an infinite loop.");
  }
  return result;
};

function arrayMerger(objValue: unknown, srcValue: unknown) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return;
}
