import { tv } from 'tailwind-variants';

export const {
  alert,
  buttonAddRule,
  buttonDeleteRule,
  container,
  button,
  buttonGroup,
  personalizeButtonGroup,
  iconButton,
  iconDraggable,
  conditionItem,
  conditionItemValueFieldWrapper,
} = tv({
  slots: {
    personalizeButtonGroup: ['flex gap-2 top-2 right-2 z-10'],
    alert: ['bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'],
    container: ['relative flex flex-col gap-2 w-100 container'],
    conditionItem: [
      'flex justify-start items-center gap-4 p-2 border-2 border-gray-200 cursor-default',
    ],
    conditionItemValueFieldWrapper: ['rounded-sm font-normal min-w-[35%]'],
    buttonAddRule: [
      'bg-green-600 text-white px-2 py-.5 rounded-sm text-sm hover:bg-green-700 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed',
    ],
    buttonDeleteRule: [
      'text-red-500 px-2 py-.5 rounded-sm text-sm hover:bg-red-500 hover:text-white transition-color disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed whitespace-nowrap ml-auto',
    ],
    button: 'px-4 py-3 rounded-[4px]',
    iconButton:
      'aspect-square transition-all flex justify-center h-8 w-8 rounded-[4px] text-center items-center text-xl cursor-pointer',
    buttonGroup: 'flex justify-end gap-4 pt-4 mt-2 border-t border-gray-200',
    iconDraggable: [
      '[&>svg>*>*]:stroke-color-brand-primary-1-base [&>svg>*]:stroke-color-brand-primary-1-base [&>svg>*>*]:fill-color-brand-primary-1-base',
      '[&>svg]:w-6',
    ],
  },
  variants: {
    style: {
      personalize: {
        button: 'text-md z-10',
      },
    },
    color: {
      delete: {
        iconButton: 'hover:bg-red-200',
      },
      primary: {
        iconButton: 'hover:bg-green-500/20',
        button:
          'bg-green-600 text-white hover:bg-green-700 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed',
      },
      secondary: {
        button: 'bg-gray-200 text-gray-700 hover:bg-gray-300',
      },
    },
  },
})();
