import { ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { navigationVariants } from 'components/GlobalHeader/GlobalHeader';
import { Fragment } from 'react';

type UserGroupProfileImageProps = {
  profileImages: ProfileImagesType[];
  userPets: UserPetsType;
  isUserPetsAvailable: boolean;
  isWhiteImage?: boolean;
  width?: number;
  height?: number;
  addClass?: string;
};

export type ProfileImagesType = {
  Specie: string;
  SpecieData: {
    jsonValue: ImageField;
  };
  children?: {
    results: {
      WhiteSpecie: string;
      WhiteSpecieData: {
        jsonValue: ImageField;
      };
    }[];
  };
};

type UserPetsType = {
  [key: string]: string[];
};

const UserGroupProfileImage = ({
  profileImages,
  userPets,
  isUserPetsAvailable,
  isWhiteImage,
  width,
  height,
  addClass,
}: UserGroupProfileImageProps) => {
  const { utilityImageMaxWidth } = navigationVariants();

  const imageWidth = width || 40;
  const imageHeight = height || 40;
  const classNames = addClass || '';
  const userPetstKeys =
    userPets && userPets && Object.keys(userPets).map((key) => key.toLowerCase());
  let profileImageKey = 'Paw';

  if (userPets && isUserPetsAvailable) {
    switch (isUserPetsAvailable) {
      case userPetstKeys.includes('dog'):
        profileImageKey = 'Dog';
        break;
      case userPetstKeys.includes('cat'):
        profileImageKey = 'Cat';
        break;
      case userPetstKeys.includes('small animal'):
        profileImageKey = 'Small Animal';
        break;
      case userPetstKeys.includes('reptile'):
        profileImageKey = 'Reptile';
        break;
      case userPetstKeys.includes('fish'):
        profileImageKey = 'Fish';
        break;
      case userPetstKeys.includes('bird'):
        profileImageKey = 'Bird';
        break;
      default:
        profileImageKey = 'Paw';
    }
  }

  return (
    <>
      {!isWhiteImage ? (
        <>
          {profileImages &&
            profileImages.length > 0 &&
            profileImages
              .filter((item) => item?.Specie === profileImageKey)
              .map((item, index) => (
                <Fragment key={index}>
                  {item?.Specie === profileImageKey && (
                    <NextImage
                      field={item?.SpecieData?.jsonValue?.value}
                      className={clsx(utilityImageMaxWidth(), classNames)}
                      width={imageWidth}
                      height={imageHeight}
                    />
                  )}
                </Fragment>
              ))}
        </>
      ) : (
        <>
          {profileImages &&
            profileImages?.length > 0 &&
            profileImages.map((item, index) => (
              <Fragment key={index}>
                {item?.Specie === 'WhiteIcons' && (
                  <>
                    {item?.children?.results.map((specie, specieIndex) => (
                      <Fragment key={specieIndex}>
                        {specie?.WhiteSpecie && specie?.WhiteSpecie?.includes(profileImageKey) && (
                          <NextImage
                            field={specie?.WhiteSpecieData?.jsonValue?.value}
                            className={classNames}
                            height={imageWidth}
                            width={imageHeight}
                          />
                        )}
                      </Fragment>
                    ))}
                  </>
                )}
              </Fragment>
            ))}
        </>
      )}
    </>
  );
};

export default UserGroupProfileImage;
