// import { configureStore, createSlice } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type DogsData = {
  webId: 'webId_value';
  firstName: 'John';
  lastName: 'Doe';
  email: 'john.doe@example.com';
  phone: '1234567890';
  ppcNumber: 'ppc1234566666';
};
const initialState = {
  dogs: {} as DogsData,
};
export const appointmentsSlice = createSlice({
  name: 'dogs',
  initialState,
  reducers: {
    setDogs: (state, action) => {
      state.dogs = action.payload;
    },
  },
});

export const { setDogs } = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
