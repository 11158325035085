import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import ModalWrapper from 'components/helpers/ModalWrapper';
import React, { useContext, useEffect, useRef, useState } from 'react';
import IconHelper from 'components/helpers/IconHelper';
import AutoCompleteWrapper from 'components/helpers/Form/AutoCompleteWrapper';
import { tv } from 'tailwind-variants';
import { StoreLocatorConfig, StoreProps } from './GenericStoreLocator';
import ModalStore from './ModalStore';
import ComponentContext from 'lib/context/ComponentContext';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { GTMLabels, GTM_EVENT } from 'components/helpers/Constants';
import Loader from 'components/Loader/Loader';

//component variants
const storeLocatorGlobalPopupVariants = tv(
  {
    slots: {
      base: ['storeLocatorGlobalPopup'],
      loaderWrapper: 'flex items-center',
      loadingText: 'font-primary',
      noSearchResultsFoundText:
        'text-body-small-regular font-body-small-regular leading-body-small-regular text-system-red mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom',
      loadMoreButton: 'w-full flex justify-center',
      modalWrapper: '',
      loadMore:
        'flex group underline h-fit w-fit font-primary text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      headingWrapper: 'flex flex-col',
      input:
        'w-full h-[56px] text-color-text-dark placeholder-color-text-dark px-desk-padding-micro-x py-desk-padding-micro-y font-primary text-body-large-regular leading-body-large-regular font-body-large-regular focus:outline-none border-[1px] border-color-border-mid mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom',
      heading: 'font-primary',
      subHeading:
        'font-primary text-body-large-regular leading-body-large-regular font-body-large-regular',
      formHeading: 'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      searchIcon: 'w-[40px] h-[40px] [&>svg]:w-[40px] [&>svg]:h-[40px]',
      storePageArrow:
        'ml-1 [&>svg>*]:stroke-color-text-brand-1 [&>svg>path]:fill-color-text-brand-1 h-[16px] w-[16px] [&_svg]:max-w-[16px] [&_svg]:max-h-[16px] group-hover:[&>svg>*]:stroke-color-brand-tertiary-3-base group-focus:[&>svg>path]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:stroke-color-brand-tertiary-3-base group-hover:[&>svg>path]:fill-color-brand-tertiary-3-base',
      searchButton:
        'bg-color-background-brand-1 flex justify-center items-center w-[72px] h-[56px] mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom absolute right-0 top-0 hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          loadMoreButton: 'pb-0',
          loadingText:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          loaderWrapper:
            'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x mt-desk-margin-base-top',
          headingWrapper: 'gap-desk-space-between-tight-vertical',
          modalWrapper: 'py-desk-padding-tight-y px-desk-padding-base-x',
          loadMore:
            'my-desk-margin-tight-top text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold',
          heading:
            'font-heading-desk-xLarge-bold text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
        },
        mobile: {
          loadMoreButton: 'pb-6',
          loadingText:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          loaderWrapper:
            'py-mob-component-button-full-padding-y mt-mob-margin-base-top gap-mob-component-button-full-space-between',

          modalWrapper: 'py-mob-padding-tight-y px-mob-padding-base-x',
          headingWrapper: 'gap-mob-space-between-tight-vertical',
          loadMore:
            'my-mob-margin-tight-top text-heading-mob-medium-bold leading-heading-mob-medium-bold font-heading-mob-medium-bold',
          heading:
            'font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
        },
      },
      isError: {
        true: {
          input: 'border-system-red',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
const StoreLocatorGlobalPopup = ({
  fields,
  options,
  handleSelect,
  address,
  setAddress,
  selectedSuggestions,
  stores,
  isStoreLoading,
  isErrorInStoreData,
  setIsErrorInStoreData,
  setStores,
  totalStores,
  onLoadMoreClick,
  setSelectedSuggestions,
}: {
  fields: StoreLocatorConfig;
  isErrorInStoreData: boolean;
  setIsErrorInStoreData: (value: boolean) => void;
  setStores: (value: []) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  handleSelect: (value: string) => void;
  address: string;
  setAddress: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSuggestions: any;
  stores: StoreProps[];
  coordinates: { lat: number; lng: number };
  isStoreLoading: boolean;
  totalStores: number;
  onLoadMoreClick: () => void;
  setSelectedSuggestions: ({ Keywords }: { Keywords: string }) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    base,
    modalWrapper,
    headingWrapper,
    heading,
    subHeading,
    formHeading,
    noSearchResultsFoundText,
    input,
    searchButton,
    searchIcon,
    loaderWrapper,
    loadingText,
    storePageArrow,
    loadMoreButton,
    loadMore,
  } = storeLocatorGlobalPopupVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const handleModalClose = () => {
    setIsModalOpen(false);
    const callbackFunctionForStoreLocatorGlobalModal =
      componentContextData?.callbackFunctionForStoreLocatorGlobalModal;
    setcomponentContextData({
      ...componentContextData,
      showStoreLocatorGlobalModal: false,
      callbackFunctionForStoreLocatorGlobalModal: false,
    });
    if (callbackFunctionForStoreLocatorGlobalModal) {
      callbackFunctionForStoreLocatorGlobalModal();
    }
  };
  useEffect(() => {
    const handleClickOutsideModal = (event: MouseEvent) => {
      if (
        componentContextData?.showStoreLocatorGlobalModal &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains('pac-container') &&
        !(event.target as HTMLElement).closest('.pac-container') &&
        !(event.target as HTMLElement).classList.contains('pac-item') &&
        !(event.target as HTMLElement).classList.contains('pac-icon') &&
        !(event.target as HTMLElement).classList.contains('WarningStoreChangeModal') &&
        !(event.target as HTMLElement).classList.contains('cart-warning-popup') &&
        !(event.target as HTMLElement).closest('.WarningStoreChangeModal') &&
        !(event.target as HTMLElement).closest('.modal-wrapper')
      ) {
        handleModalClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutsideModal);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);

  //sending GTM data regarding to Store not found error
  useEffect(() => {
    isErrorInStoreData &&
      !(stores?.length > 0 && selectedSuggestions?.Keywords?.length > 0 && isStoreLoading) &&
      sendStoreGTMData({ eventName: GTM_EVENT?.storeLocatorError, error: GTMLabels?.storeError });
  }, [stores]);

  return (
    <div className={base()}>
      <ModalWrapper
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={isModalOpen}
        customPopup={true}
        popupWidth="max-w-[997px]"
        popupSpacing="p-0"
      >
        <div ref={modalRef} className={modalWrapper()}>
          <div className={headingWrapper()}>
            <div>
              <Text tag="p" className={heading()} field={fields?.headerText} />
              <RichText tag="p" className={subHeading()} field={fields?.requiredText} />
            </div>
            <div>
              {!(selectedSuggestions?.Keywords?.length > 0 && stores?.length > 0) && (
                <Text tag="p" className={formHeading()} field={fields?.searchboxText} />
              )}
              {!(selectedSuggestions?.Keywords?.length > 0 && stores?.length > 0) && (
                <AutoCompleteWrapper
                  options={options}
                  addressLine1={address}
                  hideSuggestions={address?.length > Number(fields?.minCharactersForSearch?.value)}
                  onHandleChange={handleSelect}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {({ onAutoSuggestionSubmit }: any) => {
                    return (
                      <form className="relative">
                        <input
                          aria-label="google-auto-suggestion-address"
                          name="google-auto-suggestion-address"
                          id="google-auto-suggestion-address"
                          value={address}
                          placeholder={fields?.searchboxPlaceholderText?.value}
                          className={input({ isError: isErrorInStoreData })}
                          onChange={(e) => {
                            setIsErrorInStoreData(false);
                            setAddress(e?.target?.value);
                          }}
                        />
                        <button
                          aria-label="search"
                          onClick={async (e) => {
                            e.preventDefault();
                            const inputElement = document.getElementById(
                              'google-auto-suggestion-address'
                            ) as HTMLInputElement;
                            if (inputElement) {
                              const inputValue = inputElement?.value;
                              if (inputValue) {
                                // sendStoreGTMData({ eventName: GTM_EVENT?.storeLocator });
                                setAddress(inputValue);
                                setIsErrorInStoreData(false);
                                handleSelect(await onAutoSuggestionSubmit(inputValue));
                              }
                            }
                          }}
                          className={searchButton()}
                        >
                          <IconHelper className={searchIcon()} icon={'search'} />
                        </button>
                      </form>
                    );
                  }}
                </AutoCompleteWrapper>
              )}
              {isErrorInStoreData &&
                !(
                  !isStoreLoading &&
                  stores?.length > 0 &&
                  selectedSuggestions?.Keywords?.length > 0
                ) && (
                  <>
                    {fields?.noSearchResultsFoundText && (
                      <RichText
                        tag="p"
                        className={noSearchResultsFoundText()}
                        field={fields?.noSearchResultsFoundText}
                      />
                    )}
                  </>
                )}
            </div>

            {stores?.length > 0 && selectedSuggestions?.Keywords?.length > 0 && (
              <ModalStore
                stores={stores}
                location={selectedSuggestions?.Keywords}
                fields={fields || {}}
                setStores={setStores}
                // setAddress={setAddress}
                handleModalClose={handleModalClose}
                setSelectedSuggestions={setSelectedSuggestions}
              />
            )}
            {isStoreLoading && selectedSuggestions?.Keywords?.length > 0 && (
              <div className={loaderWrapper()}>
                <Loader />
                <RichText
                  tag="p"
                  field={fields?.loadingNearbyStoresText}
                  className={loadingText()}
                />
              </div>
            )}
            {stores?.length > 0 &&
              totalStores > stores?.length &&
              selectedSuggestions?.Keywords?.length > 0 &&
              fields?.loadMoreButtonText?.value && (
                <div className={loadMoreButton()}>
                  <button aria-label="load more" onClick={onLoadMoreClick} className={loadMore()}>
                    {fields?.loadMoreButtonText?.value}
                    <IconHelper className={storePageArrow() + ' mt-1'} icon={'chevron-right'} />
                  </button>
                </div>
              )}
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default StoreLocatorGlobalPopup;
