import { FormikProps, FormikValues } from 'formik';
import { Dispatch, Ref, SetStateAction, createContext, useContext, useRef, useState } from 'react';

export type CheckoutFormSection =
  | 'none'
  | 'billing-address'
  | 'delivery-address'
  | 'pickup-address';

interface CheckoutFormContextProps {
  openSection: CheckoutFormSection;
  setOpenSection: Dispatch<SetStateAction<CheckoutFormSection>>;
  // deliveryAddressForm: React.RefObject<FormikProps<unknown>>;
  billingAddressForm: Ref<FormikProps<FormikValues>> & React.RefObject<FormikProps<unknown>>;
  deliveryAddressForm: Ref<FormikProps<FormikValues>> & React.RefObject<FormikProps<unknown>>;
  pickupAddressForm: Ref<FormikProps<FormikValues>> & React.RefObject<FormikProps<unknown>>;
  // etc others here
}

const CheckoutFormContext = createContext<CheckoutFormContextProps | undefined>(undefined);

export default CheckoutFormContext;

export function useCheckoutFormContext() {
  return useContext(CheckoutFormContext);
}

export function CheckoutFormContextProvider({ children }: React.PropsWithChildren) {
  const [openSection, setOpenSection] = useState<CheckoutFormSection>('none');
  // const billingAddressForm = useRef<FormikProps<unknown>>(null);
  const billingAddressForm = useRef<FormikProps<FormikValues>>(null);
  const deliveryAddressForm = useRef<FormikProps<FormikValues>>(null);
  const pickupAddressForm = useRef<FormikProps<FormikValues>>(null);

  return (
    <CheckoutFormContext.Provider
      value={{
        openSection,
        setOpenSection,
        billingAddressForm,
        deliveryAddressForm,
        pickupAddressForm,
      }}
    >
      {children}
    </CheckoutFormContext.Provider>
  );
}
