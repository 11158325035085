import { ProductSearchResultModel } from '../types';
import searchResultsTailwind from '../SearchResultsTailwind';
import clsx from 'clsx';
import { useProductInventory } from '../use-product-inventory';

export function ProductInventoryMessage({ product }: { product: ProductSearchResultModel }) {
  const { productLabels } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const inventoryStatus = useProductInventory(product);

  const inventoryMessage =
    inventoryStatus === 'InStock' ? (
      <span className={clsx(productLabels(), 'text-color-global-in-stock')}> In Stock</span>
    ) : inventoryStatus === 'LowStock' ? (
      <span className={clsx(productLabels(), 'text-color-global-low-stock')}> Low Stock</span>
    ) : (
      <span className={clsx(productLabels(), 'text-color-global-outof-stock')}> Out of Stock</span>
    );
  return <div className="font-bold">{inventoryMessage}</div>;
}
