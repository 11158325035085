import {
  AccessToken,
  ApiRole,
  Auth,
  RequiredDeep,
  Tokens,
  UserInfo,
  Me,
} from 'ordercloud-javascript-sdk';
import { retrieveOrder, transferAnonOrder } from '../ocCurrentOrder';
import { clearProductList } from '../ocProductList';
import { cleanProductCache } from '../ocProductCache';
import { createOcAsyncThunk } from '../ocReduxHelpers';
import { clearUser, getUser } from '../ocUser';
//import { setIsAnonymous } from '.';
export interface LoginActionRequest {
  username: string;
  password: string;
  remember?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateUser = async (userObj: any, credentials: any, response: any) => {
  if (!userObj || !userObj?.Active || !userObj?.xp?.Verified) {
    Tokens.RemoveAccessToken();
    Tokens.RemoveRefreshToken();
  } else {
    // 2. if user is verified, let the user bypass in the site:
    Tokens.SetAccessToken(response.access_token);
    // If user has selected remember him:
    if (credentials.remember && response.refresh_token) {
      Tokens.SetRefreshToken(response.refresh_token);
    }
    const { identity_token } = await UserInfo.GetToken();
    Tokens.SetIdentityToken(identity_token);
  }
};

const login = createOcAsyncThunk<RequiredDeep<AccessToken>, LoginActionRequest>(
  'ocAuth/login',
  async (credentials, thunkAPI) => {
    const { ocConfig } = thunkAPI.getState();
    if (!ocConfig?.value) {
      throw new Error('OrderCloud Provider was not properly configured');
    }

    const { ocCurrentOrder } = thunkAPI.getState();

    // set the transfer token if the anonymous user has an in progress order
    let transferToken;
    if (ocCurrentOrder && ocCurrentOrder.order) {
      transferToken = Tokens.GetAccessToken();
    }

    thunkAPI.dispatch(clearUser());
    thunkAPI.dispatch(clearProductList());
    thunkAPI.dispatch(cleanProductCache());

    const response = await Auth.Login(
      credentials.username,
      credentials.password,
      ocConfig?.value.clientId,
      ocConfig?.value.scope as ApiRole[]
    );

    if (response && response?.access_token) {
      // 1. Get user details and check for verified and isActive.
      Tokens.SetAccessToken(response.access_token);
      if (response?.refresh_token) {
        Tokens.SetRefreshToken(response.refresh_token);
      }
      const userObj = await Me.Get();
      await validateUser(userObj, credentials, response);
    }
    const { identity_token } = await UserInfo.GetToken();
    Tokens.SetIdentityToken(identity_token);

    thunkAPI.dispatch(getUser());

    // transfer the order if a transfer token was set
    if (transferToken) {
      thunkAPI.dispatch(transferAnonOrder(transferToken));
    } else {
      thunkAPI.dispatch(retrieveOrder());
    }
    return response;
  }
);

export default login;
