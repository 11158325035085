// import { configureStore, createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generateGraphQuery } from '../lib/query.graphql';
import axios from 'axios';
import { SiteName, SiteNameUppercase } from 'components/helpers/Constants';
import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';
// import { getLookup } from 'src/utils/lookups';
// import { getLookup } from 'src/utils/lookups';

type LookupState = {
  status: string;
  loading: boolean;
  data: LookupData;
  error: string;
};
interface Region {
  code: { value: string };
  name: { value: string };
}

interface Country {
  code: { value: string };
  name: { value: string };
  regions: { targetItems: Region[] };
}

type Lookup = {
  lookupList: {
    lookupName: string;
    items: {
      list: object[];
    };
    children: {
      results: [];
    };
  }[];
};
type LookupData = {
  graphQLResponse?: {
    data: {
      lookups: Lookup;
      countries_region: {
        countries: {
          results: Country[];
        };
      };
      PSP: Lookup;
      WAGNWASH: Lookup;
    };
  };
};
const initialState: LookupState = {
  status: '',
  loading: true,
  data: {},
  error: '',
};

const rootPath = 'B2009550-BACD-484A-B5E5-3883D2AFEF4F';
const pageSize = 200;
const hasNextList = false;
const endCursor = '';
// Function to get the path value based on the site name
const getPathValue = (siteName: SiteNameUppercase): string => {
  switch (siteName) {
    case 'PSP':
      return '{D7146264-59FD-4611-8C38-AA8A366F6F35}';
    case 'WAGNWASH':
      return '{5DB6E576-B9C8-43BB-8FC3-4A8E15842B32}';
    default:
      throw new Error(`Unknown site name: ${siteName}`);
  }
};
export const getLookup = createAsyncThunk('lookup/getLookup', async (siteName: SiteName) => {
  try {
    const site = siteName.toUpperCase() as SiteNameUppercase; // or "WNW" based on your requirement
    const templateValue = '{96EAC973-A0D7-4F94-953B-9B90AC46724C}'; // value for the template
    const pathValue = getPathValue(site); // dynamic value for the path
    const gqlQuery = generateGraphQuery({ site, templateValue, pathValue });
    const publicURL = publicURLTrailingSlash();
    const response = await axios.get(
      `${publicURL}api/graphql/getData?rootPath=${rootPath}&pageSize=${pageSize}&endCursor=${endCursor}&hasNext=${hasNextList}&graphQLQuery=${gqlQuery}`
    );
    return response.data;
  } catch (err) {
    return err;
  }
});
export const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLookup.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getLookup.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getLookup.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = true;
        state.error = action.error.message || '';
      });
  },
});
export const selectLoading = (state: LookupState) => state.status;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLookupsData = (state: { data: any }) => state.data;

export default lookupSlice.reducer;
