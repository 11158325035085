import { handleCTRewardsSignIn } from 'lib/crowd-twist/ct-sign-in';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcSelector } from 'src/redux/ocStore';

const CrowdTwistSSO = () => {
  const unityId = useOcSelector((state) => state?.ocUser?.user?.xp?.UnityId);
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);
  const [, setCookie] = useCookies(['ct-auth']);

  const router = useRouter();
  const [cookies] = useCookies(['ct-auth']);
  const ctAuthCookie = cookies['ct-auth'];
  const isExperienceEditor = useExperienceEditor();

  useEffect(() => {
    if (unityId && !ctAuthCookie && isLoyaltyAccepted && !isExperienceEditor) {
      const handleCTSignIn = async () => {
        if (isLoyaltyAccepted === true && !ctAuthCookie) {
          const ctSignInRedirectUrl = await handleCTRewardsSignIn(unityId);

          if (ctSignInRedirectUrl) {
            setCookie('ct-auth', true);
            router.push(ctSignInRedirectUrl);
          }
        }
      };
      handleCTSignIn();
    }
  }, [ctAuthCookie, isExperienceEditor, isLoyaltyAccepted, router, setCookie, unityId]);

  return;
};

export default CrowdTwistSSO;
