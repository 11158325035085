import { getProductDetailPath } from 'components/helpers/Constants';
import { PspProductRootProps } from '../PspProductRoot';
import Link from 'next/link';

export const ProductLink = ({
  actions,
  product,
  index,
  children,
  styles,
}: React.PropsWithChildren<PspProductRootProps>) => {
  return (
    <Link
      prefetch={false}
      aria-label="productLink"
      href={getProductDetailPath({ ...product, ParentID: product?.parentid })}
      onClick={() => {
        actions.onItemClick({
          id: product.id ?? '',
          index,
          sourceId: product.source_id,
        });
      }}
      className={styles}
    >
      {children}
    </Link>
  );
};
