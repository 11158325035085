import { ProductXpImage } from 'src/redux/xp';

export const getSortedProductImages = (images?: ProductXpImage[]) => {
  if (images && images.length > 0) {
    return [...images]?.sort((a, b) => a.Position - b.Position);
  }
  return [];
};
export const getFirstProductImage = (images?: ProductXpImage[]) => {
  return getSortedProductImages(images)?.[0];
};
