//global
import React from 'react';
import { NextImage, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import RichTextHelper from 'components/helpers/RichTextHelper';
import TextHelper from 'components/helpers/TextHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
//type
export type TextBannerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.TextBanner.Fields.TextBanner;
//component variants
const textBannerVariaints = tv(
  {
    slots: {
      base: ['textBanner', 'relative', 'flex', 'justify-center', 'items-center'],
      desktopImage: [''],
      mobileImage: [''],
      img: [''],
      heading: [
        'text-center',
        'text-color-text-white',
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
    },
    compoundSlots: [{ slots: ['img', 'mobileImage'], class: ['h-full', 'w-full'] }],
    variants: {
      device: {
        mobile: {
          base: ['px-mob-padding-loose-x', 'py-mob-padding-loose-y'],
          mobileImage: ['absolute', 'left-0', 'top-0'],
          desktopImage: ['hidden'],
        },
        desktop: {
          base: ['px-0', 'py-desk-padding-micro-y'],
          mobileImage: ['hidden'],
          desktopImage: ['flex'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const TextBanner: React.FC<TextBannerProps> = ({ fields, params }) => {
  //check if pageEditor or not
  const isEE = useExperienceEditor();
  //variaints
  const { base, heading, desktopImage, mobileImage, img } = textBannerVariaints({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const backgroundColor =
    fields?.hexColor && fields?.hexColor?.value?.length > 0
      ? fields?.hexColor?.value
      : 'bg-color-brand-primary-1-base';
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={params?.Styles + ' ' + base({ class: backgroundColor })}
      style={{ backgroundColor: backgroundColor }}
    >
      {fields?.leftImage?.value?.src && fields?.leftImage?.value?.src?.length > 0 && (
        <div className={desktopImage()}>
          <NextImage field={fields?.leftImage} />
        </div>
      )}

      <RichTextHelper tag="h1" field={fields?.heading} className={heading()} />

      {isEE && (
        <>
          Background color :<TextHelper field={fields?.hexColor} />
        </>
      )}
      {fields?.rightImage?.value?.src && fields?.rightImage?.value?.src?.length > 0 && (
        <div className={desktopImage()}>
          <NextImage field={fields?.rightImage} />
        </div>
      )}
      {fields?.mobileImage?.value?.src && fields?.mobileImage?.value?.src?.length > 0 && (
        <div className={mobileImage()}>
          <NextImage field={fields?.mobileImage} className={img()} />
        </div>
      )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<TextBannerProps>(TextBanner);
