import { tv } from 'tailwind-variants';
import { AccordionProps } from './types';
import { MinusSVGIcon, PlusSVGIcon } from './svgIcons';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useEffect, useRef, useState } from 'react';

const Accordion = ({ faq, activeIndices, toggleAccordion }: AccordionProps) => {
  const FAQContainerClass = tv(
    {
      slots: {
        tabPanel: ['border-t', 'border-color-border-mid'],
        tabItem: ['border-b', 'border-color-border-mid'],
        tabItemGroup: [''],
        tabButton: [
          'flex',
          'items-center',
          'justify-center',
          'p-desk-component-button-condensed-padding-y',
          'rounded-desk-global-radius-x-small',
          'font-primary',
          'cursor-pointer',
          'text-color-text-brand-1',
          'font-heading-desk-xLarge-bold',
          'leading-body-large-regular',
        ],
        tabContent: ['px-4 py-3'],
        tabItemSummary: [
          'my-1',
          'py-4',
          'gap-3',
          'font-medium',
          'marker:content-none',
          'hover:cursor-pointer',
        ],
        tabItemQuestion: ['flex', 'items-center'],
        tabItemTitle: [
          'text-heading-desk-small-bold',
          'font-heading-desk-small-bold',
          'leading-heading-desk-small-bold',
          'pl-4',
        ],
        tabItemDescription: ['ml-desk-margin-loose-left', 'font-normal'],
        tabItemArticle: ['px-4', 'pb-4'],
        tabList: [''],
        activeTabButton: ['!text-color-text-black', 'border', 'border-color-border-brand'],
        activeTabSlideDown: ['pt-2', 'mb-1'],
        activeTabSlideIn: ['h-0', 'invisible'],
      },
    },
    {
      responsiveVariants: ['lg'],
    }
  );

  const {
    tabPanel,
    tabItem,
    tabItemSummary,
    tabItemQuestion,
    tabItemTitle,
    tabItemDescription,
    activeTabSlideDown,
    activeTabSlideIn,
  } = FAQContainerClass({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const [contentHeights, setContentHeights] = useState<number[]>([]);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const heights = contentRefs.current.map((ref) => ref?.scrollHeight || 0);
    setContentHeights(heights);
  }, [faq, activeIndices]);

  return (
    <div className={tabPanel()}>
      {faq &&
        faq.map((item, index) => (
          <div key={index} className={tabItem()}>
            <div className={tabItemSummary()} onClick={() => toggleAccordion(index)}>
              <div className={tabItemQuestion()}>
                {activeIndices.includes(index) ? <MinusSVGIcon /> : <PlusSVGIcon />}
                <div className={tabItemTitle()}>
                  {item.question?.value && <RichTextHelper field={item.question} />}
                </div>
              </div>
              <div
                ref={(ref) => (contentRefs.current[index] = ref)}
                className={`${tabItemDescription()} ${
                  activeIndices.includes(index) ? activeTabSlideDown() : activeTabSlideIn()
                }`}
                style={{
                  height: activeIndices.includes(index) ? `${contentHeights[index]}px` : '0',
                  transition: 'height 400ms ease',
                }}
              >
                {item.answer?.value && <RichTextHelper field={item.answer} />}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Accordion;
