import { tv } from 'tailwind-variants';

export const myFavoriteVariants = tv(
  {
    slots: {
      noProductInWishlistText:
        'w-full text-center py-mob-padding-tight-y lg:py-desk-padding-tight-y text-body-large-bold font-body-large-bold leading-body-large-bold font-primary',
      base: ['myFavorite flex flex-col -mt-[3px] lg:mt-[22px]'],
      title: 'font-primary',
      productTitles:
        'font-primary text-color-text-dark text-body-small-bold leading-body-small-bold font-body-small-bold',
      price: 'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      priceWrapper: 'flex max-w-full lg:min-w-[98px] lg:gap-desk-global-spacing-spacing-6',
      inputWrapper:
        'w-[130px] h-[62px] rounded-desk-global-radius-small border border-color-border-mid flex relative',
      input:
        'placeholder:text-color-text-black h-full w-[98px] font-primary text-heading-desk-large-regular leading-heading-desk-large-regular font-heading-desk-large-regular text-color-text-black rounded-l-desk-global-radius-small text-center outline-none focus:outline-none',
      inputButtonWrapper: 'rounded-r-desk-global-radius-small bg-color-background-light',
      inputButton: 'w-[30px] h-[30px] flex justify-center items-center',
      productName:
        'no-underline hover:no-underline text-heading-mob-medium-bold font-primary leading-heading-mob-medium-bold font-heading-mob-medium-bold',
      productDetails: '',
      productDetailWrapper: 'border-b border-b-color-border-mid',
      removeFromWishlistButton:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold underline',
      productImageWrapper: 'flex flex-col gap-[40px] relative items-center lg:min-w-[218px]',
      titlesWrapper: 'lg:py-desk-global-spacing-spacing-2',
      addToCartButton:
        'rounded-desk-global-radius-small bg-color-brand-primary-1-base flex justify-center text-color-text-white py-desk-component-button-full-padding-y font-primary text-heading-desk-large-bold leading-heading-desk-large-bold font-heading-desk-large-bold lg:w-[188px] ',
      inputError:
        'text-system-red font-primary text-body-large-bold leading-body-large-bold font-body-large-bold mb-desk-space-between-tight-vertical',
      stockDetail:
        'text-center font-primary text-color-text-dark text-body-small-bold font-body-small-bold leading-body-small-bold border w-[104px] p-desk-global-spacing-spacing-1 border-color-text-dark',
      topTooltipWrapper:
        'absolute left-1/2 -translate-x-1/2 bottom-[calc(100%+10px)] w-[193px] lg:w-[320px] bg-color-background-light text-color-text-black border-2 border-color-border-mid text-sm shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] z-10',
      addToCartWrapper: 'flex flex-col lg:min-w-[188px]',
      topTooltipArrowFirstDiv:
        'absolute left-1/2 top-full border-[12px] border-solid border-l-transparent border-r-transparent border-b-transparent border-color-border-mid -translate-x-1/2',
      topTooltipArrowSecondDiv:
        'border-[12px] absolute left-1/2 -translate-x-1/2 -bottom-[21px] border-solid border-l-transparent border-r-transparent border-b-transparent border-t-color-background-light',
      topToolTipDescription:
        'font-primary text-body-small-bold font-body-small-bold leading-body-small-bold gap-4 flex flex-col',
      tooltipContainer: 'relative w-full h-full p-2 pb-3 lg:p-4',
      productImgWrapper: 'relative w-full flex justify-center overflow-hidden',
      imageTagWrapper: 'absolute left-0 top-0 flex flex-col gap-[6px]',
      saleLabel:
        'font-primary z-[2] text-body-small-bold leading-body-small-bold font-body-small-bold uppercase text-color-text-white px-desk-global-spacing-spacing-2 py-desk-global-spacing-spacing-1 bg-pdp-red',
      offerLabel:
        'absolute bottom-[30px] -left-[96px] lg:bottom-[30px] lg:left-[-97px] z-[1] w-[280px] flex justify-center text-color-text-white rotate-45 !text-body-small-regular !font-body-small-regular !leading-body-small-regular py-desk-global-spacing-spacing-1 px-desk-padding-tight-x bg-color-accent-red',
      retailTagWrapper: 'flex absolute right-0 top-[95px]',
      retailTag:
        'bg-color-brand-primary-1-base px-desk-global-spacing-spacing-1 text-body-small-bold font-body-small-bold leading-body-small-bold text-white',
      prices: 'w-[147px] lg:w-[98px]',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          noProductInWishlistText: 'py-desk-padding-tight-y',
          addToCartButton: '',
          inputError: 'mt-0 mb-desk-space-between-tight-vertical',
          stockDetail: 'mt-0',
          productName: 'mt-0',
          title:
            'text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold font-heading-desk-xLarge-bold',
          productTitles: '',
          priceWrapper: 'mt-0  flex-col ',
          addToCartWrapper: ' gap-desk-space-between-base-vertical',
          productDetails: 'w-full flex flex-col gap-desk-margin-base-bottom',
          productDetailWrapper:
            'w-full px-0 py-desk-padding-tight-y flex gap-desk-space-between-base-horizontal',
          productImageWrapper: ' relative',
          titlesWrapper:
            'w-full flex gap-desk-space-between-base-horizontal border-b border-b-color-border-mid',
          tooltipWrapper: 'rounded-desk-global-radius-medium',
          topTooltipWrapper: 'rounded-desk-global-radius-medium',
        },
        mobile: {
          noProductInWishlistText: 'py-mob-padding-tight-y',
          addToCartButton: 'w-full',
          addToCartWrapper: 'gap-mob-space-between-base-vertical',
          inputError: 'mb-mob-space-between-tight-vertical',
          stockDetail: 'mt-mob-margin-base-bottom',
          productName: 'mt-6',
          tooltipWrapper: 'rounded-mob-global-radius-medium',
          topTooltipWrapper: 'rounded-mob-global-radius-medium',
          productDetailWrapper: 'py-mob-padding-tight-y px-mob-padding-micro-x',
          priceWrapper:
            'gap-mob-space-between-base-horizontal flex-wrap mt-mob-space-between-base-vertical',
          title:
            'text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold font-heading-mob-xLarge-bold',
        },
      },
      isError: {
        true: {
          addToCartButton:
            'bg-color-brand-primary-1-disabled cursor-not-allowed hover:no-underline',
        },
        false: {
          addToCartButton:
            'hover:bg-color-brand-primary-1-dark hover:no-underline focus:bg-color-brand-primary-1-dark',
        },
      },
      priceType: {
        red: {
          price: 'text-system-red',
        },
        strike: {
          price: 'line-through',
        },
        hasImapPrice: {
          price: 'underline cursor-pointer',
        },
        hasMemberPrice: {
          price: 'text-pdp-red',
        },
        hasListPrice: {
          price: '',
        },
        isListPriceWithMemberPrice: {
          price: 'text-color-scale-5-mid line-through',
        },
      },
      stockDetail: {
        InStock: { stockDetail: '!border-color-global-in-stock' },
        LowStock: { stockDetail: '!border-color-global-low-stock' },
        OutOfStock: { stockDetail: '!border-color-global-outof-stock' },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
