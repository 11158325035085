const faqContainerQuery = `query FAQQuery($datasource: String!, $language: String!) {
  datasource: item(path: $datasource, language: $language) {
    ... on FAQContainer {
      title: field(name: "title") {
        value
      }
      description: field(name: "description") {
        value
      }
      defaultCategory: field(name: "defaultCategory") {
        value
      }
      showDefaultCategory: field(name: "showDefaultCategory") {
        jsonValue
      }
      showDefaultCategoryAtLast: field(name: "showDefaultCategoryAtLast") {
        jsonValue
      }
      hideCategory: field(name: "hideCategory") {
        jsonValue
      }
      selectCategories: field(name: "selectCategories") {
        ... on MultilistField {
          targetItems {
            categoryName: field(name: "categoryName") {
              value
            }
            storeConfiguration: field(name: "storeConfiguration") {
              value
            }
            selectQuestions: field(name: "selectQuestions") {
              ...Questions
            }
          }
        }
      }
    }
  }
}
fragment Questions on MultilistField {
  count
  targetItems {
    question: field(name: "question") {
      value
    }
    answer: field(name: "answer") {
      value
    }
  }
}`;

const getStoreDetails = (dataSource: string) => `
  query SearchStore {
    pageOne: search(
      where: { name: "_path", value: "${dataSource}" }
      first: 10
    ) {
      results {
        name
        serviceId: field(name: "serviceId") {
          value
        }
      }
    }
  }
`;

export { faqContainerQuery, getStoreDetails };
