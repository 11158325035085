const jssConfig = require('./src/temp/config');
const plugins = require('./src/temp/next-config-plugins') || {};

const publicUrl = jssConfig.publicUrl;

/**
 * @type {import('next').NextConfig}
 */
const nextConfig = {
  productionBrowserSourceMaps: true,
  // Set assetPrefix to our public URL for editing host only.  Otherwise use current domain.
  assetPrefix: process.env.DISABLE_SSG_FETCH ? publicUrl : undefined,
  // Set assetPrefix to our public URL
  // assetPrefix: publicUrl,

  // use this configuration to ensure that only images from the whitelisted domains
  // can be served from the Next.js Image Optimization API
  // see https://nextjs.org/docs/app/api-reference/components/image#remotepatterns
  images: {
    unoptimized: !!process.env.UNOPTIMIZED_IMAGES, // Sitecore Support ticket Case CS0466192 : For now we are keeping image optimisation off because of memory leak issue.
    remotePatterns: [
      {
        protocol: 'https',
        hostname: 'edge*.**',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'feaas*.blob.core.windows.net',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'ps-ch-playground.sitecoresandbox.cloud',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'rsvzprod.pspcdn.com/',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'dummyimage.com',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'delivery-petsuppliesplus.stylelabs.cloud',
        port: '',
      },
      {
        protocol: 'https',
        hostname: '*.sitecorecontenthub.cloud',
        port: '',
      },
      {
        protocol: 'https',
        hostname: '*.pspcdn.com',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'rsuatcoupons.azureedge.net',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'petsuppliesplusemail.com',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'wagnwash.com',
        port: '',
      },
      {
        protocol: 'http',
        hostname: 'xmcloudcm.localhost',
        port: '',
      },
    ],
    dangerouslyAllowSVG: true,
  },

  // Allow specifying a distinct distDir when concurrently running app in a container
  distDir: process.env.NEXTJS_DIST_DIR || '.next',

  // Make the same PUBLIC_URL available as an environment variable on the client bundle
  env: {
    PUBLIC_URL: publicUrl,
    SITES: process.env.SITES,
    CROWD_TWIST_PSP_URL_OF_PROGRAM: process.env.CROWD_TWIST_PSP_URL_OF_PROGRAM,
    CROWD_TWIST_WAGNWASH_URL_OF_PROGRAM: process.env.CROWD_TWIST_WAGNWASH_URL_OF_PROGRAM,
    MEDIA_LIBRARY_GQL_TOKEN: process.env.MEDIA_LIBRARY_GQL_TOKEN,
  },

  i18n: {
    // These are all the locales you want to support in your application.
    // These should generally match (or at least be a subset of) those in Sitecore.
    locales: ['en'],
    // This is the locale that will be used when visiting a non-locale
    // prefixed path e.g. `/styleguide`.
    defaultLocale: jssConfig.defaultLanguage,
  },

  // Enable React Strict Mode
  reactStrictMode: true,

  async rewrites() {
    // When in connected mode we want to proxy Sitecore paths off to Sitecore
    return [
      // API endpoints
      {
        source: '/sitecore/api/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/api/:path*`,
      },
      // media items
      {
        source: '/-/:path*',
        destination: `${jssConfig.sitecoreApiHost}/-/:path*`,
      },
      // healthz check
      {
        source: '/healthz',
        destination: '/api/healthz',
      },
      // rewrite for Sitecore service pages
      {
        source: '/sitecore/service/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/service/:path*`,
      },
    ];
  },
};

module.exports = () => {
  // Run the base config through any configured plugins
  return Object.values(plugins).reduce((acc, plugin) => plugin(acc), nextConfig);
};
