import { NextImage, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React from 'react';
import { tv } from 'tailwind-variants';
import { PSP } from 'models/PetSuppliesPlus.Model';
import IconHelper from 'components/helpers/IconHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';

export type CheckoutHeaderProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.CheckoutHeader.Fields.CheckoutHeader;

const checkoutheader = tv(
  {
    slots: {
      base: ['bg-color-brand-primary-1-base'],
      linkwrapper: [
        'flex items-center gap-desk-space-between-micro-horizontal hover:no-underline text-heading-desk-medium-bold font-heading-desk-medium-bold',
      ],
      mobimagewrapper: ['lg:hidden w-full'],
      deskimagewrapper: ['lg:flex hidden w-full'],
      iconWrapper: ['w-4'],
      divWrapper: ['gap-desk-space-between-micro-horizontal items-center w-max'],
    },
    compoundSlots: [
      {
        slots: ['mobimagewrapper', 'deskimagewrapper', 'base'],
        class: ['flex justify-center items-center text-color-text-white col-span-4'],
      },
      {
        slots: ['divWrapper', 'base', 'iconWrapper'],
        class: ['flex'],
      },
    ],
    variants: {
      device: {
        mobile: {
          base: [
            'flex-wrap-reverse',
            'flex',
            'py-mob-padding-micro-y',
            'px-mob-global-grid-margin',
            'gap-mob-space-between-base-vertical',
          ],
        },
        desktop: {
          base: [
            'flex-nowrap',
            'grid',
            'grid-cols-6',
            'px-desk-global-grid-margin',
            'py-desk-padding-micro-y',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

export default function CheckoutHeader({ fields }: CheckoutHeaderProps) {
  const { base, linkwrapper, deskimagewrapper, mobimagewrapper, iconWrapper, divWrapper } =
    checkoutheader({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  const isEE = useExperienceEditor();
  return (
    <div className={base()} id="checkoutHeader">
      {fields?.link && (
        <div className={divWrapper()}>
          <LinkHelper field={fields?.link} className={linkwrapper()}>
            <>
              <IconHelper icon={'chevron-left'} className={iconWrapper()} />
              {!isEE && fields?.link?.value?.text && <span>{fields?.link?.value?.text}</span>}
            </>
          </LinkHelper>
        </div>
      )}
      <div className={deskimagewrapper()}>
        <LinkHelper field={fields?.link as LinkField} hideLinkInEE={true}>
          <NextImage field={fields?.desktopLogo} />
        </LinkHelper>
      </div>
      <div className={mobimagewrapper()}>
        <LinkHelper field={fields?.link as LinkField} hideLinkInEE={true}>
          <NextImage field={fields?.mobileLogo} />
        </LinkHelper>
      </div>
    </div>
  );
}
