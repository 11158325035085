// Define types for the variables

import { SiteNameUppercase } from 'components/helpers/Constants';

type GenerateGraphQueryParams = {
  site: SiteNameUppercase;
  templateValue: string;
  pathValue: string;
  pageSize?: number;
  endCursor?: string;
};

// Function to generate the GraphQL query
export const generateGraphQuery = ({
  site,
  templateValue,
  pathValue,
  pageSize = 20,
  endCursor = '',
}: GenerateGraphQueryParams): string => {
  return `
    query LookupsSearch(
      $pageSize: Int = ${pageSize}
      $endCursor: String = "${endCursor}"
    ) {
      lookups: search(
        where: {
          name: "_templates"
          value: "${templateValue}"
        }
        orderBy: { direction: ASC, name: "Items" }
        first: $pageSize
        after: $endCursor
      ) {
        lookupList: results {
          lookupName: displayName
          items: field(name: "Items") {
            list: jsonValue
          }
          children {
            results {
              fields(ownFields: true) {
                jsonValue
              }
            }
          }
        }
      }
      ${site}: search(
        where: {
          name: "_path"
          value: "${pathValue}"
        }
        orderBy: { direction: ASC, name: "Items" }
        first: $pageSize
        after: $endCursor
      ) {
        lookupList: results {
          lookupName: displayName
          items: field(name: "Items") {
            list: jsonValue
          }
          children {
            results {
              fields(ownFields: true) {
                jsonValue
              }
            }
          }
        }
      }
      countries_region: item(
        path: "{B3D4DC9F-33D1-40BB-96A1-FB211CA47E0B}"
        language: "en"
      ) {
        countries: children {
          total
          results {
            code: field(name: "CountryCode") {
              value
            }
            name: field(name: "CountryName") {
              value
            }
            ... on CountryRegion {
              regions {
                ... on MultilistField {
                  targetItems {
                    code: field(name: "StateCode") {
                      value
                    }
                    name: field(name: "StateName") {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};
