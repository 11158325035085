export const HideLinkFromStoreNavigation = (id: string) => {
  const idSelector = id.startsWith('#') ? `store-${id}` : `#store-${id}`;
  const parentElement = document?.querySelector('.section-selector') as HTMLElement;
  // Check if the parent element exists
  if (parentElement && idSelector) {
    const mainElement = parentElement?.querySelector(idSelector) as HTMLElement;
    // Find the child div element to hide
    if (mainElement) {
      mainElement.style.display = 'none';
    }
  }
};
export const ShowLinkFromStoreNavigation = (id: string) => {
  const idSelector = id.startsWith('#') ? `store-${id}` : `#store-${id}`;
  const parentElement = document?.querySelector('.section-selector') as HTMLElement;
  // Check if the parent element exists
  if (parentElement && idSelector) {
    const mainElement = parentElement?.querySelector(idSelector) as HTMLElement;
    // Find the child div element to hide
    if (mainElement) {
      mainElement.style.display = 'block';
    }
  }
};
