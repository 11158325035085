import React, { useContext } from 'react';
import SplideSlider from 'components/helpers/SplideSlider';
import {
  ComponentRendering,
  Field,
  ImageField,
  LinkField,
  NextImage,
  Placeholder,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'components/helpers/IconHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import useDictionary from 'src/hooks/useDictionary';
import { tv } from 'tailwind-variants';
import { useTheme } from 'lib/context/ThemeContext';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import ComponentContext from 'lib/context/ComponentContext';
import { createOrder, retrieveOrder } from 'src/redux/ocCurrentOrder';
import { unwrapResult } from '@reduxjs/toolkit';
import { DeliveryFee } from 'src/pages/api/store/get-store-details';
import { FulfillmentType } from 'components/helpers/Constants';
import { DateTime } from 'luxon';
export const StoreDetailVariants = tv(
  {
    slots: {
      base: 'container mx-auto',
      tabsWrapper: 'container mx-auto flex justify-center items-center flex-wrap',
      heading: 'w-fit text-color-text-dark',
      subHeading: 'w-fit text-color-text-dark uppercase',
      headingWrapper: '',
      myStoreIcon:
        '[&>svg>*]:stroke-color-text-brand-1 [&>svg>*]:stroke-[3.5] h-[26px] w-[26px] [&_svg]:max-w-[26px] [&_svg]:max-h-[26px] mr-3',
      tabLink:
        'p-2 text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold',
      storeDetailWrapper: '',
      storeText: 'text-color-text-brand-1 h-fit',
      iconWrapper: 'flex h-fit',
      icon: '[&_svg_path]:fill-text-color-text-brand-1 h-6 w-6 [&_svg]:max-w-6 [&_svg]:max-h-6 mr-3',
      sliderWrapper:
        ' [&_button]:h-6 [&_button]:w-6 [&_button]:bg-color-background-white [&_button]:!opacity-100 [&_svg]:!fill-color-background-brand-1 [&_svg]:!max-w-3 [&_svg]:!max-h-3',
      sliderImage: 'w-full object-cover',
      offerImage: 'w-full',
      rightContentWrapper: '',
      shopButton:
        'rounded-desk-global-radius-small bg-color-text-brand-1 h-fit w-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark',
      storeDetailHeading: 'font-primary text-color-text-dark',
      leftContentWrapper: 'flex flex-col',
      storeDetailLink:
        'hover:text-color-brand-tertiary-3-base group w-fit focus:text-color-brand-tertiary-3-base text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold flex items-center',
      firstStoreDetailLink:
        'hover:text-color-brand-tertiary-3-base w-fit group focus:text-color-brand-tertiary-3-base block text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold flex items-center',
      linkIcon:
        'w-4 h-4 [&_svg]:max-w-4 [&_svg]:max-h-4 group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:fill-color-brand-tertiary-3-base [&>svg>*]:fill-color-text-brand-1',
      storeDetailDescription:
        'font-primary text-color-text-dark text-body-large-regular font-body-large-regular leading-body-large-regular',
    },
    variants: {
      size: {
        desktop: {
          linkIcon: 'ml-desk-space-between-micro-horizontal',
          shopButton:
            'mt-0 rounded-desk-global-radius-small text-color-text-white text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y ml-desk-margin-loose-right mb-0',
          firstStoreDetailLink: 'mb-desk-margin-tight-bottom',
          leftContentWrapper: 'gap-desk-margin-loose-bottom mb-0',
          storeDetailHeading:
            'mb-desk-margin-micro-bottom text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold',
          storeDetailDescription: '',
          rightContentWrapper: 'flex gap-desk-margin-base-right pr-desk-margin-base-right',
          offerImage: 'mx-0 max-w-[233px] min-w-[233px] mt-0 max-h-[312px] min-h-[312px]',
          base: 'py-desk-padding-tight-y',
          tabsWrapper: 'py-desk-padding-micro-y gap-desk-space-between-base-horizontal',
          sliderWrapper: 'w-full h-full max-w-[518px] max-h-[371px]',
          storeDetailWrapper: 'flex justify-between mt-desk-margin-loose-bottom',
          iconWrapper: 'mt-0 ml-desk-margin-loose-right mb-0 ',
          headingWrapper: 'flex flex-wrap items-center',
          sliderImage: 'min-w-[518px] min-h-[371px] max-w-[518px] max-h-[371px]',
          storeText:
            'font-primary text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold ',
          heading:
            'font-primary mb-0 text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold tracking-heading-desk-xLarge-bold',
          subHeading:
            'mt-desk-margin-tight-bottom w-full order-4 font-primary  text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold tracking-heading-desk-medium-bold',
        },
        mobile: {
          linkIcon: 'ml-mob-space-between-micro-horizontal',
          sliderImage: 'min-h-[337px] h-auto w-full max-h-[338px]',
          shopButton:
            'mt-mob-margin-loose-bottom rounded-mob-global-radius-small px-mob-component-button-condensed-padding-x py-mob-component-button-condensed-padding-y mb-mob-margin-loose-bottom text-color-text-white block text-body-large-bold font-body-large-bold leading-body-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y',
          firstStoreDetailLink: 'mb-mob-margin-tight-bottom',
          leftContentWrapper: 'mb-[52px] gap-mob-margin-loose-bottom',
          storeDetailHeading:
            'mb-mob-margin-micro-bottom text-heading-mob-small-bold font-heading-mob-small-bold leading-heading-mob-small-bold',
          storeDetailDescription: '',
          storeDetailWrapper: 'mt-mob-margin-loose-bottom',
          offerImage: 'max-h-[479px] mt-mob-margin-base-right',
          sliderWrapper: 'w-full',
          storeText:
            'text-color-text-brand-1 h-fit font-primary text-body-large-bold font-body-large-bold leading-body-large-bo',
          base: 'p-mob-padding-tight-y',
          tabsWrapper:
            'px-mob-global-grid-margin py-mob-padding-micro-y gap-mob-space-between-base-horizontal',
          heading:
            'font-primary mb-mob-margin-tight-bottom text-heading-mob-xLarge-bold font-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold tracking-heading-mob-xLarge-bold',
          iconWrapper: 'mt-mob-margin-loose-bottom mb-mob-margin-loose-bottom',
          subHeading:
            'font-primary  text-heading-mob-medium-bold font-heading-mob-medium-bold leading-heading-mob-medium-bold tracking-heading-mob-medium-bold',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);

// Interfaces
interface DataObj {
  addressText: Field<string>;
  franchiseText: Field<string>;
  hoursText: Field<string>;
  jobOpportunitiesText: Field<string>;
  myStoreText: Field<string>;
  myStoreIcon?: { jsonValue?: ImageField };
  offers: { jsonValue: LinkField };
  phoneText: Field<string>;
  shopThisStoreText: Field<string>;
  storeSuffix: Field<string>;
  closedText: Field<string>;
  jobOpportunitiesURL: {
    jsonValue: LinkField;
  };
}
interface StoreInfoProps {
  fields?: {
    data?: {
      datasource: DataObj;
    };
  };
}
interface StoreDetailsProps {
  marktingTileRfkId?: string;
  data?: DataObj;
  hours?: DayHours;
  deliveryFee?: DeliveryFee;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storeDetails?: any;
  rendering?: ComponentRendering;
}

//global store setting
export interface GlobalStoreSettings {
  data: {
    data: {
      getGlobalStoreSetting: {
        marketingTileRfkId: Field<string>;
        maximumAmount: Field<string>;
        minimumAmount: Field<string>;
        ecommerceDisabled: {
          jsonValue: {
            value: string;
          };
        };
        ecommerceDisabledRedirect?: LinkField;
      };
    };
  };
}
// Components
const StoreHeadings: React.FC<StoreDetailsProps> = ({ data }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const dispatch = useOcDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeDetails: any = useSitecoreContext()?.sitecoreContext?.route?.fields ?? {};
  const storeId: string = storeDetails?.storeID?.value;
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { heading, shopButton, subHeading, headingWrapper, iconWrapper, storeText, myStoreIcon } =
    StoreDetailVariants({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });
  return (
    <div className={headingWrapper()}>
      <Text
        field={{
          value: `${storeDetails?.storeName?.value || ''} ${data?.storeSuffix?.value || ''}`,
        }}
        tag="p"
        className={heading()}
      />
      {storeDetails?.storeType?.value === '2' && (
        <Text field={data?.franchiseText} tag="p" className={subHeading()} />
      )}
      {myStoreData?.storeId === storeId ? (
        <div className={iconWrapper()}>
          <NextImage className={myStoreIcon()} field={data?.myStoreIcon?.jsonValue} />
          <Text field={data?.myStoreText} tag="p" className={storeText()}></Text>
        </div>
      ) : (
        <>
          {data?.shopThisStoreText?.value && (
            <LinkHelper
              className={shopButton()}
              onClick={async (e) => {
                e.preventDefault();
                if (storeId) {
                  setcomponentContextData({
                    ...componentContextData,
                    storeId: storeId,
                    callBackFunctionAfterStoreChange: async () => {
                      const retrieveStoreOrder = await dispatch(retrieveOrder());
                      const responsesStoreOrder = unwrapResult(retrieveStoreOrder);
                      if (responsesStoreOrder === undefined) {
                        await dispatch(
                          createOrder(
                            localStorage.getItem('selected_fulfillment_Method') ===
                              FulfillmentType?.DFS
                          )
                        );
                        localStorage.removeItem('selected_fulfillment_Method');
                      } else {
                        await dispatch(retrieveOrder());
                      }
                    },
                  });
                }
              }}
              field={{ value: { href: '/', text: data?.shopThisStoreText?.value } }}
            />
          )}
        </>
      )}
    </div>
  );
};

const StoreDetails = ({
  data,
  hours,
  specialDays,
  rendering,
}: {
  data: DataObj;
  hours: DayHours;
  deliveryFee?: DeliveryFee;
  specialDays?: specialDateTypeArray;
  rendering?: ComponentRendering;
}) => {
  const { storeDetailWrapper } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeDetails: any = useSitecoreContext()?.sitecoreContext?.route?.fields ?? {};
  return (
    <div className={storeDetailWrapper()}>
      <LeftSideStoreContent
        data={data}
        hours={hours}
        specialDays={specialDays}
        storeDetails={storeDetails}
      />
      <RightSideStoreContent storeDetails={storeDetails} rendering={rendering} />
    </div>
  );
};

const RightSideStoreContent: React.FC<StoreDetailsProps> = ({ rendering }) => {
  const { rightContentWrapper } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  return (
    <div className={rightContentWrapper()}>
      <StoreSlider />
      {rendering && <Placeholder name="store-info-personalise" rendering={rendering} />}
    </div>
  );
};

const LeftSideStoreContent = ({
  data,
  hours,
  storeDetails,
  specialDays,
}: {
  data: DataObj;
  hours: DayHours;
  deliveryFee?: DeliveryFee;
  specialDays?: specialDateTypeArray;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storeDetails: any;
}) => {
  const {
    storeDetailHeading,
    storeDetailDescription,
    leftContentWrapper,
    storeDetailLink,
    linkIcon,
    firstStoreDetailLink,
  } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { getDictionaryValue } = useDictionary();
  const { themeNameUpper } = useTheme();
  const showSpecialHours = (dateTimeObject: { [key: string]: string }) => {
    if (!dateTimeObject) return null;

    const now = DateTime.local();

    const formatTime = (time: string) => DateTime.fromFormat(time, 'HH:mm:ss').toFormat('hh:mm a');
    const formatSpecialDate = (date: string) =>
      DateTime.fromISO(date, { zone: 'utc' }).toFormat('MM/dd');

    const { message, specialDate, storeOpenHour, storeCloseHour, startDate, endDate } =
      dateTimeObject;
    const isClosed = storeOpenHour === storeCloseHour;

    const startDateTime = DateTime.fromISO(startDate);
    const endDateTime = DateTime.fromISO(endDate);

    if (now >= startDateTime && now <= endDateTime) {
      return (
        <>
          <p
            className={storeDetailDescription({
              className: '!font-body-large-bold',
            })}
          >
            {message}&nbsp;
            {'(' + formatSpecialDate(specialDate) + '):'} &nbsp;
            {isClosed ? (
              <Text field={data?.closedText} tag="span" />
            ) : (
              `${formatTime(storeOpenHour)} - ${formatTime(storeCloseHour)}`
            )}
          </p>
        </>
      );
    }
    return <></>;
  };
  return (
    <div className={leftContentWrapper()}>
      <div>
        <Text field={data?.hoursText} tag="p" className={storeDetailHeading()} />
        {Object.keys(hours)?.map((day) => {
          if (!hours[day]) {
            return;
          }
          return (
            <Text
              key={day} // Added key prop for optimization
              field={{ value: `${day}: ${hours[day]}` }} // Access hours using brackets
              tag="p"
              className={storeDetailDescription()}
            />
          );
        })}
        {Array.isArray(specialDays) &&
          specialDays?.map((day: specialDateType) => <>{showSpecialHours(day)}</>)}
      </div>
      <div>
        <Text field={data?.addressText} tag="p" className={storeDetailHeading()} />
        <Text
          field={{ value: getDictionaryValue(themeNameUpper) }}
          tag="p"
          className={storeDetailDescription()}
        />
        {storeDetails?.street1 && (
          <Text field={storeDetails?.street1} tag="p" className={storeDetailDescription()} />
        )}
        <Text
          field={{
            value: `${storeDetails?.city?.value || ''}, ${storeDetails?.state?.value || ''} ${
              storeDetails?.zip?.value || ''
            }`,
          }}
          tag="p"
          className={storeDetailDescription()}
        />
      </div>
      <div>
        <Text field={data?.phoneText} tag="p" className={storeDetailHeading()} />
        <LinkHelper
          field={{ value: { href: `tel:${storeDetails?.phone?.value}` } }}
          className={storeDetailLink()}
        >
          {storeDetails?.phone && <Text field={storeDetails?.phone} tag="p" />}
        </LinkHelper>
      </div>
      <div>
        {data?.offers?.jsonValue?.value?.text && data?.offers?.jsonValue?.value?.href && (
          <LinkHelper field={data?.offers?.jsonValue} className={firstStoreDetailLink()}>
            <>
              <Text field={{ value: data?.offers?.jsonValue?.value?.text }} tag="p" />
              <IconHelper className={linkIcon()} icon={'chevron-right'} />
            </>
          </LinkHelper>
        )}
        {data?.jobOpportunitiesText?.value && (
          <LinkHelper
            field={{
              value: {
                href:
                  storeDetails?.storeType?.value !== '1'
                    ? storeDetails?.jobsurl?.value
                    : data?.jobOpportunitiesURL?.jsonValue?.value?.href,
                target:
                  storeDetails?.storeType?.value !== '1'
                    ? '_blank'
                    : data?.jobOpportunitiesURL?.jsonValue?.value?.target,
              },
            }}
            className={storeDetailLink()}
          >
            <>
              {!!(storeDetails?.storeType?.value !== '1'
                ? storeDetails?.jobsurl?.value
                : data?.jobOpportunitiesURL?.jsonValue?.value?.href) && (
                <>
                  <Text field={data?.jobOpportunitiesText} tag="p" />
                  <IconHelper className={linkIcon()} icon={'chevron-right'} />
                </>
              )}
            </>
          </LinkHelper>
        )}
      </div>
    </div>
  );
};

const StoreSlider: React.FC = () => {
  const { sliderImage, sliderWrapper } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeDetails: any = useSitecoreContext()?.sitecoreContext?.route?.fields ?? {};
  return (
    <div className={sliderWrapper()}>
      <SplideSlider
        options={{
          pagination: false,
          arrows: storeDetails?.images?.value?.split('|')?.length > 1,
          speed: 800,
          autoplay: true,
          interval: 5000,
          type: 'loop',
        }}
      >
        {storeDetails?.images?.value?.split('|')?.length > 0 &&
          storeDetails?.images?.value?.split('|').map((image: string, index: number) => {
            return (
              <NextImage
                key={index}
                className={sliderImage()}
                field={{
                  src: image,
                  width: '518',
                  height: '371',
                  alt: 'sliderImage',
                }}
              />
            );
          })}
      </SplideSlider>
    </div>
  );
};
interface DayHours {
  [key: string]: string;
}

interface DayInfo {
  dayOfWeek: {
    jsonValue: {
      name: string;
    };
  };
  openTime: {
    value: string;
  };
  closeTime: {
    value: string;
  };
}

function convertHours(data: DayInfo[]): DayHours {
  const day_mapping: { [key: string]: string } = {
    '1': 'Sun',
    '2': 'Mon',
    '3': 'Tue',
    '4': 'Wed',
    '5': 'Thu',
    '6': 'Fri',
    '7': 'Sat',
  };

  const hours: DayHours = {};
  data.forEach((result) => {
    const day = day_mapping[result?.dayOfWeek?.jsonValue?.name];
    const open_time = formatTime(result?.openTime?.value, true);
    const close_time = formatTime(result?.closeTime?.value, true);
    if (open_time != close_time) {
      hours[day] = `${open_time} - ${close_time}`;
    }
  });

  return hours;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getInOrder = (dayHours: any) => {
  const orderedDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const formattedDayHours: {
    [key: string]: string;
  } = {};
  let tempStartDay = '';
  let tempEndDay = '';

  orderedDays.forEach((day, index, array) => {
    if (index === 0) {
      tempStartDay = day;
      tempEndDay = day;
    } else {
      if (dayHours[day] === dayHours[tempEndDay]) {
        tempEndDay = day;
      } else {
        if (tempStartDay === tempEndDay) {
          formattedDayHours[tempStartDay] = dayHours[tempStartDay];
        } else {
          formattedDayHours[`${tempStartDay}-${tempEndDay}`] = dayHours[tempStartDay];
        }
        tempStartDay = day;
        tempEndDay = day;
      }
    }

    if (index === array.length - 1) {
      if (tempStartDay === tempEndDay) {
        formattedDayHours[tempStartDay] = dayHours[tempStartDay];
      } else {
        formattedDayHours[`${tempStartDay}-${tempEndDay}`] = dayHours[tempStartDay];
      }
    }
  });
  return formattedDayHours;
};
function formatTime(time: string, addAMandPM?: boolean): string {
  const [hours, minutes] = time.split(':');
  const formattedHours = parseInt(hours, 10) > 12 ? parseInt(hours, 10) - 12 : parseInt(hours, 10);
  const formattedHoursStr = formattedHours.toString().padStart(2, '0');
  return `${formattedHoursStr}:${minutes} ${
    addAMandPM ? (parseInt(hours, 10) > 12 ? 'PM' : 'AM') : ''
  }`;
}
interface specialDateType {
  [key: string]: string;
}
type specialDateTypeArray = specialDateType[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StoreInfo: React.FC<StoreInfoProps> = ({ fields, rendering }: any) => {
  const { base } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const dayResults: DayInfo[] = fields?.data?.storeData?.children.results?.[0].children.results;
  const specialDays: specialDateTypeArray =
    fields?.data?.storeData?.specialDays?.results[0]?.children?.results?.map(
      (specialDay: {
        fields?: {
          name: string;
          value: string;
        }[];
      }) => {
        const specialDays: { [key: string]: string } = {};
        specialDay?.fields?.forEach((fieldName: { name: string; value: string }) => {
          specialDays[fieldName.name] = fieldName?.value;
        });
        return specialDays;
      }
    );
  const hours: DayHours = getInOrder(convertHours(dayResults));

  return (
    <>
      {fields?.data?.datasource && (
        <div className={base()} id="general-information">
          <StoreHeadings data={fields?.data?.datasource} />
          <StoreDetails
            data={fields?.data?.datasource}
            specialDays={specialDays}
            hours={hours as DayHours}
            rendering={rendering}
          />
        </div>
      )}
    </>
  );
};

export default StoreInfo;
