import React, { ReactNode, useEffect, useRef } from 'react';
import IconHelper from './IconHelper';
import { tv } from 'tailwind-variants';

type WhiteGenericModalProps = {
  handleOutsideClick?: () => void;
  handleModalClose: () => void;
  children: React.ReactNode;
  onOutsideClick: () => void;
};

const whiteGenericModal = tv(
  {
    slots: {
      modalWrapper: 'absolute top-1/5 left-1/2 transform -translate-x-1/2 top-[50%]',
      modalBase:
        'w-full z-[2] fixed bg-color-scale-1-white flex flex-col justify-center items-center gap-2  rounded-desk-global-radius-medium shadow-privacyShadow absolute top-1/5 left-1/2 transform -translate-x-1/2',

      iconWrapper: 'flex justify-end absolute top-[8px] right-[8px]',
      closeIcon:
        'cursor-pointer [&_svg]:w-[32px] [&_svg]:h-[32px] [&>svg>*>*]:fill-color-brand-primary-1-base ',
    },

    variants: {
      size: {
        mobile: {
          modalWrapper: 'w-full max-w-[328px]',
          modalBase: 'py-mob-padding-tight-y px-mob-padding-tight-x gap-[24px]',
        },
        desktop: {
          modalWrapper: 'min-w-[526px]',
          modalBase: 'py-desk-padding-tight-y px-desk-padding-tight-x',
        },
      },
    },
  },
  {
    responsiveVariants: ['md'],
  }
);

const { modalWrapper, modalBase, iconWrapper, closeIcon } = whiteGenericModal({
  size: {
    initial: 'mobile',
    md: 'desktop',
  },
});

type OutsideClickProps = {
  children: ReactNode;
  onOutsideClick: () => void;
};

const OutsideClickHandler: React.FC<OutsideClickProps> = ({ children, onOutsideClick }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (
        wrapperRef.current &&
        target instanceof HTMLElement &&
        !wrapperRef.current?.contains(target as Node)
      ) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div ref={wrapperRef} className={modalWrapper()}>
      {children}
    </div>
  );
};

const WhiteGenericModal: React.FC<WhiteGenericModalProps> = ({
  handleModalClose,
  children,
  onOutsideClick,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (
        wrapperRef.current &&
        target instanceof HTMLElement &&
        !wrapperRef.current?.contains(target as Node)
      ) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <>
      <OutsideClickHandler onOutsideClick={handleModalClose}>
        <div className={modalBase()}>
          <button aria-label="modal" onClick={handleModalClose} className={iconWrapper()}>
            <IconHelper icon="close" className={closeIcon()} />
          </button>
          {children}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default WhiteGenericModal;
