export const GET_PRODUCT_IMAGE_QUERY = `
  query ProgramImagePublicLink($fileName: String!) {
    allM_Asset(
        where: { fileName_eq: $fileName }
    ) {
        results {
            id
            altText
            urls
        }
    }
}`;
