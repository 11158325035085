import React, { useEffect } from 'react';
import { Text, RichText, Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import {
  HideLinkFromStoreNavigation,
  ShowLinkFromStoreNavigation,
} from 'src/helpers/StoreLandingHelper';
export const StoreDetailVariants = tv(
  {
    slots: {
      heading:
        'text-color-text-black h-fit w-full text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold ',
      base: 'container mx-auto',
      contentWrapper: 'w-full bg-color-background-light flex justify-between',
      description:
        'text-body-large-regular font-body-large-regular leading-body-large-regular w-full flex flex-col gap-[25px]',
    },
    variants: {
      size: {
        desktop: {
          base: 'py-desk-padding-tight-y',
          heading: 'max-w-[336px]',
          contentWrapper:
            'flex-nowrap gap-desk-space-between-loose-vertical px-desk-padding-tight-x py-desk-padding-tight-y',
        },
        mobile: {
          base: 'p-mob-padding-tight-y',
          contentWrapper:
            'flex-wrap gap-mob-space-between-loose-vertical px-mob-padding-tight-x py-mob-padding-tight-y',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
interface AboutStoreProps {
  fields?: {
    data?: {
      datasource?: { aboutThisStoreText: Field<string> };
      storeData?: { aboutStore?: Field<string> };
    };
  };
}
const AboutStore = ({ fields }: AboutStoreProps): JSX.Element => {
  const { base, contentWrapper, heading, description } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeDetails: any = useSitecoreContext()?.sitecoreContext?.route?.fields ?? {};
  useEffect(() => {
    if (!storeDetails?.aboutstore?.value) {
      HideLinkFromStoreNavigation('about-store');
    } else {
      ShowLinkFromStoreNavigation('about-store');
    }
  }, [storeDetails?.aboutstore?.value]);
  return (
    <>
      {storeDetails?.aboutstore?.value && (
        <div className={base()} id="about-store">
          <div className={contentWrapper()}>
            <Text
              className={heading()}
              field={fields?.data?.datasource?.aboutThisStoreText}
              tag="p"
            />
            <RichText className={description()} field={storeDetails?.aboutstore} />
          </div>
        </div>
      )}
    </>
  );
};
export default AboutStore;
