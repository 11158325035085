import { tv } from 'tailwind-variants';

const myCartItems = tv(
  {
    slots: {
      base: ['w-full'],
      titleWrapper: [
        'flex',
        'flex-row',
        'gap-desk-margin-base-right',
        'items-center',
        'justify-start',
        'self-stretch',
        //'shrink-0',
        'relative',
      ],
      title: ['text-color-text-black'],
      linkWithUnderline: [
        'text-left',
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline',
      ],
      itemWrapper: [
        'bg-color-background-white',
        'border-solid',
        'border-color-border-light',
        'border',
        'flex',
        'flex-row',

        'items-start',
        'justify-start',
        'self-stretch',
        //'shrink-0',
        'relative',
      ],
      cardWrapper: ['grid'],
      cardInfoWrapper: ['flex', 'flex-col', 'items-start', 'justify-start', 'relative'],
      cardTitleQtyContainer: [
        'flex',
        'flex-row',
        'gap-[53px]',
        'items-start',
        'justify-start',
        'self-stretch',
        //'shrink-0',
        'relative',
      ],
      cartTitle: [
        'text-color-text-black',
        'text-left',
        'font-body-large-bold',
        'text-body-large-bold',
        'leading-body-large-bold',
      ],
      cartTitleRed: ['text-system-red'],
      hideInMobile: ['hidden', 'md:block'],
      centerInMobile: [],
      itemImages: ['max-w-[104px]', 'h-auto', 'object-contain', 'max-h-[104px]'],
      productImageWrapper: ['flex', 'h-[104px]', 'relative'],
      productWeight: [
        'absolute',
        'pr-3',
        'pl-1',
        'right-[-25%]',
        'top-[55%]',
        '-translate-y-1/2',
        'h-auto',
        'text-left',
        'bg-color-brand-primary-1-base',
        'text-color-text-white',
        'product-weight-clip',
      ],
      priceWrapper: ['flex flex-row items-center justify-center self-center relative'],
    },
    variants: {
      size: {
        mobile: {
          base: ['pt-mob-padding-tight-y', 'pb-mob-padding-tight-y'],
          titleWrapper: ['py-mob-padding-micro-x'],
          title: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          cardWrapper: ['grid-cols-2'],
          itemWrapper: [
            'pt-mob-padding-tight-y',
            'pr-mob-padding-tight-x',
            'pb-mob-padding-tight-y',
            'pl-mob-padding-tight-x',
            'gap-mob-margin-tight-bottom',
          ],
          cardInfoWrapper: ['w-[136px]', 'gap-0'],
          cardTitleQtyContainer: ['justify-center'],
          centerInMobile: ['max-lg:flex-col', 'max-lg:items-center'],
          priceWrapper: ['justify-between'],
          cartTitle: ['w-fit'],
        },
        desktop: {
          base: ['pt-desk-padding-tight-y', 'pb-desk-padding-tight-y'],
          titleWrapper: ['py-desk-padding-micro-x'],
          title: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          cardWrapper: ['grid-cols-1'],
          itemWrapper: [
            'pt-desk-padding-tight-y',
            'pr-desk-padding-tight-x',
            'pb-desk-padding-tight-y',
            'pl-desk-padding-tight-x',
            'gap-desk-margin-loose-right',
          ],
          cardInfoWrapper: ['w-auto', 'gap-desk-margin-loose-bottom', 'w-full'],
          hideInMobile: ['max-w-[325px]'],
          cardTitleQtyContainer: ['justify-start', 'w-full', 'justify-between'],
          priceWrapper: ['justify-center', 'gap-desk-global-spacing-spacing-12'],
          cartTitle: ['max-w-[204px]'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
export default myCartItems;
