import { tv } from 'tailwind-variants';

export const addATipVariants = tv(
  {
    slots: {
      base: ['paymentWrapper py-desk-padding-tight-y', 'px-desk-padding-micro-x'],
      delveryTipping: [
        'delvery-tipping-content relative mt-desk-global-spacing-spacing-4 flex flex-col gap-desk-margin-base-bottom w-full border-b-[1px] pb-desk-global-spacing-spacing-4 border-color-border-mid',
      ],
      title: ['text-body-large-bold font-body-large-bold leading-body-large-bold'],
      tipCard: [
        'py-desk-global-spacing-spacing-4 bg-color-background-white px-desk-global-spacing-spacing-3 border-y-2 border-color-border-mid w-full flex items-center justify-center',
      ],
      tipLabel: [''],
      tipField: [
        'outline-none',
        'border-2',
        'text-color-text-black',
        'placeholder:text-color-text-black',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        '!pl-5',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
        'appearance-none',
        'bg-color-scale-1-white',
      ],
      currencyLabel: ['input-group-addon absolute top-[27%] left-[1.5%]'],
      priceError: ['text-system-red'],
      buttonWrapper: [''],
      customTipWrapper: ['flex w-full gap-desk-global-spacing-spacing-5 '],
      tipButton: [
        '!w-max !text-body-large-bold !font-body-large-bold !leading-body-large-bold !py-desk-global-spacing-spacing-3',
      ],
      tipContainer: [
        'w-full flex items-center justify-center text-center bg-color-background-white',
      ],
      customTip: [
        'flex w-full items-center justify-center border-y-2 border-l-2 border-r-2 rounded-r border-color-border-mid py-desk-global-spacing-spacing-4 px-desk-global-spacing-spacing-3 ',
      ],
      bottomWrapper: ['delivery-tipping-footer flex flex-col gap-desk-margin-base-bottom'],
      autoshipTip: 'flex relative cursor-pointer w-fit',
      autoshipTipCheckboxIcon:
        '[&>svg>*>*]:fill-color-background-white absolute [&>svg]:w-[24px] [&>svg]:h-[24px] checked:visible',
      autoshipTipCheckbox:
        'w-[24px] h-[24px] outline-color-border-mid cursor-pointer border appearance-none bg-color-background-white peer checked:bg-color-brand-primary-1-base',
      heading: [
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
      ],
      headingLarge: [
        'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      ],
      loader: [
        'absolute h-full w-full top-0 left-0 bg-black opacity-25 flex justify-center items-center border-b-[1px]',
      ],
      labelTitle: [''],
    },
    compoundSlots: [
      {
        slots: ['tipLabel', 'tipField'],
        class: ['text-body-large-regular font-body-large-regular leading-body-large-regular'],
      },
    ],
    variants: {
      isCollapse: {
        true: {
          base: ['bg-color-background-white', '!px-0'],
          labelTitle: [
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          ],
        },
        false: {
          base: ['bg-color-border-light'],
          labelTitle: [
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          ],
        },
      },
      device: {
        mobile: {
          autoshipTip: ['gap-mob-space-between-tight-horizontal'],
          tipField: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
          tipLabel: [''],
        },
        desktop: {
          autoshipTip: ['gap-desk-space-between-tight-horizontal'],
          tipField: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
          tipLabel: ['mx-2 hover-bg-'],
          tipCard: [
            'hover:cursor-pointer hover:bg-color-brand-primary-1-base hover:text-color-text-white',
          ],
          customTip: [
            'hover:cursor-pointer hover:bg-color-brand-primary-1-base hover:text-color-text-white',
          ],
        },
      },
      disableTip: {
        true: {
          base: ['pb-[0px]'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
export default addATipVariants;
