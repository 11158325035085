const MinusSVGIcon = () => (
  <svg
    aria-label="minus"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_7305_50207)">
      <path d="M19 13H5V11H19V13Z" fill="rgb(var(--colors-color-text-brand-1))" />
    </g>
    <defs>
      <clipPath id="clip0_7305_50207">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PlusSVGIcon = () => (
  <svg
    aria-label="plus"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_7305_50207)">
      <path
        d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
        fill="rgb(var(--colors-color-text-brand-1))"
      />
    </g>
    <defs>
      <clipPath id="clip0_7305_50207">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { PlusSVGIcon, MinusSVGIcon };
