import { SearchResultsAccordionFacets } from '@sitecore-search/ui';
import { SearchResponseFacet, useSearchResultsSelectedFilters } from '@sitecore-search/react';
import { PspFacet } from './PspFacet';
import { PspFacetMerged } from './PspFacetMerged';
import { SearchResultsWidget } from '@sitecore-search/react/dist/esm/types';
import { FacetSetting, FacetSettingMap } from 'components/Search/ProductListing';
import useDictionary from 'src/hooks/useDictionary';
import groupBy from 'lodash/groupBy';
import { Cross1Icon } from '@radix-ui/react-icons';
import searchResultsTailwind from './SearchResultsTailwind';
// import ButtonHelper from 'components/helpers/Button';
import ModalWrapper from 'components/helpers/ModalWrapper';
import { useEffect, useState } from 'react';
import { useBreakpoints } from 'src/utils/breakpoints';

export interface FacetSectionProps {
  actions: SearchResultsWidget['ActionProps'];
  facets: SearchResponseFacet[];
  facetSettings: FacetSettingMap;
}

type FacetDataWrapper = {
  facetId: string;
  facet: SearchResponseFacet;
  settings?: FacetSetting;
};

type MergedFacetDataWrapper = {
  facetId: string;
  facetGrouping: SearchResponseFacet[];
  settings: FacetSetting;
};

type MixedFacetArray = (FacetDataWrapper | MergedFacetDataWrapper)[];

export const PspFacetSection = ({ actions, facets, facetSettings }: FacetSectionProps) => {
  const dictionary = useDictionary();
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();

  // Merged facets are facet settings that have some mergedFacets
  const mergedFacetSettings = Object.values(facetSettings)
    .filter((x) => x && x?.mergedFacets.length > 0)
    .map((x) => x as FacetSetting);

  // Selected facets are expanded by default
  const defaultExpandedFacetsIds = new Set(selectedFacetsFromApi.map((x) => x.facetId));

  // Both normal facets and merged facets
  const mixedFacets = getMixedFacets(
    facets,
    facetSettings,
    mergedFacetSettings,
    defaultExpandedFacetsIds
  );

  // Group selected facets together
  const groupedSelectedFacets = groupBy(selectedFacetsFromApi, (x) => {
    const mergedFacetSetting = mergedFacetSettings.find((mfs) =>
      mfs.mergedFacets.includes(x.facetId)
    );
    // For merged facets, use the parent name
    // For merged facets, we always have a setting, so we can use that facet name
    if (mergedFacetSetting) {
      return mergedFacetSetting.facetName;
    }

    return x.facetId;
  });
  const {
    sitecoreLeftSection,
    filterTitle,
    clearText,
    selectedFilter,
    facetRoot,
    selectedFacetCategory,
    selectedFilterButton,
    selectedFiltersWrapper,
    selectedFilterFacet,
    filterButton,
    filterModal,
  } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // const currentWidth = useScreenWidth();

  const { isMobile } = useBreakpoints();

  const WrappedFacetSection = () => {
    if (isMobile && !showFacetInModal) {
      return <div className="no-filter-on-mobile"></div>;
    }
    return (
      <>
        <div className="flex justify-between mb-mob-margin-tight-bottom">
          {/* <Text tag="p" className="contentTitle" field={{ value: 'Filter' }} /> */}
          <p className={filterTitle()}>Filter</p>
          {Object.keys(groupedSelectedFacets).length > 0 && (
            <button
              aria-label="clear filter"
              onClick={actions.onClearFilters}
              className={clearText()}
            >
              {dictionary.getDictionaryValue('ClearAllFiltersPLP', 'Clear Filters')}
            </button>
          )}
        </div>

        {/* Selected Facets being render on top of the section */}
        <ul className={selectedFiltersWrapper()}>
          {mixedFacets.map((mf) => {
            const facetId = mf.facetId;
            const facetValues = groupedSelectedFacets[facetId];
            if (!facetValues) {
              return null;
            }
            const settings = facetSettings[facetId];

            return (
              <li key={`${facetId}`} className={selectedFilter()}>
                {/* facet category label */}
                <div className={selectedFacetCategory()}>
                  {settings?.facetLabelOverride || facetValues[0].facetLabel}
                </div>

                <ul>
                  {facetValues.map((selectedFacet) => {
                    const textValue = settings?.mergedFacets.length
                      ? selectedFacet.facetLabel
                      : selectedFacet.valueLabel;
                    return (
                      <li key={selectedFacet.facetId + textValue}>
                        <button
                          aria-label="remove filter"
                          // Remove filter button
                          onClick={() => actions.onRemoveFilter(selectedFacet)}
                          className={selectedFilterButton()}
                        >
                          <span className={selectedFilterFacet()}>
                            {settings?.facetValueOverrideMap[textValue ?? ''] || textValue}
                          </span>
                          <Cross1Icon className={selectedFilterFacet()} />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>

        <SearchResultsAccordionFacets
          defaultFacetTypesExpandedList={[...defaultExpandedFacetsIds]}
          onFacetValueClick={actions.onFacetClick}
          className={facetRoot({ className: '!border-b-[0px]' })}
        >
          {mixedFacets.map((facetWrapper) => {
            const mergedFacetWrapper = facetWrapper as MergedFacetDataWrapper;
            const mergedFacets = mergedFacetWrapper?.facetGrouping;
            if (mergedFacets) {
              return (
                <PspFacetMerged
                  key={mergedFacetWrapper.facetId}
                  facets={mergedFacets}
                  actions={actions}
                  settings={mergedFacetWrapper.settings}
                />
              );
            } else {
              const facet = (facetWrapper as FacetDataWrapper)?.facet;
              return (
                <PspFacet
                  key={facetWrapper.facetId}
                  facet={facet}
                  actions={actions}
                  settings={facetWrapper.settings}
                />
              );
            }
          })}
        </SearchResultsAccordionFacets>
      </>
    );
  };

  const [showFacetInModal, setShowFacetInModal] = useState(false);

  const handleFilterClick = () => {
    // open filter section in modal.
    setShowFacetInModal(true);
  };

  useEffect(() => {
    if (showFacetInModal) {
      document.body.classList.add('overflow-hidden');
      const filterTarget = document.getElementById('filter-button');
      if (filterTarget) {
        const offset = 20; // Set your desired offset here
        const topPos = filterTarget.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: topPos, behavior: 'smooth' });
      }
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    const ele = document?.getElementById('localOffers');
    ele?.contains(ele?.getElementsByTagName('div')?.[0]) &&
      ele?.removeChild(ele?.getElementsByTagName('div')?.[0]);
  }, [showFacetInModal]);

  return (
    <section className={sitecoreLeftSection({ className: 'relative' })}>
      <button
        aria-label="filter button"
        id="filter-button"
        className={filterButton()}
        onClick={handleFilterClick}
      >
        Filters
      </button>

      {showFacetInModal && (
        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={() => {
            setShowFacetInModal(false);
          }}
          showModal={showFacetInModal}
          customPopup={true}
          popupBG="bg-transparent"
          popupSpacing={filterModal()}
          popupWidth="w-full"
        >
          <WrappedFacetSection />
        </ModalWrapper>
      )}
      {!showFacetInModal && <WrappedFacetSection />}
    </section>
  );
};

function getMixedFacets(
  facets: SearchResponseFacet[],
  facetSettings: FacetSettingMap,
  mergedFacetSettings: FacetSetting[],
  defaultExpandedFacetsIds: Set<string>
) {
  const mixedFacets: MixedFacetArray = [];

  facets.forEach((searchFacet) => {
    const facetName = searchFacet.name;

    const mergedFacetSetting = mergedFacetSettings.find((mfs) =>
      mfs.mergedFacets.includes(facetName)
    );

    // If this is a merged facet.
    if (mergedFacetSetting?.mergedFacets.includes(facetName)) {
      const parentFacetName = mergedFacetSetting.facetName;
      const facetSetting = facetSettings[parentFacetName];

      // See if we already created a facet wrapper
      let mergedFacetWrapper = mixedFacets.find(
        (f) => f.facetId === parentFacetName
      ) as MergedFacetDataWrapper;

      // If not, create one now
      if (!mergedFacetWrapper) {
        mergedFacetWrapper = {
          facetId: parentFacetName,
          facetGrouping: [],
          settings: mergedFacetSetting,
        };
        // and add it to the list
        mixedFacets.push(mergedFacetWrapper);
      }
      // Add the subfacet to the grouping
      mergedFacetWrapper.facetGrouping.push(searchFacet);

      if (
        facetSetting?.facetDefaultOpen ||
        // If any of the facets are selected, also add the parent merged facet to expanded list
        mergedFacetWrapper.facetGrouping.some((x) => defaultExpandedFacetsIds.has(x.name))
      ) {
        defaultExpandedFacetsIds.add(parentFacetName);
      }

      // The subfacets of the merged facets always need to be expanded, otherwise they won't appear
      // This is a quirk of us doing a fake facet
      mergedFacetWrapper.facetGrouping.forEach((fg) => {
        defaultExpandedFacetsIds.add(fg.name);
      });
    } else {
      const facetSetting = facetSettings[facetName];
      // Normal facet, just add it
      mixedFacets.push({
        facetId: facetName,
        facet: searchFacet,
        settings: facetSetting,
      });
      if (facetSetting?.facetDefaultOpen) {
        defaultExpandedFacetsIds.add(facetName);
      }
    }
  });
  return mixedFacets;
}
