import { NextImage, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'components/helpers/IconHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { tv } from 'tailwind-variants';
import { StoreLocatorConfig, StoreProps } from './GenericStoreLocator';
import ComponentContext from 'lib/context/ComponentContext';
import { useRouter } from 'next/router';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { createOrder, retrieveOrder } from 'src/redux/ocCurrentOrder';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  closedText,
  FulfillmentType,
  GTM_EVENT,
  SiteNameUppercase,
} from 'components/helpers/Constants';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { DateTime } from 'luxon';
const storeVariants = tv(
  {
    slots: {
      base: 'flex flex-col',
      markerWrapper: 'relative h-[40px] w-[40px] cursor-pointer',
      serviceList: 'font-primary text-[18px] font-[400] text-color-text-black leading-[28px]',
      loadMoreButton: 'w-full flex justify-center',
      storesWrapper: 'flex-col flex',
      mainWrapper: '',
      storeText: 'text-color-text-brand-1 h-fit ',
      storeAddress:
        'hover:no-underline font-primary text-heading-desk-large-regular leading-heading-desk-large-regular font-heading-desk-large-regular',
      shopButton:
        'rounded-desk-global-radius-small flex justify-center min-w-[221px] bg-color-text-brand-1 h-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark',
      loadMore:
        'rounded-desk-global-radius-small bg-color-text-brand-1 h-fit w-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark text-body-large-bold leading-body-large-bold font-body-large-bold',
      storeDetailWrapper: 'flex w-full',
      storeActionWrapper: 'flex flex-col ',
      buttonWrapper: 'flex flex-col ',
      openStoreTimingText:
        'w-max text-heading-desk-medium-regular leading-heading-desk-medium-regular font-heading-desk-medium-regular',
      resultText: 'font-primary inline-block',
      resultTextBold: 'font-primary',
      storeDetails: 'flex flex-col',
      storeAddressWrapper: '',
      iconWrapper: 'flex h-fit',
      storePawImage: 'min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]',
      buttonWithArrow:
        'flex items-center text-heading-desk-medium-bold font-heading-desk-medium-bold font-primary leading-heading-desk-medium-bold hover:text-color-brand-primary-1-base focus:text-color-brand-primary-1-base',
      storeName:
        'cursor-pointer font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      storeWrapper:
        'bg-color-background-white hover:bg-color-store-hover-background focus:bg-color-store-hover-background w-full flex border-b border-b-color-scale-5-mid border-solid	',
      imageLabel:
        'font-primary absolute left-[49%] -translate-x-[75%] text-white bottom-[2px] text-[14px] font-[700]',
      storePageArrow:
        'ml-1 [&>svg>*]:stroke-color-text-brand-1 [&>svg>path]:fill-color-text-brand-1 h-[16px] w-[16px] [&_svg]:max-w-[16px] [&_svg]:max-h-[16px]',
      myStoreIcon:
        '[&>svg>*]:stroke-color-text-brand-1 [&>svg>*]:stroke-[3.5] h-[26px] w-[26px] [&_svg]:max-w-[26px] max-w-[26px] [&_svg]:max-h-[26px] max-h-[26px] mr-3',
    },
    variants: {
      size: {
        desktop: {
          storesWrapper: 'gap-desk-margin-loose-bottom',
          base: 'gap-desk-margin-base-bottom',
          iconWrapper: 'mt-0 min-w-[221px] justify-end mb-0 ',
          shopButton:
            'mt-0 w-fit max-w-max min-w-max rounded-desk-global-radius-small text-color-text-white text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y mb-0',
          loadMore:
            'mt-0 rounded-desk-global-radius-small text-color-text-white px-desk-component-button-condensed-padding-x py-desk-component-button-condensed-padding-y mb-0',
          storeDetails: 'gap-desk-margin-tight-bottom',
          storeText:
            'font-primary text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold ',
          storeAddressWrapper: 'mt-3',
          buttonWrapper: 'gap-desk-margin-base-bottom items-end',
          resultText:
            'inline-block text-heading-desk-large-regular font-heading-desk-large-regular leading-heading-desk-large-regular',
          resultTextBold:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          storeActionWrapper: 'mt-0 gap-desk-space-between-base-vertical items-end',
          storeDetailWrapper: 'justify-between flex-row gap-desk-margin-tight-bottom',
          storeWrapper:
            'flex-row rounded-desk-global-radius-medium gap-desk-margin-base-right py-desk-padding-micro-y px-desk-padding-micro-x',
        },
        mobile: {
          storesWrapper: 'gap-mob-margin-loose-bottom',
          storeDetails: 'gap-mob-margin-tight-bottom',
          base: 'gap-mob-margin-base-bottom',
          buttonWrapper: 'gap-mob-margin-base-bottom',
          storeAddressWrapper: 'mt-3',
          storeActionWrapper: 'mt-4 gap-mob-space-between-base-vertical -mt-2',
          iconWrapper: 'mt-mob-margin-loose-bottom mb-mob-margin-loose-bottom',
          shopButton:
            'rounded-mob-global-radius-small px-mob-component-button-condensed-padding-x py-mob-component-button-full-padding-y text-color-text-white block text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y w-full',
          loadMore:
            'rounded-mob-global-radius-small text-color-text-white block px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y',
          storeText:
            'text-color-text-brand-1 h-fit font-primary text-body-large-bold font-body-large-bold leading-body-large-bo',
          resultText: 'hidden',
          resultTextBold:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          storeDetailWrapper: 'flex-col gap-mob-margin-tight-bottom',
          storeWrapper:
            'flex-col rounded-mob-global-radius-medium gap-mob-margin-base-right py-mob-padding-micro-x px-mob-padding-micro-x',
        },
      },
      isCareerVariant: {
        true: { storeDetails: 'w-full' },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

interface StoreData {
  storeid: string;
  LatPos: number;
  LngPos: number;
}
const Store = ({
  stores,
  fields,
  location,
  setActiveMarker,
  getStoreName,
  isCareerVariant,
}: {
  stores: StoreProps[];
  fields: StoreLocatorConfig;
  location: string;
  setActiveMarker?: Dispatch<SetStateAction<StoreData>>;
  getStoreName: (location: string) => string | undefined;
  isCareerVariant?: boolean;
}) => {
  const {
    storePawImage,
    base,
    mainWrapper,
    storeWrapper,
    imageLabel,
    storeAddressWrapper,
    storeDetailWrapper,
    storeActionWrapper,
    resultText,
    storeName,
    storeDetails,
    storeAddress,
    openStoreTimingText,
    buttonWrapper,
    buttonWithArrow,
    iconWrapper,
    storeText,
    shopButton,
    storesWrapper,
    storePageArrow,
    myStoreIcon,
    markerWrapper,
  } = storeVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const dispatch = useOcDispatch();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const router = useRouter();
  //get sitecore context for siteName
  const context = useSitecoreContext();
  const sitecoreContext = context.sitecoreContext;
  const siteName = sitecoreContext?.site?.name ?? 'unknown';
  const siteNameUpper = siteName.toUpperCase() as SiteNameUppercase;
  const lookupList = useOcSelector(
    (state) => state?.lookupSlice?.data?.graphQLResponse?.data?.[siteNameUpper]?.lookupList
  );
  const servicesData: { [key: string]: string } = {};
  lookupList
    ?.filter((data) => data?.lookupName === 'Store Services')?.[0]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ?.items?.list?.forEach((item: { fields: any }) => {
      servicesData[item?.fields?.serviceId?.value] = item?.fields?.name?.value;
    });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const renderServicesList = (servicesList: number[]) => {
    const { serviceList } = storeVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });
    return (
      <ul>
        {servicesList &&
          servicesList?.map((service, index) => (
            <li key={index} className={serviceList()}>
              {servicesData?.[service?.toString()]}
            </li>
          ))}
      </ul>
    );
  };
  return (
    <div className={mainWrapper()}>
      {stores?.length > 0 && (
        <div className={base()}>
          {fields?.noofItemsPerPageText && stores?.length > 0 && (
            <RichTextHelper
              tag="p"
              field={fields?.noofItemsPerPageText}
              updatedField={{
                value: fields?.noofItemsPerPageText?.value
                  ?.replace(
                    '{location}',
                    `<strong>&nbsp;${getStoreName(location) || ''}&nbsp;</strong>`
                  )
                  ?.replace('{number}', `<strong>&nbsp;${stores?.length}&nbsp;</strong>`),
              }}
              className={resultText()}
            />
          )}
          <div className={storesWrapper()}>
            {stores?.map((store, index) => {
              const storeButton =
                store?.xp_storetype == 1
                  ? fields?.jobOpportunitiesText?.value
                  : fields?.callToInquire?.value;

              const storeTime = store?.xp_storehours?.find(
                (dayData) => dayData?.Day === new Date()?.getDay() + 1
              );
              return (
                <div
                  id={`google_map_store_${store?.storeid}`}
                  key={index}
                  className={storeWrapper()}
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.getElementById('google-map');
                    if (element) {
                      window.scrollTo({
                        top: element.offsetTop,
                        behavior: 'smooth',
                      });
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      if (setActiveMarker) setActiveMarker(store as any);
                    }
                  }}
                >
                  {fields?.storePawIcon?.value?.src && (
                    <div
                      className={markerWrapper()}
                      onClick={(e) => {
                        e.preventDefault();
                        const element = document.getElementById('google-map');
                        if (element) {
                          window.scrollTo({
                            top: element.offsetTop,
                            behavior: 'smooth',
                          });
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          if (setActiveMarker) setActiveMarker(store as any);
                        }
                      }}
                    >
                      <NextImage
                        className={storePawImage()}
                        field={{
                          value: {
                            src: fields?.storePawIcon?.value?.src,
                            alt: store?.state,
                            height: '40',
                            width: '40',
                          },
                        }}
                      />
                      <p className={imageLabel()}>{index + 1}</p>
                    </div>
                  )}
                  <div className={storeDetailWrapper()}>
                    <div className={storeDetails({ isCareerVariant: isCareerVariant })}>
                      <div>
                        <p className={storeName()}>{store?.firstname}</p>
                        <div className={storeAddressWrapper()}>
                          <div className="flex">
                            {isCareerVariant && (
                              <NextImage
                                className="w-[30px] h-[30px]"
                                field={fields?.myLocationPointIcon}
                              />
                            )}
                            <Text
                              className={storeAddress()}
                              tag="p"
                              field={{ value: store?.street1 }}
                            />
                          </div>
                          <Text
                            className={storeAddress({
                              className: isCareerVariant ? 'ml-[30px]' : '',
                            })}
                            tag="p"
                            field={{ value: `${store?.city}, ${store?.state}` }}
                          />
                          {store.phone && (
                            <div className="flex">
                              {isCareerVariant && (
                                <NextImage
                                  className="w-[30px] h-[30px]"
                                  field={fields?.phoneIcon}
                                />
                              )}
                              <LinkHelper
                                className={storeAddress()}
                                onClick={(e) => e.stopPropagation()}
                                field={{
                                  value: {
                                    text: store?.phone,
                                    href: `tel:${store?.phone}`,
                                  },
                                }}
                              />
                            </div>
                          )}
                          {isCareerVariant && storeButton ? (
                            <LinkHelper
                              className={shopButton({
                                className:
                                  'w-full mt-mob-margin-base-bottom lg:w-fit lg:mt-desk-margin-base-bottom',
                              })}
                              onClick={(e) => e.stopPropagation()}
                              field={{
                                value: {
                                  href:
                                    store?.xp_storetype == 1
                                      ? fields?.jobOpportunitiesURL?.value?.href ?? ''
                                      : store?.phone
                                      ? `tel:${store?.phone}`
                                      : '',
                                  target:
                                    store?.xp_storetype == 1
                                      ? fields?.jobOpportunitiesURL?.value?.target ?? '_blank'
                                      : '',
                                  text: storeButton,
                                },
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div>{!isCareerVariant && renderServicesList(store.xp_storeservices)}</div>
                    </div>
                    {!isCareerVariant && (
                      <div className={storeActionWrapper()}>
                        {fields?.storeLocatorRemainingOpenTimeText && (
                          <RichTextHelper
                            tag="p"
                            field={fields?.storeLocatorRemainingOpenTimeText}
                            updatedField={{
                              value:
                                storeTime && storeTime?.OpenTime && storeTime?.CloseTime
                                  ? storeTime?.OpenTime === storeTime?.CloseTime
                                    ? closedText
                                    : fields?.storeLocatorRemainingOpenTimeText?.value?.replace(
                                        '{storeLocatorRemainingOpenTime}',
                                        storeTime?.CloseTime
                                          ? DateTime.fromFormat(
                                              storeTime?.CloseTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      ) || ''
                                  : closedText,
                            }}
                            className={openStoreTimingText()}
                          />
                        )}

                        <div className={buttonWrapper()}>
                          {fields?.viewStorePageButtonText?.value && (
                            <LinkHelper
                              className={buttonWithArrow()}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                router.push(
                                  `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`?.replaceAll(
                                    ' ',
                                    '-'
                                  )
                                );
                              }}
                              field={{
                                value: {
                                  href: `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`.replaceAll(
                                    ' ',
                                    '-'
                                  ),
                                  text: fields?.viewStorePageButtonText?.value,
                                },
                              }}
                            >
                              <>
                                <Text field={fields?.viewStorePageButtonText} />
                                <IconHelper className={storePageArrow()} icon={'chevron-right'} />
                              </>
                            </LinkHelper>
                          )}
                          {myStoreData?.storeId === store?.storeid ? (
                            <>
                              {fields?.myStoreText?.value && (
                                <div className={iconWrapper()}>
                                  <NextImage
                                    className={myStoreIcon()}
                                    field={fields?.myStoreIcon}
                                  />
                                  <Text
                                    field={{ value: fields?.myStoreText?.value }}
                                    tag="p"
                                    className={storeText()}
                                  ></Text>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {fields?.shopThisStoreText?.value && (
                                <LinkHelper
                                  className={shopButton()}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (store?.storeid) {
                                      setcomponentContextData({
                                        ...componentContextData,
                                        storeId: store?.storeid,
                                        storeCount: stores?.length,
                                        callBackFunctionAfterStoreChange: async () => {
                                          const redirectUrl =
                                            fields?.afterStoreChangeUrl?.value || '/';
                                          const retrieveStoreOrder = await dispatch(
                                            retrieveOrder()
                                          );
                                          const responsesStoreOrder =
                                            unwrapResult(retrieveStoreOrder);
                                          if (responsesStoreOrder === undefined) {
                                            await dispatch(
                                              createOrder(
                                                localStorage.getItem(
                                                  'selected_fulfillment_Method'
                                                ) === FulfillmentType?.DFS
                                              )
                                            );
                                            localStorage.removeItem('selected_fulfillment_Method');
                                          } else {
                                            stores?.length &&
                                              sendStoreGTMData({
                                                eventName: GTM_EVENT?.shopThisStore,
                                                storeId: store?.storeid,
                                                storesShown: stores?.length,
                                              });
                                            await dispatch(retrieveOrder());
                                          }
                                          router?.push(redirectUrl);
                                        },
                                      });
                                    }
                                  }}
                                  field={{
                                    value: { href: '/', text: fields?.shopThisStoreText?.value },
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
