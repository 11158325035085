//global
import React, { useEffect } from 'react';
//local
import { ComponentProps } from 'lib/component-props';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { tv } from 'tailwind-variants';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useTheme } from 'lib/context/ThemeContext';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'components/helpers/Constants';
//types
export type HeadingWithDescriptionProps = ComponentProps & Fields;
export type RichTextData = {
  title: Field<string>;
  richText: Field<string>;
};
export type Fields =
  PSP.Sitecore.templates.PetSuppliesPlus.HeadingWithDescription.Fields.HeadingWithDescription;
export type PersionalizedRichTextProps = Omit<HeadingWithDescriptionProps, 'rendering'>;

type ComponentVariant =
  | 'LeftAlign'
  | 'LeftAlignFullWidth'
  | 'CenterAlign'
  | 'CenterAlignFullWidth'
  | 'Default';

//Component variants
const headingWithDescriptionVariaints = tv(
  {
    slots: {
      base: ['headingWithDescription container'],
      titleWrapper: [''],
      descriptionWrapper: [
        '[&>a]:no-underline',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      contentWrapper: ['flex ', 'flex-col'],
    },
    variants: {
      textAlignment: {
        LeftAlign: {
          base: ['text-left'],
          contentWrapper: ['max-w-[954px]'],
        },
        LeftAlignFullWidth: {
          base: ['text-left'],
        },
        CenterAlignFullWidth: {
          base: ['text-center'],
        },
        CenterAlign: {
          base: ['text-center'],
          contentWrapper: ['max-w-[954px]', 'mx-auto'],
        },
        Default: {
          base: ['text-center'],
          contentWrapper: ['max-w-[954px]', 'mx-auto'],
        },
      },
      site: {
        psp: {},
        wnw: { titleWrapper: ['font-secondary'] },
      },
      device: {
        mobile: {
          base: ['pt-mob-padding-tight-y pb-5'],
          titleWrapper: [
            'text-heading-mob-xLarge-bold ',
            'font-heading-mob-xLarge-bold ',
            'leading-heading-mob-xLarge-bold',
          ],
          contentWrapper: ['gap-2'],
        },
        desktop: {
          base: ['pt-desk-padding-tight-y pb-desk-padding-micro-y'],
          titleWrapper: [
            'text-heading-desk-xLarge-bold ',
            'font-heading-desk-xLarge-bold ',
            'leading-heading-desk-xLarge-bold',
          ],
          descriptionWrapper: ['[&>a]:cursor-pointer'],
          contentWrapper: ['gap-3'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

//main comoponent
const HeadingWithDescription: React.FC<HeadingWithDescriptionProps> = ({ fields, params }) => {
  const { themeName } = useTheme();
  const { base, titleWrapper, descriptionWrapper, contentWrapper } =
    headingWithDescriptionVariaints({
      device: { initial: 'mobile', lg: 'desktop' },
      textAlignment: params?.FieldNames as ComponentVariant,
      site: themeName,
    });
  const renderingStyle = params?.Styles ? '!' + params?.Styles : '';
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(fields?.componentName?.value),
      creative_slot: Number(fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(`${fields?.promotionDateFROM?.value} - ${fields?.promotionDateTO?.value}`)
      ),
      promotion_cta: notAvailableIfNullOrEmpty(fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(fields?.promotionURL?.value?.href),
    },
  ];

  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);

  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;

  return (
    <div
      data-component={'components/HeadingWithDescription/HeadingWithDescription'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
      className={base({ className: renderingStyle })}
    >
      <div className={contentWrapper()}>
        <>
          <RichTextHelper field={fields?.title} className={titleWrapper()} />
          <RichTextHelper field={fields?.description} className={descriptionWrapper()} />
        </>
      </div>
    </div>
  );
};

export const Default = withPersonalization((props: HeadingWithDescriptionProps): JSX.Element => {
  return <HeadingWithDescription {...props} />;
});
export const LeftAlign = withPersonalization((props: HeadingWithDescriptionProps): JSX.Element => {
  return <HeadingWithDescription {...props} />;
});
export const CenterAlign = withPersonalization(
  (props: HeadingWithDescriptionProps): JSX.Element => {
    return <HeadingWithDescription {...props} />;
  }
);
export const CenterAlignFullWidth = withPersonalization(
  (props: HeadingWithDescriptionProps): JSX.Element => {
    return <HeadingWithDescription {...props} />;
  }
);
export const LeftAlignFullWidth = withPersonalization(
  (props: HeadingWithDescriptionProps): JSX.Element => {
    return <HeadingWithDescription {...props} />;
  }
);
