import clsx from 'clsx';
import searchResultsTailwind from './SearchResultsTailwind';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { useSearchResults } from '@sitecore-search/react';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { JsonDataProps } from 'components/helpers/SitecoreSearch/ContentDataWrapper/ContentDataWrapper';
import RichTextHelper from 'components/helpers/RichTextHelper';

const { noResultsBox, noResultQuery, bodySmallRegular, cancelLastAction } = searchResultsTailwind({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

interface PspNoResultsBoxProps {
  keyphrase?: string;
  clearLastAction?: () => void;
  totalItems?: number;
}

export const PspNoResultsBox = ({
  keyphrase,
  clearLastAction,
  totalItems,
}: PspNoResultsBoxProps) => {
  const [isSearchPage, setIsSearchPage] = useState<boolean>(false);
  const searchParams = useSearchParams();
  const query = searchParams.get('q') || searchParams.get('query') || '';

  const { query: searchQuery } = useSearchResults();

  useEffect(() => {
    if (totalItems === 0) {
      searchQuery.getRequest().setSearchQueryOperator('or');
    }
  }, [totalItems]);

  useEffect(() => {
    if (query) {
      setIsSearchPage(true);
    }
  }, [query]);

  const { sitecoreContext } = useSitecoreContext();
  const pageData = sitecoreContext.route?.fields as JsonDataProps;

  const RenderMessage: React.FC = () => {
    return (
      <>
        {/* No Results for {search term} */}
        <p>
          {pageData?.noResultMessage?.value} <span className={noResultQuery()}>{keyphrase}</span>
        </p>
        {/* CTA to cancel last search */}
        <span className={clsx(bodySmallRegular(), cancelLastAction())} onClick={clearLastAction}>
          {pageData?.cancelLastActionText?.value}
        </span>
        {/* Search tips description */}
        <RichTextHelper field={pageData?.searchTips as Field<string>} />
      </>
    );
  };

  return (
    <div className={noResultsBox()}>
      {/* for PLP and shops */}
      {!isSearchPage && <p>{pageData?.noResultMessage?.value}</p>}

      {/* for Search pages: */}
      {isSearchPage && pageData && <RenderMessage {...pageData} />}
    </div>
  );
};
