import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import LinkHelper from 'components/helpers/LinkHelper';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

export const StoreDetailVariants = tv(
  {
    slots: {
      base: 'sticky top-0 z-[2] bg-color-background-white section-selector',
      tabsWrapper: 'container mx-auto flex justify-center items-center flex-wrap',
      tabLink:
        'p-2 text-color-text-brand-1 underline text-body-large-bold font-body-large-bold leading-body-large-bold hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    },
    variants: {
      size: {
        desktop: {
          base: 'block',
          tabsWrapper: 'px-0 py-desk-padding-micro-y gap-desk-space-between-base-horizontal',
        },
        mobile: {
          base: 'hidden',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
interface linkList {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields?: {
    link?: LinkField;
  };
}
export type StoreNavigationProps = {
  fields?: {
    data?: {
      storeLandingSection?: {
        sectionList?: { jsonValue?: linkList[] };
      };
    };
  };
};

const StoreNavigation = ({ fields }: StoreNavigationProps): JSX.Element => {
  const { tabsWrapper, tabLink, base } = StoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const [navigationHeight, setNavigationHeight] = useState(0);

  useEffect(() => {
    const sectionSelector = document.getElementById('section-selector');
    if (sectionSelector) {
      setNavigationHeight(sectionSelector.offsetHeight);
    }
  }, []);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string | undefined) => {
    if (id) {
      e.preventDefault();
      const element = document.getElementById(id);
      if (element) {
        const offset = element.offsetTop - navigationHeight; // Adjust the offset as needed
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  };
  return (
    <>
      {fields?.data?.storeLandingSection?.sectionList?.jsonValue &&
        fields?.data?.storeLandingSection?.sectionList?.jsonValue?.length > 0 && (
          <div className={base()} id="section-selector">
            <div className={tabsWrapper()}>
              {fields?.data?.storeLandingSection?.sectionList?.jsonValue?.map(
                (link: linkList, index: number) => {
                  return (
                    <div id={link?.fields?.link?.value?.href?.replace('#', 'store-')} key={index}>
                      {link?.fields?.link && (
                        <LinkHelper
                          onClick={(e) =>
                            handleClick(e, link?.fields?.link?.value?.href?.replace('#', ''))
                          }
                          field={link?.fields?.link}
                          className={tabLink()}
                        />
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
    </>
  );
};
export default StoreNavigation;
