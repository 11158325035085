import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import styles from './styles/MyRewardsPointsProgressBar.module.css';

type MyRewardsProgressBarProps = {
  userPoints: number;
  pointRedemptionLabel: Field<string>;
  redeemNowLink: LinkField;
  redeemNowText: Field<string>;
};

const rewardsClass = tv(
  {
    slots: {
      myRewardsBar: ['myRewardsBar', 'flex', 'flex-end', 'flex-1', 'justify-end'],
      myRewardsProgressBar: ['flex-1'],
      redemptionText: ['loyalty-redemption-text'],
      redeemPoints: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'italic',
      ],
      redeemLinkUrl: ['leading-body-large-bold', 'text-body-large-bold', 'text-color-text-brand-1'],
      progressAmount: ['flex', 'justify-between', 'items-center', 'py-[10px]', 'pl-[10%]'],
      amount: [
        'inline-block',
        'w-[20%]',
        'pl-0',
        'pr-0',
        'cursor-pointer',
        'border-l',
        'border-white',
        'mr-0',
        'uppercase',
        'list-none',
        'text-center',
      ],
      progressMeter: [
        'bg-white',
        'justify-start',
        'border-2',
        'border-color-background-brand-1',
        'rounded-full items-center relative',
        'flex',
        'h-10',
        'w-full',
        'overflow-hidden',
      ],
      progressValue: [
        'animate-load',
        'shadow-lg',
        'bg-color-background-brand-1',
        'h-10',
        'w-0',
        'rounded-[100px]',
      ],
      progressIndicator: [
        'z-1',
        'h-10',
        'w-0.5',
        'cursor-pointer',
        'bg-color-background-brand-1',
        'absolute',
      ],
      progressPoints: ['flex justify-between', 'items-center', 'py-[10px]', 'pl-[10%]'],
      pointsItem: ['points'],
      completed: ['bg-white'],
    },
    variants: {
      size: {
        mobile: {
          myRewardsProgressBar: ['w-full', 'max-w-full'],
          pointsItem: ['w-1/5', 'text-center'],
        },
        desktop: {
          myRewardsProgressBar: ['w-full', 'max-w-[453px]'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

const {
  myRewardsBar,
  myRewardsProgressBar,
  redemptionText,
  redeemPoints,
  redeemLinkUrl,
  progressAmount,
  amount,
  progressMeter,
  progressValue,
  progressIndicator,
  progressPoints,
  pointsItem,
  completed,
} = rewardsClass({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

export default function LoyaltyPointsProgressBar({
  userPoints,
  pointRedemptionLabel,
  redeemNowLink,
  redeemNowText,
}: MyRewardsProgressBarProps) {
  const points = userPoints || 0;
  const valueDollarIncrements = 5;
  const valuePointIncrements = 1000;
  const milestones = 4;

  const resets = Math.trunc(points / valuePointIncrements / milestones);

  const startDollar = resets * valueDollarIncrements * milestones;
  const startPoints = resets * valuePointIncrements * milestones;

  const progressPercent =
    ((points - startPoints) / (valuePointIncrements * milestones * (resets + 1) - startPoints)) *
    100;

  const dollarAmount = valueDollarIncrements * Math.trunc(points / valuePointIncrements);

  const redeemPointsText = pointRedemptionLabel?.value
    ? pointRedemptionLabel?.value.replace('{0}', dollarAmount.toString())
    : 'Redeem now.';

  // const redeemLink =
  //   redeemNowLink?.value && redeemNowLink?.value ? redeemNowLink?.value : '#reward-list';

  const isLoyalotyRedemptionTextShow = points < 1000 ? false : true;

  return (
    <div className={myRewardsBar()}>
      <div className={myRewardsProgressBar()}>
        {isLoyalotyRedemptionTextShow && (
          <div className={redemptionText()}>
            <p className={redeemPoints()}>
              {redeemPointsText}{' '}
              <a
                aria-label="redeem now"
                tabIndex={0}
                className={redeemLinkUrl()}
                href={redeemNowLink?.value?.href}
              >
                {redeemNowText?.value}
              </a>
            </p>
          </div>
        )}

        <div className={progressAmount()}>
          {Array.from({ length: milestones }, (_, index) => {
            const couponValue = (index + 1) * valueDollarIncrements + startDollar;
            return (
              <div key={index} className={amount()}>
                ${couponValue}
              </div>
            );
          })}
        </div>

        <div className={progressMeter()}>
          <div className={progressValue()} style={{ width: `${progressPercent}%` }}>
            {Array.from({ length: 4 }, (_, index) => {
              const indicatorPercent = (index + 1) * 25;
              return (
                <div
                  key={index}
                  className={`${progressIndicator()} ${styles.myRewardsProgressIndicator} ${
                    progressPercent >= indicatorPercent ? completed() : ''
                  }`}
                ></div>
              );
            })}
          </div>
        </div>
        <div className={progressPoints()}>
          {Array.from({ length: milestones }, (_, index) => {
            const pointValue = (index + 1) * valuePointIncrements + startPoints;
            return (
              <div key={index} className={pointsItem()}>{`${pointValue.toLocaleString()} pts`}</div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
