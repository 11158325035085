// Global
import useSWR from 'swr';
import { useEffect, useState } from 'react';

// Local
import { GET_PRODUCT_IMAGE_QUERY } from './graphQl/queries';
import ImageHelper from 'components/helpers/ImageHelper';
import Loader from 'components/Loader/Loader';

// Types
type ContentHubImageProps = {
  programid: number;
};

type ProductImageProps = {
  data?: {
    allM_Asset?: {
      results?: {
        altText?: string;
        urls?: {
          [key: string]: {
            url: string;
            metadata: {
              width: string;
              height: string;
            };
          };
        };
      }[];
    };
  };
};

type ImageInfo = {
  value: {
    src: string | undefined;
    alt: string | undefined;
    width: string | undefined;
    height: string | undefined;
  };
};

const mediaLibraryURL = process.env.NEXT_PUBLIC_MEDIA_LIBRARY;
const mediaGQLToken = process.env.MEDIA_LIBRARY_GQL_TOKEN;

if (!mediaLibraryURL || !mediaGQLToken) {
  throw new Error('Missing Media Library API Endpoint or GQL token');
}

const fetchProducts = async (apiUrl: string, fileName: string) => {
  const response = await fetch(`${apiUrl}/api/graphql/preview/v1`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-GQL-Token': mediaGQLToken,
    },
    body: JSON.stringify({
      query: GET_PRODUCT_IMAGE_QUERY,
      variables: { fileName },
    }),
  });
  const data = await response.json();
  return data;
};

const ContentHubImage = (props: ContentHubImageProps) => {
  const programid = props?.programid;
  const fileName = `programid_${programid}.jpg`;
  const [productImage, setProductImage] = useState<ProductImageProps | undefined>();
  const errorMessage = 'An error occurred in the API response.';

  const { data, error, isLoading } = useSWR([mediaLibraryURL, fileName], () =>
    fetchProducts(mediaLibraryURL, fileName)
  );

  useEffect(() => {
    if (!isLoading && data) {
      setProductImage(data);
    }
  }, [data, isLoading]);

  const urls =
    productImage?.data?.allM_Asset?.results &&
    (productImage?.data?.allM_Asset?.results[0]?.urls as
      | { [key: string]: { url: string; metadata: { width: string; height: string } } }
      | undefined);
  let imageInfo: ImageInfo | undefined;
  if (urls) {
    const urlKey = Object.keys(urls)[0];
    imageInfo = {
      value: {
        src: urls[urlKey]?.url,
        alt:
          productImage?.data?.allM_Asset?.results &&
          productImage?.data?.allM_Asset?.results[0]?.altText,
        width: urls[urlKey]?.metadata?.width,
        height: urls[urlKey]?.metadata?.height,
      },
    };
  }

  /*
   * Rendering
   */

  if (error) {
    console.error(errorMessage);
    return <></>;
  }

  if (!data) return <Loader />;

  return (
    <>
      {imageInfo &&
        imageInfo?.value?.src &&
        imageInfo?.value?.width &&
        imageInfo?.value?.height && <ImageHelper field={imageInfo} />}
    </>
  );
};

export default ContentHubImage;
