//global
import React, { useContext, useEffect, useRef } from 'react';
import { tv } from 'tailwind-variants';

//lib
import { ComponentProps } from 'lib/component-props';
//helper
import LinkHelper from 'components/helpers/LinkHelper';
import IconHelper from 'components/helpers/IconHelper';
import ImageHelper from 'components/helpers/ImageHelper';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import RichTextHelper from 'components/helpers/RichTextHelper';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import {
  formatDateForGTM,
  Items,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'components/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
//type
type gtmType = { [key: string]: string | number }[];
export type Fields =
  PSP.Sitecore.templates.PetSuppliesPlus.LandingPageBanner.Fields.LandingPageBanner & {
    myStoreId?: string;
    gtmPromotion: gtmType;
  };
export type LandingPageBannerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.LandingPageBanner.Fields.LandingPageBanner;

//component variants
export const LandingPageBannerVariaints = tv(
  {
    slots: {
      base: ['landingPageBanner'],
      bannerBase: [''],
      persionalizedBase: [''],
      contentWrapper: ['flex-wrap', 'px-[15px]'],
      title: [''],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:w-4',
        '[&>svg]:h-4',
        'group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base',
      ],
      linkWrapper: [
        'group',
        'hover:text-color-brand-tertiary-3-base',
        'text-color-text-brand-1',
        'gap-mob-space-between-micro-horizontal',
        'items-center',
      ],
      deskImg: [''],
      mobImg: [''],
    },
    compoundSlots: [
      {
        slots: ['title', 'linkWrapper'],
        class: ['text-body-large-bold', 'font-body-large-bold', 'leading-body-large-bold'],
      },
      {
        slots: ['contentWrapper', 'linkWrapper'],
        class: ['flex'],
      },
      {
        slots: ['deskImg', 'mobImg'],
        class: ['w-full'],
      },
    ],
    variants: {
      padding: {
        default: {},
        noPaddingTop: { base: ['!pt-0'] },
        normal: {},
      },
      device: {
        mobile: {
          base: ['py-mob-padding-micro-y'],
          contentWrapper: ['py-mob-padding-micro-y', 'gap-mob-margin-tight-bottom', 'items-start'],
          deskImg: ['hidden'],
        },
        desktop: {
          base: ['py-desk-padding-micro-y'],
          contentWrapper: ['py-desk-padding-micro-y', 'gap-desk-margin-tight-bottom'],
          deskImg: ['block'],
          mobImg: ['hidden'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

//Default Banner
const DefaultBanner: React.FC<Fields> = ({ fields, myStoreId, gtmPromotion }) => {
  const isEE = useExperienceEditor();
  const { bannerBase, contentWrapper, title, linkWrapper, deskImg, mobImg, icon } =
    LandingPageBannerVariaints({
      device: { initial: 'mobile', lg: 'desktop' },
    });

  return (
    <div className={bannerBase()}>
      {isEE ? (
        <div className={contentWrapper()}>
          {fields?.heading && <RichTextHelper field={fields?.heading} className={title()} />}
          {fields?.headingLink && (
            <LinkHelper field={fields?.headingLink} className={linkWrapper()}>
              <>
                <IconHelper icon="chevron-right" className={icon()} />
              </>
            </LinkHelper>
          )}
        </div>
      ) : (fields?.heading?.value && fields?.heading?.value?.length > 0) ||
        (fields?.headingLink?.value?.href && fields?.headingLink?.value?.href?.length > 0) ? (
        <div className={contentWrapper()}>
          {fields?.heading?.value && <RichTextHelper field={fields?.heading} className={title()} />}
          {(fields?.headingLink?.value?.text || fields?.headingLink?.value?.href) && (
            <LinkHelper field={fields?.headingLink} className={linkWrapper()}>
              <>
                {!isEE && fields?.headingLink?.value?.text && (
                  <span>{fields?.headingLink?.value?.text}</span>
                )}
                {fields?.headingLink?.value?.text && (
                  <IconHelper icon="chevron-right" className={icon()} />
                )}
              </>
            </LinkHelper>
          )}
        </div>
      ) : (
        ''
      )}
      {fields?.contentLink && (
        <LinkHelper
          field={fields?.contentLink}
          aria-label="offer-link"
          onClick={() => {
            myStoreId &&
              fields?.componentName?.value !== '' &&
              trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
          }}
        >
          <>
            <ImageHelper field={fields?.desktopImage} className={deskImg()} />
            <ImageHelper field={fields?.mobileImage} className={mobImg()} />
          </>
        </LinkHelper>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendGTMData = (data: any, deviceName: string, storeId: string, event: string) => {
  const gtmPromotion: Items[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?.map((d: any) => {
    d?.promotionName?.value !== '' &&
      gtmPromotion.push({
        promotion_id: notAvailableIfNullOrEmpty(d?.promotionId?.value),
        promotion_name: notAvailableIfNullOrEmpty(d?.promotionName?.value),
        creative_name: notAvailableIfNullOrEmpty(d?.componentName?.value),
        creative_slot: Number(d?.creativeSlotNumber?.value),
        promotion_device: deviceName,
        promotion_copy: notAvailableIfNullOrEmpty(d?.promotionCopy?.value),
        promotion_dates: notAvailableIfNullOrEmpty(
          formatDateForGTM(`${d?.promotionDateFROM?.value} - ${d?.promotionDateTO?.value}`)
        ),
        promotion_cta: notAvailableIfNullOrEmpty(d?.cTAButtonCopy?.value),
        promotion_url: notAvailableIfNullOrEmpty(d?.promotionURL?.value?.href),
      });
  });

  gtmPromotion?.length > 0 && trackObjectForPromotion(event, storeId, gtmPromotion);
};

//main component
const LandingPageBanner: React.FC<LandingPageBannerProps> = ({
  fields,
  params,
  rendering,
}): JSX.Element => {
  const { base } = LandingPageBannerVariaints({
    device: { initial: 'mobile', lg: 'desktop' },
    padding: params?.FieldNames == 'NoTopPadding' ? 'noPaddingTop' : 'default',
  });

  const { deviceName }: { deviceName: string } = useBreakpoints();
  const componentRef = useRef(null);
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  const gtmPromotion: gtmType = [
    {
      promotion_id: notAvailableIfNullOrEmpty(fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(fields?.componentName?.value),
      creative_slot: Number(fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(`${fields?.promotionDateFROM?.value} - ${fields?.promotionDateTO?.value}`)
      ),
      promotion_cta: notAvailableIfNullOrEmpty(fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(fields?.promotionURL?.value?.href),
    },
  ];

  useEffect(() => {
    // Add this instance's data to the context
    setcomponentContextData((prevData) => ({
      ...prevData,
      ...(fields && {
        landingPageBanner: [
          ...(prevData?.landingPageBanner || []),
          { uid: rendering?.uid, items: fields && fields },
        ],
      }),
    }));

    // Cleanup function to remove this instance when unmounting
    return () => {
      setcomponentContextData((prevData) => ({
        ...prevData,
        landingPageBanner: prevData?.landingPageBanner?.filter(
          (item: LandingPageBannerProps) =>
            item?.rendering?.uid !== prevData?.landingPageBanner?.uid
        ),
      }));
    };
  }, []);

  useEffect(() => {
    const landingPageBannerInstances = componentContextData?.landingPageBanner || [];

    // Check if this is the last rendered instance
    if (componentRef.current && landingPageBannerInstances.length > 1) {
      const allInstances = document.querySelectorAll('.landingPageBanner');
      if (componentRef.current === allInstances[allInstances.length - 1]) {
        // This is the last instance, combine data here
        const combinedData = landingPageBannerInstances.flatMap(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (instance: any) => instance.items && instance.items
        );
        combinedData &&
          myStoreId &&
          sendGTMData(combinedData, deviceName, myStoreId, GTM_EVENT?.viewPromotion);
      }
    }
  }, [componentContextData.landingPageBannerInstances, myStoreId]);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;

  return (
    <div
      ref={componentRef}
      // {/* //TODO: Add personalization here Krushna */}
      data-component={'components/LandingPageBanner/LandingPageBanner'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
      className={
        (params?.Styles ?? '') +
        ' ' +
        base({ className: `${fields?.isFullWidth?.value ? '' : 'container mx-auto'}` })
      }
    >
      <DefaultBanner fields={fields} myStoreId={myStoreId} gtmPromotion={gtmPromotion} />
    </div>
  );
};

export const Default = withPersonalization((props: LandingPageBannerProps): JSX.Element => {
  return <LandingPageBanner {...props} />;
});
export const TopPadding = withPersonalization((props: LandingPageBannerProps): JSX.Element => {
  return <LandingPageBanner {...props} />;
});
export const NoTopPadding = withPersonalization((props: LandingPageBannerProps): JSX.Element => {
  return <LandingPageBanner {...props} />;
});
