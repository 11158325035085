import { DiscreteLineItem } from 'components/helpers/Constants';
import { useLineItemPriceListForUI } from './useLineItemPriceForUI';
import { OcCurrentOrderState } from 'src/redux/ocCurrentOrder';

export interface UICartPrice {
  listPriceTotal: number;
  totalMemberSavings: number;
  salePriceSavings: number;
  autoshipDiscount: number;
  promoDiscounts: number;
  subTotal: number;

  deliveryFee: number;
}

export function useCartPriceForUI(worksheet: OcCurrentOrderState): UICartPrice {
  const cart = worksheet.order;
  const lineItems = worksheet.lineItems ?? [];
  const promotions = worksheet.orderPromotions ?? [];
  const productPrices = useLineItemPriceListForUI(lineItems, promotions);

  const result: UICartPrice = {
    listPriceTotal: 0,
    totalMemberSavings: 0,
    salePriceSavings: 0,
    autoshipDiscount: 0,
    subTotal: 0,
    promoDiscounts: 0,
    deliveryFee: cart?.ShippingCost ?? 0,
  };

  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i];
    if (lineItem.ProductID === DiscreteLineItem.TIP) {
      continue;
    }
    const productPrice = productPrices[lineItem.ID ?? ''];

    // if (productPrice.lineItemListPrice === undefined) {
    //   continue;
    // }
    const listPrice = productPrice.lineItemListPrice ?? 0;
    const baseMemberPrice = productPrice.lineItemBaseMemberPrice ?? listPrice;
    const memberPriceWithOfferid = productPrice.lineItemMemberPrice ?? baseMemberPrice;
    const autoshipPrice = productPrice.lineItemAutoshipPrice ?? memberPriceWithOfferid;

    const autoshipDiscount = memberPriceWithOfferid - autoshipPrice;
    const pluDiscount = productPrice.pluDiscount ?? 0;
    const salePriceSavings = listPrice - (memberPriceWithOfferid + pluDiscount);

    const totalMemberSavings = salePriceSavings + autoshipDiscount + pluDiscount;
    result.listPriceTotal += listPrice;

    result.totalMemberSavings += totalMemberSavings;
    result.salePriceSavings += salePriceSavings;
    result.autoshipDiscount += autoshipDiscount;
    result.promoDiscounts += pluDiscount;
    result.subTotal += lineItem.LineTotal ?? 0;
    if (productPrice.lineItemListPrice && lineItem.LineTotal !== listPrice - totalMemberSavings) {
      //JS math may go wrong most of the time. So no need to fill console with Errors. Error causes low website score.
      //Example:  In JS (0.3 - 0.1) != 0.2
      /*
      console.error(
        `Line subtotal mismatch. Actual: ${lineItem.LineTotal}. Expected: ${
          listPrice - totalMemberSavings
        }`
      );
      */
    }
  }

  return result;
}
