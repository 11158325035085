import { ErrorMessage, Field, useField } from 'formik';
import React, { MouseEventHandler } from 'react';
import { FaCheck } from 'react-icons/fa';
import clsx from 'clsx';
import { tv } from 'tailwind-variants';
type CheckboxFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  subLabel?: string;
  name: string;
  value?: string;
  requiredvalidationmessage?: string;
  validationpattern?: string;
  hasFullWidth?: boolean;
  options?: Array<OptionFieldProps>;
  singleCheckbox?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement>;
};
interface OptionFieldProps {
  text?: { value: string };
  value?: { value: string };
  code?: { value: string };
  name?: { value: string };
}
const FieldsVariant = tv(
  {
    slots: {
      fieldWrapper: ['flex', 'items-start', 'flex-wrap', 'content-start'],
      fields: [
        'w-[34px]',
        'h-[34px]',
        'outline-color-border-mid',
        'border',
        'appearance-none',
        'bg-color-background-white',
        'peer',
      ],
      labels: [
        'flex',
        'items-center',
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'relative',
      ],
      errorStyle: ['text-system-red', 'w-full'],
      checkmarkIcon: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base',
        'absolute',
        '[&>svg]:w-[34px]',
        '[&>svg]:h-[34px]',
        'invisible',
        'peer-checked:visible',
      ],
    },
    variants: {
      device: {
        mobile: {
          fields: [],
          labels: ['gap-mob-space-between-tight-horizontal'],
          fieldWrapper: ['gap-mob-space-between-base-horizontal'],
        },
        desktop: {
          fields: [],
          labels: ['gap-desk-space-between-tight-horizontal'],
          fieldWrapper: ['gap-desk-space-between-base-horizontal'],
        },
      },
      hasErrorStyle: {
        true: { fields: ['border-system-red'] },
        false: { fields: ['border-color-border-mid'] },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
const CustomFieldsVariant = tv(
  {
    slots: {
      customFields: [
        'w-[28px]',
        'h-[28px]',
        'outline-color-border-mid',
        'border-[2px]',
        'appearance-none',
        'bg-color-background-white',
        'peer',
        'rounded-[4px]',
      ],
      customLabels: [
        'flex',
        'items-center',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'relative',
      ],
      customCheckmarkIcon: [
        '[&>svg>*>*]:fill-color-background-white',
        'absolute',
        '[&>svg]:w-[28px]',
        '[&>svg]:h-[28px]',
        '[&>svg]:bg-color-brand-primary-1-base',
        'invisible',
        'peer-checked:visible',
      ],
    },
    variants: {
      device: {
        mobile: {
          // customFields: [],
          customLabels: ['gap-mob-space-between-tight-horizontal'],
        },
        desktop: {
          // customFields: [],
          customLabels: ['gap-desk-space-between-tight-horizontal'],
        },
      },
      hasErrorStyle: {
        true: { customFields: ['border-system-red'] },
        false: { customFields: ['border-color-border-mid'] },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
const CheckboxField: React.FC<CheckboxFieldProps> = (props: CheckboxFieldProps) => {
  const {
    id,
    name,
    requiredvalidationmessage,
    required,
    options,
    disabled,
    singleCheckbox,
    label,
    onClick,
  } = props;
  const fieldValidate = (value: string) => {
    let error;
    if (required && typeof value !== 'boolean') {
      error = requiredvalidationmessage || 'APP: this field is required';
    }
    return error;
  };
  const [{}, { error, touched }] = useField(name);
  const hasError = name && touched && error ? true : false;
  const { fields, errorStyle, checkmarkIcon, labels, fieldWrapper } = FieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { customFields, customCheckmarkIcon, customLabels } = CustomFieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any) => {
    // Handle onClick event
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <div className={clsx(singleCheckbox ? 'w-full' : '', fieldWrapper())}>
      {options &&
        options.map((element: OptionFieldProps, index: number): JSX.Element => {
          return (
            <div key={index}>
              {name && (
                <label className={labels()}>
                  <Field
                    aria-label={name}
                    type="checkbox"
                    className={fields()}
                    id={id}
                    name={name}
                    value={element?.value?.value}
                    validate={fieldValidate}
                    disabled={disabled}
                  />
                  {element.text?.value}
                  <FaCheck className={checkmarkIcon()} />
                </label>
              )}
            </div>
          );
        })}
      {/* custom checkbox for a single checkbox */}
      {singleCheckbox && (
        <>
          {name && (
            <label className={customLabels()}>
              <Field
                type="checkbox"
                className={clsx(customFields())}
                id={id}
                aria-label={name}
                name={name}
                validate={fieldValidate}
                required={required}
                disabled={disabled}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any) => {
                  handleClick(e);
                }}
              />
              {label}
              <FaCheck className={customCheckmarkIcon()} />
            </label>
          )}
        </>
      )}
      {hasError && <ErrorMessage className={errorStyle()} name={name} component="div" />}
    </div>
  );
};
export default CheckboxField;
