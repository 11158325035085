import { tv } from 'tailwind-variants';
const deliveryAddress = tv(
  {
    slots: {
      base1: [
        // 'bg-color-background-light',
        'rounded-global-radius-medium',
        'flex',
        'flex-col',
        'items-start',
        'justify-start',
        'shrink-0',
        'relative',
      ],
      title1: ['text-color-text-black'],
      deliveryAddressWrapper: ['w-full', 'flex', 'gap-[26px]'],
      pickupInformationContainer: ['flex', 'gap-[26px]', 'flex-col', 'w-full'],
      addressBlock: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      addressInfo: [],
      pickUpInformationWrapper: [
        'flex',
        'flex-col',
        'gap-3',
        'items-start',
        'justify-start',
        'shrink-0',
        'relative',
      ],
      title: [],
      informationTitle: [
        'text-color-text-black',
        'text-left',
        'font-body-large-bold',
        'text-body-large-bold',
        'leading-body-large-bold',
      ],
      form: ['bg-color-background-light', 'flex', 'flex-col'],
      radioButtonsWrapper: ['flex', 'flex-col', 'gap-[12px]'],
      radioButtonWrapper: ['flex', 'w-fit', 'relative', 'cursor-pointer'],
      radioIcon:
        'invisible w-[20px] h-[20px] outline-color-border-mid border-2 rounded-full peer-checked:visible absolute bg-color-brand-primary-1-base',
      radioField:
        'w-[20px] h-[20px] rounded-full cursor-pointer outline-color-border-mid border-2 appearance-none bg-color-background-white rounded-4 peer',
      validFieldInfo: [
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      info: ['text-color-text-black'],
      inlineFieldWrapper: ['relative'],
      radioAsCheckboxWrapper: ['flex', 'flex-row', 'absolute', 'top-0', 'right-0', 'z-10'],
      inlineFields: ['flex', 'flex-1', 'gap-[24px]'],
      field: [
        'w-[24px]',
        'h-[24px]',
        'outline-color-border-mid',
        'border',
        'appearance-none',
        'bg-color-background-white',
        'peer',
      ],
      labels: [
        'flex',
        'items-center',
        'text-body-large-bold',
        // 'font-body-large-bold',
        'leading-body-large-bold',
        'relative',
      ],
      errorStyle: ['text-system-red', 'w-full'],
      checkmarkIcon: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base',
        'absolute',
        '[&>svg]:w-[24px]',
        '[&>svg]:h-[24px]',
        'invisible',
        'peer-checked:visible',
      ],
      submitBtn: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
      loaderWrapper: ['flex', 'items-center'],
      fieldWrapper: ['w-full'],
      titleWrapper: [
        'flex',
        'flex-row',
        'gap-desk-margin-base-right',
        'items-center',
        'justify-start',
        'self-stretch',
        'shrink-0',
        'relative',
      ],
      specialInstructions: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'text-yellow-500',
      ],
      blockWithMargin: ['mb-desk-margin-tight-bottom'],
    },
    variants: {
      active: {
        true: {
          base1: ['bg-color-background-light'],
          title: [
            'text-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
            'font-heading-desk-large-bold',
          ],
        },
        false: {
          base1: ['bg-color-background-white', '!px-0'],
          title: [
            'text-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
          ],
        },
      },
      size: {
        mobile: {
          base1: [
            'pt-mob-padding-tight-y',
            'pr-mob-padding-micro-x',
            'pb-mob-padding-tight-y',
            'pl-mob-padding-micro-x',
            'gap-mob-margin-base-bottom',
          ],
          addressBlock: ['pl-0'],
          addressInfo: [],
          deliveryAddressWrapper: ['flex-col'],
          radioButtonWrapper: ['gap-mob-space-between-micro-horizontal'],
          validFieldInfo: ['text-color-text-black'],
          form: ['gap-mob-space-between-base-vertical'],
          info: [
            'text-body-small-regular',
            'font-body-small-regular',
            'leading-body-small-regular',
          ],
          radioAsCheckboxWrapper: ['gap-mob-margin-base-right', 'right-auto', 'top-[26px]'],
          field: [],
          labels: ['gap-mob-space-between-tight-horizontal'],
          submitBtn: [
            'w-full',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'rounded-desk-global-radius-small',
          ],
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          inlineFieldWrapper: ['[&>*>*>*>label]:pb-[36px]'],
          inlineFields: ['flex-col'],
        },
        desktop: {
          base1: [
            'pt-desk-padding-tight-y',
            'pr-desk-padding-micro-x',
            'pb-desk-padding-tight-y',
            'pl-desk-padding-micro-x',
            'gap-desk-margin-base-bottom',
          ],
          deliveryAddressWrapper: ['flex-row', 'justify-between'],
          title1: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'mb-desk-margin-base-bottom',
          ],
          addressBlock: ['pl-mob-margin-base-right'],
          addressInfo: [
            'pl-0',
            'text-body-large-regular',
            'font-body-large-regular',
            'leading-body-large-regular',
          ],
          radioButtonWrapper: ['gap-desk-space-between-micro-horizontal'],
          validFieldInfo: [],
          form: ['gap-desk-space-between-base-vertical'],
          info: [
            'text-body-small-regular',
            'font-body-small-regular',
            'leading-body-small-regular',
          ],
          radioAsCheckboxWrapper: ['gap-desk-margin-base-right', 'right-[0px]', 'top-[0px]'],
          field: [],
          labels: ['gap-desk-space-between-tight-horizontal'],
          submitBtn: [
            'max-w-[280px]',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          inlineFieldWrapper: ['[&>*>*>*>label]:pb-0'],
          inlineFields: ['flex-row'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
export default deliveryAddress;
