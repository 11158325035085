import { tv } from 'tailwind-variants';

export const myAutoshipVariants = tv(
  {
    slots: {
      orderDetailWrapper: 'flex p-2 flex-wrap ',
      subscriptionsWrappers: 'border border-color-mid',
      subscriptionsWrapper: 'bg-color-background-light',
      noAutoshipMessage:
        'justify-center border-t border-t-color-border-mid flex font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      subscriptionTopContentWrapper:
        'flex w-full justify-between border-b border-b-color-border-mid',
      subscriptionTitle:
        'font-primary font-heading-mob-large-bold text-heading-mob-large-bold leading-heading-mob-large-bold text-color-text-dark',
      subscriptionStatus:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',

      base: 'myAutoship flex flex-col',
      title: 'font-primary',
      productsDetailWrapper: 'flex flex-col',
      productsListWrapper: 'flex flex-wrap',
      heartIconWithTitle:
        'absolute right-0 -top-2 lg:top-0 cursor-pointer inline-flex w-4 h-4 [&_svg]:w-4 [&_svg]:h-4 lg:ml-2 [&>svg>*>*]:fill-color-brand-primary-1-base',
      productListNote:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular lg:mr-desk-padding-micro-x',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          productsListWrapper: 'gap-desk-global-spacing-spacing-2',
          orderDetailWrapper: 'lg:gap-2',
          subscriptionsWrappers: 'rounded-desk-global-radius-medium',
          subscriptionsWrapper: 'py-desk-padding-micro-y px-desk-padding-micro-x',
          noAutoshipMessage: 'py-desk-padding-tight-y',
          subscriptionTopContentWrapper: 'pb-desk-padding-micro-bottom',

          base: 'gap-desk-margin-base-bottom',
          title:
            'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
          productsDetailWrapper:
            'gap-desk-padding-micro-bottom py-desk-padding-micro-y pl-desk-padding-micro-x pr-0',
        },
        mobile: {
          productsListWrapper: 'gap-mob-global-spacing-spacing-2',
          orderDetailWrapper: 'gap-mob-space-between-base-vertical',
          subscriptionsWrappers: 'rounded-mob-global-radius-medium',
          subscriptionsWrapper: 'py-mob-padding-micro-y  px-mob-padding-micro-x',
          noAutoshipMessage: 'py-mob-padding-tight-y',
          subscriptionTopContentWrapper: 'pb-mob-padding-micro-bottom',
          base: 'gap-mob-padding-base-bottom',
          title:
            'text-heading-mob-xLarge-bold font-text-heading-mob-xLarge-bold leading-text-heading-mob-xLarge-bold',
          productsDetailWrapper:
            'pt-mob-padding-micro-y pb-mob-padding-tight-y px-mob-padding-micro-x',
          productListNote: 'mb-mob-padding-micro-bottom',
        },
      },
      isActive: {
        true: {
          subscriptionStatus: 'text-color-global-in-stock',
        },
        false: {
          subscriptionStatus: 'text-system-red',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
export const paymentMethodVariants = tv(
  {
    slots: {
      productImage: 'cursor-pointer h-auto object-contain',
      paymentBase: '',
      nextDeliveryWrapper: '',
      paymentMethodTitle:
        'text-color-text-dark mb-[6px] font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      paymentMethodSubTitle:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular',
      paymentMethodDescription:
        'font-primary text-body-xSmall-regular font-body-xSmall-regular leading-body-xSmall-regular',
      deliveryTip:
        'flex font-primary text-body-large-bold leading-text-body-large-bold font-text-body-large-bold text-color-brand-primary-1-dark',
      editIcon: 'cursor-pointer w-5 h-5 ml-1 [&>svg_path]:fill-color-brand-primary-1-dark',
      address: 'font-primary text-large-regular font-large-regular leading-large-regular',
      phoneNumberWrapper:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-brand-primary-1-dark hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      buttonWrapper: 'mt-desk-space-between-tight-vertical flex flex-col gap-2',
      skipButton:
        'w-full py-desk-component-button-condensed-padding-y flex justify-center text-body-large-bold font-body-large-bold leading-body-large-bold rounded-desk-global-radius-small hover:!no-underline',
      buttonHelper:
        'rte font-primary font-body-small-regular text-body-small-regular leading-body-small-regular text-color-text-dark [&_a]:mx-1 [&_a]:font-body-small-bold [&_a]:text-body-small-bold [&_a]:leading-body-small-bold',
      cancellationWrapper: 'flex w-full justify-between',
      cancellationTitle:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold mb-[6px]',
      cancellationSubTitle:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular ',
      priceWrapper:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold flex justify-between',
      price: '',
      writeAReview:
        'cursor-pointer underline font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      productImageWrapper: 'flex justify-center items-center',
      productName:
        'cursor-pointer p-desk-global-spacing-spacing-3 font-primary text-body-large-regular font-body-large-regular leading-body-large-regular',
      productWrapper:
        'flex flex-col justify-between h-auto border border-color-border-mid px-desk-global-spacing-spacing-2 pt-desk-global-spacing-spacing-2 pb-desk-global-spacing-spacing-4',
      pricesWrapper:
        'flex flex-col gap-mob-space-between-micro-vertical lg:gap-desk-space-between-micro-vertical',
      qtyWrapper:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular',
    },
    variants: {
      size: {
        desktop: {
          productImage: 'my-0 min-w-[140px] w-[140px] max-h-[140px]',
          paymentBase: 'max-w-[147.5px] w-[147.5px]',
          nextDeliveryWrapper: 'w-[217px] mt-0',
          paymentMethodDescription: 'mt-desk-padding-micro-bottom',
          writeAReview: 'mt-desk-space-between-tight-vertical',
          productName: 'p-desk-global-spacing-spacing-3',
          productImageWrapper: 'w-full h-[185px]',
          productWrapper:
            'w-[212px] min-h-[548px] px-desk-global-spacing-spacing-2 pt-desk-global-spacing-spacing-2 pb-desk-global-spacing-spacing-4',
          pricesWrapper: 'mb-6 gap-desk-space-between-micro-vertical',
          buttonWrapper: 'mt-desk-space-between-tight-vertical',
          skipButton:
            'py-desk-component-button-condensed-padding-y rounded-desk-global-radius-small',
        },
        mobile: {
          productImage: 'min-w-[104px] w-[104px] max-h-[104px] my-2',
          paymentBase: 'w-full',
          productImageWrapper: 'w-[120px] h-[120px] min-w-[104px] min-h-[104px]',
          nextDeliveryWrapper: 'w-full -mt-2',
          paymentMethodDescription: 'mt-mob-padding-micro-bottom',
          writeAReview: 'mt-mob-space-between-tight-vertical',
          productName: 'p-mob-global-spacing-spacing-3 pl-0',
          productWrapper:
            'w-full px-mob-global-spacing-spacing-2 pt-mob-global-spacing-spacing-4 pb-mob-global-spacing-spacing-4',
          pricesWrapper: 'mb-6 gap-mob-space-between-micro-vertical',
          buttonWrapper: 'mt-mob-space-between-tight-vertical',
          skipButton: 'py-mob-component-button-condensed-padding-y rounded-mob-global-radius-small',
        },
      },
      strike: {
        true: {
          price: 'line-through',
        },
      },
      showPriceInRed: {
        true: {
          price: '!text-color-accent-red',
        },
      },
      isAutoshipPrice: {
        true: {
          price:
            '!bg-color-brand-autoship px-desk-global-spacing-spacing-2 text-color-text-white rounded-desk-global-radius-small',
        },
      },
      hidePriceWrapper: {
        true: {
          priceWrapper: 'opacity-0',
        },
        false: {
          priceWrapper: 'opacity-100',
        },
      },
      isDisabled: {
        true: {
          skipButton: 'bg-color-brand-primary-1-disabled cursor-not-allowed text-color-text-white',
        },
        false: {
          skipButton:
            'text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white focus:text-color-text-white focus:border-color-brand-primary-1-dark  hover:border-color-brand-primary-1-dark hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
