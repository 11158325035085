export async function handleCTRewardsSignOut() {
  try {
    const response = await fetch('/api/crowdtwist/crowd-twist-auth-sign-out', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Error. Network response was not ok');
    }
    const redirectUrl = await response.json();
    return redirectUrl;
  } catch (error) {
    console.error('Error during sign-in into Crowd Twist: ', error);
  }
}

// export const getStaticProps = async (siteName: string, baseUrl: string) => {
//   try {
//     // const siteName = siteResolver.getByName(config.sitecoreSiteName);
//     // const siteNameKey = siteName.name.toUpperCase() as string;

//     const siteNameKey = siteName.toUpperCase();

//     const urlOfProgramEnv = `CROWD_TWIST_${siteNameKey}_URL_OF_PROGRAM`;
//     const apiKeyEnv = `CROWD_TWIST_${siteNameKey}_API_KEY`;

//     const urlOfProgram = process.env[urlOfProgramEnv];
//     const apiKey = process.env[apiKeyEnv];
//     // const publicUrl = config.publicUrl;
//     const publicUrl = baseUrl;

//     const redirectUrl = '';

//     if (!siteNameKey || !urlOfProgram || !apiKey || !publicUrl) {
//       throw new Error('Error, environment variables are not defined.');
//     }

//     const redirect = `${publicUrl?.trimEnd()}${redirectUrl}`;
//     const data = `redirect=${redirect}${apiKey}`;
//     const md5 = createHash('md5').update(data, 'utf8').digest('hex');

//     const signOutUrl = `${urlOfProgram}/http/v2/auth-sign-out?redirect=${encodeURIComponent(
//       redirect
//     )}&api_sig=${md5}`;

//     return {
//       staticProps: {
//         signOutUrl: signOutUrl,
//       },
//     };
//   } catch (error) {
//     console.error('Error creating Sign-out Url: ', error);
//     return {
//       props: {
//         data: null,
//       },
//     };
//   }
// };
