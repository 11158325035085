import { GetDefaultStoreReturn } from 'src/pages/api/store/get-default-store';
import { GetStoreDetailsReturn } from 'src/pages/api/store/get-store-details';
import { apiRequest } from 'src/utils/apiWrapper';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { SupplierAddresses, Tokens } from 'ordercloud-javascript-sdk';
import { publicURLTrailingSlash } from './publicURLTrailingSlash';
import { isTokenExpired } from 'src/utils/requestDecorator';
export const updateMyStore = async (storeId: string, siteName: string) => {
  try {
    const publicURL = publicURLTrailingSlash();
    const response = await apiRequest<GetStoreDetailsReturn>(
      `${publicURL}api/store/get-store-details?${new URLSearchParams({
        storeId,
        siteName,
      }).toString()}`,
      {
        method: 'GET',
      }
    );
    if (response?.data) {
      const storeId = response.data.storeId;
      // if (storeId) {
      //   localStorage.setItem('storeId', storeId);
      //   const cookies = new Cookies();
      //   cookies.set('supplierId', storeId);
      // }
      if (!storeId) {
        throw new Error('Error: No store found');
      }
      localStorage.setItem('storeId', storeId);
      dispatchEvent(new Event('storeIdChanged'));
      const cookies = new Cookies();
      cookies.set('supplierId', storeId);

      const token = Tokens.GetAccessToken();
      if (token && !isTokenExpired(token)) {
        const supplierAddress = await SupplierAddresses.Get(storeId, `A-${storeId}`);
        response.data.BOPIS = supplierAddress?.xp?.BOPIS;
        response.data.DFS = supplierAddress?.xp?.DFS;
        response.data.StoreType = supplierAddress?.xp?.StoreType;
        response.data.OnlineBooking = supplierAddress?.xp?.OnlineBooking;
        response.data.StoreServices = supplierAddress?.xp?.StoreServices;
      }
      return response?.data;
    }
    return {};
  } catch (error) {
    console.error('Error', error);
    return {};
  }
};

export const getDefaultStore = async (siteName: string) => {
  try {
    const publicURL = publicURLTrailingSlash();
    const response = await apiRequest<GetDefaultStoreReturn>(
      `${publicURL}api/store/get-default-store?siteName=${siteName}`,
      {
        method: 'GET',
      }
    );
    if (response?.storeId) {
      return await updateMyStore(response?.storeId as string, siteName);
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error', error);
    return {};
  }
};

export const getGlobalStoreSettings = async (siteName: string) => {
  try {
    const publicURL = publicURLTrailingSlash();
    const response = await axios.get(
      `${publicURL}api/store/get-global-store-settings?siteName=${siteName}`
    );
    return response.data;
  } catch (error) {
    console.error('Error', error);
    return {};
  }
};
