import ImageHelper from 'components/helpers/ImageHelper';
import { CrowdTwistFieldsProps } from '../LoyaltyTabSection';
import { tv } from 'tailwind-variants';
import Link from 'next/link';

type OldLoyaltyWidgetProps = {
  props: {
    fields: CrowdTwistFieldsProps;
  };
};

const mobileOldLoyaltyWidget = tv(
  {
    slots: {
      base: ['w-full'],
      productRewardsBorder: ['inline-block'],
      productRewardsLink: ['product-rewards-link'],
      productRewardsImage: [''],
    },
    variants: {
      device: {
        mobile: {
          base: ['inline-block', 'overflow-x-auto', 'whitespace-nowrap'],
          productRewardsBorder: ['mr-mob-space-between-tight-horizontal'],
          productRewardsImage: ['w-[158px]'],
        },
        desktop: {
          base: ['overflow-x-auto', 'whitespace-normal'],
          productRewardsBorder: ['mr-5'],
          productRewardsImage: ['w-[230px]'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const { base, productRewardsBorder, productRewardsLink, productRewardsImage } =
  mobileOldLoyaltyWidget({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

const OldLoyaltyWidget = ({ props }: OldLoyaltyWidgetProps) => {
  const {
    availableOffersTileImage,
    inProgressTileImage,
    earnedOffersTileImage,
    oldLoyaltyPageLink,
    availableOffersTileAnchorLink,
    earnedOffersTileAnchorLink,
    inProgressTileAnchorLink,
  } = props.fields;
  return (
    <div className={base()}>
      <div className={productRewardsBorder()}>
        <Link
          aria-label="rewards link"
          href={`${oldLoyaltyPageLink?.value?.href}${availableOffersTileAnchorLink?.value}`}
          className={productRewardsLink()}
        >
          <ImageHelper className={productRewardsImage()} field={availableOffersTileImage} />
        </Link>
      </div>
      <div className={productRewardsBorder()}>
        <Link
          aria-label="rewards link"
          href={`${oldLoyaltyPageLink?.value?.href}${inProgressTileAnchorLink?.value}`}
          className={productRewardsLink()}
        >
          <ImageHelper className={productRewardsImage()} field={inProgressTileImage} />
        </Link>
      </div>
      <div className={productRewardsBorder()}>
        <Link
          aria-label="rewards link"
          href={`${oldLoyaltyPageLink?.value?.href}${earnedOffersTileAnchorLink?.value}`}
          className={productRewardsLink()}
        >
          <ImageHelper className={productRewardsImage()} field={earnedOffersTileImage} />
        </Link>
      </div>
    </div>
  );
};

export default OldLoyaltyWidget;
