import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import ModalWrapper from 'components/helpers/ModalWrapper';
// import LinkHelper from 'components/helpers/LinkHelper';
import ComponentContext from 'lib/context/ComponentContext';
import { useContext, useEffect, useState } from 'react';

import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import { setStore } from 'src/redux/storeDetailsSlice';
import { updateMyStore } from 'src/helpers/StoreHelper';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import useOcCart from 'src/hooks/useOcCart';
import { useSiteName } from 'src/hooks/useSiteName';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'components/helpers/Constants';

export type WarningStoreChangeProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.WarningStoreChange.Fields.WarningStoreChange & {
    children?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
  };

const WarningStoreChangeClass = tv(
  {
    slots: {
      title: 'text-color-text-black font-color-text-black',
      // link: 'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x text-color-text-white hover:text-color-text-white  hover:no-underline bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark rounded-desk-global-radius-small',
      description: 'text-body-large-regular leading-body-large-regular',
      popUpPad: '',
      buttonWrapper: 'flex gap-desk-component-button-condensed-padding-x',
      buttonWithOutline:
        'flex hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-brand-1 justify-center items-center bg-color-background-white border-[2px] border-color-brand-primary-1-base outline-none hover:bg-color-brand-primary-1-dark hover:text-color-text-white focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
      button:
        'flex hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-white justify-center items-center bg-color-brand-primary-1-base outline-none',
    },
    variants: {
      size: {
        mobile: {
          title: '',
          description: '',
          popUpPad: 'py-mob-padding-tight-y px-mob-padding-base-x',
          buttonWrapper: 'mt-mob-margin-base-top',
          button:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
          buttonWithOutline:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
        },
        desktop: {
          title: '',
          description: '',
          popUpPad: 'py-desk-padding-tight-y px-desk-padding-base-x',
          buttonWrapper: 'mt-desk-margin-base-top',
          button:
            'w-fit rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
          buttonWithOutline:
            'w-fit rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);

const { title, description, popUpPad, buttonWrapper, buttonWithOutline, button } =
  WarningStoreChangeClass({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
// Main WarningStoreChange component

export const WarningStoreChange: React.FC<WarningStoreChangeProps> = ({ fields }) => {
  const [isCartEmpty, setIsCartEmpty] = useState<boolean>(true);

  const handleModalClose = () => {
    setcomponentContextData({ ...componentContextData, storeId: false });
  };
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { numberOfItemsInCart } = useOcCart();
  useEffect(() => {
    setcomponentContextData({ ...componentContextData, storeId: false });
  }, []);

  useEffect(() => {
    //Check if cart empty or not
    if ((numberOfItemsInCart() ?? 0) <= 0 && componentContextData?.storeId) {
      setIsCartEmpty(true);
      if (componentContextData?.storeId != myStoreData?.storeId) {
        changeMyStore();
      }
    } else if ((numberOfItemsInCart() ?? 0) > 0 && componentContextData?.storeId) {
      setIsCartEmpty(false);
    }
    // setIsCartEmpty(true);
  }, [componentContextData?.storeId]);
  const dispatch = useOcDispatch();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const siteName = useSiteName();
  const changeMyStore = async () => {
    sendStoreGTMData({
      eventName:
        componentContextData?.fromFulfillment === true
          ? GTM_EVENT?.selectStore
          : GTM_EVENT?.shopThisStore,
      storeId: componentContextData?.storeId,
      storesShown: componentContextData?.storeCount,
    });
    await dispatch(setStore(await updateMyStore(componentContextData?.storeId, siteName)));
    localStorage.removeItem('selected_delivery_address');
    localStorage.removeItem('selected_fulfillment_Method');
    if (componentContextData?.callBackFunctionAfterStoreChange) {
      const callbackFunction = componentContextData?.callBackFunctionAfterStoreChange;
      setcomponentContextData({
        ...componentContextData,
        storeId: false,
        callBackFunctionAfterStoreChange: false,
      });
      callbackFunction();
    } else {
      setcomponentContextData({ ...componentContextData, storeId: false });
    }
  };
  if (fields === null || fields === undefined) return <></>;

  return (
    <div className="WarningStoreChangeModal">
      {componentContextData &&
        componentContextData?.storeId &&
        componentContextData?.storeId != myStoreData?.storeId && (
          <>
            {!isCartEmpty && (
              <ModalWrapper
                additionalClassForModal={'!z-[1000]'}
                showCloseButtonInModalHeader={false}
                closeModalOnOverlayClick={true}
                onCloseClick={handleModalClose}
                showModal={true}
                popupWidth="max-w-[995px]"
                popupSpacing={popUpPad()}
                customPopup
              >
                <div className="flex flex-col gap-desk-space-between-tight-vertical">
                  <RichText className={title()} field={fields?.title} />
                  <RichText className={description()} field={fields?.description} />
                  <div className={buttonWrapper()}>
                    {fields?.primaryLink?.value?.text && (
                      <button
                        aria-label="primary link"
                        onClick={changeMyStore}
                        className={button()}
                      >
                        {fields?.primaryLink?.value.text}
                      </button>
                    )}
                    {fields?.secondaryLink?.value?.text && (
                      <button
                        aria-label="secondary link"
                        className={buttonWithOutline()}
                        onClick={handleModalClose}
                      >
                        {fields?.secondaryLink?.value.text}
                      </button>
                    )}
                  </div>
                </div>
              </ModalWrapper>
            )}
          </>
        )}
    </div>
  );
};

export const Default = (props: WarningStoreChangeProps): JSX.Element => {
  return <WarningStoreChange {...props} />;
};
