import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
// import LinkHelper from 'components/helpers/LinkHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import Link from 'next/link';
import { useOcSelector } from 'src/redux/ocStore';
import { tv } from 'tailwind-variants';

type QuickLinksProps = {
  fields: {
    heading: Field<string>;
    displayName: string;
    links: {
      fields: {
        link: {
          value: {
            href: '';
          };
        };
      };
      name: string;
    }[];
  };
};

const rewardsClass = tv(
  {
    slots: {
      base: ['my-desk-margin-base-bottom'],
      headingWrapper: [
        'py-desk-component-button-condensed-padding-y',
        'px-desk-padding-micro-x',
        'bg-color-background-brand-1',
      ],
      heading: ['text-white', 'font-heading-desk-medium-bold'],
      headingLinkWrapper: ['m-desk-global-spacing-spacing-1'],
      headingLink: ['text-color-brand-primary-1-base', 'font-heading-desk-medium-bold'],
    },
    variants: {
      size: {
        mobile: {},
        desktop: {},
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

const { base, headingWrapper, heading, headingLinkWrapper, headingLink } = rewardsClass({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const PSPQuickLinks = ({ fields }: QuickLinksProps) => {
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);

  if (isLoyaltyAccepted === true) {
    return (
      <div id="quick-links" className={base()}>
        {/* <pre>{JSON.stringify(fields, null, 4)}</pre> */}
        <div className={headingWrapper()}>
          <RichTextHelper className={heading()} field={fields?.heading} />
        </div>
        <ul>
          {fields?.links?.map((item, index) => (
            <li key={index} className={headingLinkWrapper()}>
              <Link
                aria-label="heading link"
                href={item?.fields?.link?.value?.href}
                className={headingLink()}
              >
                {item?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return;
};

export default PSPQuickLinks;
