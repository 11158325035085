export type ShorthandTimeZone =
  | 'EST'
  | 'CST'
  | 'MST'
  | 'PST'
  | 'EDT'
  | 'CDT'
  | 'MDT'
  | 'PDT'
  | 'AST'
  | 'ADT'
  | 'NST'
  | 'NDT'
  | 'HST'
  | 'AKST'
  | 'AKDT'; // Add other shorthand time zones as needed

const timeZoneMappings: Record<ShorthandTimeZone, string> = {
  EST: 'America/New_York', // Eastern Standard Time
  EDT: 'America/New_York', // Eastern Daylight Time
  CST: 'America/Chicago', // Central Standard Time
  CDT: 'America/Chicago', // Central Daylight Time
  MST: 'America/Denver', // Mountain Standard Time
  MDT: 'America/Denver', // Mountain Daylight Time
  PST: 'America/Los_Angeles', // Pacific Standard Time
  PDT: 'America/Los_Angeles', // Pacific Daylight Time
  AST: 'America/Halifax', // Atlantic Standard Time
  ADT: 'America/Halifax', // Atlantic Daylight Time
  NST: 'America/St_Johns', // Newfoundland Standard Time
  NDT: 'America/St_Johns', // Newfoundland Daylight Time
  HST: 'Pacific/Honolulu', // Hawaii Standard Time
  AKST: 'America/Anchorage', // Alaska Standard Time
  AKDT: 'America/Anchorage', // Alaska Daylight Time
  // Add more mappings as needed
};

export const getFullTimeZone = (shorthand: ShorthandTimeZone): string => {
  return timeZoneMappings[shorthand];
};
