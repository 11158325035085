import { tv } from 'tailwind-variants';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
// import clsx from 'clsx';

import React from 'react';
import ButtonHelper from 'components/helpers/Button';

export type AnchorNavigationProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Anchor.Fields.Anchor;
const AnchorNavigationClass = tv(
  {
    slots: {
      base: 'w-full ',
      wrapper: 'container mx-auto flex flex-col gap-desk-space-between-tight-horizontal',
      linkWrapper: ' justify-center flex flex-wrap',
      linkLi: 'flex justify-center hover:underline decoration-color-text-brand-1',
    },
    variants: {
      size: {
        mobile: {
          base: 'py-mob-padding-micro-y',
          linkWrapper: 'gap-mob-space-between-base-horizontal',
          linkLi: '',
        },
        desktop: {
          base: 'py-desk-padding-micro-y ',
          linkWrapper: 'gap-desk-space-between-base-horizontal',
          linkLi: '',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);

const { base, wrapper, linkWrapper, linkLi } = AnchorNavigationClass({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});
// Main AnchorNavigation component

const AnchorNavigation: React.FC<AnchorNavigationProps> = ({ fields }) => {
  if (fields === null || fields === undefined) return <></>;
  return (
    <div className={base()}>
      <div className={wrapper()}>
        <ul className={linkWrapper()}>
          {Array.isArray(fields?.list) &&
            fields?.list?.map((Item, index: number) => {
              return (
                <li key={index} className={linkLi()}>
                  <ButtonHelper
                    isLinkfield={true}
                    variant="link"
                    size="small"
                    field={Item?.fields?.link}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export const Default = (props: AnchorNavigationProps): JSX.Element => {
  return <AnchorNavigation {...props} />;
};
