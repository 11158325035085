import { Field, Text, LinkField, Link as JssLink } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

interface LinkProps {
  field: LinkField;
  linkTitle?: Field<string> | undefined;
  style?: string;
  preTitleElement?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
  postTitleElement?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
}

const Link: React.FC<LinkProps> = ({
  field,
  linkTitle,
  style,
  preTitleElement,
  postTitleElement,
}: LinkProps) => {
  if (!field?.value?.href || field === undefined) {
    return (
      <span className={style}>
        {preTitleElement && <>{preTitleElement}</>}
        <Text field={linkTitle} />
        {postTitleElement && <>{postTitleElement}</>}
      </span>
    );
  } else {
    return (
      <JssLink field={field} className={style}>
        {preTitleElement && <>{preTitleElement}</>}
        {linkTitle && <Text tag="span" field={linkTitle} />}
        {postTitleElement && <>{postTitleElement}</>}
      </JssLink>
    );
  }
};

export default Link;
