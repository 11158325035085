import ItemTreeView, { ItemNode } from './ItemTreeView/ItemTreeView';
import { buttonGroup, button } from '../styles';
import { useEffect, useState } from 'react';
import PersonalizationModalWrapper from 'components/helpers/PersonalizationModalWrapper/PersonalizationModalWrapper';

interface ItemTreeViewModalProps {
  showModal: boolean;
  onCloseClick: () => void;
  onSaveClick: (item?: ItemNode) => void;
  rootNode: ItemNode;
}

export function ItemTreeViewModal({
  showModal,
  onCloseClick,
  onSaveClick,
  rootNode,
}: ItemTreeViewModalProps) {
  const [selectedItem, setSelectedItem] = useState<ItemNode>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (selectedItem && !selectedItem?.hasChildren) {
      setShowConfirmModal(true);
    }
  }, [selectedItem]);

  const handleSaveChanges = () => {
    onSaveClick(selectedItem);
    setShowConfirmModal(false);
  };

  return (
    <PersonalizationModalWrapper
      showModal={showModal}
      onCloseClick={onCloseClick}
      popupWidth="max-w-[900px]"
      closeModalOnOverlayClick
      customPopup
      isInEditor
    >
      <ItemTreeView rootNode={rootNode} onElementSelected={(item) => setSelectedItem(item)} />
      <PersonalizationModalWrapper
        showModal={showConfirmModal}
        onCloseClick={() => setShowConfirmModal(false)}
        popupWidth="max-w-[400px]"
        closeModalOnOverlayClick
        customPopup
        isInEditor
      >
        <h2 className="text-lg font-medium text-center">
          Select content item <strong>{selectedItem?.name}</strong>?
        </h2>
        <div className={`${buttonGroup()} justify-between`}>
          <button
            type="button"
            className={button({ color: 'secondary' })}
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className={button({ color: 'primary' })}
            disabled={!selectedItem?.id}
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        </div>
      </PersonalizationModalWrapper>
    </PersonalizationModalWrapper>
  );
}
