//global
import React from 'react';

//lib
import Head from 'next/head';
import Script from 'next/script';
import { useTheme } from 'lib/context/ThemeContext';

//main component
const BVContainer = () => {
  const { themeNameUpper } = useTheme();

  return (
    <>
      <Head>
        <link rel="canonical" href="/bvcontainer" />
        <meta name="bv:pageType" content="container" />
      </Head>
      <Script
        async
        src={
          themeNameUpper === 'PSP'
            ? process.env.NEXT_PUBLIC_BAZAAR_VOICE_URL || ''
            : process.env.NEXT_PUBLIC_WNW_BAZAAR_VOICE_URL || ''
        }
      />
    </>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default BVContainer;
