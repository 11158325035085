//global
import React from 'react';

import { ComponentProps } from 'lib/component-props';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';

export type SEOMetadataProps = ComponentProps & {
  fields: {
    title: Field<string>;
    description: Field<string>;
    keywords: Field<string>;
  };
};

const SEOMetadata: React.FC<SEOMetadataProps> = ({ fields }) => {
  return (
    <Head>
      <title>{fields?.title?.value?.toString() || 'Page'}</title>
      {fields && fields.description && (
        <meta name="description" content={fields?.description?.value?.toString()} />
      )}
      {fields && fields.keywords && (
        <meta name="keywords" content={fields?.keywords?.value?.toString()} />
      )}
    </Head>
  );
};

export default SEOMetadata;
