// Global
import React, { LegacyRef, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { tv } from 'tailwind-variants';
import { useContentBlock, widget, WidgetDataType } from '@sitecore-search/react';
import { usePathname } from 'next/navigation';
import { Tokens } from 'ordercloud-javascript-sdk';
// Sitecore fields
import {
  Field,
  GetStaticComponentProps,
  ImageField,
  NextImage,
  Placeholder,
  Text,
} from '@sitecore-jss/sitecore-jss-nextjs';

// Local
import DesktopNav from './DesktopMenu';
import MobileNav from './MobileNav';
import UtilityNav from './UtilityNav';
import MobileUtility from './MobileUtility';
import IconHelper from 'components/helpers/IconHelper';
import ImageHelper from 'components/helpers/ImageHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import TextHelper from 'components/helpers/TextHelper';
import OriginalPreviewSearch from 'src/helpers/search/PreviewSearch';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { currentPath, rfkidOf, SiteNameUppercase } from 'components/helpers/Constants';

// Lib
import { useOcSelector } from 'src/redux/ocStore';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useStateAnimate } from 'src/hooks/useStateAnimate';
import ComponentContext from 'lib/context/ComponentContext';
import useOcCart from 'src/hooks/useOcCart';

// Types & interfaces
import { ComponentProps } from 'lib/component-props';
import { Data } from 'lib/types/header';
import headerQuery from 'lib/header.graphql';
import MobileHeaderQuery from 'lib/mobileheader.graphql';
import getProfileImages from './profile.graphql';

export type DefaultData = ComponentProps & {
  uid: string;
  componentName: string;
  dataSource: string;
  fields: { data: Data };
  headerDesktopData: Data;
  headerMobileData: Data;
  profileImages?: ProfileImagesProps;
};

type SpecieDataProps = {
  jsonValue: ImageField;
};

type ProfileImagesProps = {
  item: {
    children: {
      results: {
        Specie: string;
        SpecieData: SpecieDataProps;
      }[];
    };
  };
};

/*
 * Tailwind Variants
 */

export const navigationVariants = tv(
  {
    slots: {
      // *common
      justifyBetween: ['justify-between '],
      justifyCenter: [' justify-center '],
      justifyNormal: ['!justify-normal'],
      itemsEnd: ['!items-end'],
      itemsBaseLine: ['!items-baseline'],
      itemStrech: ['items-strech'],
      itemStart: ['!items-start'],
      flexEnd: ['justify-end'],
      border0: ['!border-0'],
      bodyRegular: [''],
      relative: [''],
      flexColWmax: ['flex-col w-max'],
      wFit: ['w-fit'],
      wFull: ['w-full'],
      w30: ['max-w-[30px] max-h-[30px]'],
      textBlack: ['!text-color-text-black'],
      gap2: ['gap-2'],
      hover: ['hover:text-color-brand-primary-1-base'],
      cursorNone: ['hover:cursor-default'],
      borderBottom: ['border-b-[1px] border-color-scale-5-mid'],
      // *base
      base: ['font-primary'],
      deskBase: ['lg:overflow-visible relative font-primary'],
      // *mobile primary nav
      mobRightWrapper: ['lg:py-3 lg:justify-self-end'],
      mobLeftWrapper: [''],
      mobDivWrapper: [''],
      subMenuWrapper: [''],
      mobileWrapper:
        'lg:hidden leading-5 font-body-large-bold text-body-xLarge-regular overflow-y-auto mt-[0px] bg-color-background-brand-white   z-[11] capitalize text-xxs  overflow-hidden w-full transition-all    duration-700 ease-in-out ',
      mobileNavOpen: [
        'absolute lg:hidden translate-x-full transition-all duration-300 ease-in w-full  left-0 top-0 bg-color-background-white',
      ],
      mobCloseFont: ['text-color-text-brand-1 flex items-center'],
      pr10: ['pr-[10px]'],
      h250: ['h-[250px]'],
      mobIcon: ['text-color-scale-1-white text-2xl'],
      miniCartIcon: ['minicart-icon'],
      closeIcon: ['cursor-pointer [&>svg>*>*]:fill-color-scale-8-dark'],
      fillBlack: ['[&>svg>*>*]:fill-color-scale-8-dark [&>svg>*]:fill-color-scale-8-dark'],
      fillBase: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base [&>svg>*]:fill-color-brand-primary-1-base',
      ],
      iconFit: ['[&>svg]:h-fit'],
      iconSmall: ['w-6 flex'],
      iconXSmall: ['w-4 flex'],
      // * dsktop primary nav
      desktopWrapper:
        'flex py-mob-global-spacing-spacing-3 lg:py-0 leading-5 bg-color-brand-primary-1-base justify-between lg:justify-center flex-wrap items-center px-2 lg:grid lg:grid-cols-6 flex-nowrap mobile-headerlogo-wrapper',
      deskNavItem: ['hidden lg:flex lg:col-span-4 lg:justify-center lg:col-start-2 '],
      deskSubNavWrapper: ['flex flex-col gap-desk-margin-tight-bottom'],
      deskSubNavHeading: [
        'w-full flex flex-col gap-desk-margin-tight-bottom pb-desk-margin-base-bottom',
      ],
      normalFont: ['text-body-small-regular font-body-small-regular leading-body-small-regular'],
      navigationOpen: [
        'z-20 py-desk-padding-tight-top px-desk-global-grid-margin mt-[36px] w-full left-0 bg-color-scale-1-white shadow-lg',
      ],
      liWrapper: [
        'w-full lg:w-fit lg:px-desk-padding-micro-x font-bold text-sm lg:py-desk-padding-micro-y border-b-[1px] lg:border-none',
      ],
      navigationLink: [
        'hover:no-underline lg:text-color-text-white lg:hover:text-color-text-black lg:relative text-body-small-regular font-body-small-regular leading-body-small-regular',
      ],
      navigationClose: ['hidden'],
      navigationAfter: [
        'after:bg-color-background-white after:mt-2 after:absolute after:top-full after:border-[15px] after:border-color-border-white after:left-[50%] after:-translate-x-[50%] after:rotate-45',
      ],
      linkWrapper: ['hover:no-underline'],
      ulWrapper: [
        'w-full justify-between lg:px-0 px-mob-global-grid-margin py-mob-padding-micro-y lg:p-0 lg:col-span-4 border-b-[1px] border-color-border-mid lg:border-0 lg:items-center lg:justify-center text-body-small-regular font-body-small-regular leading-body-small-regular',
      ],
      h4: [
        'text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold font-primary',
      ],
      fontMedium: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      flexGrid: ['items-center w-full justify-between lg:items-start'],
      flexCol: ['flex-col'],
      flexRow: [''],
      mobSubWrappwer: [
        'items-start gap-mob-space-between-tight-horizontal !justify-normal p-mob-global-spacing-spacing-5 pb-mob-global-spacing-spacing-3 pl-mob-global-spacing-spacing-2 border-b-[1px] border-color-border-mid',
      ],
      iconWrapper: ['lg:flex lg:justify-between lg:pb-[25px]'],
      searchwrapper: [
        'lg:py-desk-global-spacing-spacing-1 hidden lg:block px-desk-global-spacing-spacing-2 rounded-desk-global-radius-small placeholder:text-sm w-full',
      ],
      deskHidden: ['lg:hidden gap-mob-space-between-tight-horizontal'],
      leading39: ['leading-[39px]'],
      desktopHeading: ['text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold'],
      desktopIcon: ['!justify-normal gap-desk-space-between-base-vertical'],
      //*mobile utility
      mobUtilityNav: ['lg:hidden fixed w-full bottom-0 z-20'],
      utilityLi: ['text-color-text-white cursor-pointer'],
      utilityOpen: [
        'px-mob-padding-micro-x shadow-menuCard py-mob-padding-tight-top flex-col -z-10 top bg-white w-full h-fit overflow-hidden  text-color-text-black left-0 transition-all duration-1000',
      ],
      utilityImageMaxWidth: ['max-w-10'],
      utilityItem: ['flex-col w-max cursor-pointer'],
      utilityLiOpen: ['py-3 border-b-[1px] border-color-border-mid'],
      utilityUl: [
        'bg-color-brand-primary-1-base py-mob-padding-micro-y px-mob-global-grid-margin bottom-utility-nav',
      ],
      utilityImage: ['left-1/2 -translate-x-1/2'],
      utilityLogo: ['max-w-[50%] lg:max-w-[60%] xl:max-w-full'],
      utilityFont: [
        'text-[12px] xl:text-body-small-regular leading-body-small-bold font-body-xSmall-bold',
      ],
      utilitLinkText: ['cursor-normal text-body-small-regular !border-0'],
      itemGap: ['gap-desk-space-between-micro-vertical'],
      leftUtilityText: ['lg:text-body-small-regular !flex-row !self-end'],
      storeLocatorText: [''],
      bodySmallBold: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      //*desktop utility
      deskUtilityWrapper: [
        'hidden lg:flex py-desk-padding-micro-y lg:px-desk-padding-micro-x px-mob-padding-micro-x',
      ],
      itemWrapper: ['xl:px-[20px] xl:pt-[12px] px-[5px] flex-col items-center'],
      deskUtilityOpen: [
        'flex-col top-full rounded-desk-global-radius-medium shadow-menuCard mt-2 bg-white z-[999] min-w-[378px] px-desk-padding-micro-x py-desk-padding-tight-y',
      ],
      miniCartUtilityOpen: [
        'flex-col top-0 rounded-desk-global-radius-medium shadow-menuCard mt-2 bg-white z-[999] lg:min-w-[378px] w-full md:w-fit md:max-w-[378px] px-desk-padding-micro-x py-desk-padding-micro-y mini-cart-utility-open',
      ],
      img: ['w-auto'],
      utlityServiceHeding: ['text-body-large-bold font-body-large-bold !border-color-border-brand'],
      utlitiCloseIcon: ['top-4 right-4 w-6'],
      //*Store Locator
      storeLocator: [
        'block lg:hidden gap-mob-space-between-micro-horizontal p-mob-global-spacing-spacing-4 text-body-xSmall-regular',
      ],
      numberOfProduct: [
        'bg-color-brand-primary-1-base',
        'rounded-[200px]',
        'h-[16px]',
        'w-[20px]',
        'absolute',
        'right-0',
        'top-0',
        'flex',
        'justify-center',
        'items-center',
        'font-body-small-regular',
        'text-body-small-regular',
        'leading-body-small-regular',
        'text-white', // Token is not there in Figma, need to get replaced with token

        // 'px-6',
      ],
      numberOfMobProduct: [
        'bg-color-background-white',
        'rounded-[200px]',
        'h-[16px]',
        'w-[20px]',
        'absolute',
        'right-[3px]',
        'top-[8px]',
        'flex',
        'justify-center',
        'items-center',
        'font-body-small-regular',
        'text-body-small-regular',
        'leading-body-small-regular',
        'text-color-text-brand-1',

        // 'px-6',
      ],
      miniCartCloseBtn: ['absolute', 'right-0'],
      miniCartCloseBtnIcon: ['[&>svg>*>*]:fill-color-brand-primary-1-base', 'cursor-pointer'],
      uponSearchTrigger: [''],
      shopAllLink: [
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline',
        'ml-[5px]',
      ],
    },
    compoundSlots: [
      {
        slots: [
          'deskUtilityOpen',
          'miniCartUtilityOpen',
          'liWrapper',
          'ulWrapper',
          'flexGrid',
          'itemWrapper',
          'mobUtilityNav',
          'utilityOpen',
          'utlitiCloseIcon',
          'flexCol',
          'flexRow',
          'flexEnd',
          'itemsEnd',
        ],
        class: ['flex'],
      },
      {
        slots: ['justifyBetween', 'justifyCenter'],
        class: ['flex items-center w-full'],
      },
      {
        slots: ['utilityOpen', 'bodyRegular'],
        class: ['text-body-small-regular font-normal'],
      },
      {
        slots: ['leftUtilityText', 'storeLocatorText'],
        class: ['text-body-xSmall-regular'],
      },
      {
        slots: [
          'navigationOpen',
          'utilityOpen',
          'utilityOpen',
          'utilityImage',
          'deskUtilityOpen',
          'utlitiCloseIcon',
        ],
        class: ['absolute'],
      },

      {
        slots: ['desktopWrapper', 'relative'],
        class: ['relative'],
      },
      {
        slots: ['mobRightWrapper', 'mobLeftWrapper'],
        class: ['flex justify-between gap-mob-space-between-tight-horizontal items-center'],
      },
    ],
    variants: {
      isShown: {
        false: {
          mobileWrapper: ['translate-y-full'],
        },
        true: {
          mobileWrapper: [''],
        },
      },
      device: {
        mobile: {
          mobRightWrapper: ['flex-grow flex-shrink-0 basis-0 !w-full'],
        },
        desktop: {
          // mobRightWrapper: ['flex-grow-0 flex-shrink basis-auto w-fit'],
        },
      },
      turnSearchOn: {
        true: {
          uponSearchTrigger: ['!w-fit'],
        },
        false: {
          uponSearchTrigger: ['w-full'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
type BannerData = {
  image_url: Field<string>;
  image_url_mobile: Field<string>;
  heading: Field<string>;
  link: Field<string>;
  clickthrough_url: Field<string>;
  linkTitle: Field<string>;
};
//Personalized Header banner variants
export const PersionalizedHeaderBannerVariaints = tv(
  {
    slots: {
      base: ['HeadingBanner'],
      bannerBase: [''],
      persionalizedBase: [''],
      contentWrapper: [],
      linkWrapper: [
        'group',
        // 'hover:text-color-brand-tertiary-3-base',
        // 'text-color-text-brand-1',
        'text-heading-mob-medium-bold',
        'leading-heading-mob-medium-bold',
        'gap-mob-space-between-micro-horizontal',
        'items-center',
      ],
      deskImg: [''],
      mobImg: ['my-mob-margin-base-top'],
    },
    compoundSlots: [
      {
        slots: ['contentWrapper', 'linkWrapper'],
        class: ['flex'],
      },
      {
        slots: ['deskImg', 'mobImg'],
        class: ['w-full'],
      },
    ],
    variants: {
      device: {
        mobile: {
          base: ['py-mob-padding-micro-y'],
          deskImg: ['hidden'],
          contentWrapper: [
            'py-mob-padding-micro-y',
            'gap-mob-margin-tight-bottom',
            'flex-col',
            'items-start',
            'hidden',
          ],
          // mobImg: ['bl'],
        },
        desktop: {
          base: ['py-desk-padding-micro-y'],
          contentWrapper: ['gap-desk-margin-tight-bottom', 'flex-row', 'block'],

          deskImg: ['block'],
          mobImg: ['hidden'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

//Persionalized Header Banner
const PersionalizedHeaderBannerWithLink: React.FC = (): JSX.Element => {
  const { deskImg, mobImg, contentWrapper, linkWrapper } = PersionalizedHeaderBannerVariaints({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    queryResult: { data: { appearance: { variables: data = [] } = {} } = {} },
  } = useContentBlock();
  const bannerData: BannerData = data as unknown as BannerData;
  return (
    <div>
      <Link href={bannerData?.clickthrough_url?.value || '/'} aria-label="offer-link">
        <>
          {bannerData?.image_url?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url?.value,
                  alt: bannerData?.linkTitle?.value || bannerData?.heading?.value,
                },
              }}
              className={deskImg()}
            />
          )}
          {bannerData?.image_url_mobile?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url_mobile?.value,
                  alt: bannerData?.linkTitle?.value || bannerData?.heading?.value,
                },
              }}
              className={mobImg()}
            />
          )}
        </>
      </Link>
      {bannerData?.heading?.value?.length > 0 || bannerData?.link?.value?.length > 0 ? (
        <div className={contentWrapper()}>
          <Link
            aria-label="banner link"
            href={bannerData?.link?.value || '/'}
            className={linkWrapper()}
          >
            {bannerData?.linkTitle?.value}
          </Link>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
const PersionalizedHeaderBanner: React.FC = (): JSX.Element => {
  const { deskImg, mobImg } = PersionalizedHeaderBannerVariaints({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    queryResult: { data: { appearance: { variables: data = [] } = {} } = {} },
  } = useContentBlock();
  const bannerData: BannerData = data as unknown as BannerData;
  return (
    <div>
      <Link href={bannerData?.clickthrough_url?.value || '/'} aria-label="offer-link">
        <>
          {bannerData?.image_url?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url?.value,
                  alt: bannerData?.linkTitle?.value || "today's offer",
                },
              }}
              className={deskImg()}
            />
          )}
          {bannerData?.image_url_mobile?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url_mobile?.value,
                  alt: bannerData?.linkTitle?.value || "today's offer",
                },
              }}
              className={mobImg()}
            />
          )}
        </>
      </Link>
    </div>
  );
};
//creating widget
export const PersionalizedHeaderBannerWidgetWithLink = widget(
  PersionalizedHeaderBannerWithLink,
  WidgetDataType.BANNER,
  'PersionalizedHeaderBanner'
);
export const PersionalizedHeaderBannerWidget = widget(
  PersionalizedHeaderBanner,
  WidgetDataType.BANNER,
  'PersionalizedHeaderBanner'
);

export default function GlobalHeader({
  rendering,
  profileImages,
  headerDesktopData,
  headerMobileData,
}: DefaultData) {
  const [data, setData] = useState<Data>();
  const [isActive, setisActive] = useState<string>('');
  const [isHamburgerOpenRender, isHamburgerOpenCss, setIsHamburgerOpen, isHamburgerOpenActual] =
    useStateAnimate(false);
  // const [changeStore, setChangeStore] = useState('');
  const [isUtilityOpen, setisUtilityOpen] = useState('');
  const [isMobUtilityOpen, setisMobUtilityOpen] = useState('');
  const [isMobile, setisMobile] = useState<number>(1023);
  const [, setHeight] = useState<number>(0);
  //reference to close the model
  const modelref = useRef<HTMLUListElement>(null);
  const liRef = useRef<HTMLLIElement>(null);
  const storeRef = useRef<HTMLUListElement>(null);
  const mobileStoreRef = useRef<HTMLDivElement | HTMLUListElement>(null);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [isSticky, setIsSticky] = useState(false);
  const { isMobile: mobileDetected, isDesktop: desktopDetected } = useBreakpoints();
  const [globalSearchIcon, setGlobalSearchIcon] = useState<boolean>(false);
  const [, setHeightOffset] = useState(0);
  const [heightOffsetwithoutlogobar, setHeightOffsetWithoutLogoBar] = useState(0);
  //Store Data
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { numberOfItemsInCart, numberOfItemsInCartSoftLogin } = useOcCart();

  //header tailwind-variaints

  const {
    base,
    deskBase,
    navigationLink,
    ulWrapper,
    // searchwrapper,
    mobIcon,
    miniCartIcon,
    mobRightWrapper,
    mobLeftWrapper,
    deskHidden,
    mobUtilityNav,
    utilityLi,
    justifyBetween,
    deskUtilityWrapper,
    img,
    w30,
    utilityUl,
    storeLocator,
    itemWrapper,
    fillBase,
    iconSmall,
    mobileWrapper,
    deskNavItem,
    utilityImage,
    iconFit,
    leftUtilityText,
    mobCloseFont,
    justifyCenter,
    pr10,
    utilityLogo,
    itemsBaseLine,
    storeLocatorText,
    justifyNormal,
    utilityFont,
    cursorNone,
    borderBottom,
    relative,
    flexRow,
    bodySmallBold,
    miniCartUtilityOpen,
    deskUtilityOpen,
    utlitiCloseIcon,
    miniCartCloseBtnIcon,
    numberOfMobProduct,
    desktopWrapper,
    uponSearchTrigger,
  } = navigationVariants({
    isShown: isHamburgerOpenCss,
    device: { initial: 'mobile', lg: 'desktop', md: 'desktop' },
  });

  const isMobileDevice = () => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    return (
      /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) ||
      isTouchDevice
    );
  };

  // Set data based on the previous code
  useEffect(() => {
    const renderDataSource =
      isMobileDevice() && window.innerWidth <= 1023 ? headerMobileData : headerDesktopData;
    setData(renderDataSource);
  }, [headerDesktopData, headerMobileData]);

  //handling scroll to top event
  useEffect(() => {
    setHeight(document.getElementById('mobileNav')?.scrollHeight || 0);
    isHamburgerOpenActual || isMobUtilityOpen !== ''
      ? ((document.body.style.overflowY = 'hidden'),
        (document.getElementsByTagName('html')[0].style.overflow = 'hidden'),
        window.scrollTo({
          top: 0,
        }))
      : ((document.body.style.overflowY = ''),
        (document.getElementsByTagName('html')[0].style.overflow = ''));
  }, [isHamburgerOpenActual, isMobUtilityOpen]);
  //handling mobile device state
  useEffect(() => {
    const handleResize = () => {
      setisMobile(window?.innerWidth);
      if (window?.innerWidth >= 1024) {
        setIsHamburgerOpen(false);
        setisMobUtilityOpen('');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);
  //To detect outside click and close the primary navigation model
  useEffect(() => {
    function handleDivModel(event: MouseEvent) {
      const utilityRef = document?.querySelectorAll('[data-component-section=deskutility');
      const clickedOutside = Array.from(utilityRef).every(
        (ref) => !ref.contains(event.target as Node)
      );
      if (clickedOutside) {
        setisActive('');
      }
    }
    document.addEventListener('mouseup', handleDivModel);
    return () => {
      document.removeEventListener('mouseup', handleDivModel);
    };
  }, [modelref, isActive]);
  useEffect(() => {
    function handleLiModel(e: MouseEvent) {
      const utilityRef = document?.querySelectorAll('#utility');
      const minicartRef = document?.querySelectorAll('.mini-cart-utility-open');
      // const minicartIconRef = document?.querySelectorAll('.minicart-icon');

      const clickedOutside = Array.from(utilityRef).every((ref) => !ref.contains(e.target as Node));
      const clickedOutsideMinicart = Array.from(minicartRef).every(
        (ref) => !ref.contains(e.target as Node)
      );
      if (clickedOutside && clickedOutsideMinicart && !liRef.current?.contains(e.target as Node)) {
        if (componentContextData?.openMiniCart && !componentContextData?.showDeliveryThreashold) {
          setisUtilityOpen('');
        } else if (
          (isUtilityOpen !== 'My Cart' && componentContextData?.openMiniCart) ||
          !componentContextData?.showDeliveryThreashold
        ) {
          setisUtilityOpen('');
        }
        document.body.classList.remove('overflow-hidden');
      }
    }
    document.addEventListener('mouseup', handleLiModel);
    return () => {
      document.removeEventListener('mouseup', handleLiModel);
    };
  }, [componentContextData?.showDeliveryThreashold]);
  /***
   * Close the chnage my store pop up when click outside;
   */

  useEffect(() => {
    function handleDivModel(event: MouseEvent) {
      if (
        mobileStoreRef &&
        storeRef &&
        !mobileStoreRef?.current?.contains(event.target as Node) &&
        !storeRef?.current?.contains(event.target as Node)
      ) {
        componentContextData?.isChangeStoreOpen === true
          ? setcomponentContextData({
              ...componentContextData,
              isChangeStoreOpen: false,
            })
          : null;
      }
    }
    document.addEventListener('mouseup', handleDivModel);
    return () => {
      document.removeEventListener('mouseup', handleDivModel);
    };
  }, [mobileStoreRef, storeRef, componentContextData?.isChangeStoreOpen]);

  const pathName = usePathname();

  //handling mobile menu
  const handleHeaderMenu = () => {
    setIsHamburgerOpen(!isHamburgerOpenActual);
    setisMobUtilityOpen('');
    setisUtilityOpen((prev) => (prev?.length > 0 ? '' : ''));
    document.getElementById('desknav')?.classList.toggle('!overflow-x-hidden');
  };
  //Open and close the Desktop Utility
  const handleisDeskUtilityOpen = (val: string) => {
    setIsHamburgerOpen(false);
    setisActive('');
    isUtilityOpen === val ? setisUtilityOpen('') : setisUtilityOpen(val);
    val == 'My Cart'
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  };
  //Open and close the Mobile Utility
  const handleismobUtilityOpen = (val: string) => {
    setisUtilityOpen('');
    setIsHamburgerOpen(false);
    val === isMobUtilityOpen ? setisMobUtilityOpen('') : setisMobUtilityOpen(val);
  };
  //Desktop Primary nav toggle
  const handleisActive = (val: string) => {
    setisUtilityOpen('');
    setisMobUtilityOpen('');
    setisActive(val);
  };

  // Minicart position fixes
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('header');
      const headerBottom = header?.getBoundingClientRect().bottom;

      if (headerBottom !== undefined && headerBottom <= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const shouldRenderSearch = pathName !== currentPath?.isSearchPage;

  const activateSearchonMobileFunc = () => {
    setGlobalSearchIcon(!globalSearchIcon);
  };

  const [isSoftLoginEnabled, setIsSoftLoginEnabled] = useState<boolean>(false);
  const localUserToken = Tokens.GetAccessToken();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (sessionStorage.getItem('isSoftLoginEnabled') == 'true') {
        setIsSoftLoginEnabled(true);
      } else {
        setIsSoftLoginEnabled(false);
      }
    }
  }, [localUserToken]);

  useEffect(() => {
    // Function to calculate height of elements by class names
    const calculateHeight = (classNames: string[]): number => {
      const innerHeight = window?.innerHeight;
      const outerHeight = window?.outerHeight;
      const navHeight = outerHeight - innerHeight;
      return classNames.reduce((totalHeight, className) => {
        const element = document.querySelector(`.${className}`) as HTMLElement | null;
        if (element) {
          return totalHeight + element.offsetHeight;
        }
        return totalHeight + navHeight;
      }, 0);
    };

    // Function to recalculate heights and update state
    const recalculateHeights = () => {
      const classNamesToCheck = [
        'bottom-utility-nav',
        'mobile-headerlogo-wrapper',
        'alert-promo-banner',
      ];
      // const classNamesToCheckWithoutLogoBar = ['bottom-utility-nav', 'alert-promo-banner'];

      const totalHeight = calculateHeight(classNamesToCheck);
      // const totalHeightWithoutLogoBar = calculateHeight(classNamesToCheckWithoutLogoBar);

      setHeightOffset(totalHeight);
      setHeightOffsetWithoutLogoBar(totalHeight);
      // setHeightOffsetWithoutLogoBar(totalHeightWithoutLogoBar - 20);
    };

    // Initial calculation of heights
    recalculateHeights();

    // Set up a MutationObserver to monitor changes to the DOM
    const observer = new MutationObserver(() => {
      recalculateHeights(); // Recalculate when mutations occur
    });

    // Observe changes in the body (or a more specific parent if needed)
    const targetNode = document.body;
    observer.observe(targetNode, {
      childList: true,
      subtree: true,
    });

    // Cleanup the observer when the component unmounts
    return () => observer.disconnect();
  }, [isHamburgerOpenRender]);

  const RenderItemCount: React.FC = () => {
    const cartCount = isSoftLoginEnabled
      ? numberOfItemsInCartSoftLogin() !== undefined
        ? numberOfItemsInCartSoftLogin()
        : '0'
      : numberOfItemsInCart() !== undefined
      ? numberOfItemsInCart()
      : '0';
    return <span className={numberOfMobProduct()}>{cartCount}</span>;
  };

  /*
   * Rendering
   */

  return (
    <div id="header" className={base()}>
      {/**Utility Navigation */}

      <div className={clsx(deskUtilityWrapper(), justifyBetween())}>
        {/**Left Utility nav */}
        <div>
          <ul className={clsx(justifyBetween(), itemsBaseLine())}>
            <li className={clsx(itemWrapper({ className: 'mb-1' }), leftUtilityText())}>
              <ul className={clsx(relative())} id={'changeMyStore'} ref={storeRef}>
                <li
                  className={clsx(justifyBetween({ className: 'cursor-pointer' }))}
                  onClick={() => {
                    setcomponentContextData({
                      ...componentContextData,
                      isChangeStoreOpen: !componentContextData?.isChangeStoreOpen,
                    });
                  }}
                >
                  <div className={img()}>
                    <NextImage
                      field={data?.datasource?.locationLogo?.jsonValue}
                      height={30}
                      width={30}
                      className={w30()}
                    />
                  </div>
                  <div>
                    <Text
                      tag={'p'}
                      field={data?.datasource?.locationText}
                      className={storeLocatorText()}
                    />
                    <div className={clsx(flexRow(), itemsBaseLine())}>
                      <span className={utilityFont()}>
                        {myStoreData?.storeName}, {myStoreData?.state}
                      </span>
                      <IconHelper icon={'Caret'} className={fillBase({ className: 'ml-2' })} />
                    </div>
                  </div>
                </li>
                <li className={clsx(deskUtilityOpen({ className: '!p-0 min-w-[425px]' }))}>
                  {rendering && <Placeholder name="change-store-widget" rendering={rendering} />}
                </li>
              </ul>
            </li>
            {data?.datasource?.desktopLeftUtilityNav?.targetItems?.map((links, index) => {
              {
                /** Left utility Navigation*/
              }
              return (
                <UtilityNav
                  liref={liRef}
                  key={links?.name}
                  item={links}
                  direction={'left'}
                  isUtilitiOpen={isUtilityOpen}
                  setisutilitiOpen={handleisDeskUtilityOpen}
                  navIndex={index}
                  rendering={rendering}
                />
              );
            })}
          </ul>
        </div>
        <div className={utilityImage()}>
          {data?.datasource?.logoLink && (
            <LinkHelper field={data?.datasource?.logoLink?.jsonValue} hideLinkInEE editable={false}>
              {/** utility Navigation Logo */}
              <div className={justifyCenter()}>
                {
                  <NextImage
                    field={data?.datasource?.desktopLogo?.jsonValue}
                    height={68}
                    width={302}
                    className={utilityLogo()}
                    priority
                  />
                }
              </div>
            </LinkHelper>
          )}
        </div>
        <div>
          {/**Right Utility nav */}
          <ul className={clsx(justifyBetween(), itemsBaseLine())}>
            {data?.datasource?.desktopRightUtilityNav?.targetItems?.map((links, index) => {
              {
                /** Right utility navigation */
              }
              return (
                <UtilityNav
                  liref={liRef}
                  key={links?.name}
                  item={links}
                  direction="right"
                  rendering={rendering}
                  isUtilitiOpen={isUtilityOpen}
                  setisutilitiOpen={handleisDeskUtilityOpen}
                  profileImages={profileImages?.item?.children?.results}
                  navIndex={index}
                />
              );
            })}
          </ul>
        </div>
      </div>
      {/**Primar Navigation */}
      {/* <div className={deskBase({ className: 'overflow-x-hidden' })} id={'desknav'}> */}
      <div className={deskBase()} id={'desknav'}>
        {/**Desktop Navigation */}
        <div className={desktopWrapper()}>
          {isMobile && isMobile < 1024 && (
            <div className={mobLeftWrapper({ className: 'globalHeader' })}>
              <div
                className={deskHidden()}
                onClick={() => {
                  handleHeaderMenu();
                }}
              >
                {!isHamburgerOpenActual ? (
                  <IconHelper icon={'menu'} className={mobIcon()} />
                ) : (
                  <IconHelper icon={'close'} className={mobIcon()} />
                )}
              </div>
              {data?.datasource?.logoLink.jsonValue && !globalSearchIcon && (
                <LinkHelper
                  field={data?.datasource?.logoLink.jsonValue}
                  hideLinkInEE
                  className={cursorNone()}
                  editable={false}
                >
                  <div className={deskHidden()}>
                    <NextImage field={data?.datasource?.mobileLogo?.jsonValue} />
                  </div>
                </LinkHelper>
              )}
            </div>
          )}
          <ul className={clsx(ulWrapper({ className: 'flex-wrap' }), deskNavItem())}>
            {data?.datasource?.desktopPrimaryNav?.targetItems?.map((item, index) => {
              return (
                <DesktopNav
                  key={item?.name}
                  item={item}
                  modelref={modelref}
                  isActive={isActive}
                  handleisActive={handleisActive}
                  parentIndex={index}
                  rendering={rendering}
                />
              );
            })}
          </ul>
          <div
            className={clsx(
              mobRightWrapper(),
              {
                '!justify-end': !shouldRenderSearch,
                'lg:mr-[24px]': shouldRenderSearch,
                '[&>div>form>div]:!w-full': shouldRenderSearch && desktopDetected, // for avoiding wrap with DesktopNav items
              },
              'globalSearch'
            )}
          >
            {/* <input aria-label="input" type="text" placeholder="Search" className={searchwrapper()} /> */}
            {shouldRenderSearch && mobileDetected && globalSearchIcon && (
              <OriginalPreviewSearch
                rfkId={rfkidOf?.search}
                isSearchBar={true}
                triggerSearchOnMobile={globalSearchIcon}
                activateSearchonMobile={activateSearchonMobileFunc}
              />
            )}
            {shouldRenderSearch && desktopDetected && (
              <OriginalPreviewSearch
                rfkId={rfkidOf?.search}
                isSearchBar={true}
                triggerSearchOnMobile={globalSearchIcon}
              />
            )}
            <div
              className={clsx(
                deskHidden(),
                justifyBetween(),
                uponSearchTrigger({ turnSearchOn: globalSearchIcon }),
                '!justify-end'
              )}
            >
              {!globalSearchIcon && shouldRenderSearch && (
                <IconHelper
                  icon={'search'}
                  className={mobIcon()}
                  onClick={() => {
                    setGlobalSearchIcon(true);
                  }}
                />
              )}
              {/* Cart Icon for Mobile */}
              <IconHelper
                icon={'shopping-cart'}
                className={clsx(mobIcon(), miniCartIcon(), {})}
                onClick={() => {
                  if (isUtilityOpen === 'My Cart') {
                    document.body.classList.remove('overflow-hidden');
                    setisUtilityOpen('');
                  } else {
                    document.body.classList.add('overflow-hidden');
                    setcomponentContextData({
                      ...componentContextData,
                      showDeliveryThreashold: false,
                      openMiniCart: true,
                    });
                  }
                }}
              />
              <RenderItemCount />
              {isUtilityOpen == 'My Cart' ? (
                <>
                  <div className={isUtilityOpen === 'My Cart' ? 'backdrop-shadow' : ''}></div>
                  <li
                    //ref={liref as React.LegacyRef<HTMLLIElement>}
                    className={clsx(
                      `sticky-div ${isSticky ? 'fixed top-0' : 'absolute top-full'}`,
                      miniCartUtilityOpen(),
                      'right-0'
                    )}
                  >
                    <button
                      aria-label={'close the menu'}
                      //className={(flexEnd(), miniCartCloseBtn)()}
                    >
                      <IconHelper
                        icon={'close'}
                        className={clsx(utlitiCloseIcon(), miniCartCloseBtnIcon())}
                        onClick={() => {
                          setcomponentContextData({
                            ...componentContextData,
                            openMiniCart: false,
                            showMinicartLoader: false,
                          });
                          handleisDeskUtilityOpen('');
                        }}
                      />
                    </button>

                    <div className={justifyBetween()}>
                      {rendering && <Placeholder name="mini-cart" rendering={rendering} />}
                    </div>
                  </li>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {/**Mobile Store Locator */}
        {!isHamburgerOpenRender && (
          <div
            className={clsx(relative({ className: 'block lg:hidden mobileStoreLocator' }))}
            ref={mobileStoreRef as LegacyRef<HTMLDivElement>}
          >
            <div
              className={clsx(storeLocator(), justifyBetween(), justifyNormal())}
              onClick={() => {
                setcomponentContextData({
                  ...componentContextData,
                  isChangeStoreOpen: !componentContextData?.isChangeStoreOpen,
                }),
                  setIsHamburgerOpen(false);
              }}
            >
              <div>
                <NextImage
                  field={data?.datasource?.locationLogo?.jsonValue}
                  height={20}
                  width={20}
                />
              </div>
              <Text field={data?.datasource?.locationText} />
              <span className={bodySmallBold()}>{myStoreData?.storeName}</span>
              <IconHelper icon={'Caret'} className={fillBase({ className: 'ml-1' })} />
            </div>
            <li
              className={clsx(
                deskUtilityOpen({
                  className: '!p-0 w-full !min-w-0 !mt-0',
                })
              )}
            >
              {rendering && <Placeholder name="change-store-widget" rendering={rendering} />}
            </li>
          </div>
        )}

        {/**Mobile Navigation */}
        {isMobile && isMobile <= 1024 && isHamburgerOpenRender && (
          <div
            className={clsx(mobileWrapper({ className: 'mobileNavigation overflow-y-auto' }))}
            id={'mobileNav'}
            style={{ paddingBottom: `330px`, height: '100vh' }}
          >
            <ul
              className={clsx(ulWrapper(), borderBottom())}
              onClick={() => {
                setIsHamburgerOpen(false);
              }}
            >
              <li className={clsx(navigationLink(), justifyBetween())}>
                {data?.datasource?.title && <TextHelper tag="p" field={data?.datasource?.title} />}
                <div className={mobCloseFont()}>
                  {data?.datasource?.closeText && (
                    <TextHelper tag="p" field={data?.datasource?.closeText} className={pr10()} />
                  )}
                  <IconHelper icon={'close'} className={clsx(fillBase(), iconSmall(), iconFit())} />
                </div>
              </li>
            </ul>
            {data?.datasource?.mobilePrimaryNav?.targetItems?.map((item, index) => {
              return (
                item && (
                  <MobileNav
                    setIsHamburger={setIsHamburgerOpen}
                    key={item?.name}
                    item={item}
                    height={heightOffsetwithoutlogobar}
                    isActive={isActive}
                    backText={data?.datasource?.backText}
                    parentIndex={index}
                    rendering={rendering}
                  />
                )
              );
            })}
            {/* <div className={h250()}></div> */}
          </div>
        )}
      </div>

      {/**Mobile Utilitynav */}
      <div className={mobUtilityNav({ className: 'bottom-utility-nav' })}>
        <ul className={clsx(justifyBetween(), utilityUl())}>
          {data?.datasource?.mobileUtilityNav?.targetItems?.map((mutility) => {
            return (
              <MobileUtility
                key={mutility?.name}
                item={mutility}
                handleisUtilityOpen={handleismobUtilityOpen}
                isUtilityOpen={isMobUtilityOpen}
                profileImages={profileImages?.item?.children?.results}
              />
            );
          })}
          <li className={utilityLi()}>
            <div onClick={() => handleHeaderMenu()}>
              <NextImage field={data?.datasource?.moreLogo?.jsonValue} />
              <Text field={data?.datasource?.moreText} />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData, context) => {
  if (rendering.dataSource && layoutData?.sitecore?.context?.language) {
    // Header Data
    const graphQLClient = graphqlClientFactory({});
    const desktopResult = await graphQLClient.request<Data>(headerQuery, {
      datasource: rendering.dataSource,
      params: rendering.params,
      language: layoutData?.sitecore?.context?.language,
      itemID: layoutData?.sitecore?.route?.itemId,
    });

    const mobileResult = await graphQLClient.request<Data>(MobileHeaderQuery, {
      datasource: rendering.dataSource,
      params: rendering.params,
      language: layoutData?.sitecore?.context?.language,
      itemID: layoutData?.sitecore?.route?.itemId,
    });

    // Profile Image with Specie Personalization
    const endpointConfig = process.env.GRAPH_QL_ENDPOINT;
    const graphQLApiKey = process.env.SITECORE_API_KEY;
    const sitecore = layoutData?.sitecore;
    const sitecoreContext = sitecore?.context;
    const siteName = sitecoreContext?.site?.name as string;
    const siteNameUpper = siteName?.toUpperCase() as SiteNameUppercase;

    if (endpointConfig && graphQLApiKey) {
      const graphQLClient = graphqlClientFactory({});

      let datasourceItem: string;

      if (siteNameUpper === 'PSP') {
        datasourceItem = '{27A2AD57-966E-4EC6-8EE9-F25945F4C151}';
      } else {
        datasourceItem = '{DC436F5A-3AFC-4110-8220-8B493379D6B6}';
      }
      const data = await graphQLClient.request<ProfileImagesProps>(
        getProfileImages(datasourceItem),
        {
          datasource: rendering?.dataSource,
          language: layoutData?.sitecore?.context?.language,
        }
      );

      return {
        headerDesktopData: desktopResult,
        headerMobileData: mobileResult,
        profileImages: data,
      };
    }
  }
  throw new Error(
    `No datasource set for rendering: ${rendering.componentName}, Guid: ${
      layoutData.sitecore.route?.itemId
    }, Path: ${
      context.params?.path instanceof Array ? context.params.path.join('/') : context.params?.path
    } `
  );
};
