import React from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import AccountManagementSplitter from './AccountManagement/AccountManagementSplitter';

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
}

const columnSplitterStylings = tv(
  {
    slots: {
      rowClasses: ['flex', 'bg-color-background-light'],
      columnClasses: [],
    },
    variants: {
      device: {
        mobile: { rowClasses: ['gap-mob-margin-loose-bottom', 'flex-col'] },
        desktop: {
          rowClasses: ['gap-desk-margin-loose-bottom', 'flex-row'],
          columnClasses: ['flex', 'flex-wrap', 'w-full'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

export const Default = (props: ComponentProps): JSX.Element => {
  const { columnClasses, rowClasses } = columnSplitterStylings({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const styles = `${props.params.GridParameters ?? ''} ${props.params.Styles ?? ''}`.trimEnd();
  const columnWidths = [
    props.params.ColumnWidth1,
    props.params.ColumnWidth2,
    props.params.ColumnWidth3,
    props.params.ColumnWidth4,
    props.params.ColumnWidth5,
    props.params.ColumnWidth6,
    props.params.ColumnWidth7,
    props.params.ColumnWidth8,
  ];
  const columnStyles = [
    props.params.Styles1,
    props.params.Styles2,
    props.params.Styles3,
    props.params.Styles4,
    props.params.Styles5,
    props.params.Styles6,
    props.params.Styles7,
    props.params.Styles8,
  ];
  const enabledPlaceholders = props.params.EnabledPlaceholders.split(',');
  const id = props.params.RenderingIdentifier;
  const splitStyles = styles.split(' ');

  if (splitStyles && splitStyles.includes('myaccount-splitter')) {
    return <AccountManagementSplitter props={props} />;
  }

  return (
    <>
      {splitStyles && splitStyles.includes('login-splitter') ? (
        <div
          className={`row component column-splitter ${styles} ${rowClasses()}`}
          id={id ? id : undefined}
        >
          {enabledPlaceholders.map((ph, index) => {
            const phKey = `column-${ph}-{*}`;
            const phStyles = `${columnWidths[+ph - 1]} ${columnStyles[+ph - 1] ?? ''}`.trimEnd();
            return (
              <div key={index} className={`${phStyles} ${columnClasses()}`}>
                <div key={index} className="row">
                  <Placeholder key={index} name={phKey} rendering={props.rendering} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={`row component column-splitter ${styles} `} id={id ? id : undefined}>
          {enabledPlaceholders.map((ph, index) => {
            const phKey = `column-${ph}-{*}`;
            const phStyles = `${columnWidths[+ph - 1]} ${columnStyles[+ph - 1] ?? ''}`.trimEnd();

            return (
              <div key={index} className={phStyles}>
                <div key={index} className="row">
                  <Placeholder key={index} name={phKey} rendering={props.rendering} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
