/* eslint-disable @typescript-eslint/no-explicit-any */
// Global
import { Formik, Form as FormikForm, FormikValues, FormikHelpers } from 'formik';
// Utils and Form Helper
import TextField from 'components/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import { useState, useEffect, useContext, useRef } from 'react';
// Tailwind Import
import billingAddress from './BillingTailwindVariant';
import CheckboxField from 'components/helpers/Form/CheckboxField';
import { ComponentProps } from 'lib/component-props';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import DropdownField from 'components/helpers/Form/DropdownField';
import useDictionary from 'src/hooks/useDictionary';
import { Order, Me, BuyerAddress } from 'ordercloud-javascript-sdk';
import { useOcSelector, useOcDispatch } from 'src/redux/ocStore';
import { saveBillingAddress } from 'src/redux/ocCurrentOrder';
import deliveryAddress from '../DeliveryAddress/DeliveryAddressTailwindVariant';
import ComponentContext from 'lib/context/ComponentContext';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import {
  DiscreteLineItem,
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  addressType,
} from 'components/helpers/Constants';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';
// import IconHelper from 'components/helpers/IconHelper';
import AutoCompleteWrapper from 'components/helpers/Form/AutoCompleteWrapper';
import clsx from 'clsx';
import { saveAddress } from 'src/redux/ocAddressBook';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import Loader from 'components/Loader/Loader';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';

export type BillingAddressProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          submitButtonText: Field<string>;
          successMessage: Field<string>;
          successRedirectUrl?: { jsonValue: LinkField };
          failureMessage?: Field<string>;
          consentMessage?: Field<string>;
          errors: ErrorField;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
        };
        states?: {
          regions: {
            targetItems: State[];
          };
        };
      };
    };
  };
interface ErrorField {
  values: {
    name: string;
    value: string;
  }[];
}
interface State {
  code: { value: string };
  name: { value: string };
}
const BillingAddress = ({ fields }: BillingAddressProps): JSX.Element => {
  // Tailwind Variant
  const {
    base,
    title,
    form,
    pickupInformationContainer,
    validFieldInfo,
    inlineFields,
    submitBtn,
    loaderWrapper,
    fieldWrapper,
    linkWithUnderline,
    blockTitle,
    profileCheckbox,
    sameDelivery,
  } = billingAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { titleWrapper, addressBlock } = deliveryAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  interface FormValuesTypes {
    FirstName: string;
    LastName: string;
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    State: string;
    Zip: string;
    SaveToProfile?: string;
  }
  // Get form-fields

  const transFormFields = transformData(fields.data?.data?.formFields);
  const { getDictionaryValue } = useDictionary();
  const [loading, setLoading] = useState<boolean>(false);
  const { componentContextData, setExpandedForm } = useContext(ComponentContext);

  const formRef = useRef<HTMLDivElement>(null);

  const [collapse, setCollapse] = useState<boolean>(
    componentContextData?.showBillingAddressForm === true ? false : true
  );

  const [copyAddress] = useState<boolean>(false);
  const [disableFields, setDisableFields] = useState(false);
  const [initialValues, setInitialValue] = useState<any>({
    FirstName: '',
    LastName: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    Zip: '',
    SaveToProfile: 'true',
  });

  const autoCompleteFieldMap = {
    addressLine1: 'AddressLine1',
    addressLine2: 'AddressLine2',
    city: 'City',
    stateCode: 'State',
    zipcode: 'Zip',
  };
  const empty = <div id="billingaddress" className="billingaddress" hidden></div>;
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const [addresses, setAddresses] = useState<BuyerAddress<any>>();
  const cart = useOcSelector((state: any) => state?.ocCurrentOrder?.order) as Order;
  const currentOrder = useOcSelector((state: any) => state?.ocCurrentOrder);
  const billingAdd = useOcSelector((state) => state?.ocCurrentOrder?.order?.BillingAddress);
  const ocCurrentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  // const lineItems = useSelector((state: any) => state?.ocCurrentOrder?.lineItems);
  const shippingAddress = productlineitems?.[0]?.ShippingAddress;
  const { billingAddressForm } = useCheckoutFormContext() || {};

  const [saveAndCallBillingAddress, setSaveAndCallBillingAddress] = useState(false);
  const [userDetailsBillingAdd, setUserDetailsBillingAdd] = useState<any>();

  //For GTM
  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const getUserDetails = async () => {
      const listAdd = await Me.ListAddresses({ sortBy: ['DateCreated'] });
      setAddresses(listAdd?.Items?.filter((item) => item?.AddressName === addressType?.billing)[0]);
      if (listAdd) {
        // user has some save addresses:
        listAdd?.Items.forEach((item) => {
          if (item?.AddressName === 'Billing' && item?.Billing === true) {
            // if user has saved billing address:
            setInitialValue({
              ...initialValues,
              FirstName: item?.FirstName ?? '',
              LastName: item?.LastName ?? '',
              AddressLine1: item?.Street1 ?? '',
              AddressLine2: item?.Street2 ?? '',
              City: item?.City ?? '',
              State: item?.State ?? '',
              Zip: item?.Zip ?? '',
            });
            // TODO: Need to enhance this condition:
            setUserDetailsBillingAdd({
              ...initialValues,
              FirstName: item?.FirstName ?? '',
              LastName: item?.LastName ?? '',
              AddressLine1: item?.Street1 ?? '',
              AddressLine2: item?.Street2 ?? '',
              City: item?.City ?? '',
              State: item?.State ?? '',
              Zip: item?.Zip ?? '',
              Country: 'US',
              Billing: true,
              AddressName: addressType?.billing,
            });

            if (!billingAdd) {
              setSaveAndCallBillingAddress(true);
            }
          }
        });
      }
      return listAdd;
    };
    if (!isAnonymous && !billingAdd?.FirstName && ocCurrentOrder?.initialized == true) {
      // Logged-in user but BA not saved on Cart.
      getUserDetails();
    } else {
      // guest
      setInitialValue({
        ...initialValues,
        FirstName: billingAdd?.FirstName ?? '',
        LastName: billingAdd?.LastName ?? '',
        AddressLine1: billingAdd?.Street1 ?? '',
        AddressLine2: billingAdd?.Street2 ?? '',
        City: billingAdd?.City ?? '',
        State: billingAdd?.State ?? '',
        Zip: billingAdd?.Zip ?? '',
      });
    }

    // new condition from below:
    if (!billingAdd) {
      if (componentContextData?.expandedForm == 'billing') {
        setCollapse(false);
        setExpandedForm('billing');
        //if BillingAddress is not present, open that form:
        // setCollapse(true);
      } else {
        setCollapse(true);
      }
    } else {
      setCollapse(true);
    }
  }, [
    copyAddress,
    billingAdd?.Street1,
    componentContextData?.expandedForm,
    ocCurrentOrder?.initialized,
  ]);

  const dispatch = useOcDispatch();
  // Set Initial value
  // To manage between current form validation states & other Forms:
  const checkoutForm = useCheckoutFormContext();
  const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;

  useEffect(() => {
    if (componentContextData?.expandedForm == 'billing') {
      setCollapse(false);
      setTimeout(() => {
        const billingTarget = document.getElementById('billingaddress');
        billingTarget && billingTarget.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else {
      if (billingAdd) {
        setCollapse(true);
      } else if (
        !billingAdd &&
        componentContextData?.expandedForm
        // && componentContextData?.expandedForm !== 'billing'
      ) {
        // collapse billing as it doesn't have data and is not current form
        setCollapse(true);
      } else {
        setCollapse(false);
        logicToOpenBillForm();
      }
    }
  }, [componentContextData?.expandedForm]);

  const logicToOpenBillForm = () => {
    try {
      setExpandedForm('billing');
      setCollapse(false);
      const billingTarget = document.getElementById('billingaddress');
      billingTarget && billingTarget.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('---ERROR::::::,', error);
    }
  };

  useEffect(() => {
    if (componentContextData?.expandedForm == 'billing') {
      setCollapse(false);
    }
  }, [componentContextData?.expandedForm]);

  /**
   * Logic to automatically save the Billing form info if user is authenticated
   */
  useEffect(() => {
    if (
      !isAnonymous &&
      ocCurrentOrder?.initialized == true &&
      saveAndCallBillingAddress &&
      componentContextData?.expandedForm == 'billing' &&
      userDetailsBillingAdd?.AddressLine1 &&
      userDetailsBillingAdd?.City &&
      !(billingAdd?.FirstName && billingAdd?.Street1 && billingAdd?.Zip)
    ) {
      // Call Save BillingAddress and collapse it.
      submitBillingAddress(userDetailsBillingAdd);
      setCollapse(true);
    }
  }, [componentContextData?.expandedForm, saveAndCallBillingAddress, ocCurrentOrder?.initialized]);

  const openBillingForm = async () => {
    sendShippingEvent();
    const deliveryAddressErrors = await (checkoutForm?.deliveryAddressForm?.current
      ?.errors as Record<string, string>);
    const pickupAddressErrors = await (checkoutForm?.pickupAddressForm?.current?.errors as Record<
      string,
      string
    >);
    if (deliveryAddressErrors) {
      const deliveryErrorArray = Object.entries(deliveryAddressErrors);
      if (deliveryErrorArray.length > 0) {
        const deliveryTarget = document.getElementById('deliveryaddress');
        deliveryTarget && deliveryTarget.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }
    if (pickupAddressErrors) {
      const pickupErrorArray = Object.entries(pickupAddressErrors);
      if (pickupErrorArray.length > 0) {
        const pickupTarget = document.getElementById('pickupaddress');
        pickupTarget && pickupTarget.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }
    // function to open BillingAddress:
    logicToOpenBillForm();

    // Remove Loader and Restore Submit Button
    setLoading(false);
  };

  const submitBillingAddress = async (values: FormikValues) => {
    setLoading(true);
    try {
      const ocAddress = {
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Street1: values?.AddressLine1,
        Street2: values?.AddressLine2,
        City: values?.City,
        State: values?.State,
        Zip: values?.Zip,
        Country: 'US',
        Billing: true,
        AddressName: addressType?.billing,
      };
      if (values?.SaveToProfile) {
        await dispatch(saveAddress({ ...ocAddress, ID: addresses?.ID }));
      }
      // Send data to OC to save Billing address
      await dispatch(saveBillingAddress(ocAddress)).then(() => {
        if (setExpandedForm) {
          setExpandedForm('payment'); // delivery | billing | tip | pickup | payment
          const paymentTarget = document.getElementById('paymentsection');
          paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } catch (error) {
      console.error('submitPickupAddress: ', error);
    }
    setLoading(false);
  };

  const handleCopy = (
    event: any,
    setValues: FormikHelpers<FormikValues>['setValues'],
    values: FormikValues
  ) => {
    if (event.target.checked) {
      if (shippingAddress) {
        setValues(
          {
            FirstName: shippingAddress?.FirstName ?? values?.FirstName,
            LastName: shippingAddress?.LastName ?? values?.LastName,
            AddressLine1: shippingAddress?.Street1,
            AddressLine2: shippingAddress?.Street2,
            City: shippingAddress?.City,
            State: shippingAddress?.State || '',
            Zip: shippingAddress?.Zip,
          },
          true
        );
      }
      setDisableFields(true);
      // disable whole form
    } else {
      // enable whole form
      setDisableFields(false);
    }
  };

  const { currentPage, pageItem, position } = getGTMSessionStorage();

  const cartPricing = useCartPriceForUI(currentOrder);

  const sendShippingEvent = () => {
    const productLineItems = ocCurrentOrder?.lineItems?.filter(
      (x) => ![DiscreteLineItem.TIP].includes(x.ProductID)
    );

    const productData = productLineItems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
        listPrice: lineItem?.UnitPrice,
      };
      return products;
    });

    productData &&
      sendProductsPromotion({
        eventName: GTM_EVENT?.addShippingInfo,
        data: productData as unknown as ProductSearchResultModelWithVariants[],
        currentPage: currentPage,
        pageItem: pageItem,
        position: position ?? 0,
        storeId: myStoreData?.storeId,
        currency: true,
        ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
        totalCount: Number(cartPricing?.subTotal),
        fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      });
  };

  const hasEventFired = useRef(false);

  const productLineItems = ocCurrentOrder?.lineItems?.filter(
    (x) => ![DiscreteLineItem.TIP].includes(x.ProductID)
  );

  useEffect(() => {
    const checkGtmLoad = () => {
      const isBeginCheckoutFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.beginCheckout)
          ?.length > 0;

      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;

      if (
        !isAnonymous &&
        isBeginCheckoutFired &&
        isGTMLoad &&
        productLineItems &&
        productLineItems?.length > 0 &&
        !hasEventFired.current
      ) {
        sendShippingEvent();
        hasEventFired.current = true;
      } else {
        timeoutIdRef.current = setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };

    productLineItems && productLineItems?.length > 0 && checkGtmLoad();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [pickup, productLineItems]);

  useEffect(() => {
    // Reset the event firing flag whenever pickup changes
    hasEventFired.current = false;
  }, [pickup]);

  useEffect(() => {
    const productData = productlineitems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
      };
      return products;
    });

    const isBeginCheckoutFired =
      window &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.beginCheckout)
        ?.length > 0;

    isAnonymous &&
      productData &&
      isBeginCheckoutFired &&
      sendProductsPromotion({
        eventName: GTM_EVENT?.addShippingInfo,
        data: productData as unknown as ProductSearchResultModelWithVariants[],
        currentPage: currentPage,
        pageItem: pageItem,
        position: position ?? 0,
        storeId: myStoreData?.storeId,
        currency: true,
        ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
        totalCount: Number(cartPricing?.subTotal),
        fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      });
  }, []);

  if (!myStoreData || !cart) {
    return empty;
  }
  return (
    <div
      id="billingaddress"
      ref={formRef}
      className={'billingaddress ' + base({ isCollapsed: collapse })}
    >
      <div className={titleWrapper()}>
        <div className={title({ isCollapsed: collapse })}>
          {pickup ? '2.' : '3.'} {fields?.data?.data?.title?.value}
        </div>
        {collapse && billingAdd && (
          <div className={linkWithUnderline() + ' cursor-pointer'} onClick={openBillingForm}>
            Edit
          </div>
        )}
      </div>
      {collapse && billingAdd && (
        <div className={addressBlock()}>
          <span className={blockTitle()}>Bill To:</span>
          <p className="block">
            {initialValues?.FirstName} {initialValues?.LastName}
          </p>
          <span className="block">
            {initialValues?.AddressLine1} {initialValues?.AddressLine2}
          </span>
          <span className="block">
            {initialValues?.City}, {initialValues?.State} {initialValues?.Zip}
          </span>
        </div>
      )}
      {/* Fields marked with an asterisk * .... */}
      {!collapse && fields?.data?.data?.shortDescription?.value && (
        <div className={fieldWrapper()}>
          <div className={validFieldInfo()}>{fields?.data?.data?.shortDescription?.value}</div>
        </div>
      )}
      {!collapse && (
        <Formik
          initialValues={initialValues}
          innerRef={billingAddressForm}
          onSubmit={(values: FormValuesTypes) => {
            submitBillingAddress(values);
          }}
        >
          {({ setValues, values }) => (
            <FormikForm className={pickupInformationContainer()}>
              <div className={pickupInformationContainer()}>
                <div className={form()}>
                  {!pickup && transFormFields.sameasdelivery && (
                    <div className={clsx(fieldWrapper(), sameDelivery())}>
                      {
                        <CheckboxField
                          {...transFormFields.sameasdelivery}
                          singleCheckbox={true}
                          onClick={(event) => handleCopy(event, setValues, values)}
                        />
                      }
                    </div>
                  )}
                  {/* First Name and Last Name */}
                  <div className={inlineFields()}>
                    {transFormFields.FirstName && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.FirstName} disabled={disableFields} />
                      </div>
                    )}
                    {transFormFields.LastName && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.LastName} disabled={disableFields} />
                      </div>
                    )}
                  </div>
                  {/* Address Line1 and Address Line2 */}
                  <div className={inlineFields()}>
                    {transFormFields.AddressLine1 && (
                      <div className={fieldWrapper()}>
                        <AutoCompleteWrapper fieldMap={autoCompleteFieldMap}>
                          {({}: any) => {
                            return (
                              <>
                                <TextField
                                  {...transFormFields.AddressLine1}
                                  disabled={disableFields}
                                />
                              </>
                            );
                          }}
                        </AutoCompleteWrapper>
                      </div>
                    )}
                    {transFormFields.AddressLine2 && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.AddressLine2} disabled={disableFields} />
                      </div>
                    )}
                  </div>
                  {/* City and State */}
                  <div className={inlineFields()}>
                    {transFormFields.City && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.City} disabled={disableFields} />
                      </div>
                    )}
                    {transFormFields.State && (
                      <div className={fieldWrapper()}>
                        <DropdownField
                          {...transFormFields.State}
                          options={fields.data?.states?.regions?.targetItems}
                          firstOptionData={getDictionaryValue('SelectState')}
                          disabled={disableFields}
                        />
                      </div>
                    )}
                  </div>
                  {/* Zip Field */}
                  {transFormFields.Zip && (
                    <div className={fieldWrapper()}>
                      <TextField {...transFormFields.Zip} disabled={disableFields} />
                    </div>
                  )}
                  {/* Save to Profile */}
                  {!isAnonymous && transFormFields.SaveToProfile && (
                    <div className={clsx(fieldWrapper(), profileCheckbox())}>
                      {<CheckboxField {...transFormFields.SaveToProfile} singleCheckbox={true} />}
                    </div>
                  )}
                  <div className={fieldWrapper()}>
                    {loading ? (
                      <div className={loaderWrapper()}>
                        <Loader />
                        {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                      </div>
                    ) : (
                      <>
                        {fields.data?.data?.submitButtonText?.value && (
                          <button aria-label="submit" className={submitBtn()} type="submit">
                            {fields.data?.data?.submitButtonText?.value}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </div>
  );
};
export default BillingAddress;
