export async function handleCTRewardsSignIn(unityId: string) {
  try {
    const visitorContext = {
      pspVisitorContext: {
        UnityId: unityId,
      },
    };

    const response = await fetch('/api/crowdtwist/crowd-twist-auth-sign-in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pspVisitorContext: visitorContext }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const redirectUrl = await response.json();
    return redirectUrl;
  } catch (error) {
    console.error('Error during sign-in into Crowd Twist: ', error);
  }
}
