import GenericStoreLocatorWidget from 'components/StoreLocatorHelpers/GenericStoreLocator';
import { StoreLocatorProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import React from 'react';

const StoreLocator = ({ fields, params, rendering }: StoreLocatorProps) => {
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <>
      <GenericStoreLocatorWidget
        rfkId={fields?.rfkid?.value || 'rfk_Store_Locator_Geo_Location'}
        isModal={false}
        fields={fields}
        params={params}
        rendering={rendering}
      />
    </>
  );
};

export default StoreLocator;
