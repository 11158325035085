const getProfileImages = (dataSource: string) => `
  query {
    item(path: "${dataSource}", language: "en") {
      name

      children {
        results {
          Specie: name
          SpecieData: field(name: "image") {
            jsonValue
          }
          children {
            results {
              WhiteSpecie: name
              WhiteSpecieData: field(name: "image") {
                jsonValue
              }
            }
          }
        }
      }
    }
  }
`;

export default getProfileImages;
