import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { RuleList } from './RuleList';
import { IPersonalizationRule } from '../models';
import { button, buttonGroup, container } from '../styles';
import PersonalizationModalWrapper from 'components/helpers/PersonalizationModalWrapper/PersonalizationModalWrapper';

export interface ContentItem {
  displayName: string;
  id: string;
  name: string;
  url: string;
}
interface RulesModalProps {
  showModal: boolean;
  onCloseClick: () => void;
  onSaveClick: (
    rules: IPersonalizationRule[],
    showDefaultContent: boolean,
    defaultCmsPath: string
  ) => void;
  existingRules: IPersonalizationRule[];
  existingHideDefaultContent: boolean;
  existingDefaultCmsPath: string;
  contentItemIds: string[];
}

export function RulesModal({
  showModal,
  onCloseClick,
  onSaveClick,
  existingRules,
  contentItemIds,
  existingHideDefaultContent,
  existingDefaultCmsPath,
}: RulesModalProps) {
  const [rules, setRules] = useState<IPersonalizationRule[]>(existingRules);

  return (
    <PersonalizationModalWrapper
      showModal={showModal}
      onCloseClick={onCloseClick}
      popupWidth="max-w-[900px]"
      closeModalOnOverlayClick
      customPopup
      isInEditor
    >
      <Formik
        initialValues={{
          rules,
          showDefaultContent: !existingHideDefaultContent,
          defaultCmsPath: existingDefaultCmsPath,
        }}
        onSubmit={(values) =>
          onSaveClick(values.rules, values.showDefaultContent, values.defaultCmsPath)
        }
      >
        {({ values: { rules }, isSubmitting }) => (
          <Form className={container()}>
            <FieldArray
              name="rules"
              render={(arrayHelpers) => (
                <RuleList
                  rules={rules}
                  setRules={setRules}
                  addRule={(rule) => arrayHelpers.push(rule)}
                  removeRule={(index) => arrayHelpers.remove(index)}
                  swapRule={(oldIndex, newIndex) => arrayHelpers.swap(oldIndex, newIndex)}
                  contentItemIds={contentItemIds}
                />
              )}
            />
            <div className={buttonGroup()}>
              <button
                type="button"
                className={button({ color: 'secondary' })}
                onClick={() => onCloseClick()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={button({ color: 'primary' })}
                disabled={isSubmitting}
              >
                Save Changes
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </PersonalizationModalWrapper>
  );
}
