import { LineItem, Me } from 'ordercloud-javascript-sdk';
import {
  createLineItem,
  deleteCurrentOrder,
  removeLineItem,
  saveShippingAddress,
  updateLineItem,
} from 'src/redux/ocCurrentOrder';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import useOcCurrentOrder from './useOcCurrentOrder';
import ComponentContext from 'lib/context/ComponentContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  DiscreteLineItem,
  FulfillmentType,
  productAvailability,
  retailUnitMeasureFieldName,
  currentPath,
} from 'components/helpers/Constants';
import { BuyerProductWithXp, LineItemWithXp } from 'src/redux/xp';
import { NormalizedProductPrice } from 'lib/types/products';
import toExecuteBeforeSoftLogin from 'src/utils/handleSoftLogin';
import router from 'next/router';
import { Cookies } from 'react-cookie';

const useOcCart = () => {
  /***
   ** TODO:: We will replace with middleware call
   */
  const dispatch = useOcDispatch();
  const { order, lineItems } = useOcCurrentOrder();
  const ocUser = useOcSelector((s) => s.ocUser);
  const ocCurrentOrder = useOcSelector((s) => s.ocCurrentOrder);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [inventoryCache, setInventoryCache] = useState<
    Record<string, { inventory: number; inventoryRecordId: string }>
  >({});

  const getProductLineItems = (): LineItemWithXp[] => {
    return lineItems?.filter((x) => ![DiscreteLineItem.TIP].includes(x.ProductID)) ?? [];
  };

  const getProductLineItemsSoftLogin = (): LineItemWithXp[] => {
    // const lineItems = JSON.parse(localStorage.getItem('cartLineItems') as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lineItems = JSON.parse(sessionStorage.getItem('cartLineItems') as any);
    // const lineItems = cookies?.cartLineItems as any;
    return (
      lineItems?.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (x: any) => ![DiscreteLineItem.TIP].includes(x.ProductID)
      ) ?? []
    );
  };

  // to get LineTotal from passed lineItems. Used for Rewards calculation.
  const getLineItemsTotal = (lineItems: LineItem[] = []): number => {
    return lineItems.reduce((totalValue, element) => totalValue + (element.LineTotal ?? 0), 0);
  };

  const getTipLineItem = () => {
    return lineItems?.find((x) => x.ProductID === DiscreteLineItem.TIP) ?? undefined;
  };
  /*
  const getColoradoLineItem = () => {
    return lineItems?.find((x) => x.ProductID === DiscreteLineItem.CORDF) ?? undefined;
  };
  */
  const sumOfQuantity = (lineItems: LineItem[]): number => {
    return lineItems.reduce((sum, currentItem) => sum + (currentItem.Quantity ?? 0), 0);
  };

  const numberOfItemsInCart = (): number => {
    return sumOfQuantity(getProductLineItems() ?? []);
  };

  const numberOfItemsInCartSoftLogin = (): number => {
    return sumOfQuantity(getProductLineItemsSoftLogin() ?? []);
  };

  const getInventoryId = async (productId: string, storeId: string) => {
    if (productId && storeId) {
      const inventory = await Me.ListProductInventoryRecords(productId, {
        search: storeId as string,
        searchOn: ['AddressID'],
      });
      return inventory?.Items[0]?.ID;
    }
    return null;
  };

  /**
   * Add to cart
   * @param productId Product id
   * @param quantity Item quantity
   * @returns return null if id and quantity is not valid else send success response.
   */
  const addToCart = async ({
    productId,
    quantity,
    storeId,
    inventoryItemId,
    isUpdateLineItem = false,
  }: {
    productId: string;
    quantity: number;
    storeId?: string;
    inventoryItemId?: string;
    isUpdateLineItem?: boolean;
  }) => {
    try {
      if (productId && quantity) {
        setcomponentContextData({
          ...componentContextData,
          openMiniCart: true,
          showMinicartLoader: isUpdateLineItem ? false : true,
        });
        const selectedStoreId = storeId || selectedStore.storeId;
        const inventoryId = inventoryItemId || (await getInventoryId(productId, selectedStoreId));

        if (inventoryId) {
          const lineItem: LineItemWithXp = {
            ProductID: productId,
            InventoryRecordID: inventoryId,
            Quantity: quantity,
          };

          const existingLineItem = lineItems?.find((li) => li?.Product?.ID === productId);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let internalRes: any;
          if (existingLineItem) {
            const updatedLineItem = {
              ...existingLineItem,
              Quantity: isUpdateLineItem ? quantity : quantity + (existingLineItem?.Quantity ?? 0),
            };
            const res = await dispatch(updateLineItem(updatedLineItem));

            res &&
              setcomponentContextData({
                ...componentContextData,
                openMiniCart: true,
                showMinicartLoader: false,
              });
            // return res;
            internalRes = res;
          } else {
            if (lineItem && lineItem.ProductID != DiscreteLineItem.TIP) {
              if (lineItem.xp) {
                lineItem.xp.DT = 2;
              } else {
                lineItem.xp = { DT: 2 };
              }
            }
            const res = await dispatch(
              createLineItem({
                request: lineItem,
                isDFS: localStorage.getItem('selected_fulfillment_Method') === FulfillmentType?.DFS,
              })
            );
            res &&
              setcomponentContextData({
                ...componentContextData,
                openMiniCart: true,
                showMinicartLoader: false,
              });
            if (
              !(lineItems && lineItems?.length > 0) &&
              JSON.parse(localStorage.getItem('selected_delivery_address') || '')
            ) {
              await dispatch(
                saveShippingAddress(
                  JSON.parse(localStorage.getItem('selected_delivery_address') || '')
                )
              );
              localStorage.removeItem('selected_delivery_address');
              localStorage.removeItem('selected_fulfillment_Method');
            }
            internalRes = res;
            // return res;
          }
          if (internalRes && internalRes?.payload?.LineItems.length > 0) {
            sessionStorage.setItem(
              'cartLineItems',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              JSON.stringify(internalRes?.payload?.LineItems as any)
            );
            return internalRes;
          }
        }
      }

      setcomponentContextData({
        ...componentContextData,
        openMiniCart: true,
        showMinicartLoader: false,
      });

      return null;
    } catch (_err) {
      return { isError: true };
    }
  };

  //Get Product Price
  /**
   *
   * @param productId Product id
   * @returns return null if id is not valid else send price as response.
   */
  const getProductPrice = useCallback(
    async (productId: string, storeId: string): Promise<NormalizedProductPrice> => {
      if (productId && storeId) {
        const response = await Me.GetProduct<BuyerProductWithXp>(productId, {
          sellerID: storeId,
        });
        return {
          productId: productId,
          listPrice: response?.PriceSchedule?.xp.ListPrice,
          memberPrice: response?.PriceSchedule?.xp?.PPCPrice,
          iMapPrice: response?.PriceSchedule?.xp?.IMapPrice,
        };
      }
      return {};
    },
    []
  );

  const getProductsPrice = useCallback(
    async (productIds: string[]): Promise<NormalizedProductPrice[]> => {
      const result: NormalizedProductPrice[] = [];

      if (order?.ToCompanyID) {
        const promises = productIds.map(async (id) => {
          const response = await Me.GetProduct<BuyerProductWithXp>(id, {
            sellerID: order?.ToCompanyID,
          });
          const value: NormalizedProductPrice = {
            productId: id,
            listPrice: response?.PriceSchedule?.xp.ListPrice,
            memberPrice: response?.PriceSchedule?.xp?.PPCPrice,
            iMapPrice: response?.PriceSchedule?.xp?.IMapPrice,
          };
          result.push(value);
        });

        await Promise.all(promises);
      }

      return result;
    },
    [order?.ToCompanyID]
  );
  //Get Product optioms
  /**
   *
   * @param productId Product id
   * @param storeId Store id
   * @returns return product options.
   */
  // Define the type for your objects
  interface Item {
    Value?: string;
  }
  const getProductOptions = async (parentId: string, storeId: string) => {
    const variationAttributesData = await Me?.GetProduct(parentId);
    if (
      variationAttributesData?.xp?.VariationAttributes &&
      variationAttributesData?.xp?.VariationAttributes?.length > 0
    ) {
      const isRetailUnitMeasure = variationAttributesData?.xp?.VariationAttributes?.includes(
        retailUnitMeasureFieldName
      );
      const data = await Me?.ListProducts({
        search: parentId,
        searchOn: ['ParentID'],
        filters: { 'xp.WE': false },
        sellerID: storeId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filteredFacets = data?.Meta?.Facets?.filter((facet: any) =>
        variationAttributesData?.xp?.VariationAttributes?.includes(facet?.Name?.toString())
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filterFacetKeys = filteredFacets?.map((facet: any) => facet?.Name?.toString());
      if (isRetailUnitMeasure) {
        filterFacetKeys?.push(retailUnitMeasureFieldName);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filteredProducts = data?.Items?.map((item: any) => {
        const filteredProduct: { [key: string]: string } = {};
        filterFacetKeys?.forEach((key: string) => {
          if (key === retailUnitMeasureFieldName) {
            filteredProduct[key] =
              item?.xp?.RetailUnit + ' ' + item?.xp?.RetailMeasure?.toUpperCase();
          } else {
            filteredProduct[key] = Array.isArray(item?.xp?.[key])
              ? item?.xp?.[key]?.join('; ')
              : item?.xp?.[key];
          }
        });
        return { ...filteredProduct, sku: item?.xp?.UPC };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const facetsList: any = filteredFacets?.map((facet: any) => {
        return {
          Name: facet?.Name,
          Values: filteredProducts
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ?.map((item: any) => {
              return {
                Value: Array.isArray(item?.[facet?.Name])
                  ? item?.[facet?.Name]?.join('; ')
                  : item?.[facet?.Name],
              };
            })
            .reduce((accumulator: Item[], current: Item) => {
              if (current.Value && current.Value?.length > 0) {
                if (!accumulator.some((item: Item) => item.Value === current.Value)) {
                  accumulator.push(current as Item);
                }
              }
              return accumulator;
            }, []),
        };
      });
      if (isRetailUnitMeasure) {
        facetsList?.push({
          Name: retailUnitMeasureFieldName,
          Values: filteredProducts
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ?.map((item: any) => {
              return {
                Value: item?.[retailUnitMeasureFieldName],
              };
            })
            .reduce((accumulator: Item[], current: Item) => {
              if (current.Value && current.Value?.length > 0) {
                if (!accumulator.some((item: Item) => item.Value === current.Value)) {
                  accumulator.push(current as Item);
                }
              }
              return accumulator;
            }, []),
        });
      }
      return {
        filteredProducts,
        filterFacetKeys: filterFacetKeys || [],
        facets: facetsList?.filter((facet: { Values: Item[] }) => {
          return facet?.Values?.length > 0;
        }),
      };
    }
    return null;
  };

  //Get Product Inventory
  /**
   *
   * @param productId Product id
   * @returns return null if id is not valid else send Inventory as response.
   */
  const getProductInventory = useCallback(
    async (productId: string, storeId: string) => {
      const cacheKey = `${productId}-${storeId}`;
      if (inventoryCache[cacheKey]) {
        return inventoryCache[cacheKey];
      }

      if (productId) {
        try {
          const inventory = await Me.ListProductInventoryRecords(productId, {
            search: storeId as string,
            searchOn: ['AddressID'],
          });
          const inventoryData = {
            inventory: inventory.Items?.[0]?.QuantityAvailable,
            inventoryRecordId: inventory.Items?.[0]?.ID,
          };

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setInventoryCache((prevCache: any) => ({
            ...prevCache,
            [cacheKey]: inventoryData,
          }));

          return inventoryData;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const cookies = new Cookies();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error?.errors.map(async (error: any) => {
            const hasRefreshToken = cookies.get('ordercloud.refresh-token');
            // Can check refresh tokens from Tokens.GetRefreshToken but looking fishy.
            // const hasOCRefreshToken = Tokens.GetRefreshToken();

            if (error && error?.ErrorCode.includes('Authorization.')) {
              if (!hasRefreshToken) {
                toExecuteBeforeSoftLogin(ocUser, ocCurrentOrder); // invokes softLogin state
                /**
                 *  need to redirect to Login Page as upon Qty manipulation from invoked func,
                 *  will need to redirect to LoginPage to identify as softLogin
                 */
                router.push(currentPath?.isLogin);
              }
            }
          });
        }
      }
      return null;
    },
    [inventoryCache]
  );

  //Get Product Availability
  /**
   *
   * @param productId Product id
   * @returns return null if id is not valid else send Inventory as response.
   * {"IN":"In Stock","LOW":"Low Stock","OUT":"Out of Stock"}
   */
  // const currentlineItems: LineItemWithXp[] = getProductLineItems();
  // const currentlineItems: LineItemWithXp[] =
  //   localStorage.getItem('isSoftLoginEnabled') == 'true'
  //     ? getProductLineItemsSoftLogin()
  //     : getProductLineItems();

  const [currentlineItems, setCurrentlineItems] = useState<LineItemWithXp[]>([]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isSoftLoginEnabled = sessionStorage.getItem('isSoftLoginEnabled') === 'true';
      const items = isSoftLoginEnabled ? getProductLineItemsSoftLogin() : getProductLineItems();
      setCurrentlineItems(items);
    }
  }, []);

  const getProductAvailability = async (
    productId: string,
    storeId: string,
    thresholdValue?: number
  ) => {
    if (productId && storeId) {
      const data = await getProductInventory(productId, storeId);

      if (
        currentlineItems === undefined ||
        (Array.isArray(currentlineItems) && currentlineItems.length === 0)
      ) {
        if (data?.inventory) {
          if (data?.inventory <= 0) {
            return {
              productInventory: data?.inventory,
              availability: productAvailability['OUT'],
              inventoryRecordId: data?.inventoryRecordId,
            };
          } else if (thresholdValue && data?.inventory < thresholdValue) {
            return {
              productInventory: data?.inventory,
              availability: productAvailability['LOW'],
              inventoryRecordId: data?.inventoryRecordId,
            };
          } else {
            return {
              productInventory: data?.inventory,
              availability: productAvailability['IN'],
              inventoryRecordId: data?.inventoryRecordId,
            };
          }
        }
      } else {
        if (currentlineItems) {
          const productInLineItems = currentlineItems.find(
            (item) => item.InventoryRecordID === data?.inventoryRecordId
          );
          if (data && typeof data.inventory !== 'undefined') {
            if (productInLineItems) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const lineItemInventory = productInLineItems.Quantity as any;
              if (productInLineItems.Quantity) {
                if (lineItemInventory === data?.inventory) {
                  return {
                    productInventory: data?.inventory,
                    availability: productAvailability['OUT'],
                    inventoryRecordId: data?.inventoryRecordId,
                    remainingInventory: data?.inventory - productInLineItems.Quantity,
                  };
                } else if (
                  thresholdValue &&
                  data?.inventory - thresholdValue <= lineItemInventory
                ) {
                  return {
                    productInventory: data?.inventory,
                    availability: productAvailability['LOW'],
                    inventoryRecordId: data?.inventoryRecordId,
                    remainingInventory: data?.inventory - productInLineItems.Quantity,
                  };
                } else {
                  return {
                    productInventory: data?.inventory,
                    availability: productAvailability['IN'],
                    inventoryRecordId: data?.inventoryRecordId,
                    remainingInventory: data?.inventory - productInLineItems.Quantity,
                  };
                }
              }
            }
          }
        }
      }
    }
    return null;
  };

  //Remove specific item from cart
  /**
   *
   * @param productId Product id
   */
  const removeFromCart = async (productId: string) => {
    lineItems?.map(async (i: LineItem) => {
      if (i?.ID && i?.Product?.ID === productId) {
        const res = await dispatch(removeLineItem(i?.ID));
        return res;
      } else {
        return { error: 'items is not available in cart !!' };
      }
    });
  };
  //Remove all items from the cart
  const clearCart = () => {
    //weather remove all lineitems or delete cart Deepak?
    dispatch(deleteCurrentOrder());
  };

  return {
    getTipLineItem,
    // getColoradoLineItem,
    getProductLineItems,
    getProductLineItemsSoftLogin,
    getLineItemsTotal,
    numberOfItemsInCart,
    numberOfItemsInCartSoftLogin,
    addToCart,
    removeFromCart,
    clearCart,
    getProductPrice,
    getProductsPrice,
    getProductInventory,
    getProductAvailability,
    getProductOptions,
  };
};

export default useOcCart;
