export enum ConditionTypes {
  WHERE_STORE_IS = '0',
  EXCEPT_WHERE_STORE_IS = '1',
  WHERE_STORE_TYPE_IS = '2',
  EXCEPT_WHERE_STORE_TYPE_IS = '3',
  WHERE_STORE_OFFERS = '4',
  EXCEPT_WHERE_STORE_OFFERS = '5',
  WHERE_USER_HAS_PET_SPECIES = '6',
  EXCEPT_WHERE_USER_HAS_PET_SPECIES = '7',
  WHEN = '8',
  EXCEPT_WHEN = '9',
  WHERE_PAGE_URL_IS = '10',
  EXCEPT_WHERE_PAGE_URL_IS = '11',
  WHERE_GOAL_IS = '12',
  EXCEPT_WHERE_GOAL_IS = '13',
  WHERE_DEVICE_IS = '14',
  EXCEPT_WHERE_DEVICE_IS = '15',
  WHERE_BOOKING_ONLINE = '16',
  EXCEPT_WHERE_BOOKING_ONLINE = '17',
  WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE = '18',
  EXCEPT_WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE = '19',
}
const reverseConditionTypes = createReverseEnum(ConditionTypes);

export type ConditionTypeKeys = keyof typeof ConditionTypes;

export const ConditionTypeMapper: Record<ConditionTypeKeys, string> = {
  WHERE_STORE_IS: 'Where store is',
  EXCEPT_WHERE_STORE_IS: 'Except where store is',
  WHERE_STORE_TYPE_IS: 'Where store type is',
  EXCEPT_WHERE_STORE_TYPE_IS: 'Except where store type is',
  WHERE_STORE_OFFERS: 'Where store offers',
  EXCEPT_WHERE_STORE_OFFERS: 'Except where store offers',
  WHERE_USER_HAS_PET_SPECIES: 'Where user has pet species',
  EXCEPT_WHERE_USER_HAS_PET_SPECIES: 'Except where user has pet species',
  WHEN: 'When',
  EXCEPT_WHEN: 'Except when',
  WHERE_PAGE_URL_IS: 'Where page URL is',
  EXCEPT_WHERE_PAGE_URL_IS: 'Except where page URL is',
  WHERE_GOAL_IS: 'Where goal is',
  EXCEPT_WHERE_GOAL_IS: 'Except where goal is',
  WHERE_DEVICE_IS: 'Where device is',
  EXCEPT_WHERE_DEVICE_IS: 'Except where device is',
  WHERE_BOOKING_ONLINE: 'Where booking online',
  EXCEPT_WHERE_BOOKING_ONLINE: 'Except where booking online',
  WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE: 'Where user has accepted the loyalty terms of use',
  EXCEPT_WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE:
    'Except where user has accepted the loyalty terms of use',
};

export function getRuleDisplayName(ruleTypeNumber: string): ConditionTypeKeys {
  return reverseConditionTypes[ruleTypeNumber];
}

// interface IPersonalizationConditionGoal {
//   id: string;
//   name: string;
// }

type IPersonalizationDeviceType = 'desktop' | 'mobile';

export type IPersonalizationCondition =
  | {
      id: string;
      type:
        | ConditionTypes.WHERE_STORE_IS
        | ConditionTypes.EXCEPT_WHERE_STORE_IS
        | ConditionTypes.WHERE_STORE_TYPE_IS
        | ConditionTypes.EXCEPT_WHERE_STORE_TYPE_IS
        | ConditionTypes.WHERE_STORE_OFFERS
        | ConditionTypes.EXCEPT_WHERE_STORE_OFFERS
        | ConditionTypes.WHERE_USER_HAS_PET_SPECIES
        | ConditionTypes.EXCEPT_WHERE_USER_HAS_PET_SPECIES
        | ConditionTypes.WHEN
        | ConditionTypes.EXCEPT_WHEN
        | ConditionTypes.WHERE_PAGE_URL_IS
        | ConditionTypes.EXCEPT_WHERE_PAGE_URL_IS
        | ConditionTypes.WHERE_DEVICE_IS
        | ConditionTypes.EXCEPT_WHERE_DEVICE_IS
        | ConditionTypes.WHERE_BOOKING_ONLINE
        | ConditionTypes.EXCEPT_WHERE_BOOKING_ONLINE
        | ConditionTypes.WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE
        | ConditionTypes.EXCEPT_WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE;
      value: string;
    }
  | {
      id: string;
      type: ConditionTypes.WHERE_GOAL_IS | ConditionTypes.EXCEPT_WHERE_GOAL_IS;
      value: string;
      name: string;
    }
  | {
      id: string;
      type: ConditionTypes.WHERE_DEVICE_IS | ConditionTypes.EXCEPT_WHERE_DEVICE_IS;
      value: IPersonalizationDeviceType;
    }
  | 'and'
  | 'or';

export interface IPersonalizationRule {
  name: string;
  id: string;
  contentItemId: string;
  contentItemName: string;
  show: boolean;
  conditions: IPersonalizationCondition[];
}

function createReverseEnum<T extends { [key: string]: string | number }>(
  enumObj: T
): { [key: string]: keyof T } {
  const reverseEnum: { [key: string]: keyof T } = {};
  for (const key in enumObj) {
    const value = enumObj[key];
    reverseEnum[value as string] = key;
  }
  return reverseEnum;
}

export interface IXmcQueryResponse<TXmcItem> {
  graphQLResponse: {
    data: {
      item: {
        children: {
          results: TXmcItem[];
        };
      };
    };
  };
}

export interface IDropdownItem {
  label: {
    value: string;
  };
  value: {
    value: string;
  };
}
