//global
import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import { Form as FormikForm, Formik, FormikHelpers, FormikValues, FormikProps } from 'formik';
//local
import {
  MyAccountDetailsProps,
  myAccountDetailsVariants,
} from 'components/MyAccountDetails/MyAccountDetails';
import TextHelper from 'components/helpers/TextHelper';
import TextField from 'components/helpers/Form/TextField';
import ComponentContext from 'lib/context/ComponentContext';
import { transformData } from 'src/utils/formUtils';
import ModalWrapper from 'components/helpers/ModalWrapper';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from 'lib/context/ThemeContext';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { Auth, Me } from 'ordercloud-javascript-sdk';
import { updateUser } from 'src/redux/ocUser';
import { validateUser } from 'src/redux/ocAuth/login';
import RichTextHelper from 'components/helpers/RichTextHelper';
import Loader from 'components/Loader/Loader';
import { formErrorFlags } from 'components/helpers/Constants';

//type
export type MyAccountChangePasswordProps = MyAccountDetailsProps;
interface FormValuesTypes {
  OldPassword: string;
  NewPassword: string;
  ReEnterNewPassword: string;
}
export interface FieldValue {
  [key: string]: string;
}
export interface ResponseType {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}
export interface Credentials {
  username: string;
  password: string;
}
//c
// const myAccountChangePasswordVariant=tv({

// })
//main component
const MyAccountChangePassword: React.FC<MyAccountChangePasswordProps> = ({ fields }) => {
  const { inlineFields, informationContainer, heading, submitBtn, changePasswordLoader } =
    myAccountDetailsVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const initialValues: FormValuesTypes = {
    OldPassword: '',
    NewPassword: '',
    ReEnterNewPassword: '',
  };

  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [fieldValues, setFieldValues] = useState<FormValuesTypes>();
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setshowOldPassword] = useState(false);
  const [error, setError] = useState(false);
  const [samePasswordError, setSamePasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const transFormFields = transformData(fields.data?.data?.formFields);
  const formRef = useRef<FormikHelpers<FormValuesTypes>>(null);
  //geting custome error message
  const customErrorMessage = fields?.data?.data?.errors?.values?.flatMap((error) => error);

  const matchPasswordErrorMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.passwordMatch
  );
  const samePasswordErrorMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.samePassword
  );
  const { themeNameUpper } = useTheme();

  //handling show button functionality
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const toggleOldPasswordVisibility = () => {
    setshowOldPassword(!showOldPassword);
  };
  //handling the values on onChange
  const setValues = (values: FormValuesTypes) => {
    setFieldValues({
      ...values,
    });
  };
  //handling submit action
  const dispatch = useOcDispatch();
  const submitFormData = async (values: FormikValues) => {
    setLoading(true);
    try {
      const client_id =
        themeNameUpper === 'PSP'
          ? process.env.NEXT_PUBLIC_PSP_OC_CLIENT_ID
          : process.env.NEXT_PUBLIC_WNW_OC_CLIENT_ID;
      const username = userDetails?.Email ?? '';

      if (values?.OldPassword !== values?.NewPassword) {
        const response = await Auth.Login(username, values?.OldPassword, client_id as string);
        if (response?.access_token) {
          const credentials = {
            username,
            password: values?.NewPassword,
          };

          await dispatch(updateUser({ ID: userDetails?.ID, Password: values?.NewPassword }));
          const userObj = await Me.Get();
          await validateUser(userObj, credentials, response);

          setcomponentContextData({
            ...componentContextData,
            isChangePasswordOpen: false,
            passwordChangeSuccess: true,
            passwordSuccessMessage: fields?.data?.data?.successMessage?.value,
          });
          formRef?.current?.resetForm();
          setError(false);
          setSamePasswordError(false);
        } else {
          setSamePasswordError(false);
          setError(true);
        }
      } else {
        setSamePasswordError(true);
        setError(false);
      }
    } catch (error) {
      setSamePasswordError(false);
      setError(true);
      console.error('@@change password Error: ', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (componentContextData?.isChangePasswordOpen === true) {
      document.body.classList.add('overflow-hidden');
    } else {
      if (document.body.classList.contains('overflow-hidden')) {
        document.body.classList.remove('overflow-hidden');
      }
    }
  }, [componentContextData?.isChangePasswordOpen]);
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    // <div className={base({ className: params?.Style ?? '' })}>
    <ModalWrapper
      showModal={componentContextData?.isChangePasswordOpen}
      onCloseClick={() => {
        setcomponentContextData({ ...componentContextData, isChangePasswordOpen: false });
        formRef?.current?.resetForm();
      }}
      additionalClassForPopUpModal="!item-start !h-fit translate-y-0 animate-[topAnimation_0.3s_ease-in-out]"
      popupWidth="max-w-[600px]"
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick
      customPopup
    >
      <div className="flex flex-col">
        {loading && (
          <div className={changePasswordLoader()}>
            <Loader />
          </div>
        )}
        <RichTextHelper field={fields?.data?.data?.title} className={heading()} />
        {error && (
          <span className="text-system-red mt-desk-space-between-base-vertical">
            <RichTextHelper field={fields?.data?.data?.failureMessage} />
          </span>
        )}
        {samePasswordError && (
          <span className="text-system-red mt-desk-space-between-base-vertical">
            {samePasswordErrorMessage && decodeURI(samePasswordErrorMessage[0]?.value)}
          </span>
        )}

        <Formik
          innerRef={formRef as Ref<FormikProps<FormValuesTypes>>}
          initialValues={initialValues}
          onSubmit={(
            values: FormValuesTypes,
            { setSubmitting }: FormikHelpers<FormValuesTypes>
          ) => {
            submitFormData(values);
            setSubmitting(false);
          }}
        >
          <FormikForm className={informationContainer()}>
            <TextField
              {...transFormFields.OldPassword}
              fieldtype={showOldPassword ? 'text' : 'password'}
              // className={input()}
              setFieldValue={setValues}
              togglePasswordVisibility={toggleOldPasswordVisibility}
              showPassword={showOldPassword}
            />
            <TextField
              {...transFormFields.NewPassword}
              fieldtype={showPassword ? 'text' : 'password'}
              // className={input()}
              mincharacterlimit={transFormFields?.NewPassword?.mincharacterlimit as string}
              mincharacterlimitvalidationmessage={
                transFormFields?.NewPassword?.mincharacterlimitvalidationmessage as string
              }
              setFieldValue={setValues}
              togglePasswordVisibility={togglePasswordVisibility}
              showPassword={showPassword}
            />
            {transFormFields?.ReEnterNewPassword && (
              <TextField
                {...transFormFields.ReEnterNewPassword}
                fieldtype={showConfirmPassword ? 'text' : 'password'}
                setFieldValue={setValues}
                matchValue={fieldValues && fieldValues['NewPassword']}
                matchKey={'NewPassword'}
                mincharacterlimit={transFormFields?.ReEnterNewPassword?.mincharacterlimit as string}
                mincharacterlimitvalidationmessage={
                  transFormFields?.ReEnterNewPassword?.mincharacterlimitvalidationmessage as string
                }
                togglePasswordVisibility={toggleConfirmPasswordVisibility}
                showPassword={showConfirmPassword}
                customErrorMessage={decodeURI(
                  (matchPasswordErrorMessage &&
                    (decodeURI(matchPasswordErrorMessage?.[0]?.value) as string)) ||
                    ''
                )}
              />
            )}

            <div className={inlineFields()}>
              {fields.data?.data?.submitButtonText?.value && (
                <button aria-label="submit" className={submitBtn()} type="submit">
                  <TextHelper field={fields.data?.data?.submitButtonText} />
                </button>
              )}
            </div>
          </FormikForm>
        </Formik>
      </div>
    </ModalWrapper>
    // </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<MyAccountChangePasswordProps>(MyAccountChangePassword);
