// Global
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import clsx from 'clsx';

// Local
import { useTheme } from 'lib/context/ThemeContext';
import { useOcSelector } from 'src/redux/ocStore';
import { formatDateFromString } from 'src/utils/formatDate';
import RichTextHelper from 'components/helpers/RichTextHelper';
import ContentHubImage from './ContentHubImages/ContentHubImage';
import ModalWrapper from 'components/helpers/ModalWrapper';
import ButtonHelper from 'components/helpers/Button';
import Loader from 'components/Loader/Loader';
import styles from './InProgressOffers.module.css';

// Types
type InProgressOffersProps = {
  inProgressRewardsHeading: Field<string>;
  inProgressRewardsSubheading: Field<string>;
  inProgressRewardsEarnThroughLabel: Field<string>;
  inProgressRewardsNoEarnThroughDateMessage: Field<string>;
  inProgressRewardsViewPurchasesLinkText: Field<string>;
  noExpiryDateText: Field<string>;
  termsAndConditionLabel: Field<string>;
  inProgressRewardsViewMoreLinkText: Field<string>;
  noInProgressRewardsMessage: Field<string>;
  inProgressRewardsDisclaimerText: Field<string>;
  qualifyingPurchaseModalHeading: Field<string>;
  qualifyingPurchasesTableColumn1Text: Field<string>;
  qualifyingPurchasesTableColumn2Text: Field<string>;
  qualifyingPurchasesTableColumn3Text: Field<string>;
};

type ProductProps = {
  program: {
    rewardPlu: string;
    rewardLimit: number;
    rewardAmount: number;
    requiresSignup: boolean;
    programName: string;
    shortProgramName: string;
    programid: number;
    pluexpireInDays: number;
    enabled: boolean;
    description: string;
    shortDescription: string;
    daysBetweenReward: number;
    DateStart: string;
    DateEnd?: string;
    brandid: number;
    termCondition: string;
    ShopNowUrl: string;
    offerExpiresDate: string;
  };
  numberOfPurchasesToGo: number;
  CouponRewardName: string;
  rewardLimit: number;
  percentageOfPurchase: number;
};

type PurchaseProps = {
  neighborid: number;
  quantity: number;
  storeId: number;
  storeName: string;
  storeAddress1: string;
  storeAddress2: string;
  storeCity: string;
  storeState: string;
  storeZip: string;
  TransDate: string;
};

/*
 * Tailwind Variants
 */

const availableOffersStyles = tv(
  {
    slots: {
      base: ['flex', 'flex-col', 'gap-desk-padding-micro-bottom'],
      productGrid: [
        'loyalty-product-grid',
        'grid',
        'pt-desk-padding-micro-bottom',
        'gap-desk-space-between-tight-horizontal',
        'border-t',
        'border-color-color-border-mid',
      ],
      productItem: [
        'lp-inprogress-product',
        'flex',
        'flex-col',
        'justify-between',
        'rounded-lg',
        'bg-white',
      ],
      productHeading: ['relative', 'h-[172px]'],
      productImage: ['flex', 'justify-center', 'px-desk-padding-tight-x'],
      productHeadingProgress: ['flex', 'justify-center', 'items-center', 'w-full', 'h-full'],
      productHeadingImageWrapper: [
        'flex',
        'justify-center',
        'items-center',
        'absolute',
        'z-10',
        'w-[91px]',
        'h-[91px]',
        'm-[15px]',
      ],
      productShortProgramName: [
        'text-color-background-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'mb-3',
      ],
      productShortProgramNameWrapper: [
        'flex',
        'justify-between',
        'flex-col',
        'bg-color-background-light',
        'py-desk-padding-micro-y',
        'px-desk-global-spacing-spacing-3',
        'min-h-[160px]',
      ],
      productShortDescription: [
        'text-heading-mob-small-regular',
        'font-heading-mob-small-regular',
        'leading-heading-mob-small-regular',
      ],
      amountLabelStyle: [
        'text-heading-desk-xLarge-medium',
        'font-heading-desk-xLarge-medium',
        'leading-heading-desk-xLarge-medium',
      ],
      purchaseToGoLabelStyle: [
        'text-body-xSmall-bold',
        'font-body-xSmall-bold',
        'leading-body-xSmall-bold',
      ],
      modalProgramName: [
        'text-heading-mob-small-bold',
        'font-heading-mob-small-bold',
        'leading-heading-mob-small-bold',
      ],
      modalProgramDescription: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      productNoExpiryDateText: [
        'text-color-text-dark',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'py-3',
      ],
      termsAndConditionWrapperStyle: [
        'border-y',
        'border-color-scale-3-light-mid',
        'border-1',
        'py-desk-padding-micro-y',
        'mb-3',
      ],
      termsAndConditionLabelStyle: [
        'font-bold text-color-scale-5-mid',
        'mb-desk-margin-base-bottom',
      ],
      termsAndConditionTextStyle: ['text-color-scale-5-mid'],
      loadMoreButtonWrapper: ['flex', 'justify-center '],
      loadMoreButton: [
        'flex',
        'w-[188px]',
        'justify-center',
        '!py-desk-component-button-condensed-padding-y',
        'mt-desk-global-spacing-spacing-5',
      ],
      inProgressRewardsEarnThroughLabelStyle: [
        'mt-3',
        'font-body-small-bold',
        'text-body-small-bold',
        'leading-body-small-bold',
      ],
      inProgressRewardsViewPurchasesLinkTextStyle: [
        'flex',
        'items-center',
        'text-color-background-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      qualifyingPurchaseModalHeadingStyle: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'mb-6',
      ],
      qualifyingPurchasesTableColumn1TextWrapper: [
        'flex',
        'flex-wrap',
        'p-3',
        'border-b',
        'border-color-color-scale-3-lightMid',
      ],
      qualifyingPurchasesTableColumn1TextStyle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'heading-heading-desk-medium-bold',
      ],
      qualifyingPurchasesTableColumn2TextStyle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'heading-heading-desk-medium-bold',
      ],
      qualifyingPurchasesTableColumn3TextStyle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'heading-heading-desk-medium-bold',
      ],
    },
    variants: {
      device: {
        mobile: {
          base: [''],
          productGrid: ['grid-cols-1'],
        },
        desktop: {
          base: [''],
          productGrid: ['grid-cols-4'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const {
  base,
  productGrid,
  productItem,
  productHeading,
  productImage,
  productHeadingImageWrapper,
  productHeadingProgress,
  productShortProgramName,
  productShortProgramNameWrapper,
  amountLabelStyle,
  purchaseToGoLabelStyle,
  modalProgramName,
  modalProgramDescription,
  productShortDescription,
  productNoExpiryDateText,
  termsAndConditionWrapperStyle,
  termsAndConditionLabelStyle,
  termsAndConditionTextStyle,
  loadMoreButtonWrapper,
  loadMoreButton,
  inProgressRewardsEarnThroughLabelStyle,
  inProgressRewardsViewPurchasesLinkTextStyle,
  qualifyingPurchaseModalHeadingStyle,
  qualifyingPurchasesTableColumn1TextWrapper,
  qualifyingPurchasesTableColumn1TextStyle,
  qualifyingPurchasesTableColumn2TextStyle,
  qualifyingPurchasesTableColumn3TextStyle,
} = availableOffersStyles({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const InProgressOffers = (props: InProgressOffersProps) => {
  const {
    inProgressRewardsHeading,
    inProgressRewardsSubheading,
    inProgressRewardsEarnThroughLabel,
    inProgressRewardsNoEarnThroughDateMessage,
    inProgressRewardsViewPurchasesLinkText,
    noExpiryDateText,
    termsAndConditionLabel,
    inProgressRewardsViewMoreLinkText,
    noInProgressRewardsMessage,
    inProgressRewardsDisclaimerText,
    qualifyingPurchaseModalHeading,
    qualifyingPurchasesTableColumn1Text,
    qualifyingPurchasesTableColumn2Text,
    qualifyingPurchasesTableColumn3Text,
  } = props;

  const [products, setProducts] = useState<ProductProps[]>([]);
  const [purchases, setPurchases] = useState<PurchaseProps[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState('');
  const [page, setPage] = useState(1);
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const [openPurchaseModalIndex, setOpenPurchaseModalIndex] = useState<number | null>(null);
  const { themeName } = useTheme();
  const size = 12;
  const program = 'Products';
  const errorMessage = 'An error occured, please try again later.';
  const inProgressGridRef = useRef<HTMLDivElement | null>(null);

  // todo: Remove the "any" when the "PPC" is added to the types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ppcNumber = useOcSelector((state: any) => state?.ocUser?.user?.xp?.PPC);

  const handleModalClick = (index: number) => {
    setOpenModalIndex(index);
  };

  const handleModalClose = () => {
    setOpenModalIndex(null);
  };

  const handlePurchaseModalClick = async (index: number, programId: number) => {
    setOpenPurchaseModalIndex(index);
    try {
      const response = await fetch(
        `/api/loyalty/get-qualifying-purchases?cardnumber=${ppcNumber}&id=${programId}&size=${size}&page=${page}`
      );
      if (!response.ok && response.status !== 404) {
        throw new Error('Error. The API response was not ok.');
      }
      const data = await response.json();
      setPurchases(data?.content);
    } catch (error) {
      setIsError(`Error. An error occured in the API response. ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePurchaseModalClose = () => {
    setOpenPurchaseModalIndex(null);
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    fetchProducts(size, page, program);
  }, [page]);

  useEffect(() => {
    if (inProgressGridRef.current) {
      const frames = inProgressGridRef.current.querySelectorAll('.progress-frame:not(.is-loaded)');
      frames.forEach((frame) => frame.classList.add('is-loaded'));

      const applyStyle = (el: HTMLElement, value: number, half: boolean) => {
        const percentage = value < 50 ? value : value - 50;
        let deg = Math.round(360 * percentage) / 100;
        if (!half && value >= 50) {
          deg = 180;
        }
        el.style.transform = `rotate(${deg}deg)`;
      };

      frames.forEach((frame) => {
        const value = parseFloat(frame.getAttribute('data-value') || '0');
        const child1 = frame.querySelector('.half i') as HTMLElement;
        const child2 = frame.querySelector('.full i') as HTMLElement;

        applyStyle(child1, value, false);

        if (value >= 50) {
          applyStyle(child2, value, true);
        }
      });
    }
  }, [products]);

  const fetchProducts = async (size: number, page: number, program: string) => {
    try {
      const response = await fetch(
        `/api/loyalty/get-in-progress-programs?group=${themeName}&cardNumber=${ppcNumber}&size=${size}&page=${page}&program=${program}`
      );
      if (!response.ok && response.status !== 404) {
        throw new Error('Error. The API response was not ok.');
      }
      const data = await response.json();
      if (page === 1) {
        setProducts(data?.content);
      } else {
        setProducts((prevResults) => [...prevResults, ...data?.content]);
      }
      setTotalProducts(data?.totalCount);
    } catch (error) {
      setIsError(`Error. An error occured in the API response. ${error}`);
    } finally {
      setLoading(false);
    }
  };

  /*
   * Rendering
   */

  if (loading && products?.length === 0) {
    return <Loader />;
  }

  return (
    <div className={base()}>
      {!isError ? (
        <>
          {products && products?.length > 0 ? (
            <>
              {inProgressRewardsHeading && inProgressRewardsHeading?.value && (
                <RichTextHelper field={inProgressRewardsHeading} />
              )}
              {inProgressRewardsHeading && inProgressRewardsSubheading?.value && (
                <RichTextHelper field={inProgressRewardsSubheading} />
              )}
              {products &&
                products?.length > 0 &&
                inProgressRewardsDisclaimerText &&
                inProgressRewardsDisclaimerText?.value && (
                  <RichTextHelper className="italic" field={inProgressRewardsDisclaimerText} />
                )}
              {products && products?.length > 0 && (
                <div
                  ref={inProgressGridRef}
                  className={clsx(productGrid(), styles.loyaltyProductGrid)}
                  id="inprogress-grid"
                >
                  <>
                    {products &&
                      products?.length > 0 &&
                      products.map((product: ProductProps, index: number) => (
                        <div
                          key={index}
                          className={productItem()}
                          style={{ boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.25)' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleModalClick(index);
                          }}
                        >
                          <div className={productHeading()}>
                            <div className={productImage()}>
                              <div
                                className={clsx(styles.progressFrame, 'progress-frame')}
                                data-value={product?.percentageOfPurchase}
                              >
                                <div className={clsx(styles.progressHalf, 'half')}>
                                  <i className="bg-color-background-brand-1"></i>
                                </div>
                                <div className={clsx(styles.progressFull, 'full')}>
                                  <i className="bg-color-background-brand-1"></i>
                                </div>
                              </div>
                            </div>
                            {product && product?.program?.programid && (
                              <div className={productHeadingProgress()}>
                                <div className={productHeadingImageWrapper()}>
                                  <ContentHubImage programid={product?.program?.programid} />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="bottom">
                            <div className="text-center py-desk-padding-micro-y">
                              <span className={amountLabelStyle()}>
                                {product?.numberOfPurchasesToGo}
                              </span>
                              <span className={purchaseToGoLabelStyle()}>
                                {inProgressRewardsEarnThroughLabel &&
                                inProgressRewardsEarnThroughLabel?.value ? (
                                  <RichTextHelper field={inProgressRewardsEarnThroughLabel} />
                                ) : (
                                  <p>PURCHASES TO GO</p>
                                )}
                              </span>
                            </div>
                            <div className={productShortProgramNameWrapper()}>
                              <div>
                                <h3 className={productShortProgramName()}>
                                  {product?.program?.shortProgramName}
                                </h3>
                                <p className="description">{product?.program?.shortDescription}</p>
                              </div>

                              {product?.program?.DateEnd ? (
                                <div className="earn-date">
                                  {inProgressRewardsEarnThroughLabel &&
                                    inProgressRewardsEarnThroughLabel?.value && (
                                      <>
                                        <RichTextHelper field={inProgressRewardsEarnThroughLabel} />
                                        &nbsp;
                                        <span className="date">{product?.program?.DateEnd}</span>
                                      </>
                                    )}
                                </div>
                              ) : (
                                <>
                                  <div className={inProgressRewardsEarnThroughLabelStyle()}>
                                    {inProgressRewardsEarnThroughLabel &&
                                    inProgressRewardsEarnThroughLabel?.value ? (
                                      <>
                                        <RichTextHelper field={inProgressRewardsEarnThroughLabel} />
                                        &nbsp;
                                        <span className="date">
                                          {inProgressRewardsNoEarnThroughDateMessage &&
                                            inProgressRewardsNoEarnThroughDateMessage?.value && (
                                              <RichTextHelper
                                                field={inProgressRewardsNoEarnThroughDateMessage}
                                              />
                                            )}
                                        </span>
                                      </>
                                    ) : (
                                      'Earn Thru: ONGOING'
                                    )}
                                  </div>
                                  {inProgressRewardsViewPurchasesLinkText &&
                                    inProgressRewardsViewPurchasesLinkText?.value && (
                                      <a
                                        aria-label="inProgress rewards view"
                                        className={inProgressRewardsViewPurchasesLinkTextStyle()}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handlePurchaseModalClick(
                                            index,
                                            product?.program?.programid
                                          );
                                        }}
                                        data-programid={product?.program?.programid}
                                      >
                                        <RichTextHelper
                                          field={inProgressRewardsViewPurchasesLinkText}
                                        />
                                        <svg
                                          aria-label="inProgress"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M11.9213 8.53031L5.84805 14.6035C5.55514 14.8964 5.08027 14.8964 4.78739 14.6035L4.07905 13.8952C3.78664 13.6028 3.78608 13.1289 4.0778 12.8358L8.89095 7.99997L4.0778 3.16419C3.78608 2.87109 3.78664 2.39719 4.07905 2.10478L4.78739 1.39644C5.0803 1.10353 5.55517 1.10353 5.84805 1.39644L11.9213 7.46966C12.2142 7.76253 12.2142 8.23741 11.9213 8.53031Z"
                                            fill="#00AF41"
                                          />
                                        </svg>
                                      </a>
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                          {openModalIndex === index && (
                            <ModalWrapper
                              showCloseButtonInModalHeader={false}
                              closeModalOnOverlayClick={true}
                              onCloseClick={handleModalClose}
                              showModal={true}
                              customPopup={true}
                              popupWidth="max-w-[500px]"
                              popupSpacing="p-[15px]"
                            >
                              <div>
                                {product && product?.program.programid && (
                                  <ContentHubImage programid={product?.program?.programid} />
                                )}
                              </div>
                              <div className="my-1">
                                <h3 className={modalProgramName()}>
                                  {product?.program?.shortProgramName}
                                </h3>
                              </div>
                              <div className={modalProgramDescription()}>
                                {product?.program?.description}
                              </div>
                              {product?.program?.offerExpiresDate ? (
                                <div className={productShortDescription()}>
                                  {product?.program?.offerExpiresDate}
                                </div>
                              ) : (
                                <div className={productNoExpiryDateText()}>
                                  {noExpiryDateText && noExpiryDateText?.value && (
                                    <RichTextHelper field={noExpiryDateText} />
                                  )}
                                </div>
                              )}
                              {product?.program?.termCondition &&
                                product?.program?.termCondition?.length > 0 && (
                                  <div className={termsAndConditionWrapperStyle()}>
                                    <div className={termsAndConditionLabelStyle()}>
                                      {termsAndConditionLabel && termsAndConditionLabel?.value && (
                                        <RichTextHelper field={termsAndConditionLabel} />
                                      )}
                                    </div>
                                    <div className={termsAndConditionTextStyle()}>
                                      {product?.program?.termCondition}
                                    </div>
                                  </div>
                                )}
                            </ModalWrapper>
                          )}
                          {openPurchaseModalIndex === index && (
                            <ModalWrapper
                              showCloseButtonInModalHeader={false}
                              closeModalOnOverlayClick={true}
                              onCloseClick={handlePurchaseModalClose}
                              showModal={true}
                              customPopup={true}
                              popupWidth="max-w-[705px]"
                              popupSpacing="p-[15px]"
                            >
                              <div>
                                <div className="modal-content">
                                  <div className="modal-header">
                                    {qualifyingPurchaseModalHeading &&
                                      qualifyingPurchaseModalHeading?.value && (
                                        <RichTextHelper
                                          className={qualifyingPurchaseModalHeadingStyle()}
                                          field={qualifyingPurchaseModalHeading}
                                        />
                                      )}
                                  </div>
                                  <div className="modal-body">
                                    <div className="w-full">
                                      <div className={qualifyingPurchasesTableColumn1TextWrapper()}>
                                        <div className="w-1/3">
                                          <span>
                                            {qualifyingPurchasesTableColumn1Text &&
                                              qualifyingPurchasesTableColumn1Text?.value && (
                                                <RichTextHelper
                                                  className={qualifyingPurchasesTableColumn1TextStyle()}
                                                  field={qualifyingPurchasesTableColumn1Text}
                                                />
                                              )}
                                          </span>
                                        </div>
                                        <div className="w-5/12">
                                          <span>
                                            {qualifyingPurchasesTableColumn2Text &&
                                              qualifyingPurchasesTableColumn2Text?.value && (
                                                <RichTextHelper
                                                  className={qualifyingPurchasesTableColumn2TextStyle()}
                                                  field={qualifyingPurchasesTableColumn2Text}
                                                />
                                              )}
                                          </span>
                                        </div>
                                        <div className="w-1/4">
                                          <span>
                                            {qualifyingPurchasesTableColumn3Text &&
                                              qualifyingPurchasesTableColumn3Text?.value && (
                                                <RichTextHelper
                                                  className={qualifyingPurchasesTableColumn3TextStyle()}
                                                  field={qualifyingPurchasesTableColumn3Text}
                                                />
                                              )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div role="presentation">
                                      <div>
                                        {purchases?.map((purchase, index) => (
                                          <div
                                            className={clsx(
                                              'flex p-3',
                                              index % 2 !== 0 ? 'bg-color-background-light' : ''
                                            )}
                                            key={index}
                                          >
                                            <div className="w-1/3">
                                              <span className="font-bold">
                                                {purchase?.TransDate &&
                                                  formatDateFromString(
                                                    new Date(purchase?.TransDate as string)
                                                  )}
                                              </span>
                                            </div>
                                            <div className="w-5/12 store">
                                              <span className="font-bold">
                                                {purchase?.storeName}
                                              </span>
                                              <br />
                                              <span>
                                                {purchase?.storeAddress1} <br />{' '}
                                                {purchase?.storeCity}, {purchase?.storeState}{' '}
                                                {purchase?.storeZip}
                                              </span>
                                            </div>
                                            <div className="w-1/4">
                                              <span className="font-bold">
                                                {purchase?.quantity}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalWrapper>
                          )}
                        </div>
                      ))}
                  </>
                </div>
              )}

              {inProgressRewardsDisclaimerText && inProgressRewardsDisclaimerText?.value && (
                <RichTextHelper className="italic mt-3" field={inProgressRewardsDisclaimerText} />
              )}
              <div>
                {products?.length > size &&
                  products?.length <= totalProducts &&
                  inProgressRewardsViewMoreLinkText &&
                  inProgressRewardsViewMoreLinkText?.value && (
                    <div className={loadMoreButtonWrapper()}>
                      <ButtonHelper
                        isLinkfield={false}
                        size="small"
                        variant={'primary'}
                        className={loadMoreButton()}
                        field={inProgressRewardsViewMoreLinkText}
                        onClickHandler={() => loadMore()}
                      />
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className="no-rewards">
              {noInProgressRewardsMessage && noInProgressRewardsMessage?.value && (
                <RichTextHelper className="italic mt-3" field={noInProgressRewardsMessage} />
              )}
              {inProgressRewardsDisclaimerText && inProgressRewardsDisclaimerText?.value && (
                <RichTextHelper field={inProgressRewardsDisclaimerText} />
              )}
            </div>
          )}
        </>
      ) : (
        <>{errorMessage}</>
      )}
    </div>
  );
};

export default InProgressOffers;
