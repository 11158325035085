import { Field, FormikValues, useFormikContext } from 'formik';
import React from 'react';
import FieldWrapper from './FieldWrapper';
import { tv } from 'tailwind-variants';

type LocationDropdownFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  subLabel?: string;
  name?: string;
  requiredvalidationmessage?: string;
  validationpattern?: string;
  hasFullWidth?: boolean;
  options?: Array<OptionFieldProps>;
  firstOptionData?: string;
};

interface OptionFieldProps {
  name?: string;
  children?: {
    results?: abc[];
  };
}
interface abc {
  children?: { results?: { [key: string]: { value: string } }[] };
}

const FieldsVariant = tv(
  {
    slots: {
      fields: [
        'outline-none',
        'bg-color-scale-1-white',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'appearance-none',
        'w-full',
        "bg-[url('/icons/dropdown.svg')]",
        'bg-no-repeat',
        'bg-[position:calc(100%-10px)_center]',
      ],
    },
    variants: {
      device: {
        mobile: {
          fields: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
        },
        desktop: {
          fields: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
        },
      },
      hasErrorStyle: {
        true: { fields: ['border-system-red'] },
        false: { fields: ['border-color-border-mid'] },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const LocationDropdownField: React.FC<LocationDropdownFieldProps> = (
  props: LocationDropdownFieldProps
) => {
  const { id, name, requiredvalidationmessage, required, options, disabled, firstOptionData } =
    props;
  const fieldValidate = (value: string) => {
    let error;
    if (required && !value) {
      error = requiredvalidationmessage;
    }
    return error;
  };
  const { errors, touched, values } = useFormikContext<FormikValues>();
  const hasError = name && touched[name] && errors[name] ? true : false;
  const { fields } = FieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  return (
    <>
      <FieldWrapper {...props} hasError={hasError}>
        <Field
          as="select"
          className={fields()}
          id={id}
          aria-label={name}
          name={name}
          value={values.name}
          validate={fieldValidate}
          defaultValue={'US'} // TODO: make dynamic check for selected option field from sitecore.
          disabled={disabled}
        >
          {/* Dynamic first Option via Sitecore dictionary item */}
          {firstOptionData ? (
            <option value={''} label={firstOptionData} />
          ) : (
            <option value={''} label="" />
          )}

          {options &&
            options.map((state, index) => (
              <optgroup key={index} label={state.name}>
                {state?.children?.results &&
                  state?.children?.results.map((city) => {
                    return (
                      city?.children?.results &&
                      city?.children?.results.map((store, index) => {
                        return (
                          <option
                            key={index}
                            value={`${store.city?.value}, ${store.state?.value} ${
                              !(store.storename?.value === store?.city?.value)
                                ? `(${store.storeid?.value} ${store.storename?.value})`
                                : ''
                            }||${store.storeemail?.value}`}
                          >
                            {`${store.city?.value}, ${store.state?.value} ${
                              !(store.storename?.value === store?.city?.value)
                                ? `(${store.storeid?.value} ${store.storename?.value})`
                                : ''
                            }`}
                          </option>
                        );
                      })
                    );
                  })}
              </optgroup>
            ))}
          {/* {options &&
            options.map((element: OptionFieldProps, index: number): JSX.Element => {
              return (
                <option
                  key={index}
                  value={element?.value?.value || element.code?.value}
                  label={element.text?.value || element.name?.value}
                />
              );
            })} */}
        </Field>
      </FieldWrapper>
    </>
  );
};

export default LocationDropdownField;
