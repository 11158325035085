// components/Modal.js
// import { useState } from 'react';
import React, { ReactNode, useEffect, useRef } from 'react';
import IconHelper from './IconHelper';

interface InternalStylingsProps {
  baseM: () => string;
  closeIconM: () => string;
  contentWrapperM: () => string;
}

interface ModalProps {
  className: string;
  internalStylings: InternalStylingsProps;
  isOpen: boolean;
  onClose: () => void;
  onClickOutside: (flag: boolean) => void;
  children?: ReactNode; // ReactNode type accepts any JSX elements as children
}

const Modal = ({
  className,
  internalStylings,
  isOpen,
  onClose,
  onClickOutside,
  children,
}: ModalProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  // extracting TV stylings:
  const { baseM, closeIconM, contentWrapperM } = internalStylings;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside && onClickOutside(isOpen);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);
  if (!isOpen) return <></>;
  return (
    <div className={className} ref={ref}>
      <div className={baseM()}>
        <div className={contentWrapperM()}>
          <IconHelper icon="close" className={closeIconM()} onClick={onClose} />
          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
