import LinkHelper from 'components/helpers/LinkHelper';

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { tv } from 'tailwind-variants';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';

export const StickyNavigationVariants = tv(
  {
    slots: {
      base: 'StickyNavigation sticky top-0 z-[5] bg-color-brand-primary-1-dark w-full',
      container: 'container flex overflow-x-auto items-center',
      link: ' hover:no-underline hover:text-component-sticky-nav-hover flex-none w-fit focus:text-component-sticky-nav-hover text-color-text-white text-heading-mob-medium-regular font-heading-mob-medium-regular leading-heading-mob-medium-regular',
    },
    variants: {
      size: {
        desktop: {
          container: 'justify-center gap-desk-global-spacing-spacing-9 py-desk-padding-micro-y',
        },
        mobile: {
          container: 'gap-mob-global-spacing-spacing-8 px-0 py-mob-padding-micro-y',
          base: 'px-mob-global-grid-margin',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);

export type StickyNavigationProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.StickyNavigation.Fields.StickyNavigation;
type ListItem = {
  fields?: {
    link?: {
      value?: {
        href?: string;
      };
    };
  };
};
const StickyNavigation = ({ fields, params }: StickyNavigationProps) => {
  const { base, container, link } = StickyNavigationVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const router = useRouter();

  // Code to execute immediately upon component mount

  useEffect(() => {
    const routerasPath = router.asPath.split('#')[1];

    const element1: HTMLElement | null = document.getElementById(routerasPath);

    // Function to find item with href value "#test"
    function findItemWithTestHref(data: ListItem[] | undefined) {
      if (!data) return null; // Null check for data

      for (const item of data) {
        if (item?.fields?.link?.value?.href?.split('#')[1] === routerasPath) {
          return item;
        }
      }
      return null; // Return null if item not found
    }

    // Call the function with a null check
    const testItem = findItemWithTestHref(fields?.list);

    if (testItem && routerasPath && element1) {
      element1.style.top = '-54px';
    }
  }, []);

  const handleClick = (e: React.MouseEvent, link: string) => {
    e.preventDefault();

    const element = document.getElementById(link.split('#')[1]);
    if (element) {
      element.style.top = '-54px';
      window.scrollTo({
        top: element.offsetTop,
        // behavior: 'smooth',
      });

      // Update the URL
      window.history.pushState(null, '', link); // Update the URL without reloading the page
    }
  };

  if (fields === null || fields === undefined || !fields?.list?.length) return <></>;

  return (
    <div className={(params?.Styles ?? '') + base()}>
      <div className={container()}>
        {fields?.list?.map((LinkDetail, index) => {
          return (
            <LinkHelper
              key={index}
              field={LinkDetail?.fields?.link}
              onClick={
                LinkDetail?.fields?.link?.value?.href.split('#')[1]?.length &&
                ((e) => handleClick(e, LinkDetail?.fields?.link?.value?.href))
              }
              className={link()}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StickyNavigation;
