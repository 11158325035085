import { Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useEffect, useState } from 'react';
import AvailableOffers from './AvailableOffers';
import { tv } from 'tailwind-variants';
import InProgressOffers from './InProgressOffers';
import EarnedOffers from './EarnedOffers';
import { useRouter } from 'next/router';
import { useOcSelector } from 'src/redux/ocStore';

const availableOffersStyles = tv(
  {
    slots: {
      base: [''],
      breadCrumbsWrapper: ['flex items-center mb-3'],
      linkToLoyaltyProgram: [
        'text-color-background-brand-1 text-heading-mob-small-regular font-heading-mob-small-regular leading-heading-mob-small-regular hover:no-underline',
      ],
      mainHeadingStyles: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
      ],
      tabsWrapper: ['mt-desk-margin-loose-right border-b border-color-color-border-mid'],
      tabsSection: ['border-b border-color-color-scale-3-light-mid'],
      tabHeading: ['flex gap-desk-space-between-base-horizontal'],
      tabSectionTabs: [
        'text-heading-desk-small-bold font-heading-desk-small-bold  leading-heading-desk-small-bold text-color-scale-7-mid-dark pb-2',
      ],
      tabSectionTabActive: ['text-color-text-black border-b-[3px] border-color-background-brand-1'],
      tabComponents: ['my-desk-padding-micro-bottom'],
    },
    variants: {
      device: {
        mobile: {
          base: [''],
        },
        desktop: {
          base: [''],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const {
  base,
  breadCrumbsWrapper,
  linkToLoyaltyProgram,
  mainHeadingStyles,
  tabsWrapper,
  tabsSection,
  tabHeading,
  tabSectionTabs,
  tabSectionTabActive,
  tabComponents,
} = availableOffersStyles({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

/* Replace with generated models with Leprechaun when they are available */
type LoyaltyProgramTabsProps = {
  fields: {
    dateOfPurchaseLabel: Field<string>;
    qualifyingPurchaseModalHeading: Field<string>;
    qualifyingPurchaseQuantityLabel: Field<string>;
    qualifyingPurchasesTableColumn1Text: Field<string>;
    qualifyingPurchasesTableColumn2Text: Field<string>;
    qualifyingPurchasesTableColumn3Text: Field<string>;
    availableOffersDisclaimerText: Field<string>;
    availableOffersExpiryDateLabel: Field<string>;
    availableOffersNoExpiryDateLabel: Field<string>;
    availableOffersQualifyingPurchasesLinkText: Field<string>;
    availableOffersViewMoreLinkText: Field<string>;
    shopNowButtonText: Field<string>;
    availableOffersHeading: Field<string>;
    availableOffersPageSize: Field<string>;
    availableOffersSubheading: Field<string>;
    noAvailableOffersMessage: Field<string>;
    defaultProgramImage: ImageField;
    linkToNewLoyaltyProgram: LinkField;
    mainHeading: Field<string>;
    tab1Heading: Field<string>;
    tab2Heading: Field<string>;
    tab3Heading: Field<string>;
    earnedRewardsNoEarnedDateText: Field<string>;
    earnedRewardsNoExpiryDateText: Field<string>;
    earnedRewardsTableColumn1Heading: Field<string>;
    earnedRewardsTableColumn2Heading: Field<string>;
    earnedRewardsTableColumn3Heading: Field<string>;
    earnedRewardsTableColumn4Heading: Field<string>;
    earnedRewardsTableColumn5Heading: Field<string>;
    earnedRewardsViewMoreLinkText: Field<string>;
    includeCouponNumberInCouponImage: Field<string>;
    showCouponImageForFollowingCouponStatus: Field<string>;
    couponCodeCopiedLabel: Field<string>;
    couponNotApplicableMessage: Field<string>;
    couponRetrievalErrorMessage: Field<string>;
    couponStatusUnknownMessage: Field<string>;
    earnedRewardsDisclaimerText: Field<string>;
    earnedRewardsHeading: Field<string>;
    earnedRewardsPageSize: Field<string>;
    earnedRewardsSubheading: Field<string>;
    noEarnedRewardsMessage: Field<string>;
    inProgressRewardsDisclaimerText: Field<string>;
    inProgressRewardsEarnThroughLabel: Field<string>;
    inProgressRewardsHeading: Field<string>;
    inProgressRewardsNoEarnThroughDateMessage: Field<string>;
    inProgressRewardsPageSize: Field<string>;
    inProgressRewardsPurchasesToGoLabel: Field<string>;
    inProgressRewardsSubheading: Field<string>;
    inProgressRewardsViewMoreLinkText: Field<string>;
    inProgressRewardsViewPurchasesLinkText: Field<string>;
    noInProgressRewardsMessage: Field<string>;
    expiryDateLabel: Field<string>;
    noExpiryDateText: Field<string>;
    termsAndConditionLabel: Field<string>;
  };
};

const BreadcrumbsIcon = () => (
  <svg
    aria-label="breadcrumb Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.0787 7.46969L10.152 1.39647C10.4449 1.10356 10.9197 1.10356 11.2126 1.39647L11.921 2.10481C12.2134 2.39722 12.2139 2.87113 11.9222 3.16422L7.10905 8.00003L11.9222 12.8358C12.2139 13.1289 12.2134 13.6028 11.921 13.8952L11.2126 14.6036C10.9197 14.8965 10.4448 14.8965 10.152 14.6036L4.07873 8.53034C3.78583 8.23747 3.78583 7.76259 4.0787 7.46969Z"
      fill="rgb(var(--colors-color-text-brand-1))"
    />
  </svg>
);

const LoyaltyProgramTabs = ({ fields }: LoyaltyProgramTabsProps) => {
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);
  const [activeTab, setActiveTab] = useState(0);
  const router = useRouter();

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const anchorHash = window.location.hash;
      if (anchorHash === '#active') {
        setActiveTab(0);
      } else if (anchorHash === '#inprogress') {
        setActiveTab(1);
      } else if (anchorHash === '#earned') {
        setActiveTab(2);
      }
    };
    setActiveTabFromUrl();

    window.addEventListener('hashchange', setActiveTabFromUrl);

    return () => {
      window.removeEventListener('hashchange', setActiveTabFromUrl);
    };
  }, []);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
    if (tabIndex === 0) {
      router.push('#active', undefined, { shallow: true });
    } else if (tabIndex === 1) {
      router.push('#inprogress', undefined, { shallow: true });
    } else if (tabIndex === 2) {
      router.push('#earned', undefined, { shallow: true });
    }
  };

  if (isLoyaltyAccepted === true) {
    return (
      <div className={base()}>
        {fields?.linkToNewLoyaltyProgram && fields?.linkToNewLoyaltyProgram?.value?.href && (
          <div className={breadCrumbsWrapper()}>
            <BreadcrumbsIcon />
            <a
              aria-label="link to new loyalty program"
              className={linkToLoyaltyProgram()}
              href={fields?.linkToNewLoyaltyProgram?.value?.href}
            >
              {fields?.linkToNewLoyaltyProgram?.value?.text}
            </a>
          </div>
        )}
        {fields?.mainHeading && fields?.mainHeading?.value && (
          <div>
            <RichTextHelper className={mainHeadingStyles()} field={fields?.mainHeading} />
          </div>
        )}
        <div className={tabsWrapper()}>
          <div className={tabsSection()}>
            <div className={tabHeading()}>
              {fields?.tab1Heading && fields?.tab1Heading?.value && (
                <button
                  aria-label="tab1Heading button"
                  key={0}
                  className={`${tabSectionTabs()} ${
                    activeTab === 0 ? `${tabSectionTabActive()}` : ''
                  }`}
                  onClick={() => handleTabClick(0)}
                >
                  <RichTextHelper field={fields?.tab1Heading} />
                </button>
              )}
              {fields?.tab2Heading && fields?.tab2Heading?.value && (
                <button
                  aria-label="tab2Heading button"
                  key={1}
                  className={`${tabSectionTabs()} ${
                    activeTab === 1 ? `${tabSectionTabActive()}` : ''
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  <RichTextHelper field={fields?.tab2Heading} />
                </button>
              )}
              {fields?.tab3Heading && fields?.tab3Heading?.value && (
                <button
                  aria-label="tab3Heading button"
                  key={3}
                  className={`${tabSectionTabs()} ${
                    activeTab === 2 ? `${tabSectionTabActive()}` : ''
                  }`}
                  onClick={() => handleTabClick(2)}
                >
                  <RichTextHelper field={fields?.tab3Heading} />
                </button>
              )}
            </div>
          </div>
          <div className={tabComponents()}>
            {activeTab === 0 && (
              <AvailableOffers
                availableOffersHeading={fields?.availableOffersHeading}
                availableOffersSubheading={fields?.availableOffersSubheading}
                availableOffersExpiryDateLabel={fields?.availableOffersExpiryDateLabel}
                availableOffersNoExpiryDateLabel={fields?.availableOffersNoExpiryDateLabel}
                availableOffersQualifyingPurchasesLinkText={
                  fields?.availableOffersQualifyingPurchasesLinkText
                }
                shopNowButtonText={fields?.shopNowButtonText}
                availableOffersViewMoreLinkText={fields?.availableOffersViewMoreLinkText}
                noAvailableOffersMessage={fields?.noAvailableOffersMessage}
                noExpiryDateText={fields?.noExpiryDateText}
                termsAndConditionLabel={fields?.termsAndConditionLabel}
              />
            )}
            {activeTab === 1 && (
              <InProgressOffers
                inProgressRewardsHeading={fields?.inProgressRewardsHeading}
                inProgressRewardsSubheading={fields?.inProgressRewardsSubheading}
                inProgressRewardsEarnThroughLabel={fields?.inProgressRewardsEarnThroughLabel}
                inProgressRewardsNoEarnThroughDateMessage={
                  fields?.inProgressRewardsNoEarnThroughDateMessage
                }
                inProgressRewardsViewPurchasesLinkText={
                  fields?.inProgressRewardsViewPurchasesLinkText
                }
                noExpiryDateText={fields?.noExpiryDateText}
                termsAndConditionLabel={fields?.termsAndConditionLabel}
                inProgressRewardsViewMoreLinkText={fields?.inProgressRewardsViewMoreLinkText}
                noInProgressRewardsMessage={fields?.noInProgressRewardsMessage}
                inProgressRewardsDisclaimerText={fields?.inProgressRewardsDisclaimerText}
                qualifyingPurchaseModalHeading={fields?.qualifyingPurchaseModalHeading}
                qualifyingPurchasesTableColumn1Text={fields?.qualifyingPurchasesTableColumn1Text}
                qualifyingPurchasesTableColumn2Text={fields?.qualifyingPurchasesTableColumn2Text}
                qualifyingPurchasesTableColumn3Text={fields?.qualifyingPurchasesTableColumn3Text}
              />
            )}
            {activeTab === 2 && (
              <EarnedOffers
                noEarnedRewardsMessage={fields?.noEarnedRewardsMessage}
                earnedRewardsHeading={fields?.earnedRewardsHeading}
                earnedRewardsSubheading={fields?.earnedRewardsSubheading}
                earnedRewardsDisclaimerText={fields?.earnedRewardsDisclaimerText}
                earnedRewardsTableColumn1Heading={fields?.earnedRewardsTableColumn1Heading}
                earnedRewardsTableColumn2Heading={fields?.earnedRewardsTableColumn2Heading}
                earnedRewardsTableColumn3Heading={fields?.earnedRewardsTableColumn3Heading}
                earnedRewardsTableColumn4Heading={fields?.earnedRewardsTableColumn4Heading}
                earnedRewardsTableColumn5Heading={fields?.earnedRewardsTableColumn5Heading}
                earnedRewardsNoEarnedDateText={fields?.earnedRewardsNoEarnedDateText}
                earnedRewardsNoExpiryDateText={fields?.earnedRewardsNoExpiryDateText}
                couponStatusUnknownMessage={fields?.couponStatusUnknownMessage}
                earnedRewardsViewMoreLinkText={fields?.earnedRewardsViewMoreLinkText}
                termsAndConditionLabel={fields?.termsAndConditionLabel}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return;
};

export default LoyaltyProgramTabs;
