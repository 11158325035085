import { SitecoreItemId } from 'lib/constants';

// Normalizes a guid format so they can be compared as strings.
export const normalizeGuid = (guid?: string): string => {
  return guid?.toLowerCase().replace(/[\{\-\}]/g, '') ?? '';
};

/**
 * Compares two guids regardless of the format
 * @param guid1 The first guid
 * @param guid2 The second guid
 * @returns Whether the two are equal
 */
export const guidEquals = (guid1: string | undefined, guid2: string | undefined) => {
  return normalizeGuid(guid1) === normalizeGuid(guid2);
};

export function getQueryWords(q: string) {
  return new Set(q?.toLocaleLowerCase().split(' '));
}

// let query = 'dry dog FOOd free of grain';

// let searchPhrase = 'Dog Grain Free';

export function match(
  query: string,
  searchPhrase: string,
  comparison?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  excludePhrase?: any,
  excludecomparison?: string
) {
  const queryWords = getQueryWords(query);

  const searchPhraseWords = getQueryWords(searchPhrase);

  let hasMatch = false;
  if (guidEquals(comparison, SitecoreItemId?.WordLookups?.AllWords)) {
    if ([...searchPhraseWords].every((word) => queryWords.has(word))) {
      hasMatch = true;
    }
  }
  if (guidEquals(comparison, SitecoreItemId?.WordLookups?.AnyWords)) {
    if ([...searchPhraseWords].some((word) => queryWords.has(word))) {
      hasMatch = true;
    }
  }
  if (guidEquals(comparison, SitecoreItemId?.WordLookups?.ContainsPhrase)) {
    if (query.toLocaleLowerCase().indexOf(searchPhrase.toLocaleLowerCase()) >= 0) {
      hasMatch = true;
    }
  }

  if (guidEquals(comparison, SitecoreItemId?.WordLookups?.ExactMatch)) {
    if (query.toLocaleLowerCase().trim() === searchPhrase.toLocaleLowerCase().trim()) {
      hasMatch = true;
    }
  }

  if (hasMatch && excludePhrase) {
    const excludeQueryWords = getQueryWords(query);

    const excludeSearchPhraseWords = getQueryWords(excludePhrase);
    if (guidEquals(excludecomparison, SitecoreItemId?.WordLookups?.AllWords)) {
      if ([...excludeSearchPhraseWords].every((word) => excludeQueryWords.has(word))) {
        hasMatch = false;
      }
    }
    if (guidEquals(excludecomparison, SitecoreItemId?.WordLookups?.AnyWords)) {
      if ([...excludeSearchPhraseWords].some((word) => excludeQueryWords.has(word))) {
        hasMatch = false;
      }
    }
    if (guidEquals(excludecomparison, SitecoreItemId?.WordLookups?.ContainsPhrase)) {
      if (query.toLocaleLowerCase().indexOf(excludePhrase.toLocaleLowerCase()) >= 0) {
        hasMatch = false;
      }
    }

    if (guidEquals(excludecomparison, SitecoreItemId?.WordLookups?.ExactMatch)) {
      if (query.toLocaleLowerCase().trim() === excludePhrase.toLocaleLowerCase().trim()) {
        hasMatch = false;
      }
    }
  }

  return hasMatch;
}

/**
 * Matches as per the rules.
 * @param keyphrase: what you're searching for
 * @param item: sitecore item object
 * @returns boolean
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMatchData = async (keyphrase: string, item: any) => {
  return await match(
    keyphrase || '',
    item?.searchPhrase?.value,
    item?.wordMatch?.value,
    item?.exclusions?.value,
    item?.exclusionMatch?.value
  );
};

/**
 * Iterating over the sitecore map holding folder, and break once satisfies.
 * @param keyphrase: what you're searching for
 * @param items: sitecore item object i.e. holding map folder
 * @param matchFunction: iterate over map holding folder, if matches, return true and break out of loop
 * @returns
 */
export const findMatchingItem = async (
  keyphrase: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matchFunction: (keyphrase: string, item: any) => Promise<boolean>
) => {
  for (const item of items) {
    const isMatch = await matchFunction(keyphrase, item);
    if (isMatch) {
      return item;
    }
  }
  return null; // Return null if no match is found
};

// Remove round braces, dashes, and spaces from the phone number
/**
 *
 * @param value String e.g (123)-456-7890
 * @returns 1234567890
 */
export function formatPhone(value: string) {
  return value?.replace(/[()-\s]/g, '');
}
