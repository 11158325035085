import { Theme } from 'components/helpers/Constants';

interface FontProps {
  fontFamily: string;
  fontUrl: string;
  localFonts: boolean;
}

export const FontLinks: Record<Theme, FontProps[]> = {
  psp: [
    {
      fontFamily: 'effra',
      fontUrl: 'https://use.typekit.net/mqm6ahy.css',
      localFonts: false,
    },
  ],
  wnw: [
    {
      fontFamily: 'filson-soft',
      fontUrl: 'https://use.typekit.net/dxt0veg.css',
      localFonts: false,
    },
  ],
};

const loadedFonts: Set<string> = new Set();

export const loadFonts = (fonts: FontProps[]): void => {
  if (typeof window !== 'undefined') {
    fonts.forEach(({ fontFamily, fontUrl, localFonts }: FontProps) => {
      if (!loadedFonts.has(fontFamily)) {
        const style = document.createElement('style');
        if (localFonts) {
          style.textContent = `
          @font-face {
            font-family: '${fontFamily}';
            src: url('${fontUrl}') format('woff');
            font-display: swap;
            /* Add additional formats and styles as needed */
          }
        `;
        } else {
          style.textContent = `
         @import url('${fontUrl}')
        `;
        }
        document.head.appendChild(style);
        loadedFonts.add(fontFamily);
      }
    });
  }
};

// TODO: Need to restructure below code and implement preload file for optimisation.
export const fonts = (themeName: Theme): void => {
  const site = themeName;
  if (site === 'psp') {
    loadFonts([
      {
        fontFamily: FontLinks[themeName][0].fontFamily,
        fontUrl: FontLinks[themeName][0].fontUrl,
        localFonts: FontLinks[themeName][0].localFonts,
      },
    ]);
  } else if (site === 'wnw') {
    loadFonts([
      {
        fontFamily: FontLinks[themeName][0].fontFamily,
        fontUrl: FontLinks[themeName][0].fontUrl,
        localFonts: FontLinks[themeName][0].localFonts,
      },
    ]);
  }
};
