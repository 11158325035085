import { Presence } from '@sitecore-search/ui';

import styles from './styles.module.css';

export function PspSearchLoader() {
  return (
    <div className={styles['sitecore-loader-container']}>
      <Presence present={true}>
        <svg
          aria-label="Loading"
          aria-busy={true}
          aria-hidden={false}
          focusable="false"
          role="progressbar"
          viewBox="0 0 20 20"
          className={styles['sitecore-loader-animation']}
        >
          <path d="M7.229 1.173a9.25 9.25 0 1 0 11.655 11.412 1.25 1.25 0 1 0-2.4-.698 6.75 6.75 0 1 1-8.506-8.329 1.25 1.25 0 1 0-.75-2.385z" />
        </svg>
      </Presence>
    </div>
  );
}
