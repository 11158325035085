import { tv } from 'tailwind-variants';

export const ChangeStoreVariants = tv(
  {
    slots: {
      container: ['change-store', 'flex', 'gap-[40px]'],
      mainWrapper: '',
      popupSpacing: [],
      dialogueTitle: ['text-color-text-black'],
      storeListRow: ['storeListRow', 'flex', 'border-b', 'border-b-color-border-mid'],
      storeDetailsContainer: [
        'storeDetailsContainer',
        'flex',
        'justify-between',
        'self-stretch',
        'flex-[1_0_0]',
      ],
      storeDetail: ['flex flex-col gap-[12px]'],
      storeName: [
        'text-color-text-dark',
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      storeInventoryContainer: ['flex', 'flex-col', 'items-end', 'min-w-[150px]'],
      inventoryText: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      myStoreWrapper: ['flex', 'gap-[12px]'],
      myStoreTxt: [
        'text-color-text-brand-1',
        'font-heading-mob-large-bold',
        'leading-heading-mob-large-bold',
        'text-heading-mob-large-bold',
      ],
      selectStoreCTA: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        'text-center',
      ],
      openUnitilText: [
        'openUnitilText',
        'text-heading-desk-small-regular',
        'font-heading-desk-small-regular',
        'leading-heading-desk-small-regular',
      ],
      moreDetailsLink: [
        'font-primary',
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'text-color-text-brand-1',
        'focus:text-color-brand-tertiary-3-base',
        'hover:text-color-brand-tertiary-3-base',
        'underline',
      ],
      storeDetailsPopupContainer: ['flex', 'flex-col', 'self-stretch', 'align-start'],
      storeDetailsPopupRow: ['flex', 'self-stretch', 'align-start'],
      storeDetailsPopupRight: ['flex', 'flex-col', 'flex-[1_0_0]'],
      storeDetailsAddress: ['flex', 'flex-col'],
      popupDetailsTitle: [
        'title',
        'text-heading-mob-large-bold',
        'font-heading-mob-large-bold',
        'leading-heading-mob-large-bold',
      ],
      storeDetailsPopupRightPadding: [],
      storeDetailsimage: [],
      storeAddressWrapper: '',
      storeAddress: 'hover:no-underline font-primary',
      storeAddressLink:
        '!underline hover:underline focus:underline text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      noofItemsPerPageText: 'flex flex-wrap gap-x-2 gap-y-0',
      resultText:
        'font-primary inline-block font-body-large-regular text-body-large-regular leading-body-large-regular',
      changeLink:
        'font-primary text-body-large-bold_underline leading-body-large-bold_underline font-body-large-bold_underline underline text-color-text-brand-1  hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    },
    compoundSlots: [],
    variants: {
      device: {
        mobile: {
          container: ['flex-col', 'items-start'],
          popupSpacing: ['py-mob-padding-micro-y', 'px-mob-padding-micro-y'],
          dialogueTitle: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          storeListRow: [
            'flex-col',
            'py-mob-padding-micro-y',
            'px-0',
            'gap-mob-space-between-base-horizontal',
          ],
          storeDetailsContainer: ['gap-mob-margin-base-bottom'],
          storeDetailsPopupRight: ['gap-mob-margin-base-bottom'],
          storeInventoryContainer: ['gap-mob-space-between-base-vertical'],
          selectStoreCTA: [
            'font-body-large-bold',
            'leading-body-large-bold',
            'text-body-large-bold',
            'px-[13px]',
            'py-[16px]',
          ],
          storeDetailsAddress: ['gap-mob-margin-tight-bottom'],
          storeDetailsPopupRightPadding: ['pr-0'],
          storeDetailsPopupRow: ['flex-col', 'gap-mob-margin-loose-bottom'],
          storeDetailsimage: ['w-full'],
          storeDetailsPopupContainer: ['gap-mob-margin-loose-bottom'],
          storeAddressWrapper: 'mt-3',
          noofItemsPerPageText: 'mt-mob-margin-tight-top',
        },
        desktop: {
          container: ['justify-between', 'items-center', 'self-stretch', 'flex-row'],
          popupSpacing: ['py-desk-padding-micro-y', 'px-desk-padding-micro-x'],
          dialogueTitle: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          storeListRow: [
            'flex-row',
            'py-desk-padding-micro-y',
            'px-desk-padding-micro-y',
            'gap-desk-space-between-base-horizontal',
          ],
          storeDetailsContainer: ['gap-desk-margin-base-bottom'],
          storeDetailsPopupRight: ['gap-desk-margin-base-bottom'],
          storeInventoryContainer: ['gap-desk-space-between-base-vertical'],
          selectStoreCTA: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
          storeDetailsAddress: ['gap-desk-margin-tight-bottom'],
          storeDetailsPopupRightPadding: ['pr-desk-margin-loose-right'],
          storeDetailsPopupRow: ['flex-row', 'gap-desk-margin-loose-bottom'],
          storeDetailsimage: ['w-2/12'],
          storeDetailsPopupContainer: ['gap-desk-margin-loose-bottom'],
          storeAddressWrapper: 'mt-3',
          storeAddress:
            'ml-desk-margin-base-top text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          noofItemsPerPageText: 'mt-desk-margin-tight-top',
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
