import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import ModalWrapper from 'components/helpers/ModalWrapper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import React from 'react';
import { tv } from 'tailwind-variants';
const CancelledAutoshipModalVariants = tv(
  {
    slots: {
      base: 'CancelledAutoship flex flex-col',
      buttonWrapper: 'flex',
      title:
        'font-primary text-color-text-black font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
      description:
        'font-primary text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      warningHeading:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      loadingWrapper:
        'font-primary w-full py-desk-component-button-full-padding-y flex text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
      buttonWithBg:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      button:
        'h-fit w-fit px-desk-component-button-full-padding-x rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white focus:text-color-text-white hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark ',
    },
    variants: {
      size: {
        desktop: {
          base: 'px-desk-padding-tight-x py-desk-padding-tight-y gap-desk-space-between-tight-vertical',
          warningHeading: 'mt-desk-margin-micro-bottom',
          buttonWrapper: 'mt-desk-margin-base-top flex-row gap-desk-space-between-base-horizontal',
        },
        mobile: {
          base: 'px-mob-padding-tight-x py-mob-padding-tight-y gap-mob-space-between-tight-vertical',
          warningHeading: 'mt-mob-margin-micro-bottom',
          buttonWrapper: 'mt-mob-margin-base-top flex-col gap-mob-space-between-base-horizontal',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
interface CancelledAutoshipProps {
  fields: {
    title?: Field<string>;
    description?: Field<string>;
    closeText?: Field<string>;
  };
}
const CancelledAutoship = ({ fields }: CancelledAutoshipProps) => {
  const { base, title, description, button, buttonWrapper } = CancelledAutoshipModalVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const handleModalClose = () => {
    setAutoshipContextData({
      ...autoshipContextData,
      isCancelledSuccessfully: false,
    });
  };
  if (fields === undefined || fields === null) return <></>;

  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={handleModalClose}
      showModal={autoshipContextData?.isCancelledSuccessfully}
      customPopup={true}
      popupWidth="max-w-[997px]"
      popupSpacing="p-0"
    >
      <div className={base()}>
        <RichText className={title()} field={fields?.title} />
        <div>
          <RichTextHelper className={description()} field={fields?.description} />
        </div>
        <div className={buttonWrapper()}>
          {fields?.closeText?.value && (
            <button aria-label="close button" className={button()} onClick={handleModalClose}>
              {fields?.closeText?.value}
            </button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CancelledAutoship;
