import { IPersonalizationCondition, ConditionTypes } from '../models';
import { ConditionInputs } from './evaluateRules';

export function evaluateWhereStoreIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_STORE_IS &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_STORE_IS) ||
    !condition?.value ||
    !inputs.storeId
  ) {
    return false;
  }
  const conditionValue = condition?.value.replace(/\s/g, '');
  const storeIds = conditionValue.split(',');
  const isMatch = storeIds.includes(inputs.storeId);
  return isMatch;
}

export function evaluateExceptWhereStoreIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_STORE_IS ||
    !condition?.value ||
    !inputs.storeId
  ) {
    return false;
  }
  return !evaluateWhereStoreIs(condition, inputs);
}

export function evalueWhereStoreTypeIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_STORE_TYPE_IS &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_STORE_TYPE_IS) ||
    !condition?.value
  ) {
    return false;
  }
  const conditionValue = condition?.value;
  switch (conditionValue) {
    case 'BOPIS Enabled':
      return inputs.isBopisEnabled;
    case 'DFS Enabled':
      return inputs.isDfsEnabled;
    case 'Franchise':
      return inputs.isFranchise;
    case 'Corporate':
      return inputs.isCorporate;
    default:
      return false;
  }
}

export function evaluateExceptWhereStoreTypeIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_STORE_TYPE_IS ||
    !condition?.value
  ) {
    return false;
  }
  return !evalueWhereStoreTypeIs(condition, inputs);
}

export function evaluateWhereStoreOffers(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_STORE_OFFERS &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_STORE_OFFERS) ||
    !condition?.value ||
    !inputs.storeServices.length
  ) {
    return false;
  }
  const conditionValue = parseInt(condition?.value, 10);
  return inputs.storeServices.includes(conditionValue);
}

export function evaluateExceptWhereStoreOffers(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_STORE_OFFERS ||
    !condition?.value
  ) {
    return false;
  }
  return !evaluateWhereStoreOffers(condition, inputs);
}

export function evaluateWhenDateHasPassed(condition: IPersonalizationCondition): boolean {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHEN && condition.type !== ConditionTypes.EXCEPT_WHEN) ||
    !condition?.value
  ) {
    return false;
  }
  const currentDate = new Date();
  const when = new Date(condition?.value);
  return currentDate > when;
}

export function evaluateExceptWhenDateHasPassed(condition: IPersonalizationCondition): boolean {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHEN ||
    !condition?.value
  ) {
    return false;
  }
  return !evaluateWhenDateHasPassed(condition);
}

export function evaluateWherePageUrlIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_PAGE_URL_IS &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_PAGE_URL_IS) ||
    !condition?.value ||
    !inputs.path
  ) {
    return false;
  }
  const conditionValue = condition?.value.replace(/\s/g, '');
  const pageUrls = conditionValue.split(',');
  return pageUrls.includes(inputs.path);
}

export function evaluateExceptWherePageUrlIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_PAGE_URL_IS ||
    !condition?.value ||
    !inputs.path
  ) {
    return false;
  }
  return !evaluateWherePageUrlIs(condition, inputs);
}

export function evaluateWhereGoalIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_GOAL_IS &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_GOAL_IS) ||
    !condition?.value ||
    !inputs.goalId
  ) {
    return false;
  }
  if (!inputs.goalId) {
    return false;
  }
  const conditionValue = condition?.value;
  const goals = conditionValue.split(',');
  return goals.includes(inputs.goalId);
}

export function evaluateExceptWhereGoalIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_GOAL_IS ||
    !condition?.value ||
    !inputs.goalId
  ) {
    return false;
  }
  return !evaluateWhereGoalIs(condition, inputs);
}

export function evaluateWhereDeviceIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_DEVICE_IS &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_DEVICE_IS) ||
    !condition?.value
  ) {
    return false;
  }
  if (condition?.value === 'Mobile') {
    return inputs.isMobile;
  }
  return !inputs.isMobile;
}

export function evaluateExceptWhereDeviceIs(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_DEVICE_IS ||
    !condition?.value
  ) {
    return false;
  }
  return !evaluateWhereDeviceIs(condition, inputs);
}

export function evaluateWhereBookingOnline(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_BOOKING_ONLINE &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_BOOKING_ONLINE)
  ) {
    return false;
  }
  return inputs.hasOnlineBooking;
}

export function evaluateExceptWhereBookingOnline(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_BOOKING_ONLINE
  ) {
    return false;
  }
  return !evaluateWhereBookingOnline(condition, inputs);
}

export function evaluateWhereUserHasAcceptedLoyaltyTermsOfUse(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
): boolean {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE)
  ) {
    return false;
  }
  return inputs.userHasAcceptedLoyaltyTermsOfUse;
}

export function evaluateExceptWhereUserHasAcceptedLoyaltyTermsOfUse(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_USER_HAS_ACCEPTED_LOYALTY_TERMS_OF_USE
  ) {
    return false;
  }
  return !evaluateWhereUserHasAcceptedLoyaltyTermsOfUse(condition, inputs);
}

export function evaluateWhereUserHasPetSpecies(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    (condition.type !== ConditionTypes.WHERE_USER_HAS_PET_SPECIES &&
      condition.type !== ConditionTypes.EXCEPT_WHERE_USER_HAS_PET_SPECIES) ||
    !condition?.value ||
    !inputs.userPetSpecies.length
  ) {
    return false;
  }
  return inputs.userPetSpecies.includes(condition?.value);
}

export function evaluateExceptWhereUserHasPetSpecies(
  condition: IPersonalizationCondition,
  inputs: ConditionInputs
) {
  if (
    typeof condition === 'string' ||
    condition.type !== ConditionTypes.EXCEPT_WHERE_USER_HAS_PET_SPECIES ||
    !condition?.value
  ) {
    return false;
  }
  return !evaluateWhereUserHasPetSpecies(condition, inputs);
}
