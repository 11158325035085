import clsx from 'clsx';
import { FieldsVariant } from './DropdownField';

export interface SimpleDropdownOption {
  value: string;
  label: string;
}

interface SimpleDropdownProps {
  value: string;
  options: SimpleDropdownOption[];
  onChange: (value: string) => void;
  placeholder: string;
  className?: string;
}
// This dropdown can be used without the formik context
export function SimpleDropdown({
  value,
  options,
  onChange,
  placeholder,
  className,
}: SimpleDropdownProps) {
  const { fields } = FieldsVariant({
    hasErrorStyle: false,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  return (
    <select
      aria-label="select"
      className={clsx(fields(), 'max-w-[200px]', className)}
      value={value}
      onChange={(e) => onChange(e.target?.value)}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option) => (
        <option key={option?.value} value={option?.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
