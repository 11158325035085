//global
import React from 'react';
import { NextImage, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';

export type CultureContainerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.CultureContainer.Fields.CultureContainer;
//component variants
const cultureContainerVariaints = tv(
  {
    slots: {
      base: ['cultureContainer', 'relative'],
      imageWrapper: ['absolute', 'left-0'],
    },
    variants: {
      device: {
        mobile: { base: ['hidden'] },
        desktop: { base: ['block'] },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
const CultureContainer: React.FC<CultureContainerProps> = ({ fields, params }) => {
  //variaints
  const { base, imageWrapper } = cultureContainerVariaints({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      {fields?.image?.value && (
        <div className={imageWrapper()}>
          <NextImage field={fields?.image} />
        </div>
      )}
    </div>
  );
};

export default withDatasourceCheck()<CultureContainerProps>(CultureContainer);
