import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import ModalWrapper from 'components/helpers/ModalWrapper';
import Loader from 'components/Loader/Loader';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useSiteNameForApi } from 'src/hooks/useSiteName';
import apiConfig, { SubscriptionResponse } from 'src/utils/apiConfig';
import { apiRequest, ApiRequestOptions } from 'src/utils/apiWrapper';
import { tv } from 'tailwind-variants';
const skipDeliveryModalVariants = tv(
  {
    slots: {
      base: 'skipDelivery flex flex-col',
      buttonWrapper: 'flex',
      title:
        'font-primary text-color-text-black font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
      description:
        'font-primary text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      warningHeading:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      loadingWrapper:
        'font-primary w-full py-desk-component-button-full-padding-y flex text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
      buttonWithBg:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      button:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold ',
    },
    variants: {
      size: {
        desktop: {
          base: 'px-desk-padding-tight-x py-desk-padding-tight-y gap-desk-space-between-tight-vertical',
          warningHeading: 'mt-desk-margin-micro-bottom',
          buttonWrapper: 'mt-desk-margin-base-top flex-row gap-desk-space-between-base-horizontal',
        },
        mobile: {
          base: 'px-mob-padding-tight-x py-mob-padding-tight-y gap-mob-space-between-tight-vertical',
          warningHeading: 'mt-mob-margin-micro-bottom',
          buttonWrapper: 'mt-mob-margin-base-top flex-col gap-mob-space-between-base-horizontal',
        },
      },
      isLoading: {
        true: {
          button: 'bg-color-brand-primary-1-disabled cursor-not-allowed text-color-text-white',
        },
        false: {
          button:
            'text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white hover:border-color-brand-primary-1-dark focus:border-color-brand-primary-1-dark focus:text-color-text-white hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
interface SkipDeliveryProps {
  fields: {
    title?: Field<string>;
    description?: Field<string>;
    warningHeading?: Field<string>;
    skipDeliveryText?: Field<string>;
    loaderText?: Field<string>;
    ctaText?: Field<string>;
    button?: Field<string>;
  };
}
const SkipDelivery = ({ fields }: SkipDeliveryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    base,
    title,
    description,
    button,
    warningHeading,
    buttonWrapper,
    loadingWrapper,
    buttonWithBg,
  } = skipDeliveryModalVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const handleModalClose = () => {
    setIsLoading(false);
    setAutoshipContextData({
      ...autoshipContextData,
      isSkipClicked: false,
      subscriptionData: false,
    });
  };

  const site = useSiteNameForApi();
  const token = useAuthToken();
  if (fields === undefined || fields === null) return <></>;

  const onSkipClicked = async () => {
    setIsLoading(true);

    const oldNextDeliveryDate = autoshipContextData?.subscriptionData?.NextOrderDate
      ? DateTime.fromISO(autoshipContextData.subscriptionData.NextOrderDate)
      : null;

    const options: ApiRequestOptions = {
      method: 'POST',
      data: {
        SubscriptionId: autoshipContextData.subscriptionData.ID,
      },
      headers: {
        Authorization: token,
        site: site,
      },
    };
    const response = await apiRequest<SubscriptionResponse>(
      apiConfig.autoship.skipAutoship,
      options
    );

    setIsLoading(false);

    setAutoshipContextData({
      ...autoshipContextData,
      isSkipClicked: false,
      subscriptionData: false,
      isSkippedSuccessfully: response.Success,
      subscriptionId: autoshipContextData.subscriptionData.ID,
      nextDeliveryDate: response.Subscription.NextOrderDate, // updatedNextDeliveryDate.toISO(),
      skippedDeliveryDate: oldNextDeliveryDate?.toFormat('MM/dd/yyyy'),
    });
  };
  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={handleModalClose}
      showModal={autoshipContextData?.isSkipClicked && autoshipContextData?.subscriptionData}
      customPopup={true}
      popupWidth="max-w-[997px]"
      popupSpacing="p-0"
    >
      <div className={base()}>
        <RichText className={title()} field={fields?.title} />
        <div>
          <RichText className={description()} field={fields?.description} />
          <RichText className={warningHeading()} field={fields?.warningHeading} />
        </div>
        <div className={buttonWrapper()}>
          {isLoading ? (
            <div className={loadingWrapper()}>
              <Loader />
              {fields?.loaderText?.value}
            </div>
          ) : (
            <>
              {fields?.skipDeliveryText?.value && (
                <button
                  aria-label="skip delivery"
                  className={buttonWithBg()}
                  onClick={onSkipClicked}
                >
                  {fields?.skipDeliveryText?.value}
                </button>
              )}
            </>
          )}
          {fields?.ctaText?.value && (
            <button
              aria-label="cancel delivery"
              className={button({
                isLoading,
              })}
              onClick={handleModalClose}
            >
              {fields?.ctaText?.value}
            </button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SkipDelivery;
