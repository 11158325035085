import { Field, FormikValues, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import FieldWrapper from './FieldWrapper';
import { tv } from 'tailwind-variants';
import { FieldValues } from 'components/Registration/RegistrationForm/RegistrationForm';

type DropdownFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  subLabel?: string;
  name?: string;
  requiredvalidationmessage?: string;
  validationpattern?: string;
  hasFullWidth?: boolean;
  options?: Array<OptionFieldProps>;
  firstOptionData?: string;
  mergeTextAndValue?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue?: (val: FieldValues | any) => void;
};

interface OptionFieldProps {
  text?: { value: string };
  value?: { value: string };
  code?: { value: string };
  name?: { value: string };
}

const FieldsVariant = tv(
  {
    slots: {
      fields: [
        'outline-none',
        'bg-color-scale-1-white',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'appearance-none',
        'w-full',
        "bg-[url('/icons/dropdown.svg')]",
        'bg-no-repeat',
        'bg-[position:calc(100%-10px)_center]',
        'disabled:bg-color-scale-3-light-mid',
      ],
    },
    variants: {
      device: {
        mobile: {
          fields: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
        },
        desktop: {
          fields: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
        },
      },
      hasErrorStyle: {
        true: { fields: ['border-system-red'] },
        false: { fields: ['border-color-border-mid'] },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const DropdownField: React.FC<DropdownFieldProps> = (props: DropdownFieldProps) => {
  const {
    id,
    name,
    requiredvalidationmessage,
    required,
    options,
    disabled,
    firstOptionData,
    defaultValue,
    mergeTextAndValue,
    setFieldValue,
  } = props;
  const fieldValidate = (value: string) => {
    let error;
    if (required && !value) {
      error = requiredvalidationmessage;
    }
    return error;
  };
  const { errors, touched, values } = useFormikContext<FormikValues>();
  const hasError = name && touched[name] && errors[name] ? true : false;
  const { fields } = FieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  //setting up onChabge values
  useEffect(() => {
    setFieldValue && setFieldValue(values);
  }, [values]);
  return (
    <>
      <FieldWrapper {...props} hasError={hasError}>
        <Field
          as="select"
          aria-label={name}
          className={fields()}
          id={id}
          name={name}
          value={name && values[name]}
          validate={fieldValidate}
          defaultValue={defaultValue} // TODO: make dynamic check for selected option field from sitecore.
          disabled={disabled}
        >
          {/* Dynamic first Option via Sitecore dictionary item */}
          {firstOptionData ? (
            <option value={''} label={firstOptionData} />
          ) : (
            <option value={''} label="" />
          )}
          {options &&
            options.map((element: OptionFieldProps, index: number): JSX.Element => {
              return (
                <option
                  key={index}
                  value={
                    mergeTextAndValue
                      ? `${element?.value?.value || element?.code?.value} || ${
                          element?.text?.value || element?.name?.value
                        }`
                      : element?.value?.value || element?.code?.value
                  }
                  label={element?.text?.value || element?.name?.value}
                />
              );
            })}
        </Field>
      </FieldWrapper>
    </>
  );
};

export default DropdownField;
