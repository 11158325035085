import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Tokens } from 'ordercloud-javascript-sdk';

export const useAuthToken = () => {
  const [cookies, setCookie] = useCookies(['ordercloud.access-token']);
  const token = cookies['ordercloud.access-token'] || Tokens.GetAccessToken() || '';
  useEffect(() => {
    if (!cookies['ordercloud.access-token']) {
      setCookie('ordercloud.access-token', token, { path: '/' });
    }
  }, [token]);
  return token;
};
