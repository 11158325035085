import searchResultsTailwind from '../SearchResultsTailwind';
import { UIProductPrice } from 'src/helpers/ProductPriceUI';

const { listPrice } = searchResultsTailwind({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

export function ProductPrice({ productPrice }: { productPrice: UIProductPrice }) {
  return (
    <div className="w-full h-[48px]">
      {productPrice.hasListPrice && !productPrice.hasListandMemberPrice ? (
        <div className={listPrice()}>
          <div>List Price</div>
          <div className={`${productPrice.showViewPriceInCart ? 'line-through' : ''}`}>
            {productPrice.listPrice}
          </div>
        </div>
      ) : null}
      {productPrice.hasMemberPrice && !productPrice.hasListandMemberPrice ? (
        <div className={listPrice()}>
          <div>Member Price</div>
          <div>${productPrice.memberPrice}</div>
        </div>
      ) : null}
      {productPrice.hasImapPrice && !productPrice.hasListandMemberPrice ? (
        <div className={listPrice()}>
          <div>List Price</div>
          <div className="line-through">${productPrice.imapPrice}</div>
        </div>
      ) : null}
      {productPrice.hasListandMemberPrice ? (
        <>
          <div className={listPrice()}>
            <div>List Price</div>
            <div className="line-through">${productPrice.listPrice}</div>
          </div>
          <div className={listPrice()}>
            <div>Member Price</div>
            <div className="text-color-global-outof-stock">${productPrice.memberPrice}</div>
          </div>
        </>
      ) : null}
    </div>
  );
}
