/* eslint-disable @typescript-eslint/no-unused-vars */
import { Field, LinkField, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React, { useContext, useState } from 'react';
import { useTheme } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';
import { Form as FormikForm, Formik, FormikValues, FormikHelpers } from 'formik';
import { useCookies } from 'react-cookie';
import TextField from 'components/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import clsx from 'clsx';
import { Tokens, MeUser, LineItemProduct } from 'ordercloud-javascript-sdk';
// import TextHelper from 'components/helpers/TextHelper';
import ComponentContext from 'lib/context/ComponentContext';
import { useRouter } from 'next/router';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { validateUser } from 'src/redux/ocAuth/login';
import { apiRequest } from 'src/utils/apiWrapper';
import CheckboxField from 'components/helpers/Form/CheckboxField';
import { MergeCart } from 'components/Checkout/helper/MergeCartHelper';
import { getUser } from 'src/redux/ocUser';
import { unwrapResult } from '@reduxjs/toolkit';
import { clearCurrentOrder } from 'src/redux/ocCurrentOrder';
import { getWishlist, setWishlistCollectionId, updateProductInWishlist } from 'src/redux/wishlist';
import { handleCTRewardsSignIn } from 'lib/crowd-twist/ct-sign-in';
import { getBVData } from 'components/BazarVoice/BazarVoiceToken';
import { setIsAnonymous } from 'src/redux/ocAuth';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { trackUserLoginEvent } from '@sitecore-search/react';
import { removeSoftLoginDependencies } from 'src/utils/handleSoftLogin';
import { statusMessage } from 'components/helpers/Constants';
import Loader from 'components/Loader/Loader';
import { currentPath } from 'components/helpers/Constants';

export type LoginFormProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          createLink: LinkField;
          formTitle: Field<string>;
          formDescription: Field<string>;
          ForgotPasswordLink: LinkField;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          forgotPasswordTitle?: Field<string>;
          forgotPasswordDisclaimer?: Field<string>;
          forgotPasswordSubmit?: Field<string>;
          forgotPasswordCancel?: Field<string>;
          afterSubmit: Field<string>;
          submitButtonText: Field<string>;
          ForgotPasswordText: Field<string>;
          successRedirectUrl?: { jsonValue: LinkField };
          failureMessage?: Field<string>;
          consentMessage?: Field<string>;
          migratedUserMessage?: Field<string>;
        };
      };
    };
  };
interface FormValuesTypes {
  Email: string;
}
const loginFormStylings = tv(
  {
    slots: {
      loginFormWrapper: ['loginForm', 'flex', 'flex-col', 'gap-y-[24px]'],
      form: ['flex', 'flex-col', 'gap-y-[24px]', 'items-start'],
      fieldWrap: ['flex', 'justify-between', 'w-full'],
      position: 'relative',
      ctaLink: [
        'text-color-brand-primary-1-base',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline cursor-pointer',
      ],
      button: [
        'flex',
        'content-center',
        'items-center',
        'rounded-desk-global-radius-small',
        'text-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'outline-none',
        'justify-center',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
      consentMsg: [
        'text-color-text-black',
        'text-body-small-bold',
        'font-body-small-bold',
        'leading-body-small-bold',
      ],
      userFields: ['flex', 'flex-col', 'w-full'],
      loaderWrapper: ['flex', 'items-center'],
      formTitle: ['pb-[12px]'],
      buttonWrapper: ['flex'],
      migratedUserMessage: ['rte', 'migratedUserMessage', '[&>strong]:cursor-pointer'],
    },
    compoundSlots: [],
    variants: {
      site: {
        psp: {},
        wnw: {},
      },
      size: {
        mobile: {
          fieldWrap: [
            'gap-x-mob-space-between-loose-horizontal',
            'flex-col',
            'gap-mob-space-between-base-vertical',
          ],
          button: [
            'gap-mob-component-button-full-space-between',
            'py-mob-component-button-full-padding-y',
            'px-mob-component-button-full-padding-x',
            'w-full',
          ],
          userFields: ['gap-mob-space-between-tight-vertical'],
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          formTitle: [
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
            'text-heading-mob-large-bold',
          ],
          buttonWrapper: ['w-full', 'justify-center'],
        },
        desktop: {
          fieldWrap: [
            'gap-x-desk-space-between-loose-horizontal',
            'flex-row',
            'gap-none',
            'flex',
            'flex-col',
            'items-end',
          ],
          button: [
            'gap-desk-component-button-full-space-between',
            'py-desk-component-button-full-padding-y',
            'px-component-button-full-padding-x',
          ],
          userFields: ['gap-desk-space-between-tight-vertical'],
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          formTitle: [
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
            'text-heading-desk-large-bold',
          ],
          buttonWrapper: ['basic-1/2', 'w-fit'],
        },
      },
      outlineCta: {
        true: {
          button: [
            'bg-color-background-white',
            'text-color-text-brand-1',
            'border-2 border-solid border-color-brand-primary-1-dark',
            'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
            'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
          ],
        },
        false: {
          button: [
            'bg-color-brand-primary-1-base',
            'text-color-text-white',
            'hover:bg-color-brand-primary-1-dark',
            'focus:bg-color-brand-primary-1-dark',
          ],
        },
      },
      isDisabled: {
        true: {
          button: ['cursor-not-allowed'],
        },
        false: {
          button: ['pointer'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'], // `true` to apply to all screen sizes
  }
);
const LoginForm = ({ fields, params }: LoginFormProps): JSX.Element => {
  const { themeName, themeNameUpper } = useTheme();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  // const { clearPriceCache } = useProductPrice();
  const dispatch = useOcDispatch();
  const {
    loginFormWrapper,
    form,
    fieldWrap,
    position,
    ctaLink,
    button,
    consentMsg,
    userFields,
    loaderWrapper,
    formTitle,
    buttonWrapper,
    migratedUserMessage,
  } = loginFormStylings({
    site: themeName,
    size: {
      initial: 'mobile',
      md: 'desktop',
    },
  });
  const [cookies, setCookie] = useCookies(['ct-auth', 'ordercloud.access-token']);
  const router = useRouter();
  const initialValues = {
    Email: '',
    Password: '',
    RememberMe: false,
  };
  const transFormFields = transformData(fields?.data?.data?.formFields);
  const [finishSubmit, setFinishSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const getUSerWishlist = async (userObj: MeUser) => {
    await dispatch(setWishlistCollectionId(`Wishlist-${userObj?.ID}`));
    await dispatch(getWishlist(`Wishlist-${userObj?.ID}`));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const isLoginEnable = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.enableLogin?.jsonValue?.value
  );
  const hasMigratedUserMsg = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.migratedUserMessage
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openForgotPassword = (event: any) => {
    if (event?.target?.tagName === 'STRONG') {
      setcomponentContextData((prevData) => ({
        ...prevData,
        ToggleForgotPasswordForm: true,
      }));
    }
  };

  const addProductInWishlist = async (
    productId: string,
    product: ProductSearchResultModelWithVariants | LineItemProduct
  ) => {
    await dispatch(
      updateProductInWishlist({
        productId: productId,
        product: product,
        storeId: myStoreData?.storeId,
        addOnly: true,
        clickFrom: componentContextData?.clickFrom,
      })
    );
  };
  const handleModalOpen = () => {
    setcomponentContextData({
      ...componentContextData,
      ToggleLoginOverlayForm: false,
      ToggleForgotPasswordForm: true,
    });
  };
  const configurationDetails = useOcSelector((state) => state?.configurations.data);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const lineItems = useOcSelector((state) => state?.ocCurrentOrder?.lineItems);
  const anonCartId = cart?.ID;
  const anonProducts: string[] = lineItems?.map(function (val) {
    return val.ProductID;
  }) ?? [''];
  const fetchConfigDetails = async () => {
    const response = await configurationDetails;
    return response;
  };
  fetchConfigDetails();
  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;
  const forLogin = async (values: FormikValues, _routeURL: string | undefined) => {
    const token = cookies['ordercloud.access-token']
      ? cookies['ordercloud.access-token']
      : Tokens.GetAccessToken();

    if (values && values?.Email && values?.Password) {
      // MIDDLEWARE CALL: to call via a helper function
      // will need to send the below payload to an API endpoint here which will handle the flow.
      const loginUserUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/login`;
      //const clientId = process.env.NEXT_PUBLIC_PSP_OC_CLIENT_ID;
      const headersData = {
        Authorization: token,
        site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
        // PSP-3043: Migrated User: Redirect to Reset Password to modify oldPassword
        requesturl: window.location.origin,
      };
      const postData = {
        username: values?.Email,
        password: values?.Password,
      };
      const options = { method: 'POST', headers: headersData, data: postData };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await apiRequest(loginUserUrl, options);
      const credentials = {
        username: values?.Email,
        password: values?.Password,
      };
      if (response && !response.Errors && !(response?.Location && response?.Status == 'OK')) {
        await dispatch(setIsAnonymous(false));
      }
      if (response && response.Errors) {
        setShowError(true);
        if (response.Errors && response.Errors[0]['ErrorCode'] == 'Auth.LockedUser') {
          if (response?.ismobile) {
            router.push(
              configurationDetails?.AccountUnlockPage +
                '?un=' +
                encodeURIComponent(values?.Email) +
                '&ismobile=true'
            );
          } else {
            router.push(
              configurationDetails?.AccountUnlockPage + '?un=' + encodeURIComponent(values?.Email)
            );
          }
        }
      } else if (response && response?.Location && response?.Status == 'OK') {
        if (componentContextData && componentContextData?.ToggleLoginOverlayForm) {
          await setcomponentContextData({
            ...componentContextData,
            ToggleLoginOverlayForm: false,
          });
        }
        // PSP-3043: Migrated User: Redirect to Reset Password to modify oldPassword
        router.push(response?.Location);
      } else if (response && response?.access_token) {
        // 1. Get user details and check for verified and isActive.
        Tokens.SetAccessToken(response.access_token);
        removeSoftLoginDependencies();

        /**
         * if checked RememberMe, only than keep RefreshTokens else delete them.
         */
        if (response?.refresh_token && values?.RememberMe == true) {
          Tokens.SetRefreshToken(response.refresh_token);
          localStorage.setItem('isKeepMeLoggedIn', 'true');
        } else {
          Tokens.RemoveRefreshToken();
          if (localStorage.getItem('isKeepMeLoggedIn')) {
            localStorage.removeItem('isKeepMeLoggedIn');
          }
        }

        // Sending user login event to discover for tracking
        trackUserLoginEvent({
          email: values?.Email,
        });

        // clearPriceCache();
        setShowError(false);
        // This is not needed as we're fetching the latest user from the 'unwrapResult(result)' given below:
        // const userObj = await Me.Get();

        if (anonCartId) {
          await MergeCart(dispatch, anonCartId, anonProducts, token);
        }
        const result = await dispatch(getUser());
        dispatch(clearCurrentOrder());

        const user = unwrapResult(result);

        validateUser(user, credentials, response);
        await getUSerWishlist(user);

        const urlQuery = router?.query;
        if (urlQuery?.bveventid) {
          const data = await getBVData(
            {
              userId: user?.ID,
              email: user?.Email,
              bvEventId: urlQuery?.bveventid,
            },
            themeName,
            token
          );
          if (data?.Url) {
            return router.push(data.Url);
          }
        }

        // Page redirect to Return Url
        const returnUrl = router?.query?.ReturnUrl || router?.query?.returnurl;
        if (
          componentContextData &&
          componentContextData?.ToggleLoginOverlayForm &&
          componentContextData?.productIdToAddInWishlistAfterLogin
        ) {
          await addProductInWishlist(
            componentContextData?.productIdToAddInWishlistAfterLogin,
            componentContextData?.productToAddInWishlistAfterLogin
          );
          await setcomponentContextData({
            ...componentContextData,
            ToggleLoginOverlayForm: false,
          });
        } else if (componentContextData && componentContextData?.ToggleLoginOverlayForm) {
          await setcomponentContextData({
            ...componentContextData,
            ToggleLoginOverlayForm: false,
          });
        }

        if (response.firstlogin && user?.xp?.CartUser == true) {
          /**
           * PSP-1989: Return users created from Checkout to '/checkout' page upon first login
           */
          router.push(currentPath?.isCheckout || '/checkout');
        }
        // First Login
        else if (response.firstlogin && returnUrl !== '/checkout') {
          // todo: to add if user is in checkout flow, don't redirect (check with team)
          // router.push(configurationDetails?.TermsRewardPage || '/accountmanagement/my-rewards');

          // the router.push didn't work, the isAnonymous is getting updated after refresh
          window.location.href =
            configurationDetails?.TermsRewardPage || '/accountmanagement/my-rewards';
        }
        // With Return URL
        else if (returnUrl) {
          // Previous changes
          if (router?.query?.favoritesku) {
            await addProductInWishlist(
              router?.query?.favoritesku?.toString(),
              componentContextData?.productToAddInWishlistAfterLogin
            );
          }

          // Checkout
          if (returnUrl === '/checkout') {
            /**
             * If we have a return URL, we need to be pushing the user to that particular URL.
             */
            router.push(returnUrl);
            // TODO: to confirm with team, and remove below snippet later:
            // if (routeURL) {
            //   router.push(routeURL);
            // } else {
            //   router.push('/');
            // }
          }

          // My Rewards
          else if (returnUrl?.includes('/accountmanagement/my-rewards')) {
            if (user && user?.xp?.LoyaltyAccepted === true) {
              // User accepted the Loyalty Terms and Conditions (sign into CT)
              if (user?.xp?.UnityId) {
                const ctSignInRedirectUrl = await handleCTRewardsSignIn(user?.xp?.UnityId);

                if (ctSignInRedirectUrl) {
                  setCookie('ct-auth', true);
                  // router.push(ctSignInRedirectUrl);
                  window.location.href = ctSignInRedirectUrl.toString();
                }
              } else {
                window.location.href = returnUrl.toString();
              }
            }
            // User didn't accept Loyalty Terms and Conditions (do not sign into CT)
            else {
              // the router.push didn't work, the isAnonymous is getting updated after refresh
              // router.push(returnUrl.toString());
              window.location.href = returnUrl.toString();
            }
          } else {
            router.push((returnUrl as string) || '/');
          }
        }
        // Redirect to the homepage if no conditions meet
        else {
          // router.push('/');
          if (!componentContextData?.ToggleLoginOverlayForm) {
            router.push('/');
          }
        }
      }
      return setFinishSubmit(false);
    }
    return null;
  };
  // fetching forgotPasswordText:
  const forgotPasswordText =
    fields.data?.data?.formFields.targetItems.find((x) => x.id?.value === 'ForgotPassword')?.label
      ?.value || 'Forgot Passord';

  return (
    <>
      <div className={loginFormWrapper({ className: params?.Styles ?? '' })}>
        {/* 1sst Column */}
        {/* LOGIN FORM< */}
        <Formik
          initialValues={initialValues}
          onSubmit={(
            values: FormValuesTypes,
            { setSubmitting }: FormikHelpers<FormValuesTypes>
          ) => {
            setFinishSubmit(true);
            forLogin(values, fields.data?.data?.successRedirectUrl?.jsonValue?.value?.href);
            setSubmitting(false);
          }}
        >
          <FormikForm className={form()}>
            {(fields?.data?.data?.title?.value || fields?.data?.data?.shortDescription?.value) && (
              <div className={'formTitleBlock()'}>
                <p className={'formTitle()'}>{fields?.data?.data?.title?.value}</p>
                <p className={'formDescription()'}>{fields?.data?.data?.shortDescription?.value}</p>
              </div>
            )}
            <div className={userFields()}>
              {showError && (
                <div className="text-system-red">{fields?.data?.data?.failureMessage?.value}</div>
              )}
              {/* General Message to display for migration */}
              {hasMigratedUserMsg?.value && (
                <RichText
                  tag="p"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onClick={(event: any) => {
                    openForgotPassword(event);
                  }}
                  className={migratedUserMessage()}
                  field={hasMigratedUserMsg}
                />
              )}
              <p className={formTitle()}>
                {fields?.data?.data?.title?.value || 'Log in or create an account below.'}
              </p>

              <div className={fieldWrap()}>
                <TextField {...transFormFields.Email} />
              </div>
              <div className={clsx(fieldWrap(), position())}>
                <TextField
                  {...transFormFields.Password}
                  fieldtype={showPassword ? 'text' : 'password'}
                  togglePasswordVisibility={togglePasswordVisibility}
                  showPassword={showPassword}
                />
              </div>
            </div>
            <a aria-label="modal open" className={ctaLink()} onClick={handleModalOpen}>
              {forgotPasswordText}
            </a>
            <div className="flex flex-col gap-y-[12px]">
              {/* TODO: Find some condition to bifurcate between Login and LoginOverlay  */}
              {/* {!isAnonymous && ( */}
              <div className={fieldWrap()}>
                {<CheckboxField {...transFormFields.RememberMe} singleCheckbox={true} />}
              </div>
              {/* )} */}
              <p className={consentMsg()}>{fields?.data?.data?.consentMessage?.value}</p>
            </div>
            <div className={buttonWrapper()}>
              {!finishSubmit && isAnonymous && (
                <button
                  type="submit"
                  className={button({
                    outlineCta: false,
                    isDisabled: !(isLoginEnable === 'true'),
                  })}
                  disabled={!(isLoginEnable === 'true')}
                >
                  {fields?.data?.data?.submitButtonText?.value}
                </button>
              )}
              {finishSubmit && (
                <div className={loaderWrapper()}>
                  <Loader />
                  <p>{statusMessage?.loginInProgress}</p>
                </div>
              )}
            </div>
          </FormikForm>
        </Formik>
      </div>
    </>
  );
};
export default withDatasourceCheck()<LoginFormProps>(LoginForm);
