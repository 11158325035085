//global
import React from 'react';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local

//lib
import { ComponentProps } from 'lib/component-props';
import TextHelper from 'components/helpers/TextHelper';
//type
export type HeadingProps = ComponentProps & {
  fields: unknown;
};
//component variants
const headingVariants = tv(
  {
    slots: {
      base: 'heading',
      title: 'font-primary',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          title:
            'mb-3 text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold font-heading-mob-xLarge-bold',
        },
        desktop: {
          title:
            'mb-[23px] text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold font-heading-desk-xLarge-bold',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
//main component
const Heading: React.FC<HeadingProps> = ({ params }) => {
  const { base, title } = headingVariants({ size: { initial: 'mobile', lg: 'desktop' } });
  const siteContext = useSitecoreContext();

  return (
    <div className={base({ className: params?.Style ?? '' })}>
      <TextHelper
        field={siteContext?.sitecoreContext?.route?.fields?.heading as Field<string>}
        tag="p"
        className={title()}
      />
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default Heading;
