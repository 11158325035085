//global
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
//redux
import useOcCurrentOrder from 'src/hooks/useOcCurrentOrder';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { createLineItem, updateLineItem } from 'src/redux/ocCurrentOrder';
//lib
import { ComponentProps } from 'lib/component-props';
//helpers
import TextHelper from 'components/helpers/TextHelper';
//types
import { PSP } from 'models/PetSuppliesPlus.Model';
import ButtonHelper from 'components/helpers/Button';
import { FulfillmentType } from 'components/helpers/Constants';
import { DiscreteLineItem } from 'components/helpers/Constants';
import { LineItemWithXp } from 'src/redux/xp';
import ComponentContext from 'lib/context/ComponentContext';
import IconHelper from 'components/helpers/IconHelper';
import useOcCart from 'src/hooks/useOcCart';
import addATipVariants from './AddATipVariant';
import Loader from 'components/Loader/Loader';

export type AddATipProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.AddATip.Fields.AddATip;

//component variants

const AddATip = ({ fields, params }: AddATipProps): JSX.Element => {
  //importing variants
  const {
    base,
    delveryTipping,
    title,
    priceError,
    buttonWrapper,
    tipLabel,
    tipCard,
    currencyLabel,
    tipField,
    customTipWrapper,
    customTip,
    tipButton,
    tipContainer,
    bottomWrapper,
    loader,
    autoshipTip,
    autoshipTipCheckboxIcon,
    autoshipTipCheckbox,
    labelTitle,
  } = addATipVariants({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const currentOrder = useOcCurrentOrder();

  const dispatch = useOcDispatch();

  const lines = currentOrder.lineItems;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);

  const tipLineitem = lines?.find((x) => x.ProductID === DiscreteLineItem.TIP);
  const { componentContextData, setExpandedForm } = useContext(ComponentContext);

  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();
  const hasAutoShip = productlineitems.some((x) => x.xp?.Autoship);
  const shippingAddress = productlineitems?.[0]?.ShippingAddress;
  const pickupInformation = cart?.xp?.PickupInfo;
  const [collapse, setCollapse] = useState<boolean>(false);

  const [showCustomTip, setShowCustomTip] = useState(false);
  const [isCustomTip, setIsCustomTip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customTipUnsaved, setCustomTipUnsaved] = useState('0.00');
  const [customTipSaved, setCustomTipSaved] = useState('0.00');
  const [customTipError, setCustomTipError] = useState('');
  // const [isAutoshipTipChecked, setIsAutoshipTipChecked] = useState(true);
  // const [DFS, setDFS] = useState(false);
  const [tipAmmount, setTipAmmount] = useState<number>(0);

  const DFS = currentOrder.order?.xp?.Fulfillment === FulfillmentType?.DFS;

  const isAutoshipTipChecked = tipLineitem?.xp?.TipIsRecurring;

  const setTip = async (amount: number) => {
    setIsLoading(true);
    setTipAmmount(amount);
    const hasTipLineitem = tipLineitem?.ProductID;
    const updatedLineItem: LineItemWithXp = {
      ...tipLineitem,
      ProductID: DiscreteLineItem.TIP,
      xp: {
        ...tipLineitem?.xp,
        TipAmount: amount,
        DT: 0,
      },
    };
    if (hasTipLineitem) {
      await dispatch(updateLineItem({ ...updatedLineItem, ID: tipLineitem?.ID }));
    } else {
      await dispatch(createLineItem({ request: updatedLineItem }));
    }
    setIsLoading(false);
  };

  const setTipIsRecurring = async (isRecurring: boolean) => {
    // await dispatch(removeLineItem('2N9rL0DgBEWp9FUvRYup-w'));
    setIsLoading(true);
    const hasTipLineitem = tipLineitem?.ProductID;
    const updatedLineItem: LineItemWithXp = {
      ...tipLineitem,
      ProductID: DiscreteLineItem.TIP,
      xp: {
        ...tipLineitem?.xp,
        TipIsRecurring: isRecurring,
      },
    };

    hasTipLineitem && (await dispatch(updateLineItem({ ...updatedLineItem, ID: tipLineitem?.ID })));

    // setIsAutoshipTipChecked(isRecurring);
    setIsLoading(false);
  };

  const openPaymentOnceFormDone = () => {
    if (
      cart?.xp?.Fulfillment === FulfillmentType.BOPIS &&
      cart?.BillingAddress &&
      pickupInformation?.FirstName
    ) {
      setCollapse(false);
      setExpandedForm('payment');
      setTimeout(() => {
        const paymentTarget = document.getElementById('paymentsection');
        paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else if (
      cart?.xp?.Fulfillment === FulfillmentType.DFS &&
      cart?.BillingAddress &&
      shippingAddress?.FirstName
    ) {
      setCollapse(false);
      setExpandedForm('payment');
      setTimeout(() => {
        const paymentTarget = document.getElementById('paymentsection');
        paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else {
      setCollapse(true);

      // for opening forms at load.
      if (cart?.xp?.Fulfillment === FulfillmentType.BOPIS) {
        if (!pickupInformation?.FirstName) {
          setExpandedForm('pickup');
        } else {
          if (!cart?.BillingAddress && componentContextData?.expandedForm !== 'billing') {
            setExpandedForm('billing');
          }
        }
      } else if (cart?.xp?.Fulfillment === FulfillmentType.DFS) {
        if (!shippingAddress?.FirstName) {
          setExpandedForm('delivery');
          setCollapse(true);
        } else {
          if (!cart?.BillingAddress) {
            // setExpandedForm('billing');
          }
        }
      } else {
      }
    }
  };

  useEffect(() => {
    openPaymentOnceFormDone();
  }, [cart?.xp?.Fulfillment, cart?.BillingAddress, shippingAddress?.FirstName]);

  useEffect(() => {
    if (componentContextData?.expandedForm == 'payment') {
      setCollapse(false);
      setTimeout(() => {
        const paymentTarget = document.getElementById('paymentsection');
        paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else {
      // check if billing address if true or not.
      setCollapse(true);
    }
  }, [cart?.xp?.Fulfillment, componentContextData?.expandedForm]);

  const hasCustomTip = parseFloat(customTipSaved) > 0;

  const tipPrice = fields?.tipPrice?.value;

  const tips = [...new URLSearchParams(tipPrice).entries()];

  const [disableTipping, setDisableTipping] = useState<number>();

  //set the default tip
  useEffect(() => {
    if (
      DFS &&
      lines &&
      !tipLineitem &&
      tipAmmount != Number(fields?.defaultTip?.value) &&
      fields?.defaultTip?.value &&
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tips?.some(([_, value]) => value === fields?.defaultTip?.value)
    ) {
      setTip(Number(fields?.defaultTip?.value));
    } else if (
      DFS &&
      lines &&
      tipLineitem &&
      tipLineitem?.xp?.TipAmount &&
      tipAmmount != tipLineitem?.xp?.TipAmount
    ) {
      setTipAmmount(tipLineitem?.xp?.TipAmount);
      if (
        tipLineitem?.xp?.TipAmount &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !tips?.find((tip) => tip?.[1] == (tipLineitem?.xp?.TipAmount as any))
      ) {
        setIsCustomTip(true);
        setCustomTipSaved(tipLineitem?.xp?.TipAmount?.toFixed(2));
        setCustomTipUnsaved(tipLineitem?.xp?.TipAmount?.toFixed(2));
      }
    }
  }, [DFS, tipLineitem, myStoreData?.storeId]);
  //Setting default tip checked if oder is autoship
  useEffect(() => {
    productlineitems && productlineitems?.length > 0 && hasAutoShip
      ? setTipIsRecurring(true)
      : setTipIsRecurring(false);
  }, [hasAutoShip]);
  useEffect(() => {
    if (myStoreData?.storeId) {
      // if no data is found on store, it will take delivery tipping as fallback from the GQL itself.
      setDisableTipping(Number(myStoreData?.disableTipping));
    }
  }, [myStoreData?.disableTipping]);
  if (!myStoreData || !cart) {
    return (
      <div id="AddATip" className="AddATip" data-comopnent="component/AddATip/AddATip" hidden></div>
    );
  }

  return (
    <div
      className={clsx(
        base({ isCollapse: collapse, disableTip: disableTipping == 1 ? true : false }),
        `${params?.Styles}`
      )}
      id="paymentsection"
    >
      <span className={labelTitle({ isCollapse: collapse })}>
        {DFS ? '4.' : '3.'}&nbsp;
        <TextHelper field={fields?.title} />
      </span>

      {disableTipping !== 1 && DFS && !collapse ? (
        <>
          <div className={delveryTipping()}>
            {isLoading && (
              <div className={loader()}>
                <Loader />
              </div>
            )}
            <div
              id="delvey-tipping-content-container"
              className="flex flex-col gap-desk-margin-base-bottom"
            >
              <TextHelper field={fields?.subHeading} tag="p" className={title()} />
              <div className={tipContainer()}>
                <ul className="flex w-full">
                  {tips.map(([label, value], index) => {
                    const amount = parseInt(value);
                    return (
                      index !== tips?.length - 1 && (
                        <li
                          key={label}
                          className={clsx(tipCard(), {
                            'font-bold !border-color-border-brand border-x-2 [&+li]:!border-l-0 ':
                              tipAmmount === amount && !hasCustomTip,
                            'border-l-2': tipAmmount !== amount && !hasCustomTip,
                            'border-l-2 rounded-l': index === 0,
                            'hidden ': index === tips?.length - 1,
                            '!border-color-border-mid font-normal !border-r-0 [&+li]:!border-l-2':
                              isCustomTip,
                          })}
                          onClick={() => {
                            setShowCustomTip(false);
                            setIsCustomTip(false);
                            setCustomTipSaved('0.00');
                            setCustomTipUnsaved('0.00');
                            setTip(amount);
                          }}
                        >
                          <p className={tipLabel()}>{label}</p>
                        </li>
                      )
                    );
                  })}
                  <li
                    className={clsx(customTip(), {
                      'font-bold ': hasCustomTip,
                      '!border-color-border-brand': isCustomTip,
                    })}
                    onClick={() => {
                      setShowCustomTip(true);
                      setIsCustomTip(true);
                    }}
                  >
                    {hasCustomTip ? <span className="block">${customTipSaved}&nbsp;</span> : null}
                    <TextHelper tag="span" field={fields?.customTip} className="hidden lg:flex" />
                    <TextHelper
                      tag="span"
                      field={fields?.customTipMobile}
                      className="flex lg:hidden"
                    />
                  </li>
                </ul>
              </div>
            </div>
            {showCustomTip ? (
              <form id="delivery-tip-custom-amount-body" className={customTipWrapper()}>
                <div className="delivery-tip-input w-full">
                  <div className="relative">
                    <span className={currencyLabel()}>$</span>
                    <input
                      aria-label="tip-amount"
                      className={tipField()}
                      value={customTipUnsaved}
                      type="text"
                      onChange={(e) => {
                        const value = e.target?.value;
                        setCustomTipUnsaved(value);
                        const numValue = parseFloat(value);
                        if (isNaN(numValue) || numValue < 0 || isNaN(Number(value))) {
                          setCustomTipSaved(Number(0).toFixed(2));
                          setCustomTipError('Value must be a positive number or zero.');
                        } else {
                          setCustomTipError('');
                          setCustomTipSaved(numValue.toFixed(2));
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target?.value;
                        const numValue = parseFloat(value);
                        setCustomTipUnsaved(value || '0.00');
                        if (isNaN(numValue) || numValue < 0 || isNaN(Number(value))) {
                          setCustomTipSaved(Number(0).toFixed(2));
                          setCustomTipError('Value must be a positive number or zero.');
                        } else {
                          setCustomTipError('');
                          setCustomTipSaved(numValue.toFixed(2));
                        }
                      }}
                    />
                  </div>

                  {customTipError ? <div className={priceError()}>{customTipError}</div> : null}
                </div>
                <div className={buttonWrapper()}>
                  {fields?.addtip && (
                    <ButtonHelper
                      onClickHandler={(e) => {
                        e?.preventDefault();
                        if (!customTipError) {
                          const numValue = parseFloat(customTipSaved);
                          numValue < 0 ? (setTip(0), setCustomTipSaved('0.00')) : setTip(numValue);
                          numValue <= 0 && setIsCustomTip(false);
                          setShowCustomTip(false);
                        }
                      }}
                      field={fields?.addtip}
                      size={'default'}
                      isLinkfield={false}
                      variant={customTipError?.length > 0 ? 'disabled' : 'primary'}
                      className={tipButton()}
                    />
                  )}
                </div>
              </form>
            ) : null}

            <div id="delvey-tipping-footer" className={bottomWrapper()}>
              <TextHelper field={fields?.deliveryNote} tag="p" />
              {hasAutoShip ? (
                <div
                  onClick={() => {
                    setTipIsRecurring(!isAutoshipTipChecked);
                  }}
                  className={autoshipTip()}
                >
                  <input
                    aria-label="autoshipTipCheckbox"
                    type="checkbox"
                    checked={isAutoshipTipChecked}
                    className={autoshipTipCheckbox()}
                    name="autoshipTipCheckbox"
                  />
                  <TextHelper field={fields?.autoshipNote} tag="p" />
                  {isAutoshipTipChecked && (
                    <IconHelper className={autoshipTipCheckboxIcon()} icon={'icon-checkmark'} />
                  )}
                </div>
              ) : null}
              <TextHelper field={fields?.sucessMessage} tag="p" />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
export default AddATip;
