import { Field, FormikValues, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import FieldWrapper from './FieldWrapper';
import { tv } from 'tailwind-variants';
import { FieldValues } from 'components/Registration/RegistrationForm/RegistrationForm';

type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  subLabel?: string;
  name?: string;
  matchValue?: string;
  matchKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue?: (val: FieldValues | any) => void;
  requiredvalidationmessage?: string;
  validationpattern?: string;
  hasFullWidth?: boolean;
  placeholdertext?: string;
  characterlimit?: number;
  fieldtype?: string;
  validationerrormessage?: string;
  togglePasswordVisibility?: () => void;
  showPassword?: boolean;
  onBlurFunction?: (e: React.FocusEvent<HTMLInputElement>) => void;
  showRed?: boolean;
  customErrorMessage?: string;
  hasCustomBlur?: boolean;
  mincharacterlimit?: string;
  mincharacterlimitvalidationmessage?: string;
  autoComplete?: string;
  preventAutofill?: boolean;
};

const FieldsVariant = tv(
  {
    slots: {
      fields: [
        'outline-none',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        'focus:border-color-brand-primary-1-base',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
        'appearance-none',
        'bg-color-scale-1-white',
      ],
      showField: [
        'border-l-2',
        'border-t-0',
        'border-b-0',
        'outline-0',
        'border-color-border-mid',
        'absolute',
        'right-[2px]',
        'h-[48px]',
        'w-[65px]',
        'flex',
        'text-color-scale-7-mid-dark',
        'justify-center',
        'items-center',
        'cursor-pointer',
        'px-desk-global-spacing-spacing-3',
        'py-desk-padding-micro-y',
        'hover:bg-color-scale-3-light-mid',
        'focus:bg-color-scale-3-light-mid',
        'hover:no-underline',
      ],
    },
    variants: {
      device: {
        mobile: {
          showField: ['top-[2px]'],
          fields: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
        },
        desktop: {
          showField: ['top-[2px]'],
          fields: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
        },
      },
      hasErrorStyle: {
        true: {
          fields: ['border-system-red'],
        },
        false: {
          fields: ['border-color-border-mid'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const {
    id,
    name,
    placeholdertext,
    requiredvalidationmessage,
    required,
    characterlimit,
    validationpattern,
    fieldtype,
    validationerrormessage,
    disabled,
    showPassword,
    togglePasswordVisibility,
    onBlurFunction,
    showRed,
    customErrorMessage,
    autoComplete,
    setFieldValue,
    hasCustomBlur,
    mincharacterlimit,
    mincharacterlimitvalidationmessage,
    matchKey,
    preventAutofill,
  } = props;
  const { errors, touched, values, setFieldValue: setFields } = useFormikContext<FormikValues>();
  const val = name && values[`${matchKey || 'Password'}`];
  const fieldValidate = (value: string) => {
    let error;
    if (required && !value) {
      error = requiredvalidationmessage || 'This field is required.'; // TODO: - Static message callback can be take from dictionary item.
    } else if (mincharacterlimit && value.trim().length < Number(mincharacterlimit)) {
      error = mincharacterlimitvalidationmessage;
    } else if (validationpattern && value.trim().length) {
      const regex = new RegExp(validationpattern);
      if (!regex.test(value)) {
        error = validationerrormessage;
      }
    }
    if (name && (name === 'ConfirmPassword' || name === 'ReEnterNewPassword')) {
      if (value && val !== value) {
        error = customErrorMessage;
      }
    }
    return error;
  };
  let hasError = name && touched[name] && errors[name] ? true : false;
  if (showRed) {
    hasError = true;
  }
  const { fields, showField } = FieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setFieldValue && setFieldValue(values);
  }, [values]);
  useEffect(() => {
    if (fieldtype == 'password') {
      setShowButton(true);
    }
  }, [fieldtype]);

  const isAutofill = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isAutofillTriggered = (event as any).nativeEvent.isComposing === false;
    return isAutofillTriggered;
  };

  return (
    <>
      <FieldWrapper hasError={hasError} hasStackedHelper={showButton} {...props}>
        {!hasCustomBlur ? (
          <div className="input-group relative">
            <Field
              className={fields()}
              id={id}
              name={name}
              aria-label={name}
              placeholder={placeholdertext}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (preventAutofill) {
                  if (isAutofill(event)) {
                    setFields(name as string, event.target.value); // Only set value if not autofill
                  }
                } else {
                  setFields(name as string, event.target.value); // Only set value if not autofill
                }
              }}
              validate={fieldValidate}
              type={fieldtype || 'text'}
              data-characterlimit={characterlimit}
              maxLength={characterlimit} // TODO: need to discuss the fallback value.
              disabled={disabled}
              autoComplete={autoComplete ?? 'on'}
            />
            {showButton && (
              <a
                aria-label="show password"
                tabIndex={0}
                className={showField()}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? 'Hide' : 'Show'}
              </a>
            )}
          </div>
        ) : (
          <Field
            className={fields()}
            id={id}
            name={name}
            aria-label={name}
            placeholder={placeholdertext}
            validate={fieldValidate}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (isAutofill(event)) {
                setFields(name as string, event.target.value); // Only set value if not autofill
              }
            }}
            type={fieldtype || 'text'}
            data-characterlimit={characterlimit}
            maxLength={characterlimit} // TODO: need to discuss the fallback value.
            disabled={disabled}
            onBlur={onBlurFunction}
            autoComplete={autoComplete ?? 'on'}
          />
        )}

        {showRed && <p className="text-system-red">{customErrorMessage}</p>}
      </FieldWrapper>
    </>
  );
};
export default TextField;
