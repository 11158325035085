import {
  Field,
  ImageField,
  LinkField,
  NextImage,
  RichText,
  Text,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import LinkHelper from 'components/helpers/LinkHelper';
import { ComponentProps } from 'lib/component-props';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';

export const FavouriteFriendsVariants = tv(
  {
    slots: {
      slider: 'w-full',
      base: 'bg-color-background-light',
      container: 'container',
      sliderContainer:
        'flex flex-col items-center [&_.splide]:w-full [&_.slider-arrow]:rounded-[23px] [&_.slider-arrow]:top-[44px] [&_.slider-arrow]:transform-none [&_.slider-arrow_svg]:w-3 [&_.slider-next]:-right-[35px] [&_.slider-prev]:-left-[35px] [&_.slider-arrow_svg]:h-3 [&_.slider-arrow]:max-w-6 [&_.slider-arrow]:max-h-6 [&_.slider-arrow]:border-2 [&_.slider-arrow]:border-color-background-dark [&_.slider-arrow]:border-solid [&_.slider-arrow]:bg-color-background-light [&_.slider-arrow]:!opacity-100 [&_.is-active.is-visible_.slide]:border-b-[4px] [&_.is-active.is-visible_.slide]:border-b-color-brand-primary-1-base [&_.is-active.is-visible_.slide]:pb-2',
      sliderTitle: 'rte w-full font-primary text-center',
      slideContainer:
        'cursor-pointer slide w-full flex flex-col items-center [&_img]:w-[120px] [&_img]:h-[120px]',
    },
    variants: {
      size: {
        desktop: {
          slider: 'px-0 ',
          sliderContainer: 'py-desk-padding-tight-y gap-desk-margin-loose-bottom',
          sliderTitle:
            'p-0 text-heading-desk-xLarge-medium font-heading-desk-xLarge-medium leading-heading-desk-xLarge-medium',
          slideContainer: 'max-w-[197px] pb-desk-margin-tight-bottom gap-desk-margin-tight-bottom',
        },
        mobile: {
          slider: 'px-mob-margin-loose-right',
          sliderContainer:
            'py-mob-padding-tight-y gap-mob-margin-loose-bottom [&_.is-active.is-visible_.slide]:pb-1',
          sliderTitle:
            'text-heading-mob-xLarge-bold font-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
          slideContainer: 'pb-mob-margin-tight-bottom gap-mob-margin-tight-bottom',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
export const ArticleVariants = tv(
  {
    slots: {
      articleBase: '',
      articleContentWrapper: '',
      articleContent: 'flex flex-col',
      articleTitle: 'font-primary',
      articleDescription:
        'font-body-large-regular text-body-large-regular leading-body-large-regular font-primary',
      articleSubHead: 'font-primary',
      articleImage:
        'w-full lg:max-w-[500px] object-fit xl:max-w-desk-component-image-promo-image-2-max-width',
      articleLink:
        'text-color-text-brand-1 lg:hover:no-underline font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
    },
    variants: {
      size: {
        desktop: {
          articleImage: 'w-auto max-h-desk-component-image-promo-image-2-max-height min-h-[450px]',
          articleContent:
            'px-desk-padding-micro-x gap-desk-margin-tight-bottom pb-desk-margin-micro-bottom',
          articleBase: 'py-desk-padding-tight-y flex-row w-full',
          articleContentWrapper:
            'bg-color-scale-8-dark px-desk-padding-base-x py-desk-padding-tight-y gap-desk-margin-tight-bottom',
          articleSubHead:
            'font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-scale-3-light-mid',
          articleDescription: 'text-color-text-white',
          articleLink:
            'mx-desk-padding-micro-x hover:bg-color-brand-primary-1-dark hover:text-color-text-white  text-color-text-white py-desk-component-button-full-padding-y w-fit px-desk-component-button-full-padding-x rounded-[4px] bg-color-brand-primary-1-base',
          articleTitle:
            'text-color-text-white font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
        },
        mobile: {
          articleBase: 'py-mob-padding-tight-y flex flex-col-reverse',
          articleLink: 'hover:text-color-brand-tertiary-3-base ',
          articleContentWrapper:
            'py-mob-padding-tight-y px-mob-padding-micro-x flex flex-col gap-mob-margin-tight-bottom',
          articleContent: 'gap-mob-margin-tight-bottom pb-mob-margin-tight-bottom',
          articleTitle:
            'text-color-text-dark font-heading-mob-large-bold text-heading-mob-large-bold leading-heading-mob-large-bold',
          articleSubHead:
            'text-color-scale-7-mid-dark font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          articleDescription:
            'font-body-large-regular text-body-large-regular leading-body-large-regular text-color-text-dark',
          articleImage: 'w-full min-h-[240px]',
        },
      },
      isReverse: {
        true: {
          articleBase: 'lg:flex-row-reverse',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
const Article = ({ isReverse, article }: { isReverse?: boolean; article: FeaturedItem }) => {
  const {
    articleBase,
    articleContentWrapper,
    articleContent,
    articleDescription,
    articleTitle,
    articleLink,
    articleImage,
  } = ArticleVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    isReverse: isReverse,
  });
  return (
    <div className={articleBase()}>
      <div className={articleContentWrapper() + ' lg:w-[calc(100%-500px)] xl:w-[calc(100%-650px)]'}>
        <div className={articleContent()}>
          <RichText className={articleTitle()} field={article?.fields?.title} tag="p" />
          <RichText className={articleDescription()} field={article?.fields?.description} tag="p" />
        </div>
        {article?.fields?.link && (
          <LinkHelper className={articleLink()} field={article?.fields?.link} />
        )}
      </div>
      {article?.fields?.image?.value?.src && (
        <NextImage className={articleImage()} field={article?.fields?.image} />
      )}
    </div>
  );
};
export type FavouriteFriendsProps = ComponentProps & {
  fields: {
    friendsList: Friend[];
    heading: {
      value: string;
    };
  };
};
interface Friend {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    image: ImageField;
    isDefaultFriend: {
      value: boolean;
    };
    name: Field<string>;
    featuredList: FeaturedItem[];
    link: LinkField;
  };
}
interface FeaturedItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    image: ImageField;
    subHead?: Field<string>;
    textAligment?: Field<string>;
    description?: Field<string>;
    title?: Field<string>;
    link?: LinkField;
  };
}
const FavouriteFriends = ({ fields, params }: FavouriteFriendsProps) => {
  const { base, sliderContainer, slider, container, sliderTitle, slideContainer } =
    FavouriteFriendsVariants({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });
  const getInitialIndex = () => {
    const selectedData = fields?.friendsList?.find(
      (slide: Friend) => slide?.fields?.isDefaultFriend?.value
    );
    if (selectedData) {
      return fields?.friendsList?.indexOf(selectedData);
    }
    return 0;
  };
  const splideRef = useRef<Splide | null>(null); // Define the ref type
  const router = useRouter();
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(getInitialIndex());

  function onFriendsSlideChange(index: number) {
    splideRef?.current?.splide?.Components.Controller?.setIndex(index);
    splideRef?.current?.splide?.Components?.Move?.move(index, index, 0);
  }
  useEffect(() => {
    onFriendsSlideChange(activeSlideIndex);
  }, [activeSlideIndex]);
  useEffect(() => {
    const hash = router.asPath.split('#')[1];
    if (hash) {
      const selectedSlide = fields?.friendsList.find(
        (slide) => slide?.fields?.name?.value?.toLowerCase() === hash?.toLowerCase()
      );
      if (selectedSlide) {
        setActiveSlideIndex(fields?.friendsList.indexOf(selectedSlide));
        splideRef?.current?.splide?.Components.Controller?.setIndex(
          fields?.friendsList.indexOf(selectedSlide)
        );
        const currentPath = `${router.asPath.split('#')[0]}#${selectedSlide?.fields?.name?.value}`;
        window.history.replaceState({}, '', currentPath); // Update the URL without reloading the page
      }
    }
  }, [router.asPath]);

  if (fields === null || fields === undefined) return <></>;

  return (
    <>
      <div className={`${params?.style ?? ''}  ${base()}`}>
        <div className={container()}>
          <div className={sliderContainer()}>
            <RichText className={sliderTitle()} field={fields?.heading} tag="p" />
            <div className={slider()}>
              <Splide
                ref={splideRef}
                options={{
                  // type: isMobile ? 'loop' : 'slide',
                  arrows: false,
                  pagination: false,
                  perMove: 1,
                  gap: '20px',
                  perPage: 6,
                  breakpoints: {
                    1024: {
                      perPage: 6,
                      type: 'slide',
                    },
                    1023: {
                      arrows: true,
                      perPage: 2,
                      gap: '8px',
                      type: 'loop',
                    },
                  },
                  classes: {
                    prev: 'splide__arrow--prev slider-arrow slider-prev',
                    next: 'splide__arrow--next slider-arrow slider-next',
                  },
                }}
                onMoved={(_slide, currentIndex) => {
                  setActiveSlideIndex(currentIndex);
                  const currentPath = `${router.asPath.split('#')[0]}#${
                    fields?.friendsList[currentIndex]?.fields?.name?.value
                  }`;
                  window.history.replaceState({}, '', currentPath); // Update the URL without reloading the page
                }}
                onClick={(_slide, e) => {
                  onFriendsSlideChange(e.index);
                }}
              >
                {fields?.friendsList?.map((slide, index) => {
                  return (
                    <SplideSlide key={index}>
                      <div className={slideContainer()}>
                        <NextImage field={slide?.fields?.image} />
                        <Text tag="p" field={slide?.fields?.name} />
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          </div>
        </div>
      </div>
      <div className={container()}>
        {fields?.friendsList[activeSlideIndex]?.fields?.featuredList?.map((article, index) => {
          return (
            <Article
              key={index}
              isReverse={article?.fields?.textAligment?.value?.toLowerCase() === 'right'}
              article={article}
            />
          );
        })}
      </div>
    </>
  );
};

export default FavouriteFriends;
