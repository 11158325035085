import React, { useState } from 'react';
import { BaseItemNode, TreeViewer } from './TreeViewer/TreeViewer';
import { PageInfo, getPaginatedDataUtil } from './graphql-utils';

const GetItemsQuery = `
  query GetItem($path: String = "/sitecore", $language: String!, $nextCursor: String) {
    item(path: $path, language: $language) {
      id
      name
      rendered
      children(first: 20, after: $nextCursor) {
        pageInfo {
          hasNext
          endCursor
        }
        results {
          id
          name
          rendered
          children(first: 1) {
            results {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface ItemData {
  item?: {
    id: string;
    name: string;
    rendered?: object;
    children: {
      pageInfo: PageInfo;
      results: {
        id: string;
        name: string;
        rendered?: object;
        children: {
          results: {
            id: string;
            name: string;
          }[];
        };
      }[];
    };
  };
}

export type ItemTreeViewProps = {
  onElementSelected: (item: ItemNode) => void;
  rootNode?: ItemNode;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ItemNode extends BaseItemNode<ItemNode> {}

const ItemTreeView = ({
  onElementSelected,
  rootNode = {
    id: '/sitecore/content/PetSuppliesPlus',
    name: 'PetSuppliesPlus',
    hasLayout: false,
    hasChildren: true,
  },
}: ItemTreeViewProps) => {
  const [selectedItem, setSelectedItem] = useState<ItemNode>();
  const item = { ...rootNode };
  const fetchData = async (item: ItemNode) => {
    item.children = [];
    const addedItemIds = new Set<string>();
    const data = await getPaginatedDataUtil<ItemData>(
      GetItemsQuery,
      {
        path: item.id,
        language: 'en',
      },
      (x) => x.item?.children.pageInfo
    );

    if (data) {
      data.item?.children.results.forEach((x) => {
        if (addedItemIds.has(x.id)) {
          return;
        }
        addedItemIds.add(x.id);
        item.children?.push({
          id: x.id,
          name: x.name,
          hasLayout: !!x.rendered,
          hasChildren: x.children.results.length > 0,
        });
      });
    }

    return item.children;
  };

  return (
    <TreeViewer<ItemNode>
      item={item}
      onItemSelected={(item) => {
        setSelectedItem(item);
        onElementSelected(item);
      }}
      isSelected={(item) => selectedItem?.id === item.id}
      fetchData={fetchData}
    />
  );
};

export default ItemTreeView;
