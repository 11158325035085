/* eslint-disable */
import ComponentContext from 'lib/context/ComponentContext';
import { useTheme } from 'lib/context/ThemeContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useOcSelector } from 'src/redux/ocStore';
// Define the type for BV object
interface BVObject {
  reviews: {
    on: (event: string, callback: () => void) => void;
  };
}
const BazarVoiceReviews = ({ bvProductId }: { bvProductId: string }) => {
  const { themeNameUpper } = useTheme();
  const ocUserDetails = useOcSelector((s: any) => s?.ocUser);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const { componentContextData } = useContext(ComponentContext);
  const [loadScriptCheck, setLoadScriptCheck] = useState(false);
  const loadBvScript = () => {
    // Check if the bvCallback script has already been initialized
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(window as any).bvCallbackInitialized) {
      // Define the bvCallback function
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).bvCallback = function (BV: BVObject) {
        if (BV && BV.reviews) {
          BV.reviews.on('show', function () {
            const el = document.getElementById('el-pReviews');
            if (el) {
              el.click();
              window.scrollTo({
                top: el.offsetTop,
                behavior: 'smooth',
              });
            } else {
              console.error('Element with id "el-pReviews" not found.');
            }
          });
        } else {
          console.error('BV or BV.reviews is not defined.');
        }
      };
      // Set the flag to indicate the bvCallback script has been initialized
      (window as any).bvCallbackInitialized = true;
      // Create and append the script
      const script = document.createElement('script');
      script.src =
        themeNameUpper === 'PSP'
          ? process.env.NEXT_PUBLIC_BAZAAR_VOICE_URL || ''
          : process.env.NEXT_PUBLIC_WNW_BAZAAR_VOICE_URL || '';

      script.async = true;
      script.onload = () => {
        // Manually trigger the bvCallback if BV is already available
        if ((window as any).BV) {
          (window as any).bvCallback((window as any).BV);
        }
      };
      script.onerror = () => {
        console.error('Error loading Bazaarvoice script.');
      };
      // Append the script to the document body
      document.body.appendChild(script);
      // Cleanup function to remove the script when the component is unmounted
      return () => {
        document.body.removeChild(script);
      };
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (!ocUserDetails?.loading && ocUserDetails?.user?.Email) {
      if (!isAnonymous && componentContextData?.bvToken) {
        loadBvScript();
        setLoadScriptCheck(true);
      } else if (isAnonymous) {
        loadBvScript();
        setLoadScriptCheck(true);
      }
    }
  }, [componentContextData?.bvToken, bvProductId, ocUserDetails?.loading, ocUserDetails?.user]);
  return (
    <>
      {loadScriptCheck && (
        <>
          <div
            data-bv-show="rating_summary"
            data-bv-product-id={bvProductId}
            id="ratingSummary"
            className="ratingSummary"
          />
        </>
      )}
    </>
  );
};
export default BazarVoiceReviews;
