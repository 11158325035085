// React imports and component dependencies
import React, { useEffect, useState } from 'react';
import { Text, RichText, Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { tv } from 'tailwind-variants';
import LinkHelper from 'components/helpers/LinkHelper';
import ImageHelper from 'components/helpers/ImageHelper';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import IconHelper from 'components/helpers/IconHelper';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useTheme } from 'lib/context/ThemeContext';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcSelector } from 'src/redux/ocStore';
import { getPromotionObject, trackObjectForPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'components/helpers/Constants';
import { useBreakpoints } from 'src/utils/breakpoints';
// Define the properties of a Card in the list
export type CardListProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.DoubleCallout.Fields.DoubleCallout;
export type DoubleCalloutProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.DoubleCallout.Fields.DoubleCalloutList;
export type DoubleCalloutData = DoubleCalloutProps & {
  isPrimary?: boolean;
};
// Define the properties of the CalloutRow component
interface CalloutRowProps {
  fields: {
    heading?: Field<string>;
    subHeading?: Field<string>;
    headingLink?: LinkField;
    cardList?: CardListProps[];
  };
  isPrimary?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotionObject?: any;
  storeId?: string;
  componentName?: string;
}
// Define the properties of the CalloutBlock component
interface CalloutBlockProps {
  data: CardListProps;
  isPrimary?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotionObject?: any;
  storeId?: string;
  componentName?: string;
}
interface WrapperContent {
  isPrimary: boolean;
  linkField?: LinkField;
  content: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotionObject?: any;
  storeId?: string;
  componentName?: string;
}
// Define tailwind CSS variants for the components
const calloutBlockVariants = tv({
  slots: {
    base: 'doubleCallout',
    wrapper: 'container mx-auto',
    heading: '',
    headingLink:
      'w-fit block hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    linkIcon:
      'w-4 h-4 [&_svg]:max-w-4 [&_svg]:max-h-4 [&>svg>*]:fill-color-text-brand-1 group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:fill-color-brand-tertiary-3-base',
    buttonWrapper: 'block hover:no-underline   w-fit',
    noButtonWrapper: '',
    colHeading:
      'text-color-scale-8-dark w-fit font-primary font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
    colDescription:
      'font-body-large-regular text-color-scale-8-dark font-primary text-body-large-regular leading-body-large-regular  lg:mb-0',
    colWrapper: 'flex flex-wrap',
    headingWrapper: 'block w-full h-auto  lg:flex-1 ',
    headingWrapperForCol: 'block w-full h-auto lg:flex-1 ',
    contentWrapper:
      'pb-mob-margin-micro-bottom lg:pb-desk-margin-micro-bottom gap-mob-margin-tight-bottom lg:gap-desk-margin-tight-bottom',
    icon: 'flex',
    link: 'flex items-center group font-body-large-bold w-fit font-primary leading-body-large-bold text-body-large-bold text-color-text-brand-1 mt-2 lg:mt-3 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    description: '',
  },
  variants: {
    isPrimary: {
      true: {
        linkIcon: 'ml-desk-space-between-micro-horizontal',
        colWrapper:
          'gap-mob-space-between-base-vertical lg:flex-nowrap lg:gap-desk-global-grid-gutter',
        wrapper: 'px-mob-global-grid-margin py-mob-padding-base-y lg:py-desk-padding-tight-y',
        contentWrapper: 'pb-mob-margin-tight-bottom lg:pb-desk-margin-micro-bottom lg:mt-3',
        heading:
          'font-primary text-color-text-brand-1 font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold mb-mob-margin-tight-bottom lg:mb-desk-margin-tight-bottom',
        headingLink:
          'font-primary text-color-text-brand-1 font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold mb-mob-margin-tight-bottom lg:mb-desk-margin-tight-bottom',
        headingWrapper:
          'lg:w-1/4 lg:min-w-1/4 pb-mob-margin-micro-bottom lg:pb-0 px-mob-padding-micro-x lg:px-desk-padding-micro-x',
        headingWrapperForCol:
          'lg:w-1/4 flex py-mob-padding-micro-y px-mob-padding-micro-x lg:block lg:py-0 gap-mob-margin-base-right lg:gap-0 flex-row lg:flex-col lg:min-w-1/4 px-mob-padding-micro-x lg:px-desk-padding-micro-x',
        icon: ' w-mob-component-image-70-icons-width h-mob-component-image-70-icons-width max-h-mob-component-image-70-icons-width max-w-mob-component-image-70-icons-width mb-mob-margin-tight-bottom lg:w-desk-component-image-70-icons-width lg:h-desk-component-image-70-icons-width lg:max-h-desk-component-image-70-icons-width lg:max-w-desk-component-image-70-icons-width ',
        link: 'flex font-body-large-bold font-primary leading-body-large-bold text-body-large-bold',
        description:
          'font-body-large-regular text-color-scale-9-black font-primary text-body-large-regular leading-body-large-regular',
        colDescription: 'mt-2 lg:mt-3',
      },
      false: {
        colHeading:
          'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold lg:font-heading-desk-large-bold lg:text-heading-desk-large-bold lg:leading-heading-desk-large-bold',
        linkIcon: 'ml-mob-space-between-micro-horizontal',
        colWrapper:
          'gap-mob-margin-tight-bottom lg:flex-nowrap lg:gap-desk-global-grid-gutter lg:h-fit',
        wrapper: 'py-mob-padding-tight-y lg:py-desk-padding-tight-y',
        headingWrapperForCol:
          'flex lg:flex-col lg:border-r-[1px] gap-mob-margin-base-right lg:gap-desk-margin-tight-bottom lg:border-r-color-border-mid lg:border-solid lg:last:border-r-0 items-start lg:w-1/3 lg:min-w-1/3 py-mob-padding-tight-y px-mob-global-grid-margin lg:py-0 lg:px-desk-padding-micro-x',
        icon: 'w-[70px] h-[70px] max-w-[70px] max-h-[70px] lg:w-[110px] lg:h-[110px] lg:max-h-[110px] lg:max-w-[110px]',
        headingWrapper:
          'lg:w-1/3 lg:min-w-1/3 py-mob-padding-tight-y px-mob-global-grid-margin lg:p-0',
        colDescription: 'text-start ',
        contentWrapper:
          'flex flex-col items-start pb-mob-margin-tight-bottom lg:pb-desk-margin-micro-bottom',
      },
    },
    showHeading: {
      true: {
        headingWrapper: 'block',
      },
      false: {
        headingWrapper: 'hidden lg:block',
      },
    },
    showBackgroundColor: {
      true: {
        base: 'bg-color-background-light',
      },
    },
  },
});
// Component for rendering each row in the Callout component
const CalloutRow: React.FC<CalloutRowProps> = ({
  fields,
  isPrimary = false,
  promotionObject,
  storeId,
  componentName,
}) => {
  // Extract relevant variants
  const { heading, headingLink, colWrapper, description, headingWrapper } = calloutBlockVariants({
    isPrimary,
  });
  const isEE = useExperienceEditor();
  return (
    <div className={colWrapper()}>
      {isPrimary && (
        <div
          className={headingWrapper({
            showHeading: !!(fields?.heading?.value || fields?.subHeading?.value),
            className: 'self-center',
          })}
        >
          <>
            {isEE && (
              <>
                <Text field={fields.heading} tag="p" className={heading()} />
                {fields.headingLink && (
                  <LinkHelper field={fields.headingLink} className={headingLink()} />
                )}
              </>
            )}
            {!isEE && (
              <>
                {fields?.heading?.value && fields?.heading?.value != '' ? (
                  <Text field={fields.heading} tag="p" className={heading()} />
                ) : (
                  <>
                    {fields.headingLink && (
                      <LinkHelper field={fields.headingLink} className={headingLink()} />
                    )}
                  </>
                )}
              </>
            )}
          </>
          {fields?.subHeading && (
            <RichText tag="p" field={fields.subHeading} className={description()} />
          )}
        </div>
      )}
      {fields?.cardList &&
        fields?.cardList?.length > 0 &&
        fields.cardList.map((card, index) => (
          <CalloutBlock
            key={index}
            data={card}
            isPrimary={isPrimary}
            promotionObject={promotionObject}
            storeId={storeId}
            componentName={componentName}
          />
        ))}
    </div>
  );
};
// Utility component to wrap content with a link if available
const WrapContentWithLink: React.FC<WrapperContent> = ({
  isPrimary,
  linkField,
  content,
  storeId,
  componentName,
  promotionObject,
}) => {
  const { buttonWrapper, noButtonWrapper } = calloutBlockVariants({
    isPrimary,
  });

  if (linkField?.value?.href) {
    return (
      <LinkHelper
        field={linkField}
        className={buttonWrapper()}
        hideLinkInEE={true}
        onClick={() => {
          if (componentName !== '') {
            trackObjectForPromotion(GTM_EVENT?.selectPromotion, storeId as string, promotionObject);
          }
        }}
      >
        <>{content}</>
      </LinkHelper>
    );
  }
  return <div className={noButtonWrapper()}>{content}</div>;
};
// Component for rendering each block in the Callout component
const CalloutBlock: React.FC<CalloutBlockProps> = ({
  data,
  isPrimary = false,
  promotionObject,
  storeId,
  componentName,
}) => {
  //Experience Editor
  const isEE = useExperienceEditor();
  // Extract relevant variants
  const { contentWrapper, icon, colHeading, colDescription, link, headingWrapperForCol, linkIcon } =
    calloutBlockVariants({
      isPrimary,
    });
  const [linkDetails, setLinkDetails] = useState(data?.fields?.link);
  const storeDetails = useOcSelector((state) => state?.storeReducer?.selectedStore);
  useEffect(() => {
    setLinkDetails(
      data.fields?.link?.value?.text === '{store-phoneNumber}'
        ? {
            value: {
              ...data?.fields?.link?.value,
              text: storeDetails?.phone,
              href: storeDetails?.phone ? `tel:${storeDetails?.phone}` : '',
            },
          }
        : data?.fields?.link
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDetails]);
  return (
    <div className={headingWrapperForCol()}>
      {data?.fields?.icon?.value?.src &&
        WrapContentWithLink({
          isPrimary,
          promotionObject,
          storeId,
          componentName,
          linkField: linkDetails,
          content: <ImageHelper field={data.fields.icon} className={icon()} />,
        })}
      <div>
        <div className={contentWrapper()}>
          {data?.fields?.heading?.value &&
            WrapContentWithLink({
              isPrimary,
              promotionObject,
              storeId,
              componentName,
              linkField: linkDetails,
              content: <Text tag="p" field={data.fields.heading} className={colHeading()} />,
            })}
          {data?.fields?.subHeading && (
            <RichTextHelper
              tag="p"
              field={data.fields.subHeading}
              {...(data.fields.subHeading?.value?.includes('{opening-hours}') && {
                updatedField: {
                  value: data.fields.subHeading?.value?.replace(
                    '{opening-hours}',
                    storeDetails?.OpeningHours || ''
                  ),
                },
              })}
              className={colDescription()}
            />
          )}
        </div>
        <div>
          {linkDetails &&
            linkDetails?.value?.href &&
            linkDetails?.value?.text &&
            data?.fields?.link && (
              <LinkHelper
                field={isEE ? data?.fields?.link : linkDetails}
                className={link()}
                onClick={() => {
                  if (componentName !== '')
                    trackObjectForPromotion(
                      GTM_EVENT?.selectPromotion,
                      storeId as string,
                      promotionObject
                    );
                }}
              >
                <>
                  {!isEE && <span>{linkDetails?.value?.text}</span>}
                  {data.fields?.link?.value?.text != '{store-phoneNumber}' &&
                    data?.fields?.link?.value?.href &&
                    data?.fields?.link?.value?.href?.length > 0 && (
                      <IconHelper className={linkIcon()} icon={'chevron-right'} />
                    )}
                </>
              </LinkHelper>
            )}
        </div>
      </div>
    </div>
  );
};
// Main DoubleCallout component
const DoubleCallout: React.FC<DoubleCalloutData> = ({ fields, params, isPrimary }) => {
  // Extract relevant variants
  const { themeName } = useTheme();
  const { base, wrapper } = calloutBlockVariants({
    isPrimary: isPrimary,
  });
  //Getting current Store ID
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  // Promotion GTM data Object
  const gtmPromotion = getPromotionObject(fields, deviceName);
  //Promotion GTM data push on load
  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);
  if (fields === null || fields === undefined) return <></>;
  return (
    <div
      className={`${params?.Styles ?? ''} ${base({
        showBackgroundColor: isPrimary && themeName === 'psp',
      })}`}
      data-component={'components/DoubleCallout/DoubleCallout'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
    >
      <div className={wrapper()}>
        <CalloutRow
          fields={fields}
          isPrimary={isPrimary}
          componentName={fields?.componentName?.value}
          promotionObject={gtmPromotion}
          storeId={myStoreId}
        />
      </div>
    </div>
  );
};

export const Default = (props: DoubleCalloutProps): JSX.Element => {
  return <DoubleCallout {...props} isPrimary={true} />;
};

export const PreFooterwithCTA = (props: DoubleCalloutProps): JSX.Element => {
  return <DoubleCallout {...props} isPrimary={true} />;
};

export const ContactCards3Up = (props: DoubleCalloutProps): JSX.Element => {
  return <DoubleCallout {...props} isPrimary={false} />;
};
