import { Field, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextHelper from 'components/helpers/RichTextHelper';
import { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import ContentHubImage from './ContentHubImages/ContentHubImage';
import ModalWrapper from 'components/helpers/ModalWrapper';
import ButtonHelper from 'components/helpers/Button';
import { useOcSelector } from 'src/redux/ocStore';
import { formatDateFromString } from 'src/utils/formatDate';
import Loader from 'components/Loader/Loader';
import clsx from 'clsx';

type EarnedOffersProps = {
  noEarnedRewardsMessage: Field<string>;
  earnedRewardsHeading: Field<string>;
  earnedRewardsSubheading: Field<string>;
  earnedRewardsDisclaimerText: Field<string>;
  earnedRewardsTableColumn1Heading: Field<string>;
  earnedRewardsTableColumn2Heading: Field<string>;
  earnedRewardsTableColumn3Heading: Field<string>;
  earnedRewardsTableColumn4Heading: Field<string>;
  earnedRewardsTableColumn5Heading: Field<string>;
  earnedRewardsNoEarnedDateText: Field<string>;
  earnedRewardsNoExpiryDateText: Field<string>;
  couponStatusUnknownMessage: Field<string>;
  earnedRewardsViewMoreLinkText: Field<string>;
  termsAndConditionLabel: Field<string>;
};

type ProductProps = {
  programid: number;
  programName: string;
  programDescription: string;
  termsAndConditions: string;
  dateRewardEarned: string;
  dateRewardExpires: string;
  DateRewardRedeemed: string;
  programRewardStatus: string;
  poolName: string;
  couponCode: string;
  isExpired: false;
  CouponRewardName: string;
};

const availableOffersStyles = tv(
  {
    slots: {
      base: ['flex', 'flex-col', 'gap-desk-padding-micro-bottom'],
      earnedOffersIntro: ['flex', 'flex-col', 'gap-desk-padding-micro-bottom'],
      couponInfoHeading: [
        'row',
        'border-y',
        'border-color-scale-3-light-mid',
        'border-1',
        'py-desk-padding-micro-y',
        'mb-3',
      ],
      couponInfoHeadingRow: ['flex'],
      couponInfoTableColumn1Heading: ['w-1/4', 'font-bold'],
      couponInfoTableColumn2Row: ['flex', 'w-3/4', 'gap-1'],
      couponInfoTableColumn2Heading: ['w-1/6', 'font-bold', 'min-w-[105px]'],
      couponInfoTableColumn3Heading: ['w-1/6', 'font-bold', 'min-w-[105px]'],
      couponInfoTableColumn4Heading: ['w-3/6', 'font-bold'],
      couponInfoTableColumn5Heading: ['w-1/6', 'font-bold', 'min-w-[120px]'],
      couponProductItem: [''],
      couponProductItemProgramName: [''],
      couponProductItemProgramDetails: [''],
      couponProductItemProgramDetailsColumn1: [''],
      couponProductItemProgramDetailsColumn2: [''],
      couponProductItemProgramDetailsColumn3: [''],
      couponProductItemProgramDetailsColumn4: [''],
      productShortDescription: [
        'text-heading-mob-small-regular',
        'font-heading-mob-small-regular',
        'leading-heading-mob-small-regular',
      ],
      loadMoreButtonWrapper: ['flex', 'justify-center'],
      loadMoreButton: [
        'flex',
        'w-[188px]',
        'justify-center',
        '!py-desk-component-button-condensed-padding-y',
        'mt-desk-global-spacing-spacing-5',
      ],
      modalProgramName: [
        'text-heading-mob-small-bold',
        'font-heading-mob-small-bold',
        'leading-heading-mob-small-bold',
      ],
      modalProgramDescription: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      productNoExpiryDateText: [
        'text-color-text-dark',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold py-3',
      ],
      termsAndConditionLabelStyle: [
        'font-bold',
        'text-color-scale-5-mid',
        'mb-desk-margin-base-bottom',
      ],
      termsAndConditionTextStyle: ['text-color-scale-5-mid'],
      rewardProgramName: [
        'reward-program-name',
        'text-color-background-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'mb-3',
        'pr-2',
      ],
    },
    variants: {
      device: {
        mobile: {
          couponProductItem: [
            'block',
            'border-y',
            'border-color-scale-3-light-mid',
            'border-1',
            'py-desk-padding-micro-y',
            'mb-3',
          ],
          couponProductItemProgramName: ['w-full'],
          couponProductItemProgramDetails: ['block'],
        },
        desktop: {
          couponProductItem: ['flex', 'border-0', 'py-0', 'mb-0'],
          couponProductItemProgramName: ['w-1/4'],
          couponProductItemProgramDetails: ['flex', 'w-3/4'],
          couponProductItemProgramDetailsColumn1: ['w-1/6', 'min-w-[105px]'],
          couponProductItemProgramDetailsColumn2: ['w-1/6', 'min-w-[105px]'],
          couponProductItemProgramDetailsColumn3: ['w-3/6', 'min-w-[105px]'],
          couponProductItemProgramDetailsColumn4: ['w-1/6 coupon-status min-w-[120px]'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const {
  base,
  earnedOffersIntro,
  couponInfoHeading,
  couponInfoHeadingRow,
  couponInfoTableColumn1Heading,
  couponInfoTableColumn2Row,
  couponInfoTableColumn2Heading,
  couponInfoTableColumn3Heading,
  couponInfoTableColumn4Heading,
  couponInfoTableColumn5Heading,
  couponProductItem,
  couponProductItemProgramName,
  couponProductItemProgramDetails,
  couponProductItemProgramDetailsColumn1,
  couponProductItemProgramDetailsColumn2,
  couponProductItemProgramDetailsColumn3,
  couponProductItemProgramDetailsColumn4,
  modalProgramName,
  productShortDescription,
  loadMoreButtonWrapper,
  loadMoreButton,
  modalProgramDescription,
  productNoExpiryDateText,
  termsAndConditionLabelStyle,
  termsAndConditionTextStyle,
  rewardProgramName,
} = availableOffersStyles({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const EarnedOffers = (props: EarnedOffersProps) => {
  const {
    noEarnedRewardsMessage,
    earnedRewardsHeading,
    earnedRewardsSubheading,
    earnedRewardsDisclaimerText,
    earnedRewardsTableColumn1Heading,
    earnedRewardsTableColumn2Heading,
    earnedRewardsTableColumn3Heading,
    earnedRewardsTableColumn4Heading,
    earnedRewardsTableColumn5Heading,
    earnedRewardsNoEarnedDateText,
    earnedRewardsNoExpiryDateText,
    couponStatusUnknownMessage,
    earnedRewardsViewMoreLinkText,
    termsAndConditionLabel,
  } = props;

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState('');
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const size = 12;
  const program = 'Products';
  const errorMessage = 'An error occured, please try again later.';
  const [isMobileScreenView, setIsMobileScreenView] = useState(window.innerWidth < 1024);

  // todo: Remove the "any" when the "PPC" is added to the types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ppcNumber = useOcSelector((state: any) => state?.ocUser?.user?.xp?.PPC);

  const handleModalClick = (index: number) => {
    setOpenModalIndex(index);
  };

  const handleModalClose = () => {
    setOpenModalIndex(null);
  };

  useEffect(() => {
    fetchProducts(size, page, program);
  }, [page]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreenView(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchProducts = async (size: number, page: number, program: string) => {
    try {
      if (ppcNumber) {
        const response = await fetch(
          `/api/loyalty/get-earned-rewards?cardnumber=${ppcNumber}&size=${size}&page=${page}&program=${program}`
        );
        if (!response.ok && response.status !== 404) {
          throw new Error('Error. The API response was not ok.');
        }
        const data = await response.json();
        setProducts(data?.content);
        setTotalProducts(data?.totalCount);
      }
    } catch (error) {
      setIsError(`Error. An error occured in the API response. ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  if (loading && products?.length === 0) {
    return <Loader />;
  }

  return (
    <div className={base()}>
      {!isError ? (
        <>
          {products && products?.length > 0 ? (
            <>
              <div className={earnedOffersIntro()}>
                {earnedRewardsHeading && earnedRewardsHeading?.value && (
                  <RichTextHelper field={earnedRewardsHeading} />
                )}
                {earnedRewardsSubheading && earnedRewardsSubheading?.value && (
                  <RichTextHelper field={earnedRewardsSubheading} />
                )}
                {earnedRewardsDisclaimerText && earnedRewardsDisclaimerText?.value && (
                  <RichTextHelper className="italic" field={earnedRewardsDisclaimerText} />
                )}
              </div>
              {products && products?.length > 0 && !isMobileScreenView && (
                <div className={couponInfoHeading()}>
                  <div className={couponInfoHeadingRow()}>
                    <div className={couponInfoTableColumn1Heading()}>
                      {earnedRewardsTableColumn1Heading &&
                        earnedRewardsTableColumn1Heading?.value && (
                          <RichTextHelper field={earnedRewardsTableColumn1Heading} />
                        )}
                    </div>
                    <div className={couponInfoTableColumn2Row()}>
                      <div className={couponInfoTableColumn2Heading()}>
                        {earnedRewardsTableColumn2Heading &&
                          earnedRewardsTableColumn2Heading?.value && (
                            <RichTextHelper field={earnedRewardsTableColumn2Heading} />
                          )}
                      </div>
                      <div className={couponInfoTableColumn3Heading()}>
                        {earnedRewardsTableColumn3Heading &&
                          earnedRewardsTableColumn3Heading?.value && (
                            <RichTextHelper field={earnedRewardsTableColumn3Heading} />
                          )}
                      </div>
                      <div className={couponInfoTableColumn4Heading()}>
                        {earnedRewardsTableColumn4Heading &&
                          earnedRewardsTableColumn4Heading?.value && (
                            <RichTextHelper field={earnedRewardsTableColumn4Heading} />
                          )}
                      </div>
                      <div className={couponInfoTableColumn5Heading()}>
                        {earnedRewardsTableColumn5Heading &&
                          earnedRewardsTableColumn5Heading?.value && (
                            <RichTextHelper field={earnedRewardsTableColumn5Heading} />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {products &&
                products?.length > 0 &&
                products.map((product: ProductProps, index: number) => (
                  <div className={couponProductItem()} key={index}>
                    <div className={couponProductItemProgramName()}>
                      <a
                        aria-label="program name"
                        className={rewardProgramName()}
                        onClick={() => handleModalClick(index)}
                      >
                        {product?.programName}
                      </a>
                      <div className="w-[150px]">
                        <ContentHubImage programid={product?.programid} />
                      </div>
                    </div>
                    <div className={couponProductItemProgramDetails()}>
                      <div className={couponProductItemProgramDetailsColumn1()}>
                        {product?.dateRewardEarned ? (
                          <>
                            {!isMobileScreenView ? (
                              <p className="break-words">
                                {formatDateFromString(
                                  new Date(product?.dateRewardEarned as string)
                                )}
                              </p>
                            ) : (
                              <div className="flex">
                                <div className="flex-1">
                                  {earnedRewardsTableColumn2Heading &&
                                    earnedRewardsTableColumn2Heading?.value && (
                                      <RichTextHelper
                                        className="font-bold"
                                        field={earnedRewardsTableColumn2Heading}
                                      />
                                    )}
                                </div>
                                <div className="flex-1">
                                  <p className="break-words">
                                    {formatDateFromString(
                                      new Date(product?.dateRewardEarned as string)
                                    )}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div>
                            {earnedRewardsNoEarnedDateText &&
                              earnedRewardsNoEarnedDateText?.value && (
                                <RichTextHelper
                                  className="break-words"
                                  field={earnedRewardsNoEarnedDateText}
                                />
                              )}
                          </div>
                        )}
                      </div>
                      <div className={couponProductItemProgramDetailsColumn2()}>
                        {product?.dateRewardExpires ? (
                          <>
                            {!isMobileScreenView ? (
                              <p
                                className={clsx(
                                  'break-words',
                                  product?.isExpired && 'text-color-global-outof-stock'
                                )}
                              >
                                {formatDateFromString(
                                  new Date(product?.dateRewardExpires as string)
                                )}
                              </p>
                            ) : (
                              <div className="flex my-4">
                                <div className="flex-1">
                                  {earnedRewardsTableColumn3Heading &&
                                    earnedRewardsTableColumn3Heading?.value && (
                                      <RichTextHelper
                                        className="font-bold"
                                        field={earnedRewardsTableColumn3Heading}
                                      />
                                    )}
                                </div>
                                <div className="flex-1">
                                  <p
                                    className={clsx(
                                      'break-words',
                                      product?.isExpired && 'text-color-global-outof-stock'
                                    )}
                                  >
                                    {formatDateFromString(
                                      new Date(product?.dateRewardExpires as string)
                                    )}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {!isMobileScreenView ? (
                              <div>
                                <RichTextHelper
                                  className="break-words"
                                  field={earnedRewardsNoExpiryDateText}
                                />
                              </div>
                            ) : (
                              <div className="flex my-4">
                                <div className="flex-1">
                                  {earnedRewardsTableColumn3Heading &&
                                    earnedRewardsTableColumn3Heading?.value && (
                                      <RichTextHelper
                                        className="font-bold"
                                        field={earnedRewardsTableColumn3Heading}
                                      />
                                    )}
                                </div>
                                <div className="flex-1">
                                  <RichTextHelper
                                    className="break-words"
                                    field={earnedRewardsNoExpiryDateText}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className={couponProductItemProgramDetailsColumn3()}>
                        {!isMobileScreenView ? (
                          <NextImage
                            field={{
                              value: {
                                src: `https://petsuppliesplusemail.com/bca/z/image?ct=${product?.couponCode}&amp;cc=T&amp;bc={0}&amp;st={1}`,
                                alt: product?.programName,
                                width: '225',
                                height: '44',
                              },
                            }}
                          />
                        ) : (
                          <div className="flex my-4">
                            <div className="flex-1">
                              {earnedRewardsTableColumn4Heading &&
                                earnedRewardsTableColumn4Heading?.value && (
                                  <RichTextHelper
                                    className="font-bold"
                                    field={earnedRewardsTableColumn4Heading}
                                  />
                                )}
                            </div>
                            <div className="flex-1">
                              <NextImage
                                className="ml-[-8px]"
                                field={{
                                  value: {
                                    src: `https://petsuppliesplusemail.com/bca/z/image?ct=${product?.couponCode}&amp;cc=T&amp;bc={0}&amp;st={1}`,
                                    alt: product?.programName,
                                    width: '225',
                                    height: '44',
                                  },
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={couponProductItemProgramDetailsColumn4()}>
                        {product?.programRewardStatus ? (
                          <>
                            {!isMobileScreenView ? (
                              <>
                                {product?.programRewardStatus === 'Expired' && (
                                  <p className="text-color-global-outof-stock">
                                    {product?.programRewardStatus}
                                  </p>
                                )}
                                {product?.programRewardStatus === 'Redeemed' && (
                                  <p className="text-color-global-low-stock">
                                    {product?.programRewardStatus} <br />
                                    {formatDateFromString(
                                      new Date(product?.DateRewardRedeemed as string)
                                    )}
                                  </p>
                                )}
                                {product?.programRewardStatus === 'Available' && (
                                  <p className="text-color-brand-primary-1-base">
                                    {product?.programRewardStatus}
                                  </p>
                                )}
                              </>
                            ) : (
                              <div className="flex my-4">
                                <div className="flex-1">
                                  {earnedRewardsTableColumn5Heading &&
                                    earnedRewardsTableColumn5Heading?.value && (
                                      <RichTextHelper
                                        className="font-bold"
                                        field={earnedRewardsTableColumn5Heading}
                                      />
                                    )}
                                </div>
                                <div className="flex-1">
                                  {product?.programRewardStatus === 'Expired' && (
                                    <p className="text-color-global-outof-stock">
                                      {product?.programRewardStatus}
                                    </p>
                                  )}
                                  {product?.programRewardStatus === 'Redeemed' && (
                                    <p className="text-color-global-low-stock">
                                      {product?.programRewardStatus} <br />
                                      {formatDateFromString(
                                        new Date(product?.DateRewardRedeemed as string)
                                      )}
                                    </p>
                                  )}
                                  {product?.programRewardStatus === 'Available' && (
                                    <p className="text-color-brand-primary-1-base">
                                      {product?.programRewardStatus}
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {couponStatusUnknownMessage && couponStatusUnknownMessage?.value && (
                              <RichTextHelper field={couponStatusUnknownMessage} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {openModalIndex === index && (
                      <ModalWrapper
                        showCloseButtonInModalHeader={false}
                        closeModalOnOverlayClick={true}
                        onCloseClick={handleModalClose}
                        showModal={true}
                        customPopup={true}
                        popupWidth="max-w-[500px]"
                        popupSpacing="p-[15px]"
                      >
                        <div className="">
                          {product && product?.programid && (
                            <ContentHubImage programid={product?.programid} />
                          )}
                        </div>
                        <div className="my-1">
                          <h3 className={modalProgramName()}>{product?.programName}</h3>
                        </div>
                        <div className={modalProgramDescription()}>
                          {product?.programDescription}
                        </div>
                        {product?.dateRewardExpires ? (
                          <div className={productShortDescription()}>
                            {formatDateFromString(new Date(product?.dateRewardExpires as string))}
                          </div>
                        ) : (
                          <div className={productNoExpiryDateText()}>
                            {earnedRewardsNoExpiryDateText &&
                              earnedRewardsNoExpiryDateText?.value && (
                                <RichTextHelper field={earnedRewardsNoExpiryDateText} />
                              )}
                          </div>
                        )}
                        {product?.termsAndConditions && product?.termsAndConditions?.length > 0 && (
                          <div className="border-y border-color-scale-3-light-mid border-1 py-desk-padding-micro-y mb-3">
                            <div className={termsAndConditionLabelStyle()}>
                              {termsAndConditionLabel && termsAndConditionLabel?.value && (
                                <RichTextHelper field={termsAndConditionLabel} />
                              )}
                            </div>
                            <div className={termsAndConditionTextStyle()}>
                              {product?.termsAndConditions}
                            </div>
                          </div>
                        )}
                      </ModalWrapper>
                    )}
                  </div>
                ))}
              {products &&
                products?.length > 0 &&
                earnedRewardsDisclaimerText &&
                earnedRewardsDisclaimerText?.value && (
                  <RichTextHelper className="italic mt-3" field={earnedRewardsDisclaimerText} />
                )}

              <div>
                {products?.length > size &&
                  products?.length <= totalProducts &&
                  earnedRewardsViewMoreLinkText &&
                  earnedRewardsViewMoreLinkText?.value && (
                    <div className={loadMoreButtonWrapper()}>
                      <ButtonHelper
                        isLinkfield={false}
                        size="small"
                        variant={'primary'}
                        className={loadMoreButton()}
                        field={earnedRewardsViewMoreLinkText}
                        onClickHandler={() => loadMore()}
                      />
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-desk-padding-micro-bottom">
              {noEarnedRewardsMessage && noEarnedRewardsMessage?.value && (
                <RichTextHelper className="italic mt-3" field={noEarnedRewardsMessage} />
              )}
              {earnedRewardsDisclaimerText && earnedRewardsDisclaimerText?.value && (
                <RichTextHelper field={earnedRewardsDisclaimerText} />
              )}
            </div>
          )}
        </>
      ) : (
        <>{errorMessage}</>
      )}
    </div>
  );
};

export default EarnedOffers;
