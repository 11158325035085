// Global
import React, { useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { Data as FooterStaticDataProps } from 'lib/types/footer';
import footerQuery from 'lib/footer.graphql';

// Local
import FooterNav from './FooterNav';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { ComponentProps } from 'lib/component-props';

// Types
export type FooterProps = ComponentProps & {
  footerData: FooterStaticDataProps;
};

/*
 * Tailwind Variants
 */

export const footerVariants = tv(
  {
    slots: {
      base: [
        'bg-color-background-dark',
        'font-primary',
        'py-mob-padding-tight-y',
        'pb-[88px]',
        'lg:flex',
        'lg:py-desk-padding-tight-y',
        'w-full',
      ],
      divWrapper: ['container', 'lg:flex', 'lg:justify-center'],
      columnWrapper: [
        'text-color-text-white',
        'w-full',
        'lg:border-b-0',
        'lg:mr-desk-margin-loose-right',
        'lg:p-0',
        'lg:pr-desk-margin-base-right',
        'lg:border-r-[1px]',
      ],
      titleWrapper: ['flex', 'justify-between', 'items-center'],
      iconWrapper: ['justify-self-end', 'lg:hidden'],
      subNavWrapper: [
        'overflow-hidden',
        'transition-all',
        'duration-500',
        'ease-linear',
        '[&>li:nth-child(1)]:mt-mob-margin-base-bottom',
        'lg:transition-none',
        'lg:[&>li:nth-child(1)]:mt-0',
        'h-fit',
        '[&>li:nth-last-child(1)]:mr-0',
      ],
      soicialLi: ['mb-desk-margin-loose-bottom', '!mt-desk-margin-tight-bottom', 'lg:!mt-0'],
      headingWrapper: [
        'text-body-xSmall-regular',
        'font-body-xSmall-bold',
        'hover:no-underline',
        'pointer-events-none',
        'lg:mb-desk-margin-base-bottom',
        'lg:pointer-events-auto',
      ],
      logoWrapper: ['hidden', 'lg:block', 'mb-desk-margin-tight-bottom'],
      linkWrapper: [
        'text-body-xSmall-regular',
        'leading-body-xSmall-regular',
        'pb-desk-margin-micro-bottom',
        'before:!content-[""]',
        'after:!content-[""]',
        'hover:no-underline',
        'transition-colors',
        'duration-75',
      ],
      arrowIcon: ['flex', 'w-4', 'transition-all', 'duration-500'],
    },
    variants: {
      theme: {
        psp: {
          columnWrapper: ['border-color-scale-7-mid-dark'],
          linkWrapper: ['hover:text-color-brand-primary-1-base'],
        },
        wnw: { columnWrapper: ['border-color-scale-5-mid'] },
      },
      device: {
        desktop: {
          base: ['flex', 'pb-desk-padding-base-bottom', 'py-desk-padding-tight-y'],
          divWrapper: ['container', 'flex'],
          columnWrapper: [
            'border-b-0',
            'mr-desk-margin-loose-right',
            'p-0',
            'pr-desk-margin-base-right',
            'border-r-[1px]',
          ],
          iconWrapper: ['hidden'],
          subNavWrapper: ['transition-none', '[&>li:nth-child(1)]:mt-0 h-fit'],
          headingWrapper: ['mb-desk-margin-base-bottom', 'pointer-events-auto'],
        },
        mobile: {
          base: ['py-mob-padding-tight-y', 'pb-mob-padding-base-bottom'],
          columnWrapper: ['py-mob-padding-tight-y', 'px-mob-global-grid-margin', 'border-b-[1px]'],
          titleWrapper: ['hover:cursor-pointer'],
          subNavWrapper: [
            'transition-all',
            'duration-500',
            'ease-linear',
            '[&>li:nth-child(1)]:mt-mob-margin-base-bottom',
            'h-0',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);

export default function Footer({ footerData }: FooterProps) {
  const [isToggled, setisToggled] = useState(-1);
  // const { base, divWrapper } = footerVariants({ device: { initial: 'mobile', lg: 'desktop' } });
  const { base, divWrapper } = footerVariants();

  /*
   * Rendering
   */

  if (!footerData) {
    return <></>;
  }

  return (
    <div className={base()} id="footer">
      <div className={divWrapper()}>
        {footerData?.datasource?.list?.targetItems.map((links, index) => {
          return (
            <FooterNav
              navitems={links?.subLink}
              data={footerData}
              index={index}
              link={links?.link}
              linkTitle={links?.linkTitle}
              key={index}
              isToggled={isToggled}
              setisToggled={setisToggled}
            />
          );
        })}
      </div>
    </div>
  );
}

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphqlClientFactory({});
  const result = await graphQLClient.request<FooterStaticDataProps>(footerQuery, {
    datasource: rendering.dataSource,
    params: rendering.params,
    language: layoutData?.sitecore?.context?.language,
    itemID: layoutData?.sitecore?.route?.itemId,
  });

  return { footerData: result };
};
