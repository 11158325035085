import { tv } from 'tailwind-variants';
const ProductCardTailwindVariant = tv(
  {
    slots: {
      plpWrapper: ['bg-color-scale-2-light'],
      sitecoreProductRoot: [
        'productTile',
        // 'cursor-pointer',
        'border-[1px]',
        'border-color-border-mid',
        'bg-color-background-white',
        'justify-between',
        'overflow-hidden',
        // 'w-fit',
        // 'block border-solid border-grey-400 shadow-md w-48 px-4 py-4 cursor-pointer',
      ],
      sitecoreMarketingTile: ['marketingTile'],
      resultsWrapper: [
        'grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4',
        // 'sm:gap-[14px] md:gap-[24px]',
        'flex flex-row flex-wrap',
        'gap-[24px]',
        // 'flex',
        // 'flex-wrap',
        // 'gap-[24px]',
        // 'flex flex-wrap sm:flex-nowrap md:flex-wrap md:flex-nowrap lg:flex-wrap',
        // 'flex-grow-4 flex-shrink',
      ],
      //   sitecoreRightSection: ['flex flex-col flex-grow-4 flex-shrink'],
      sitecoreRightSection: [
        'flex flex-col',
        'md:max-w-[99%]',
        // 'lg:max-w-[72%]',
        // 'lg:max-w-[80%]',
        'lg:max-w-[77%]',
        // 'xl:max-w-[74%]',
        // 'xl:max-w-[994px]',
        'gap-mob-margin-base-bottom',
      ],
      sitecoreLeftSection: ['sitecoreLeftSection', 'relative'],
      sitecoreResultsWrapper: [
        'flex',
        'w-full',
        'justify-between',
        // 'sm:gap-[14px] md:gap-[24px] lg:gap-[48px]',
        'sm:gap-[14px] md:gap-[24px]',
      ],
      productInfoWrapper: ['flex', 'flex-col', 'gap-[24px]'],
      sitecoreProductName: [
        'text-color-text-dark',
        'text-left',
        'cursor-pointer',
        'font-body-large-regular',
        'text-body-large-regular',
        'leading-body-large-regular',
        'inline-block whitespace-normal',
        'overflow-hidden overflow-ellipsis',
      ],
      productLabels: ['flex', 'justify-center'],
      productButtonCTA: [
        'py-mob-component-button-condensed-padding-y',
        'px-mob-component-button-condensed-padding-x',
        'rounded-global-radius-small',
        'border-[2px]',
        'bg-blue-300',
        'text-center',
      ],
      submitBtn: [
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        // 'py-mob-component-button-condensed-padding-y',
        // 'px-mob-component-button-condensed-padding-x',
        'text-center',
        'p-auto',
        'w-full',
        'h-[48px]',
      ],
      listPrice: ['w-full', 'flex', 'flex-row', 'justify-between'],
      autoship: [
        'text-color-brand-autoship',
        'text-center',
        'flex',
        'items-center',
        'justify-center',
        'gap-[4px]',
      ],

      productBottomWrapper: ['flex', 'flex-col'],
      productImage: ['h-auto', 'min-w-[140px]', 'max-w-[140px]'],
      productTopWrapper: ['flex', 'justify-between'],
      queryAndFilter: ['flex'],
      filterTitle: [
        'font-heading-desk-xLarge-bold',
        'text-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
      clearText: ['text-color-brand-tertiary-1-dark', 'hover:underline'],
      selectedFilter: [
        'flex',
        'flex-col',
        'justify-start',
        'gap-mob-space-between-micro-horizontal',
        'text-color-brand-tertiary-1-dark',
      ],
      accordionList: [
        'flex',
        'flex-col',
        'gap-space-between-base-vertical',
        'gap-mob-space-between-base-vertical',
      ],
      accordionItem: ['flex', 'justify-start', 'w-full', 'items-center'],
      facetItemLabel: [
        'text-color-text-dark',
        'text-ellipsis',
        'whitespace-nowrap',
        'w-full',
        'cursor-pointer',
        'hover:text-color-scale-5-mid',
      ],
      facetCount: ['text-[#67768B]'],
      facetCheckbox: [
        'bg-white w-[16px] h-[15px] flex items-center justify-center border border-gray-300 rounded cursor-pointer',
        '[&>*>*>*]:fill-color-brand-primary-1-base',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'mr-desk-margin-tight-right',
        'outline-none',
        'checked:[&~div>label]:text-red-500',
        'rounded-[4px]',
        'focus:border-color-brand-primary-1-base',
      ],
      facetRoot: [
        'flex',
        'flex-col',
        'py-mob-padding-tight-y',
        'px-mob-global-spacing-spacing-0',
        'border-b-[1px]',
        'border-color-border-mid',
        'gap-mob-margin-base-bottom',
      ],
      //sitecore-accordion-value-list
      accordionWrapper: ['flex', 'flex-col', 'gap-mob-space-between-base-vertical', 'item-center'],
      accordionItemWrapper: ['flex', 'w-full', 'justify-between'],
      facetIcon: [
        '[&>svg]:w-[16px]',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'transition-transform duration-300',
        'group-data-[state=closed]:-rotate-90',
        'data-[state=closed]:-rotate-90',
      ],
      searchInput: [
        'border-[1px]',
        'rounded-mob-global-radius-small',
        'bg-color-background-white',
        'border-color-border-mid',
        'outline-none',
        'py-mob-global-spacing-spacing-1',
        'px-mob-global-spacing-spacing-2',
        'rounded-[4px]',
        'focus:border-color-brand-primary-1-base',
        'relative',
      ],
      facetLabel: ['w-full flex items-center justify-between group'],
      inputOperator: ['ml-[4px]'],
      iconSearch: [
        '[&>svg]:w-[16px]',
        '[&>svg]:h-fit',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'absolute',
        'translate-y-1/2',
      ],
      sortSelectParent: [
        '[&>*>div>div]:padding-0',
        '[&>*>div>div]:border-[2px]',
        '[&>*>div>div]:border-bg-color-border-mid',
        '[&>*>div>div]:overflow-y-hidden',
        '[&>*>div>div]:outline-none',
        '[&>*>div>div>div]:px-[12px]',
        '[&>*>div>div>div]:py-[8px]',
        '[&>*>div>div>div[data-highlighted]]:bg-color-primary-brand-1',
        '[&>*>div>div>div[data-highlighted]]:text-color-background-white',
        '[&>*>div>div>div[data-highlighted]]:outline-none',
      ],
      sortSelectTrigger: [
        'relative',
        'flex',
        'justify-between',
        'w-[240px]',
        'items-center',
        'cursor-pointer',
        'py-mob-padding-micro-y',
        'px-mob-global-spacing-spacing-3',
        'bg-color-background-white',
        'border-[1px]',
        'rounded-desk-global-radius-small',
        'outline-none',
        'data-[state=open]:border-[1px]',
        'data-[state=open]:border-color-brand-primary-1-base',
      ],
      sortSelectContent: [
        'bg-primary-contrastText',
        'overflow-hidden',
        'text-primary-main',
        'shadow-md',
        'absolute',
        'top-12',
        'z-50',
        'privacyShadow',
        'w-full',
        '!bg-color-background-white',
        'rounded-[4px]',
        '!border-[1px]',
        '!border-color-brand-primary-1-base',
      ],
      sortIcon: [
        'sortIcon',
        '[&_svg]:w-[16px]',
        '[&_svg]:h-[100%]',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'absolute',
        'right-[8px]',
        'transition-transform duration-300',
      ],
      sortOption: ['data-[highlighted]:bg-color-brand-primary-1-base'],

      selectedFiltersWrapper: ['w-full', 'flex flex-col', 'gap-[12px]', 'list-style-none'],
      selectedFacetCategory: ['text-color-text-black'],

      selectedFilterFacet: [],

      paginationArrow: ['[&_path]:fill-color-scale-7-mid-dark', 'cursor-pointer'],
      flagIcon: ['w-[27px]', 'h-[18px]'],
      querySummary: [
        // 'mx-auto',
        // 'my-0',
        'text-left',
        'text-color-scale-8-dark',
        '[&_span]:font-body-large-bold',
        '[&_span]:text-body-large-bold',
        '[&_span]:leading-body-large-bold',
      ],
      searchTitle: ['text-color-scale-8-dark'],
      sitecoreImage: [
        'relative',
        'h-[185px]',
        'border-b-[1px]',
        'border-color-border-mid',
        'overflow-y-clip',
      ],
      sitecoreImageWrapper: [
        'mx-10',
        'my-auto',
        'relative',
        'h-[156px]',
        'flex',
        'justify-center',
        'items-center',
        'overflow-hidden',
      ],
      productStockPriceInfo: [
        'flex',
        'flex-col',
        'gap-[12px]',
        'items-center',
        'justify-between',
        'h-[112px]',
      ],
      showMoreLess: ['hover:underline'],
      addToFavoritesWrapper: 'flex max-w-[148px] font-primary text-left',
      addToFavoriteIcon: 'inline-flex [&>svg>*>*]:fill-color-brand-primary-1-base',
    },
    compoundSlots: [
      {
        slots: [
          'submitBtn',
          'productLabels',
          'productButtonCTA',
          'submitBtn',
          'listPrice',
          'facetLabel',
        ],
        class: ['font-body-large-bold', 'text-body-large-bold', 'leading-body-large-bold'],
      },
      {
        slots: [
          'clearText',
          'facetCount',
          'facetItemLabel',
          'inputOperator',
          'sortSelectTrigger',
          'selectedFilterFacet',
          'querySummary',
        ],
        class: ['font-body-large-regular', 'text-body-large-regular', 'leading-body-large-regular'],
      },
      {
        slots: ['searchInput', 'sortSelectContent'],
        class: ['font-body-small-regular', 'text-body-small-regular', 'leading-body-small-regular'],
      },
      {
        slots: ['selectedFilterFacet', 'autoship'],
        class: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      },

      {
        slots: ['submitBtn'],
        class: ['break-words'],
      },
    ],
    variants: {
      size: {
        mobile: {
          sitecoreResultsWrapper: ['flex flex-col', 'py-mob-padding-tight-y'],
          sitecoreProductRoot: [
            'w-full',
            'w-[220px]',
            'py-mob-global-spacing-spacing-2',
            'px-mob-global-spacing-spacing-5',
            'flex',
            'flex-col',
          ],

          sitecoreProductName: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-5',
            'h-[138px]',
          ],
          productBottomWrapper: ['gap-[7px]'],
          sitecoreLeftSection: ['min-w-full', 'bg-color-scale-2-light'],
          filterButton: ['block'],
          iconSearch: ['right-[23px]'],
          searchTitle: [
            'font-heading-mob-xLarge-bold',
            'text-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          queryAndFilter: [
            'flex-col',
            'w-full',
            'items-start',
            'justify-between',
            'items-center',
            'gap-mob-margin-base-bottom',
          ],
          querySummary: ['w-full'],
          sortSelectParent: ['w-full', '[&>*>div>div]:w-full', '[&_button]:w-full'],
          splideWrapper: ['[&_li]:max-w-[136px]'],
          sitecoreMarketingTile: ['[&_img]:h-full'],
          facetPaginationBlock: ['gap-mob-space-between-tight-vertical'],
        },
        desktop: {
          sitecoreResultsWrapper: ['flex-row', 'justify-between', 'py-desk-padding-tight-y'],
          sitecoreProductRoot: ['p-desk-global-spacing-spacing-2'],
          sitecoreProductName: [
            'px-desk-global-spacing-spacing-3 py-desk-global-spacing-spacing-3',
            'h-[106px]',
          ],
          productBottomWrapper: ['gap-[10px]'],
          sitecoreLeftSection: [
            'min-w-fit',
            'block',
            'w-full',
            // 'lg:w-[12%]',
            'lg:w-[20%]',
            'lg:max-w-[276px]',
          ],
          filterButton: ['hidden'],
          iconSearch: ['right-[8px]'],
          searchTitle: [
            'font-heading-desk-xLarge-bold',
            'text-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          queryAndFilter: ['flex-row', 'w-full', 'justify-between', 'items-center'],
          querySummary: ['w-fit'],
          sortSelectParent: ['!w-[250px]', '[&>*>div>div]:w-[250px]'],
          facetItemLabel: ['max-w-[203px]'],
          sitecoreMarketingTile: ['w-[220px]', '[&_img]:h-full'],
          facetPaginationBlock: ['gap-desk-space-between-tight-vertical'],
        },
      },
      outlineCta: {
        true: {
          submitBtn: [
            'bg-color-background-white',
            'text-color-text-brand-1',
            'border-2 border-solid border-color-brand-primary-1-dark',
            'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
            'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
          ],
        },
        false: {
          submitBtn: [
            'bg-color-brand-primary-1-base',
            'text-color-text-white',
            'hover:bg-color-brand-primary-1-dark',
            'focus:bg-color-brand-primary-1-dark',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);

export default ProductCardTailwindVariant;
