// Global

import {
  Field as FormikField,
  Formik,
  Form as FormikForm,
  FormikValues,
  FormikHelpers,
} from 'formik';

// Utils and Form Helper
import TextAreaField from 'components/helpers/Form/TextAreaField';
import TextField from 'components/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
// Tailwind Import
import pickUpAddress from './TailwindVariant';
import CheckboxField from 'components/helpers/Form/CheckboxField';
import IconHelper from 'components/helpers/IconHelper';
import { ComponentProps } from 'lib/component-props';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState, useEffect, useContext, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { patchOrder } from 'src/redux/ocCurrentOrder';
import { FulfillmentType, GTMLabels, GTM_EVENT } from 'components/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import PhoneField, { formatPhoneNumber } from 'components/helpers/Form/PhoneField';
import RichTextHelper from 'components/helpers/RichTextHelper';
import LinkHelper from 'components/helpers/LinkHelper';
import { updateUser } from 'src/redux/ocUser';
import { LineItemWithXp, OrderWithXp, PickupInfo } from 'src/redux/xp';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import useOcCart from 'src/hooks/useOcCart';
import Loader from 'components/Loader/Loader';
import useDictionary from 'src/hooks/useDictionary';
import { formatPhone } from 'lib/utils/string-utils';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';
import ErrorLabel from '../ErrorLabel/ErrorLabel';

export type PickupAddressProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          submitButtonText: Field<string>;
          successMessage: Field<string>;
          successRedirectUrl?: { jsonValue: LinkField };
          failureMessage?: Field<string>;
          consentMessage?: Field<string>;
          errors: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
        };
        content: {
          editLabel: Field<string>;
          pickupInfo: Field<string>;
          pickupByLabel: Field<string>;
          pickupFromLabel: Field<string>;
          instructionLabel: Field<string>;
          pickupInfoTitle?: Field<string>;
        };
      };
    };
  };
export interface UserAddress {
  ID?: string;
  Phone?: string;
  FirstName?: string;
  LastName?: string;
  Street1?: string;
  Street2?: string;
  City?: string;
  State?: string;
  Zip?: string;
  Country?: string;
  AddressName?: string;
  Shipping?: boolean;
  xp?: { DeliveryInstruction?: string; PickupInstruction?: string; HomePhone?: string };
}
const PickupAddress = ({ fields }: PickupAddressProps): JSX.Element => {
  // Tailwind Variant
  const {
    base,
    titleWrapper,
    title,
    pickupAddressWrapper,
    addressBlock,
    informationTitle,
    form,
    radioIcon,
    pickupInformationContainer,
    radioButtonsWrapper,
    radioButtonWrapper,
    radioField,
    validFieldInfo,
    info,
    inlineFieldWrapper,
    radioAsCheckboxWrapper,
    labels,
    checkmarkIcon,
    field,
    inlineFields,
    submitBtn,
    loaderWrapper,
    fieldWrapper,
    addressBlockHeadline,
    blockWithMargin,
    linkWithUnderline,
    addressInfo,
    personalInfo,
  } = pickUpAddress({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  interface FormValuesTypes {
    Email: string;
    Message: string;
    PhoneNumber: string;
    PhoneTypeCheckbox: string;
    IamPickingUp: string;
    FirstName: string;
    LastName: string;
    savetoprofile?: string;
  }

  // OC Dispatch
  const dispatch = useOcDispatch();
  const { componentContextData, setcomponentContextData, setExpandedForm } =
    useContext(ComponentContext);
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);

  // Get form-fields
  const transFormFields = transformData(fields.data?.data?.formFields);

  // Radio for pickup information
  const radioButtonData = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'IamPickingUp'
  )?.checkboxvalues?.values;

  // Checkbox treated as radio
  const checkboxAsRadioButtonData = fields.data?.data?.formFields?.targetItems?.find(
    (item) => item?.id?.value === 'PhoneTypeCheckbox'
  )?.checkboxvalues?.values;
  const content = fields.data?.content;
  const empty = <div id="pickupaddress" className="pickupaddress" hidden></div>;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const pickupInformation = cart?.xp?.PickupInfo;
  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  // const checkoutForm = useCheckoutFormContext();

  const { pickupAddressForm } = useCheckoutFormContext() || {};

  const userPickupAddress = {
    ID: userDetails?.ID,
    Phone: userDetails?.Phone,
    xp: { PickupInstruction: userDetails?.xp?.PickupInstruction },
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(
    componentContextData?.showDeliveryAddressForm ? false : true
  );
  const [pickupToMe, setPickupToMe] = useState<boolean>(
    pickupInformation ? !!pickupInformation?.SelfPickup : true
  );
  const [validAddress, setValidAddress] = useState<boolean>(false);

  const ocCurrentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  // to re-render based on SelfPickup option on first load and Edit:
  useEffect(() => {
    if (pickupInformation) {
      setPickupToMe(!!pickupInformation.SelfPickup);
      const validAddressBoolean = !!(
        pickupInformation &&
        pickupInformation?.FirstName &&
        pickupInformation?.LastName &&
        pickupInformation?.Email &&
        pickupInformation?.StoreId
      );
      setValidAddress(validAddressBoolean);
    }
  }, [pickupInformation]);

  useEffect(() => {
    if (validAddress) {
      setCollapse(true);
    }
  }, [validAddress]);

  // will need to move validAddress as a useState.
  // const validAddress = !!(
  //   pickupInformation &&
  //   pickupInformation?.FirstName &&
  //   pickupInformation?.LastName &&
  //   pickupInformation?.Email &&
  //   pickupInformation?.StoreId
  // );

  // To toggle FName and LName based on selection
  const handleDeliveryInfo = (
    event: React.MouseEvent,
    setValues: FormikHelpers<FormikValues>['setValues']
  ) => {
    if ((event.target as HTMLInputElement)?.value == 'true') {
      setPickupToMe(true);
      if (!pickupInformation) {
        if (isAnonymous) {
          setValues((prevData) => ({
            ...prevData,
            FirstName: '',
            LastName: '',
            Email: '',
            PhoneNumber: '',
            PhoneTypeCheckbox: 'true',
            Message: '',
          }));
        } else {
          setValues((prevData) => ({
            ...prevData,
            FirstName: userDetails?.FirstName ?? '',
            LastName: userDetails?.LastName ?? '',
            Email: userDetails?.Email ?? '',
            PhoneNumber: formatPhoneNumber(userDetails?.Phone as string) ?? '',
            PhoneTypeCheckbox: userDetails?.xp?.IsMobile?.toString() ?? 'true',
            Message: userPickupAddress?.xp?.PickupInstruction ?? '',
          }));
        }
      } else {
        if (pickupInformation?.SelfPickup) {
          // should be pulled from saved information as pickUpToMe:true
          setValues((prevData) => ({
            ...prevData,
            FirstName: pickupInformation?.FirstName || (!isAnonymous ? userDetails?.FirstName : ''),
            LastName: pickupInformation?.LastName || (!isAnonymous ? userDetails?.LastName : ''),
            Email: pickupInformation?.Email || (!isAnonymous ? userDetails?.Email : ''),
            PhoneNumber:
              formatPhoneNumber(pickupInformation?.PhoneNumber) ||
              (!isAnonymous ? formatPhoneNumber(userDetails?.Phone as string) : ''),
            PhoneTypeCheckbox:
              pickupInformation?.IsMobile.toString() ||
              (!isAnonymous ? userDetails?.xp?.IsMobile?.toString() : 'true'),
            Message: userPickupAddress?.xp?.PickupInstruction ?? '',
          }));
        } else {
          // should be null as the saved data was pickUpToMe:false
          setValues((prevData) => ({
            ...prevData,
            FirstName: '',
            LastName: '',
            Email: !isAnonymous ? userDetails?.Email : '',
            PhoneNumber: !isAnonymous ? formatPhoneNumber(userDetails?.Phone as string) : '',
            PhoneTypeCheckbox: !isAnonymous ? userDetails?.xp?.IsMobile?.toString() : 'true',
            Message: userPickupAddress?.xp?.PickupInstruction ?? '',
          }));
        }
      }
    } else {
      setPickupToMe(false);

      if (pickupInformation?.SelfPickup) {
        // if true, values should be null
        setValues((prevData) => ({
          ...prevData,
          FirstName: '',
          LastName: '',
          Email: '',
          PhoneNumber: '',
          PhoneTypeCheckbox: 'true',
          Message: '',
        }));
      } else {
        // if false, values should be fetched from pickupInfo
        setValues((prevData) => ({
          ...prevData,
          FirstName: pickupInformation?.FirstName || '',
          LastName: pickupInformation?.LastName || '',
          Email: pickupInformation?.Email || '',
          PhoneNumber: formatPhoneNumber(pickupInformation?.PhoneNumber as string) || '',
          PhoneTypeCheckbox: pickupInformation?.IsMobile.toString() || 'true',
          Message: pickupInformation?.SpecialInstruction ?? '',
        }));
      }
    }
  };

  //set initial componentContextDataValues
  useLayoutEffect(() => {
    setcomponentContextData({ ...componentContextData, warningProfileUpdate: false });
  }, [componentContextData?.setSubmitting]);

  useEffect(() => {
    if (pickup && componentContextData?.expandedForm == 'pickup') {
      setCollapse(false);
      setExpandedForm('pickup');
      setTimeout(() => {
        const pickupTarget = document.getElementById('pickupaddress');
        pickupTarget && pickupTarget.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else if (pickup && !validAddress) {
      // alert('address not valid in pickup so open form');
      setCollapse(false);
      setExpandedForm('pickup');
    } else {
      //  'has valid address or not pickup
      setCollapse(true);
    }
  }, [componentContextData?.expandedForm]);

  useEffect(() => {
    // if (pickupInformation?.SelfPickup.toString() == 'true') {
    if (pickupInformation?.SelfPickup) {
      setPickupToMe(true);
    }
  }, [pickupInformation?.SelfPickup]);

  let pickupObject: FormValuesTypes = {
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    PhoneTypeCheckbox: 'true',
    IamPickingUp: 'true',
    Message: '',
    savetoprofile: 'true',
  };

  if (!pickupInformation) {
    // when no pickupInfo is saved prior to this.
    if (isAnonymous) {
      // for guest
      pickupObject = {
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneNumber: '',
        PhoneTypeCheckbox: 'true',
        IamPickingUp: 'true',
        Message: '',
        savetoprofile: 'false',
      };
    } else {
      pickupObject = {
        FirstName: userDetails?.FirstName ?? '',
        LastName: userDetails?.LastName ?? '',
        Email: userDetails?.Email ?? '',
        PhoneNumber: formatPhoneNumber(userDetails?.Phone as string) ?? '',
        PhoneTypeCheckbox: userDetails?.xp?.IsMobile?.toString() ?? 'true',
        IamPickingUp: 'true',
        Message: userDetails?.xp?.PickupInstruction ?? '',
        savetoprofile: 'true',
      };
    }
  } else {
    // already have saved pickupInfo
    pickupObject = {
      FirstName: pickupInformation?.FirstName ?? '',
      LastName: pickupInformation?.LastName ?? '',
      Email: pickupInformation?.Email ?? '',
      PhoneNumber: formatPhoneNumber(pickupInformation?.PhoneNumber as string) ?? '',
      PhoneTypeCheckbox: pickupInformation?.IsMobile?.toString() ?? 'true',
      IamPickingUp: (!pickupInformation || pickupInformation?.SelfPickup?.toString()) ?? 'true',
      Message: pickupInformation?.SpecialInstruction ?? '',
      savetoprofile: 'true',
    };
  }
  // passing the desired object into initial values
  const initialValues = pickupObject;

  // Function call to submit/save the delivery address[ShippingAddress] to the currentOrder.
  const submitForm = async (values: FormikValues) => {
    try {
      setLoading(true);
      const isMobile = values?.PhoneTypeCheckbox === 'true';
      const selfPick = values?.IamPickingUp === 'true';
      const pickupinfo: PickupInfo = {
        StoreId: myStoreData.storeId as string,
        FirstName: values?.FirstName ?? '',
        LastName: values?.LastName ?? '',
        Email: values?.Email ?? '',
        PhoneNumber: formatPhone(values?.PhoneNumber ?? ''),
        IsMobile: isMobile,
        SelfPickup: selfPick,
        SpecialInstruction: values?.Message,
      };

      // keep this commented. currently handled during setting up on the form and setValues
      if (pickupToMe && !isAnonymous) {
        // fetch names from user's details:
        pickupinfo.FirstName = userDetails?.FirstName ?? '';
        pickupinfo.LastName = userDetails?.LastName ?? '';
      }
      const request: OrderWithXp = {
        xp: {
          PickupInfo: pickupinfo,
          Fulfillment: FulfillmentType.BOPIS,
        },
      };
      // Send data to OC to save Pickup address
      await dispatch(patchOrder(request)).then(() => {
        setCollapse(true);
        if (!cart?.BillingAddress) {
          setcomponentContextData(() => ({
            ...componentContextData,
            setSubmitting: undefined,
            showDeliveryAddressForm: false,
            showBillingAddressForm: true,
          }));
          // go to BA form:
          if (setExpandedForm) {
            setExpandedForm('billing');
            const billingTarget = document.getElementById('billingaddress');
            billingTarget && billingTarget.scrollIntoView({ behavior: 'smooth' });
          }
        }
        // TODO: if Step1, Step2 are saved and clicking on Step1 Continue CTA, should redirect to step3
        if (cart?.BillingAddress) {
          setcomponentContextData(() => ({
            ...componentContextData,
            showDeliveryAddressForm: false,
            showBillingAddressForm: false,
            showPaymentSection: true,
          }));
          if (setExpandedForm) {
            setExpandedForm('payment'); // delivery | billing | tip | pickup | payment
            const paymentTarget = document.getElementById('paymentsection');
            paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
          }
        }
      });

      setLoading(false);
    } catch (error) {
      console.error('saveShippingAddress: ', error);
    }
  };

  //for saving pickupaddress in home
  const saveUserProfile = async (values: FormikValues) => {
    setLoading(true);
    const request: UserAddress = {
      ID: userPickupAddress?.ID as string,
      ...(values?.PhoneTypeCheckbox === 'true' && { Phone: formatPhone(values?.PhoneNumber) }),
      xp: {
        PickupInstruction: values?.Message as string,
        ...(values?.PhoneTypeCheckbox !== 'true' && {
          HomePhone: formatPhone(values?.PhoneNumber),
        }),
      },
    };
    request && (await dispatch(updateUser(request)));
    setLoading(false);
  };

  const [formValues, setFormValues] = useState<FormikValues>();

  useEffect(() => {
    if (componentContextData?.setSubmitting !== undefined) {
      if (componentContextData?.setSubmitting === true) {
        setcomponentContextData({ ...componentContextData, setSubmitting: undefined });
        saveUserProfile(formValues as FormikValues);
        submitForm(formValues as FormikValues);
      } else {
        setcomponentContextData({ ...componentContextData, setSubmitting: undefined });
        submitForm(formValues as FormikValues);
      }
    }
  }, [componentContextData?.setSubmitting]);

  const openDeliveryForm = async () => {
    const productData = productlineitems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
        listPrice: lineItem?.UnitPrice,
      };
      return products;
    });

    sendProductsPromotion({
      eventName: GTM_EVENT?.beginCheckout,
      data: productData as unknown as ProductSearchResultModelWithVariants[],
      currentPage: currentPage,
      pageItem: pageItem,
      position: position ?? 0,
      storeId: myStoreData?.storeId,
      currency: true,
      ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
      totalCount: Number(cartPricing?.subTotal),
      fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
    });
    /*
      Commenting now as discussed that first form Edit functionality should not be handled by the second form
    */
    /*
      const billingAddressErrors = await (checkoutForm?.billingAddressForm?.current?.errors as Record<
        string,
        string
      >);
      if (billingAddressErrors) {
        const deliveryErrorArray = Object.entries(billingAddressErrors);
        if (deliveryErrorArray.length > 0) {
          const deliveryTarget = document.getElementById('billingaddress');
          deliveryTarget && deliveryTarget.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }
    */
    // Remove Loader and Restore Submit Button
    setLoading(false);
    if (setExpandedForm) {
      setExpandedForm('pickup'); // delivery | billing | tip | pickup | payment
      setCollapse(false);
      const pickupTarget = document.getElementById('pickupaddress');
      pickupTarget && pickupTarget.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //submitting PickupAddress
  const submitPickupAddress = async (values: FormikValues) => {
    setcomponentContextData({ ...componentContextData, isSavedMarked: values?.savetoprofile });
    setFormValues(values);
    if (values?.savetoprofile && !isAnonymous) {
      if (
        formatPhone(userPickupAddress?.Phone ?? '') !== formatPhone(values?.PhoneNumber) ||
        (userPickupAddress?.xp?.PickupInstruction &&
          userPickupAddress?.xp?.PickupInstruction !== values?.Message)
      ) {
        setcomponentContextData({
          ...componentContextData,
          warningProfileUpdate: true,
          isPickup: true,
        });
      } else {
        saveUserProfile(values);
        submitForm(values);
      }
    } else {
      submitForm(values);
    }
  };

  const { currentPage, pageItem, position } = getGTMSessionStorage();
  const { getDictionaryValue } = useDictionary();

  const cartPricing = useCartPriceForUI(currentOrder);

  useEffect(() => {
    const productData = productlineitems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
        listPrice: lineItem?.UnitPrice,
      };
      return products;
    });

    const checkGtmLoad = () => {
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (!isAnonymous && pickup && productData && isGTMLoad) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.beginCheckout,
          data: productData as unknown as ProductSearchResultModelWithVariants[],
          currentPage: currentPage,
          pageItem: pageItem,
          position: position ?? 0,
          storeId: myStoreData?.storeId,
          currency: true,
          ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
          totalCount: Number(cartPricing?.subTotal),
          fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [pickup, isAnonymous]);

  useEffect(() => {
    const productData = productlineitems?.map((lineItem) => {
      const products = {
        ...lineItem?.Product,
        quantity: lineItem?.Quantity,
        BasePrice: lineItem?.UnitPrice,
      };
      return products;
    });

    isAnonymous &&
      collapse &&
      pickup &&
      productData &&
      sendProductsPromotion({
        eventName: GTM_EVENT?.beginCheckout,
        data: productData as unknown as ProductSearchResultModelWithVariants[],
        currentPage: currentPage,
        pageItem: pageItem,
        position: position ?? 0,
        storeId: myStoreData?.storeId,
        currency: true,
        ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
        totalCount: Number(cartPricing?.subTotal),
        fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      });
  }, [pickup, collapse]);

  /**
   * Logic to automatically save the Pickup form info if user is authenticated
   */
  useEffect(() => {
    if (
      !isAnonymous &&
      ocCurrentOrder?.initialized == true &&
      componentContextData?.expandedForm == 'pickup'
    ) {
      if (
        !(
          pickupInformation?.FirstName &&
          pickupInformation?.Email &&
          pickupInformation?.PhoneNumber
        )
      ) {
        // Extract data from user Details and save to shippingAddress.
        submitForm(initialValues); // call save shippingAddress:
        setCollapse(false);

        if (cart?.BillingAddress) {
          // if Billing present already, open payment,
          setTimeout(() => {
            const paymentTarget = document.getElementById('paymentsection');
            paymentTarget && paymentTarget.scrollIntoView({ behavior: 'smooth' });
          }, 500);
        }
      }

      if (cart?.BillingAddress) {
        setCollapse(false);
      } else {
        // Commenting to overcome PSP-1788: Not opening of PickupAddress, and focusing on BillingAddress.
        // setExpandedForm('billing');
        // const billingTarget = document.getElementById('billingaddress');
        // billingTarget && billingTarget.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [componentContextData?.expandedForm, pickupInformation, ocCurrentOrder?.initialized]);

  if (!myStoreData || !cart || cart?.xp?.Fulfillment === FulfillmentType.DFS) {
    return empty;
  }

  return (
    <>
      {componentContextData?.CheckoutError == true && <ErrorLabel />}
      <div id="pickupaddress" className={'pickupaddress ' + base({ isCollapsed: collapse })}>
        <div className={titleWrapper()}>
          <div className={title({ isCollapsed: collapse })}>
            1. {fields?.data?.data?.title?.value}
          </div>
          {collapse && (
            <div className={linkWithUnderline() + ' cursor-pointer'} onClick={openDeliveryForm}>
              {content?.editLabel?.value ?? 'Edit'}
            </div>
          )}
        </div>
        <div className={pickupAddressWrapper()}>
          <div className={personalInfo()}>
            <div className={blockWithMargin()}>
              <div className={clsx(addressBlockHeadline(), addressBlock())}>
                {' '}
                {content?.pickupFromLabel?.value ?? 'Pick From:'}
              </div>
              <div className={addressBlock()}>
                <span className="block">
                  {myStoreData?.storeName}, {myStoreData?.street1}
                </span>
                <span className="block">
                  {myStoreData?.city}, {myStoreData?.state}
                </span>
              </div>
            </div>
            <div className={blockWithMargin()}>
              <div className={addressBlock()}>
                <LinkHelper
                  className={linkWithUnderline()}
                  field={{
                    value: {
                      href: `tel:${myStoreData?.phone}`,
                      text: `${myStoreData?.phone}`,
                    },
                  }}
                />
              </div>
            </div>
            {collapse && (
              <div className="flex">
                <div className={blockWithMargin()}>
                  <div className={addressBlock()}>
                    {content?.pickupByLabel?.value ?? 'Pickedup By:'} {initialValues?.FirstName}{' '}
                    {initialValues?.LastName}
                  </div>
                  {initialValues?.PhoneNumber && (
                    <LinkHelper
                      className={linkWithUnderline()}
                      field={{
                        value: {
                          href: `tel:${initialValues?.PhoneNumber}`,
                          text: `${formatPhoneNumber(initialValues?.PhoneNumber)}`,
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={addressInfo()}>
            <RichTextHelper className={addressInfo()} field={content?.pickupInfo} />
          </div>
        </div>
        <div className={personalInfo()}>
          {initialValues?.Message && (
            <div className="block">
              <div className={addressBlock()}>
                {content?.instructionLabel?.value ?? 'Special instructions'}
              </div>
              <div className="break-all">{initialValues?.Message}</div>
            </div>
          )}
        </div>

        {!collapse && (
          <Formik
            initialValues={initialValues}
            innerRef={pickupAddressForm}
            onSubmit={(values: FormValuesTypes) => {
              submitPickupAddress(values);
            }}
          >
            {({ setValues }) => (
              <FormikForm className={pickupInformationContainer()}>
                <div className={radioButtonsWrapper()}>
                  <div className={informationTitle()}>{content?.pickupInfoTitle?.value}</div>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className={radioButtonsWrapper()}
                  >
                    {radioButtonData?.map((radioButton, index: number) => {
                      return (
                        <label key={index} className={radioButtonWrapper()}>
                          <FormikField
                            type="radio"
                            className={radioField()}
                            name="IamPickingUp"
                            value={radioButton?.value}
                            onClick={(event: React.MouseEvent) =>
                              handleDeliveryInfo(event, setValues)
                            }
                          />
                          {radioButton?.name}
                          <div className={radioIcon()}></div>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className={pickupInformationContainer()}>
                  <div className={form({ isCollapsed: collapse })}>
                    {/* Fields marked with an asterisk * .... */}
                    {fields?.data?.data?.shortDescription?.value && (
                      <div className={fieldWrapper()}>
                        <div className={validFieldInfo()}>
                          {fields?.data?.data?.shortDescription?.value}
                        </div>
                      </div>
                    )}

                    {/* First Name and Last Name */}
                    {isAnonymous && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}
                    {!isAnonymous && !pickupToMe && (
                      <div className={inlineFields()}>
                        {transFormFields.FirstName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.FirstName} />
                          </div>
                        )}
                        {transFormFields.LastName && (
                          <div className={fieldWrapper()}>
                            <TextField {...transFormFields.LastName} />
                          </div>
                        )}
                      </div>
                    )}

                    {/* Email Field */}
                    {transFormFields.Email && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields.Email} />
                      </div>
                    )}

                    {/* Telephone - Mobile Phone & Home Phone */}
                    {transFormFields.PhoneNumber && (
                      <div className={inlineFieldWrapper()}>
                        <div className="inlineWrapper">
                          <PhoneField {...transFormFields.PhoneNumber} />
                        </div>
                        <div className={radioAsCheckboxWrapper()}>
                          {checkboxAsRadioButtonData?.map((element, index: number) => {
                            return (
                              <label className={labels()} key={index}>
                                <FormikField
                                  type="radio"
                                  className={field({ className: 'rounded-full' })}
                                  name="PhoneTypeCheckbox"
                                  value={element?.value}
                                />
                                {element.name}
                                <IconHelper className={checkmarkIcon()} icon={'icon-checkmark'} />
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {/* Special Information TextBlock */}
                    <div className={fieldWrapper()}>
                      {transFormFields.Message && <TextAreaField {...transFormFields.Message} />}
                      {fields?.data?.data?.consentMessage && (
                        <span className={info()}>{fields?.data?.data?.consentMessage?.value}</span>
                      )}
                    </div>

                    {/* Save to Profile */}
                    {!isAnonymous && transFormFields.savetoprofile && (
                      <div className={fieldWrapper()}>
                        {<CheckboxField {...transFormFields.savetoprofile} singleCheckbox={true} />}
                      </div>
                    )}
                    <div className={fieldWrapper()}>
                      {loading ? (
                        <div className={loaderWrapper()}>
                          <Loader />
                          {getDictionaryValue('ScheduleAppointmentsStep2SelectDogInProgressText')}
                        </div>
                      ) : (
                        <>
                          {fields.data?.data?.submitButtonText?.value && (
                            <button aria-label="submit" className={submitBtn()} type="submit">
                              {fields.data?.data?.submitButtonText?.value}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};
export default PickupAddress;
