import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import BasePersonalizedImage from './BasePersonalizedImage';
import { Field, ImageField, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { tv } from 'tailwind-variants';
import LinkHelper from 'components/helpers/LinkHelper';
import clsx from 'clsx';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'components/helpers/Constants';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';

// Component variants
const personalizedImageVariants = tv(
  {
    slots: {
      linkWrapper: [
        'group',
        'text-heading-mob-medium-bold',
        'leading-heading-mob-medium-bold',
        'gap-mob-space-between-micro-horizontal',
        'items-center',
      ],
      contentWrapper: [],
    },
    variants: {
      size: {
        mobile: {
          contentWrapper: [
            'py-mob-padding-micro-y',
            'gap-mob-margin-tight-bottom',
            'flex-col',
            'items-start',
            'hidden',
          ],
        },
        desktop: {
          contentWrapper: ['gap-desk-margin-tight-bottom', 'flex-row', 'block'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);

const { linkWrapper, contentWrapper } = personalizedImageVariants({
  size: { initial: 'mobile', lg: 'desktop' },
});

export type PromotionData = {
  personalizedLinkUrl?: LinkField;
  personalizedImage?: ImageField;
  personalizedTitle?: Field<string>;
  promotionName?: Field<string>;
  promotionId?: Field<string>;
  componentName?: Field<string>;
  creativeSlotNumber?: Field<string>;
  promotionCopy?: Field<string>;
  promotionDateFROM?: Field<string>;
  promotionDateTO?: Field<string>;
  cTAButtonCopy?: Field<string>;
  promotionURL?: LinkField;
};
type PersonalizedImageProps = ComponentProps & {
  fields: PromotionData;
  imageClassName?: string; // class name for tailwind css for image styling
};

export const Default = withPersonalization((props: PersonalizedImageProps) => (
  <BasePersonalizedImage {...props} />
));

export const OnlyImage = withPersonalization((props: PersonalizedImageProps) => (
  <BasePersonalizedImage {...props} />
));

export const BottomTitle = withPersonalization((props: PersonalizedImageProps) => {
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(props?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(props?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(props?.fields?.componentName?.value),
      creative_slot: Number(props?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(props?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${props?.fields?.promotionDateFROM?.value} - ${props?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(props?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(props?.fields?.promotionURL?.value?.href),
    },
  ];
  return (
    <BasePersonalizedImage {...props}>
      <div className={contentWrapper()}>
        <LinkHelper
          field={props?.fields?.personalizedLinkUrl}
          className={clsx(linkWrapper())}
          onClick={() => {
            myStoreId &&
              props?.fields?.componentName?.value !== '' &&
              trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
          }}
        >
          {props?.fields?.personalizedTitle && (
            <Text field={props?.fields?.personalizedTitle} tag="span" />
          )}
        </LinkHelper>
      </div>
    </BasePersonalizedImage>
  );
});
