import { NormalizedProductPrice } from 'lib/types/products';
import { ProductSearchResultModel } from './search/SearchResults/types';

// Defines the structure of product price information
export interface UIProductPrice {
  productId?: string;
  listPrice: number;
  memberPrice: number;
  imapPrice: number;
  hasListPrice?: boolean;
  hasMemberPrice?: boolean;
  hasImapPrice?: boolean;
  hasListandMemberPrice?: boolean;
  showViewPriceInCart?: boolean;
}

export function getProductPriceFromSearch(product: ProductSearchResultModel): UIProductPrice {
  const normalizedPrice: NormalizedProductPrice = {
    productId: product?.id,
    listPrice: product?.priceschedule?.xp_listprice,
    memberPrice: product?.priceschedule?.xp_ppcprice,
    iMapPrice: product?.priceschedule?.xp_imapprice,
  };
  return getProductPriceUI(normalizedPrice);
}

// Retrieves and processes product price information for UI display
export function getProductPriceUI(product: NormalizedProductPrice): UIProductPrice {
  // Extracts price information, fallback to zero if not available
  const productId = product.productId;
  const listPrice = product?.listPrice ?? 0;
  const memberPrice = product?.memberPrice ?? 0;
  const imapPrice = product?.iMapPrice ?? 0;

  // Determines if IMAP price is available and greater than list and PPC prices
  const hasImapPrice =
    (imapPrice > 0 && imapPrice > listPrice && listPrice > memberPrice) ||
    (imapPrice > 0 && imapPrice == listPrice && listPrice > memberPrice) ||
    (imapPrice > 0 && imapPrice > listPrice && !memberPrice) ||
    (imapPrice > 0 && imapPrice > listPrice && listPrice == memberPrice);

  // Determines if list price is available and greater than IMAP and PPC prices
  const hasListPrice =
    (listPrice > 0 && listPrice > imapPrice && imapPrice > memberPrice) ||
    (listPrice > 0 && listPrice > imapPrice && !memberPrice) ||
    (listPrice > 0 && listPrice > imapPrice && listPrice == memberPrice) ||
    (listPrice > 0 && listPrice == imapPrice && imapPrice == memberPrice);

  // For PLP
  const showViewPriceInCart = listPrice > 0 && listPrice > imapPrice && imapPrice > memberPrice;

  // Determines if PPC price is available and greater than list and IMAP prices
  const hasMemberPrice = memberPrice > 0 && memberPrice > listPrice && memberPrice > imapPrice;

  // Determines if listPrice price is available and greater than PPC and PPC is greater then IMAP prices
  const hasListandMemberPrice =
    listPrice > 0 && listPrice > memberPrice && memberPrice >= imapPrice;

  return {
    productId,
    listPrice,
    memberPrice,
    imapPrice,
    hasListPrice,
    hasMemberPrice,
    hasImapPrice,
    hasListandMemberPrice,
    showViewPriceInCart,
  };
}
