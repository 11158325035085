import tempConfig from 'temp/config';

export async function edgeSafeGraphQLQuery<TReturn>(
  query: string,
  variables: Record<string, unknown>
) {
  const apiUrl = tempConfig.graphQLEndpoint;
  const apiKey = tempConfig?.sitecoreApiKey;
  const response = await fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables: variables,
    }),
    headers: {
      'content-type': 'application/json',
      sc_apikey: apiKey,
    },
  });
  if (response.ok) {
    const json = await response.json();

    if (json.data) {
      return json.data as TReturn;
    } else {
      throw new Error(`GraphQL Error:  ${json}`);
    }
  } else {
    const responseText = await response.text();

    throw new Error(`Error ${response.status}: ${response.statusText}.  ${responseText}`);
  }
}
