//global
import React, { useEffect, useState } from 'react';
import {
  Field as sitecoreField,
  LinkField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
//local
import apiConfig from 'src/utils/apiConfig';
//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'components/helpers/LinkHelper';
import { apiRequest } from 'src/utils/apiWrapper';
import { useCookies } from 'react-cookie';
import { Tokens } from 'ordercloud-javascript-sdk';
import { useTheme } from 'lib/context/ThemeContext';
import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
//type
export type AccountUnlockFormProps = ComponentProps & {
  fields?: {
    data?: {
      data?: {
        title: sitecoreField<string>;
        consentMessage: sitecoreField<string>;
        shortDescription: sitecoreField<string>;
        submitButtonText: sitecoreField<string>;
        successMessage: sitecoreField<string>;
        failureMessage: sitecoreField<string>;
        cancelButtonUrl: {
          jsonValue: LinkField;
        };
        cancelButtonText: sitecoreField<string>;
        successRedirectUrl: {
          jsonValue: LinkField;
        };
        formFields: {
          targetItems: FormField[];
        };
      };
    };
  };
};
type FormField = {
  id: sitecoreField<string>;
  name: sitecoreField<string>;
  label: sitecoreField<string>;
  placeholdertext: sitecoreField<string>;
  helptext: sitecoreField<string>;
  required: sitecoreField<string>;
  requiredvalidationmessage: sitecoreField<string>;
  validationpattern: sitecoreField<string>;
  validationerrormessage: sitecoreField<string>;
  characterlimit: sitecoreField<number | null>;
  disabled: sitecoreField<string>;
  fieldtype: sitecoreField<string>;
  checkboxvalues?: radioList[];
};
interface radioList {
  name?: string;
  value?: string;
}
//component variants
const accountUnlockFormVariants = tv(
  {
    slots: {
      base: 'flex-col bg-color-background-light accountUnlockForm flex flex-center',
      successBase: 'flex text-center justify-center w-full',
      title: 'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      headingWrapper: 'flex flex-col',
      description:
        'font-primary rte text-body-large-regular leading-body-large-regular font-body-large-regular',
      radioButtonWrapper: 'flex w-fit relative cursor-pointer',
      radioButtonsWrapper: 'flex',
      radioIcon:
        'invisible w-[20px] h-[20px] outline-color-border-mid border-2 rounded-full peer-checked:visible absolute bg-color-brand-primary-1-base',
      radioField:
        'w-[20px] h-[20px] rounded-full cursor-pointer outline-color-border-mid border-2 appearance-none bg-color-background-white rounded-4 peer',
      button:
        'hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold items-center bg-color-brand-primary-1-base text-color-text-white rounded-desk-global-radius-small',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          successBase: 'mb-mob-margin-loose-bottom py-mob-padding-tight-y',
          base: 'mb-mob-margin-loose-bottom mx-mob-global-grid-margin gap-mob-global-spacing-spacing-7 px-mob-padding-tight-x py-mob-padding-tight-y',
          headingWrapper: 'gap-mob-margin-base-bottom',
          radioButtonsWrapper: 'gap-mob-margin-tight-right',
          radioButtonWrapper: 'gap-mob-space-between-micro-horizontal',
          button:
            'w-full mt-mob-margin-loose-top px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y',
        },
        desktop: {
          successBase: 'mb-desk-margin-loose-bottom  py-desk-padding-tight-y',
          base: 'mx-auto w-full max-w-[800px] gap-desk-global-spacing-spacing-11 px-desk-padding-tight-x py-desk-padding-tight-y mb-desk-margin-loose-bottom',
          headingWrapper: 'gap-desk-margin-tight-bottom',
          radioButtonWrapper: 'gap-desk-space-between-micro-horizontal',
          radioButtonsWrapper: 'gap-desk-margin-tight-right',
          button:
            'w-fit mt-desk-margin-base-top w-fit px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
const getFieldData = (
  formFields: {
    targetItems: FormField[];
  },
  fieldName: string
) => {
  return formFields?.targetItems?.find((item: FormField) => item?.name?.value === fieldName)?.label;
};
//main component
const AccountUnlockForm: React.FC<AccountUnlockFormProps> = ({ fields, params }) => {
  const { base, successBase, title, headingWrapper, button, description } =
    accountUnlockFormVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const [successResponse, setSuccessResponse] = useState(false);
  const [emailFromLinkExpiredScenario, setEmailFromLinkExpiredScenario] = useState<false | string>(
    false
  );
  const [showAccountUnlockFormForLinkExpiredCase, setShowAccountUnlockFormForLinkExpiredCase] =
    useState(false);
  const [isMobileFromLinkExpiredScenario, setIsMobileFromLinkExpiredScenario] = useState(false);

  const [cookies] = useCookies(['ordercloud.access-token']);
  const { themeNameUpper } = useTheme();
  const router = useRouter();
  let token: string;
  if (cookies['ordercloud.access-token']) {
    token = cookies['ordercloud.access-token'];
  } else {
    token = Tokens.GetAccessToken() ?? '';
  }
  const renderFormFields = (formFields: { targetItems: FormField[] }) => {
    const { radioButtonWrapper, radioButtonsWrapper, radioField, radioIcon } =
      accountUnlockFormVariants({
        size: { initial: 'mobile', lg: 'desktop' },
      });
    const radioButtonData = formFields?.targetItems?.find(
      (item: FormField) => item?.id?.value === 'SendLinkCheckbox'
    )?.checkboxvalues?.values as radioList[] | undefined;
    return (
      <div role="group" aria-labelledby="my-radio-group" className={radioButtonsWrapper()}>
        {radioButtonData?.map((radioButton: radioList, index: number) => {
          if (
            radioButton?.name === 'Text' &&
            router?.query?.ismobile?.toString()?.toLowerCase() !== 'true' &&
            isMobileFromLinkExpiredScenario !== true
          ) {
            return <></>;
          }
          return (
            <>
              <label key={index} className={radioButtonWrapper()}>
                <Field
                  aria-label="SendLinkCheckbox"
                  type="radio"
                  class={radioField()}
                  name="SendLinkCheckbox"
                  value={radioButton?.name}
                />
                {radioButton?.name}
                <div className={radioIcon()}></div>
              </label>
            </>
          );
        })}
      </div>
    );
  };
  const onSendLinkClick = async (e: React.MouseEvent<HTMLButtonElement>, selectedMode: string) => {
    e.stopPropagation();
    e.preventDefault();
    if (router?.query?.un || emailFromLinkExpiredScenario) {
      try {
        const email = router?.query?.un ?? emailFromLinkExpiredScenario;
        const response = await apiRequest(
          process.env.NEXT_PUBLIC_API_ENDPOINT +
            apiConfig?.lockedUserRequestAPI?.lockedUserRequestEndpoint,
          {
            method: 'POST',
            headers: {
              Authorization: token,
              site: themeNameUpper,
              requesturl: window.location.origin,
            },
            data: {
              Email: decodeURIComponent(email?.toString()),
              SMS: selectedMode === 'Text',
            },
          }
        );
        if (response) {
          setSuccessResponse(true);
          HideAllHeadingWithDescriptionExceptThis();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const unlockUser = async () => {
    try {
      const dataToSend = router?.query;
      delete dataToSend?.path;
      const response: {
        Status: string;
        Email?: string;
        IsMobile?: boolean;
      } = await apiRequest(
        process.env.NEXT_PUBLIC_API_ENDPOINT +
          apiConfig?.lockedUserRequestAPI?.unlockUserEndpoint +
          `?${new URLSearchParams(dataToSend as { [key: string]: string }).toString()}`,
        {
          method: 'POST',
          headers: {
            Authorization: token,
            site: themeNameUpper,
            requesturl: window.location.origin,
          },
        }
      );
      if (response) {
        showThisHeadingWithDescription(response?.Status);
        if (response?.Status === 'LinkExpired') {
          setShowAccountUnlockFormForLinkExpiredCase(true);
          if (response?.Email) {
            setEmailFromLinkExpiredScenario(response?.Email);
          }
          if (response?.IsMobile) {
            setIsMobileFromLinkExpiredScenario(response?.IsMobile);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const showThisHeadingWithDescription = (className: string) => {
    const headings = document.querySelectorAll(`.${className}`);
    headings.forEach((heading: HTMLElement) => {
      heading.style.display = 'block';
    });
  };
  const HideAllHeadingWithDescriptionExceptThis = (className?: string) => {
    const headings = document.querySelectorAll(
      className ? `.headingWithDescription:not(.${className})` : '.headingWithDescription'
    );
    headings.forEach((heading: HTMLElement) => {
      heading.style.display = 'none';
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (router.isReady) {
        if (router?.query?.un) {
          HideAllHeadingWithDescriptionExceptThis('accountUnlockHeading');
        } else if (router?.query?.e) {
          HideAllHeadingWithDescriptionExceptThis();
          unlockUser();
        }
      }
    }, 100);
    return () => {
      const headings = document.querySelectorAll('.headingWithDescription');
      headings.forEach((heading: HTMLElement) => {
        heading.style.display = 'block';
      });
      clearTimeout(timer);
    };
  }, [router.isReady]);
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={'AccountUnlockForm ' + params?.Styles}>
      {successResponse ? (
        <div className={successBase()}>
          <RichText className="rte" tag="p" field={fields?.data?.data?.successMessage} />
        </div>
      ) : (
        <>
          {(router?.query?.un || showAccountUnlockFormForLinkExpiredCase) && (
            <div className={base()}>
              <div>
                <div className={headingWrapper()}>
                  {fields?.data?.data?.formFields && (
                    <Text
                      tag="p"
                      className={title()}
                      field={getFieldData(fields?.data?.data?.formFields, 'SendLinkCheckbox')}
                    />
                  )}
                  <div>
                    {fields?.data?.data?.formFields && (
                      <Formik
                        initialValues={{
                          SendLinkCheckbox: 'Email',
                        }}
                        onSubmit={async () => {
                          await new Promise((r) => setTimeout(r, 500));
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            {fields?.data?.data?.formFields &&
                              renderFormFields(fields?.data?.data?.formFields)}
                            <button
                              type="submit"
                              className={button()}
                              onClick={(e) => onSendLinkClick(e, values.SendLinkCheckbox)}
                            >
                              {fields?.data?.data?.submitButtonText?.value}
                            </button>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className={headingWrapper()}>
                  {fields?.data?.data?.formFields && (
                    <Text
                      tag="p"
                      className={title()}
                      field={getFieldData(fields?.data?.data?.formFields, 'Registration1Text')}
                    />
                  )}
                  {fields?.data?.data?.formFields && (
                    <RichText
                      tag="p"
                      className={description()}
                      field={getFieldData(fields?.data?.data?.formFields, 'Registration2Text')}
                    />
                  )}
                </div>
                <LinkHelper
                  className={button()}
                  field={{
                    value: {
                      ...fields?.data?.data?.cancelButtonUrl?.jsonValue?.value,
                      text: fields?.data?.data?.cancelButtonText?.value,
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<AccountUnlockFormProps>(AccountUnlockForm);
